import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MenuPopoverList } from 'common/components';
import { Typography } from 'common/ui';

const getInstallationTypeText = (installationType, t) => {
    switch (get(installationType, 'key', 3)) {
        case 0:
            return t('Residential');
        case 1:
            return t('Commercial');
        case 2:
            return t('Industrial');
        default:
            return t('No installation type');
    }
};

const InstallationTypeFormatter = ({ row }) => {
    const { t } = useTranslation();
    const installationTypes = get(row, 'installation_type', []);
    const installationTypesForPopoverList = installationTypes.map((i) => {
        return {
            id: i.id,
            name: getInstallationTypeText(i.installation_type, t),
        };
    });

    if (installationTypes.length === 0) {
        return (
            <Typography
                key={`${row.id}-installation-type`}
                variant="subtitle2"
                sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                title={t('No installation type')}
            >
                {t('No installation type')}
            </Typography>
        );
    }
    if (installationTypes.length === 1) {
        return (
            <Typography
                key={`${row.id}-installation-type`}
                variant="subtitle2"
                sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                title={getInstallationTypeText(
                    installationTypes[0].installation_type,
                    t
                )}
            >
                {getInstallationTypeText(
                    installationTypes[0].installation_type,
                    t
                )}
            </Typography>
        );
    }
    return (
        <MenuPopoverList
            items={installationTypesForPopoverList}
            keyName={`${row.id}-installation-type`}
            titleList={t('View rates')}
            titleMenu={t('Rate', { count: 2 })}
        />
    );
};

InstallationTypeFormatter.propTypes = {
    cell: PropTypes.string,
    row: PropTypes.object,
};

const InstallationTypeFormatterBuild = (_, row) => {
    return <InstallationTypeFormatter row={row} />;
};

export default InstallationTypeFormatterBuild;
