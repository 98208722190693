import React from 'react';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PropTypes from 'prop-types';

import { Box, IconButton } from 'common/ui';

import FileUploadIconButton from './FileUploadIconButton';

const FilePreviewControls = ({
    disabled,
    handleOnChange,
    handleOnZoomIn,
    handleOnZoomOut,
}) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
            <IconButton onClick={handleOnZoomIn} size="medium">
                <ZoomInIcon fontSize="medium" />
            </IconButton>
            <IconButton onClick={handleOnZoomOut} size="medium">
                <ZoomOutIcon fontSize="medium" />
            </IconButton>
            {handleOnChange && (
                <FileUploadIconButton
                    disabled={disabled}
                    handleOnChange={handleOnChange}
                />
            )}
        </Box>
    );
};

FilePreviewControls.propTypes = {
    disabled: PropTypes.bool,
    handleOnChange: PropTypes.func,
    handleOnZoomIn: PropTypes.func,
    handleOnZoomOut: PropTypes.func,
};

export default FilePreviewControls;
