import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { bulkProducts } from 'common/api/productsBulk';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';

import fetchInverters from './fetchInverters';

export default (ids, value, filterData, callback) => (dispatch) => {
    dispatch({ type: BULK_UPDATE_ITEMS });

    const products = ids.map((id) => ({ id, status_flag: value }));

    bulkProducts({ product_type: 'inverters', products })
        .then(() => {
            dispatch({
                type: BULK_UPDATE_ITEMS_SUCCESS,
            });
            if (!isEmpty(filterData)) dispatch(fetchInverters(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            if (value === 'archived')
                showToast({ body: i18next.t('It was successfully archived') });
            if (value === 'active')
                showToast({
                    body: i18next.t('It was successfully unarchived'),
                });
            if (callback) callback();
        })
        .catch((error) => {
            dispatch({
                type: BULK_UPDATE_ITEMS_FAILURE,
                payload: error?.response?.data?.errors,
            });
        });
};
