import i18next from 'i18next';

import { create, update } from 'common/api/v1/rates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_CUSTOM_RATE,
    SAVE_CUSTOM_RATE_FAILURE,
    SAVE_CUSTOM_RATE_SUCCESS,
} from '../actionTypes';
import { getJsonValues } from '../helpers';

import fetchCustomRates from './fetchCustomRates';
import setModalIsOpen from './setModalIsOpen';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE_CUSTOM_RATE });

    const jsonValues = getJsonValues(values);

    const save = !values.id
        ? () => create(jsonValues)
        : () => update(values.id, jsonValues);

    save()
        .then((response) => {
            dispatch(fetchCustomRates());
            dispatch(setModalIsOpen(false));
            showToast({ body: i18next.t('Changes were saved') });
            dispatch({
                payload: response.data.data,
                type: SAVE_CUSTOM_RATE_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch({
                payload: error?.response?.data?.errors,
                type: SAVE_CUSTOM_RATE_FAILURE,
            });
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
