import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteTemplate from './deleteTemplate';

export default (item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [
            i18next.t('The template "{{name}}" will be permanently deleted', {
                name: `${item.title}`,
            }),
        ],
        onSuccess: () => dispatch(deleteTemplate(item.id)),
        title: i18next.t('Delete template'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(alerts.actions.show(dataAlert));
};
