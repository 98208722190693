import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputSearch } from 'common/components';
import { Card, CardContent, Grid } from 'common/ui';

const BulkOperationsCard = ({ filterData, handleOnChangeSearch }) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid
                    alignItems="end"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <InputSearch
                            placeholder={t('Search')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    filterData: PropTypes.object,
    handleOnChangeSearch: PropTypes.func,
};

export default BulkOperationsCard;
