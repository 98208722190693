import update from 'immutability-helper';

import {
    DELETE_DATASHEET,
    DELETE_DATASHEET_FAILURE,
    DELETE_DATASHEET_SUCCESS,
    FETCH_PRODUCT_DATASHEET,
    FETCH_PRODUCT_DATASHEET_FAILURE,
    FETCH_PRODUCT_DATASHEET_SUCCESS,
    RESET_UPLOAD_MODEL,
    SHOW_FORM,
    UPLOAD_DATASHEET,
    UPLOAD_DATASHEET_FAILURE,
    UPLOAD_DATASHEET_SUCCESS,
    DOWNLOAD_DATASHEET,
    DOWNLOAD_DATASHEET_SUCCESS,
    DOWNLOAD_DATASHEET_FAILURE,
    RESET,
} from './actionTypes';

const INITIAL_STATE = {
    uploadDatasheet: {
        isLoading: false,
        errors: null,
    },
    productDatasheet: {
        isLoading: false,
        data: null,
        error: null,
    },
    deleteDatasheet: {
        isLoading: false,
        error: null,
    },
    isVisibleForm: false,
    downloadDatasheet: {
        isFetching: false,
        data: null,
        errors: [],
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case FETCH_PRODUCT_DATASHEET:
            return update(state, {
                productDatasheet: { isLoading: { $set: true } },
            });

        case FETCH_PRODUCT_DATASHEET_SUCCESS:
            return update(state, {
                productDatasheet: {
                    $merge: {
                        isLoading: false,
                        data: action.payload,
                        error: null,
                    },
                },
            });

        case FETCH_PRODUCT_DATASHEET_FAILURE:
            return update(state, {
                productDatasheet: {
                    $merge: { isLoading: false, error: action.payload },
                },
            });

        case RESET_UPLOAD_MODEL:
            return update(state, {
                uploadDatasheet: {
                    $merge: {
                        iisLoading: false,
                        errors: null,
                    },
                },
            });
        case UPLOAD_DATASHEET:
            return update(state, {
                uploadDatasheet: { isLoading: { $set: true } },
            });

        case UPLOAD_DATASHEET_SUCCESS:
            return update(state, {
                uploadDatasheet: { isLoading: { $set: false } },
            });

        case UPLOAD_DATASHEET_FAILURE:
            return update(state, {
                uploadDatasheet: {
                    $merge: {
                        isLoading: false,
                        errors: action.payload,
                    },
                },
            });
        case DELETE_DATASHEET:
            return update(state, {
                deleteDatasheet: { isLoading: { $set: true } },
            });

        case DELETE_DATASHEET_SUCCESS:
            return update(state, {
                deleteDatasheet: { isLoading: { $set: false } },
            });

        case DELETE_DATASHEET_FAILURE:
            return update(state, {
                deleteDatasheet: {
                    $merge: {
                        isLoading: false,
                        error: action.payload,
                    },
                },
            });

        case SHOW_FORM:
            return update(state, { isVisibleForm: { $set: action.payload } });

        case DOWNLOAD_DATASHEET:
            return update(state, {
                downloadDatasheet: { $merge: { isFetching: true, errors: [] } },
            });

        case DOWNLOAD_DATASHEET_SUCCESS:
            return update(state, {
                downloadDatasheet: {
                    $merge: { isFetching: false, data: action.payload },
                },
            });

        case DOWNLOAD_DATASHEET_FAILURE:
            return update(state, {
                downloadDatasheet: {
                    $merge: { isFetching: false, errors: action.payload },
                },
            });

        default:
            return state;
    }
}
