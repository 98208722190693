import createInverter from './createInverter';
import updateInverter from './updateInverter';

export default (filterData, values) => (dispatch) => {
    let newValues = {
        general: {
            brand: values.brand,
            family: values.family,
            type: values.product_type,
            name: values.name,
            code: values.code,
            cost: values.cost,
        },
        tags: {
            num_cells: values.num_cells,
            ancho: values.ancho,
            alto: values.alto,
            profundo: values.profundo,
            peso: values.peso,
            oversizing: values.oversizing,
            dc_max_p: values.dc_max_p,
            dc_max_v: values.dc_max_v,
            dc_max_i: values.dc_max_i,
            mppts: values.mppts,
            mppt_strings: values.mppt_strings,
            dc_mppt_min_v: values.dc_mppt_min_v,
            dc_mppt_max_v: values.dc_mppt_max_v,
            dc_mppt_max_i: values.dc_mppt_max_i,
            dc_mppt_max_string_i: values.dc_mppt_max_string_i,
            ac_max_p: values.ac_max_p,
            ac_nominal_v: values.ac_nominal_v,
            ac_min_v: values.ac_min_v,
            ac_max_v: values.ac_max_v,
            ac_nom_i: values.ac_nom_i,
            fases: values.fases,
        },
        thumbnail: {
            image_thumbnail: values.image_thumbnail,
        },
        datasheet: {
            datasheet_archive: values.datasheet_archive,
            datasheet_name: values.datasheet_name,
        },
    };

    if (values.id) dispatch(updateInverter(filterData, newValues, values.id));
    else dispatch(createInverter(filterData, newValues));
};
