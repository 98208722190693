import React from 'react';

import PropTypes from 'prop-types';

import SelectTags from './SelectTags';

const ReduxSelectTag = ({ disabled, input, meta, options }) => {
    return (
        <SelectTags
            disabled={disabled}
            error={meta.error}
            onChange={input.onChange}
            options={options}
            value={input.value}
        />
    );
};

ReduxSelectTag.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    options: PropTypes.array,
};

export default ReduxSelectTag;
