import update from 'immutability-helper';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    DUPLICATE,
    DUPLICATE_FAILURE,
    DUPLICATE_SUCCESS,
    FETCH_BRANDS,
    FETCH_BRANDS_FAILURE,
    FETCH_BRANDS_SUCCESS,
    FETCH_FAMILIES,
    FETCH_FAMILIES_FAILURE,
    FETCH_FAMILIES_SUCCESS,
    FETCH_PANELS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
    FETCH_PANEL_DETAIL,
    FETCH_PANEL_DETAIL_FAILURE,
    FETCH_PANEL_DETAIL_SUCCESS,
    INITIALIZE_FORM,
    OPEN_PRODUCT_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_ENABLED_EDITION,
    SET_IS_NEW_RECORD,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    bulkUpdateItems: {
        errors: null,
        isFetching: false,
    },
    duplicateProduct: {
        errors: null,
        isDuplicating: false,
    },
    initialValues: {
        alto: '',
        ancho: '',
        baseproduct_ptr: null,
        brand: null,
        code: null,
        cost: null,
        created_at: null,
        datasheet_archive: null,
        datasheet_name: '',
        default_margin: null,
        description: null,
        family: null,
        id: null,
        image_thumbnail: null,
        imp: '',
        is_active: false,
        isc: '',
        max_dc: '',
        max_p: '',
        module_e: '',
        name: null,
        noct: '',
        num_cells: '',
        peso: '',
        product_type: null,
        profundo: '',
        tk_isc: '',
        tk_pmax: '',
        tk_voc: '',
        type: null,
        updated_at: null,
        vmp: '',
        voc: '',
    },
    panels: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    panelDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    fetchBrands: {
        isFetching: false,
        data: [],
        error: null,
    },
    fetchFamilies: {
        isFetching: false,
        data: [],
        error: null,
    },
    isNewRecord: false,
    isEnabledEdition: true,
    isOpenProductForm: false,
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    productStatus: {
        isSaving: false,
        data: {},
        errors: null,
    },
    filters: {
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case BULK_UPDATE_ITEMS:
            return update(state, {
                bulkUpdateItems: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case BULK_UPDATE_ITEMS_SUCCESS:
            return update(state, {
                bulkUpdateItems: {
                    $merge: {
                        isFetching: false,
                    },
                },
            });

        case BULK_UPDATE_ITEMS_FAILURE:
            return update(state, {
                bulkUpdateItems: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case DUPLICATE:
            return update(state, {
                duplicateProduct: {
                    $merge: {
                        isDuplicating: true,
                        errors: [],
                    },
                },
            });

        case DUPLICATE_SUCCESS:
            return update(state, {
                duplicateProduct: {
                    $merge: {
                        isDuplicating: false,
                    },
                },
            });

        case DUPLICATE_FAILURE:
            return update(state, {
                duplicateProduct: {
                    $merge: {
                        isDuplicating: false,
                        errors: action.payload,
                    },
                },
            });
        case FETCH_PANELS:
            return update(state, {
                panels: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_PANELS_FAILURE:
            return update(state, {
                panels: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_PANELS_SUCCESS:
            return update(state, {
                panels: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_PANEL_DETAIL:
            return update(state, {
                panelDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_PANEL_DETAIL_FAILURE:
            return update(state, {
                panelDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_PANEL_DETAIL_SUCCESS:
            return update(state, {
                panelDetail: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_BRANDS:
            return update(state, {
                fetchBrands: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_BRANDS_SUCCESS:
            return update(state, {
                fetchBrands: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_BRANDS_FAILURE:
            return update(state, {
                fetchBrands: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_FAMILIES:
            return update(state, {
                fetchFamilies: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_FAMILIES_SUCCESS:
            return update(state, {
                fetchFamilies: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_FAMILIES_FAILURE:
            return update(state, {
                fetchFamilies: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
            });

        case OPEN_PRODUCT_FORM:
            return update(state, {
                isOpenProductForm: {
                    $set: action.payload,
                },
            });

        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case SET_ENABLED_EDITION:
            return update(state, {
                isEnabledEdition: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case UPDATE_ITEMS_SUCCESS:
            return update(state, {
                panels: {
                    data: {
                        data: {
                            $set: action.payload,
                        },
                    },
                },
            });

        default:
            return state;
    }
}
