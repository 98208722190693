import update from 'immutability-helper';

import {
    DELETE_API_ACCOUNT,
    DELETE_API_ACCOUNT_FAILURE,
    DELETE_API_ACCOUNT_SUCCESS,
    FETCH_API_ACCOUNTS,
    FETCH_API_ACCOUNTS_FAILURE,
    FETCH_API_ACCOUNTS_SUCCESS,
    FETCH_API_ACCOUNT_DETAIL,
    FETCH_API_ACCOUNT_DETAIL_FAILURE,
    FETCH_API_ACCOUNT_DETAIL_SUCCESS,
    INITIALIZE_FORM,
    OPEN_ITEM_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_ENABLED_EDITION,
    SET_FILTERS,
    SET_IS_NEW_RECORD,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        phone: null,
        email: null,
        password: null,
        rpus_counter: null,
        status: null,
    },
    apiAccounts: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    apiAccountDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    isNewRecord: false,
    isEnabledEdition: true,
    isOpenItemForm: false,
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    filters: {
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    deleteItem: {
        isDeleting: false,
        data: [],
        error: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_API_ACCOUNTS:
            return update(state, {
                apiAccounts: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_API_ACCOUNTS_FAILURE:
            return update(state, {
                apiAccounts: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_API_ACCOUNTS_SUCCESS:
            return update(state, {
                apiAccounts: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_API_ACCOUNT_DETAIL:
            return update(state, {
                apiAccountDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_API_ACCOUNT_DETAIL_FAILURE:
            return update(state, {
                apiAccountDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_API_ACCOUNT_DETAIL_SUCCESS:
            return update(state, {
                apiAccountDetail: { $merge: { errors: [], isFetching: false } },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
                deleteItem: {
                    $set: INITIAL_STATE.deleteItem,
                },
            });

        case OPEN_ITEM_FORM:
            return update(state, {
                isOpenItemForm: {
                    $set: action.payload,
                },
            });

        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case SET_ENABLED_EDITION:
            return update(state, {
                isEnabledEdition: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case DELETE_API_ACCOUNT:
            return update(state, {
                deleteItem: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_API_ACCOUNT_SUCCESS:
            return update(state, {
                deleteItem: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_API_ACCOUNT_FAILURE:
            return update(state, {
                deleteItem: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });
        default:
            return state;
    }
}
