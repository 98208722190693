import React from 'react';

import { useTheme } from '@mui/material';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import PlaceholderBreadcrumbs from 'common/components/placeholders/PlaceholderBreadcrumbs';
import { SvgIcon, Breadcrumbs, Link, Typography } from 'common/ui';

const renderIcon = (icon = null) => {
    if (!icon) return null;

    return (
        <SvgIcon
            component={icon}
            color="inherit"
            fontSize="inherit"
            sx={{ mr: 0.5 }}
        />
    );
};

const renderBreadcrumb = ({ action = null, label, icon = null, link }, key) => {
    const theme = useTheme();
    const color =
        theme.palette.mode === 'light' ? 'secondary' : 'secondary.contrastText';

    return (
        <div key={key}>
            {link ? (
                <Link
                    component={RouterLink}
                    to={link}
                    underline="hover"
                    color={color}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    {renderIcon(icon)}
                    {label}
                </Link>
            ) : (
                <Typography
                    color={color}
                    onClick={action ? action : () => {}}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: action ? 'pointer' : 'normal',
                        '&:hover': {
                            textDecoration: action ? 'underline' : 'none',
                        },
                    }}
                >
                    {renderIcon(icon)}
                    {label}
                </Typography>
            )}
        </div>
    );
};

export default withRouter(
    ({ current = null, isFetchingData = false, locations = [] }) => (
        <PlaceholderBreadcrumbs ready={isFetchingData}>
            <Breadcrumbs maxItems={3}>
                {locations.map((location, index) =>
                    renderBreadcrumb(
                        location,
                        `breadcrumb-${index}-${Math.random()}`
                    )
                )}
                {current && (
                    <Typography
                        color="primary"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        {renderIcon(current?.icon)}
                        {current.label}
                    </Typography>
                )}
            </Breadcrumbs>
        </PlaceholderBreadcrumbs>
    )
);
