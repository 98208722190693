import { styled } from '@mui/material/styles';

import { Box } from 'common/ui';
import { scroll } from 'common/utils/mixins';

export default styled(Box)`
    display: flex;
    flex-direction: column;
    flexgrow: 1;
    gap: 1rem;
    height: 100%;
    margin-bottom: 1rem;
    overflow-y: scroll;
    padding: 8px;
    white-space: pre-line;
    width: 100%;
    ${scroll.custom}
`;
