import React from 'react';

import { Tooltip, Typography } from 'common/ui';

const FieldMailFormatter = (cell) => {
    return (
        <Tooltip title={cell ? cell : '- - -'}>
            <Typography
                noWrap
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    width: 'calc(100% - 16px)',
                }}
                variant="subtitle2"
            >
                {cell ? cell : '- - -'}
            </Typography>
        </Tooltip>
    );
};

FieldMailFormatter.propTypes = {};

export default FieldMailFormatter;
