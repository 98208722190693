import React, { useCallback, useState } from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import i18next from 'i18next';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { MAX_FILE_SIZE } from 'common/constants';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Tooltip,
} from 'common/ui';
import {
    getFileNameByPath,
    isImageBase64,
    isImageUrl,
} from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

const ReduxFieldFile = ({
    accept = '*',
    contentEndAdornment,
    disabled = false,
    endAdornmentIcon = <FileUploadIcon />,
    endAdornmentTooltip = i18next.t('File upload'),
    fullWidth = false,
    handleClickButtonEndAdornment,
    id,
    input,
    label = i18next.t('File', { context: 'one' }),
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit'
    ),
    meta: { touched, error },
    placeholder = i18next.t('File upload'),
    text = i18next.t('Change image'),
    variant,
    visible = true,
}) => {
    if (!visible) {
        return null;
    }
    const [errorSize, setErrorSize] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            showToast({
                body: maxSizeErrorMessage,
                type: 'error',
                autoClose: 3000,
            });
            setErrorSize(maxSizeErrorMessage);
            return;
        }

        setErrorSize(null);

        input.onChange(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    }, []);

    const propId = id ? id : 'inputGroup' + Math.random();
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Box
            {...getRootProps()}
            sx={{
                textAlign: 'center',
            }}
        >
            <input
                {...getInputProps()}
                disabled={disabled}
                id={propId}
                accept={accept}
            />
            <FormControl
                fullWidth={fullWidth}
                error={Boolean(touched && error)}
            >
                {label && variant !== 'profile' && (
                    <InputLabel htmlFor={propId}>{label}</InputLabel>
                )}

                {variant !== 'profile' ? (
                    <OutlinedInput
                        disabled={disabled}
                        endAdornment={
                            <InputAdornment position="end">
                                {contentEndAdornment ? (
                                    contentEndAdornment
                                ) : (
                                    <Tooltip
                                        title={endAdornmentTooltip}
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={(e) => {
                                                if (
                                                    handleClickButtonEndAdornment
                                                ) {
                                                    e.stopPropagation();
                                                    handleClickButtonEndAdornment();
                                                }
                                            }}
                                            edge="end"
                                        >
                                            {endAdornmentIcon}
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </InputAdornment>
                        }
                        id={propId}
                        label={label}
                        readOnly
                        value={
                            isString(input.value) &&
                            !isImageBase64(input.value) &&
                            !isImageUrl(input.value)
                                ? input.value
                                : isArray(input.value)
                                ? input.value[0].path
                                : getFileNameByPath(input.value).length > 0
                                ? getFileNameByPath(input.value)
                                : placeholder
                        }
                    />
                ) : (
                    <Button
                        disabled={disabled}
                        height="48px"
                        size="large"
                        type="button"
                        variant="outlined"
                    >
                        {text}
                    </Button>
                )}

                {((touched && error) || errorSize !== null) && (
                    <FormHelperText className="Mui-error">
                        {error}
                        <br />
                        {errorSize}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    );
};

ReduxFieldFile.propTypes = {
    accept: PropTypes.string,
    contentEndAdornment: PropTypes.string,
    disabled: PropTypes.bool,
    endAdornmentIcon: PropTypes.node,
    endAdornmentTooltip: PropTypes.string,
    fullWidth: PropTypes.bool,
    handleClickButtonEndAdornment: PropTypes.func,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    text: PropTypes.string,
    variant: PropTypes.string,
    visible: PropTypes.bool,
};

export default ReduxFieldFile;
