import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomFieldEdit from 'common/components/products/CustomFieldEdit';
import { useBreakpoint } from 'common/hooks';
import { Link, Typography } from 'common/ui';
import {
    accesoryTypeToLabel,
    batteryTypeToLabel,
    inverterTypeToLabel,
    panelTypeToLabel,
} from 'common/utils/helpers';

import ProductActionsFormatter from './ProductActionsFormatter';

const ProductColumns = ({
    filterData,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickUpdate,
    productKey,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const maxPowerLabel = () => {
        switch (productKey) {
            case 'batteries':
                return 'kWh';
            case 'inverters':
                return 'kW';
            default:
                return 'W';
        }
    };

    const nameColumn = [
        {
            align: 'left',
            editable: true,
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Link
                        color="secondary"
                        component="button"
                        onClick={() => handleClickUpdate(row)}
                        underline="hover"
                        sx={{ textAlign: 'left' }}
                    >
                        <Typography fontWeight="bold" variant="caption">
                            {row.name}
                        </Typography>
                    </Link>
                );
            },
            renderEditCell: (params) => {
                return (
                    <CustomFieldEdit
                        rowModesModel={rowModesModel}
                        setRowModesModel={setRowModesModel}
                        {...params}
                    />
                );
            },
        },
    ];

    let columns = [
        {
            align: 'left',
            field: 'brand_name',
            flex: 1,
            headerName: t('Brand', { count: 1 }),
            valueGetter: (params) => params.row?.brand?.name,
        },
        {
            align: 'left',
            editable: true,
            field: 'code',
            flex: 1,
            headerName: t('Code'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography fontWeight="bold" variant="caption">
                        {row.code}
                    </Typography>
                );
            },
            renderEditCell: (params) => {
                return (
                    <CustomFieldEdit
                        rowModesModel={rowModesModel}
                        setRowModesModel={setRowModesModel}
                        {...params}
                    />
                );
            },
        },
        {
            align: 'left',
            field: 'product_type',
            headerName: t('Type'),
            renderCell: (params) => {
                const { row } = params;
                switch (productKey) {
                    case 'accessories':
                        return accesoryTypeToLabel(row?.product_type);
                    case 'batteries':
                        return batteryTypeToLabel(row.product_type);
                    case 'inverters':
                        return inverterTypeToLabel(row.product_type);
                    case 'panels':
                        return panelTypeToLabel(row.product_type);
                    default:
                        return '- - -';
                }
            },
            width: 140,
        },
        ...nameColumn,
        {
            field: 'max_power',
            flex: 1,
            headerName: maxPowerLabel(),
            hide: productKey === 'accessories',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.max_power} {maxPowerLabel()}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'created_at',
            headerName: t('Discharge date'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">{row.created_at}</Typography>
                );
            },
            width: 180,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = [
            {
                align: 'left',
                editable: true,
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <Link
                            color="secondary"
                            component="button"
                            onClick={() => handleClickUpdate(row)}
                            underline="hover"
                            sx={{ textAlign: 'left' }}
                        >
                            <Typography fontWeight="bold" variant="caption">
                                {row.name}
                            </Typography>
                        </Link>
                    );
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ProductActionsFormatter
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDuplicate={handleClickDuplicate}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
            width: 100,
        },
    ];

    return columns;
};

ProductColumns.propTypes = {
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    productKey: PropTypes.string,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns;
