import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export const parseClipboardData = (data) => {
    if (isNil(data) || isEmpty(data)) return [];
    return data
        .trim()
        .split(/\r\n|\n|\r/)
        .map((row) => row.split('\t'));
};
