import i18next from 'i18next';

import { getFile } from 'common/api/ocr/mediaStorage';
import showToast from 'common/utils/showToast';

import { getNameFromPath } from '../helpers';

export default (fileName) =>
    getFile(fileName)
        .then((response) => fetch(response?.data?.url, { method: 'GET' }))
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', getNameFromPath(fileName));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(() =>
            showToast({
                autoClose: 3000,
                body: i18next.t('An error occurred while downloading the file'),
                type: 'error',
            })
        );
