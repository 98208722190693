import API from '..';
const ENTITY = 'templates/fonts';

export const getFonts = () => API.get(`/api/v1/${ENTITY}/`);

export const filterFonts = ({ orderBy, page = 1, perPage = 25, sortBy } = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            page: page,
            per_page: perPage,
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const createFont = (data) => API.post(`/api/v1/${ENTITY}/`, data);

export const updateFont = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/${id}/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const deleteFont = (id) => API.delete(`/api/v1/${ENTITY}/${id}/`);
