import API from '..';
const ENTITY = 'users';

export const changePassword = (data) =>
    API.post(`/api/v1/${ENTITY}/change-password/`, { ...data });
export const changePasswordProfile = (data) =>
    API.post(`/api/v1/${ENTITY}/change-password-profile/`, { ...data });
export const getFinancierGroup = () =>
    API.get(`/api/v1/${ENTITY}/financier-group/`);
export const restartPassword = (id) =>
    API.put(`/api/v1/${ENTITY}/restart-password/${id}`);
export const update = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
