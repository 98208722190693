import React, { useEffect } from 'react';

import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { CardContainer, ShowErrors } from 'common/components';
import { ReduxFieldInput, ReduxFieldFile } from 'common/components/form/redux';
import { PlaceholderProductDatasheet } from 'common/components/placeholders';
import { Box, Button, Stack } from 'common/ui';

import * as actions from './actions';
import ProductDatasheet from './components/ProductDatasheet';
import { ALLOWED_EXTENSIONS } from './constants';
import * as selectors from './selectors';

const Container = ({
    datasheet,
    handleClickDelete,
    handleClickDownload,
    handleCloseForm,
    isEnabledEdition,
    isFetchingDetail,
    isFetchingProductDetail,
    isVisibleForm,
    productId,
    reset,
    showForm,
    uploadDatasheetErrors,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    const renderContent = () => {
        if (isVisibleForm)
            return (
                <Stack spacing={3}>
                    <Field
                        disabled={!isEnabledEdition}
                        fullWidth
                        name="datasheet_name"
                        component={ReduxFieldInput}
                        placeholder={t('Name')}
                        variant="outlined"
                    />

                    <Field
                        disabled={!isEnabledEdition}
                        fullWidth
                        accept={ALLOWED_EXTENSIONS}
                        component={ReduxFieldFile}
                        label={t('Attached file')}
                        name="datasheet_archive"
                        maxSize={20000000}
                    />
                    <Box
                        hidden={
                            isEmpty(datasheet) ||
                            isNull(get(datasheet, 'data_sheet', null))
                        }
                        textAlign="right"
                    >
                        <Button
                            color="secondary"
                            fullWidth={false}
                            onClick={handleCloseForm}
                        >
                            {t('Cancel')}
                        </Button>
                    </Box>
                </Stack>
            );
        return (
            <PlaceholderProductDatasheet
                ready={isFetchingDetail || isFetchingProductDetail}
            >
                <ProductDatasheet
                    datasheet={datasheet}
                    handleClickDownload={handleClickDownload}
                    isEnabledEdition={isEnabledEdition}
                    prepareDelete={handleClickDelete}
                    productId={productId}
                    showForm={showForm}
                />
            </PlaceholderProductDatasheet>
        );
    };

    return (
        <CardContainer
            isCollapsable={true}
            title={t('Data sheet', { count: 1 })}
            loading={isFetchingDetail || isFetchingProductDetail}
            withDivider
            isOpen={true}
        >
            <>
                {renderContent()}
                <ShowErrors
                    errors={uploadDatasheetErrors}
                    pt={3}
                    severity="error"
                    variant="filled"
                />
            </>
        </CardContainer>
    );
};

Container.propTypes = {
    datasheet: PropTypes.object,
    handleClickDelete: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleCloseForm: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isFetchingProductDetail: PropTypes.bool,
    isVisibleForm: PropTypes.bool,
    productId: PropTypes.string,
    reset: PropTypes.func,
    showForm: PropTypes.func,
    uploadDatasheetErrors: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    datasheet: selectors.getDataProductDatasheet,
    uploadDatasheetErrors: selectors.getUploadDatasheetErrors,
    isFetchingProductDetail: selectors.getIsLoadingProductDatasheet,
    isUploadingDatasheet: selectors.getIsUploadingDatasheet,
    isVisibleForm: selectors.getIsVisibleForm,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickDelete: (id) => dispatch(actions.prepareDelete(id)),
    handleClickDownload: (datasheet, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheet, openLoading, closeLoading)
        ),
    handleCloseForm: () => dispatch(actions.prepareCloseForm()),
    reset: () => dispatch(actions.reset()),
    save: (values) => dispatch(actions.uploadFile(values)),
    showForm: (value) => dispatch(actions.showForm(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
