import isEmpty from 'lodash/isEmpty';

import fetchInverters from './fetchInverters';
import resetForm from './resetForm';
import toggleProductForm from './toggleProductForm';

export default (filterData = {}) =>
    (dispatch) => {
        if (!isEmpty(filterData)) dispatch(fetchInverters(filterData));
        dispatch(resetForm());
        dispatch(toggleProductForm(false));
    };
