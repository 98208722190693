import React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';

import { formatDate, isValidDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

const CustomDatePicker = ({
    disableFuture,
    disablePast,
    disabled,
    format,
    fullWidth = true,
    inputFormat = 'dd/MM/yyyy',
    label,
    maxDate,
    minDate,
    onChange,
    placeholder,
    readOnly,
    value,
    variant = 'outlined',
    views,
}) => {
    const onChangeAction = ({ date, inputFormat, onChange }) => {
        if (!isValidDate(date)) return;
        const formatedDate = formatDate(date, inputFormat);
        if (onChange) onChange(formatedDate);
    };

    return (
        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
            <DatePicker
                disableFuture={disableFuture}
                disablePast={disablePast}
                disabled={disabled}
                format={format}
                inputFormat={inputFormat}
                label={label}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(date) =>
                    onChangeAction({
                        date,
                        inputFormat,
                        onChange,
                    })
                }
                readOnly={readOnly}
                slotProps={{
                    textField: {
                        fullWidth,
                        placeholder,
                        variant,
                    },
                }}
                value={value ? parseDate(value, inputFormat) : null}
                views={views}
            />
        </LocalizationProvider>
    );
};

CustomDatePicker.defaultProps = {
    displayFormat: getDateFormatByLocale(),
};

CustomDatePicker.propTypes = {
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    format: PropTypes.string,
    fullWidth: PropTypes.bool,
    inputFormat: PropTypes.string,
    label: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    variant: PropTypes.string,
    views: PropTypes.array,
};

export default CustomDatePicker;
