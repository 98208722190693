import i18next from 'i18next';

import { getBureauCSVReport } from 'common/api/v1/bureauCredit';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_BUREAU_CSV_REPORT,
    DOWNLOAD_BUREAU_CSV_REPORT_FAILURE,
    DOWNLOAD_BUREAU_CSV_REPORT_SUCCESS,
} from '../actionTypes';

import downloadFile from './downloadFile';

export default (fileName) => (dispatch) => {
    dispatch({ type: DOWNLOAD_BUREAU_CSV_REPORT });

    getBureauCSVReport()
        .then((response) => {
            dispatch({
                type: DOWNLOAD_BUREAU_CSV_REPORT_SUCCESS,
                payload: response.data,
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            dispatch(downloadFile(fileName, url, 'csv'));
            showToast({ body: i18next.t('It was created successfully') });
        })
        .catch((error) => {
            dispatch({
                type: DOWNLOAD_BUREAU_CSV_REPORT_FAILURE,
                payload: error,
            });
        });
};
