import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import JSONViewer from 'common/components/JSONViewer';
import { Box } from 'common/ui';

import * as selectors from '../selectors';

const LogViewer = ({ log }) => (
    <Box height="500px" width="100%">
        <JSONViewer json={log} path="after-sales/logs" readOnly />
    </Box>
);

const mapStateToProps = createStructuredSelector({
    log: selectors.getReadLogStringified,
});

LogViewer.propTypes = {
    log: PropTypes.string,
};

export default connect(mapStateToProps)(LogViewer);
