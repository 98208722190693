import isArray from 'lodash/isArray';

import { updateThumbnail } from 'common/api/thumbnails';

import {
    UPLOAD_THUMBNAIL,
    UPLOAD_THUMBNAIL_FAILURE,
    UPLOAD_THUMBNAIL_SUCCESS,
} from '../actionTypes';

export default (values, productId) => (dispatch) =>
    new Promise((resolve) => {
        let data = new FormData();

        if (isArray(values.image_thumbnail)) {
            data.append(
                'image',
                values.image_thumbnail && values.image_thumbnail[0]
            );
        }

        dispatch({ type: UPLOAD_THUMBNAIL });

        updateThumbnail(data, productId)
            .then((response) => {
                dispatch({
                    type: UPLOAD_THUMBNAIL_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPLOAD_THUMBNAIL_FAILURE,
                    payload: error.response.data.errors,
                });
            })
            .finally(resolve);
    });
