import update from 'immutability-helper';

import {
    FETCH_INVERTERS,
    FETCH_INVERTERS_FAILURE,
    FETCH_INVERTERS_SUCCESS,
    FETCH_PANELS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
    FETCH_PLANS,
    FETCH_PLANS_FAILURE,
    FETCH_PLANS_SUCCESS,
    FETCH_PLAN_DETAIL,
    FETCH_PLAN_DETAIL_FAILURE,
    FETCH_PLAN_DETAIL_SUCCESS,
    FETCH_PLAN_HISTORY,
    FETCH_PLAN_HISTORY_FAILURE,
    FETCH_PLAN_HISTORY_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_FILTERS,
    SHOW_EDITION_FORM,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        custom_offer_template: '',
        name: null,
        validity: 30,
        installation_type: [],
        panel: null,
        inverter_brand: null,
        structure: null,
        workforce_and_electric_materials: [{}],
        accessories: [{}],
        additionals: [{}],
        branch_office: null,
        saving_percentage: 0,
        is_active: true,
        company_id: '',
        enabledEdition: false,
    },
    plans: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    fetchPanels: {
        isFetching: false,
        data: [],
        error: null,
    },
    fetchInverters: {
        isFetching: false,
        data: [],
        error: null,
    },
    filters: {
        id: '',
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    planDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    planHistory: {
        isFetching: false,
        data: [],
        errors: [],
    },
    isOpenEditionForm: false,
    saveItem: {
        isSaving: false,
        data: {},
        errors: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case FETCH_PLANS:
            return update(state, {
                plans: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_PLANS_FAILURE:
            return update(state, {
                plans: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_PLANS_SUCCESS:
            return update(state, {
                plans: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_PANELS:
            return update(state, {
                fetchPanels: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_PANELS_SUCCESS:
            return update(state, {
                fetchPanels: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_PANELS_FAILURE:
            return update(state, {
                fetchPanels: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_INVERTERS:
            return update(state, {
                fetchInverters: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_INVERTERS_SUCCESS:
            return update(state, {
                fetchInverters: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_INVERTERS_FAILURE:
            return update(state, {
                fetchInverters: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_PLAN_DETAIL:
            return update(state, {
                planDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_PLAN_DETAIL_FAILURE:
            return update(state, {
                planDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_PLAN_DETAIL_SUCCESS:
            return update(state, {
                planDetail: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_PLAN_HISTORY:
            return update(state, {
                planHistory: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_PLAN_HISTORY_FAILURE:
            return update(state, {
                planHistory: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_PLAN_HISTORY_SUCCESS:
            return update(state, {
                planHistory: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                saveItem: {
                    $set: INITIAL_STATE.saveItem,
                },
                fetchPanels: {
                    $set: { isFetching: false, data: [], error: null },
                },
                fetchInverters: {
                    $set: { isFetching: false, data: [], error: null },
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case SAVE_ITEM:
            return update(state, {
                saveItem: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_ITEM_SUCCESS:
            return update(state, {
                saveItem: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_ITEM_FAILURE:
            return update(state, {
                saveItem: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case SHOW_EDITION_FORM:
            return update(state, {
                isOpenEditionForm: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
