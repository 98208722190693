import { getCompanyBranchOfficesNoPaging } from 'common/api/companies';

import {
    FETCH_BRANCH_OFFICES,
    FETCH_BRANCH_OFFICES_FAILURE,
    FETCH_BRANCH_OFFICES_SUCCESS,
} from '../actionTypes';

export default (id) => (dispatch) => {
    dispatch({ type: FETCH_BRANCH_OFFICES });

    getCompanyBranchOfficesNoPaging(id)
        .then((response) => {
            dispatch({
                type: FETCH_BRANCH_OFFICES_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_BRANCH_OFFICES_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
