import isEmpty from 'lodash/isEmpty';
import { getFormValues } from 'redux-form';

import {
    INVALID_PASSWORD_CONFIRMATION,
    INVALID_PASSWORD_CONFIRMATION_LENGTH,
    VALID_PASSWORD_CONFIRMATION,
    VALID_PASSWORD_CONFIRMATION_LENGTH,
} from '../actionTypes';

const handleChangePasswordConfirmation = (event) => (dispatch, getState) => {
    const state = getState();
    const values = getFormValues('profile-security/form')(state);

    let password = '';

    if (!isEmpty(values)) {
        password = isEmpty(values.new_password) ? '' : values.new_password;
    }

    const password_confirmation = event.target.value;

    if (password_confirmation.length >= 8) {
        dispatch({ type: VALID_PASSWORD_CONFIRMATION_LENGTH });
    } else {
        dispatch({ type: INVALID_PASSWORD_CONFIRMATION_LENGTH });
    }

    if (password_confirmation === password) {
        dispatch({ type: VALID_PASSWORD_CONFIRMATION });
    } else {
        dispatch({ type: INVALID_PASSWORD_CONFIRMATION });
    }
};

export default handleChangePasswordConfirmation;
