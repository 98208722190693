import * as selectors from '../selectors';

import downloadFile from './downloadFile';

export default (selectionModel, callback) => (_, getState) => {
    const state = getState();
    const files = selectors.getFilesData(state);

    const filteredFiles = files.filter((file) =>
        selectionModel.includes(file?.id)
    );

    if (!Array.isArray(filteredFiles) || filteredFiles.length === 0) {
        if (callback) callback();
        return;
    }

    filteredFiles.forEach((data) => downloadFile(data.file));

    if (callback) callback();
};
