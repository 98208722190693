import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModal from './toggleModal';

export default (item) => (dispatch) => {
    dispatch(setIsNewRecord(true));
    dispatch(toggleModal(true));
    dispatch(resetForm());
    dispatch(
        initializeForm({
            id: item.id,
            brand: item.brand,
        })
    );
};
