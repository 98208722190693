import { getRoles } from 'common/api/systemRoles';

import {
    FETCH_ROLES,
    FETCH_ROLES_FAILURE,
    FETCH_ROLES_SUCCESS,
} from '../actionTypes';
import { usersActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(usersActions[FETCH_ROLES]());

    getRoles()
        .then((response) =>
            dispatch(usersActions[FETCH_ROLES_SUCCESS](response?.data?.data))
        )
        .catch((error) =>
            dispatch(
                usersActions[FETCH_ROLES_FAILURE](error?.response?.data?.errors)
            )
        );
};
