import i18next from 'i18next';

import { COMPENSATION_SCHEMES } from 'common/constants/rates';

// Compensation schemes
export const getIsSelfConsumption = (value) =>
    ['SELFCON', 'SELFVB'].includes(value);

export const getIsCompatibleCompensationScheme = ({
    certified,
    name,
    paymentType,
    scheme,
}) =>
    (certified && scheme?.certifiedRates?.includes(name)) ||
    (!certified && scheme?.customRates?.includes(paymentType));

export const getCompatibleCompensationSchemes = ({
    certified,
    name,
    paymentType,
}) => {
    return COMPENSATION_SCHEMES.map((scheme) => ({
        disabled: !getIsCompatibleCompensationScheme({
            certified,
            name,
            paymentType,
            scheme,
        }),
        label: i18next.t(scheme.label),
        value: scheme.value,
    }));
};

export const getCompensationSchemeByBackendKey = ({
    backendKey,
    certified,
    name,
    paymentType,
}) => {
    if (!backendKey) return {};

    return COMPENSATION_SCHEMES.find(
        (scheme) =>
            scheme?.backendKey === backendKey &&
            getIsCompatibleCompensationScheme({
                certified,
                name,
                paymentType,
                scheme,
            })
    );
};

export const getCompensationSchemeByValue = (value) => {
    if (!value) return {};
    return COMPENSATION_SCHEMES.find((scheme) => scheme.value === value);
};
