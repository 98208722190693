import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { HeaderContainer, TitleIcon, TabsSystem } from 'common/components';
import { Container, Typography } from 'common/ui';

import { ReactComponent as IcoRates } from 'resources/icoParameters.svg';

import apiAccountsConfiguration from '../apiAccountsConfiguration';
import ratesConfiguration from '../ratesConfiguration';
import suppliersConfiguration from '../suppliersConfiguration';

import { HeaderBreadcrumbs } from './components';
import { RATES, SUPPLIERS, ACCOUNTS } from './constants';

const getTabs = (t, setCurrentItem) => [
    {
        id: RATES,
        label: t('Certified rate', { count: 2 }),
        content: (
            <ratesConfiguration.Container setCurrentItem={setCurrentItem} />
        ),
    },
    {
        id: SUPPLIERS,
        label: t('Supplier', { count: 2 }),
        content: (
            <suppliersConfiguration.Container setCurrentItem={setCurrentItem} />
        ),
    },
    {
        id: ACCOUNTS,
        label: t('API accounts'),
        content: (
            <apiAccountsConfiguration.Container
                setCurrentItem={setCurrentItem}
            />
        ),
    },
];

const ParametersContainer = ({
    match,
    prepareReturnCertifiedRatesCatalog,
    prepareReturnSuppliersCatalog,
    prepareReturnApiAccountsCatalog,
}) => {
    const { t } = useTranslation();
    const [currentTabId, setCurrentTabId] = useState(RATES);
    const [currentTabLabel, setCurrentTabLabel] = useState(
        t('Certified rate', { count: 2 })
    );
    const [currentItem, setCurrentItem] = useState({});

    const getLocations = () => {
        if (!isEmpty(currentItem)) {
            return [
                {
                    label: t('Parameter', { count: 2 }),
                    link: `/parameters/${
                        match.params.tab ? match.params.tab : RATES
                    }`,
                },
                {
                    label: currentTabLabel,
                    action: () => {
                        setCurrentItem({});

                        switch (currentTabId) {
                            case SUPPLIERS:
                                return prepareReturnSuppliersCatalog();
                            case ACCOUNTS:
                                return prepareReturnApiAccountsCatalog();
                            default:
                                return prepareReturnCertifiedRatesCatalog();
                        }
                    },
                },
            ];
        }
        return [];
    };

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoRates}
                        title={t('Parameter', { count: 2 })}
                    />
                </Typography>
            </HeaderContainer>

            <HeaderBreadcrumbs
                currentTabLabel={currentItem?.name}
                locationsBreadcrumbs={getLocations()}
                titleIcon={IcoRates}
                title={currentTabLabel}
            />

            <TabsSystem
                baseUrl="parameters"
                tabs={getTabs(t, setCurrentItem)}
                setCurrentTabId={setCurrentTabId}
                setCurrentTabLabel={setCurrentTabLabel}
            />
        </Container>
    );
};
ParametersContainer.propTypes = {
    match: PropTypes.object,
    prepareReturnCertifiedRatesCatalog: PropTypes.func,
    prepareReturnSuppliersCatalog: PropTypes.func,
    prepareReturnApiAccountsCatalog: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    prepareReturnCertifiedRatesCatalog: () =>
        dispatch(ratesConfiguration.actions.returnCatalog()),
    prepareReturnSuppliersCatalog: () =>
        dispatch(suppliersConfiguration.actions.returnCatalog()),
    prepareReturnApiAccountsCatalog: () =>
        dispatch(apiAccountsConfiguration.actions.returnCatalog()),
});

export default connect(
    null,
    mapDispatchToProps
)(withRouter(ParametersContainer));
