import React from 'react';

import PropTypes from 'prop-types';

import { Link, Typography } from 'common/ui';

const NameFormatter = ({ row }) => {
    return (
        <Link
            color="secondary"
            href={`/company-file/${row.id}`}
            underline="hover"
            sx={{ textAlign: 'left' }}
        >
            <Typography fontWeight="bold" variant="caption">
                {row?.business_name}
            </Typography>
        </Link>
    );
};

NameFormatter.propTypes = {
    row: PropTypes.object,
};

export default NameFormatter;
