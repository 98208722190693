import React, { useEffect } from 'react';

import FeedIcon from '@mui/icons-material/Feed';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Breadcrumbs, HeaderContainer, TitleIcon } from 'common/components';
import * as afterSalesProvidersActions from 'common/modules/afterSalesProviders/actions';
import ProviderImage from 'common/modules/afterSalesProviders/components/ProviderImage';
import { Container, Stack, Typography } from 'common/ui';

import * as actions from './actions';
import ListItems from './components/ListItems';
import LogModal from './components/LogModal';
import StatusIndicator from './components/StatusIndicator';
import * as selectors from './selectors';

const CompaniesContainer = ({
    fetchProviders,
    isLoading,
    projectData,
    reset,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchProviders();

        return () => reset();
    }, []);

    const locationsBreadcrumbs = [
        {
            label: t('Power station', { count: 2 }),
            link: '/after-sales-power-stations',
        },
    ];

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">{t('After sales')}</Typography>
            </HeaderContainer>

            <Stack direction="row" justifyContent="space-between" mb={3} ml={3}>
                <Stack>
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={FeedIcon}
                        title={t('Read', { count: 2 })}
                    />

                    <Breadcrumbs
                        current={{ label: projectData?.name || '' }}
                        isFetchingData={isLoading}
                        locations={locationsBreadcrumbs}
                    />
                </Stack>

                <Stack direction="row" spacing={1} alignItems="flex-end">
                    <StatusIndicator status={projectData?.status} />

                    <ProviderImage
                        providerId={projectData?.provider}
                        style={{ maxWidth: '80px', maxHeight: '30px' }}
                    />
                </Stack>
            </Stack>

            <ListItems />

            <LogModal />
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectors.getReadsIsLoading,
    projectData: selectors.getReadsProject,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProviders: () => dispatch(afterSalesProvidersActions.fetchProviders()),
    reset: () => dispatch(actions.reset()),
});

CompaniesContainer.propTypes = {
    fetchProviders: PropTypes.func,
    isLoading: PropTypes.bool,
    projectData: PropTypes.object,
    reset: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesContainer);
