import React from 'react';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Box, CardMedia, Checkbox, Stack, Typography } from 'common/ui';

import TemplatePlaceholder from 'resources/placeholders/placeholder_template.svg';

import * as outstandingTemplateActions from '../../outstandingTemplateForm/actions';
import * as actions from '../actions';
import { getFeaturedTimeText } from '../helpers';

import { LanguageFormatter } from './formatters';

const StyledThumbnail = styled(CardMedia)(({ theme }) => ({
    position: 'relative',
    minHeight: 180,
    minWidth: 110,
    backgroundSize: 'contain',
    padding: '0.25rem',
    backgroundColor: '#EEF4FA',
    border: '1px solid #e8e8e8',
    borderRadius: '0.55rem',
    [theme.breakpoints.down('xl')]: {
        minHeight: 180,
        minWidth: 110,
    },
    [theme.breakpoints.down('lg')]: {
        minHeight: 109,
        minWidth: 70,
    },
}));

const TemplatesPreviewTable = ({
    handleClickOutstandingCreate,
    handleClickOutstandingUpdate,
    handleClickUpdate,
    handleSortable,
    isFetching,
    items,
    orderdBy,
    pagination,
    setCurrentItem,
    sortedBy,
}) => {
    const { t } = useTranslation();

    const ThumbnailFormatter = (cell, row) => (
        <Box
            component="label"
            sx={{
                textAlign: 'center',
            }}
        >
            <StyledThumbnail
                image={
                    !isNil(row.image_thumbnail)
                        ? row.image_thumbnail
                        : TemplatePlaceholder
                }
                alt="Template thumbnail or placeholder"
            />
        </Box>
    );

    const CountryFormatter = (cell) => (
        <Typography
            variant="subtitle2"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            title={cell}
        >
            {cell ? cell : t('Global')}
        </Typography>
    );

    const ActionsFormatter = (cell, row) => (
        <Stack direction="row" justifyContent="center" alignItems="center">
            <Checkbox
                icon={<StarBorderIcon />}
                checkedIcon={<StarIcon color="primary" />}
                checked={row.is_outstanding}
                onChange={() => {
                    if (row.is_outstanding) {
                        handleClickOutstandingUpdate(row);
                    } else {
                        handleClickOutstandingCreate(row);
                    }
                }}
                onClick={(event) => event.stopPropagation()}
            />
        </Stack>
    );

    const FeaturedTimeFormatter = (cell, row) => (
        <Typography
            variant="subtitle2"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            title={getFeaturedTimeText(row)}
        >
            {getFeaturedTimeText(row)}
        </Typography>
    );

    const columns = [
        {
            dataField: 'thumbnail',
            formatter: ThumbnailFormatter,
            label: t('Thumbnail'),
            width: '10%',
        },
        {
            dataField: 'title',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Name'),
            width: '10%',
        },
        {
            dataField: 'category',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Category', { count: 1 }),
            width: '10%',
        },
        {
            dataField: 'country',
            formatter: CountryFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Country'),
            width: '10%',
        },
        {
            dataField: 'language_name',
            formatter: LanguageFormatter,
            label: t('Language', { count: 1 }),
            width: '10%',
        },
        {
            dataField: 'created_at',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Creation date'),
            width: '10%',
        },
        {
            dataField: 'is_outstanding',
            handleSortable: handleSortable,
            sortable: true,
            label: `${t('Outstanding')}`,
            headerAlign: 'center',
            width: '10%',
            placeholder: ActionsFormatter,
        },
        {
            dataField: 'featured_time',
            formatter: FeaturedTimeFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Featured time'),
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={(item) => {
                setCurrentItem({
                    ...item,
                    name: item.title,
                });
                handleClickUpdate(item);
            }}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

TemplatesPreviewTable.propTypes = {
    handleClickOutstandingCreate: PropTypes.func,
    handleClickOutstandingUpdate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    orderdBy: PropTypes.string,
    pagination: PropTypes.object,
    setCurrentItem: PropTypes.func,
    sortedBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
    handleClickOutstandingCreate: (item) =>
        dispatch(outstandingTemplateActions.prepareCreate(item)),
    handleClickOutstandingUpdate: (item) =>
        dispatch(outstandingTemplateActions.prepareUpdate(item)),
});

export default connect(null, mapDispatchToProps)(TemplatesPreviewTable);
