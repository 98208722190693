import React, { useCallback } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { styled, useTheme } from '@mui/material/styles';
import i18next from 'i18next';
import { isArray, isEmpty, isString } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Avatar, Box, IconButton, Typography } from 'sunwise-ui';

import LabelError from 'common/components/LabelError';
import { MAX_FILE_SIZE } from 'common/constants';
import { isImageBase64, isImageUrl, stringAvatar } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

const AVATAR_SIZE = 96;

const FilenameText = styled(Typography)(
    ({ theme }) => `
    color: ${theme.palette.mode === 'dark' ? '#ffffff' : 'rgba(0, 0, 0, 0.35)'};
`
);

const WrapperField = styled(Box)(
    ({ theme }) => `
    align-items: center;
    background-color: ${
        theme.palette.mode === 'dark' ? '#3F444C80' : '#fafafa80'
    };
    border-color: #eeeeee;
    border-style: dashed;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
    &.Mui-error {
        border-color: red;
    }
`
);

const AvatarProfile = ({ avatarData }) => {
    if (!isEmpty(avatarData?.image))
        return (
            <Avatar
                alt={avatarData.name}
                src={
                    Array.isArray(avatarData.image)
                        ? avatarData.image[0].preview
                        : avatarData.image
                }
                sx={{
                    height: AVATAR_SIZE,
                    width: AVATAR_SIZE,
                }}
            />
        );
    if (!isEmpty(avatarData?.name))
        return (
            <Avatar
                {...(avatarData?.name ? stringAvatar(avatarData?.name) : {})}
                sx={{
                    fontSize: '16px',
                    height: AVATAR_SIZE,
                    width: AVATAR_SIZE,
                }}
            />
        );
    return null;
};

AvatarProfile.propTypes = {
    avatarData: PropTypes.object,
};

const ReactHookFormDragnDropFile = ({
    accept = '*',
    avatarData = null,
    control,
    disabled,
    formats = i18next.t('All text', { count: 2 }),
    id,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit'
    ),
    name,
    onChange,
    setValue,
    showFileName = true,
    text = i18next.t('Drag & drop some file here, or click to select file'),
    variant,
    visible,
}) => {
    if (!visible) return null;
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const onDrop = useCallback((acceptedFiles) => {
        if (!acceptedFiles.length) return;
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            showToast({
                body: maxSizeErrorMessage,
                type: 'danger',
                autoClose: 3000,
            });
            return;
        }

        setValue(
            name,
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );

        onChange ? onChange(acceptedFiles) : null;
    }, []);

    const propId = id ? id : 'inputGroup' + Math.random();
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const getFileName = (value) => {
        if (isString(value) && !isImageBase64(value) && !isImageUrl(value))
            return value;
        if (isArray(value) && value.length > 0) return value[0].path;
        return null;
    };

    const getTextBackground = () => {
        if (variant === 'image') {
            if (isDarkMode) return '#000';
            return '#fafafa';
        }
        return 'transparent';
    };

    const getZoneBackground = () => {
        if (variant === 'image' && Array.isArray(avatarData.image)) {
            return `url(${avatarData.image[0].preview})`;
        }
        if (variant === 'image' && isString(avatarData.image)) {
            return `url(${avatarData.image})`;
        }
        return 'transparent';
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value }, fieldState: { error } }) => (
                <Box mb={2}>
                    <WrapperField
                        {...getRootProps({
                            className: `dropzone ${
                                !value && Boolean(error) && 'Mui-error'
                            }`,
                        })}
                        disabled={disabled}
                        htmlFor={`contained-button-file-${propId}`}
                        id={`box-file-${propId}`}
                    >
                        <input
                            {...getInputProps()}
                            accept={accept}
                            disabled={disabled}
                            id={`contained-button-file-${propId}`}
                            type="file"
                            value={value?.name}
                        />
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            justifyContent="center"
                            p={1}
                            sx={{
                                background: getZoneBackground(),
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                width: '100%',
                            }}
                            textAlign="center"
                        >
                            {variant === 'profile' && avatarData && (
                                <AvatarProfile avatarData={avatarData} />
                            )}
                            <Typography
                                variant="caption"
                                fontWeight="bold"
                                sx={{
                                    background: getTextBackground(),
                                    p: '0 8px',
                                }}
                            >
                                {text}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    background: getTextBackground(),
                                    p: '0 8px',
                                }}
                            >
                                {i18next.t('Format {{format}}', {
                                    format: formats,
                                })}{' '}
                                -{' '}
                                {i18next.t('Maximum weight {{maxSize}}MB', {
                                    maxSize: Math.floor(maxSize / 1e6),
                                })}
                            </Typography>
                        </Box>
                    </WrapperField>
                    {showFileName && getFileName(value) && (
                        <Box alignItems="center" display="flex" gap={1}>
                            <FilenameText variant="caption" fontWeight="bold">
                                {i18next.t('File')}: {getFileName(value)}
                            </FilenameText>
                            <IconButton onClick={() => setValue(name, null)}>
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                    {!value && Boolean(error) && (
                        <LabelError fontSize="0.75rem" type="error">
                            {error?.message}
                        </LabelError>
                    )}
                </Box>
            )}
        />
    );
};

ReactHookFormDragnDropFile.defaultProps = {
    disabled: false,
    visible: true,
};

ReactHookFormDragnDropFile.propTypes = {
    accept: PropTypes.string,
    avatarData: PropTypes.object,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    formats: PropTypes.string,
    id: PropTypes.string,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    setValue: PropTypes.func,
    showFileName: PropTypes.bool,
    text: PropTypes.string,
    variant: PropTypes.string,
    visible: PropTypes.bool,
};

export default ReactHookFormDragnDropFile;
