import { object, string, number } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ REQUIRED_TEXT, NUMBER_TYPE_TEXT, MIN_ZERO_VALIDATION_TEXT }) =>
        object().shape({
            brand: string().required(REQUIRED_TEXT).nullable(),
            family: string().required(REQUIRED_TEXT).nullable(),
            product_type: string().required(REQUIRED_TEXT).nullable(),
            name: string().required(REQUIRED_TEXT).nullable(),
            code: string().required(REQUIRED_TEXT).nullable(),
            cost: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .nullable(),
            costing_unit: string().required(REQUIRED_TEXT).nullable(),
        })
);
