import styled from 'styled-components';

import { scroll } from 'common/utils/mixins';

export default styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: overlay;
    width: 100%;
    ${scroll.custom}
    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;
