import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { HeaderContainer, TitleIcon, TabsSystem } from 'common/components';
import { Container, Typography } from 'common/ui';

import { ReactComponent as IcoBrands } from 'resources/icoBrands.svg';
import { ReactComponent as IcoProducts } from 'resources/icoProducts.svg';

import brandsConfiguration from '../brandsConfiguration';
import * as brandsConfigurationActions from '../brandsConfiguration/actions';

import { HeaderBreadcrumbs } from './components';

const getTabs = (t, setCurrentItem) => [
    {
        id: 'brands',
        label: t('Brands catalog'),
        content: (
            <brandsConfiguration.Container setCurrentItem={setCurrentItem} />
        ),
    },
];

const BrandsCatalogContainer = ({ prepareReturnBrandsCatalog }) => {
    const { t } = useTranslation();
    const [currentTabLabel, setCurrentTabLabel] = useState(
        t('Brand', { count: 2 })
    );
    const [currentItem, setCurrentItem] = useState({});

    const getLocations = () => {
        if (!isEmpty(currentItem)) {
            return [
                {
                    label: t('Brands catalog'),
                    link: '/products/brands',
                },
                {
                    label: currentTabLabel,
                    action: () => {
                        setCurrentItem({});
                        prepareReturnBrandsCatalog();
                    },
                },
            ];
        }
        return [];
    };

    return (
        <Container maxWidth="" disableGutters>
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoProducts}
                        title={t('Product', { count: 2 })}
                    />
                </Typography>
            </HeaderContainer>

            <HeaderBreadcrumbs
                currentTabLabel={currentItem?.name}
                locationsBreadcrumbs={getLocations()}
                titleIcon={IcoBrands}
                title={t('Brands catalog')}
            />

            <TabsSystem
                baseUrl="products/brands"
                tabs={getTabs(t, setCurrentItem)}
                setCurrentTabLabel={setCurrentTabLabel}
            />
        </Container>
    );
};

BrandsCatalogContainer.propTypes = {
    prepareReturnBrandsCatalog: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    prepareReturnBrandsCatalog: () =>
        dispatch(brandsConfigurationActions.returnCatalog()),
});

export default connect(null, mapDispatchToProps)(BrandsCatalogContainer);
