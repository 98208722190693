import { filterCompanies } from 'common/api/companies';

import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
} from '../actionTypes';
import { proposalsActions } from '../reducer';

export default ({ search = '' } = {}) =>
    (dispatch) => {
        dispatch(proposalsActions[FETCH_COMPANIES]());

        filterCompanies({
            orderBy: 'business_name',
            perPage: 10,
            searchText: search,
            sort: 'desc',
        })
            .then((response) =>
                dispatch(
                    proposalsActions[FETCH_COMPANIES_SUCCESS](
                        response.data.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    proposalsActions[FETCH_COMPANIES_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
