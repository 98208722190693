import { deleteTrainingData } from 'common/api/ocr/fineTuning';

import {
    DELETE_TRAINING_DATA,
    DELETE_TRAINING_DATA_SUCCESS,
    DELETE_TRAINING_DATA_FAILURE,
} from '../actionTypes';

import fetchModel from './fetchModel';

export default (id, modelId) => (dispatch) => {
    dispatch({ type: DELETE_TRAINING_DATA });

    return deleteTrainingData(id, modelId)
        .then(() => {
            dispatch({ type: DELETE_TRAINING_DATA_SUCCESS });
            dispatch(fetchModel(modelId));
        })
        .catch((err) =>
            dispatch({ type: DELETE_TRAINING_DATA_FAILURE, payload: err })
        );
};
