import { updatePlan } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_SUCCESS,
    SAVE_ITEM_FAILURE,
} from '../actionTypes';
import * as selectors from '../selectors';

import fetchPlans from './fetchPlans';
import resetForm from './resetForm';
import showEditionForm from './showEditionForm';

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    let newValues = {
        name: values.name,
        panel: values.panel,
        inverter_brand: values.inverter_brand,
    };

    dispatch({ type: SAVE_ITEM });

    updatePlan(values.company_id, values.id, newValues)
        .then((response) => {
            dispatch({ type: SAVE_ITEM_SUCCESS, payload: response.data });
            dispatch(
                fetchPlans(
                    filters.id,
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(showEditionForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) =>
            dispatch({
                type: SAVE_ITEM_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
