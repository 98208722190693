import * as selectors from '../selectors';

import fetchTemplates from './fetchTemplates';
import resetForm from './resetForm';
import toggleTemplateForm from './toggleTemplateForm';

export default () => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    dispatch(fetchTemplates(filters.page));
    dispatch(resetForm());
    dispatch(toggleTemplateForm(false));
};
