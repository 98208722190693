import React, { useRef, useState, useEffect, Suspense } from 'react';

import { CssBaseline } from '@mui/material';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import LoadingProcessView from 'common/modules/loadingView/ProcessView';
import 'react-toastify/dist/ReactToastify.css';
import breakpoints from 'common/theme/breakpoints';
import componentsOverride from 'common/theme/overrides';
import palette from 'common/theme/palette';
import shadows, { customShadows } from 'common/theme/shadows';
import shape from 'common/theme/shape';
import typography from 'common/theme/typography';
import { LoadingProvider, GeneralProvider } from 'common/utils/contexts';
import showToast from 'common/utils/showToast';

import main from './modules/main';
import * as profileActions from './modules/profile/actions';
import * as profileSelectors from './modules/profile/selectors';
import {
    components as themeDarkComponents,
    palette as themeDarkPalette,
} from './theme-dark';

const buildConnectionToast = (offlineToast, setOfflineToast, t) => (evt) => {
    if (evt.type === 'online') {
        if (offlineToast && toast.isActive(offlineToast)) {
            toast.dismiss(offlineToast);
        }
        setTimeout(() => {
            showToast({
                title: t('On-line'),
                body: t(
                    'Now you can save your changes. Your connection has been reestablished'
                ),
                type: 'success',
                autoClose: 3000,
            });
        }, 300);
    } else {
        setOfflineToast(
            showToast({
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                title: t('Offline'),
                body: t(
                    'You will not be able to save changes until your connection is reestablished'
                ),
                type: 'error',
            })
        );
    }
};

const App = ({ history, selectedTheme, setTheme }) => {
    const defaultTheme = 'light';
    const { t } = useTranslation();
    const loadingProcessRef = useRef(null);
    const [providerValue, setProviderValue] = useState({});
    const [offlineToast, setOfflineToast] = useState(null);

    const themeOptions = {
        breakpoints,
        customShadows,
        palette: selectedTheme === 'light' ? palette : themeDarkPalette,
        shadows,
        shape,
        typography,
    };
    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    if (selectedTheme === 'dark')
        theme.components = { ...themeDarkComponents(theme).components };

    useEffect(() => {
        if (loadingProcessRef.current !== null) {
            const { openLoading, closeLoading } = loadingProcessRef.current;
            setProviderValue({ openLoading, closeLoading });
        }
    }, [loadingProcessRef]);

    useEffect(() => {
        const handlerToast = buildConnectionToast(
            offlineToast,
            setOfflineToast,
            t
        );
        window.addEventListener('offline', handlerToast);
        window.addEventListener('online', handlerToast);
        return () => {
            window.removeEventListener('offline', handlerToast);
            window.removeEventListener('online', handlerToast);
        };
    }, [offlineToast, setOfflineToast]);

    useEffect(() => {
        const storageTheme = localStorage.getItem('theme') || defaultTheme;
        setTheme(storageTheme);
    }, []);

    return (
        <Suspense fallback={null}>
            <LoadingProcessView ref={loadingProcessRef} />

            <Router history={history}>
                <LoadingProvider value={providerValue}>
                    <GeneralProvider value={{}}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <main.Container />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </GeneralProvider>
                </LoadingProvider>
                <ToastContainer />
            </Router>
        </Suspense>
    );
};

App.propTypes = {
    history: PropTypes.object,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    selectedTheme: profileSelectors.getTheme,
});

const mapDispatchToProps = (dispatch) => ({
    setTheme: (value) => dispatch(profileActions.setTheme(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    withRouter(App)
);
