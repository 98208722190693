import update from 'immutability-helper';

import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
    INITIALIZE,
    RESET,
    RESET_FORM,
    REWIND_PROJECT,
    REWIND_PROJECT_FAILURE,
    REWIND_PROJECT_SUCCESS,
    SET_OPEN_REWIND_MODAL,
    SET_OPEN_STATUS_MODAL,
    SET_PROJECT_STATUS,
    SET_PROJECT_STATUS_FAILURE,
    SET_PROJECT_STATUS_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    companies: { data: [], errors: null, isLoading: false },
    projects: {
        data: { projects: [], pagination: {} },
        errors: null,
        isLoading: false,
    },
    initialValues: {},
    rewindProject: { errors: null, isLoading: false },
    setProjectStatus: { errors: null, isLoading: false },
    isOpenRewindModal: false,
    isOpenStatusModal: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_COMPANIES:
            return update(state, {
                companies: { $merge: { errors: null, isLoading: true } },
            });

        case FETCH_COMPANIES_FAILURE:
            return update(state, {
                companies: {
                    $merge: { errors: action.payload, isLoading: false },
                },
            });

        case FETCH_COMPANIES_SUCCESS:
            return update(state, {
                companies: {
                    $merge: { data: action.payload, isLoading: false },
                },
            });

        case FETCH_PROJECTS:
            return update(state, {
                projects: { $merge: { errors: null, isLoading: true } },
            });

        case FETCH_PROJECTS_FAILURE:
            return update(state, {
                projects: {
                    $merge: { errors: action.payload, isLoading: false },
                },
            });

        case FETCH_PROJECTS_SUCCESS:
            return update(state, {
                projects: {
                    $merge: {
                        isLoading: false,
                        data: {
                            pagination: {
                                page: action.payload?.page,
                                pageSize: action.payload?.pageSize,
                                total: action.payload?.total,
                            },
                            projects: action.payload?.projects,
                        },
                    },
                },
            });

        case INITIALIZE:
            return update(state, {
                initialValues: { $merge: action.payload },
            });

        case RESET:
            return update(state, { $set: INITIAL_STATE });

        case RESET_FORM:
            return update(state, {
                initialValues: { $set: INITIAL_STATE.initialValues },
            });

        case REWIND_PROJECT:
            return update(state, {
                rewindProject: { $merge: { errors: null, isLoading: true } },
            });

        case REWIND_PROJECT_FAILURE:
            return update(state, {
                rewindProject: {
                    $merge: { errors: action.payload, isLoading: false },
                },
            });

        case REWIND_PROJECT_SUCCESS:
            return update(state, {
                rewindProject: { $merge: { isLoading: false } },
            });

        case SET_OPEN_REWIND_MODAL:
            return update(state, {
                isOpenRewindModal: { $set: action.payload },
            });

        case SET_OPEN_STATUS_MODAL:
            return update(state, {
                isOpenStatusModal: { $set: action.payload },
            });

        case SET_PROJECT_STATUS:
            return update(state, {
                setProjectStatus: { $merge: { errors: null, isLoading: true } },
            });

        case SET_PROJECT_STATUS_FAILURE:
            return update(state, {
                setProjectStatus: {
                    $merge: { errors: action.payload, isLoading: false },
                },
            });

        case SET_PROJECT_STATUS_SUCCESS:
            return update(state, {
                setProjectStatus: { $merge: { isLoading: false } },
            });

        default:
            return state;
    }
}
