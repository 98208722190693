import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { HiddenComponent } from 'common/components';
import { SearchTable } from 'common/components/tables';
import { Box, Button, IconButton, Toolbar, Tooltip } from 'common/ui';

const StyledContainer = styled(Toolbar)({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledIconButton = styled(IconButton)(({ theme, color }) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
    margin: '4px 6px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    width: '90%',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

const CategoriesPreviewTableToolbar = ({
    handleClickCreate,
    handleOnSearchChange,
    isFetching,
    searchStr,
    setCurrentItem,
}) => {
    const { t } = useTranslation();
    const handleOnClick = () => {
        setCurrentItem({
            name: t('New category'),
        });
        handleClickCreate();
    };

    return (
        <StyledContainer disableGutters>
            <StyledBox>
                <SearchTable
                    defaultValue={searchStr}
                    handleOnSearchChange={handleOnSearchChange}
                    placeholder={`${t('Search')}...`}
                    variant="filled"
                />
            </StyledBox>

            <HiddenComponent width="lgDown">
                <Button
                    disabled={isFetching}
                    fullWidth={false}
                    height="48px"
                    startIcon={<AddIcon />}
                    type="button"
                    onClick={handleOnClick}
                >
                    {t('Add')}
                </Button>
            </HiddenComponent>
            <HiddenComponent width="lgUp">
                <Tooltip title={t('Add')}>
                    <span>
                        <StyledIconButton
                            color="primary"
                            disabled={isFetching}
                            onClick={handleOnClick}
                        >
                            <AddIcon />
                        </StyledIconButton>
                    </span>
                </Tooltip>
            </HiddenComponent>
        </StyledContainer>
    );
};

CategoriesPreviewTableToolbar.propTypes = {
    handleClickCreate: PropTypes.func,
    handleOnSearchChange: PropTypes.func,
    isFetching: PropTypes.bool,
    searchStr: PropTypes.string,
    setCurrentItem: PropTypes.func,
};

export default CategoriesPreviewTableToolbar;
