import isEmpty from 'lodash/isEmpty';
import { array, boolean, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

const getTiersValidations = (validationTexts) => {
    const { minimumNumberCharacters, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        validationTexts;

    return object().shape({
        consumption: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT),
        tier: string()
            .typeError(REQUIRED_TEXT)
            .min(1, minimumNumberCharacters(1))
            .required(REQUIRED_TEXT),
    });
};

export default () => {
    const validationTexts = getValidationTexts();
    const { getMaxValueText, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        validationTexts;

    return object().shape({
        values: array().of(
            object().shape({
                completion: object().shape({
                    address: string()
                        .nullable()
                        .transform((value) => value || null),
                    applied_credit: number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .nullable(),
                    contracted_demand: array()
                        .of(getTiersValidations(validationTexts))
                        .nullable()
                        .transform((value) => (isEmpty(value) ? null : value)),
                    has_low_tension_concept: boolean()
                        .nullable()
                        .transform((value) => value || false),
                    history: array()
                        .of(
                            object().shape({
                                consumption: number()
                                    .typeError(NUMBER_TYPE_TEXT)
                                    .required(REQUIRED_TEXT),
                                demand: number()
                                    .typeError(NUMBER_TYPE_TEXT)
                                    .nullable(),
                                final_date: string().required(REQUIRED_TEXT),
                                initial_date: string().required(REQUIRED_TEXT),
                                fp: number()
                                    .typeError(NUMBER_TYPE_TEXT)
                                    .nullable(),
                            })
                        )
                        .max(12, getMaxValueText(12))
                        .nullable()
                        .transform((value) => (isEmpty(value) ? null : value)),
                    is_bimonthly: boolean()
                        .nullable()
                        .transform((value) =>
                            value || value === false ? value : null
                        ),
                    name: string()
                        .nullable()
                        .transform((value) => value || null),
                    rate: string()
                        .nullable()
                        .transform((value) => value || null),
                    service_number: string()
                        .nullable()
                        .transform((value) => value || null),
                    unit_prefix: string()
                        .nullable()
                        .transform((value) => value || null),
                    values: object()
                        .shape({
                            demand: array()
                                .of(getTiersValidations(validationTexts))
                                .nullable()
                                .transform((value) =>
                                    isEmpty(value) ? null : value
                                ),
                            energy: array()
                                .of(getTiersValidations(validationTexts))
                                .nullable()
                                .transform((value) =>
                                    isEmpty(value) ? null : value
                                ),
                            final_date: string().required(REQUIRED_TEXT),
                            fp: number()
                                .typeError(NUMBER_TYPE_TEXT)
                                .nullable()
                                .transform((value) => value || null),
                            initial_date: string().required(REQUIRED_TEXT),
                            season_change: boolean()
                                .nullable()
                                .transform((value) => value || false),
                            solar_generation: number()
                                .typeError(NUMBER_TYPE_TEXT)
                                .nullable()
                                .transform((value) => value || null),
                        })
                        .nullable()
                        .transform((value) => value || null),
                    zip_code: string()
                        .typeError(REQUIRED_TEXT)
                        .nullable()
                        .transform((value) => value || null),
                }),
            })
        ),
    });
};
