import API from '..';
const ENTITY = 'companies';

export const deleteRole = (id) => API.delete(`/api/v1/${ENTITY}/roles/${id}`);
export const filterRoles = ({
    company,
    orderBy = null,
    page = 1,
    perPage = 25,
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/roles/`, {
        params: {
            company,
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
        },
    });
export const getRoleDetails = (companyId, roleId) =>
    API.get(`/api/v1/${ENTITY}/roles/${roleId}/`, {
        params: { company: companyId },
    });
export const updateRole = (data, id) =>
    API.patch(
        `/api/v1/${ENTITY}/roles/${id}/`,
        { ...data },
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );
