import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Typography } from 'common/ui';

import * as actions from '../actions';

const CustomRatesPreviewTable = ({
    handleClickUpdate,
    handleSortable,
    isFetching,
    items,
    orderdBy,
    pagination,
    sortedBy,
}) => {
    const { t } = useTranslation();

    const RateTypeFormatter = (cell) => (
        <Typography
            variant="subtitle2"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            title={cell ? cell : '- - -'}
        >
            {cell ? cell : '- - -'}
        </Typography>
    );

    const VoltageTypeFormatter = (cell) => (
        <Typography
            variant="subtitle2"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            title={cell ? cell : '- - -'}
        >
            {cell ? cell : '- - -'}
        </Typography>
    );

    const columns = [
        {
            dataField: 'country',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            label: t('Country'),
            sortable: true,
            width: '10%',
        },
        {
            dataField: 'utility_supplier',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            label: t('Supplier', { count: 1 }),
            sortable: true,
            width: '10%',
        },
        {
            dataField: 'name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            label: t('Name'),
            sortable: true,
            width: '20%',
        },
        {
            dataField: 'business_type',
            formatter: RateTypeFormatter,
            handleSortable: handleSortable,
            label: t('Type of installation'),
            sortable: true,
            width: '10%',
        },
        {
            dataField: 'voltage_type',
            formatter: VoltageTypeFormatter,
            handleSortable: handleSortable,
            label: t('Voltage type'),
            sortable: true,
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={handleClickUpdate}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

CustomRatesPreviewTable.propTypes = {
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    orderdBy: PropTypes.string,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (item) =>
        dispatch(actions.prepareCustomRateUpdate(item)),
});

export default connect(null, mapDispatchToProps)(CustomRatesPreviewTable);
