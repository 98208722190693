import React, { useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';

import { Box, Skeleton } from 'common/ui';
import { isImageFileSupported } from 'common/utils/helpers';
import { scroll } from 'common/utils/mixins';

import FilePreviewControls from './FilePreviewControls';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FILE_X_MARGIN = 32;

const WrapperPage = styled(Box)`
    margin-bottom: 8px;
    & .react-pdf__Page__canvas {
        margin: 0 auto;
    }
`;

const FileWrapper = styled(Box)`
    height: calc(100% - 40px);
    overflow-y: scroll;
    width: 100%;
    ${scroll.custom}
`;

const FilePreview = ({
    disabled,
    file,
    handleOnChange,
    pageNumber = 1,
    paginated = false,
}) => {
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState(null);
    const [scalePage, setScalePage] = useState(1);
    const refFileWrapper = useRef(null);

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    const onPageLoad = (page) => {
        const scale =
            (refFileWrapper.current?.offsetWidth - FILE_X_MARGIN) /
            page.originalWidth;

        if (scale !== scalePage) setScalePage(scale);
    };

    const onImageLoad = (event) => {
        const width = event?.target?.naturalWidth;

        if (!width) return;
        const scale =
            (refFileWrapper.current?.offsetWidth - FILE_X_MARGIN) / width;

        if (scale !== scalePage) setScalePage(scale);
    };

    const handleOnZoomIn = () => setScalePage(scalePage * 1.1);

    const handleOnZoomOut = () => setScalePage(scalePage / 1.1);

    return (
        <>
            <FileWrapper ref={refFileWrapper}>
                {isImageFileSupported(file) ? (
                    <img
                        alt="File preview"
                        onLoad={onImageLoad}
                        src={file}
                        style={{
                            display: 'block',
                            margin: '0 auto',
                            maxWidth: 'none',
                            transformOrigin: 'top left',
                            zoom: scalePage,
                        }}
                    />
                ) : (
                    <Document
                        error={t('Failed to load PDF file')}
                        file={file}
                        loading={() => (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                            />
                        )}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {paginated ? (
                            <WrapperPage>
                                <Page
                                    onLoadSuccess={onPageLoad}
                                    pageNumber={pageNumber}
                                    scale={scalePage}
                                />
                            </WrapperPage>
                        ) : (
                            Array.from(new Array(numPages)).map((_, index) => (
                                <WrapperPage key={`page-${index}`}>
                                    <Page
                                        onLoadSuccess={onPageLoad}
                                        pageNumber={index + 1}
                                        scale={scalePage}
                                    />
                                </WrapperPage>
                            ))
                        )}
                    </Document>
                )}
            </FileWrapper>

            <FilePreviewControls
                disabled={disabled}
                handleOnChange={handleOnChange}
                handleOnZoomIn={handleOnZoomIn}
                handleOnZoomOut={handleOnZoomOut}
            />
        </>
    );
};

FilePreview.propTypes = {
    disabled: PropTypes.bool,
    file: PropTypes.string,
    handleOnChange: PropTypes.func,
    pageNumber: PropTypes.number,
    paginated: PropTypes.bool,
};

export default FilePreview;
