import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import TagsIcon from '@mui/icons-material/Discount';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HeaderContainer, TitleIcon } from 'common/components';
import { Button, Container, Typography } from 'common/ui';

import * as actions from './actions';
import TagModal from './components/TagModal';
import TagsTable from './components/TagsTable';
import * as selectors from './selectors';

const TrainingDataTagsContainer = ({
    fetchTags,
    initialValues,
    initializeForm,
    isFetchingTags,
    isNewTag,
    isOpenModal,
    isSavingTag,
    prepareDeleteTag,
    reset,
    resetForm,
    saveTag,
    setModalIsOpen,
    tags,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchTags();
        return () => reset();
    }, []);

    const prepareUpdateTag = (tag) => initializeForm(tag);
    const handleCloseModal = () => {
        setModalIsOpen(false);
        resetForm();
    };

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={TagsIcon}
                        title={t('Tags')}
                    />
                </Typography>

                <Button
                    onClick={() => initializeForm()}
                    fullWidth={false}
                    startIcon={<AddIcon />}
                >
                    {t('Add')}
                </Button>
            </HeaderContainer>

            <TagsTable
                isFetchingTags={isFetchingTags}
                items={tags}
                prepareDeleteTag={prepareDeleteTag}
                prepareUpdateTag={prepareUpdateTag}
            />

            <TagModal
                disabled={isFetchingTags || isSavingTag}
                handleCloseModal={handleCloseModal}
                initialValues={initialValues}
                isNewTag={isNewTag}
                isOpenModal={isOpenModal}
                onSubmit={saveTag}
            />
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetchingTags: selectors.getIsFetchingTags,
    isNewTag: selectors.getIsNewTag,
    isOpenModal: selectors.getIsOpenModal,
    isSavingTag: selectors.getIsSavingTag,
    tags: selectors.getFetchTagsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTags: () => dispatch(actions.fetchTags()),
    initializeForm: (tag) => dispatch(actions.initializeForm(tag)),
    prepareDeleteTag: (id) => dispatch(actions.prepareDeleteTag(id)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
    saveTag: (values) => dispatch(actions.saveTag(values)),
    setModalIsOpen: (isOpen) => dispatch(actions.setModalIsOpen(isOpen)),
});

TrainingDataTagsContainer.propTypes = {
    fetchTags: PropTypes.func,
    initialValues: PropTypes.object,
    initializeForm: PropTypes.func,
    isFetchingTags: PropTypes.bool,
    isNewTag: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSavingTag: PropTypes.bool,
    prepareDeleteTag: PropTypes.func,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    saveTag: PropTypes.func,
    setModalIsOpen: PropTypes.func,
    tags: PropTypes.array,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TrainingDataTagsContainer);
