import i18next from 'i18next';

import { deleteBrand } from 'common/api/productsBrands';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    DELETE_BRAND,
    DELETE_BRAND_SUCCESS,
    DELETE_BRAND_FAILURE,
} from '../actionTypes';
import * as selectors from '../selectors';

import fetchBrands from './fetchBrands';
import resetForm from './resetForm';
import toggleBrandForm from './toggleBrandForm';

export default (id) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());
    dispatch({ type: DELETE_BRAND });

    deleteBrand(id)
        .then(() => {
            dispatch({ type: DELETE_BRAND_SUCCESS, payload: null });
            dispatch(alerts.actions.close());
            dispatch(
                fetchBrands(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleBrandForm(false));
            dispatch(resetForm());

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch({
                type: DELETE_BRAND_FAILURE,
                payload: error.response.data.errors,
            });

            dispatch(alerts.actions.close());

            const dataAlert = {
                confirmText: i18next.t('Close'),
                messages: [
                    `${i18next.t('Whoops, something went wrong')}:`,
                    ...error.response.data.errors,
                ],
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'error',
            };

            dispatch(alerts.actions.show(dataAlert));
        });
};
