import { updateRole } from 'common/api/companyRoles';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { getFormattedPermissions } from '../helpers';
import { rolesConfigurationActions } from '../reducer';

import fetchItems from './fetchItems';
import openModal from './openModal';
import resetForm from './resetForm';

export default (filterData, values) => (dispatch) => {
    let permissions = getFormattedPermissions(values?.permissions);
    const newValues = {
        contacts_visualization_level: values.contacts_visualization_level,
        permissions,
        maximum_discount: values.maximum_discount,
        name: values.name,
    };

    dispatch(rolesConfigurationActions[SAVE_ITEM]());

    updateRole(newValues, values.id)
        .then(() => {
            dispatch(rolesConfigurationActions[SAVE_ITEM_SUCCESS]());
            dispatch(fetchItems(filterData));
            dispatch(openModal(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch(
                rolesConfigurationActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
