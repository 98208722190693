import { filterUsers } from 'common/api/staffUsers';

import {
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
} from '../actionTypes';
import { usersActions } from '../reducer';

export default ({
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
    } = {}) =>
    (dispatch) => {
        dispatch(usersActions[FETCH_USERS]());

        filterUsers({
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
        })
            .then((response) =>
                dispatch(usersActions[FETCH_USERS_SUCCESS](response.data))
            )
            .catch((error) =>
                dispatch(
                    usersActions[FETCH_USERS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
