import { merge } from 'lodash';

import Autocomplete from './Autocomplete';
import Button from './Button';
import Card from './Card';
import Dialog from './Dialog';
import Grid from './Grid';
import IconButton from './IconButton';
import Input from './Input';
import Lists from './Lists';
import Paper from './Paper';
import Tooltip from './Tooltip';
import Typography from './Typography';

const ComponentsOverrides = (theme) => {
    return merge(
        Autocomplete(theme),
        Button(theme),
        Card(theme),
        Dialog(theme),
        Grid(theme),
        IconButton(theme),
        Input(theme),
        Lists(theme),
        Paper(theme),
        Tooltip(theme),
        Typography(theme)
    );
};

export default ComponentsOverrides;
