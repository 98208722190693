import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';

import { STAGGERED_RATE_TYPE } from '../../../constants';
import { getIsSpain } from '../../../helpers';
import * as selectors from '../../../selectors';
import SectionHeader from '../../SectionHeader';

import FieldArrayConcepts from './FieldArrayConcepts';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const DatesContainer = styled.div`
    display: flex;
    margin-top: 42px;
    margin-bottom: 31px;
`;

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
`;

const Period = ({
    contractedDemandUnitsDataToSelect,
    control,
    countriesDictionary,
    demandUnitsDataToSelect,
    energyUnitsDataToSelect,
    getValues,
    hasActiveProjects,
    isAnnualType,
    isHourlyRate,
    name,
    othersUnitsDataToSelect,
    paymentType,
    setValue,
    showMode,
    spainExcessDemandUnitsDataToSelect,
}) => {
    const country = useWatch({ control, name: 'main_data.country' });
    const { t } = useTranslation();
    const isSpain = getIsSpain(countriesDictionary, country);
    return (
        <Container>
            {!isAnnualType && (
                <DatesContainer>
                    <ReactHookFormInputDatePicker
                        control={control}
                        format="dd/MMM"
                        inputFormat="dd/MM/yyyy"
                        label={t('Start date')}
                        name={`${name}.date_start`}
                        placeholder={t('Start date')}
                    />
                    <ReactHookFormInputDatePicker
                        control={control}
                        format="dd/MMM"
                        inputFormat="dd/MM/yyyy"
                        label={t('Final date')}
                        name={`${name}.date_end`}
                        placeholder={t('Final date')}
                    />
                </DatesContainer>
            )}

            <SectionHeader title={`${t('Energy charges')} (kWh)`} type="2" />

            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    disableActions={hasActiveProjects}
                    getValues={getValues}
                    isAnnualType={isAnnualType}
                    isEnergyConcept
                    isHourlyRate={isHourlyRate}
                    linkedConceptName={`${name}.demand_concepts.concepts`}
                    name={`${name}.energy_concepts.concepts`}
                    periodFieldName={name}
                    setValue={setValue}
                    showMode={showMode}
                    topValueFieldEnabled={paymentType === STAGGERED_RATE_TYPE}
                    unitItems={energyUnitsDataToSelect}
                />
            </FieldsContainer>

            <SectionHeader
                title={`${t('Measured demand charges')} (kW)`}
                type="2"
            />

            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    disableActions={hasActiveProjects}
                    getValues={getValues}
                    isHourlyRate={isHourlyRate}
                    linkedConceptParentName={`${name}.energy_concepts.concepts`}
                    name={`${name}.demand_concepts.concepts`}
                    periodFieldName={name}
                    setValue={setValue}
                    showMode={showMode}
                    unitItems={demandUnitsDataToSelect}
                />
            </FieldsContainer>

            {isHourlyRate && (
                <>
                    <SectionHeader
                        title={`${t('Contracted demand charges')} (kW)`}
                        type="2"
                    />
                    <FieldsContainer>
                        <FieldArrayConcepts
                            control={control}
                            disableActions={hasActiveProjects}
                            getValues={getValues}
                            isContractedDemandConcept
                            isHourlyRate={isHourlyRate}
                            linkedConceptName={
                                isSpain
                                    ? `${name}.excess_demand_concepts.concepts`
                                    : null
                            }
                            name={`${name}.contracted_demand_concepts.concepts`}
                            periodFieldName={name}
                            setValue={setValue}
                            showMode={showMode}
                            unitItems={contractedDemandUnitsDataToSelect}
                        />
                    </FieldsContainer>
                </>
            )}

            {isHourlyRate && isSpain && (
                <>
                    <SectionHeader
                        title={`${t('Excess demand charges')} (kW)`}
                        type="2"
                    />
                    <FieldsContainer>
                        <FieldArrayConcepts
                            control={control}
                            disableActions={hasActiveProjects}
                            getValues={getValues}
                            isHourlyRate={isHourlyRate}
                            linkedConceptParentName={`${name}.contracted_demand_concepts.concepts`}
                            name={`${name}.excess_demand_concepts.concepts`}
                            periodFieldName={name}
                            setValue={setValue}
                            showMode={showMode}
                            unitItems={spainExcessDemandUnitsDataToSelect}
                        />
                    </FieldsContainer>
                </>
            )}

            <SectionHeader title={t('Other charges')} type="2" />

            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    getValues={getValues}
                    isConceptsOtherChargesTaxes
                    name={`${name}.other_concepts.concepts`}
                    periodFieldName={name}
                    setValue={setValue}
                    showMode={showMode}
                    unitItems={othersUnitsDataToSelect}
                />
            </FieldsContainer>

            <SectionHeader title={t('Tax', { count: 2 })} type="2" />

            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    getValues={getValues}
                    isConceptsOtherChargesTaxes
                    name={`${name}.taxes_concepts.concepts`}
                    periodFieldName={name}
                    setValue={setValue}
                    showMode={showMode}
                    unitItems={othersUnitsDataToSelect}
                />
            </FieldsContainer>
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    contractedDemandUnitsDataToSelect:
        selectors.getContractedDemandUnitsDataToSelect,
    countriesDictionary: selectors.getCountriesDictionary,
    demandUnitsDataToSelect: selectors.getDemandUnitsDataToSelect,
    energyUnitsDataToSelect: selectors.getEnergyUnitsDataToSelect,
    hasActiveProjects: selectors.getHasActiveProjects,
    othersUnitsDataToSelect: selectors.getOthersUnitsDataToSelect,
    spainExcessDemandUnitsDataToSelect:
        selectors.getSpainExcessDemandUnitsDataToSelect,
});

Period.propTypes = {
    contractedDemandUnitsDataToSelect: PropTypes.array,
    control: PropTypes.object,
    countriesDictionary: PropTypes.object,
    demandUnitsDataToSelect: PropTypes.array,
    energyUnitsDataToSelect: PropTypes.array,
    getValues: PropTypes.func,
    hasActiveProjects: PropTypes.bool,
    isAnnualType: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    name: PropTypes.string,
    othersUnitsDataToSelect: PropTypes.array,
    paymentType: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    spainExcessDemandUnitsDataToSelect: PropTypes.array,
};

export default connect(mapStateToProps)(Period);
