import fetchOrigins from './fetchOrigins';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleBrandForm from './toggleBrandForm';

export default () => (dispatch) => {
    dispatch(fetchOrigins(''));

    dispatch(toggleBrandForm(true));
    dispatch(setIsNewRecord(true));
    dispatch(setEnabledEdition(true));
    dispatch(resetForm());
};
