import { getModelsList } from 'common/api/ocr/fineTuning';

import {
    FETCH_MODELS,
    FETCH_MODELS_SUCCESS,
    FETCH_MODELS_FAILURE,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_MODELS });

    getModelsList()
        .then((response) =>
            dispatch({
                payload: response?.data,
                type: FETCH_MODELS_SUCCESS,
            })
        )
        .catch((error) =>
            dispatch({
                payload: error?.response?.data?.detail,
                type: FETCH_MODELS_FAILURE,
            })
        );
};
