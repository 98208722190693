import { NAME } from './constants';

export const DOWNLOAD_CSV = `${NAME}/DOWNLOAD_CSV`;
export const DOWNLOAD_CSV_FAILURE = `${NAME}/DOWNLOAD_CSV_FAILURE`;
export const DOWNLOAD_CSV_SUCCESS = `${NAME}/DOWNLOAD_CSV_SUCCESS`;

export const FETCH_USERS = `${NAME}/FETCH_USERS`;
export const FETCH_USERS_FAILURE = `${NAME}/FETCH_USERS_FAILURE`;
export const FETCH_USERS_SUCCESS = `${NAME}/FETCH_USERS_SUCCESS`;

export const FETCH_ROLES = `${NAME}/FETCH_ROLES`;
export const FETCH_ROLES_FAILURE = `${NAME}/FETCH_ROLES_FAILURE`;
export const FETCH_ROLES_SUCCESS = `${NAME}/FETCH_ROLES_SUCCESS`;

export const FETCH_BRANCH_OFFICES = `${NAME}/FETCH_BRANCH_OFFICES`;
export const FETCH_BRANCH_OFFICES_FAILURE = `${NAME}/FETCH_BRANCH_OFFICES_FAILURE`;
export const FETCH_BRANCH_OFFICES_SUCCESS = `${NAME}/FETCH_BRANCH_OFFICES_SUCCESS`;

export const FETCH_USER_DETAIL = `${NAME}/FETCH_USER_DETAIL`;
export const FETCH_USER_DETAIL_FAILURE = `${NAME}/FETCH_USER_DETAIL_FAILURE`;
export const FETCH_USER_DETAIL_SUCCESS = `${NAME}/FETCH_USER_DETAIL_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_MODAL_USER = `${NAME}/OPEN_MODAL_USER`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const FETCH_TEMPORAL_PASSWORD = `${NAME}/FETCH_TEMPORAL_PASSWORD`;
export const FETCH_TEMPORAL_PASSWORD_FAILURE = `${NAME}/FETCH_TEMPORAL_PASSWORD_FAILURE`;
export const FETCH_TEMPORAL_PASSWORD_SUCCESS = `${NAME}/FETCH_TEMPORAL_PASSWORD_SUCCESS`;

export const SET_FILTERS = `${NAME}/SET_FILTERS`;

export const DELETE_USER = `${NAME}/DELETE_USER`;
export const DELETE_USER_SUCCESS = `${NAME}/DELETE_USER_SUCCESS`;
export const DELETE_USER_FAILURE = `${NAME}/DELETE_USER_FAILURE`;
