import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFontsModel = createSelector(getModel, (model) => model.fonts);

export const getFontsData = createSelector(
    getFontsModel,
    (model) => model.data.data || []
);

export const getFontsErrors = createSelector(
    getFontsModel,
    (model) => model.errors
);

export const getFontsIsFetching = createSelector(
    getFontsModel,
    (model) => model.isFetching
);

export const getFontsPagination = createSelector(
    getFontsModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenProductForm = createSelector(
    getModel,
    (model) => model.isOpenProductForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);

export const getDeleteFont = createSelector(
    getModel,
    (model) => model.deleteFont
);

export const getIsDeletingFont = createSelector(
    getDeleteFont,
    (model) => model.isDeleting
);
