import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { STATUS_CONFIG } from 'common/constants/afterSales';
import { Typography } from 'common/ui';

const StatusIndicator = ({ status }) => {
    const { t } = useTranslation();
    const config = STATUS_CONFIG[status];

    if (!config) return null;
    return (
        <Typography variant="caption">
            <span
                style={{
                    backgroundColor: config.color,
                    borderRadius: '50%',
                    display: 'inline-block',
                    height: 10,
                    marginRight: 8,
                    width: 10,
                }}
            />
            {t(config.label)}
        </Typography>
    );
};

StatusIndicator.propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StatusIndicator;
