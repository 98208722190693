import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Container as MaterialContainer } from 'common/ui';

import * as actions from './actions';
import ListItems from './components/ListItems';
import * as selectors from './selectors';

const Container = ({
    downloadBulk,
    fetchItems,
    files,
    isFetching,
    setFileAsSuccess,
}) => {
    const [filterData, setFilterData] = useState({
        address: '',
        is_bimonthly: -1,
        name: '',
        rate: '',
        service_number: '',
        status: 0,
        zip_code: '',
    });

    useEffect(() => fetchItems(filterData), []);

    return (
        <MaterialContainer maxWidth="">
            <ListItems
                downloadBulk={downloadBulk}
                filterData={filterData}
                filterItems={fetchItems}
                isFetching={isFetching}
                items={files}
                setFileAsSuccess={setFileAsSuccess}
                setFilterData={setFilterData}
            />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    files: selectors.getFilesData,
    isFetching: selectors.getFilesIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    downloadBulk: (selectionModel, callback) =>
        dispatch(actions.downloadBulk(selectionModel, callback)),
    fetchItems: (filterData) => dispatch(actions.fetchItems(filterData)),
    setFileAsSuccess: (id, filter) =>
        dispatch(actions.setFileAsSuccess(id, filter)),
});

Container.propTypes = {
    downloadBulk: PropTypes.func,
    fetchItems: PropTypes.func,
    files: PropTypes.array,
    isFetching: PropTypes.bool,
    setFileAsSuccess: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
