import { SAVE } from '../actionTypes';

import createSupplier from './createSupplier';
import updateSupplier from './updateSupplier';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE });
    if (values.id) {
        dispatch(updateSupplier(values, values.id));
    } else {
        dispatch(createSupplier(values));
    }
};
