import update from 'immutability-helper';

import {
    CREATE_TRAINING_DOCUMENT,
    CREATE_TRAINING_DOCUMENT_FAILURE,
    CREATE_TRAINING_DOCUMENT_SUCCESS,
    DELETE_MODEL,
    DELETE_MODEL_FAILURE,
    DELETE_MODEL_SUCCESS,
    DOWNLOAD_TRAINING_DOCUMENT,
    DOWNLOAD_TRAINING_DOCUMENT_FAILURE,
    DOWNLOAD_TRAINING_DOCUMENT_SUCCESS,
    DUPLICATE_MODEL,
    DUPLICATE_MODEL_FAILURE,
    DUPLICATE_MODEL_SUCCESS,
    FETCH_MODELS,
    FETCH_MODELS_FAILURE,
    FETCH_MODELS_SUCCESS,
    INITIALIZE_DUPLICATE_MODEL_FORM,
    INITIALIZE_MODEL_FORM,
    RESET,
    RESET_DUPLICATE_MODEL_FORM,
    RESET_MODEL_FORM,
    SAVE_MODEL,
    SAVE_MODEL_FAILURE,
    SAVE_MODEL_SUCCESS,
    SET_IS_NEW_MODEL,
    SET_IS_OPEN_DUPLICATE_MODEL_FORM,
    SET_IS_OPEN_MODEL_FORM,
    START_TRAINING,
    START_TRAINING_FAILURE,
    START_TRAINING_SUCCESS,
    STOP_TRAINING,
    STOP_TRAINING_FAILURE,
    STOP_TRAINING_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    createTrainingDocument: { data: null, errors: [], isCreating: false },
    deleteModel: { errors: [], isDeleting: false },
    downloadTrainingDocument: { data: null, errors: [], isDownloading: false },
    duplicateModel: { errors: [], isDuplicating: false },
    fetchModel: { data: [], errors: [], isFetching: false },
    initialValuesDuplicateModelForm: { id: null, name: null },
    intialValuesModelForm: {
        base_model: null,
        context: '',
        description: '',
        main_model: false,
        max_tokens: null,
        name: null,
        prompt: null,
        temperature: 0,
    },
    isNewModel: true,
    isOpenModelForm: false,
    isOpenDuplicateModelForm: false,
    models: { data: [], errors: null, isFetching: false },
    saveModel: { errors: [], isSaving: false },
    startTraining: { data: null, errors: [], isStarting: false },
    stopTraining: { errors: [], isStopping: false },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_TRAINING_DOCUMENT:
            return update(state, {
                createTrainingDocument: {
                    $merge: { errors: [], isCreating: true },
                },
            });

        case CREATE_TRAINING_DOCUMENT_FAILURE:
            return update(state, {
                createTrainingDocument: {
                    $merge: { errors: action.payload, isCreating: false },
                },
            });

        case CREATE_TRAINING_DOCUMENT_SUCCESS:
            return update(state, {
                createTrainingDocument: {
                    $merge: { data: action.payload, isCreating: false },
                },
            });

        case DELETE_MODEL:
            return update(state, {
                deleteModel: { $merge: { errors: [], isDeleting: true } },
            });

        case DELETE_MODEL_FAILURE:
            return update(state, {
                deleteModel: {
                    $merge: { errors: action.payload, isDeleting: false },
                },
            });

        case DELETE_MODEL_SUCCESS:
            return update(state, {
                deleteModel: { $merge: { isDeleting: false } },
            });

        case DOWNLOAD_TRAINING_DOCUMENT:
            return update(state, {
                downloadTrainingDocument: {
                    $merge: { errors: [], isDownloading: true },
                },
            });

        case DOWNLOAD_TRAINING_DOCUMENT_FAILURE:
            return update(state, {
                downloadTrainingDocument: {
                    $merge: { errors: action.payload, isDownloading: false },
                },
            });

        case DOWNLOAD_TRAINING_DOCUMENT_SUCCESS:
            return update(state, {
                downloadTrainingDocument: {
                    $merge: { data: action.payload, isDownloading: false },
                },
            });

        case DUPLICATE_MODEL:
            return update(state, {
                duplicateModel: { $merge: { errors: [], isDuplicating: true } },
            });

        case DUPLICATE_MODEL_FAILURE:
            return update(state, {
                duplicateModel: {
                    $merge: { errors: action.payload, isDuplicating: false },
                },
            });

        case DUPLICATE_MODEL_SUCCESS:
            return update(state, {
                duplicateModel: { $merge: { isDuplicating: false } },
            });

        case FETCH_MODELS:
            return update(state, {
                models: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_MODELS_FAILURE:
            return update(state, {
                models: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_MODELS_SUCCESS:
            return update(state, {
                models: { $merge: { data: action.payload, isFetching: false } },
            });

        case INITIALIZE_DUPLICATE_MODEL_FORM:
            return update(state, {
                initialValuesDuplicateModelForm: { $merge: action.payload },
            });
        case INITIALIZE_MODEL_FORM:
            return update(state, {
                intialValuesModelForm: { $merge: action.payload },
            });

        case RESET:
            return update(state, { $set: INITIAL_STATE });

        case RESET_DUPLICATE_MODEL_FORM:
            return update(state, {
                initialValuesDuplicateModelForm: {
                    $set: INITIAL_STATE.initialValuesDuplicateModelForm,
                },
            });

        case RESET_MODEL_FORM:
            return update(state, {
                intialValuesModelForm: {
                    $set: INITIAL_STATE.intialValuesModelForm,
                },
            });

        case SAVE_MODEL:
            return update(state, {
                saveModel: { $merge: { errors: [], isSaving: true } },
            });

        case SAVE_MODEL_FAILURE:
            return update(state, {
                saveModel: {
                    $merge: { errors: action.payload, isSaving: false },
                },
            });

        case SAVE_MODEL_SUCCESS:
            return update(state, {
                saveModel: { $merge: { isSaving: false } },
            });

        case SET_IS_NEW_MODEL:
            return update(state, {
                isNewModel: { $set: action.payload },
            });

        case SET_IS_OPEN_MODEL_FORM:
            return update(state, {
                isOpenModelForm: { $set: action.payload },
            });

        case SET_IS_OPEN_DUPLICATE_MODEL_FORM:
            return update(state, {
                isOpenDuplicateModelForm: { $set: action.payload },
            });

        case START_TRAINING:
            return update(state, {
                startTraining: { $merge: { errors: [], isStarting: true } },
            });

        case START_TRAINING_FAILURE:
            return update(state, {
                startTraining: {
                    $merge: { errors: action.payload, isStarting: false },
                },
            });

        case START_TRAINING_SUCCESS:
            return update(state, {
                startTraining: {
                    $merge: { data: action.payload, isStarting: false },
                },
            });

        case STOP_TRAINING:
            return update(state, {
                stopTraining: { $merge: { errors: [], isStopping: true } },
            });

        case STOP_TRAINING_FAILURE:
            return update(state, {
                stopTraining: {
                    $merge: { errors: action.payload, isStopping: false },
                },
            });

        case STOP_TRAINING_SUCCESS:
            return update(state, {
                stopTraining: { $merge: { isStopping: false } },
            });

        default:
            return state;
    }
}
