import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import alerts from 'common/modules/alerts';
import productDatasheet from 'common/modules/productDatasheet';
import productThumbnail from 'common/modules/productThumbnail';

import afterSalesProviders from './common/modules/afterSalesProviders';
import productDatasheetAIReader from './common/modules/productDatasheetAIReader';
import accessoriesConfiguration from './modules/accesoriesConfiguration';
import afterSalesPowerStation from './modules/afterSalesPowerStation';
import afterSalesProjects from './modules/afterSalesProjects';
import alliances from './modules/alliances';
import apiAccountsConfiguration from './modules/apiAccountsConfiguration';
import batteryConfiguration from './modules/batteryConfiguration';
import brandFamilyForm from './modules/brandFamilyForm';
import brandsConfiguration from './modules/brandsConfiguration';
import companies from './modules/companies';
import companyAlliances from './modules/companyAlliances';
import companyGeneral from './modules/companyGeneral';
import companyLogs from './modules/companyLogs';
import companyPlans from './modules/companyPlans';
import companyRoles from './modules/companyRoles';
import companyUsers from './modules/companyUsers';
import filterFilesLisa from './modules/filterFilesLisa';
import filterFilesScrapper from './modules/filterFilesScrapper';
import fineTuningAI from './modules/fineTuningAI';
import invertersConfiguration from './modules/invertersConfiguration';
import login from './modules/login';
import modelTrainingData from './modules/modelTrainingData';
import outstandingTemplateForm from './modules/outstandingTemplateForm';
import panelConfiguration from './modules/panelConfiguration';
import profile from './modules/profile';
import profileSecurity from './modules/profileSecurity';
import proposals from './modules/proposals';
import rateHistoryModal from './modules/rateHistoryModal';
import ratesConfiguration from './modules/ratesConfiguration';
import renewalControl from './modules/renewalControl';
import reportDownload from './modules/reportDownload';
import rolesConfiguration from './modules/rolesConfiguration';
import suppliersConfiguration from './modules/suppliersConfiguration';
import templatesCatalog from './modules/templatesCatalog';
import templatesCategories from './modules/templatesCategories';
import templatesFonts from './modules/templatesFonts';
import trainingDataAI from './modules/trainingDataAI';
import trainingDataAITags from './modules/trainingDataAITags';
import users from './modules/users';

export default (history) =>
    combineReducers({
        [accessoriesConfiguration.NAME]: accessoriesConfiguration.reducer,
        [afterSalesPowerStation.NAME]: afterSalesPowerStation.reducer,
        [afterSalesProjects.NAME]: afterSalesProjects.reducer,
        [afterSalesProviders.NAME]: afterSalesProviders.reducer,
        [alerts.NAME]: alerts.reducer,
        [alliances.NAME]: alliances.reducer,
        [apiAccountsConfiguration.NAME]: apiAccountsConfiguration.reducer,
        [batteryConfiguration.NAME]: batteryConfiguration.reducer,
        [brandFamilyForm.NAME]: brandFamilyForm.reducer,
        [brandsConfiguration.NAME]: brandsConfiguration.reducer,
        [companies.NAME]: companies.reducer,
        [companyAlliances.NAME]: companyAlliances.reducer,
        [companyGeneral.NAME]: companyGeneral.reducer,
        [companyLogs.NAME]: companyLogs.reducer,
        [companyPlans.NAME]: companyPlans.reducer,
        [companyRoles.NAME]: companyRoles.reducer,
        [companyUsers.NAME]: companyUsers.reducer,
        [filterFilesLisa.NAME]: filterFilesLisa.reducer,
        [filterFilesScrapper.NAME]: filterFilesScrapper.reducer,
        [fineTuningAI.NAME]: fineTuningAI.reducer,
        [invertersConfiguration.NAME]: invertersConfiguration.reducer,
        [login.NAME]: login.reducer,
        [modelTrainingData.NAME]: modelTrainingData.reducer,
        [outstandingTemplateForm.NAME]: outstandingTemplateForm.reducer,
        [panelConfiguration.NAME]: panelConfiguration.reducer,
        [productDatasheet.NAME]: productDatasheet.reducer,
        [productDatasheetAIReader.NAME]: productDatasheetAIReader.reducer,
        [productThumbnail.NAME]: productThumbnail.reducer,
        [profile.NAME]: profile.reducer,
        [profileSecurity.NAME]: profileSecurity.reducer,
        [proposals.NAME]: proposals.reducer,
        [rateHistoryModal.NAME]: rateHistoryModal.reducer,
        [ratesConfiguration.NAME]: ratesConfiguration.reducer,
        [renewalControl.NAME]: renewalControl.reducer,
        [reportDownload.NAME]: reportDownload.reducer,
        [rolesConfiguration.NAME]: rolesConfiguration.reducer,
        [suppliersConfiguration.NAME]: suppliersConfiguration.reducer,
        [templatesCatalog.NAME]: templatesCatalog.reducer,
        [templatesCategories.NAME]: templatesCategories.reducer,
        [templatesFonts.NAME]: templatesFonts.reducer,
        [trainingDataAI.NAME]: trainingDataAI.reducer,
        [trainingDataAITags.NAME]: trainingDataAITags.reducer,
        [users.NAME]: users.reducer,
        form: formReducer,
        router: connectRouter(history),
    });
