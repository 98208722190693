import API from '../.';
const ENTITY = 'products/datasheets';

export const getProductDatasheet = (id) => API.get(`/api/v1/${ENTITY}/${id}/`);
export const updateDatasheet = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/${id}/`, data);
export const deleteDatasheet = (id) => API.delete(`/api/v1/${ENTITY}/${id}/`);
export const downloadDatasheet = (name) =>
    API.get(`/api/v1/products/pdf/datasheets/${name}/`, {
        responseType: 'blob',
    });
