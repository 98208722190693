import { SAVE } from '../actionTypes';

import createFamily from './createFamily';
import updateFamily from './updateFamily';

export default (values, callback) => (dispatch) => {
    dispatch({ type: SAVE });
    if (values.id) {
        dispatch(updateFamily(values, values.id, callback));
    } else {
        dispatch(createFamily(values, callback));
    }
};
