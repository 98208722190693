export const NAME = 'rates-configuration';

export const ANNUAL_TYPE = '1';
export const CONTRACT_DATE = '1';
export const DEBOUNCE_TIME = 500;
export const FIXED_RATE_TYPE = 'Fixed';
export const HOURLY_RATE_TYPE = 'Schedule';
export const HOURLY_SELECTION_BASE_COLOR = '182, 189, 196';
export const MOVIL_YEAR = '0';
export const NAME_LENGTH = 32;
export const NAME_SHORT_LENGTH = 20;
export const PER_PERIODS = '0';
export const ROUND_VALUE = 6;
export const PRICE_REGEX = new RegExp('(\\.\\d{' + ROUND_VALUE + '})\\d+', 'g');
export const STAGGERED_RATE_TYPE = 'Range';

export const INIT_VALUES_DEFAULT = {
    id: null,
    main_data: {
        business_type: 'Residential',
        certified: false,
        country: '',
        currency: '',
        name: '',
        name_short: '',
        periodicity_type: 'Monthly',
        rate_description: '',
        type_of_input_charges: ANNUAL_TYPE,
        utility_supplier: '',
        voltage_type: 'Low tension',
    },
    policy_data: {
        consumption_profile: 'Simple',
        default_compensation_scheme: 'NETMET_SS',
        energy_export_price: 0,
        payback_type: 'Netmetering',
        percent_bags_used: 100,
        period_compensation: MOVIL_YEAR,
        start_period_comp_mont: '',
    },
    settings_data: {
        annual_percentage_increase: '0',
        payment_type: 'Fixed',
        periods: [],
    },
    version: 0,
};

export const BUSINESS_TYPE_ITEMS = {
    all: 'All text',
    Commercial: 'Commercial',
    Industrial: 'Industrial',
    Residential: 'Residential',
};

export const RATE_TYPE_ITEMS = {
    all: 'All text',
    false: 'Custom',
    true: 'Certified',
};

export const VOLTAGE_TYPE_ITEMS = {
    all: 'All text',
    'High tension': 'High tension',
    'Low tension': 'Low tension',
    'Mid tension': 'Mid tension',
};
