import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import ReduxFieldSelect from 'common/components/form/redux/ReduxFieldSelect';
import { ROLE_LEVELS } from 'common/constants/permissionsV2';

const BranchOfficeSelect = ({
    branchOffices,
    isMultiBranchesCompany,
    roleLevel,
}) => {
    const { t } = useTranslation();
    const isCustomOrGM = [
        ROLE_LEVELS.CUSTOMIZED,
        ROLE_LEVELS.GENERAL_MANAGER,
    ].includes(roleLevel);

    if (!isMultiBranchesCompany || roleLevel === ROLE_LEVELS.OWNER) return null;

    if (isCustomOrGM)
        return (
            <Field
                component={ReduxFieldSelect}
                label={t('Branch office', { count: 2 }).concat('*')}
                multiple
                name="branch_offices"
                options={branchOffices}
                variant="outlined"
            />
        );
    return (
        <Field
            component={ReduxFieldSelect}
            label={t('Branch office', { count: 1 }).concat('*')}
            name="branch_office"
            options={branchOffices}
            variant="outlined"
        />
    );
};

BranchOfficeSelect.propTypes = {
    branchOffices: PropTypes.array,
    isMultiBranchesCompany: PropTypes.bool,
    roleLevel: PropTypes.string,
};

export default BranchOfficeSelect;
