import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/DropdownCardMenu';
import { Box, Button } from 'common/ui';

const ActionsFormatter = ({
    filterData,
    handleClickDelete,
    handleClickUpdate,
    row,
}) => {
    const { t } = useTranslation();
    const isDefault = row?.is_system_role;

    if (isDefault)
        return (
            <Box sx={{ ml: 'auto' }}>
                <Button
                    onClick={() =>
                        handleClickUpdate(filterData?.company, row?.id)
                    }
                    startIcon={<VisibilityIcon fontSize="small" />}
                    variant="text"
                >
                    Ver permisos
                </Button>
            </Box>
        );
    return (
        <DropdownCardMenu
            items={[
                {
                    handleClick: () =>
                        handleClickUpdate(filterData?.company, row?.id),
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                },
                {
                    handleClick: () => handleClickDelete(filterData, row),
                    icon: row?.has_users ? (
                        <WarningIcon fontSize="small" />
                    ) : (
                        <DeleteIcon fontSize="small" />
                    ),
                    label: t('Delete'),
                    visible: !isDefault,
                },
            ]}
            sxIconButton={{ ml: 'auto' }}
        />
    );
};

ActionsFormatter.propTypes = {
    filterData: PropTypes.object,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    row: PropTypes.object,
};

export default ActionsFormatter;
