import API from '..';
const ENTITY = 'parameters';

export const filterSuppliers = ({
    orderBy = '',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = '',
}) =>
    API.get(`/api/v1/${ENTITY}/powersuppliers/`, {
        params: {
            page: page,
            per_page: perPage,
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const getSupplierDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/powersuppliers/${id}/`);

export const createSupplier = (data) =>
    API.post(`/api/v1/${ENTITY}/powersuppliers/`, data);

export const updateSupplier = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/powersuppliers/${id}/`, data);

export const deleteSupplier = (id) =>
    API.delete(`/api/v1/${ENTITY}/powersuppliers/${id}/`);

export const filterOrigins = ({
    orderBy,
    page = 1,
    perPage = 25,
    searchText,
    sortBy,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/origins/`, {
        params: {
            page: page,
            per_page: perPage,
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const getRateHistory = ({
    division,
    month,
    orderBy,
    page,
    rateId,
    region,
    searchText,
    sortBy,
    summer,
    year,
}) =>
    API.get(`/api/v1/${ENTITY}/fee-charges/${rateId}/`, {
        params: {
            division,
            month,
            page,
            region,
            summer,
            year,
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const getYears = () => API.get(`/api/v1/${ENTITY}/years/`);

export const getMonths = () => API.get(`/api/v1/${ENTITY}/months/`);

export const getSummerMonths = () => API.get(`/api/v1/${ENTITY}/summers/`);

export const getRegions = () => API.get(`/api/v1/${ENTITY}/regions/`);

export const getDivisions = () => API.get(`/api/v1/${ENTITY}/divisions/`);

export const getRateHistoryDatagrid = (rateId = '', year = '', month = '') =>
    API.get(`/api/v1/${ENTITY}/grid-rates/${rateId}/${year}/${month}/`);

export const saveRateHistoryDatagrid = (
    rateId = '',
    year = '',
    month = '',
    data
) =>
    API.post(`/api/v1/${ENTITY}/grid-rates/${rateId}/${year}/${month}/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
