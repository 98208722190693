import { getTrainingData } from 'common/api/ocr/fineTuning';

import {
    FETCH_TRAINING_DATA,
    FETCH_TRAINING_DATA_FAILURE,
    FETCH_TRAINING_DATA_SUCCESS,
} from '../actionTypes';

import initializeForm from './initializeForm';

export default (id, modelId) => (dispatch) => {
    dispatch({ type: FETCH_TRAINING_DATA });

    getTrainingData(id, modelId)
        .then((response) => {
            dispatch({
                type: FETCH_TRAINING_DATA_SUCCESS,
                payload: response.data,
            });

            dispatch(initializeForm(response.data));
        })
        .catch((error) =>
            dispatch({
                type: FETCH_TRAINING_DATA_FAILURE,
                payload: error?.response?.data?.detail,
            })
        );
};
