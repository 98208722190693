import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { Grid } from 'common/ui';

import { Label } from '../styledComponents';

const DefaultSettings = ({
    baseName = '',
    compensationSchemesOptions,
    control,
    initialValues,
    showMode,
}) => {
    const { t } = useTranslation();
    const countryCurrencySymbol = '$';

    return (
        <Grid columns={18} container py={2} spacing={2}>
            <Grid item xs={18} sm={10} md={8} lg={6} xl={5}>
                <Label>{t('Energy export price')}:</Label>
            </Grid>

            <Grid item xs={18} sm={8} md={10} lg={12} xl={13}>
                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    disabled={showMode}
                    key={initialValues?.energy_export_price}
                    name={`${baseName}energy_export_price`}
                    placeholder="0"
                    prepend={countryCurrencySymbol}
                />
            </Grid>

            <Grid item xs={18} sm={10} md={8} lg={6} xl={5}>
                <Label>{t('Netted exported generation price')}:</Label>
            </Grid>

            <Grid item xs={18} sm={8} md={10} lg={12}>
                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    disabled={showMode}
                    key={initialValues?.netted_exported_generation_price}
                    name={`${baseName}netted_exported_generation_price`}
                    placeholder="0"
                    prepend={countryCurrencySymbol}
                />
            </Grid>

            <Grid item xs={18} sm={10} md={8} lg={6} xl={5}>
                <Label>{t('Energy compensation scheme')}:</Label>
            </Grid>

            <Grid item xs={18} md={10} lg={8} xl={7}>
                <ReactHookFormSelect
                    control={control}
                    disabled={showMode}
                    key={initialValues?.default_compensation_scheme}
                    name={`${baseName}default_compensation_scheme`}
                    options={compensationSchemesOptions}
                    sxFormControl={{ maxWidth: '450px' }}
                />
            </Grid>
        </Grid>
    );
};

DefaultSettings.propTypes = {
    baseName: PropTypes.string,
    compensationSchemesOptions: PropTypes.array,
    control: PropTypes.object,
    initialValues: PropTypes.object,
    showMode: PropTypes.bool,
};

export default DefaultSettings;
