import i18next from 'i18next';

import { bulkUsers } from 'common/api/staffUsers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { usersActions } from '../reducer';

import filterUsers from './filterUsers';

export default (ids, value, filterData, callback) => (dispatch) => {
    dispatch(usersActions[BULK_UPDATE_ITEMS]());

    bulkUsers({ status_flag: value, user_ids: ids })
        .then(() => {
            dispatch(usersActions[BULK_UPDATE_ITEMS_SUCCESS]());
            dispatch(filterUsers(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            else showToast();
            callback();
        })
        .catch((error) => {
            dispatch(
                usersActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
