import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DialogContainer, HeaderContainer, TitleIcon } from 'common/components';
import { Button, Container, Typography } from 'common/ui';

import * as actions from './actions';
import DuplicateModelForm from './components/DuplicateModelForm';
import ModelForm from './components/ModelForm';
import ModelsTable from './components/ModelsTable';
import * as selectors from './selectors';

const FineTuningContainer = ({
    createTrainingDocument,
    downloadTrainingDocument,
    duplicateModel,
    fetchModels,
    initialValuesDuplicateModelForm,
    initialValuesModelForm,
    isDuplicatingModel,
    isFetchingModels,
    isNewModel,
    isOpenDuplicateModelForm,
    isOpenModelForm,
    isSavingModel,
    modelsList,
    prepareCreateModel,
    prepareDeleteModel,
    prepareDuplicateModel,
    prepareStartTrainingModel,
    prepareStopTrainingModel,
    prepareUpdateModel,
    redirect,
    reset,
    resetDuplicateModelForm,
    resetModelForm,
    saveModel,
    setIsOpenDuplicateModelForm,
    setIsOpenModelForm,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchModels();
        return () => reset();
    }, []);

    const handleCloseModalForm = () => {
        setIsOpenModelForm(false);
        resetModelForm();
    };

    const handleCloseDuplicateModelForm = () => {
        setIsOpenDuplicateModelForm(false);
        resetDuplicateModelForm();
    };

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={PsychologyIcon}
                        title={t('Model', { count: 2 })}
                    />
                </Typography>

                <Button
                    onClick={prepareCreateModel}
                    fullWidth={false}
                    startIcon={<AddIcon />}
                >
                    {t('Add')}
                </Button>
            </HeaderContainer>

            <ModelsTable
                createTrainingDocument={createTrainingDocument}
                downloadTrainingDocument={downloadTrainingDocument}
                isFetchingModels={isFetchingModels}
                items={modelsList}
                prepareDeleteModel={prepareDeleteModel}
                prepareDuplicateModel={prepareDuplicateModel}
                prepareStartTrainingModel={prepareStartTrainingModel}
                prepareStopTrainingModel={prepareStopTrainingModel}
                prepareUpdateModel={prepareUpdateModel}
                redirect={redirect}
            />

            <DialogContainer
                open={isOpenModelForm}
                fullWidth={true}
                onClose={handleCloseModalForm}
                title={`${isNewModel ? t('Create') : t('Edit')} 
                    ${t('Model', { count: 1 }).toLocaleLowerCase()}`}
            >
                <ModelForm
                    disabled={isSavingModel}
                    handleCloseModalForm={handleCloseModalForm}
                    initialValues={initialValuesModelForm}
                    onSubmit={(values) => saveModel(values, isNewModel)}
                />
            </DialogContainer>

            <DialogContainer
                open={isOpenDuplicateModelForm}
                fullWidth={true}
                onClose={handleCloseDuplicateModelForm}
                title={t('Duplicate')}
            >
                <DuplicateModelForm
                    disabled={isDuplicatingModel}
                    handleCloseModalForm={handleCloseDuplicateModelForm}
                    initialValues={initialValuesDuplicateModelForm}
                    onSubmit={(values) => duplicateModel(values)}
                />
            </DialogContainer>
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValuesDuplicateModelForm:
        selectors.getInitialValuesDuplicateModelForm,
    initialValuesModelForm: selectors.getInitialValuesModelForm,
    isDuplicatingModel: selectors.getDuplicateModelIsDuplicating,
    isFetchingModels: selectors.getModelsIsFetching,
    isNewModel: selectors.getIsNewModel,
    isOpenDuplicateModelForm: selectors.getIsOpenDuplicateModelForm,
    isOpenModelForm: selectors.getIsOpenModelForm,
    isSavingModel: selectors.getSaveModelIsSaving,
    modelsList: selectors.getModelsData,
});

const mapDispatchToProps = (dispatch) => ({
    createTrainingDocument: (modelId) =>
        dispatch(actions.createTrainingDocument(modelId)),
    downloadTrainingDocument: (fileName) =>
        dispatch(actions.downloadTrainingDocument(fileName)),
    duplicateModel: (model) => dispatch(actions.duplicateModel(model)),
    fetchModels: () => dispatch(actions.fetchModels()),
    prepareCreateModel: () => dispatch(actions.prepareCreateModel()),
    prepareDeleteModel: (id) => dispatch(actions.prepareDeleteModel(id)),
    prepareDuplicateModel: (model) =>
        dispatch(actions.prepareDuplicateModel(model)),
    prepareStartTrainingModel: (modelId) =>
        dispatch(actions.prepareStartTrainingModel(modelId)),
    prepareStopTrainingModel: (modelId) =>
        dispatch(actions.prepareStopTrainingModel(modelId)),
    prepareUpdateModel: (model) => dispatch(actions.prepareUpdateModel(model)),
    redirect: (link) => dispatch(push(link)),
    reset: () => dispatch(actions.reset()),
    resetDuplicateModelForm: () => dispatch(actions.resetDuplicateModelForm()),
    resetModelForm: () => dispatch(actions.resetModelForm()),
    saveModel: (model, isNewModel) =>
        dispatch(actions.saveModel(model, isNewModel)),
    setIsOpenDuplicateModelForm: (isOpenForm) =>
        dispatch(actions.setIsOpenDuplicateModelForm(isOpenForm)),
    setIsOpenModelForm: (isOpenForm) =>
        dispatch(actions.setIsOpenModelForm(isOpenForm)),
});

FineTuningContainer.propTypes = {
    createTrainingDocument: PropTypes.func,
    downloadTrainingDocument: PropTypes.func,
    duplicateModel: PropTypes.func,
    fetchModels: PropTypes.func,
    initialValuesDuplicateModelForm: PropTypes.object,
    initialValuesModelForm: PropTypes.object,
    isDuplicatingModel: PropTypes.bool,
    isFetchingModels: PropTypes.bool,
    isNewModel: PropTypes.bool,
    isOpenDuplicateModelForm: PropTypes.bool,
    isOpenModelForm: PropTypes.bool,
    isSavingModel: PropTypes.bool,
    modelsList: PropTypes.array,
    prepareCreateModel: PropTypes.func,
    prepareDeleteModel: PropTypes.func,
    prepareDuplicateModel: PropTypes.func,
    prepareStartTrainingModel: PropTypes.func,
    prepareStopTrainingModel: PropTypes.func,
    prepareUpdateModel: PropTypes.func,
    redirect: PropTypes.func,
    reset: PropTypes.func,
    resetDuplicateModelForm: PropTypes.func,
    resetModelForm: PropTypes.func,
    saveModel: PropTypes.func,
    setIsOpenDuplicateModelForm: PropTypes.func,
    setIsOpenModelForm: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FineTuningContainer);
