import reduxWebsocket from '@giantmachines/redux-websocket';
import { configureStore } from '@reduxjs/toolkit';
import { isImmutableDefault } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { PREFIX } from 'common/sockets/constants';

import createRootReducer from './reducer';

export const history = createBrowserHistory();

const isImmutable = (value) =>
    isImmutableDefault(value) || value instanceof File;

export default function configureStoreApp(preloadedState) {
    const store = configureStore({
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: { isImmutable },
            })
                .concat(routerMiddleware(history))
                .concat(
                    reduxWebsocket({
                        prefix: PREFIX,
                        reconnectOnClose: true,
                    })
                ),
        preloadedState,
        reducer: createRootReducer(history),
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducer', () =>
            store.replaceReducer(createRootReducer(history))
        );
    }

    return store;
}
