import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { CardContainer } from 'common/components';
import { Card, CardActions, Container, Grid, Pagination } from 'common/ui';

import PlaceholderAlliances from 'resources/placeholders/placeholder_alliances.svg';

import * as actions from './actions';
import {
    AlliancesPreviewTable,
    AllianceProductsPreviewTable,
} from './components';
import * as selectors from './selectors';

const StyledCard = styled(Card)(({ backgroundcolor, backgroundimg }) => ({
    alignItems: 'center',
    background: `${backgroundimg}`,
    backgroundImage: `url(${backgroundimg}), linear-gradient(to right, #fff , ${backgroundcolor})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
}));

const AlliancesContainer = ({
    allianceProductsFilters,
    fetchAlliances,
    isFetching,
    showAllianceProducts,
    match,
    pagination,
    alliances,
    reset,
    isFetchingProducts,
    products,
    productsPagination,
    fetchAllianceProducts,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');

    const [productsPage, setProductsPage] = useState(1);
    const [productsOrderBy, setProductsOrderBy] = useState('');
    const [productsSortBy, setProductsSortBy] = useState('');

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (match.params.id) {
            fetchAlliances(match.params.id, page, orderBy, sortBy, '');
        }
    }, [page, match.params.id]);

    useEffect(() => {
        if (productsPage && !isEmpty(allianceProductsFilters.alliance)) {
            fetchAllianceProducts(
                match.params.id,
                allianceProductsFilters.alliance,
                productsPage,
                orderBy,
                sortBy,
                ''
            );
        }
    }, [productsPage]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchAlliances(match.params.id, page, orderBy, sortBy, '');
    };

    const handleOnChangeProductsPage = (event, value) => setProductsPage(value);
    const handleSortableProducts = (orderBy, sortBy) => {
        setProductsOrderBy(orderBy);
        setProductsSortBy(sortBy);
        fetchAllianceProducts(
            match.params.id,
            allianceProductsFilters.alliance,
            productsPage,
            orderBy,
            sortBy,
            ''
        );
    };

    return (
        <>
            <Container maxWidth="">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={5}>
                        <CardContainer
                            title={t('Alliance', { count: 2 })}
                            withDivider
                        >
                            <Card
                                sx={{
                                    boxShadow: (theme) =>
                                        theme.customShadows.z0,
                                }}
                            >
                                <AlliancesPreviewTable
                                    companyId={match.params.id}
                                    handleSortable={handleSortable}
                                    isFetching={isFetching}
                                    items={alliances}
                                    sortedBy={sortBy}
                                    orderdBy={orderBy}
                                />

                                {!isFetching &&
                                    pagination &&
                                    !isEmpty(pagination) && (
                                        <CardActions>
                                            <Pagination
                                                color="primary"
                                                count={
                                                    Math.ceil(
                                                        parseInt(
                                                            pagination.total_rows
                                                        ) /
                                                            parseInt(
                                                                pagination.per_page
                                                            )
                                                    ) || 0
                                                }
                                                shape="rounded"
                                                showFirstButton
                                                showLastButton
                                                sx={{
                                                    marginLeft: 'auto',
                                                }}
                                                page={pagination.current_page}
                                                onChange={handleOnChangePage}
                                                variant="outlined"
                                            />
                                        </CardActions>
                                    )}
                            </Card>
                        </CardContainer>
                    </Grid>
                    <Grid item xs={12} md={6} lg={7}>
                        {showAllianceProducts ? (
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <CardContainer
                                        title={t('Alliance products')}
                                        isCollapsable
                                        withDivider
                                    >
                                        <Card
                                            sx={{
                                                boxShadow: (theme) =>
                                                    theme.customShadows.z0,
                                            }}
                                        >
                                            <AllianceProductsPreviewTable
                                                handleSortable={
                                                    handleSortableProducts
                                                }
                                                isFetching={isFetchingProducts}
                                                items={products}
                                                sortedBy={productsSortBy}
                                                orderdBy={productsOrderBy}
                                            />

                                            {!isFetchingProducts &&
                                                productsPagination &&
                                                !isEmpty(
                                                    productsPagination
                                                ) && (
                                                    <CardActions>
                                                        <Pagination
                                                            color="primary"
                                                            count={
                                                                Math.ceil(
                                                                    parseInt(
                                                                        productsPagination.total_rows
                                                                    ) /
                                                                        parseInt(
                                                                            productsPagination.per_page
                                                                        )
                                                                ) || 0
                                                            }
                                                            shape="rounded"
                                                            showFirstButton
                                                            showLastButton
                                                            sx={{
                                                                marginLeft:
                                                                    'auto',
                                                            }}
                                                            page={
                                                                productsPagination.current_page
                                                            }
                                                            onChange={
                                                                handleOnChangeProductsPage
                                                            }
                                                            variant="outlined"
                                                        />
                                                    </CardActions>
                                                )}
                                        </Card>
                                    </CardContainer>
                                </Grid>
                            </Grid>
                        ) : (
                            <StyledCard
                                backgroundcolor="#fff"
                                backgroundimg={PlaceholderAlliances}
                            >
                                <img
                                    alt="placeholder no alliance selected"
                                    loading="lazy"
                                    src={PlaceholderAlliances}
                                />
                            </StyledCard>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

AlliancesContainer.propTypes = {
    allianceProductsFilters: PropTypes.object,
    fetchAlliances: PropTypes.func,
    isFetching: PropTypes.bool,
    showAllianceProducts: PropTypes.bool,
    match: PropTypes.object,
    pagination: PropTypes.object,
    alliances: PropTypes.array,
    reset: PropTypes.func,
    isFetchingProducts: PropTypes.bool,
    products: PropTypes.array,
    productsPagination: PropTypes.object,
    fetchAllianceProducts: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getAlliancesIsFetching,
    showAllianceProducts: selectors.getIsShowAllianceProducts,
    pagination: selectors.getAlliancesPagination,
    alliances: selectors.getAlliancesData,
    isFetchingProducts: selectors.getAllianceProductsIsFetching,
    products: selectors.getAllianceProductsData,
    productsPagination: selectors.getAllianceProductsPagination,
    allianceProductsFilters: selectors.getAllianceProductsFilters,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAlliances: (id, page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchAlliances(id, page, orderBy, sortBy, searchStr)),
    fetchAllianceProducts: (id, alliance, page, orderBy, sortBy, searchStr) =>
        dispatch(
            actions.fetchAllianceProducts(
                id,
                alliance,
                page,
                orderBy,
                sortBy,
                searchStr
            )
        ),
    reset: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AlliancesContainer));
