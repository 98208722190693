import React, { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { DialogContainer } from 'common/components';
import { Card, CardActions, Container, Pagination } from 'common/ui';

import * as actions from './actions';
import { RolesPreviewTable, RoleForm } from './components';
import * as selectors from './selectors';

const RolesContainer = ({
    fetchRoles,
    handleClickCloseModalEdition,
    isFetching,
    isLoadingRoleDetail,
    isOpenModalEdition,
    match,
    pagination,
    reset,
    roles,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (match.params.id) {
            fetchRoles(match.params.id, page, orderBy, sortBy, '');
        }
    }, [page, match.params.id]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchRoles(match.params.id, page, orderBy, sortBy, '');
    };

    return (
        <>
            <Container maxWidth="">
                <Card>
                    <RolesPreviewTable
                        companyId={match.params.id}
                        handleSortable={handleSortable}
                        isFetching={isFetching}
                        items={roles}
                        sortedBy={sortBy}
                        orderdBy={orderBy}
                    />

                    {!isFetching && pagination && !isEmpty(pagination) && (
                        <CardActions>
                            <Pagination
                                color="primary"
                                count={
                                    Math.ceil(
                                        parseInt(pagination.total_rows) /
                                            parseInt(pagination.per_page)
                                    ) || 0
                                }
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                sx={{
                                    marginLeft: 'auto',
                                }}
                                page={pagination.current_page}
                                onChange={handleOnChangePage}
                                variant="outlined"
                            />
                        </CardActions>
                    )}
                </Card>
            </Container>
            <DialogContainer
                loading={isLoadingRoleDetail}
                onClose={handleClickCloseModalEdition}
                open={isOpenModalEdition}
                maxWidth="lg"
                fullWidth={true}
                title={t('Roles and permissions')}
            >
                <RoleForm />
            </DialogContainer>
        </>
    );
};

RolesContainer.propTypes = {
    fetchRoles: PropTypes.func,
    handleClickCloseModalEdition: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoadingRoleDetail: PropTypes.bool,
    isOpenModalEdition: PropTypes.bool,
    match: PropTypes.object,
    pagination: PropTypes.object,
    reset: PropTypes.func,
    roles: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getRolesIsFetching,
    isLoadingRoleDetail: selectors.getIsFetchingRoleDetail,
    isOpenModalEdition: selectors.getIsOpenModalEdition,
    pagination: selectors.getRolesPagination,
    roles: selectors.getRolesData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRoles: (id, page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchRoles(id, page, orderBy, sortBy, searchStr)),
    handleClickCloseModalEdition: () =>
        dispatch(actions.toggleModalEdition(false)),
    reset: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RolesContainer));
