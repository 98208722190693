import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Avatar } from 'common/ui';

import * as actions from '../actions';

const LogoFormatter = (cell) => (
    <div>{cell ? <Avatar alt={cell} src={cell || '/'} /> : '- - -'}</div>
);

const AlliancesPreviewTable = ({
    companyId,
    handleClickGetProducts,
    handleSortable,
    isFetching,
    items,
    sortedBy,
    pagination,
    orderdBy,
}) => {
    const { t } = useTranslation();
    const columns = [
        {
            dataField: 'logo',
            formatter: LogoFormatter,
            handleSortable: handleSortable,
            label: t('Logo'),
            width: '10%',
        },
        {
            dataField: 'name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Name'),
            width: '10%',
        },
        {
            dataField: 'status',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Status'),
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={(item) => handleClickGetProducts(companyId, item)}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

AlliancesPreviewTable.propTypes = {
    companyId: PropTypes.string,
    handleClickGetProducts: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
    orderdBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickGetProducts: (companyId, item) =>
        dispatch(actions.fetchAllianceProducts(companyId, item)),
});

export default connect(null, mapDispatchToProps)(AlliancesPreviewTable);
