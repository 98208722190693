import i18next from 'i18next';

import { getRoleDetail } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    FETCH_ROLE_DETAIL,
    FETCH_ROLE_DETAIL_FAILURE,
    FETCH_ROLE_DETAIL_SUCCESS,
} from '../actionTypes';
import { getEnabledGroups, getPermissions, isSelected } from '../helpers';
import * as selectors from '../selectors';

import initializeForm from './initializeForm';
import resetForm from './resetForm';
import toggleModalEdition from './toggleModalEdition';

export default (companyId, role) => (dispatch, getState) => {
    const state = getState();

    const contactCategories = selectors.getContactPermissions(state);
    const configurationCategories =
        selectors.getConfigurationPermissions(state);
    const proposerCategories = selectors.getProposerPermissions(state);

    dispatch({ type: FETCH_ROLE_DETAIL });
    dispatch(resetForm());
    dispatch(toggleModalEdition(true));

    getRoleDetail(companyId, role.id)
        .then((response) => {
            dispatch({
                type: FETCH_ROLE_DETAIL_SUCCESS,
                payload: response.data,
            });

            const contactPermissions = getPermissions(
                contactCategories,
                response.data.groups,
                i18next.t('Contacts permissions')
            );

            const configurationPermissions = getPermissions(
                configurationCategories,
                response.data.groups,
                i18next.t('Configuration permissions')
            );

            const proposerPermissions = getPermissions(
                proposerCategories,
                response.data.groups,
                i18next.t('Proposer actions')
            );

            const values = {
                ...response.data,
                groups: getEnabledGroups(response.data),
                id: response.data.id,
                maximum_discount: response.data.maximum_discount,
                name: response.data.name,
                owner_type: response.data.owner_type,
                type: response.data.owner_type == 2 ? 3 : response.data.type,
                contacts_visualization_level:
                    response.data.contacts_visualization_level.key,
                configuration_groups_view: isSelected(
                    configurationPermissions,
                    'permission_view',
                    'VIEW'
                ),
                configuration_groups_add: isSelected(
                    configurationPermissions,
                    'permission_add',
                    'ADD'
                ),
                configuration_groups_edit: isSelected(
                    configurationPermissions,
                    'permission_change',
                    'EDIT'
                ),
                configuration_groups_delete: isSelected(
                    configurationPermissions,
                    'permission_delete',
                    'DELETE'
                ),
                contact_groups_view: isSelected(
                    contactPermissions,
                    'permission_view',
                    'VIEW'
                ),
                contact_groups_add: isSelected(
                    contactPermissions,
                    'permission_add',
                    'ADD'
                ),
                contact_groups_edit: isSelected(
                    contactPermissions,
                    'permission_change',
                    'EDIT'
                ),
                contact_groups_delete: isSelected(
                    contactPermissions,
                    'permission_delete',
                    'DELETE'
                ),
                proposer_groups_view: isSelected(
                    proposerPermissions,
                    'permission_view',
                    'VIEW'
                ),
                proposer_groups_add: isSelected(
                    proposerPermissions,
                    'permission_add',
                    'ADD'
                ),
                proposer_groups_edit: isSelected(
                    proposerPermissions,
                    'permission_change',
                    'EDIT'
                ),
                proposer_groups_delete: isSelected(
                    proposerPermissions,
                    'permission_delete',
                    'DELETE'
                ),
                company_id: companyId,
            };

            dispatch(initializeForm(values));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_ROLE_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t('An error occurred while querying the role'),
            });

            dispatch(toggleModalEdition(false));
        });
};
