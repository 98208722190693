import i18next from 'i18next';

import { trainModel } from 'common/api/ocr/openai';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    START_TRAINING,
    START_TRAINING_FAILURE,
    START_TRAINING_SUCCESS,
} from '../actionTypes';

import fetchModels from './fetchModels';

export default (modelId) => (dispatch) => {
    dispatch({ type: START_TRAINING });

    trainModel(modelId)
        .then((response) => {
            dispatch({
                payload: response?.data,
                type: START_TRAINING_SUCCESS,
            });

            showToast({ autoClose: 3000 });
            dispatch(fetchModels());
        })
        .catch((error) => {
            dispatch({
                payload: error?.response?.data?.detail,
                type: START_TRAINING_FAILURE,
            });

            const dataAlert = {
                confirmText: i18next.t('Close'),
                messages: [error?.response?.data?.detail],
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'error',
            };

            dispatch(alerts.actions.show(dataAlert));
        });
};
