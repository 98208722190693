import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { createBrand } from 'common/api/productsBrands';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchBrands from './fetchBrands';
import resetForm from './resetForm';
import toggleBrandForm from './toggleBrandForm';

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    if (isEmpty(values.families)) {
        dispatch({
            type: SAVE_FAILURE,
            payload: [
                i18next.t(
                    'Adding at least one family is required to create the brand'
                ),
            ],
        });
        return;
    }

    let newValues = {
        name: values.name,
        country: values.country,
    };

    if (Array.isArray(values.families)) {
        newValues.families = !isEmpty(values.families)
            ? values.families.join()
            : '';
    }

    createBrand(newValues)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchBrands(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleBrandForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while creating the brand'),
            });
        });
};
