import { getCompanyDetail } from 'common/api/companies';

import {
    FETCH_COMPANY_DETAIL,
    FETCH_COMPANY_DETAIL_FAILURE,
    FETCH_COMPANY_DETAIL_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeForm from './initializeForm';

export default (companyId) => (dispatch) => {
    dispatch(companyGeneralActions[FETCH_COMPANY_DETAIL]());
    getCompanyDetail(companyId)
        .then((response) => {
            dispatch(
                companyGeneralActions[FETCH_COMPANY_DETAIL_SUCCESS](
                    response.data
                )
            );
            dispatch(initializeForm(response.data));
        })
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_COMPANY_DETAIL_FAILURE](
                    error.response.data.errors
                )
            )
        );
};
