import React from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const TabPanel = (props) => {
    const { children, selectedTab, value, ...other } = props;

    return (
        <div
            hidden={value !== selectedTab}
            id={`tabpanel-${value}`}
            role="tabpanel"
            {...other}
        >
            <Box sx={{ py: 2 }}>
                {value === selectedTab && <div>{children}</div>}
            </Box>
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TabPanel;
