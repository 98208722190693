import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

const countryCurrencySymbol = '$';

const PriceField = ({ control, label, name, onChange, showMode, unitName }) => {
    const unit = useWatch({ control, name: unitName });
    const typeValue =
        unit && unit.search('%') >= 0 ? '%' : countryCurrencySymbol;

    return (
        <ReactHookFormIntlNumberInput
            append={typeValue === '%' && typeValue}
            control={control}
            disabled={showMode}
            fullWidth
            label={label}
            name={name}
            onChange={onChange}
            placeholder={label}
            prepend={typeValue !== '%' && typeValue}
        />
    );
};

PriceField.propTypes = {
    control: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    showMode: PropTypes.bool,
    unitName: PropTypes.string,
};

export default PriceField;
