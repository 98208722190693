import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { ShowErrors } from 'common/components';
import { ReduxFieldInput, ReduxFieldFile } from 'common/components/form/redux';
import { Box, Button, Grid, Stack, Typography } from 'common/ui';
import { handleFileURL } from 'common/utils/helpers';

import * as actions from '../actions';
import { ALLOWED_EXTENSIONS, NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const ProfileImage = styled.div`
    align-items: center;
    background-color: #202253;
    border-radius: 100%;
    color: #eff1fb;
    display: flex;
    font-size: 22px;
    font-weight: bold;
    height: 112px;
    justify-content: center;
    letter-spacing: 0.57px;
    line-height: 33px;
    margin: 4px auto;
    overflow: hidden;
    text-align: center;
    width: 112px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const DataForm = ({
    errors,
    fetchData,
    formValues,
    handleClickSave,
    handleSubmit,
    isSaving,
    pristine,
    reset,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchData();
    }, []);

    const placeholderProfileImage = formValues.first_name
        ? formValues.first_name[0] + formValues.last_name[0]
        : '';

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Stack spacing={3}>
                        <Box
                            component="label"
                            htmlFor={'profile_image_01'}
                            sx={{
                                cursor: 'pointer',
                                textAlign: 'center',
                            }}
                        >
                            <ProfileImage>
                                {!formValues.picture && (
                                    <>{placeholderProfileImage}</>
                                )}

                                {formValues.picture && (
                                    <img
                                        alt=""
                                        src={
                                            Array.isArray(formValues.picture)
                                                ? formValues.picture[0].preview
                                                : handleFileURL(
                                                      formValues.picture,
                                                      process.env
                                                          .REACT_APP_API_URL
                                                  )
                                        }
                                    />
                                )}
                            </ProfileImage>
                            <Typography align="center" variant="caption">
                                {t('No more than 2MB')}
                            </Typography>
                        </Box>

                        <Field
                            accept={ALLOWED_EXTENSIONS}
                            id="profile_image_01"
                            component={ReduxFieldFile}
                            placeholder=""
                            name="picture"
                            variant="profile"
                            maxSize={2000000}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <Stack spacing={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    name="first_name"
                                    component={ReduxFieldInput}
                                    placeholder={t('Name')}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    name="last_name"
                                    component={ReduxFieldInput}
                                    placeholder={t('Last name')}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Field
                            name="email"
                            component={ReduxFieldInput}
                            disabled={true}
                            placeholder={t('Email')}
                            type="email"
                            variant="outlined"
                        />
                    </Stack>

                    <ShowErrors
                        errors={errors}
                        mt={3}
                        severity="error"
                        variant="filled"
                    />

                    {!pristine && (
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={3}
                            mt={3}
                        >
                            <Grid item>
                                <Button
                                    color="secondary"
                                    disabled={isSaving}
                                    fullWidth={false}
                                    height="48px"
                                    size="large"
                                    onClick={reset}
                                >
                                    {t('Cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={isSaving}
                                    fullWidth={false}
                                    height="48px"
                                    size="large"
                                    type="submit"
                                >
                                    {isSaving
                                        ? `${t('Loading')}...`
                                        : t('Save')}
                                </Button>
                            </Grid>
                        </Stack>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

DataForm.propTypes = {
    errors: PropTypes.array,
    fetchData: PropTypes.func,
    formValues: PropTypes.object,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSaving: PropTypes.bool,
    pristine: PropTypes.bool,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: () => dispatch(actions.fetchData()),
    handleClickSave: (values) => dispatch(actions.save(values)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(DataForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
