import { rewindProject } from 'common/api/afterSales/projects';
import { transformDateFormat } from 'common/utils/dates';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    REWIND_PROJECT,
    REWIND_PROJECT_FAILURE,
    REWIND_PROJECT_SUCCESS,
} from '../actionTypes';
import { REWIND_TYPES } from '../constants';

const transformDate = (date) => transformDateFormat(date, 'yyy-MM-dd');

const getDateFields = ({ final_date, initial_date, rewind_type }) => {
    switch (rewind_type) {
        case REWIND_TYPES.DAY:
            return {
                finalDate: null,
                initialDate: transformDate(initial_date),
            };
        case REWIND_TYPES.RANGE:
            return {
                finalDate: transformDate(final_date),
                initialDate: transformDate(initial_date),
            };
        default:
            return {
                finalDate: null,
                initialDate: null,
            };
    }
};

export default (values, callback) => (dispatch) => {
    if (!values?.id) return;

    dispatch({ type: REWIND_PROJECT });

    const {
        clear_all_reads,
        company,
        final_date,
        freq,
        id,
        initial_date,
        rewind_type,
    } = values || {};

    const dateFields = getDateFields({
        rewind_type,
        final_date,
        initial_date,
    });

    rewindProject({
        clearAllReads: clear_all_reads,
        companyId: company,
        freq,
        id,
        ...dateFields,
    })
        .then(() => {
            dispatch({ type: REWIND_PROJECT_SUCCESS });

            showToast();
            if (callback) callback();
        })
        .catch((error) => {
            dispatch({ payload: error, type: REWIND_PROJECT_FAILURE });

            showFastApiErrorsAsAlert(dispatch, error?.response);
        });
};
