import { filterCompanies } from 'common/api/companies';

import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
} from '../actionTypes';

export default (filterData) => (dispatch) => {
    dispatch({ type: FETCH_COMPANIES });

    const {
        countryName,
        orderBy,
        page = 0,
        pageSize,
        planName,
        searchText,
        sortBy,
        status,
        subscriptionStatus,
    } = filterData;

    filterCompanies({
        countryName: countryName,
        orderBy,
        page: page + 1,
        perPage: pageSize,
        planName: planName,
        searchText,
        sortBy,
        status,
        subscriptionStatus: subscriptionStatus.join(','),
    })
        .then((response) =>
            dispatch({
                type: FETCH_COMPANIES_SUCCESS,
                payload: response.data,
            })
        )
        .catch((error) => {
            dispatch({
                type: FETCH_COMPANIES_FAILURE,
                payload: error.response.data.errors,
            });

            dispatch({
                type: FETCH_COMPANIES_SUCCESS,
                payload: { data: [] },
            });
        });
};
