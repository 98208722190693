import { getBrands } from 'common/api/productsBrands';

import {
    FETCH_BRANDS,
    FETCH_BRANDS_FAILURE,
    FETCH_BRANDS_SUCCESS,
} from '../actionTypes';

import setFilters from './setFilters';

export default (page = 1, orderBy = '', sortBy = '', searchStr = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_BRANDS });
        dispatch(setFilters({ page, orderBy, sortBy, searchStr }));

        getBrands(page, orderBy, sortBy, searchStr)
            .then((response) =>
                dispatch({
                    type: FETCH_BRANDS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_BRANDS_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_BRANDS_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
