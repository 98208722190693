import React from 'react';
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { DialogContainer } from 'common/components';
import { ReduxFieldDraggableFile } from 'common/components/form/redux';
import { Box, Button, Stack } from 'common/ui';

import { ALLOWED_EXTENSIONS, NAME } from '../constants';

import ReduxSelectTag from './ReduxSelectTag';

const Form = ({
    disabled,
    handleCloseModalForm,
    handleCreateEmptyTrainingData,
    handleSubmit,
    initialValues,
    isOpenModal,
    onSubmit,
    reset,
    tagsOptions,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isOpenModal) reset();
    }, [isOpenModal]);

    return (
        <DialogContainer
            open={isOpenModal}
            fullWidth
            onClose={handleCloseModalForm}
            title={t('Training data')}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    {!initialValues?.id && (
                        <Field
                            accept={ALLOWED_EXTENSIONS}
                            changeFileText={t('Change file', { count: 2 })}
                            component={ReduxFieldDraggableFile}
                            disabled={disabled}
                            fullWidth
                            maxSize={20000000}
                            name="files"
                            uploadText={t('Upload file', { count: 2 })}
                        />
                    )}

                    <Field
                        component={ReduxSelectTag}
                        disabled={disabled}
                        name="tags"
                        multiple
                        options={tagsOptions}
                        variant="outlined"
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { sm: 'row', xs: 'column' },
                            gap: 2,
                            justifyContent: { sm: 'right', xs: 'center' },
                            pt: 2,
                            width: '100%',
                        }}
                    >
                        <Button
                            color="secondary"
                            disabled={disabled}
                            fullWidth={false}
                            height="48px"
                            onClick={handleCreateEmptyTrainingData}
                            size="large"
                            sx={{ textTransform: 'none' }}
                        >
                            {t('Create empty training data')}
                        </Button>
                        <Button
                            disabled={disabled}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            type="submit"
                        >
                            {t('Save')}
                        </Button>
                    </Box>
                </Stack>
            </form>
        </DialogContainer>
    );
};

Form.propTypes = {
    disabled: PropTypes.bool,
    handleCloseModalForm: PropTypes.func,
    handleCreateEmptyTrainingData: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isOpenModal: PropTypes.bool,
    onSubmit: PropTypes.func,
    reset: PropTypes.func,
    tagsOptions: PropTypes.array,
};

export default reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
})(Form);
