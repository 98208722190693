import isEmpty from 'lodash/isEmpty';

import { getCountryCurrency } from 'common/utils/helpers/session';

import { SET_MODAL_IS_CREATE } from '../actionTypes';
import { INIT_VALUES_DEFAULT } from '../constants';

import initializeRateModalForm from './initializeRateModalForm';
import setModalIsOpen from './setModalIsOpen';

export default (country) => (dispatch) => {
    dispatch({ payload: true, type: SET_MODAL_IS_CREATE });
    const currency = getCountryCurrency();
    dispatch(
        initializeRateModalForm({
            ...INIT_VALUES_DEFAULT,
            main_data: {
                ...INIT_VALUES_DEFAULT.main_data,
                country,
                currency: !isEmpty(currency) && currency.id,
            },
        })
    );
    dispatch(setModalIsOpen(true));
};
