import i18next from 'i18next';

import { MONTHS } from 'common/constants';

export const getPoliticalDivisionsOptions = (politicalDivisions) => {
    const options = politicalDivisions.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    return [
        {
            label: i18next.t('All_female_other'),
            value: 'all',
        },
        ...options,
    ];
};

export const getCountriesOptions = (countries) => {
    const options = countries.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    return [
        {
            label: i18next.t('All_female_other'),
            value: 'all',
        },
        ...options,
    ];
};

export const getRateOptions = (rates) => {
    const options = rates.map((item) => ({
        label: item.label,
        value: item.optionValue,
    }));

    return [
        {
            label: i18next.t('All_female_other'),
            value: 'all',
        },
        ...options,
    ];
};

export const getYears = () => {
    const date = new Date();
    let years = [];
    for (let i = 2019; i <= date.getFullYear(); ++i)
        years.push({ label: i.toString(), value: i.toString() });

    return [
        {
            label: i18next.t('All_male_other'),
            value: 'all',
        },
        ...years,
    ];
};

export const getMonths = () => {
    const months = MONTHS.map((month, index) => ({
        label: i18next.t(month),
        value: index + 1,
    }));
    return [
        {
            label: i18next.t('All_male_other'),
            value: 'all',
        },
        ...months,
    ];
};
