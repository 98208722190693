import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { debounce, isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    CustomPagination,
    ProductsBulkOperationsCard,
    ProductColumns,
} from 'common/components';
import { Card, CardContent, DataGrid } from 'common/ui';
import { getDataGridClasses } from 'common/utils/helpers';

const DEBOUNCE_TIME = 1500;

const ListItems = ({
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    items,
    paginationData,
    panelBrands,
    setFilterData,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [rowCount, setRowCount] = useState(0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedBulkOption, setSelectedBulkOption] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeMaxPower = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            maxPower: event?.target?.value,
        });
    };

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSelectBrand = (value) => {
        const filter = {
            ...filterData,
            brandName: value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSelectStatus = (value) => {
        const filter = { ...filterData, page: 0, status: value };
        setFilterData(filter);
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const handleRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const processRowUpdate = (newRow) => {
        handleSaveRow(newRow);
        return newRow;
    };

    return (
        <>
            <ProductsBulkOperationsCard
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleOnChangeMaxPower={handleOnChangeMaxPower}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectBrand={handleOnSelectBrand}
                handleOnSelectStatus={handleOnSelectStatus}
                maxPowerLabel="W"
                prefix={t('Product', {
                    count: selectionModel.length,
                }).toLocaleLowerCase()}
                productBrands={panelBrands}
                selectedBulkOption={selectedBulkOption}
                selectionModel={selectionModel}
                setSelectedBulkOption={setSelectedBulkOption}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <CardContent>
                    <DataGrid
                        autoHeight
                        checkboxSelection={true}
                        columns={ProductColumns({
                            filterData,
                            handleClickBulkItems,
                            handleClickDuplicate,
                            handleClickUpdate,
                            productKey: 'panels',
                            rowModesModel,
                            selectedRowId,
                            setRowModesModel,
                            setSelectedRowId,
                        })}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onRowModesModelChange={(newModel) =>
                            setRowModesModel(newModel)
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                        sx={getDataGridClasses(isDarkMode)}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    panelBrands: PropTypes.array,
    setFilterData: PropTypes.func,
};

export default ListItems;
