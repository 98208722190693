import { NAME } from './constants';

export const FETCH_BRANDS = `${NAME}/FETCH_BRANDS`;
export const FETCH_BRANDS_FAILURE = `${NAME}/FETCH_BRANDS_FAILURE`;
export const FETCH_BRANDS_SUCCESS = `${NAME}/FETCH_BRANDS_SUCCESS`;

export const FETCH_BRAND_DETAIL = `${NAME}/FETCH_BRAND_DETAIL`;
export const FETCH_BRAND_DETAIL_FAILURE = `${NAME}/FETCH_BRAND_DETAIL_FAILURE`;
export const FETCH_BRAND_DETAIL_SUCCESS = `${NAME}/FETCH_BRAND_DETAIL_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_BRAND_FORM = `${NAME}/OPEN_BRAND_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const FETCH_ORIGINS = `${NAME}/FETCH_ORIGINS`;
export const FETCH_ORIGINS_SUCCESS = `${NAME}/FETCH_ORIGINS_SUCCESS`;
export const FETCH_ORIGINS_FAILURE = `${NAME}/FETCH_ORIGINS_FAILURE`;

export const SAVE_ORIGIN = `${NAME}/SAVE_ORIGIN`;
export const SAVE_ORIGIN_SUCCESS = `${NAME}/SAVE_ORIGIN_SUCCESS`;
export const SAVE_ORIGIN_FAILURE = `${NAME}/SAVE_ORIGIN_FAILURE`;

export const SET_FILTERS = `${NAME}/SET_FILTERS`;
export const SET_FAMILIES_FILTERS = `${NAME}/SET_FAMILIES_FILTERS`;
export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;

export const DELETE_BRAND = `${NAME}/DELETE_BRAND`;
export const DELETE_BRAND_SUCCESS = `${NAME}/DELETE_BRAND_SUCCESS`;
export const DELETE_BRAND_FAILURE = `${NAME}/DELETE_BRAND_FAILURE`;

export const FETCH_FAMILIES = `${NAME}/FETCH_FAMILIES`;
export const FETCH_FAMILIES_FAILURE = `${NAME}/FETCH_FAMILIES_FAILURE`;
export const FETCH_FAMILIES_SUCCESS = `${NAME}/FETCH_FAMILIES_SUCCESS`;
