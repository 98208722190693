import styled from 'styled-components';

import { Modal } from 'common/ui';

export default styled(Modal)`
    && {
        .modal-header {
            background-color: #ff9a00;

            ${({ variant }) =>
                variant === 'success' &&
                `
                background-color: #00B667;
            `}

            ${({ variant }) =>
                variant === 'info' &&
                `
                background-color: #008DFF;
            `}

            ${({ variant }) =>
                variant === 'error' &&
                `
                background-color: #FA6968;
            `}

            ${({ variant }) =>
                variant === 'warning' &&
                `
                background-color: #ff9a00;
            `}

            color: #ffffff;
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
            padding: 15px;

            .title,
            .fas {
                vertical-align: middle;
            }

            .fas {
                margin-right: 8px;
            }
        }

        .close {
            height: 38px;
            width: 38px;
            background-color: #f9fafe;
            border-radius: 100%;
            position: absolute;
            right: 30px;
            top: 30px;
        }

        .modal-body {
            padding: 15px;
        }

        h6 {
            color: #003b4b;
            font-size: 13px;
            font-weight: 600;
            line-height: 22px;
            text-align: center;
        }
    }
`;
