import i18next from 'i18next';

import { updateBrand } from 'common/api/productsBrands';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchBrands from './fetchBrands';
import resetForm from './resetForm';
import toggleBrandForm from './toggleBrandForm';

export default (values, id) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = new FormData();
    newValues.append('name', values.name);
    newValues.append('country', values.country);

    updateBrand(newValues, id)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchBrands(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleBrandForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while updating the brand'),
            });
        });
};
