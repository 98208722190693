import { updateFamily } from 'common/api/productsFamilies';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';

import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (values, id, onSuccessCallback = null) =>
    (dispatch) => {
        var newValues = new FormData();
        newValues.append('name', values.name);

        updateFamily(newValues, id)
            .then((response) => {
                dispatch({ type: SAVE_SUCCESS, payload: response.data });

                dispatch(toggleModal(false));
                dispatch(resetForm());
                showToast();

                if (onSuccessCallback) {
                    onSuccessCallback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_FAILURE,
                    payload: error.response.data.errors,
                });
            });
    };
