import fetchOrigins from './fetchOrigins';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleItemForm from './toggleItemForm';

export default () => (dispatch) => {
    dispatch(fetchOrigins());

    dispatch(toggleItemForm(true));
    dispatch(setIsNewRecord(true));
    dispatch(setEnabledEdition(true));
    dispatch(resetForm());
};
