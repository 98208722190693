import API from '../..';
const ENTITY = 'alliances';

export const activeAlliance = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/financiers/${id}/`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const exportFile = ({ searchText = '' } = {}) =>
    API.get(`/api/v1/${ENTITY}/download/`, {
        params: {
            ...(searchText ? { search: searchText } : {}),
        },
    });

export const filterAlliances = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/financiers/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });

export const getActiveCiBancoAlliancesReport = (financierId = null) =>
    API.get(`/api/v1/${ENTITY}/report/`, {
        params: {
            ...(financierId ? { financier: financierId } : {}),
        },
    });
