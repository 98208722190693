export const MAX_FILE_SIZE = 20971520;

export const DO_NOT_ASSIGN_VALUE = 'DO_NOT_ASSIGN';

export const SUBSCRIPTION_STATUS = {
    FUTURE: { key: 'future', color: '#9174a3' },
    INTRIAL: { key: 'in_trial', color: '#20c4d9' },
    ACTIVE: { key: 'active', color: '#44b700' },
    NON_RENEWING: { key: 'non_renewing', color: '#da1e28' },
    PAUSED: { key: 'paused', color: '#ffb84d' },
    CANCELLED: { key: 'cancelled', color: '#da1e28' },
    NO_SUBSCRIPTION: { key: 'no_subscription', color: '#747679' },
};
export const DEBOUNCE_TIME = 1500;
export const HOURS_IN_DAY = 24;
export const HOURS_IN_YEAR = 8760;
export const MONTHS_IN_YEAR = 12;
export const MS_IN_HOUR = 3600 * 1000;

export const HOURS = [
    '12am',
    '1am',
    '2am',
    '3am',
    '4am',
    '5am',
    '6am',
    '7am',
    '8am',
    '9am',
    '10am',
    '11am',
    '12pm',
    '1pm',
    '2pm',
    '3pm',
    '4pm',
    '5pm',
    '6pm',
    '7pm',
    '8pm',
    '9pm',
    '10pm',
    '11pm',
];

export const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const PROPOSAL_STATUS = {
    DRAFT: 'draft',
    GENERATED: 'generated',
};

export const TEMPLATE_LANGUAGES = {
    ES_MX: 'es-mx',
    EN_US: 'en-us',
};

export const TEMPLATE_TYPES = {
    PROPOSAL_TYPE: 'proposal',
    BOM_TYPE: 'bom',
    SYSTEM_TYPE: 'system',
    PDF_TYPE: 'pdf',
};
