import i18next from 'i18next';

import { SUBSCRIPTION_STATUS } from 'common/constants';

export const getStatusDetails = (status) => {
    switch (status) {
        case SUBSCRIPTION_STATUS.ACTIVE.key:
            return i18next.t('Active', { context: 'female' });
        case SUBSCRIPTION_STATUS.FUTURE.key:
            return i18next.t('Future');
        case SUBSCRIPTION_STATUS.INTRIAL.key:
            return i18next.t('In trial period');
        case SUBSCRIPTION_STATUS.NON_RENEWING.key:
            return i18next.t('Non renewing');
        case SUBSCRIPTION_STATUS.PAUSED.key:
            return i18next.t('Paused');
        case SUBSCRIPTION_STATUS.CANCELLED.key:
            return i18next.t('Cancelled');
        case SUBSCRIPTION_STATUS.NO_SUBSCRIPTION.key:
            return i18next.t('Without subscription');
        default:
            return i18next.t('Inactive');
    }
};

export const getStatusName = (hasPaymentDue, status) => {
    if (hasPaymentDue) return i18next.t('Payment due');
    return getStatusDetails(status);
};

export const getCountryOptions = (countries) => {
    return countries.map((item) => ({
        label: item.name,
        value: item.name,
    }));
};

export const getPlansOptions = (plans) => {
    return plans.map((item) => ({
        label: item.name,
        value: item.name,
    }));
};

export const getStatusOptions = () => {
    const statusArray = Object.values(SUBSCRIPTION_STATUS);
    return statusArray.map((item) => ({
        label: getStatusDetails(item.key),
        value: item.key,
    }));
};
