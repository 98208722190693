import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { HiddenComponent } from 'common/components';
import { SearchTable } from 'common/components/tables';
import { Box, Button, IconButton, Toolbar, Tooltip } from 'common/ui';

const StyledContainer = styled(Toolbar)({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledIconButton = styled(IconButton)(({ theme, color }) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
    margin: '4px 6px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    width: '85%',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

const TableToolbar = ({
    isFetching,
    isFetchingCSV,
    handleDownloadFile,
    handleOnSearchChange,
}) => {
    const { t } = useTranslation();
    const title = isFetchingCSV
        ? t('Downloading CSV').concat('...')
        : t('Download CSV');
    const disabled = isFetching || isFetchingCSV;

    return (
        <StyledContainer disableGutters>
            <StyledBox>
                <SearchTable
                    handleOnSearchChange={handleOnSearchChange}
                    placeholder={`${t('Search')}...`}
                    variant="filled"
                />
            </StyledBox>

            <HiddenComponent width="lgDown">
                <Button
                    disabled={disabled}
                    fullWidth={false}
                    height="48px"
                    startIcon={<DownloadIcon />}
                    type="button"
                    onClick={handleDownloadFile}
                >
                    {title}
                </Button>
            </HiddenComponent>
            <HiddenComponent width="lgUp">
                <Tooltip title={title}>
                    <span>
                        <StyledIconButton
                            color="primary"
                            disabled={disabled}
                            onClick={handleDownloadFile}
                        >
                            <DownloadIcon />
                        </StyledIconButton>
                    </span>
                </Tooltip>
            </HiddenComponent>
        </StyledContainer>
    );
};

TableToolbar.propTypes = {
    isFetching: PropTypes.bool,
    isFetchingCSV: PropTypes.bool,
    handleDownloadFile: PropTypes.func,
    handleOnSearchChange: PropTypes.func,
};

export default TableToolbar;
