import React from 'react';

import i18next from 'i18next';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';

import { Alert, AlertTitle, Box, Stack, Typography } from 'common/ui';

const ShowErrors = ({
    errors,
    severity = 'warning',
    title = i18next.t('Please correct the following errors').concat(':'),
    variant = 'outlined',
    ...other
}) => {
    if (!errors || errors.length === 0) return null;
    const dot = errors.length > 1 ? '*' : '';

    if (!isArray(errors)) return null;

    return (
        <Box {...other}>
            <Alert severity={severity} variant={variant}>
                {errors.length > 1 && <AlertTitle>{title}</AlertTitle>}
                <Stack spacing={1}>
                    {errors.map((error, index) => {
                        return (
                            <Typography
                                key={`label-error-service-${index}-${Math.random()}`}
                                variant="caption"
                            >
                                {dot} {error}
                            </Typography>
                        );
                    })}
                </Stack>
            </Alert>
        </Box>
    );
};

ShowErrors.propTypes = {
    errors: PropTypes.array,
    severity: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.string,
};

export default ShowErrors;
