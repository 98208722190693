import { SAVE } from '../actionTypes';

import createCategory from './createCategory';
import updateCategory from './updateCategory';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE });
    if (values.id) {
        dispatch(updateCategory(values, values.id));
    } else {
        dispatch(createCategory(values));
    }
};
