import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    Box,
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    Typography,
} from 'common/ui';
import {
    getIsGuest,
    getPermissionsLocalStorage,
} from 'common/utils/helpers/session';

import LoginIlustration2 from 'resources/login/ilustration_2.svg';
import LoginIlustration3 from 'resources/login/ilustration_3.svg';
import LoginBackground from 'resources/login/loginBackground.png';
import HorizontalLogotype from 'resources/logotypes/HorizontalSunwiseStaffLogotype.svg';

import * as actions from './actions';
import FirstStepAuthForm from './components/FirstStepAuthForm';
import SecondStepAuthForm from './components/SecondStepAuthForm';
import { getPermissionRedirect } from './helpers';
import * as selectors from './selectors';

const StyledGrid = styled(Grid)(
    ({ theme }) => `
    background-color: ${theme.palette.mode === 'dark' ? '#000000' : '#fff'};
`
);

const StyledCol = styled(Grid)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#081019' : '#f6f8fa'};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    backgroundImage: url(${LoginBackground});
`
);

const MainContainer = ({
    errorsInitAuth,
    errorsSession,
    formValues,
    initAuth,
    initialValues,
    initResendCode,
    isLoadingInitAuth,
    isLoadingSession,
    isResendingCode,
    loginUser,
    redirect,
    showSecondStepAuthForm,
    updateTooltipState,
    validPassword,
}) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!getIsGuest()) redirect('/companies');
        const permissions = getPermissionsLocalStorage();
        if (!isEmpty(permissions)) {
            const permission = permissions[0].group;
            getPermissionRedirect(permission, redirect);
        }
    }, []);

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };
    const handleSubmitInitAuth = (values) => initAuth(values);
    const handleResendCode = (values) => initResendCode(values);
    const handleSubmitInitSession = (values) => loginUser(values);

    const onChangePassword = (event) => updateTooltipState(event);

    return (
        <Container disableGutters maxWidth="" sx={{ height: '100%' }}>
            <Grid container sx={{ alignItems: 'center', height: '100%' }}>
                <StyledGrid
                    item
                    lg={6}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                    }}
                    xs={12}
                >
                    <Card
                        sx={{ p: 2, maxWidth: '670px', mx: 2, width: '100%' }}
                    >
                        <CardContent>
                            <Box sx={{ textAlign: 'center' }}>
                                <img
                                    alt="Sunwise Staff Logotype"
                                    loading="lazy"
                                    src={HorizontalLogotype}
                                    width="533px"
                                />
                            </Box>

                            <Box py={2} textAlign="center">
                                <Typography variant="h5">
                                    {showSecondStepAuthForm
                                        ? t('Validate')
                                        : t('Log in')}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>
                                    {showSecondStepAuthForm
                                        ? t(
                                              'We send you a temporary verification code to the email associated with your account'
                                          )
                                        : t(
                                              'Identify yourself as a Sunwise user with your email'
                                          )}
                                </Typography>
                            </Box>

                            <Divider sx={{ mb: 3 }} />

                            {showSecondStepAuthForm ? (
                                <SecondStepAuthForm
                                    errorsSession={errorsSession}
                                    formValues={formValues}
                                    handleResendCode={handleResendCode}
                                    initialValues={initialValues}
                                    isLoadingSession={isLoadingSession}
                                    isResendingCode={isResendingCode}
                                    onSubmit={handleSubmitInitSession}
                                />
                            ) : (
                                <FirstStepAuthForm
                                    errorsInitAuth={errorsInitAuth}
                                    handleShowPassword={handleShowPassword}
                                    initialValues={initialValues}
                                    isLoadingInitAuth={isLoadingInitAuth}
                                    onChangePassword={onChangePassword}
                                    onSubmit={handleSubmitInitAuth}
                                    showPassword={showPassword}
                                    validPassword={validPassword}
                                />
                            )}
                        </CardContent>
                    </Card>
                </StyledGrid>

                <StyledCol
                    item
                    md={6}
                    sx={{
                        alignItems: 'center',
                        display: { lg: 'flex', xs: 'none' },
                        height: '100%',
                    }}
                    xs={12}
                >
                    <img
                        alt="Login Ilustration"
                        loading="lazy"
                        src={
                            showSecondStepAuthForm
                                ? LoginIlustration3
                                : LoginIlustration2
                        }
                        width="80%"
                    />
                </StyledCol>
            </Grid>
        </Container>
    );
};

MainContainer.propTypes = {
    errorsInitAuth: PropTypes.array,
    errorsSession: PropTypes.array,
    formValues: PropTypes.object,
    initAuth: PropTypes.func,
    initialValues: PropTypes.object,
    initResendCode: PropTypes.func,
    isLoadingInitAuth: PropTypes.bool,
    isLoadingSession: PropTypes.bool,
    isResendingCode: PropTypes.bool,
    loginUser: PropTypes.func,
    redirect: PropTypes.func,
    showSecondStepAuthForm: PropTypes.bool,
    updateTooltipState: PropTypes.func,
    validPassword: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    errorsInitAuth: selectors.getErrorsInitAuth,
    errorsSession: selectors.getErrorsSession,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isLoadingInitAuth: selectors.getIsLoadingInitAuth,
    isLoadingSession: selectors.getIsLoadingSession,
    isResendingCode: selectors.getIsLoadingResendCode,
    showSecondStepAuthForm: selectors.getIsShowSecondStepAuthForm,
    validPassword: selectors.getValidPassword,
});

const mapDispatchToProps = (dispatch) => ({
    initAuth: (data) => dispatch(actions.preAuth(data)),
    initResendCode: (data) => dispatch(actions.resendCode(data)),
    loginUser: (data) => dispatch(actions.auth(data)),
    redirect: (link) => dispatch(push(link)),
    updateTooltipState: (event) => dispatch(actions.updateTooltipState(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
