import i18next from 'i18next';

import { createCategory } from 'common/api/templatesCategories';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchCategories from './fetchCategories';
import resetForm from './resetForm';
import toggleCategoryForm from './toggleCategoryForm';

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = {
        name: values.name,
    };

    if (Array.isArray(values.file_url)) {
        newValues.file_url = values.file_url && values.file_url[0];
    }

    createCategory(newValues)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchCategories(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleCategoryForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t(
                    'An error occurred while creating the category'
                ),
            });
        });
};
