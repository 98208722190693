import React, { useEffect, useState } from 'react';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import CardContainer from 'common/components/CardContainer';
import {
    ReduxFieldAutocomplete,
    ReduxFieldInput,
    ReduxFieldSelect,
    ReduxFieldFile,
    ReduxFieldSwitch,
} from 'common/components/form/redux';
import {
    Box,
    Button,
    CardMedia,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Typography,
    Tooltip,
} from 'common/ui';

import TemplatePlaceholder from 'resources/placeholders/placeholder_template.svg';

import * as actions from '../actions';
import { NAME, ALLOWED_EXTENSIONS } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const StyledThumbnail = styled(CardMedia)(() => ({
    position: 'relative',
    height: 218,
    width: 140,
    backgroundSize: 'contain',
    padding: '0.25rem',
    backgroundColor: '#EEF4FA',
    borderRadius: '0.55rem',
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiThumbnailBackdrop-root': {
            opacity: 1,
        },
        '& .MuiBackdrop-root': {
            opacity: 0.15,
        },
    },
}));

const ThumbnailBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    zIndex: 2,
}));

const Backdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    zIndex: 3,
}));

const StyledIconButton = styled(IconButton)(({ theme, color }) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,

    '&:hover': {
        backgroundColor: theme.palette[color].light,
    },
}));

const DEBOUNCE_TIME = 1000;

const TemplateForm = ({
    categories,
    changeInput,
    customTemplates,
    errors,
    formValues,
    getCategories,
    getCustomTemplates,
    getLanguages,
    getOrigins,
    handleClickSave,
    handleEditionChange,
    handleSubmit,
    isDeleting,
    isEnabledEdition,
    isFetchingCategories,
    isFetchingCustomTemplates,
    isFetchingDetail,
    isFetchingLanguages,
    isFetchingOrigins,
    isNewRecord,
    isSaving,
    languages = [],
    origins,
    prepareDelete,
    prepareReturnCatalog,
    setCurrentItem,
}) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        getLanguages();
    }, []);

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    const handleDelete = (item) => {
        prepareDelete(item);
    };

    const handleChangeCompanyIdField = (value) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(value), DEBOUNCE_TIME));
    };

    const triggerChange = (value) => {
        changeInput('custom_offer_template', null);
        getCustomTemplates(value, '');
    };

    const handleChangeCustomTemplatesField = (str) => {
        getCustomTemplates(formValues.company_id, str);
    };

    return (
        <Stack spacing={3}>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title={t('Back')}>
                    <IconButton
                        onClick={() => {
                            prepareReturnCatalog();
                            setCurrentItem({});
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <FormGroup>
                    <FormControlLabel
                        disabled={isNewRecord || isFetchingDetail}
                        control={
                            <Switch
                                checked={isEnabledEdition}
                                onChange={handleEditionSwitchChange}
                            />
                        }
                        label={t('Edit')}
                        labelPlacement="start"
                    />
                </FormGroup>
            </Stack>

            <Box>
                <form>
                    <Stack spacing={3}>
                        <CardContainer
                            title={t('Template information')}
                            isCollapsable
                            isOpen
                            loading={isFetchingDetail}
                            withDivider
                        >
                            <Grid container spacing={2} pt={3}>
                                <Grid item xs={12} md={9}>
                                    <Grid item container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="title"
                                                component={ReduxFieldInput}
                                                placeholder={t('Name')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="category"
                                                component={
                                                    ReduxFieldAutocomplete
                                                }
                                                placeholder={t('Category', {
                                                    count: 1,
                                                })}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                                options={categories}
                                                handleOnSearchChange={
                                                    getCategories
                                                }
                                                loading={isFetchingCategories}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={2} pt={2}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="country"
                                                component={
                                                    ReduxFieldAutocomplete
                                                }
                                                placeholder={t('Country')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                                options={origins}
                                                handleOnSearchChange={
                                                    getOrigins
                                                }
                                                loading={isFetchingOrigins}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="languages"
                                                component={ReduxFieldSelect}
                                                label={t('Language', {
                                                    count: 1,
                                                })}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition ||
                                                    isFetchingLanguages ||
                                                    !isNewRecord
                                                }
                                                options={languages}
                                                multiple
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={2} pt={2}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="company_id"
                                                component={ReduxFieldInput}
                                                placeholder={t('Company ID')}
                                                variant="outlined"
                                                required={isNewRecord}
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                                onChange={(e) => {
                                                    handleChangeCompanyIdField(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="custom_offer_template"
                                                component={
                                                    ReduxFieldAutocomplete
                                                }
                                                placeholder={t('Template base')}
                                                variant="outlined"
                                                required={isNewRecord}
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition ||
                                                    !formValues.company_id
                                                }
                                                options={customTemplates}
                                                handleOnSearchChange={
                                                    handleChangeCustomTemplatesField
                                                }
                                                loading={
                                                    isFetchingCustomTemplates
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={2} pt={2}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="locked"
                                                component={ReduxFieldSwitch}
                                                label={t('Locked')}
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="div"
                                        mt={4}
                                        mb={4}
                                    >
                                        * {t('Required fields')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={3}
                                    justifyContent="center"
                                >
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Box
                                            component="label"
                                            htmlFor={
                                                isEnabledEdition
                                                    ? 'thumbnail_image_01'
                                                    : ''
                                            }
                                            sx={{
                                                cursor: isEnabledEdition
                                                    ? 'pointer'
                                                    : 'normal',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <StyledThumbnail
                                                image={
                                                    Array.isArray(
                                                        formValues?.image_thumbnail
                                                    )
                                                        ? formValues
                                                              ?.image_thumbnail[0]
                                                              .preview
                                                        : !isNil(
                                                              formValues.image_thumbnail
                                                          )
                                                        ? formValues.image_thumbnail
                                                        : TemplatePlaceholder
                                                }
                                                alt="Template thumbnail or placeholder"
                                            >
                                                {isEnabledEdition && (
                                                    <>
                                                        <ThumbnailBackdrop className="MuiThumbnailBackdrop-root">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                spacing={2}
                                                            >
                                                                <StyledIconButton color="primary">
                                                                    <AddPhotoAlternateIcon />
                                                                </StyledIconButton>
                                                            </Stack>
                                                        </ThumbnailBackdrop>
                                                        <Backdrop
                                                            className="MuiBackdrop-root"
                                                            htmlFor="thumbnail_image_01"
                                                        />
                                                    </>
                                                )}
                                            </StyledThumbnail>
                                        </Box>

                                        <Typography
                                            align="center"
                                            variant="body2"
                                            color="text.secondary"
                                            component="div"
                                        >
                                            <strong>{t('Thumbnail')}</strong>
                                        </Typography>

                                        <Typography
                                            align="center"
                                            variant="caption"
                                            color="text.secondary"
                                            component="div"
                                        >
                                            {t('JPG format')} - 140 x 218 px -
                                            2MB máx
                                        </Typography>

                                        <Box sx={{ display: 'none' }}>
                                            <Field
                                                accept={ALLOWED_EXTENSIONS}
                                                id="thumbnail_image_01"
                                                component={ReduxFieldFile}
                                                placeholder=""
                                                name="image_thumbnail"
                                                variant="profile"
                                                maxSize={2000000}
                                                onChange={() => {}}
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContainer>

                        <ShowErrors
                            errors={errors}
                            mt={3}
                            severity="error"
                            variant="filled"
                        />

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={3}
                            pt={3}
                            pb={3}
                        >
                            <Grid container mt={2}>
                                <Grid item>
                                    {!isNewRecord && isEnabledEdition && (
                                        <Button
                                            color="error"
                                            disabled={
                                                isFetchingDetail ||
                                                isSaving ||
                                                isDeleting
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={() => {
                                                handleDelete(formValues);
                                            }}
                                            variant="text"
                                        >
                                            {t('Delete template')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="flex-end"
                            >
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={isFetchingDetail || isSaving}
                                        fullWidth={false}
                                        height="48px"
                                        size="large"
                                        onClick={() => {
                                            prepareReturnCatalog();
                                            setCurrentItem({});
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </Grid>
                                {isEnabledEdition && (
                                    <Grid item>
                                        <Button
                                            disabled={
                                                isFetchingDetail || isSaving
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={handleSubmit(
                                                handleClickSave
                                            )}
                                            type="submit"
                                        >
                                            {isSaving
                                                ? `${t('Loading')}...`
                                                : isNewRecord
                                                ? t('Create')
                                                : t('Save')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </Stack>
    );
};

TemplateForm.propTypes = {
    categories: PropTypes.array,
    changeInput: PropTypes.func,
    customTemplates: PropTypes.array,
    errors: PropTypes.array,
    formValues: PropTypes.object,
    getCategories: PropTypes.func,
    getCustomTemplates: PropTypes.func,
    getLanguages: PropTypes.func,
    getOrigins: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleEditionChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isDeleting: PropTypes.bool,
    isEnabledEdition: PropTypes.bool,
    isFetchingCategories: PropTypes.bool,
    isFetchingCustomTemplates: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isFetchingLanguages: PropTypes.bool,
    isFetchingOrigins: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    languages: PropTypes.array,
    origins: PropTypes.array,
    prepareDelete: PropTypes.func,
    prepareReturnCatalog: PropTypes.func,
    setCurrentItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    categories: selectors.getCategoriesOptionsForSelect,
    customTemplates: selectors.getCustomTemplatesOptionsForSelect,
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isDeleting: selectors.getIsDeletingTemplate,
    isEnabledEdition: selectors.getEnabledEdition,
    isFetchingCategories: selectors.getIsFetchingCategories,
    isFetchingCustomTemplates: selectors.getIsFetchingCustomTemplates,
    isFetchingDetail: selectors.getIsFetchingTemplateDetail,
    isFetchingLanguages: selectors.getIsFetchingLanguages,
    isFetchingOrigins: selectors.getIsFetchingOrigins,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
    languages: selectors.getLanguagesOptionsForSelect,
    origins: selectors.getOriginsOptionsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    getCategories: (str) => dispatch(actions.fetchCategories(str)),
    getCustomTemplates: (id, str) =>
        dispatch(actions.fetchCustomTemplates(id, str)),
    getLanguages: (str) => dispatch(actions.fetchLanguages(str)),
    getOrigins: (str) => dispatch(actions.fetchOrigins(str)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    prepareDelete: (item) => dispatch(actions.prepareDelete(item)),
    prepareReturnCatalog: () => dispatch(actions.returnCatalog()),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(TemplateForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
