import update from 'immutability-helper';

import {
    FETCH_CATEGORIES_SETTINGS,
    FETCH_CATEGORIES_SETTINGS_FAILURE,
    FETCH_CATEGORIES_SETTINGS_SUCCESS,
    FETCH_ROLES,
    FETCH_ROLES_FAILURE,
    FETCH_ROLES_SUCCESS,
    FETCH_ROLE_DETAIL,
    FETCH_ROLE_DETAIL_FAILURE,
    FETCH_ROLE_DETAIL_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL_EDITION,
    RESET,
    RESET_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_FILTERS,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        name: '',
        maximum_discount: 0,
        type: null,
        owner_type: null,
        contacts_visualization_level: null,
        groups: [],
        enabled_groups: [],
        contact_groups_view: false,
        contact_groups_add: false,
        contact_groups_edit: false,
        contact_groups_delete: false,
        proposer_groups_view: false,
        proposer_groups_add: false,
        proposer_groups_edit: false,
        proposer_groups_delete: false,
        configuration_groups_view: false,
        configuration_groups_add: false,
        configuration_groups_edit: false,
        configuration_groups_delete: false,
        company_id: '',
        isEnabledEdition: false,
    },
    roles: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    isOpenModalEdition: false,
    filters: {
        id: '',
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    roleDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    categoriesSettings: {
        isFetching: false,
        data: [],
        error: null,
    },
    saveItem: {
        isSaving: false,
        data: {},
        errors: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case FETCH_ROLES:
            return update(state, {
                roles: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_ROLES_FAILURE:
            return update(state, {
                roles: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_ROLES_SUCCESS:
            return update(state, {
                roles: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_ROLE_DETAIL:
            return update(state, {
                roleDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_ROLE_DETAIL_FAILURE:
            return update(state, {
                roleDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_ROLE_DETAIL_SUCCESS:
            return update(state, {
                roleDetail: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_CATEGORIES_SETTINGS:
            return update(state, {
                categoriesSettings: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_CATEGORIES_SETTINGS_SUCCESS:
            return update(state, {
                categoriesSettings: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_CATEGORIES_SETTINGS_FAILURE:
            return update(state, {
                categoriesSettings: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                saveItem: {
                    $set: INITIAL_STATE.saveItem,
                },
            });

        case OPEN_MODAL_EDITION:
            return update(state, {
                isOpenModalEdition: {
                    $set: action.payload,
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case SAVE_ITEM:
            return update(state, {
                saveItem: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_ITEM_SUCCESS:
            return update(state, {
                saveItem: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_ITEM_FAILURE:
            return update(state, {
                saveItem: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        default:
            return state;
    }
}
