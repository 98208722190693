import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const DELETE_USER = `${NAME}/DELETE_USER`;
export const DELETE_USER_FAILURE = `${NAME}/DELETE_USER_FAILURE`;
export const DELETE_USER_SUCCESS = `${NAME}/DELETE_USER_SUCCESS`;

export const FETCH_ROLES = `${NAME}/FETCH_ROLES`;
export const FETCH_ROLES_FAILURE = `${NAME}/FETCH_ROLES_FAILURE`;
export const FETCH_ROLES_SUCCESS = `${NAME}/FETCH_ROLES_SUCCESS`;

export const FETCH_USERS = `${NAME}/FETCH_USERS`;
export const FETCH_USERS_FAILURE = `${NAME}/FETCH_USERS_FAILURE`;
export const FETCH_USERS_SUCCESS = `${NAME}/FETCH_USERS_SUCCESS`;

export const FETCH_USER_DETAILS = `${NAME}/FETCH_USER_DETAILS`;
export const FETCH_USER_DETAILS_FAILURE = `${NAME}/FETCH_USER_DETAILS_FAILURE`;
export const FETCH_USER_DETAILS_SUCCESS = `${NAME}/FETCH_USER_DETAILS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_USER = `${NAME}/SAVE_USER`;
export const SAVE_USER_FAILURE = `${NAME}/SAVE_USER_FAILURE`;
export const SAVE_USER_SUCCESS = `${NAME}/SAVE_USER_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;
