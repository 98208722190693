import React from 'react';

import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';
import { Typography } from 'common/ui';
import { formatDate, formatDistanceDate, parseDate } from 'common/utils/dates';

import {
    EmailFormatter,
    SubscriptionStatusFormatter,
} from '../../companies/components';

import NameFormatter from './NameFormatter';
import UsabilityFormatter from './UsabilityFormatter';

const ProductColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const nameColumn = [
        {
            align: 'left',
            field: 'name',
            headerName: t('Company', { count: 1 }),
            renderCell: (params) => {
                const { row } = params;
                return <NameFormatter row={row} />;
            },
            width: 200,
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'owner_email',
            headerName: `${t('Owner')} (${t('Email')})`,
            renderCell: (params) => {
                const { row } = params;
                return <EmailFormatter email={row?.owner_email} />;
            },
            width: 200,
        },
        {
            align: 'left',
            field: 'created_at',
            headerName: t('Creation date'),
            renderCell: (params) => {
                const { row } = params;
                if (!row?.created_at) return '';
                const createdAtDate = parseDate(
                    row.created_at,
                    'dd/MM/yyyy HH:mm:ss'
                );
                return (
                    <Typography variant="caption">
                        {formatDate(createdAtDate, 'dd/MM/yyyy')}
                    </Typography>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'created_at_2',
            headerName: t('Age of creation'),
            renderCell: (params) => {
                const { row } = params;
                let AgeDate = '';
                if (row?.created_at)
                    AgeDate = formatDistanceDate(
                        parseDate(row?.created_at, 'dd/MM/yyyy HH:mm:ss'),
                        new Date(),
                        { addSuffix: true }
                    );
                return <Typography variant="caption">{AgeDate}</Typography>;
            },
            sortable: false,
            width: 180,
        },
        {
            align: 'left',
            field: 'last_subscription_date',
            headerName: t('Last subscription date'),
            renderCell: (params) => {
                const { row } = params;
                if (!row?.last_subscription_date) return '';
                const lastSubscriptionDate = parseDate(
                    row.last_subscription_date,
                    'dd/MM/yyyy HH:mm:ss'
                );
                return (
                    <Typography variant="caption">
                        {formatDate(lastSubscriptionDate, 'dd/MM/yyyy')}
                    </Typography>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'last_subscription_date_2',
            headerName: t('Age of subscription'),
            renderCell: (params) => {
                const { row } = params;
                let AgeDate = '';
                if (row?.last_subscription_date)
                    AgeDate = formatDistanceDate(
                        parseDate(
                            row?.last_subscription_date,
                            'dd/MM/yyyy HH:mm:ss'
                        ),
                        new Date(),
                        { addSuffix: true }
                    );
                return <Typography variant="caption">{AgeDate}</Typography>;
            },
            sortable: false,
            width: 180,
        },
        {
            align: 'left',
            field: 'counter_a_proposals',
            flex: 1,
            headerName: `${t('Proposal', { count: 2 })} ${t('Rank')} A`,
        },
        {
            align: 'left',
            field: 'counter_b_proposals',
            flex: 1,
            headerName: `${t('Proposal', { count: 2 })} ${t('Rank')} B`,
        },
        {
            align: 'left',
            field: 'usability',
            flex: 1,
            headerName: `${t('Usability')} %`,
            renderCell: (params) => {
                const { row } = params;
                return <UsabilityFormatter row={row} />;
            },
        },
        {
            align: 'left',
            field: 'subscription_status',
            flex: 1,
            headerName: t('Subscription'),
            renderCell: (params) => {
                const { row } = params;
                return <SubscriptionStatusFormatter row={row} />;
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    return columns;
};

export default ProductColumns;
