import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Typography } from 'common/ui';

const handleProductType = (type, t) =>
    type === 'special' ? t('Special') : t('Public');

const AllianceProductsPreviewTable = ({
    handleSortable,
    isFetching,
    items,
    sortedBy,
    pagination,
    orderdBy,
}) => {
    const { t } = useTranslation();

    const TypeFormatter = (cell) => (
        <Typography
            variant="subtitle2"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            title={cell}
        >
            {cell ? handleProductType(cell, t) : '- - -'}
        </Typography>
    );

    const columns = [
        {
            dataField: 'name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Name'),
            width: '10%',
        },
        {
            dataField: 'type',
            formatter: TypeFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Type'),
            width: '10%',
        },
        {
            dataField: 'created_at',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Creation date'),
            width: '10%',
        },
        {
            dataField: 'status',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Status'),
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

AllianceProductsPreviewTable.propTypes = {
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
    orderdBy: PropTypes.string,
};

export default AllianceProductsPreviewTable;
