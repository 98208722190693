import { get, isEmpty, isNull } from 'lodash';

import { arraytoDictionary } from './index';

const hasValue = (object, name) =>
    Object.prototype.hasOwnProperty.call(object, name) &&
    !isEmpty(object[name]);

export const getCountryCurrency = () => {
    const session = getSessionLocalStorage();
    return get(session, 'currency_company_locale', {});
};

export const getCountryCurrencyDecimalSeparator = () => {
    const parts = getNumberFormatPartsByCountryCurrency();
    const decimalSeparator = parts.find((p) => p.type === 'decimal');
    if (decimalSeparator) return decimalSeparator.value;
    return '.';
};

export const getCountryCurrencyIso = () => {
    const currency = getCountryCurrency();
    if (!hasValue(currency, 'abbreviation')) return 'USD';
    return get(currency, 'abbreviation', 'USD');
};

export const getCountryCurrencyLocale = () => {
    const currency = getCountryCurrency();
    if (!isEmpty(currency) && !isNull(currency) && hasValue(currency, 'locale'))
        return get(currency, 'locale', 'en-US').replace(/_/g, '-');
    return 'en-US';
};

export const getCountryCurrencyThousandSeparator = () => {
    const parts = getNumberFormatPartsByCountryCurrency();
    const thousandSeparator = parts.find((p) => p.type === 'group');
    if (thousandSeparator) return thousandSeparator.value;
    return ',';
};

export const getIsGuest = () => !localStorage.getItem('token');

export const getNumberFormatPartsByCountryCurrency = () =>
    new Intl.NumberFormat(getCountryCurrencyLocale(), {
        style: 'currency',
        currency: getCountryCurrencyIso(),
    }).formatToParts(Math.PI * 10000);

export const getPermissionsLocalStorage = () =>
    typeof localStorage.getItem('permissions') !== 'undefined'
        ? JSON.parse(localStorage.getItem('permissions'))
        : [];

export const getPermissionsDictionary = () =>
    arraytoDictionary(getPermissionsLocalStorage(), 'group');

export const getRoleLevel = () => {
    const session = getSessionLocalStorage();
    if (isNull(session)) return false;
    return get(session, 'company_group.role_level', null);
};

export const getSessionLocalStorage = () =>
    typeof localStorage.getItem('session') !== 'undefined'
        ? JSON.parse(localStorage.getItem('session'))
        : {};

export const getToken = () => localStorage.getItem('token');

export const getUserPermissions = () => {
    const permissionsLE = getPermissionsLocalStorage();
    if (permissionsLE) {
        return permissionsLE.map((permission) => permission.group);
    }
    return [];
};

export const logoutActions = () => {
    localStorage.clear();
    window.location.replace('/login');
};

export const updateSession = (props) => {
    const session = getSessionLocalStorage();
    if (session && props) {
        localStorage.setItem(
            'session',
            JSON.stringify(Object.assign(session, props))
        );
    }
};
