import React, { useCallback } from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Tooltip } from 'common/ui';

const FileUploadIconButton = ({ disabled, handleOnChange }) => {
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles) => {
        handleOnChange(
            acceptedFiles.map((file) =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
            )
        );
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Tooltip arrow title={t('Upload file')}>
            <Box {...getRootProps()}>
                <input {...getInputProps()} disabled={disabled} />
                <IconButton disabled={disabled} size="medium">
                    <FileUploadIcon fontSize="medium" />
                </IconButton>
            </Box>
        </Tooltip>
    );
};

FileUploadIconButton.propTypes = {
    disabled: PropTypes.bool,
    handleOnChange: PropTypes.func,
};

export default FileUploadIconButton;
