import React, { useEffect, useState } from 'react';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Card,
    CardContent,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
} from 'sunwise-ui';

import { DialogContainer } from 'common/components';
import { ShowErrors } from 'common/components';
import { Button } from 'common/ui';
import { Box } from 'common/ui';

import ReactHookFormDragnDropFile from '../../components/form/bootstrap/ReactHookFormDragnDropFile';

import * as actions from './actions';
import { ALLOWED_EXTENSIONS } from './constants';
import * as selectors from './selectors';

const Container = ({
    clipboardData,
    initializeForm,
    isLoading,
    type,
    uploadDatasheetErrors,
    uploadFile,
}) => {
    const { t } = useTranslation();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [availableModels, setAvailableModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const clipboard = clipboardData[type];

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: { file: null, type },
    });

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    const file = watch('file');

    const applySelectedModel = (model) => {
        initializeForm({
            ...model.general,
            ...model.tags,
            product_type: model.general.type,
        });
        setIsOpenModal(false);
    };
    const callback = (values) => {
        setAvailableModels(values);
        setIsOpenModal(true);
    };
    const handleOnCloseModal = () => setIsOpenModal(false);
    const handleOnSubmit = (values) => uploadFile(values, callback);
    const handleOnClickCliboard = () => callback(clipboard);

    return (
        <>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
                <Card>
                    <CardContent>
                        <Stack direction="row" spacing={1}>
                            <Box sx={{ width: '100%' }}>
                                <ReactHookFormDragnDropFile
                                    accept={ALLOWED_EXTENSIONS}
                                    control={control}
                                    disabled={isLoading}
                                    formats="pdf"
                                    name="file"
                                    setValue={setValue}
                                />
                            </Box>

                            <Box
                                sx={{ alignItems: 'baseline', display: 'flex' }}
                            >
                                <IconButton
                                    disabled={!file || isLoading}
                                    sx={{ border: '1px solid', mt: 1 }}
                                    type="submit"
                                >
                                    <AutoAwesomeIcon />
                                    {isLoading && (
                                        <CircularProgress
                                            size={44}
                                            sx={{
                                                left: -1,
                                                position: 'absolute',
                                                top: -1,
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </IconButton>
                            </Box>

                            <Box
                                sx={{ alignItems: 'baseline', display: 'flex' }}
                            >
                                <IconButton
                                    disabled={
                                        isLoading ||
                                        !clipboard ||
                                        clipboard.length === 0
                                    }
                                    onClick={() => handleOnClickCliboard()}
                                    sx={{ border: '1px solid', mt: 1 }}
                                >
                                    <ContentPasteIcon />
                                </IconButton>
                            </Box>
                        </Stack>

                        <ShowErrors
                            errors={uploadDatasheetErrors}
                            pt={3}
                            severity="error"
                            variant="filled"
                        />
                    </CardContent>
                </Card>
            </form>

            <DialogContainer
                open={isOpenModal}
                fullWidth={true}
                onClose={handleOnCloseModal}
                title={t('Select a model')}
            >
                <ToggleButtonGroup
                    exclusive
                    fullWidth={true}
                    onChange={(_, value) => setSelectedModel(value)}
                    orientation="vertical"
                    value={selectedModel}
                >
                    {availableModels.map((model, index) => (
                        <ToggleButton key={`model-${index}`} value={index}>
                            {model.general.brand} - {model.general.code}
                            {model.tags?.max_p ? ` - ${model.tags?.max_p}` : ''}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { sm: 'row', xs: 'column' },
                        gap: 2,
                        justifyContent: { sm: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        fullWidth={false}
                        height="48px"
                        onClick={handleOnCloseModal}
                        size="large"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={selectedModel === null}
                        fullWidth={false}
                        height="48px"
                        onClick={() =>
                            applySelectedModel(availableModels[selectedModel])
                        }
                        size="large"
                    >
                        {t('Use select model')}
                    </Button>
                </Box>
            </DialogContainer>
        </>
    );
};

Container.propTypes = {
    clipboardData: PropTypes.object,
    initializeForm: PropTypes.func,
    isLoading: PropTypes.bool,
    type: PropTypes.number,
    uploadDatasheetErrors: PropTypes.array,
    uploadFile: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    clipboardData: selectors.getClipboardData,
    isLoading: selectors.getIsUploadingDatasheet,
    uploadDatasheetErrors: selectors.getUploadDatasheetErrors,
});

const mapDispatchToProps = (dispatch) => ({
    uploadFile: (values, callback) =>
        dispatch(actions.uploadFile(values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
