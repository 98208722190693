import update from 'immutability-helper';

import {
    INIT_AUTH,
    INIT_AUTH_FAILURE,
    INIT_AUTH_SUCCESS,
    INVALID_PASSWORD,
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    SHOW_SECOND_STEP_AUTH_FORM,
    SET_AUTH_VALUES,
    VALID_PASSWORD,
    RESEND_CODE,
    RESEND_CODE_SUCCESS,
    RESEND_CODE_FAILURE,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        email: null,
        password: null,
        code: null,
        validationToken: null,
    },
    initAuth: {
        isLoading: false,
        errors: null,
    },
    resendCode: {
        isLoading: false,
        errors: null,
    },
    session: {
        user: null,
        isLoading: false,
        errors: null,
    },
    validPassword: false,
    showSecondStepForm: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case INIT_AUTH:
            return update(state, {
                initAuth: { $merge: { isLoading: true, errors: null } },
            });

        case INIT_AUTH_SUCCESS:
            return update(state, {
                initAuth: { $merge: { isLoading: false } },
            });

        case INIT_AUTH_FAILURE:
            return update(state, {
                initAuth: {
                    $merge: { isLoading: false, errors: action.payload },
                },
            });

        case RESEND_CODE:
            return update(state, {
                resendCode: { $merge: { isLoading: true, errors: null } },
            });

        case RESEND_CODE_SUCCESS:
            return update(state, {
                resendCode: { $merge: { isLoading: false } },
            });

        case RESEND_CODE_FAILURE:
            return update(state, {
                resendCode: {
                    $merge: { isLoading: false, errors: action.payload },
                },
            });

        case LOGIN_USER:
            return update(state, {
                session: { $merge: { isLoading: true, errors: null } },
            });

        case LOGIN_USER_SUCCESS:
            return update(state, {
                session: { $merge: { isLoading: false, user: action.payload } },
            });

        case LOGIN_USER_FAILURE:
            return update(state, {
                session: {
                    $merge: { isLoading: false, errors: action.payload },
                },
            });

        case LOGOUT_USER:
            return update(state, {
                session: {
                    $merge: {
                        user: null,
                        isLoading: false,
                        errors: null,
                    },
                },
            });

        case SHOW_SECOND_STEP_AUTH_FORM:
            return update(state, {
                showSecondStepForm: { $set: action.payload },
            });

        case SET_AUTH_VALUES:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case VALID_PASSWORD:
            return update(state, { validPassword: { $set: true } });

        case INVALID_PASSWORD:
            return update(state, { validPassword: { $set: false } });

        default:
            return state;
    }
}
