export const getIsEmptyCategoryGroups = (category) =>
    category.groups.filter((item) => !item.hidden).length === 0;

export const getEnabledGroups = (item) => {
    const enabled_groups = [];

    item.groups.forEach((_group) => {
        let subpermissions = {
            field: {},
        };

        if (_group.subpermissions) {
            _group.subpermissions.forEach((item) => {
                const listValues = handleLists(
                    item.value.replace(/"|\[|\]/g, '')
                );

                subpermissions.field[item.field] = listValues;
            });
        }

        if (parseInt(item.type) == 0 && item.owner_type == 0) {
            _group.prefix = 'owner';
            enabled_groups[_group.group] = {
                id: _group.group,
                owner: {
                    permission_add: _group.permission_add,
                    permission_view: _group.permission_view,
                    permission_change: _group.permission_change,
                    permission_delete: _group.permission_delete,
                    subpermissions: subpermissions,
                },
            };
        } else if (parseInt(item.type) == 0 && item.owner_type == 1) {
            _group.prefix = 'general_manager';
            enabled_groups[_group.group] = {
                id: _group.group,
                general_manager: {
                    permission_add: _group.permission_add,
                    permission_view: _group.permission_view,
                    permission_change: _group.permission_change,
                    permission_delete: _group.permission_delete,
                    subpermissions: subpermissions,
                },
            };
        } else if (parseInt(item.type) == 0 && item.owner_type == 2) {
            _group.prefix = 'superadmin';
            enabled_groups[_group.group] = {
                id: _group.group,
                superadmin: {
                    permission_add: _group.permission_add,
                    permission_view: _group.permission_view,
                    permission_change: _group.permission_change,
                    permission_delete: _group.permission_delete,
                    subpermissions: subpermissions,
                },
            };
        } else if (parseInt(item.type) == 1 && item.owner_type == null) {
            _group.prefix = 'branch_office_manager';
            enabled_groups[_group.group] = {
                id: _group.group,
                branch_office_manager: {
                    permission_add: _group.permission_add,
                    permission_view: _group.permission_view,
                    permission_change: _group.permission_change,
                    permission_delete: _group.permission_delete,
                    subpermissions: subpermissions,
                },
            };
        } else if (parseInt(item.type) == 2 && item.owner_type == null) {
            _group.prefix = 'salesman_agent';
            enabled_groups[_group.group] = {
                id: _group.group,
                salesman_agent: {
                    permission_add: _group.permission_add,
                    permission_view: _group.permission_view,
                    permission_change: _group.permission_change,
                    permission_delete: _group.permission_delete,
                    subpermissions: subpermissions,
                },
            };
        }
    });

    return enabled_groups;
};

export const handleLists = (list) => {
    const temp = {};

    if (list) {
        list.split(',').forEach((item) => (temp[item] = true));
    }

    return temp;
};

export const isSelected = (groups, key, permission) => {
    let counter = 0;
    let total_categories = 0;
    groups.forEach((_group) => {
        if (_group.forbidden.includes(permission) || _group[key]) {
            counter += 1;
        }
        total_categories += 1;
    });
    return counter >= total_categories;
};

export const getPermissions = (categories, groups, tab) => {
    return groups
        .filter((item) => item.tab == tab)
        .map((groupItem) => {
            let forbidden = [];
            const category = categories.find(
                (category) => category.name === groupItem.category
            );
            if (category) {
                const group = category.groups.find(
                    (group) => group.id === groupItem.group
                );
                if (group) {
                    forbidden = group[groupItem.prefix].forbidden;
                }
            }
            return {
                ...groupItem,
                forbidden: forbidden,
            };
        });
};
