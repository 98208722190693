import { SAVE } from '../actionTypes';

import updateRate from './updateRate';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE });
    if (values.id) {
        dispatch(updateRate(values, values.id));
    }
};
