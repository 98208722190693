import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { HOURS, MONTHS } from 'common/constants';
import { Box, Grid, Typography } from 'common/ui';

const HourlyItemStyled = styled(Grid)`
    &.MuiGrid-item {
        padding: 8px;
    }
`;

const StyledCol = styled(Grid)`
    flex-direction: column;
    min-width: 50px;
    width: 4%;
`;

const HourlyGridWrapper = ({ children, sx = {} }) => {
    const { t } = useTranslation();

    return (
        <Box sx={sx}>
            <Box display="flex" gap={1}>
                <StyledCol columns={1} container>
                    <HourlyItemStyled item xs={1} />
                </StyledCol>
                <Grid
                    alignItems="center"
                    columns={24}
                    container
                    gap={0}
                    justifyContent="center"
                    m={0}
                    textAlign="center"
                >
                    {HOURS.map((hour) => (
                        <HourlyItemStyled
                            item
                            key={hour}
                            sx={{ transform: 'rotate(-90deg)' }}
                            textAlign="center"
                            xs={1}
                        >
                            <Typography variant="h6">{hour}</Typography>
                        </HourlyItemStyled>
                    ))}
                </Grid>
            </Box>

            <Box display="flex" mt={1} gap={1}>
                <StyledCol columns={13} container mt={0}>
                    {MONTHS.map((month) => (
                        <HourlyItemStyled item key={month} xs={1}>
                            <Typography variant="h6">{t(month)}</Typography>
                        </HourlyItemStyled>
                    ))}
                </StyledCol>

                {children}
            </Box>
        </Box>
    );
};

HourlyGridWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    sx: PropTypes.object,
};

export default HourlyGridWrapper;
