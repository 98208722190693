import { getTags } from 'common/api/ocr/fineTuning';

import {
    FETCH_TAGS,
    FETCH_TAGS_FAILURE,
    FETCH_TAGS_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_TAGS });

    getTags()
        .then((response) =>
            dispatch({ type: FETCH_TAGS_SUCCESS, payload: response.data })
        )
        .catch((error) =>
            dispatch({
                type: FETCH_TAGS_FAILURE,
                payload: error?.response?.data?.detail,
            })
        );
};
