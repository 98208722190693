import i18next from 'i18next';

import { getFile } from 'common/api/ocr/mediaStorage';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_TRAINING_DOCUMENT,
    DOWNLOAD_TRAINING_DOCUMENT_FAILURE,
    DOWNLOAD_TRAINING_DOCUMENT_SUCCESS,
} from '../actionTypes';

export default (fileName) => (dispatch) => {
    dispatch({ type: DOWNLOAD_TRAINING_DOCUMENT });

    getFile(fileName)
        .then((response) => {
            dispatch({
                payload: response?.data,
                type: DOWNLOAD_TRAINING_DOCUMENT_SUCCESS,
            });

            const link = document.createElement('a');
            link.href = response?.data?.url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            dispatch({
                payload: error?.response?.data?.detail,
                type: DOWNLOAD_TRAINING_DOCUMENT_FAILURE,
            });

            showToast({
                autoClose: 3000,
                body: i18next.t('An error occurred while downloading the file'),
                type: 'error',
            });
        });
};
