import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import stopTrainingModel from './stopTrainingModel';

export default (modelId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [i18next.t('Are you sure to stop this training?')],
        onSuccess: () => dispatch(stopTrainingModel(modelId)),
        title: i18next.t('Stop training'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
