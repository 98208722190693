import { number, object } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { required } = getValidationTexts();

    return object().shape({
        status: number().required(required),
    });
};
