import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from 'common/ui';

const WrapperField = styled.div`
    background: #002438;
    border-radius: 3px;
    border: 3px dashed #a3a6b4;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    height: 100%;
    letter-spacing: -0.29px;
    outline: none;
    padding: 24px;
`;

const StyledWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
`;

const FileUpload = ({ accept, disabled, onChange }) => {
    const { t } = useTranslation();
    const onDrop = useCallback((acceptedFiles) => {
        onChange(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <WrapperField {...getRootProps()}>
            <StyledWrapper>
                <input
                    disabled={disabled}
                    {...getInputProps()}
                    accept={accept}
                />

                <Box
                    alignItems="center"
                    display="flex"
                    gap="16px"
                    textAlign="center"
                >
                    <AttachFileIcon />
                    <Box>{t('Upload file')}</Box>
                </Box>
            </StyledWrapper>
        </WrapperField>
    );
};

FileUpload.propTypes = {
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default FileUpload;
