import React, { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Box, Card, CardActions, Container, Pagination } from 'common/ui';

import * as actions from './actions';
import {
    BrandForm,
    BrandsPreviewTable,
    BrandsPreviewTableToolbar,
} from './components';
import * as selectors from './selectors';

const BrandsContainer = ({
    brands,
    fetchBrands,
    handleClickCreate,
    isFetching,
    isOpenBrandForm,
    pagination,
    reset,
    setCurrentItem,
}) => {
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        setCurrentItem({});
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (!isOpenBrandForm) {
            setCurrentItem({});
        }
    }, [isOpenBrandForm]);

    useEffect(() => {
        fetchBrands(page, orderBy, sortBy, searchStr);
    }, [page, searchStr]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchBrands(page, orderBy, sortBy, searchStr);
    };

    const handleOnSearchChange = (e) => setSearchStr(e);

    return (
        <>
            {!isOpenBrandForm ? (
                <Container maxWidth="">
                    <Box mb={3}>
                        <BrandsPreviewTableToolbar
                            isFetching={isFetching}
                            handleClickCreate={handleClickCreate}
                            handleOnSearchChange={handleOnSearchChange}
                            setCurrentItem={setCurrentItem}
                        />
                    </Box>

                    <Card>
                        <BrandsPreviewTable
                            handleSortable={handleSortable}
                            isFetching={isFetching}
                            items={brands}
                            orderdBy={orderBy}
                            setCurrentItem={setCurrentItem}
                            sortedBy={sortBy}
                        />

                        {!isFetching && pagination && !isEmpty(pagination) && (
                            <CardActions>
                                <Pagination
                                    color="primary"
                                    count={
                                        Math.ceil(
                                            parseInt(pagination.total_rows) /
                                                parseInt(pagination.per_page)
                                        ) || 0
                                    }
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                    page={pagination.current_page}
                                    onChange={handleOnChangePage}
                                    variant="outlined"
                                />
                            </CardActions>
                        )}
                    </Card>
                </Container>
            ) : (
                <Container maxWidth="">
                    <BrandForm setCurrentItem={setCurrentItem} />
                </Container>
            )}
        </>
    );
};

BrandsContainer.propTypes = {
    brands: PropTypes.array,
    fetchBrands: PropTypes.func,
    handleClickCreate: PropTypes.func,
    isFetching: PropTypes.bool,
    isOpenBrandForm: PropTypes.bool,
    pagination: PropTypes.object,
    reset: PropTypes.func,
    setCurrentItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    brands: selectors.getBrandsData,
    isFetching: selectors.getBrandsIsFetching,
    isOpenBrandForm: selectors.getIsOpenBrandForm,
    pagination: selectors.getBrandsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBrands: (page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchBrands(page, orderBy, sortBy, searchStr)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandsContainer);
