import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getAlliancesModel = createSelector(
    getModel,
    (model) => model.alliances
);

export const getAlliancesData = createSelector(getAlliancesModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((alliance) => ({
              ...alliance,
              id: alliance.id,
              logo: alliance.financier.image,
              name: alliance.financier.name,
              status: alliance.status,
          }))
);

export const getAlliancesErrors = createSelector(
    getAlliancesModel,
    (model) => model.errors
);

export const getAlliancesIsFetching = createSelector(
    getAlliancesModel,
    (model) => model.isFetching
);

export const getAlliancesPagination = createSelector(
    getAlliancesModel,
    (model) => model.data.pagination || {}
);

export const getAllianceProductsModel = createSelector(
    getModel,
    (model) => model.allianceProducts
);

export const getAllianceProductsData = createSelector(
    getAllianceProductsModel,
    (model) =>
        !model.data.data
            ? []
            : model.data.data.map((product) => ({
                  ...product,
                  status: product.enabled
                      ? i18next.t('Active')
                      : i18next.t('Inactive'),
              }))
);

export const getAllianceProductsErrors = createSelector(
    getAllianceProductsModel,
    (model) => model.errors
);

export const getAllianceProductsIsFetching = createSelector(
    getAllianceProductsModel,
    (model) => model.isFetching
);

export const getAllianceProductsPagination = createSelector(
    getAllianceProductsModel,
    (model) => model.data.pagination || {}
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getIsShowAllianceProducts = createSelector(
    getModel,
    (model) => model.showAllianceProducts || false
);

export const getAllianceProductsFilters = createSelector(
    getModel,
    (model) => model.productsFilters
);
