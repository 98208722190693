import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldMailFormatter, FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';

import * as actions from '../actions';

const ApiAccountsPreviewTable = ({
    handleClickUpdate,
    handleSortable,
    isFetching,
    items,
    orderdBy,
    pagination,
    setCurrentItem,
    sortedBy,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'email',
            formatter: FieldMailFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Email'),
            width: '10%',
        },
        {
            dataField: 'phone',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Phone'),
            width: '10%',
        },
        {
            dataField: 'rpus_counter',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('RPUs'),
            width: '10%',
        },
        {
            dataField: 'status',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Status'),
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={(item) => {
                setCurrentItem({
                    ...item,
                    name: item.email,
                });
                handleClickUpdate(item);
            }}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

ApiAccountsPreviewTable.propTypes = {
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    orderdBy: PropTypes.string,
    pagination: PropTypes.object,
    setCurrentItem: PropTypes.func,
    sortedBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
});

export default connect(null, mapDispatchToProps)(ApiAccountsPreviewTable);
