import isEmpty from 'lodash/isEmpty';

import { PERMISSION_LEVELS } from 'common/constants/permissionsV2';

import {
    getPermissionsDictionary,
    getUserPermissions,
    logoutActions,
} from './session';

export const isValidAccess = (isPublic, requiredPermissions) => {
    if (isPublic || isEmpty(requiredPermissions)) return true;
    if (requiredPermissions)
        return validateAccessWithPermissions(requiredPermissions);
    return false;
};

export const validateAccessWithPermissions = (requiredPermissions) => {
    let count = 0;
    const permissions = getUserPermissions();
    if (isEmpty(permissions)) logoutActions();
    if (isEmpty(requiredPermissions)) return true;
    if (!isEmpty(permissions) && requiredPermissions) {
        requiredPermissions.forEach((permission) => {
            const hasPermission = getHasPermission(permission);
            if (hasPermission) count++;
        });
        return count >= 1 && count <= requiredPermissions.length;
    }
    return false;
};

export const getHasPermission = (id) => {
    const permissionsDictionary = getPermissionsDictionary();
    const permission = permissionsDictionary[id];
    if (permission) {
        const { permission_level: permissionLevel } = permission;
        return permissionLevel !== PERMISSION_LEVELS.NONE;
    }
    return false;
};
