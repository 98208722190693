import get from 'lodash/get';

import { updateUserProfile } from 'common/api/auth';
import { updateSession } from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';

import initializeForm from './initializeForm';
import setCurrentValues from './setCurrentValues';

export default (values) => (dispatch) => {
    var newValues = new FormData();
    newValues.append('first_name', values.first_name);
    newValues.append('last_name', values.last_name);

    if (Array.isArray(values.picture)) {
        newValues.append('picture', values.picture && values.picture[0]);
    }

    dispatch({ type: SAVE });

    updateUserProfile(newValues, values.id)
        .then((response) => {
            const data = get(response, 'data', {});
            const { email, first_name, last_name, picture } = data;

            updateSession({
                first_name,
                last_name,
                picture,
            });

            dispatch({ type: SAVE_SUCCESS, payload: data });

            dispatch(
                setCurrentValues({
                    email,
                    first_name,
                    last_name,
                    picture,
                })
            );

            dispatch(initializeForm(data));

            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
