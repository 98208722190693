import API from '../../ocrAPI';
const ENTITY = 'projects-staff';

export const getDocuments = (data) =>
    API.post(`/api/${ENTITY}/documents`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const setDocumentAsSuccess = (documentId) =>
    API.post(`/api/${ENTITY}/${documentId}/set-as-success`);
