import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import addOrigin from './addOrigin';

export default (values) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Add'),
        messages: [
            i18next.t('The origin will be added: "{{name}}"', {
                name: `${values.label}`,
            }),
        ],
        onSuccess: () => dispatch(addOrigin(values)),
        title: i18next.t('Add origin'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
