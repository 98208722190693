import get from 'lodash/get';

import { getRoleLevels } from 'common/api/systemRoles';

import {
    FETCH_ROLE_LEVELS,
    FETCH_ROLE_LEVELS_FAILURE,
    FETCH_ROLE_LEVELS_SUCCESS,
} from '../actionTypes';
import { rolesConfigurationActions } from '../reducer';

import fetchRoleLevelDetails from './fetchRoleLevelDetails';

export default (companyId, formValues, isNewRecord) => (dispatch) => {
    dispatch(rolesConfigurationActions[FETCH_ROLE_LEVELS]());

    getRoleLevels(companyId)
        .then((response) => {
            const data = get(response, 'data.data', {});
            const levelId = get(data[1], 'id', null);
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_LEVELS_SUCCESS](data)
            );
            if (isNewRecord && levelId)
                dispatch(fetchRoleLevelDetails(formValues, levelId));
        })
        .catch((error) =>
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_LEVELS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
