import { INITIALIZE_TAGS_FORM } from '../actionTypes';

import setIsOpenTagsForm from './setIsOpenTagsForm';

export default (tags) => (dispatch) => {
    const payload = {
        tags: tags?.map((tag) => ({
            color: tag.color,
            label: tag.name,
            value: tag.id,
        })),
    };
    dispatch({ type: INITIALIZE_TAGS_FORM, payload });
    dispatch(setIsOpenTagsForm(true));
};
