const getFileName = (fileName) => new URL(fileName).pathname.split('/').pop();

export default (urlFile) => () => {
    fetch(urlFile, {
        headers: { 'Content-Type': 'application/pdf' },
        method: 'GET',
    })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            const filename = getFileName(urlFile);
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
};
