import React, { useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomListItem } from 'common/components';
import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    Tooltip,
    Typography,
} from 'common/ui';
import { getSubscriptionStatusDotColor } from 'common/utils/helpers';
import { getTranslatedAddonLabel } from 'common/utils/helpers';

import { getStatusName } from '../../companies/helpers';
import {
    getUsersDetails,
    handleNormalizeValue,
    handleSocketEvents,
} from '../helpers';

const SubscriptionInfoCard = ({
    companyDetail,
    fetchCompanyDetail,
    isUpdatingSubscription,
    prepareUpdateSubscription,
    socketHandler,
}) => {
    const { t } = useTranslation();
    const [event, setEvent] = useState(null);
    const { id: companyId, subscription } = companyDetail;

    useEffect(() => {
        setEvent(socketHandler);
    }, [socketHandler]);

    useEffect(() => {
        handleSocketEvents(companyId, event, fetchCompanyDetail, setEvent);
    }, [companyId, event]);

    const addons = get(subscription, 'addons', []);
    const counters = get(subscription, 'counters', {});

    const dueInvoicesCount = get(subscription, 'due_invoices_count', 0);
    const hasPaymentDue = dueInvoicesCount > 0;
    const status = get(subscription, 'status.key', null);

    return (
        <Box>
            <List>
                <CustomListItem
                    backgroundAvatar={getSubscriptionStatusDotColor(
                        get(subscription, 'status.key', null)
                    )}
                    label={t('Status')}
                    value={getStatusName(hasPaymentDue, status)}
                    withAvatar
                />

                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <CustomListItem
                            label={t('Plan')}
                            value={handleNormalizeValue(
                                get(subscription, 'plan.name', null)
                            )}
                            withVerticalDivider
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomListItem
                            label={t('Purchased users')}
                            value={getUsersDetails(subscription)}
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <CustomListItem
                            label={t('Billing')}
                            value={
                                get(subscription, 'plan.billing.key', null) ===
                                'year'
                                    ? t('Annual')
                                    : t('Monthly')
                            }
                            withVerticalDivider
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomListItem
                            label={t('Billing date')}
                            value={handleNormalizeValue(
                                get(subscription, 'current_period_end', null)
                            )}
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid container sx={{ mt: '5px' }}>
                    <Grid item xs={12} sm={6} hidden={isNull(counters)}>
                        <Box
                            p={2}
                            sx={{
                                borderRight:
                                    '1px solid rgba(145, 158, 171, 0.24)',
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={2}>
                                <Typography
                                    fontWeight="bold"
                                    title={t('Total available projects')}
                                    variant="body2"
                                >
                                    {t('Total available projects')}:{' '}
                                    {counters?.available_projects}
                                </Typography>
                                <Tooltip
                                    title={
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={2}
                                            p={1}
                                            sx={{
                                                width: {
                                                    xs: 'auto',
                                                    lg: '160px',
                                                },
                                            }}
                                        >
                                            <Typography variant="caption">
                                                {t(
                                                    'Projects included in your plan expire upon renewal'
                                                )}
                                                .
                                            </Typography>
                                            <Typography variant="caption">
                                                {t(
                                                    'Additional projects have no expiration date'
                                                )}
                                                .
                                            </Typography>
                                        </Box>
                                    }
                                    placement="bottom"
                                >
                                    <InfoIcon sx={{ color: '#ff9a00' }} />
                                </Tooltip>
                            </Box>
                            <Box ml={2}>
                                <Typography variant="body2">
                                    {`${t('Included in your plan')}: ${
                                        counters?.available_current
                                    }/${counters?.maximum_current}`}
                                </Typography>
                                <Typography variant="body2">
                                    {`${t('Additional projects')}: ${
                                        counters?.available_purchased
                                    }`}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box p={2}>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Add-Ons')}
                            </Typography>

                            <Box ml={2} mt={1}>
                                {addons.map((addon) => (
                                    <Typography variant="body2" key={addon}>
                                        {getTranslatedAddonLabel(addon)}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container mt={2} textAlign="right">
                    <Grid item xs>
                        <Button
                            disabled={isUpdatingSubscription}
                            fullWidth={false}
                            onClick={() => prepareUpdateSubscription(companyId)}
                        >
                            {isUpdatingSubscription
                                ? `${t('Updating')}...`
                                : t('Update')}
                        </Button>
                    </Grid>
                </Grid>
            </List>
        </Box>
    );
};

SubscriptionInfoCard.propTypes = {
    companyDetail: PropTypes.object,
    fetchCompanyDetail: PropTypes.func,
    isUpdatingSubscription: PropTypes.bool,
    prepareUpdateSubscription: PropTypes.func,
    socketHandler: PropTypes.object,
};

export default SubscriptionInfoCard;
