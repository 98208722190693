import update from 'immutability-helper';

import {
    DELETE_TRAINING_DATA,
    DELETE_TRAINING_DATA_FAILURE,
    DELETE_TRAINING_DATA_SUCCESS,
    FETCH_MODEL,
    FETCH_MODEL_FAILURE,
    FETCH_MODEL_SUCCESS,
    FETCH_TRAINING_DATA,
    FETCH_TRAINING_DATA_FAILURE,
    FETCH_TRAINING_DATA_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    RESTART_TRAINING_DATA,
    RESTART_TRAINING_DATA_FAILURE,
    RESTART_TRAINING_DATA_SUCCESS,
    SAVE_TRAINING_DATA,
    SAVE_TRAINING_DATA_FAILURE,
    SAVE_TRAINING_DATA_SUCCESS,
    SET_IS_OPEN_MODAL_FORM,
} from './actionTypes';

const INITIAL_STATE = {
    deleteTrainingData: { errors: [], isDeleting: false },
    fetchTrainingData: { data: {}, errors: [], isFetching: false },
    initialValues: { files: null, tags: [] },
    isOpenModal: false,
    modelTrainingData: { data: {}, errors: [], isFetching: false },
    restartTrainingData: { errors: [], isRestarting: false },
    saveTrainingData: { errors: [], isSaving: false },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELETE_TRAINING_DATA:
            return update(state, {
                deleteTrainingData: {
                    $merge: { errors: [], isDeleting: true },
                },
            });

        case DELETE_TRAINING_DATA_FAILURE:
            return update(state, {
                deleteTrainingData: {
                    $merge: { errors: action.payload, isDeleting: false },
                },
            });

        case DELETE_TRAINING_DATA_SUCCESS:
            return update(state, {
                deleteTrainingData: {
                    $merge: { errors: [], isDeleting: false },
                },
            });

        case FETCH_MODEL:
            return update(state, {
                modelTrainingData: {
                    $merge: { isFetching: true, errors: [] },
                },
            });

        case FETCH_MODEL_FAILURE:
            return update(state, {
                modelTrainingData: {
                    $merge: { isFetching: false, errors: action.payload },
                },
            });

        case FETCH_MODEL_SUCCESS:
            return update(state, {
                modelTrainingData: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_TRAINING_DATA:
            return update(state, {
                fetchTrainingData: {
                    $merge: { isFetching: true, errors: [] },
                },
            });

        case FETCH_TRAINING_DATA_FAILURE:
            return update(state, {
                fetchTrainingData: {
                    $merge: { isFetching: false, errors: action.payload },
                },
            });

        case FETCH_TRAINING_DATA_SUCCESS:
            return update(state, {
                fetchTrainingData: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: { $merge: action.payload },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
            });

        case RESTART_TRAINING_DATA:
            return update(state, {
                restartTrainingData: {
                    $merge: { errors: [], isRestarting: true },
                },
            });

        case RESTART_TRAINING_DATA_FAILURE:
            return update(state, {
                restartTrainingData: {
                    $merge: { errors: action.payload, isRestarting: false },
                },
            });

        case RESTART_TRAINING_DATA_SUCCESS:
            return update(state, {
                restartTrainingData: {
                    $merge: { errors: [], isRestarting: false },
                },
            });

        case SAVE_TRAINING_DATA:
            return update(state, {
                saveTrainingData: {
                    $merge: { errors: [], isSaving: true },
                },
            });

        case SAVE_TRAINING_DATA_FAILURE:
            return update(state, {
                saveTrainingData: {
                    $merge: { errors: action.payload, isSaving: false },
                },
            });

        case SAVE_TRAINING_DATA_SUCCESS:
            return update(state, {
                saveTrainingData: {
                    $merge: { errors: [], isSaving: false },
                },
            });

        case SET_IS_OPEN_MODAL_FORM:
            return update(state, {
                isOpenModal: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
};
