import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { PREFIX } from 'common/sockets/constants';
import { generateSocketActions } from 'common/sockets/helpers';
import { formatDate, subDate } from 'common/utils/dates';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
    FETCH_BRANCH_OFFICES,
    FETCH_BRANCH_OFFICES_FAILURE,
    FETCH_BRANCH_OFFICES_SUCCESS,
    FETCH_COMPANY_DETAIL,
    FETCH_COMPANY_DETAIL_FAILURE,
    FETCH_COMPANY_DETAIL_SUCCESS,
    FETCH_PROJECTS_COUNTERS,
    FETCH_PROJECTS_COUNTERS_FAILURE,
    FETCH_PROJECTS_COUNTERS_SUCCESS,
    FETCH_PROPOSALS_TOTALS,
    FETCH_PROPOSALS_TOTALS_FAILURE,
    FETCH_PROPOSALS_TOTALS_SUCCESS,
    INITIALIZE_FILTER_FORM,
    INITIALIZE_FORM,
    RESET,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    UPDATE_COMPANY_SUBSCRIPION,
    UPDATE_COMPANY_SUBSCRIPION_FAILURE,
    UPDATE_COMPANY_SUBSCRIPION_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} = generateSocketActions(PREFIX);

const initialDate = formatDate(subDate(new Date(), { years: 1 }), 'dd/MM/yyyy');
const finalDate = formatDate(new Date(), 'dd/MM/yyyy');

const INITIAL_STATE = {
    agents: {
        data: [],
        error: null,
        isFetching: false,
    },
    branchOffices: {
        data: {
            data: [],
            paginaton: {},
        },
        errors: null,
        isFetching: false,
    },
    companyDetail: {
        isFetching: false,
        data: {},
        errors: null,
    },
    initialFilterValues: {
        agent: '',
        final_date: finalDate,
        initial_date: initialDate,
    },
    initialValues: {
        id: null,
        active_users: null,
        business_name: null,
        rfc: null,
        owner: null,
        subscription: null,
    },
    projectsCounters: {
        data: {
            categories: [],
            series: [],
            projects_totals: 0,
        },
        error: null,
        isEmpty: false,
        isFetching: false,
    },
    proposalsTotals: {
        data: {
            categories: [],
            series: [],
            projects_number: 0,
            projects_totals: 0,
            projects_kws: 0,
        },
        error: null,
        isEmpty: false,
        isFetching: false,
    },
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    socketConnected: false,
    socketMessage: {},
    socketError: null,
    updateSubscription: {
        errors: [],
        isFetching: false,
    },
};

const companyGeneralSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_AGENTS]: (state) => {
            state.agents = {
                ...state.agents,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_AGENTS_FAILURE]: (state, action) => {
            state.agents = {
                ...state.agents,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_AGENTS_SUCCESS]: (state, action) => {
            state.agents = {
                ...state.agents,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BRANCH_OFFICES]: (state) => {
            state.branchOffices = {
                ...state.branchOffices,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BRANCH_OFFICES_FAILURE]: (state, action) => {
            state.branchOffices = {
                ...state.branchOffices,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BRANCH_OFFICES_SUCCESS]: (state, action) => {
            state.branchOffices = {
                ...state.branchOffices,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANY_DETAIL]: (state) => {
            state.companyDetail = {
                ...state.companyDetail,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_COMPANY_DETAIL_FAILURE]: (state, action) => {
            state.companyDetail = {
                ...state.companyDetail,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANY_DETAIL_SUCCESS]: (state, action) => {
            state.companyDetail = {
                ...state.companyDetail,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROJECTS_COUNTERS]: (state) => {
            state.projectsCounters = {
                ...state.projectsCounters,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PROJECTS_COUNTERS_FAILURE]: (state, action) => {
            state.projectsCounters = {
                ...state.projectsCounters,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROJECTS_COUNTERS_SUCCESS]: (state, action) => {
            state.projectsCounters = {
                ...state.projectsCounters,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS_TOTALS]: (state) => {
            state.proposalsTotals = {
                ...state.proposalsTotals,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PROPOSALS_TOTALS_FAILURE]: (state, action) => {
            state.proposalsTotals = {
                ...state.proposalsTotals,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS_TOTALS_SUCCESS]: (state, action) => {
            state.proposalsTotals = {
                ...state.proposalsTotals,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FILTER_FORM]: (state, action) => {
            state.initialFilterValues = {
                ...state.initialFilterValues,
                ...action.payload,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_COMPANY_SUBSCRIPION]: (state) => {
            state.updateSubscription = {
                ...state.updateSubscription,
                errors: [],
                isFetching: true,
            };
        },
        [UPDATE_COMPANY_SUBSCRIPION_FAILURE]: (state, action) => {
            state.updateSubscription = {
                ...state.updateSubscription,
                errors: action.payload,
                isFetching: false,
            };
        },
        [UPDATE_COMPANY_SUBSCRIPION_SUCCESS]: (state, action) => {
            state.updateSubscription = {
                ...state.updateSubscription,
                data: action.payload,
                isFetching: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(BROKEN_SOCKET, (state) => {
                state.socketError = i18next.t(
                    'Error sending data. Restart your session or try again later'
                );
            })
            .addCase(ERROR_SOCKET, (state) => {
                state.socketError = i18next.t('Server error. Try again later');
            })
            .addCase(RECONNECTED, (state) => {
                state.socketError = null;
            })
            .addCase(CLOSED_SOCKET, (state) => {
                state.socketConnected = false;
            })
            .addCase(OPEN_SOCKET, (state) => {
                state.socketConnected = true;
            })
            .addCase(MESSAGE_SOCKET, (state, action) => {
                const { message = {} } = JSON.parse(action?.payload?.message);
                state.socketMessage = message;
            });
    },
});

export const companyGeneralActions = companyGeneralSlice.actions;

export default companyGeneralSlice.reducer;
