import { isToday, isYesterday } from 'date-fns';
import i18next from 'i18next';

import { formatDate, parseDate } from 'common/utils/dates';

import { ACTIONS_TYPES, DATE_FORMATS } from './constants';

export const getActionColor = (action) => {
    switch (action) {
        case ACTIONS_TYPES.ADDITION:
            return '#388e3C';
        case ACTIONS_TYPES.CHANGE:
            return '#1976d2';
        case ACTIONS_TYPES.DELETION:
            return '#d32f2f';
        default:
            return '#1976d2';
    }
};

export const getActionName = (action) => {
    switch (action) {
        case ACTIONS_TYPES.ADDITION:
            return i18next.t('Added');
        case ACTIONS_TYPES.CHANGE:
            return i18next.t('Modified');
        case ACTIONS_TYPES.DELETION:
            return i18next.t('Deleted');
        default:
            return i18next.t('Modified');
    }
};

export const getDateTitle = (date, dateFormat = 'yyyy-MM-dd') => {
    if (!date) return '';

    const parsedDate = parseDate(date, dateFormat);

    if (isToday(parsedDate)) return i18next.t('Today');
    if (isYesterday(parsedDate)) return i18next.t('Yesterday');
    return formatDate(parsedDate, DATE_FORMATS.TITLE);
};

export const getModelName = (name) => {
    const models = {
        Contact: i18next.t('Contact'),
        ContactPosition: i18next.t('Contact location'),
        ContactDocument: i18next.t('Contact document'),
        ContactTracking: i18next.t('Follow-up', { count: 2 }),
        Project: i18next.t('Project'),
        CommercialOfferParent: i18next.t('Proposal'),
        ProposalSummary: i18next.t('Summary'),
        IrradiationCompanyConfiguration: i18next.t('Irradiation source'),
        Irradiation: i18next.t('Irradiation'),
        BranchOfficePanelCompany: i18next.t('Panel'),
        BranchOfficeInverterCompany: i18next.t('Inverter'),
        BranchOfficeBatteryCompany: i18next.t('Battery'),
        BranchOfficeAccesoryCompany: i18next.t('Accessory'),
        BranchOfficeStructureCompany: i18next.t('Structure'),
        BranchOfficeWorkforceAndElectricalMaterialCompany: i18next.t(
            'Workforce and electrical equipment'
        ),
        BranchOfficeAdditionalCompany: i18next.t('Additional'),
        ProposalPlan: i18next.t('Plan'),
        CustomOfferTemplate: i18next.t('Template'),
        ProductFinancialPlan: i18next.t('Financial product'),
        Application: i18next.t('Integration'),
        EmbeddedProposal: i18next.t('Web quoter'),
        User: i18next.t('User'),
        UserSettings: i18next.t('Profile'),
        BranchOffice: i18next.t('Branch office'),
        CompanyGroup: i18next.t('Role'),
        CompanyCatalog: i18next.t('Listings'),
    };
    return models[name];
};
