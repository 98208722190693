import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    AutocompleteField,
    BulkEditionMenu,
    InputSearch,
    StatusFilterSelect,
} from 'common/components';
import { Card, CardContent, Grid, TextField } from 'common/ui';

const BulkOperationsCard = ({
    filterData,
    handleClickBulkItems,
    handleOnChangeMaxPower,
    handleOnChangeSearch,
    handleOnSelectBrand,
    handleOnSelectStatus,
    maxPowerLabel = '',
    prefix,
    productBrands = [],
    selectedBulkOption,
    selectionModel,
    setSelectedBulkOption,
    setSelectionModel,
}) => {
    const { t } = useTranslation();

    const isArchivedList = filterData?.status === 'archived';
    const callback = () => {
        setSelectedBulkOption('');
        setSelectionModel([]);
    };

    const getBrandOptions = () => {
        return productBrands.map(({ name }) => ({
            value: name,
            label: name,
        }));
    };

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid
                    alignItems="end"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item xs={12} lg={isEmpty(maxPowerLabel) ? 6 : 4}>
                        <InputSearch
                            placeholder={t('Search')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid
                        hidden={isEmpty(productBrands)}
                        item
                        xs={12}
                        md={isEmpty(maxPowerLabel) ? 9 : 6}
                        lg={3}
                    >
                        <AutocompleteField
                            label={t('Brand', { count: 1 })}
                            options={getBrandOptions()}
                            onChange={(value) => handleOnSelectBrand(value)}
                            value={filterData?.brandName}
                        />
                    </Grid>
                    <Grid
                        hidden={isEmpty(maxPowerLabel)}
                        item
                        xs={12}
                        md={3}
                        lg={2}
                    >
                        <TextField
                            fullWidth
                            label={maxPowerLabel}
                            onChange={(e) => handleOnChangeMaxPower(e)}
                            value={filterData?.maxPower}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <StatusFilterSelect
                            filterBy={filterData?.status}
                            handleSelected={handleOnSelectStatus}
                            variant="standard"
                        />
                    </Grid>
                    {!isEmpty(selectionModel) && (
                        <Grid item xs={12}>
                            <BulkEditionMenu
                                items={[
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'archived',
                                                filterData,
                                                callback
                                            ),
                                        visible: !isArchivedList,
                                    },
                                    {
                                        label: t('Unarchive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'active',
                                                filterData,
                                                callback
                                            ),
                                        visible: isArchivedList,
                                    },
                                    {
                                        label: t('Delete'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'deleted',
                                                filterData,
                                                callback
                                            ),
                                        visible: isArchivedList,
                                    },
                                ]}
                                prefix={prefix}
                                selectedBulkOption={selectedBulkOption}
                                selectionModel={selectionModel}
                                setSelectionModel={setSelectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleOnChangeMaxPower: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectBrand: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    maxPowerLabel: PropTypes.string,
    prefix: PropTypes.string,
    productBrands: PropTypes.array,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectedBulkOption: PropTypes.func,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
