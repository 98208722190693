import i18next from 'i18next';

import { setDocumentAsSuccess } from 'common/api/ocr/projects';
import alerts from 'common/modules/alerts';

import {
    SET_AS_SUCCESS,
    SET_AS_SUCCESS_FAILURE,
    SET_AS_SUCCESS_SUCCESS,
} from '../actionTypes';

import fetchItems from './fetchItems';

export default (documentId, filter) => (dispatch) => {
    dispatch({ type: SET_AS_SUCCESS });

    setDocumentAsSuccess(documentId)
        .then(() => {
            dispatch({ type: SET_AS_SUCCESS_SUCCESS });

            dispatch(fetchItems(filter));
        })
        .catch((error) => {
            const rawErrors = error?.response?.data?.detail;
            dispatch({ type: SET_AS_SUCCESS_FAILURE, payload: rawErrors });

            const errors = rawErrors?.map((error) => error?.msg);

            if (errors?.length) {
                const dataAlert = {
                    confirmText: i18next.t('Close'),
                    messages: errors,
                    title: i18next.t('Error'),
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'error',
                };

                dispatch(alerts.actions.show(dataAlert));
            }
        });
};
