import { getCountries } from 'common/api/companies';

import {
    FECH_COUNTRIES,
    FECH_COUNTRIES_FAILURE,
    FECH_COUNTRIES_SUCCESS,
} from '../actionTypes';

export default (companyId) => (dispatch) => {
    dispatch({ type: FECH_COUNTRIES });
    getCountries(companyId)
        .then((response) => {
            dispatch({
                type: FECH_COUNTRIES_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: FECH_COUNTRIES_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
