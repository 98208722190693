import { SET_IS_NEW_MODEL } from '../actionTypes';

import initializeModelForm from './initializeModelForm';
import setIsOpenModelForm from './setIsOpenModelForm';

export default () => (dispatch) => {
    dispatch({ type: SET_IS_NEW_MODEL, payload: true });
    dispatch(initializeModelForm({}));
    dispatch(setIsOpenModelForm(true));
};
