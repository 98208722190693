import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getDeleteFamily = createSelector(
    getModel,
    (model) => model.deleteFamily
);

export const getIsDeletingFamily = createSelector(
    getDeleteFamily,
    (deleteFamily) => deleteFamily.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteFamily,
    (deleteFamily) => deleteFamily.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteFamily,
    (deleteFamily) => deleteFamily.errors
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);
