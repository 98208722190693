import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { InputAdornment, TextField } from 'common/ui';

const TextCounter = styled('span')`
    display: block;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.25px;
    margin-bottom: 12px;
    text-align: end;
`;

const ReactHookFormInput = ({
    append,
    appendIcon,
    control,
    dataIntercomTarget,
    defaultBehevior,
    hasCounter,
    maxLength,
    name,
    onChange,
    onlyIntegerNumbers,
    onlyNumbers,
    placeholder,
    prepend,
    prependIcon,
    prependSvgIcon,
    readOnly,
    type,
    variant,
    ...rest
}) => {
    const onChangeAction = (
        event,
        defaultBehevior,
        onlyNumbers,
        onChangeField
    ) => {
        const text = event.target.value;

        if (onlyNumbers) {
            event.target.value = text.replace(/[A-Za-z]/gi, '');
            onChangeField(event);
            if (onChange) onChange(event);
            return;
        }

        if (onlyIntegerNumbers) {
            event.target.value = text.replace(/[A-Za-z$,.]/gi, '');
            onChangeField(event);
            if (onChange) onChange(event);
            return;
        }

        if (
            !(
                text.length > 1 &&
                text.charAt(0) === '0' &&
                text.charAt(1) === '0' &&
                !defaultBehevior
            )
        ) {
            if (type === 'number' && parseFloat(text) >= 1) {
                event.target.value = text.replace(/^0+/, '');
            }
        }
        onChangeField(event);
        if (onChange) onChange(event);
    };

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onBlur, onChange, ref, value },
                    fieldState: { error },
                }) => (
                    <>
                        <TextField
                            data-intercom-target={dataIntercomTarget}
                            error={Boolean(error)}
                            helperText={error?.message}
                            inputProps={{ maxLength }}
                            InputProps={{
                                endAdornment: (appendIcon || append) && (
                                    <InputAdornment position="end">
                                        {appendIcon && (
                                            <img alt="" src={appendIcon} />
                                        )}
                                        {append}
                                    </InputAdornment>
                                ),
                                readOnly,
                                startAdornment: (prepend ||
                                    prependIcon ||
                                    prependSvgIcon) && (
                                    <InputAdornment position="start">
                                        {prependIcon && (
                                            <i className={prependIcon} />
                                        )}
                                        {prependSvgIcon && (
                                            <img alt="" src={prependSvgIcon} />
                                        )}
                                        {prepend}
                                    </InputAdornment>
                                ),
                            }}
                            inputRef={ref}
                            name={name}
                            onBlur={onBlur}
                            onChange={(e) =>
                                onChangeAction(
                                    e,
                                    defaultBehevior,
                                    onlyNumbers,
                                    onChange
                                )
                            }
                            placeholder={placeholder}
                            type={type}
                            value={value || ''}
                            variant={variant}
                            {...rest}
                        />
                        {hasCounter && maxLength && (
                            <TextCounter>
                                {value.length}/{maxLength}
                            </TextCounter>
                        )}
                    </>
                )}
            />
        </>
    );
};

ReactHookFormInput.defaultProps = { fullWidth: true, variant: 'outlined' };

ReactHookFormInput.propTypes = {
    append: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.element,
        PropTypes.string,
    ]),
    appendIcon: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.array]),
    control: PropTypes.object,
    dataIntercomTarget: PropTypes.string,
    defaultBehevior: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hasCounter: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.number,
    name: PropTypes.string,
    numberTypeOnBlurActionDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    onlyIntegerNumbers: PropTypes.bool,
    onlyNumbers: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    prepend: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.element,
        PropTypes.string,
    ]),
    prependIcon: PropTypes.string,
    prependSvgIcon: PropTypes.string,
    readOnly: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
};

export default ReactHookFormInput;
