import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import styled from 'styled-components';

import ReduxFieldCheck from 'common/components/form/redux/ReduxFieldCheck';
import { Grid } from 'common/ui';

const StyledColAction = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #eff1fb;
    border-left: 1px solid #eff1fb;
    border-right: 1px solid #eff1fb;
`;

const InputCheck = ({ disabled, inputId, name, onchange, visible = true }) => {
    return visible ? (
        <StyledColAction item xs={2} md={1}>
            <Field
                component={ReduxFieldCheck}
                disabled={disabled}
                id={inputId}
                name={name}
                onChange={() => onchange(name)}
            />
        </StyledColAction>
    ) : null;
};

InputCheck.propTypes = {
    disabled: PropTypes.bool,
    inputId: PropTypes.string,
    name: PropTypes.string,
    onchange: PropTypes.func,
    visible: PropTypes.bool,
};

export default InputCheck;
