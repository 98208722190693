import { NAME } from './constants';

export const INIT_AUTH = `${NAME}/INIT_AUTH`;
export const INIT_AUTH_FAILURE = `${NAME}/INIT_AUTH_FAILURE`;
export const INIT_AUTH_SUCCESS = `${NAME}/INIT_AUTH_SUCCESS`;

export const RESEND_CODE = `${NAME}/RESEND_CODE`;
export const RESEND_CODE_FAILURE = `${NAME}/RESEND_CODE_FAILURE`;
export const RESEND_CODE_SUCCESS = `${NAME}/RESEND_CODE_SUCCESS`;

export const INVALID_PASSWORD = `${NAME}/INVALID_PASSWORD`;

export const LOGIN_USER = `${NAME}/LOGIN_USER`;
export const LOGIN_USER_FAILURE = `${NAME}/LOGIN_USER_FAILURE`;
export const LOGIN_USER_SUCCESS = `${NAME}/LOGIN_USER_SUCCESS`;

export const LOGOUT_USER = `${NAME}/LOGOUT`;

export const SHOW_SECOND_STEP_AUTH_FORM = `${NAME}/SHOW_SECOND_STEP_AUTH_FORM`;
export const SET_AUTH_VALUES = `${NAME}/SET_AUTH_VALUES`;

export const VALID_PASSWORD = `${NAME}/VALID_PASSWORD`;
