import { filterOrigins } from 'common/api/suppliers';

import {
    FECH_COUNTRIES,
    FECH_COUNTRIES_FAILURE,
    FECH_COUNTRIES_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FECH_COUNTRIES });
    filterOrigins('', 2000)
        .then((response) => {
            dispatch({
                payload: response.data.data,
                type: FECH_COUNTRIES_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch({
                payload: error.response.data.errors,
                type: FECH_COUNTRIES_FAILURE,
            });
        });
};
