import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getModels = createSelector(getModel, (model) => model?.models);

export const getModelsData = createSelector(
    getModels,
    (model) => model?.data || []
);

export const getModelsForSelect = createSelector(
    getModelsData,
    (models) =>
        models.map((model) => ({
            label: model.name,
            optionValue: model.id,
        })) || []
);

export const getModelsErrors = createSelector(
    getModels,
    (model) => model?.errors
);

export const getModelsIsFetching = createSelector(
    getModels,
    (model) => model?.isFetching
);

export const getSaveModel = createSelector(
    getModel,
    (model) => model?.saveModel
);

export const getSaveModelErrors = createSelector(
    getSaveModel,
    (model) => model?.errors
);

export const getSaveModelIsSaving = createSelector(
    getSaveModel,
    (model) => model?.isSaving
);

export const getIsOpenModelForm = createSelector(
    getModel,
    (model) => model?.isOpenModelForm
);

export const getIsNewModel = createSelector(
    getModel,
    (model) => model?.isNewModel
);

export const getInitialValuesModelForm = createSelector(
    getModel,
    (model) => model?.intialValuesModelForm
);

export const getDeleteModel = createSelector(
    getModel,
    (model) => model?.deleteModel
);

export const getDeleteModelErrors = createSelector(
    getDeleteModel,
    (model) => model?.errors
);

export const getDeleteModelIsDeleting = createSelector(
    getDeleteModel,
    (model) => model?.isDeleting
);

export const getIsOpenDuplicateModelForm = createSelector(
    getModel,
    (model) => model?.isOpenDuplicateModelForm
);

export const getInitialValuesDuplicateModelForm = createSelector(
    getModel,
    (model) => model?.initialValuesDuplicateModelForm
);

export const getDuplicateModel = createSelector(
    getModel,
    (model) => model?.duplicateModel
);

export const getDuplicateModelErrors = createSelector(
    getDuplicateModel,
    (model) => model?.errors
);

export const getDuplicateModelIsDuplicating = createSelector(
    getDuplicateModel,
    (model) => model?.isDuplicating
);
