import { updateRate } from 'common/api/rates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchRates from './fetchRates';
import resetForm from './resetForm';
import toggleItemForm from './toggleItemForm';

export default (values, id) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = new FormData();
    newValues.append('description', values.description);

    updateRate(newValues, id)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchRates(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleItemForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error?.response?.data?.errors,
            });
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
