import i18next from 'i18next';

import { createSupplier } from 'common/api/suppliers';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchSuppliers from './fetchSuppliers';
import resetForm from './resetForm';
import toggleItemForm from './toggleItemForm';

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = {
        name: values.name,
        country: values.country,
    };

    createSupplier(newValues)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchSuppliers(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleItemForm(false));
            dispatch(resetForm());

            showToast({
                body: i18next.t(
                    'A new supplier has been successfully created. Notify development to add rates'
                ),
            });
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t(
                    'An error occurred while creating the power supplier'
                ),
            });
        });
};
