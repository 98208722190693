import { NAME } from './constants';

export const DELETE_TRAINING_DATA = `${NAME}/DELETE_TRAINING_DATA`;
export const DELETE_TRAINING_DATA_SUCCESS = `${NAME}/DELETE_TRAINING_DATA_SUCCESS`;
export const DELETE_TRAINING_DATA_FAILURE = `${NAME}/DELETE_TRAINING_DATA_FAILURE`;

export const FETCH_MODEL = `${NAME}/FETCH_MODEL`;
export const FETCH_MODEL_SUCCESS = `${NAME}/FETCH_MODEL_SUCCESS`;
export const FETCH_MODEL_FAILURE = `${NAME}/FETCH_MODEL_FAILURE`;

export const FETCH_TRAINING_DATA = `${NAME}/FETCH_TRAINING_DATA`;
export const FETCH_TRAINING_DATA_SUCCESS = `${NAME}/FETCH_TRAINING_DATA_SUCCESS`;
export const FETCH_TRAINING_DATA_FAILURE = `${NAME}/FETCH_TRAINING_DATA_FAILURE`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const RESTART_TRAINING_DATA = `${NAME}/RESTART_TRAINING_DATA`;
export const RESTART_TRAINING_DATA_SUCCESS = `${NAME}/RESTART_TRAINING_DATA_SUCCESS`;
export const RESTART_TRAINING_DATA_FAILURE = `${NAME}/RESTART_TRAINING_DATA_FAILURE`;

export const SET_IS_OPEN_MODAL_FORM = `${NAME}/SET_IS_OPEN_MODAL_FORM`;

export const SAVE_TRAINING_DATA = `${NAME}/SAVE_TRAINING_DATA`;
export const SAVE_TRAINING_DATA_SUCCESS = `${NAME}/SAVE_TRAINING_DATA_SUCCESS`;
export const SAVE_TRAINING_DATA_FAILURE = `${NAME}/SAVE_TRAINING_DATA_FAILURE`;
