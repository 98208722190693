import API from '../../ocrAPI';

const ENTITY = 'fine-tuning';

export const createModel = (data) =>
    API.post(`/api/${ENTITY}/model`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const deleteModel = (id) => API.delete(`/api/${ENTITY}/model/${id}`);
export const duplicateModel = (id, data) =>
    API.post(`/api/${ENTITY}/model/${id}/duplicate`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const getModelsList = (status) =>
    API.get(`/api/${ENTITY}/models`, { params: { status } });
export const getModel = (id, query = '') =>
    API.get(`/api/${ENTITY}/model/${id}/training-data${query}`);
export const updateModel = (id, data) =>
    API.patch(`/api/${ENTITY}/model/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const createTrainingDocument = (modelId) =>
    API.post(`/api/${ENTITY}/model/${modelId}/training-document`);

export const bulkUploadTrainingDocuments = (modelId, values) =>
    API.post(
        `/api/${ENTITY}/model/${modelId}/training-data/bulk-upload-training-documents`,
        values
    );

export const createTrainingData = (modelId) =>
    API.post(`/api/${ENTITY}/model/${modelId}/training-data`);
export const deleteTrainingData = (id, modelId) =>
    API.delete(`/api/${ENTITY}/model/${modelId}/training-data/${id}`);
export const getTrainingData = (id, modelId) =>
    API.get(`/api/${ENTITY}/model/${modelId}/training-data/${id}`);
export const restartTrainingData = (id, modelId) =>
    API.post(
        `/api/${ENTITY}/model/${modelId}/training-data/${id}/retry-textract`
    );
export const updateTrainingData = (id, modelId, data) =>
    API.patch(`/api/${ENTITY}/model/${modelId}/training-data/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const updateTrainingDataFile = (id, modelId, file) =>
    API.put(`/api/${ENTITY}/model/${modelId}/training-data/${id}`, { file });

export const createTag = (data) =>
    API.post(`/api/${ENTITY}/tags`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const deleteTag = (id) => API.delete(`/api/${ENTITY}/tags/${id}`);
export const getTags = () => API.get(`/api/${ENTITY}/tags`);
export const updateTag = (id, data) =>
    API.patch(`/api/${ENTITY}/tags/${id}`, data);
