import update from 'immutability-helper';

import {
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DOWNLOAD_CSV_SUCCESS,
    FECH_COUNTRIES,
    FECH_COUNTRIES_FAILURE,
    FECH_COUNTRIES_SUCCESS,
    FECH_POLITICAL_DIVISIONS,
    FECH_POLITICAL_DIVISIONS_FAILURE,
    FECH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_FILES,
    FETCH_FILES_FAILURE,
    FETCH_FILES_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    countries: {
        data: [],
        errors: [],
        isFetching: false,
    },
    downloadCSV: {
        data: null,
        errors: [],
        isFetching: false,
    },
    fetchRates: {
        data: [],
        errors: null,
        isFetching: false,
    },
    files: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    politicalDivisions: {
        data: [],
        errors: [],
        isFetching: false,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DOWNLOAD_CSV:
            return update(state, {
                downloadCSV: { $merge: { errors: [], isFetching: true } },
            });

        case DOWNLOAD_CSV_FAILURE:
            return update(state, {
                downloadCSV: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case DOWNLOAD_CSV_SUCCESS:
            return update(state, {
                downloadCSV: { $merge: { errors: [], isFetching: false } },
            });

        case FECH_COUNTRIES:
            return update(state, {
                countries: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FECH_COUNTRIES_FAILURE:
            return update(state, {
                countries: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FECH_COUNTRIES_SUCCESS:
            return update(state, {
                countries: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_FILES:
            return update(state, {
                files: { $merge: { data: [], isFetching: true, errors: [] } },
            });

        case FETCH_FILES_FAILURE:
            return update(state, {
                files: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_FILES_SUCCESS:
            return update(state, {
                files: { $merge: { data: action.payload, isFetching: false } },
            });

        case FECH_POLITICAL_DIVISIONS:
            return update(state, {
                politicalDivisions: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FECH_POLITICAL_DIVISIONS_FAILURE:
            return update(state, {
                politicalDivisions: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FECH_POLITICAL_DIVISIONS_SUCCESS:
            return update(state, {
                politicalDivisions: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_RATES:
            return update(state, {
                fetchRates: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_RATES_SUCCESS:
            return update(state, {
                fetchRates: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_RATES_FAILURE:
            return update(state, {
                fetchRates: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        default:
            return state;
    }
}
