import React, { useEffect } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { Box, Button, Divider, Grid, Tooltip, Typography } from 'common/ui';
import yupResolver from 'common/utils/yupResolver';

import * as companyGeneralSelectors from '../../companyGeneral/selectors';
import * as actions from '../actions';
import {
    getDisplayLevelOptions,
    getIsDisabledFields,
    getRoleLevelOptions,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import PermissionList from './PermissionList';

const FormWrapper = styled(Box)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgb(47, 51, 53)' : '#EEF4FA',
    borderBottomLeftRadius: '18px',
    borderTopLeftRadius: '18px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
    minHeight: '100%',
    padding: '16px',
}));

const RoleForm = ({
    companyId,
    errors,
    fetchRoleLevelDetails,
    fetchRoleLevels,
    filterData,
    initialValues,
    isFetchingCompanyDetail,
    isFetchingRoleDetails,
    isFetchingRoleLevelDetails,
    isFetchingRoleLevels,
    isNewRecord,
    isSaving,
    roleLevels,
    save,
    subscription,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchRoleLevels(companyId, watch(), isNewRecord);
    }, []);

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const isDefault = watch('is_system_role');

    const isFetching =
        isFetchingCompanyDetail ||
        isFetchingRoleDetails ||
        isFetchingRoleLevelDetails ||
        isFetchingRoleLevels;

    const isDisabled = getIsDisabledFields({
        isDefault,
        isFetching,
        isNewRecord,
    });

    const handleOnSave = (values) => save(filterData, values);

    return (
        <form onSubmit={handleSubmit(handleOnSave)}>
            <Grid container>
                <Grid item xs={4}>
                    <FormWrapper>
                        <Typography fontWeight="bold" mb={1} variant="body2">
                            Rol
                        </Typography>
                        <ReactHookFormInput
                            control={control}
                            disabled={isDisabled}
                            name="name"
                            label={t('Role name')}
                            variant="standard"
                        />
                        {isNewRecord && (
                            <Box alignItems="center" display="flex" gap={1}>
                                <ReactHookFormSelect
                                    control={control}
                                    disabled={isDisabled}
                                    fullWidth
                                    name="level_of_permissions"
                                    label={t('Level of permissions')}
                                    onChange={(e) => {
                                        fetchRoleLevelDetails(
                                            watch(),
                                            e.target.value
                                        );
                                    }}
                                    options={getRoleLevelOptions(roleLevels)}
                                    variant="standard"
                                />
                                <Tooltip title="Este es un mensaje de ayuda">
                                    <Box component="span">
                                        <InfoIcon
                                            color="primary"
                                            fontSize="small"
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                        )}
                        <ReactHookFormSelect
                            control={control}
                            disabled={isDisabled}
                            label={t('Contacts views')}
                            name="contacts_visualization_level"
                            options={getDisplayLevelOptions()}
                            variant="standard"
                        />
                        <ReactHookFormIntlNumberInput
                            allowDecimals={false}
                            allowNegativeValue={false}
                            control={control}
                            disabled={isDisabled}
                            fullWidth
                            label={t('Maximum total discount')}
                            name="maximum_discount"
                            variant="standard"
                        />
                    </FormWrapper>
                </Grid>
                <Grid item xs={8}>
                    <PermissionList
                        control={control}
                        formValues={watch()}
                        isDisabled={isDisabled}
                        isFetching={isFetching}
                        setValue={setValue}
                        subscription={subscription}
                    />
                </Grid>
            </Grid>

            <ShowErrors errors={errors} />

            <Divider sx={{ my: 2 }} />

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'end',
                }}
            >
                {(isNewRecord || !isDefault) && (
                    <Button
                        disabled={isDisabled || isSaving}
                        fullWidth={false}
                        type="submit"
                    >
                        {isNewRecord ? 'Agregar' : 'Guardar'}
                    </Button>
                )}
            </Box>
        </form>
    );
};

RoleForm.propTypes = {
    companyId: PropTypes.string,
    errors: PropTypes.array,
    fetchRoleLevelDetails: PropTypes.func,
    fetchRoleLevels: PropTypes.func,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    isFetchingCompanyDetail: PropTypes.bool,
    isFetchingRoleDetails: PropTypes.bool,
    isFetchingRoleLevelDetails: PropTypes.bool,
    isFetchingRoleLevels: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    roleLevels: PropTypes.array,
    save: PropTypes.func,
    subscription: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetchingCompanyDetail: companyGeneralSelectors.getIsFetchingCompanyDetail,
    isFetchingRoleDetails: selectors.getIsFetchingRoleDetails,
    isFetchingRoleLevelDetails: selectors.getIsFetchingRoleLevelDetails,
    isFetchingRoleLevels: selectors.getIsFetchingRoleLevels,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    roleLevels: selectors.getRoleLevelsData,
    subscription: companyGeneralSelectors.getCompanySubscription,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRoleLevels: (companyId, formValues, isNewRecord) =>
        dispatch(actions.fetchRoleLevels(companyId, formValues, isNewRecord)),
    fetchRoleLevelDetails: (formValues, levelId) =>
        dispatch(actions.fetchRoleLevelDetails(formValues, levelId)),
    save: (filterData, values) => dispatch(actions.save(filterData, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);
