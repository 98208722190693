import sortBy from 'lodash/sortBy';
import { change } from 'redux-form';

import { FETCH_FAMILIES, FETCH_FAMILIES_SUCCESS } from '../actionTypes';
import { NAME } from '../constants';
import * as selectors from '../selectors';

export default (item) => (dispatch, getState) => {
    dispatch({ type: FETCH_FAMILIES });

    const changeInput = (field, value) =>
        dispatch(change(`${NAME}/form`, field, value));

    var families = selectors.getFamiliesData(getState());

    var itemIndex = families.findIndex((f) => f.id === item.id);

    if (itemIndex != -1) {
        families.splice(itemIndex, 1);
    }

    const familiesSorted = sortBy(families, 'name');

    changeInput(
        'families',
        Array.from(familiesSorted, (x) => x.name)
    );

    dispatch({
        type: FETCH_FAMILIES_SUCCESS,
        payload: { data: familiesSorted },
    });
};
