import i18next from 'i18next';

import { updateTrainingData } from 'common/api/ocr/fineTuning';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    SAVE_TRAINING_DATA,
    SAVE_TRAINING_DATA_FAILURE,
    SAVE_TRAINING_DATA_SUCCESS,
} from '../actionTypes';

import initialize from './initialize';

export default ({ id, formValues, modelId }) =>
    (dispatch) => {
        dispatch({ type: SAVE_TRAINING_DATA });

        const newValues = { values: formValues?.values };

        updateTrainingData(id, modelId, newValues)
            .then((response) => {
                dispatch({ type: SAVE_TRAINING_DATA_SUCCESS });
                showToast();
                if (response?.data) dispatch(initialize(response?.data));
            })
            .catch((error) => {
                const rawErrors = error?.response?.data?.detail;

                dispatch({
                    type: SAVE_TRAINING_DATA_FAILURE,
                    payload: rawErrors,
                });

                const errors =
                    rawErrors?.map((error) => `${error?.msg || ''}`) || [];

                const dataAlert = {
                    confirmText: i18next.t('Accept'),
                    messages: errors,
                    title: i18next.t('Error'),
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            });
    };
