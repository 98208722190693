import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';
import { Link, Typography } from 'common/ui';

import ActionsFormatter from './ActionsFormatter';

const Columns = ({ filterData, handleClickDelete, handleClickUpdate }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            field: 'name',
            headerName: t('Name'),
            minWidth: 200,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Link
                        color="secondary"
                        component="button"
                        onClick={() => handleClickUpdate(row)}
                        underline="hover"
                        sx={{ textAlign: 'left' }}
                    >
                        <Typography fontWeight="bold" variant="caption">
                            {row.name}
                        </Typography>
                    </Link>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'url',
            flex: 1,
            headerName: 'Url',
            renderCell: (params) => {
                const { row } = params;
                return <Typography variant="caption">{row.url}</Typography>;
            },
            sortable: false,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        filterData={filterData}
                        handleClickDelete={handleClickDelete}
                        row={row}
                    />
                );
            },
            sortable: false,
            width: 100,
        },
    ];

    return columns;
};

Columns.propTypes = {
    filterData: PropTypes.object,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
};

export default Columns;
