import React from 'react';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Alert, AlertTitle } from 'common/ui';

const ToastNotification = ({ body, title, toastId, type, variant }) => {
    return (
        <Alert
            onClose={() => {
                if (toastId) toast.dismiss(toastId);
                else toast.dismiss();
            }}
            severity={type}
            variant={variant}
        >
            <AlertTitle>{title}</AlertTitle>
            {body}
        </Alert>
    );
};

ToastNotification.propTypes = {
    body: PropTypes.string,
    title: PropTypes.string,
    toastId: PropTypes.number,
    type: PropTypes.string,
    variant: PropTypes.string,
};

export default ToastNotification;
