import i18next from 'i18next';

import { duplicateModel } from 'common/api/ocr/fineTuning';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE_MODEL,
    DUPLICATE_MODEL_FAILURE,
    DUPLICATE_MODEL_SUCCESS,
    SET_IS_OPEN_DUPLICATE_MODEL_FORM,
} from '../actionTypes';

import fetchModels from './fetchModels';

export default (data) => (dispatch) => {
    dispatch({ type: DUPLICATE_MODEL });

    const newValues = { name: data?.name };

    duplicateModel(data?.id, newValues)
        .then(() => {
            dispatch({ type: DUPLICATE_MODEL_SUCCESS });
            dispatch({
                type: SET_IS_OPEN_DUPLICATE_MODEL_FORM,
                payload: false,
            });
            dispatch(fetchModels());
        })
        .catch((error) => {
            const errors = error?.response?.data?.detail || [];
            dispatch({ type: DUPLICATE_MODEL_FAILURE, payload: errors });

            if (Array.isArray(errors) && errors.length > 0) {
                const dataAlert = {
                    confirmText: i18next.t('Close'),
                    messages: errors.map((item) => item?.message || ''),
                    title: i18next.t('Error'),
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'error',
                };

                dispatch(alerts.actions.show(dataAlert));
            } else {
                showToast({
                    autoClose: 3000,
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while duplicating the model'
                    ),
                });
            }
        });
};
