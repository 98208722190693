import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getInitialFilterValues = createSelector(
    getModel,
    (model) => model.initialFilterValues
);

export const getFilterValues = createSelector(
    (state) => getFormValues(`${NAME}/filter-form`)(state),
    (values) => values || {}
);

export const getAgents = createSelector(getModel, (model) => model.agents);

export const getAgentsData = createSelector(getAgents, (model) => model.data);

export const getCompanyDetailModel = createSelector(
    getModel,
    (model) => model.companyDetail
);

export const getIsFetchingCompanyDetail = createSelector(
    getCompanyDetailModel,
    (companyDetail) => companyDetail.isFetching
);

export const getCompanyDetailData = createSelector(
    getCompanyDetailModel,
    (companyDetail) => companyDetail.data || {}
);

export const getCompanyDetailErrors = createSelector(
    getCompanyDetailModel,
    (companyDetail) => companyDetail.errors
);

export const getCompanySubscription = createSelector(
    getCompanyDetailData,
    (data) => data.subscription || {}
);

export const getIsMultiBranchCompany = createSelector(
    getCompanyDetailData,
    (data) => data.is_multibranches
);

export const getBranchOfficesModel = createSelector(
    getModel,
    (model) => model.branchOffices
);

export const getBranchOfficesData = createSelector(
    getBranchOfficesModel,
    (model) =>
        !model.data.data
            ? []
            : model.data.data.map((branchOffice) => ({
                  id: branchOffice.id,
                  name: branchOffice.name,
                  business_name: branchOffice.business_name,
                  web_page: branchOffice.web_page,
                  rfc: branchOffice.rfc,
                  email: branchOffice.contact_email,
              }))
);

export const getBranchOfficesErrors = createSelector(
    getBranchOfficesModel,
    (model) => model.errors
);

export const getBranchOfficesIsFetching = createSelector(
    getBranchOfficesModel,
    (model) => model.isFetching
);

export const getBranchOfficesPagination = createSelector(
    getBranchOfficesModel,
    (model) => model.data.pagination || {}
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getProjectsCounters = createSelector(
    getModel,
    (model) => model.projectsCounters
);

export const getProjectsCountersData = createSelector(
    getProjectsCounters,
    (model) => model.data
);

export const getIsFetchingProjectsCounters = createSelector(
    getProjectsCounters,
    (model) => model.isFetching
);

export const getProjectsCountersErrors = createSelector(
    getProjectsCounters,
    (model) => model.error
);

export const getProposalsTotals = createSelector(
    getModel,
    (model) => model.proposalsTotals
);

export const getProposalsTotalsData = createSelector(
    getProposalsTotals,
    (model) => model.data
);

export const getIsFetchingProposalsTotals = createSelector(
    getProposalsTotals,
    (model) => model.isFetching
);

export const getProposalsTotalsErrors = createSelector(
    getProposalsTotals,
    (model) => model.error
);

export const getUpdateSubscription = createSelector(
    getModel,
    (model) => model.updateSubscription
);

export const getIsUpdatingSubscription = createSelector(
    getUpdateSubscription,
    (save) => save.isFetching
);

export const getUpdateSubscriptionErrors = createSelector(
    getUpdateSubscription,
    (save) => save.errors
);

// SUBSCRIPTION SOCKET
export const getSocketConected = createSelector(
    getModel,
    (model) => model.socketConnected
);
export const getSocketMessage = createSelector(
    getModel,
    (model) => model.socketMessage
);
export const getSocketHandler = createSelector(
    getSocketMessage,
    (message) => message.handler
);
export const getSocketError = createSelector(
    getModel,
    (model) => model.socketError
);
