import update from 'immutability-helper';
import { 
    SAVE, 
    SAVE_SUCCESS, 
    SAVE_FAILURE, 
    VALID_PASSWORD,
    INVALID_PASSWORD,
    VALID_PASSWORD_CONFIRMATION,
    INVALID_PASSWORD_CONFIRMATION,
    VALID_PASSWORD_CONFIRMATION_LENGTH,
    INVALID_PASSWORD_CONFIRMATION_LENGTH
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        old_password: null,
        new_password: null,
        confirm_password: null,
        profile_image: null,
    },
    fetch: {
        isFetching: false,
        data: [],
        error: null,
    },
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    validPassword: false,
    validPasswordConfirmation: false,
    validPasswordConfirmationLength: false
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case VALID_PASSWORD:
            return update(state, {
                validPassword: {
                    $set: true,
                },
            });

        case INVALID_PASSWORD:
            return update(state, {
                validPassword: {
                    $set: false,
                },
            });

        case VALID_PASSWORD_CONFIRMATION:
            return update(state, {
                validPasswordConfirmation: {
                    $set: true,
                },
            });

        case INVALID_PASSWORD_CONFIRMATION:
            return update(state, {
                validPasswordConfirmation: {
                    $set: false,
                },
            });

        case VALID_PASSWORD_CONFIRMATION_LENGTH:
            return update(state, {
                validPasswordConfirmationLength: {
                    $set: true,
                },
            });

        case INVALID_PASSWORD_CONFIRMATION_LENGTH:
            return update(state, {
                validPasswordConfirmationLength: {
                    $set: false,
                },
            });


        default:
            return state;
    }
}
