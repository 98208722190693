import { NAME } from './constants';

export const FETCH_API_ACCOUNTS = `${NAME}/FETCH_API_ACCOUNTS`;
export const FETCH_API_ACCOUNTS_FAILURE = `${NAME}/FETCH_API_ACCOUNTS_FAILURE`;
export const FETCH_API_ACCOUNTS_SUCCESS = `${NAME}/FETCH_API_ACCOUNTS_SUCCESS`;

export const FETCH_API_ACCOUNT_DETAIL = `${NAME}/FETCH_API_ACCOUNT_DETAIL`;
export const FETCH_API_ACCOUNT_DETAIL_FAILURE = `${NAME}/FETCH_API_ACCOUNT_DETAIL_FAILURE`;
export const FETCH_API_ACCOUNT_DETAIL_SUCCESS = `${NAME}/FETCH_API_ACCOUNT_DETAIL_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_ITEM_FORM = `${NAME}/OPEN_ITEM_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const SET_FILTERS = `${NAME}/SET_FILTERS`;
export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;

export const DELETE_API_ACCOUNT = `${NAME}/DELETE_API_ACCOUNT`;
export const DELETE_API_ACCOUNT_SUCCESS = `${NAME}/DELETE_API_ACCOUNT_SUCCESS`;
export const DELETE_API_ACCOUNT_FAILURE = `${NAME}/DELETE_API_ACCOUNT_FAILURE`;
