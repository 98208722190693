import React from 'react';

import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import ReduxFieldInput from 'common/components/form/redux/ReduxFieldInput';
import { Box, Typography } from 'common/ui';
import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

const ChatWrapper = styled(Box)`
    align-items: center;
    background-color: ${({ theme }) =>
        theme.palette.grey[500_12] || 'rgba(255, 255, 255, 0.09)'};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 8px;
    border-radius: 4px;
`;

const FormContainer = styled('form')`
    width: 100%;
`;

const ScrollableContent = styled(Box)`
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    margin-bottom: 8px;
    margin-top: 8px;
    min-height: 0;
    overflow: auto;
    width: 100%;
    ${scroll.custom}
`;

const Comment = styled(Box)`
    background-color: ${({ theme }) =>
        theme.palette.grey[500_32] || 'rgba(255, 255, 255, 0.09)'};
    border-radius: 0 3px 3px 3px;
    font-size: ${14 / 16}rem;
    max-width: 80%;
    overflow-wrap: break-word;
    padding: 8px;
    text-wrap: wrap;
    ${({ role, theme }) =>
        role === 'user' &&
        `
        background-color: ${theme.palette.primary.main};
        border-radius: 3px 0 3px 3px;
    `}
`;

const Message = styled(Box)`
    display: flex;
    justify-content: flex-start;
    padding: 8px;
    width: 100%;
    ${({ role }) => role === 'user' && `justify-content: flex-end;`}
`;

const ChatContainer = ({
    handleSubmit,
    isSendingMessages,
    messages = [],
    reset,
    sendMessages,
}) => {
    const onSubmit = (values) => {
        if (!values.content || isSendingMessages) return;
        const newMessage = { role: 'user', content: values.content };
        sendMessages(messages, newMessage);
        reset();
    };

    return (
        <ChatWrapper>
            <Typography color="primary" variant="subtitle1">
                Chat GPT
            </Typography>

            <ScrollableContent>
                <Box>
                    {messages.map((comment, index) => (
                        <Message key={`comment-${index}`} role={comment.role}>
                            <Comment role={comment.role}>
                                {comment.content}
                            </Comment>
                        </Message>
                    ))}
                </Box>
            </ScrollableContent>

            <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <Field
                    autoComplete="off"
                    disabled={isSendingMessages}
                    component={ReduxFieldInput}
                    endAdornmentIcon={
                        <SendIcon
                            color="primary"
                            disabled={isSendingMessages}
                        />
                    }
                    handleClickButtonEndAdornment={handleSubmit(onSubmit)}
                    name="content"
                    variant="outlined"
                />
            </FormContainer>
        </ChatWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getChatInitialValues,
    isSendingMessages: selectors.getSendMessagesIsSending,
    messages: selectors.getMessages,
});
const mapDispatchToProps = (dispatch) => ({
    sendMessages: (messages, newMessage) =>
        dispatch(actions.sendMessages(messages, newMessage)),
});

ChatContainer.propTypes = {
    handleSubmit: PropTypes.func,
    isSendingMessages: PropTypes.bool,
    messages: PropTypes.array,
    reset: PropTypes.func,
    sendMessages: PropTypes.func,
};

const form = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/chat-form`,
})(ChatContainer);

export default connect(mapStateToProps, mapDispatchToProps)(form);
