import i18next from 'i18next';

import { deleteFamily } from 'common/api/productsFamilies';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    DELETE_FAMILY,
    DELETE_FAMILY_SUCCESS,
    DELETE_FAMILY_FAILURE,
} from '../actionTypes';

import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (id, onSuccessCallback = null) =>
    (dispatch) => {
        dispatch({ type: DELETE_FAMILY });

        deleteFamily(id)
            .then(() => {
                dispatch({ type: DELETE_FAMILY_SUCCESS, payload: null });
                dispatch(alerts.actions.close());
                dispatch(toggleModal(false));
                dispatch(resetForm());

                showToast({ body: i18next.t('It was successfully removed') });

                if (onSuccessCallback) {
                    onSuccessCallback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_FAMILY_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch(alerts.actions.close());

                const dataAlert = {
                    confirmText: i18next.t('Close'),
                    messages: [
                        `${i18next.t('Whoops, something went wrong')}:`,
                        ...error.response.data.errors,
                    ],
                    title: i18next.t('Error'),
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'error',
                };

                dispatch(alerts.actions.show(dataAlert));
            });
    };
