import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import {
    CONFIGURATION_PERMISSIONS_TAB,
    CONTACT_PERMISSIONS_TAB,
    NAME,
    PROPOSER_PERMISSIONS_TAB,
} from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getRolesModel = createSelector(getModel, (model) => model.roles);

export const getRolesData = createSelector(getRolesModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((role) => ({
              ...role,
          }))
);

export const getRolesErrors = createSelector(
    getRolesModel,
    (model) => model.errors
);

export const getRolesIsFetching = createSelector(
    getRolesModel,
    (model) => model.isFetching
);

export const getRolesPagination = createSelector(
    getRolesModel,
    (model) => model.data.pagination || {}
);

export const getRoleDetail = createSelector(
    getModel,
    (model) => model.roleDetail
);

export const getIsFetchingRoleDetail = createSelector(
    getRoleDetail,
    (roleDetail) => roleDetail.isFetching
);

export const getIsOpenModalEdition = createSelector(
    getModel,
    (model) => model.isOpenModalEdition || false
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getCategoriesSettingsModel = createSelector(
    getModel,
    (model) => model.categoriesSettings
);

export const getCategoriesSettingsData = createSelector(
    getCategoriesSettingsModel,
    (model) => model.data || {}
);

export const getContactPermissions = createSelector(
    getCategoriesSettingsData,
    (model) => {
        const data = model.filter(
            (item) => item.tab === CONTACT_PERMISSIONS_TAB
        );
        return data.map((category) => {
            category.permissions = ['VIEW', 'ADD', 'EDIT', 'DELETE'];
            category.groups.map((group) => {
                group.hidden = false;
                group.permissions = ['VIEW', 'ADD', 'EDIT', 'DELETE'];
                return group;
            });
            return category;
        });
    }
);

export const getProposerPermissions = createSelector(
    getCategoriesSettingsData,
    (model) => {
        const data = model.filter(
            (item) => item.tab === PROPOSER_PERMISSIONS_TAB
        );
        return data.map((category) => {
            category.permissions = ['ENABLE'];
            category.groups.map((group) => {
                group.hidden = false;
                group.permissions = ['EDIT'];
                return group;
            });
            return category;
        });
    }
);

export const getConfigurationPermissions = createSelector(
    getCategoriesSettingsData,
    (model) => {
        const data = model.filter(
            (item) => item.tab === CONFIGURATION_PERMISSIONS_TAB && !item.hidden
        );

        return data.map((category) => {
            category.permissions = ['VIEW', 'ADD', 'EDIT', 'DELETE'];
            category.groups.map((group) => {
                group.hidden = false;
                group.permissions = ['VIEW', 'ADD', 'EDIT', 'DELETE'];
                return group;
            });

            return category;
        });
    }
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getDataSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.data
);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);
