import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import styled from 'styled-components';

import ReduxFieldCheck from 'common/components/form/redux/ReduxFieldCheck';
import { Grid } from 'common/ui';

const ButtonAction = styled.div`
    align-items: center;
    background: rgba(238, 244, 250, 0.2);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 0 0 0 transparent !important;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 10px;
    text-align: center;
    width: 100%;
`;

const HeaderColumn = ({
    inputId,
    isDisabled,
    label,
    name,
    onchange,
    visible = true,
}) => {
    return visible ? (
        <Grid
            item
            xs={2}
            md={1}
            sx={{
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
                borderRadius: '8px 8px 0 0',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <ButtonAction>
                {label}
                <Field
                    component={ReduxFieldCheck}
                    disabled={isDisabled}
                    id={inputId}
                    label=""
                    name={name}
                    onChange={() => onchange(name)}
                />
            </ButtonAction>
        </Grid>
    ) : null;
};

HeaderColumn.propTypes = {
    inputId: PropTypes.string,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onchange: PropTypes.func,
    visible: PropTypes.bool,
};

export default HeaderColumn;
