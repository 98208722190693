import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    AutocompleteField,
    AutocompleteMultipleField,
    InputSearch,
    ManageCsvDropdown,
} from 'common/components';
import { Card, CardContent, Grid } from 'common/ui';

const BulkOperationsCard = ({
    countryOptions,
    downloadFile,
    filterData,
    handleClickCountry,
    handleClickPlan,
    handleClickStatus,
    handleOnChangeSearch,
    isFetchingCountries,
    isFetchingPlans,
    plansOptions,
    statusList,
}) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid
                    alignItems="end"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item xs={12} md={11} lg={11}>
                        <InputSearch
                            placeholder={t('Search')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid item xs={2} md={1} lg={1} sx={{ textAlign: 'right' }}>
                        <ManageCsvDropdown
                            handleDownload={() => downloadFile(filterData)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AutocompleteField
                            disabled={isFetchingPlans}
                            label={
                                isEmpty(filterData?.planName)
                                    ? t('All plans')
                                    : t('Plan')
                            }
                            onChange={handleClickPlan}
                            options={plansOptions}
                            value={filterData?.planName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AutocompleteField
                            disabled={isFetchingCountries}
                            label={
                                isEmpty(filterData?.countryName)
                                    ? t('All countries')
                                    : t('Country')
                            }
                            onChange={handleClickCountry}
                            options={countryOptions}
                            value={filterData?.countryName}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={6}>
                        <AutocompleteMultipleField
                            label={
                                filterData?.subscriptionStatus.length === 0
                                    ? t('All status')
                                    : t('Status')
                            }
                            onChange={handleClickStatus}
                            options={statusList}
                            value={filterData?.subscriptionStatus}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    countryOptions: PropTypes.array,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    handleClickCountry: PropTypes.func,
    handleClickPlan: PropTypes.func,
    handleClickStatus: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    isFetchingCountries: PropTypes.bool,
    isFetchingPlans: PropTypes.bool,
    plansOptions: PropTypes.array,
    statusList: PropTypes.array,
};

export default BulkOperationsCard;
