import { createSelector } from 'reselect';

import { NAME } from './constants';
import { formatDateFromISO } from './helpers';

export const getModel = (state) => state[NAME];

export const getFilesModel = createSelector(getModel, (model) => model.files);

export const getFilesData = createSelector(
    getFilesModel,
    (model) =>
        model?.data?.map((item) => ({
            created_at: formatDateFromISO(item?.created_at),
            file: item?.file,
            id: item?.id,
            status: item?.status,
        })) || []
);

export const getFilesErrors = createSelector(
    getFilesModel,
    (model) => model.errors
);

export const getFilesIsFetching = createSelector(
    getFilesModel,
    (model) => model.isFetching
);
