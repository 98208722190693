import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Breadcrumbs, HeaderContainer, TitleIcon } from 'common/components';
import { Button, Container, Stack, Typography } from 'common/ui';

import { MODEL_STATUS_KEYS } from '../fineTuningAI/constants';
import * as tagsActions from '../trainingDataAITags/actions';
import * as tagsSelectors from '../trainingDataAITags/selectors';

import * as actions from './actions';
import Filter from './components/Filter';
import Form from './components/Form';
import TrainingDataTable from './components/TrainingDataTable';
import * as selectors from './selectors';

const ModelTrainingDataContainer = ({
    bulkUploadTrainingDocuments,
    deleteTrainingData,
    fetchModel,
    fetchTags,
    initialValues,
    isFetchingModel,
    isOpenModal,
    isSavingTrainingData,
    match,
    modelTrainingData,
    prepareUpdateTrainingData,
    redirect,
    reset,
    resetForm,
    restartTrainingData,
    saveEmptyTrainingData,
    setIsOpenModal,
    trainingDataTags,
    updateTrainingData,
}) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const { t } = useTranslation();
    const modelId = match?.params?.id;

    useEffect(() => {
        fetchModel(modelId);
        fetchTags();
        return () => reset();
    }, [modelId]);

    const locationsBreadcrumbs = [
        {
            label: t('Model', { count: 2 }),
            link: '/ai/fine-tuning',
        },
    ];

    const handleFilterTrainingData = () => {
        fetchModel(modelId, selectedTags);
    };

    const handleDeleteTrainingData = (id) => deleteTrainingData(id, modelId);

    const handleCloseModalForm = () => {
        setIsOpenModal(false);
        resetForm();
    };

    const handleOnSubmit = (values) => {
        if (values.id) updateTrainingData(values, modelId, selectedTags);
        else bulkUploadTrainingDocuments(values, modelId, selectedTags);
    };

    const tagsOptions = trainingDataTags.map((tag) => ({
        color: tag.color,
        label: tag.name,
        value: tag.id,
    }));

    const modelCanBeTrained =
        modelTrainingData?.status !== MODEL_STATUS_KEYS.TRAINED &&
        modelTrainingData?.status !== MODEL_STATUS_KEYS.TRAINING;

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={PsychologyIcon}
                        title={t('Model', { count: 2 })}
                    />
                </Typography>

                <Button
                    disabled={
                        isFetchingModel ||
                        isSavingTrainingData ||
                        !modelCanBeTrained
                    }
                    fullWidth={false}
                    onClick={() => setIsOpenModal(true)}
                    startIcon={<AddIcon />}
                >
                    {t('Add')}
                </Button>
            </HeaderContainer>

            <Stack mb={3} pl={3}>
                <TitleIcon
                    isSvgIcon={true}
                    svgIcon={PsychologyIcon}
                    title={t('Training data', { count: 2 })}
                />
                <Breadcrumbs
                    current={{ label: modelTrainingData?.name ?? '' }}
                    isFetchingData={isFetchingModel}
                    locations={locationsBreadcrumbs}
                />
            </Stack>

            <Filter
                disabled={isFetchingModel}
                handleFilterTrainingData={handleFilterTrainingData}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                tagsOptions={tagsOptions}
            />

            <TrainingDataTable
                deleteTrainingData={handleDeleteTrainingData}
                disabled={!modelCanBeTrained}
                isFetchingModel={isFetchingModel}
                onRowClick={(id) =>
                    redirect(`/ai/fine-tuning/${modelId}/training-data/${id}`)
                }
                prepareUpdateTrainingData={(id) =>
                    prepareUpdateTrainingData(id, modelId)
                }
                restartTrainingData={(id) => restartTrainingData(id, modelId)}
                trainingData={modelTrainingData?.training_data || []}
            />

            <Form
                disabled={isSavingTrainingData || !modelCanBeTrained}
                handleCloseModalForm={handleCloseModalForm}
                handleCreateEmptyTrainingData={() =>
                    saveEmptyTrainingData(modelId, selectedTags)
                }
                initialValues={initialValues}
                isOpenModal={isOpenModal}
                onSubmit={handleOnSubmit}
                tagsOptions={tagsOptions}
            />
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetchingModel: selectors.getModelTrainingDataIsFetching,
    isOpenModal: selectors.getIsOpenModal,
    isSavingTrainingData: selectors.getSaveTrainingDataIsSaving,
    modelTrainingData: selectors.getDataModelTrainingData,
    trainingDataTags: tagsSelectors.getFetchTagsData,
});

const mapDispatchToProps = (dispatch) => ({
    bulkUploadTrainingDocuments: (values, modelId, query) =>
        dispatch(actions.bulkUploadTrainingDocuments(values, modelId, query)),
    deleteTrainingData: (id, modelId) =>
        dispatch(actions.deleteTrainingData(id, modelId)),
    fetchModel: (id, query) => dispatch(actions.fetchModel(id, query)),
    fetchTags: () => dispatch(tagsActions.fetchTags()),
    prepareUpdateTrainingData: (id, modelId) =>
        dispatch(actions.prepareUpdateTrainingData(id, modelId)),
    redirect: (url) => dispatch(push(url)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
    restartTrainingData: (id, modelId) =>
        dispatch(actions.restartTrainingData(id, modelId)),
    saveEmptyTrainingData: (modelId, query) =>
        dispatch(actions.saveEmptyTrainingData(modelId, query)),
    setIsOpenModal: (isOpen) => dispatch(actions.setIsOpenModal(isOpen)),
    updateTrainingData: (values, modelId, query) =>
        dispatch(actions.updateTrainingData(values, modelId, query)),
});

ModelTrainingDataContainer.propTypes = {
    bulkUploadTrainingDocuments: PropTypes.func,
    deleteTrainingData: PropTypes.func,
    fetchModel: PropTypes.func,
    fetchTags: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingModel: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isSavingTrainingData: PropTypes.bool,
    match: PropTypes.object,
    modelTrainingData: PropTypes.object,
    prepareUpdateTrainingData: PropTypes.func,
    redirect: PropTypes.func,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    restartTrainingData: PropTypes.func,
    saveEmptyTrainingData: PropTypes.func,
    setIsOpenModal: PropTypes.func,
    trainingDataTags: PropTypes.array,
    updateTrainingData: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelTrainingDataContainer);
