import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomSelect } from 'common/components/form';
import { Button, Card, CardContent, TextField, Grid } from 'common/ui';

const BulkOperationsCard = ({
    downloadBulk,
    filterData,
    handleClickOnFilter,
    handleOnChangeFilter,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();

    const handleBulkDownload = (selectionModel) => {
        downloadBulk(selectionModel, () => setSelectionModel(() => []));
    };

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid
                    alignItems="end"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('Address')}
                            onChange={(e) => handleOnChangeFilter(e, 'address')}
                            value={filterData.address}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('Name')}
                            onChange={(e) => handleOnChangeFilter(e, 'name')}
                            value={filterData.name}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('Service Number')}
                            onChange={(e) =>
                                handleOnChangeFilter(e, 'service_number')
                            }
                            value={filterData.service_number}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('Rate')}
                            onChange={(e) => handleOnChangeFilter(e, 'rate')}
                            value={filterData.rate}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item md={3} sm={6} xs={12}>
                        <TextField
                            fullWidth
                            label={t('Zip Code')}
                            onChange={(e) =>
                                handleOnChangeFilter(e, 'zip_code')
                            }
                            value={filterData.zip_code}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item lg={3} md={2} sm={6} xs={12}>
                        <CustomSelect
                            label={t('Billing period')}
                            onChange={(e) =>
                                handleOnChangeFilter(e, 'is_bimonthly')
                            }
                            options={[
                                { label: t('All'), optionValue: -1 },
                                { label: t('Monthly'), optionValue: 0 },
                                { label: t('Bimonthly'), optionValue: 1 },
                            ]}
                            value={filterData.is_bimonthly}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item md={2} sm={6} xs={12}>
                        <CustomSelect
                            label={t('Status')}
                            onChange={(e) => handleOnChangeFilter(e, 'status')}
                            options={[
                                { label: t('Failed'), optionValue: 0 },
                                { label: t('Success'), optionValue: 1 },
                            ]}
                            value={filterData.status}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item lg={1} md={2} sm={6} xs={12}>
                        <Button onClick={handleClickOnFilter}>
                            {t('Filter')}
                        </Button>
                    </Grid>

                    {!isEmpty(selectionModel) && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                onClick={() =>
                                    handleBulkDownload(selectionModel)
                                }
                            >
                                {t('Download')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    downloadBulk: PropTypes.func,
    filterData: PropTypes.object,
    handleClickOnFilter: PropTypes.func,
    handleOnChangeFilter: PropTypes.func,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
