import { filterFamiliesByBrand } from 'common/api/productsFamilies';

import {
    FETCH_FAMILIES,
    FETCH_FAMILIES_SUCCESS,
    FETCH_FAMILIES_FAILURE,
} from '../actionTypes';

export default (brandId, str = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_FAMILIES });

        filterFamiliesByBrand(brandId, str)
            .then((response) => {
                dispatch({
                    type: FETCH_FAMILIES_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_FAMILIES_FAILURE,
                    payload: error.response.data.errors,
                })
            );
    };
