import { string, object, bool } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { REWIND_TYPES } from './constants';

export default () => {
    const { required } = getValidationTexts();

    return object().shape({
        initial_date: string().when('rewind_type', {
            is: REWIND_TYPES.ALL,
            then: string().nullable(),
            otherwise: string().required(required),
        }),
        final_date: string().when('rewind_type', {
            is: REWIND_TYPES.RANGE,
            then: string().required(required),
            otherwise: string().nullable(),
        }),
        freq: string().required(required),
        clear_all_reads: bool(),
    });
};
