import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteBrand from './deleteBrand';

export default (item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [
            `${i18next.t(
                'Removing this brand will remove all associated families'
            )}.`,
        ],
        onSuccess: () => dispatch(deleteBrand(item.id)),
        title: i18next.t('Delete brand'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(alerts.actions.show(dataAlert));
};
