import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Box, Button, Container } from 'common/ui';

import * as actions from './actions';
import { ListItems, ProductForm } from './components';
import * as selectors from './selectors';

const FontsContainer = ({
    fetchFonts,
    fonts,
    handleClickCreate,
    handleClickDelete,
    handleClickUpdate,
    isFetching,
    isOpenProductForm,
    pagination,
    reset,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: null,
        page: 0,
        pageSize: 25,
        sortBy: null,
    });

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        fetchFonts(filterData);
    }, []);

    return (
        <>
            {!isOpenProductForm ? (
                <Container maxWidth="">
                    <Box mb={3} textAlign="right">
                        <Button
                            disabled={isFetching}
                            fullWidth={false}
                            height="48px"
                            onClick={() => handleClickCreate()}
                            startIcon={<AddIcon />}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            type="button"
                        >
                            {t('Add')}
                        </Button>
                    </Box>

                    <ListItems
                        filterData={filterData}
                        filterItems={fetchFonts}
                        handleClickDelete={handleClickDelete}
                        handleClickUpdate={handleClickUpdate}
                        isFetching={isFetching}
                        items={fonts}
                        paginationData={pagination}
                        setFilterData={setFilterData}
                    />
                </Container>
            ) : (
                <Container maxWidth="">
                    <ProductForm filterData={filterData} />
                </Container>
            )}
        </>
    );
};

FontsContainer.propTypes = {
    fetchFonts: PropTypes.func,
    fonts: PropTypes.array,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    isOpenProductForm: PropTypes.bool,
    pagination: PropTypes.object,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    fonts: selectors.getFontsData,
    isFetching: selectors.getFontsIsFetching,
    isOpenProductForm: selectors.getIsOpenProductForm,
    pagination: selectors.getFontsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFonts: (filterData) => dispatch(actions.fetchFonts(filterData)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickDelete: (filterData, item) =>
        dispatch(actions.prepareDelete(filterData, item)),
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FontsContainer);
