import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import { PlaceholderProductHeader } from 'common/components/placeholders';
import { Box, Card, CardContent, Grid, Typography } from 'common/ui';

import * as actions from './actions';
import { Form } from './components';
import * as selectors from './selectors';

const Container = ({
    formValues,
    handleClickDelete,
    isFetchingDetail,
    isFetchingProductDetail,
    isEnabledEdition,
    isUploadingThumbnail,
    placeholder,
    reset,
    thumbnail,
    uploadThumbnailErrors,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    const onHandleClickDelete = () => {
        handleClickDelete(formValues.id);
    };

    return (
        <PlaceholderProductHeader
            ready={
                isFetchingDetail ||
                isFetchingProductDetail ||
                isUploadingThumbnail
            }
        >
            <Card sx={{ display: 'flex' }}>
                <Grid container>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <Form
                            formValues={formValues}
                            isEnabledEdition={isEnabledEdition}
                            onHandleClickDelete={onHandleClickDelete}
                            placeholder={placeholder}
                            thumbnail={thumbnail}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={10}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h6">
                                    {formValues?.name || t('Nameless')}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    {formValues?.name ||
                                        t('Without description')}
                                </Typography>

                                <ShowErrors
                                    errors={uploadThumbnailErrors}
                                    pt={3}
                                    severity="error"
                                    variant="filled"
                                />
                            </CardContent>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </PlaceholderProductHeader>
    );
};

Container.propTypes = {
    formValues: PropTypes.object,
    handleClickDelete: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isFetchingProductDetail: PropTypes.bool,
    isUploadingThumbnail: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    reset: PropTypes.func,
    thumbnail: PropTypes.object,
    uploadThumbnailErrors: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetchingProductDetail: selectors.getIsLoadingProductThumbnail,
    isUploadingThumbnail: selectors.getIsUploadingThumbnail,
    thumbnail: selectors.getDataProductThumbnail,
    uploadThumbnailErrors: selectors.getUploadThumbnailErrors,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProductThumbnail: (id) => dispatch(actions.fetchProductThumbnail(id)),
    handleClickDelete: (id) => dispatch(actions.deleteThumbnail(id)),
    save: (values, id) => dispatch(actions.uploadThumbnail(values, id)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
