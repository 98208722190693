import { getUnits } from 'common/api/v1/rates';

import {
    FETCH_UNITS,
    FETCH_UNITS_FAILURE,
    FETCH_UNITS_SUCCESS,
} from '../actionTypes';

import fetchContractedDemandUnits from './fetchContractedDemandUnits';
import fetchSpainExcessDemandUnits from './fetchSpainExcessDemandUnits';

const getUnitsPromise = (type) =>
    new Promise((resolve, reject) =>
        getUnits(type)
            .then((response) => resolve(response.data.data))
            .catch((error) => reject(error))
    );

export default () => (dispatch) => {
    dispatch({ type: FETCH_UNITS });
    dispatch(fetchContractedDemandUnits());
    dispatch(fetchSpainExcessDemandUnits());
    Promise.all([
        getUnitsPromise('energy'),
        getUnitsPromise('demand'),
        getUnitsPromise('others'),
    ])
        .then((response) => {
            const data = response.reduce(
                (acc, current) => [...acc, ...current],
                []
            );
            dispatch({
                type: FETCH_UNITS_SUCCESS,
                payload: data,
            });
        })
        .catch((error) =>
            dispatch({ type: FETCH_UNITS_FAILURE, payload: error })
        );
};
