import i18next from 'i18next';
import get from 'lodash/get';
import { change } from 'redux-form';

import { createOrigin } from 'common/api/productsCountry';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    SAVE_ORIGIN,
    SAVE_ORIGIN_SUCCESS,
    SAVE_ORIGIN_FAILURE,
} from '../actionTypes';
import { NAME } from '../constants';

export default (values) => (dispatch) => {
    const changeInput = (field, value) =>
        dispatch(change(`${NAME}/form`, field, value));

    const newValues = {
        name: values.label,
    };
    dispatch({ type: SAVE_ORIGIN });

    createOrigin(newValues)
        .then((response) => {
            dispatch({
                type: SAVE_ORIGIN_SUCCESS,
                payload: [response.data],
            });
            dispatch(alerts.actions.close());
            changeInput('country', get(response.data, 'id', ''));

            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_ORIGIN_FAILURE,
                payload: error.response.data.errors,
            });

            dispatch(alerts.actions.close());

            const dataAlert = {
                confirmText: i18next.t('Close'),
                messages: [
                    `${i18next.t('Whoops, something went wrong')}:`,
                    ...error.response.data.errors,
                ],
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'error',
            };

            dispatch(alerts.actions.show(dataAlert));
        });
};
