import update from 'immutability-helper';

import {
    DELETE_TAG,
    DELETE_TAG_FAILURE,
    DELETE_TAG_SUCCESS,
    FETCH_TAGS,
    FETCH_TAGS_FAILURE,
    FETCH_TAGS_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE_TAG,
    SAVE_TAG_FAILURE,
    SAVE_TAG_SUCCESS,
    SET_MODAL_IS_OPEN,
    SET_IS_NEW_TAG,
} from './actionTypes';

const INITIAL_STATE = {
    deleteTag: { errors: [], isDeleting: false },
    fetchTags: { data: [], errors: [], isFetching: false },
    initialValues: { color: '#5e72e4', name: null },
    isNewTag: true,
    isOpenModal: false,
    saveTag: { errors: [], isSaving: false },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DELETE_TAG:
            return update(state, {
                deleteTag: { $merge: { errors: [], isDeleting: true } },
            });

        case DELETE_TAG_FAILURE:
            return update(state, {
                deleteTag: {
                    $merge: { errors: action.payload, isDeleting: false },
                },
            });

        case DELETE_TAG_SUCCESS:
            return update(state, {
                deleteTag: { $merge: { isDeleting: false } },
            });

        case FETCH_TAGS:
            return update(state, {
                fetchTags: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_TAGS_FAILURE:
            return update(state, {
                fetchTags: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_TAGS_SUCCESS:
            return update(state, {
                fetchTags: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case INITIALIZE_FORM:
            return update(state, { initialValues: { $merge: action.payload } });

        case RESET:
            return update(state, { $set: INITIAL_STATE });

        case RESET_FORM:
            return update(state, {
                initialValues: { $set: INITIAL_STATE.initialValues },
            });

        case SAVE_TAG:
            return update(state, {
                saveTag: { $merge: { errors: [], isSaving: true } },
            });

        case SAVE_TAG_FAILURE:
            return update(state, {
                saveTag: {
                    $merge: { errors: action.payload, isSaving: false },
                },
            });

        case SAVE_TAG_SUCCESS:
            return update(state, {
                saveTag: { $merge: { isSaving: false } },
            });

        case SET_MODAL_IS_OPEN:
            return update(state, { isOpenModal: { $set: action.payload } });

        case SET_IS_NEW_TAG:
            return update(state, { isNewTag: { $set: action.payload } });

        default:
            return state;
    }
}
