import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Typography } from 'common/ui';

import * as actions from '../actions';

const ContactsVisualizationFormatter = (cell) => (
    <Typography
        variant="subtitle2"
        sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }}
        title={cell && cell.value}
    >
        {cell ? cell.value : '- - -'}
    </Typography>
);

const RolesPreviewTable = ({
    companyId,
    handleClickUpdate,
    handleSortable,
    isFetching,
    items,
    sortedBy,
    pagination,
    orderdBy,
}) => {
    const { t } = useTranslation();
    const columns = [
        {
            dataField: 'name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Role'),
            width: '10%',
        },
        {
            dataField: 'level',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Rol level'),
            width: '10%',
        },
        {
            dataField: 'contacts_visualization_level',
            formatter: ContactsVisualizationFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('User visualization'),
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={(item) => handleClickUpdate(companyId, item)}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

RolesPreviewTable.propTypes = {
    companyId: PropTypes.string,
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
    orderdBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (companyId, item) =>
        dispatch(actions.prepareUpdate(companyId, item)),
});

export default connect(null, mapDispatchToProps)(RolesPreviewTable);
