import i18next from 'i18next';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { getOutstandingDetail } from 'common/api/outstanding';
import showToast from 'common/utils/showToast';

import {
    FETCH_OUTSTANDING_DETAIL,
    FETCH_OUTSTANDING_DETAIL_FAILURE,
    FETCH_OUTSTANDING_DETAIL_SUCCESS,
} from '../actionTypes';

import initializeForm from './initializeForm';
import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (item) => (dispatch) => {
    dispatch({ type: FETCH_OUTSTANDING_DETAIL });
    dispatch(resetForm());
    dispatch(toggleModal(true));

    const outstandingId = get(item, 'outstanding.id', '');

    getOutstandingDetail(outstandingId)
        .then((response) => {
            dispatch({
                type: FETCH_OUTSTANDING_DETAIL_SUCCESS,
                payload: response.data,
            });

            const values = {
                ...response.data,
                days_left: response.data.days_left,
                days_validity: response.data.days_validity,
                unlimited:
                    isNil(response.data.days_left) ||
                    parseInt(response.data.days_left) === 0,
            };

            dispatch(initializeForm(values));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_OUTSTANDING_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t(
                    'An error occurred while querying the template'
                ),
            });

            dispatch(toggleModal(false));
        });
};
