import { object, string, number } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ REQUIRED_TEXT, NUMBER_TYPE_TEXT, MIN_ZERO_VALIDATION_TEXT }) =>
        object().shape({
            brand: string().required(REQUIRED_TEXT).nullable(),
            family: string().required(REQUIRED_TEXT).nullable(),
            product_type: string().required(REQUIRED_TEXT).nullable(),
            name: string().required(REQUIRED_TEXT).nullable(),
            code: string().required(REQUIRED_TEXT).nullable(),
            cost: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .nullable(),
            storage_cap_max: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            cycles: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            P_charge_max: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            I_charge_max: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            DoD: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            c_nom: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            P_discharge_max: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            I_discharge_max: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
        })
);
