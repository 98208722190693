import { NAME } from './constants';

export const FETCH_RATE_HISTORY_DATAGRID = `${NAME}/FETCH_RATE_HISTORY_DATAGRID`;
export const FETCH_RATE_HISTORY_DATAGRID_FAILURE = `${NAME}/FETCH_RATE_HISTORY_DATAGRID_FAILURE`;
export const FETCH_RATE_HISTORY_DATAGRID_SUCCESS = `${NAME}/FETCH_RATE_HISTORY_DATAGRID_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
