import React from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Chip, Grid, Typography } from 'common/ui';

import { getActionColor, getActionName, getModelName } from '../helpers';

const LogItem = ({ handleSelectLog, logData }) => {
    const { t } = useTranslation();
    const { action_flag, action_time, content_type, object_str, user_data } =
        logData;

    return (
        <Grid alignItems="center" container spacing={1}>
            <Grid item md={7} xs={10}>
                <Chip label={get(user_data, 'name', '')} size="small" />
                <Typography fontWeight={600} variant="body2">
                    {getModelName(content_type)} {object_str}{' '}
                    <Typography
                        component="span"
                        sx={{ color: getActionColor(action_flag) }}
                    >
                        {getActionName(action_flag?.key).toLowerCase()}
                    </Typography>
                </Typography>
            </Grid>
            <Grid item md={2} xs={12} textAlign="center">
                <Typography variant="body2">{action_time}</Typography>
            </Grid>
            <Grid item md={3} textAlign="right" xs={12}>
                <Button
                    color="secondary"
                    onClick={() => handleSelectLog(logData)}
                    startIcon={<VisibilityIcon fontSize="small" />}
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                >
                    {t('View details')}
                </Button>
            </Grid>
        </Grid>
    );
};

LogItem.propTypes = {
    handleSelectLog: PropTypes.func,
    logData: PropTypes.object,
};

export default LogItem;
