import { getRolesNoPaging } from 'common/api/companies';

import {
    FETCH_ROLES,
    FETCH_ROLES_FAILURE,
    FETCH_ROLES_SUCCESS,
} from '../actionTypes';

export default (id) => (dispatch) => {
    dispatch({ type: FETCH_ROLES });

    getRolesNoPaging(id)
        .then((response) => {
            dispatch({
                type: FETCH_ROLES_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_ROLES_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
