import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'common/ui';
import { media } from 'common/utils/mixins';

const SideBarStyled = styled.div`
    justify-content: center;
    overflow-y: auto;
    position: relative;
    transition: background-color 120ms ease-in-out, padding-top 0.6s ease-in-out,
        width 250ms ease-in-out;
    width: 42px;
    z-index: 50;

    &::-webkit-scrollbar {
        width: 0;
    }

    ${media.md` width: 86px; `}
`;

const SideBarInnerContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

const SideBarItemContainer = styled.div`
    width: 100%;
`;

const SideBar = ({ children }) => (
    <Box
        sx={{
            backgroundColor: (theme) => theme.palette.secondary.main,
            color: (theme) => theme.palette.secondary.contrastText,
            height: '100%',
            left: 0,
            position: 'fixed',
            top: 0,
            zIndex: 50,
        }}
    >
        <SideBarStyled>
            <SideBarInnerContainer>
                <SideBarItemContainer>{children}</SideBarItemContainer>
            </SideBarInnerContainer>
        </SideBarStyled>
    </Box>
);

SideBar.propTypes = {
    children: PropTypes.node,
};

export default SideBar;
