import React from 'react';

import PropTypes from 'prop-types';

import { Box, IconButton, InputAdornment, TextField } from 'common/ui';

const ReduxFieldInput = ({
    borderRadius,
    contentEndAdornment,
    contentStartAdornment,
    defaultBehevior = false,
    defaultValue,
    endAdornmentIcon,
    fontSize,
    fontWeight,
    fullWidth = true,
    handleClickButtonEndAdornment,
    handleClickButtonStartAdornment,
    input: { value, onChange, onBlur },
    meta: { touched, error },
    min,
    onlyIntegerNumbers = false,
    onlyNumbers = false,
    placeholder,
    startAdornmentIcon,
    step,
    sxBox = {},
    textAlign,
    type,
    variant = 'filled',
    ...other
}) => {
    const onChangeAction = (event, defaultBehevior, onlyNumbers) => {
        const text = event.target.value;

        if (onlyNumbers) {
            event.target.value = text.replace(/[A-Za-z]/gi, '');
            onChange(event);

            return;
        }

        if (onlyIntegerNumbers) {
            event.target.value = text.replace(/[A-Za-z$,.]/gi, '');
            onChange(event);

            return;
        }

        if (
            !(
                text.length > 1 &&
                text.charAt(0) === '0' &&
                text.charAt(1) === '0' &&
                !defaultBehevior
            )
        ) {
            if (type === 'number' && parseFloat(text) >= 1) {
                event.target.value = text.replace(/^0+/, '');
            }

            onChange(event);
        }
    };

    const onBlurAction = (event, blurValue) => {
        if (type === 'number' && event.target.value === '') {
            event.preventDefault();
            blurValue = min || 0;
            event.target.value = min || 0;
        }

        onBlur(event, blurValue);
    };

    return (
        <Box
            sx={{
                '& .MuiInputBase-input': {
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    textAlign: textAlign,
                },
                '& .MuiTextField-root': {
                    '& fieldset': {
                        borderRadius: borderRadius,
                    },
                },
                ...sxBox,
            }}
        >
            <TextField
                error={Boolean(touched && error)}
                InputProps={{
                    endAdornment: (endAdornmentIcon || contentEndAdornment) && (
                        <InputAdornment position="end">
                            {endAdornmentIcon ? (
                                <IconButton
                                    onClick={handleClickButtonEndAdornment}
                                    edge="end"
                                >
                                    {endAdornmentIcon}
                                </IconButton>
                            ) : (
                                contentEndAdornment
                            )}
                        </InputAdornment>
                    ),
                    startAdornment: (startAdornmentIcon ||
                        contentStartAdornment) && (
                        <InputAdornment position="start">
                            {startAdornmentIcon ? (
                                <IconButton
                                    onClick={handleClickButtonStartAdornment}
                                    edge="end"
                                >
                                    {startAdornmentIcon}
                                </IconButton>
                            ) : (
                                contentStartAdornment
                            )}
                        </InputAdornment>
                    ),
                }}
                label={placeholder}
                defaultValue={defaultValue}
                helperText={touched && error}
                variant={variant}
                onBlur={onBlurAction}
                onFocus={(e) => {
                    e.target.select();
                }}
                onChange={(e) =>
                    onChangeAction(e, defaultBehevior, onlyNumbers)
                }
                placeholder={placeholder}
                step={step}
                type={type}
                value={value}
                fullWidth={fullWidth}
                {...other}
            />
        </Box>
    );
};

ReduxFieldInput.propTypes = {
    borderRadius: PropTypes.string,
    contentEndAdornment: PropTypes.string,
    contentStartAdornment: PropTypes.string,
    defaultBehevior: PropTypes.bool,
    defaultValue: PropTypes.string,
    endAdornmentIcon: PropTypes.node,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    fullWidth: PropTypes.bool,
    handleClickButtonEndAdornment: PropTypes.func,
    handleClickButtonStartAdornment: PropTypes.func,
    input: PropTypes.object,
    meta: PropTypes.object,
    min: PropTypes.string,
    onlyIntegerNumbers: PropTypes.bool,
    onlyNumbers: PropTypes.bool,
    placeholder: PropTypes.string,
    startAdornmentIcon: PropTypes.node,
    step: PropTypes.string,
    sxBox: PropTypes.object,
    textAlign: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
};

export default ReduxFieldInput;
