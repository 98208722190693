import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExtensionIcon from '@mui/icons-material/Extension';
import PropTypes from 'prop-types';

import ReactHookFormSelect from 'common/components/form/bootstrap/ReactHookFormSelect';
import { Box, Tooltip, Typography } from 'common/ui';
import { getTranslatedAddonLabel } from 'common/utils/helpers';

import {
    getPermissionLevelOptions,
    handleOnChangePermission,
} from '../helpers';

import StyledItem from './StyledItem';

const GroupItem = ({
    addons,
    categories,
    control,
    disabled,
    index,
    item,
    name,
    proposerIndex,
    setValue,
}) => {
    const hasAddon = addons.includes(item?.addon_name);
    return (
        <StyledItem className={index % 2 === 0 ? 'StripedRow' : ''}>
            <Typography id={item.id} variant="caption">
                {item?.name}
            </Typography>
            {item?.addon_name && (
                <Tooltip
                    title={
                        <Box alignItems="center" display="flex" gap={1}>
                            {getTranslatedAddonLabel(item?.addon_name)}
                            {hasAddon && (
                                <CheckCircleIcon sx={{ fontSize: '13px' }} />
                            )}
                        </Box>
                    }
                >
                    <ExtensionIcon
                        color="primary"
                        sx={{ cursor: 'pointer', fontSize: 14, ml: 1 }}
                    />
                </Tooltip>
            )}
            <Box ml="auto">
                <ReactHookFormSelect
                    control={control}
                    disabled={disabled || !item?.is_enabled}
                    name={name}
                    onChange={(e) => {
                        handleOnChangePermission({
                            addons,
                            categories,
                            item,
                            proposerIndex,
                            setValue,
                            value: e?.target?.value,
                        });
                    }}
                    options={getPermissionLevelOptions({
                        addonName: item?.addon_name,
                        hasAddon,
                        value: item?.permission_level,
                    })}
                    sxFormControl={{
                        '&.MuiFormControl-root': { mb: 0 },
                        '& .MuiInputBase-root': { mt: 0, fontSize: '14px' },
                    }}
                    variant="standard"
                />
            </Box>
        </StyledItem>
    );
};

GroupItem.propTypes = {
    addons: PropTypes.array,
    categories: PropTypes.array,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    index: PropTypes.number,
    item: PropTypes.object,
    name: PropTypes.string,
    proposerIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setValue: PropTypes.func,
};

export default GroupItem;
