import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DialogContainer } from 'common/components';

import * as actions from './actions';
import { Form } from './components';
import * as selectors from './selectors';

const Container = ({
    filters,
    handleAfterSave,
    handleClickCloseModal,
    handleTemporalDelete,
    handleTemporalSave,
    isNewBrand,
    isNewRecord,
    isOpenModal,
    reset,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    return (
        <DialogContainer
            onClose={handleClickCloseModal}
            open={isOpenModal}
            fullWidth={true}
            title={isNewRecord ? t('Add family') : t('Edit family')}
        >
            <Form
                filters={filters}
                handleAfterSave={handleAfterSave}
                handleTemporalDelete={handleTemporalDelete}
                handleTemporalSave={handleTemporalSave}
                isNewBrand={isNewBrand}
            />
        </DialogContainer>
    );
};

Container.propTypes = {
    filters: PropTypes.object,
    handleAfterSave: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleTemporalDelete: PropTypes.func,
    handleTemporalSave: PropTypes.func,
    isNewBrand: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isNewRecord: selectors.getIsNewRecord,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () => dispatch(actions.toggleModal(false)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
