import API from '..';
const ENTITY = 'parameters';

export const getRates = (
    page = 1,
    perPage = 20,
    orderBy = '',
    sortBy = '',
    str = ''
) =>
    API.get(
        `/api/v1/${ENTITY}/rates/?search${
            str ? '=' + str : ''
        }&page=${page}&per_page=${perPage}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );

export const getRateDetail = (id) => API.get(`/api/v1/${ENTITY}/rates/${id}/`);

export const updateRate = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/rates/${id}/`, data);
