import update from 'immutability-helper';

import {
    ENABLE_ALLIANCE,
    ENABLE_ALLIANCE_FAILURE,
    ENABLE_ALLIANCE_SUCCESS,
    FILTER_ALLIANCES,
    FILTER_ALLIANCES_SUCCESS,
    FILTER_ALLIANCES_FAILURE,
} from './actionTypes';

const INITIAL_STATE = {
    activeAlliance: {
        errors: [],
        isUpdating: false,
    },
    alliances: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    downloadCSV: {
        isFetching: false,
        data: null,
        errors: [],
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ENABLE_ALLIANCE:
            return update(state, {
                activeAlliance: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case ENABLE_ALLIANCE_SUCCESS:
            return update(state, {
                activeAlliance: {
                    $merge: {
                        isSaving: false,
                    },
                },
            });

        case ENABLE_ALLIANCE_FAILURE:
            return update(state, {
                activeAlliance: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case FILTER_ALLIANCES:
            return update(state, {
                alliances: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FILTER_ALLIANCES_FAILURE:
            return update(state, {
                alliances: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FILTER_ALLIANCES_SUCCESS:
            return update(state, {
                alliances: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        default:
            return state;
    }
}
