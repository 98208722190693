export const NAME = 'filter-files-lisa';

export const FILE_STATUS_KEYS = {
    FAILED: -1,
    SUCCESS: 0,
    PARTIALLY_SOLVED: 1,
    UNSOLVED: 2,
};

export const FILE_STATUS = {
    [FILE_STATUS_KEYS.FAILED]: { color: '#FF4136', label: 'Failed' },
    [FILE_STATUS_KEYS.SUCCESS]: { color: '#00FF00', label: 'Success' },
    [FILE_STATUS_KEYS.PARTIALLY_SOLVED]: {
        color: '#FFDC00',
        label: 'Partially solved',
    },
    [FILE_STATUS_KEYS.UNSOLVED]: { color: '#FF851B', label: 'Unsolved' },
};
