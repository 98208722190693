import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteOutstanding from './deleteOutstanding';
import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (item, callback) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        onSuccess: () => dispatch(deleteOutstanding(item.id, callback)),
        title: i18next.t('Remove from outstanding'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Remove').toUpperCase(),
    };

    dispatch(toggleModal(false));
    dispatch(resetForm());
    dispatch(alerts.actions.show(dataAlert));
};
