import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getCompaniesModel = createSelector(
    getModel,
    (model) => model.companies
);

export const getCompaniesData = createSelector(
    getCompaniesModel,
    (model) => model.data || []
);

export const getProposalsModel = createSelector(
    getModel,
    (model) => model.proposals
);

export const getProposalsData = createSelector(
    getProposalsModel,
    (model) => model.data.data || []
);

export const getProposalsPagination = createSelector(
    getProposalsModel,
    (model) => model.data.pagination || {}
);

export const getIsFetchingProposals = createSelector(
    getProposalsModel,
    (model) => model.isFetching
);

export const getProposalsErrors = createSelector(
    getProposalsModel,
    (model) => model.errors
);
