import React, { useEffect } from 'react';

import { useGridApiContext } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import { TextField } from 'common/ui';

const handleSetValue = (field, id, rowModesModel, setRowModesModel, value) => {
    let newObject = {};
    newObject[field] = value;
    setRowModesModel({
        ...rowModesModel,
        [id]: { ...rowModesModel[id], ...newObject },
    });
};

const CustomFieldEdit = (props) => {
    const { id, field, value, row, rowModesModel, setRowModesModel } = props;
    const apiRef = useGridApiContext();

    useEffect(() => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {
                ...rowModesModel[id],
                ...{ code: row.code, name: row.name },
            },
        });
    }, [value]);

    const handleValueChange = (newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
        handleSetValue(field, id, rowModesModel, setRowModesModel, newValue);
    };
    return (
        <TextField
            fullWidth
            onChange={(e) => handleValueChange(e?.target?.value)}
            size="small"
            sx={{
                width: 'calc(100% - 10px)',
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '0 !important',
                },
                '& .MuiInputBase-input': {
                    fontSize: '14px',
                },
            }}
            value={value}
            variant="outlined"
        />
    );
};

CustomFieldEdit.propTypes = {
    field: PropTypes.string,
    id: PropTypes.string,
    row: PropTypes.object,
    value: PropTypes.string,
    rowModesModel: PropTypes.object,
    setRowModesModel: PropTypes.func,
};

export default CustomFieldEdit;
