import API from '..';
const ENTITY = 'companies';

export const filterCompanies = ({
    countryName,
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    planName,
    searchText = '',
    sortBy = null,
    status = 'active',
    subscriptionStatus,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/filter/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            ...(searchText ? { search: searchText } : {}),
            ...(planName ? { plan_name: planName } : {}),
            ...(countryName ? { country_name: countryName } : {}),
            ...(subscriptionStatus
                ? { subscription_status: subscriptionStatus }
                : {}),
            sort: sortBy,
            status_flag: status,
        },
    });

export const filterCompany = (company) =>
    API.get(`/api/v1/${ENTITY}/filter-company/`, { params: company });

export const filterRenewals = ({
    endDate,
    orderBy,
    page = 1,
    perPage = 25,
    queryRange,
    searchText = '',
    sortBy,
    startDate,
    status = 'active',
    usabilityOperator,
    usabilityValue,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/renewals/`, {
        params: {
            ...(endDate ? { ends: endDate } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(startDate ? { starts: startDate } : {}),
            ...(usabilityOperator
                ? { usability_operator: usabilityOperator }
                : {}),
            ...(usabilityValue ? { usability_value: usabilityValue } : {}),
            order_by: orderBy,
            page: page,
            per_page: perPage,
            query_range: `${queryRange} days`,
            sort: sortBy,
            status,
        },
    });

export const getAddonsCSVReport = () =>
    API.get(`/api/v1/${ENTITY}/addons/csv/`);

/**Companies home */

export const getCompanies = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/filter/${str ? '?search=' + str : ''}${
            str ? '&page=' : '?page='
        }${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const exportFile = ({
    countryName,
    planName,
    searchText = '',
    subscriptionStatus,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/download/`, {
        params: {
            ...(searchText ? { search: searchText } : {}),
            ...(planName ? { plan_name: planName } : {}),
            ...(countryName ? { country_name: countryName } : {}),
            ...(subscriptionStatus
                ? { subscription_status: subscriptionStatus }
                : {}),
        },
    });

export const getCountries = () => API.get(`/api/v1/${ENTITY}/origins/`);

export const getSubscriptionPlans = () =>
    API.get(`/api/v1/${ENTITY}/subscriptions/plans/`);

/** Company detail */

export const getCompanyDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/${id}/detail/`);

export const getSyncCompanySubscription = (id) =>
    API.get(`/api/v1/${ENTITY}/chargebee/sync-refresh/?company_id=${id}`);

export const getCompanyBranchOffices = (
    id,
    page = 1,
    orderBy = '',
    sortBy = ''
) =>
    API.get(
        `/api/v1/${ENTITY}/${id}/branch-offices/?page=${page}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );

export const getCompanyBranchOfficesNoPaging = (id) =>
    API.get(`/api/v1/${ENTITY}/${id}/branch-offices-nopage/`);

export const updateCompanyOwner = (data, id) =>
    API.put(`/api/v1/${ENTITY}/owner/${id}/`, { ...data });

export const getAgents = (id) => API.get(`/api/v1/${ENTITY}/${id}/agents/`);

/** Company users */

export const getUsers = (id, page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/${id}/users/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getUsersCSV = (id, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/${id}/users/download/?search${str ? '=' + str : ''}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );

export const getUserDetail = (companyId, userId) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/users/${userId}/detail/`);

export const updateUser = (companyId, userId, data) =>
    API.put(`/api/v1/${ENTITY}/${companyId}/users/${userId}/detail/`, {
        ...data,
    });

export const deleteUser = (companyId, userId) =>
    API.delete(`/api/v1/${ENTITY}/${companyId}/users/${userId}/detail/`);

/** Company roles */

export const getRoles = (id, page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/${id}/roles/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getRolesNoPaging = (id) =>
    API.get(`/api/v1/${ENTITY}/${id}/roles-nopage/`);

export const getRoleDetail = (companyId, roleId) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/roles/${roleId}/detail/`);

export const fetchCategoriesSettings = () =>
    API.get(`/api/v1/${ENTITY}/categories-settings/`);

export const updateRole = (companyId, roleId, data) =>
    API.put(`/api/v1/${ENTITY}/${companyId}/roles/${roleId}/detail/`, {
        ...data,
    });

/** Company plans */

export const getPlans = (id, page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/${id}/plans/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getPlanDetail = (companyId, planId) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/plans/${planId}/detail/`);

export const getPlanHistory = (companyId, planId) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/plans/${planId}/history/`);

export const updatePlan = (companyId, planId, data) =>
    API.put(`/api/v1/${ENTITY}/${companyId}/plans/${planId}/detail/`, {
        ...data,
    });

export const getPanelsCompany = (companyId, planId) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/plans/${planId}/panels/`);

export const getInvertersCompany = (companyId, planId) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/plans/${planId}/inverter-brands/`);

/** Company alliances */

export const getAlliances = (
    id,
    page = 1,
    orderBy = '',
    sortBy = '',
    str = ''
) =>
    API.get(
        `/api/v1/${ENTITY}/${id}/alliances/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getAllianceProducts = (
    companyId,
    allianceId,
    page = 1,
    orderBy = '',
    sortBy = '',
    str = ''
) =>
    API.get(
        `/api/v1/${ENTITY}/${companyId}/alliances/${allianceId}/products/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getProjectsCounters = (
    companyId,
    initialDate,
    finalDate,
    agentId = null
) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/projects-totals/`, {
        params: {
            agent_id: agentId,
            final_date: finalDate,
            initial_date: initialDate,
        },
    });
export const getProposalsTotals = (
    companyId,
    initialDate,
    finalDate,
    agentId = null
) =>
    API.get(`/api/v1/${ENTITY}/${companyId}/proposals-totals/`, {
        params: {
            agent_id: agentId,
            final_date: finalDate,
            initial_date: initialDate,
        },
    });
