import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { ReduxFieldInput } from 'common/components/form/redux';
import { Box, Button, Stack } from 'common/ui';

import { NAME } from '../constants';
import validate from '../validate';

const DuplicateModelForm = ({
    disabled,
    handleCloseModalForm,
    handleSubmit,
    onSubmit,
}) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <Field
                    component={ReduxFieldInput}
                    disabled={disabled}
                    name="name"
                    placeholder={t('Name')}
                    required
                    variant="outlined"
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { sm: 'row', xs: 'column' },
                        gap: 2,
                        justifyContent: { sm: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        disabled={disabled}
                        fullWidth={false}
                        height="48px"
                        onClick={handleCloseModalForm}
                        size="large"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={disabled}
                        fullWidth={false}
                        height="48px"
                        size="large"
                        type="submit"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </Stack>
        </form>
    );
};

DuplicateModelForm.propTypes = {
    disabled: PropTypes.bool,
    handleCloseModalForm: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default reduxForm({
    enableReinitialize: true,
    form: `${NAME}/duplicate-form`,
    validate,
})(DuplicateModelForm);
