import i18next from 'i18next';

import { updateTrainingData } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import {
    SAVE_TRAINING_DATA_TAGS,
    SAVE_TRAINING_DATA_TAGS_FAILURE,
    SAVE_TRAINING_DATA_TAGS_SUCCESS,
} from '../actionTypes';

import resetTagsForm from './resetTagsForm';
import setIsOpenTagsForm from './setIsOpenTagsForm';

export default ({ id, modelId, tags }) =>
    (dispatch) => {
        dispatch({ type: SAVE_TRAINING_DATA_TAGS });

        const newValues = { tags: tags?.map((tag) => tag.value) };

        updateTrainingData(id, modelId, newValues)
            .then((response) => {
                dispatch({
                    payload: response?.data,
                    type: SAVE_TRAINING_DATA_TAGS_SUCCESS,
                });
                dispatch(setIsOpenTagsForm(false));
                dispatch(resetTagsForm());
                showToast();
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_TRAINING_DATA_TAGS_FAILURE,
                    payload: error?.response?.data?.detail,
                });

                showToast({
                    autoClose: 3000,
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while updating the training data'
                    ),
                });
            });
    };
