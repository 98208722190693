import i18next from 'i18next';

export default () => ({
    CONFIRM_PASSWORD: i18next.t("The confirm password doesn't match"),
    EMAIL_VALIDATION_TEXT: i18next.t('Please enter a valid email'),
    EMAILS_DONT_MATCH: i18next.t('Email must be the same'),
    MAX_100_VALIDATION_TEXT: i18next.t('Maximum valid value is 100'),
    MAX_100_PERCENTAGE_VALIDATION_TEXT: i18next.t(
        'Maximum percentage discount can not exceed 100%'
    ),
    MIN_ZERO_VALIDATION_TEXT: i18next.t('Minimum valid value is 0'),
    NUMBER_TYPE_TEXT: i18next.t('Must be a number'),
    PASSWORDS_DONT_MATCH: i18next.t('Confirm password does not match'),
    REQUIRED_FILE: i18next.t('The file is required'),
    REQUIRED_TEXT: i18next.t('This field is required'),
    PHONE_VALIDATION_TEXT: i18next.t('Please enter a valid phone'),
    maximumNumberCharacters: (number = '') =>
        i18next.t('Maximum {{number}} characters', { number }),
    minimumNumberCharacters: (number = '') =>
        i18next.t('Minimum {{number}} characters', { number }),
    getMinValueText: (value) =>
        i18next.t('It cannot be less than {{value}}', { value }),
    getMaxValueText: (value) =>
        i18next.t('It cannot be greater than {{value}}', { value }),
});
