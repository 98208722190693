import API from '..';
const ENTITY = 'staff';

export const bulkUsers = (data) =>
    API.put(
        `/api/v1/${ENTITY}/users/bulk-status-flags/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const createUser = (data) =>
    API.post(
        `/api/v1/${ENTITY}/users/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const filterUsers = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/users/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const getUserDetails = (roleId) =>
    API.get(`/api/v1/${ENTITY}/users/${roleId}/`);
export const updateUser = (data, id) =>
    API.patch(
        `/api/v1/${ENTITY}/users/${id}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
