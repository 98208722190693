import React from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import { useTranslation } from 'react-i18next';

import { HeaderContainer, TabsSystem } from 'common/components';
import { Box, Container, Typography } from 'common/ui';

import renewalControl from '../renewalControl';
import reportDownload from '../reportDownload';

import { REPORTS, RENEWAL_CONTROL } from './constants';

const getTabs = (t) => [
    {
        id: RENEWAL_CONTROL,
        label: t('Renewal control'),
        content: <renewalControl.Container />,
    },
    {
        id: REPORTS,
        label: t('Report', { count: 2 }),
        content: <reportDownload.Container />,
    },
];

const ReportsContainer = () => {
    const { t } = useTranslation();

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Box display="flex" alignItems="center" gap={1}>
                    <AssessmentIcon sx={{ color: '#ff9a00' }} />
                    <Typography variant="h4">
                        {t('Report', { count: 2 })}
                    </Typography>
                </Box>
            </HeaderContainer>

            <TabsSystem baseUrl="reports" tabs={getTabs(t)} />
        </Container>
    );
};
ReportsContainer.propTypes = {};

export default ReportsContainer;
