import i18next from 'i18next';

import { updateFont } from 'common/api/templatesFonts';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';

import fetchFonts from './fetchFonts';
import resetForm from './resetForm';
import toggleProductForm from './toggleProductForm';

export default (filterData, values, id) => (dispatch) => {
    if (!id) return;

    dispatch({ type: SAVE });

    updateFont(values, id)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(fetchFonts(filterData));
            dispatch(toggleProductForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while updating the product'),
            });
        });
};
