import update from 'immutability-helper';

import {
    FETCH_PROVIDERS,
    FETCH_PROVIDERS_FAILURE,
    FETCH_PROVIDERS_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    providers: { data: [], errors: null, isLoading: false },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_PROVIDERS:
            return update(state, {
                providers: {
                    $merge: {
                        isLoading: true,
                        errors: null,
                    },
                },
            });

        case FETCH_PROVIDERS_FAILURE:
            return update(state, {
                providers: {
                    $merge: {
                        isLoading: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_PROVIDERS_SUCCESS:
            return update(state, {
                providers: {
                    $merge: {
                        isLoading: false,
                        data: action.payload,
                    },
                },
            });

        default:
            return state;
    }
}
