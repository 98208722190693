import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { Container, UiDialog as Dialog } from 'common/ui';

import * as actions from './actions';
import ListItems from './components/ListItems';
import LogDetails from './components/LogDetails';
import * as selectors from './selectors';

const CompaniesContainer = ({
    companyLogs,
    filterLogs,
    isFetching,
    match,
    pagination,
    reset,
}) => {
    const { t } = useTranslation();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [currentLog, setCurrentLog] = useState({});

    useEffect(() => {
        return () => reset();
    }, []);

    useEffect(() => {
        filterLogs({
            companyId: match.params.id,
            page: 1,
        });
    }, []);

    const handleSelectLog = (log) => {
        setCurrentLog(log);
        setIsOpenModal(true);
    };

    return (
        <Container maxWidth="">
            <ListItems
                companyId={match.params.id}
                companyLogs={companyLogs}
                filterLogs={filterLogs}
                handleSelectLog={handleSelectLog}
                isFetching={isFetching}
                paginationData={pagination}
                selectedLogId={currentLog?.id}
            />
            <Dialog
                onClose={() => {
                    setIsOpenModal(false);
                    setCurrentLog({});
                }}
                open={isOpenModal}
                size="lg"
                title={t('Activity details')}
            >
                <LogDetails metafields={currentLog?.metafields || []} />
            </Dialog>
        </Container>
    );
};

CompaniesContainer.propTypes = {
    companyLogs: PropTypes.array,
    filterLogs: PropTypes.func,
    isFetching: PropTypes.bool,
    match: PropTypes.object,
    pagination: PropTypes.object,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    companyLogs: selectors.getCompanyLogsData,
    isFetching: selectors.getIsFetchingCompanyLogs,
    pagination: selectors.getCompanyLogsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    filterLogs: (filterData) => dispatch(actions.filterLogs(filterData)),
    reset: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompaniesContainer));
