import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import { Box, Typography } from 'common/ui';

const NameFormatter = ({ row }) => (
    <Box display="flex" flexDirection="column">
        <Typography fontWeight="bold" variant="caption">
            {`${row.first_name} ${row.last_name}`}
        </Typography>
        <Typography variant="caption">{get(row, 'email', '@')}</Typography>
    </Box>
);

NameFormatter.propTypes = {
    row: PropTypes.object,
};

export default NameFormatter;
