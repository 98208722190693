import React from 'react';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import CardContainer from 'common/components/CardContainer';
import { ReduxFieldInput, ReduxFieldFile } from 'common/components/form/redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CardMedia,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Typography,
    Tooltip,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from 'common/ui';

import CategoryPlaceholder from 'resources/placeholders/placeholder_category.svg';

import * as actions from '../actions';
import { NAME, ALLOWED_EXTENSIONS } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const StyledThumbnail = styled(CardMedia)(() => ({
    position: 'relative',
    height: 218,
    width: 140,
    backgroundSize: 'contain',
    padding: '0.25rem',
    backgroundColor: '#EEF4FA',
    borderRadius: '0.55rem',
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiThumbnailBackdrop-root': {
            opacity: 1,
        },
        '& .MuiBackdrop-root': {
            opacity: 0.15,
        },
    },
}));

const IconContainer = styled('span')(() => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    zIndex: 2,
}));

const ActionContainer = styled('span')(({ theme }) => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    zIndex: 3,
}));

const Backdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    zIndex: 4,
}));

const StyledIconButton = styled(IconButton)(({ theme, color }) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,

    '&:hover': {
        backgroundColor: theme.palette[color].light,
    },
}));

const StyledImage = styled('img')(() => ({
    objectFit: 'contain',
    width: '1.5rem',
}));

const CategoryForm = ({
    errors,
    formValues,
    handleClickSave,
    handleEditionChange,
    handleSubmit,
    isDeleting,
    isEnabledEdition,
    isFetchingDetail,
    isNewRecord,
    isSaving,
    prepareDelete,
    prepareReturnCatalog,
    setCurrentItem,
}) => {
    const { t } = useTranslation();

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    const handleDelete = (item) => {
        prepareDelete(item);
    };

    return (
        <Stack spacing={3}>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title={t('Back')}>
                    <IconButton
                        onClick={() => {
                            prepareReturnCatalog();
                            setCurrentItem({});
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <FormGroup>
                    <FormControlLabel
                        disabled={isNewRecord || isFetchingDetail}
                        control={
                            <Switch
                                checked={isEnabledEdition}
                                onChange={handleEditionSwitchChange}
                            />
                        }
                        label={t('Edit')}
                        labelPlacement="start"
                    />
                </FormGroup>
            </Stack>

            <Box>
                <form>
                    <Stack spacing={3}>
                        <CardContainer
                            title={t('Category information')}
                            isCollapsable
                            isOpen
                            loading={isFetchingDetail}
                            withDivider
                        >
                            <Grid container spacing={2} pt={3}>
                                <Grid item xs={12} md={9}>
                                    <Grid item container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field
                                                name="name"
                                                component={ReduxFieldInput}
                                                placeholder={t('Name')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    {!isNewRecord && (
                                        <Grid item container pt={3}>
                                            <Accordion
                                                sx={{
                                                    border: '1px solid rgba(145, 158, 171, 0.24)',
                                                    width: '100%',
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    id="panel1a-header"
                                                >
                                                    <Typography variant="subtitle1">
                                                        {t(
                                                            'Assigned templates'
                                                        )}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        padding: 0,
                                                    }}
                                                >
                                                    {!isEmpty(
                                                        formValues.templates
                                                    ) ? (
                                                        <List disablePadding>
                                                            {formValues.templates.map(
                                                                (t) => (
                                                                    <ListItem
                                                                        key={`list-item-${t.id}`}
                                                                        disablePadding
                                                                    >
                                                                        <ListItemButton>
                                                                            <ListItemText
                                                                                primary={
                                                                                    t.title
                                                                                }
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                )
                                                            )}
                                                        </List>
                                                    ) : (
                                                        <Box
                                                            component="div"
                                                            padding={2}
                                                        >
                                                            <Typography color="text.secondary">
                                                                {t(
                                                                    'There are no templates assigned to this category'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}

                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="div"
                                        mt={4}
                                        mb={4}
                                    >
                                        * {t('Required fields')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={3}
                                    justifyContent="center"
                                >
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Box
                                            component="label"
                                            htmlFor={
                                                isEnabledEdition
                                                    ? 'thumbnail_image_01'
                                                    : ''
                                            }
                                            sx={{
                                                cursor: isEnabledEdition
                                                    ? 'pointer'
                                                    : 'normal',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <StyledThumbnail
                                                image={CategoryPlaceholder}
                                                alt="Category thumbnail or placeholder"
                                            >
                                                <>
                                                    <IconContainer>
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            {!isNil(
                                                                formValues.file_url
                                                            ) && (
                                                                <StyledImage
                                                                    src={
                                                                        Array.isArray(
                                                                            formValues?.file_url
                                                                        )
                                                                            ? formValues
                                                                                  ?.file_url[0]
                                                                                  .preview
                                                                            : formValues.file_url
                                                                    }
                                                                    alt=""
                                                                />
                                                            )}
                                                        </Stack>
                                                    </IconContainer>

                                                    {isEnabledEdition && (
                                                        <>
                                                            <ActionContainer className="MuiThumbnailBackdrop-root">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    spacing={2}
                                                                >
                                                                    <StyledIconButton color="primary">
                                                                        <AddPhotoAlternateIcon />
                                                                    </StyledIconButton>
                                                                </Stack>
                                                            </ActionContainer>
                                                            <Backdrop
                                                                className="MuiBackdrop-root"
                                                                htmlFor="thumbnail_image_01"
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            </StyledThumbnail>
                                        </Box>

                                        <Typography
                                            align="center"
                                            variant="body2"
                                            color="text.secondary"
                                            component="div"
                                        >
                                            <strong>{t('Icon')}</strong>
                                        </Typography>

                                        <Typography
                                            align="center"
                                            variant="caption"
                                            color="text.secondary"
                                            component="div"
                                        >
                                            {t('SVG file')} - 2MB máx
                                        </Typography>

                                        <Box sx={{ display: 'none' }}>
                                            <Field
                                                accept={ALLOWED_EXTENSIONS}
                                                id="thumbnail_image_01"
                                                component={ReduxFieldFile}
                                                placeholder=""
                                                name="file_url"
                                                variant="profile"
                                                maxSize={2000000}
                                                onChange={() => {}}
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContainer>

                        <ShowErrors
                            errors={errors}
                            mt={3}
                            severity="error"
                            variant="filled"
                        />

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={3}
                            pt={3}
                            pb={3}
                        >
                            <Grid container mt={2}>
                                <Grid item>
                                    {!isNewRecord && isEnabledEdition && (
                                        <Button
                                            color="secondary"
                                            disabled={
                                                isFetchingDetail ||
                                                isSaving ||
                                                isDeleting
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={() => {
                                                handleDelete(formValues);
                                            }}
                                        >
                                            {t('Delete category')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="flex-end"
                            >
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={isFetchingDetail || isSaving}
                                        fullWidth={false}
                                        height="48px"
                                        size="large"
                                        onClick={() => {
                                            prepareReturnCatalog();
                                            setCurrentItem({});
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </Grid>
                                {isEnabledEdition && (
                                    <Grid item>
                                        <Button
                                            disabled={
                                                isFetchingDetail || isSaving
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={handleSubmit(
                                                handleClickSave
                                            )}
                                            type="submit"
                                        >
                                            {isSaving
                                                ? `${t('Loading')}...`
                                                : isNewRecord
                                                ? t('Create')
                                                : t('Save')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </Stack>
    );
};

CategoryForm.propTypes = {
    errors: PropTypes.array,
    formValues: PropTypes.object,
    handleClickSave: PropTypes.func,
    handleEditionChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isDeleting: PropTypes.bool,
    isEnabledEdition: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    prepareDelete: PropTypes.func,
    prepareReturnCatalog: PropTypes.func,
    setCurrentItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isDeleting: selectors.getIsDeletingItem,
    isEnabledEdition: selectors.getEnabledEdition,
    isFetchingDetail: selectors.getIsFetchingCategoryDetail,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    prepareDelete: (item) => dispatch(actions.auditPrepareDelete(item)),
    prepareReturnCatalog: () => dispatch(actions.returnCatalog()),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(CategoryForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
