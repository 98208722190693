import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs, TabPanel } from 'common/ui';

import { ANNUAL_TYPE } from '../../constants';

import MainDataTab from './MainDataTab';
import PolicyDataTab from './PolicyDataTab';
import SettingsDataTab from './SettingsDataTab';

const RateFormFields = ({
    control,
    getValues,
    setValue,
    showMode,
    tabKey = '',
    trigger,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(`${tabKey}-main_data`);
    const typeInputCharges = useWatch({
        control,
        name: 'main_data.type_of_input_charges',
    });

    if (!typeInputCharges) return null;
    const isAnnualType = typeInputCharges === ANNUAL_TYPE;

    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);
    const changeTab = (key) => setSelectedTab(`${tabKey}-${key}`);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleOnChangeTab}
                    value={selectedTab}
                    variant="scrollable"
                >
                    <Tab
                        id={'tab-general'}
                        key={'tab-general'}
                        label={t('General')}
                        value={`${tabKey}-main_data`}
                    />
                    <Tab
                        id={'tab-settings'}
                        key={'tab-settings'}
                        label={t('Setting')}
                        value={`${tabKey}-settings_data`}
                    />
                    <Tab
                        id={'tab-policies'}
                        key={'tab-policies'}
                        label={t('Policy', { count: 2 })}
                        value={`${tabKey}-policy_data`}
                    />
                </Tabs>
            </Box>

            <TabPanel
                key={'tab-panel-general'}
                selectedTab={selectedTab}
                value={`${tabKey}-main_data`}
            >
                <MainDataTab
                    changeTab={changeTab}
                    control={control}
                    getValues={getValues}
                    isAnnualType={isAnnualType}
                    setValue={setValue}
                    showMode={showMode}
                />
            </TabPanel>

            <TabPanel
                key={'tab-panel-settings'}
                selectedTab={selectedTab}
                value={`${tabKey}-settings_data`}
            >
                <SettingsDataTab
                    changeTab={changeTab}
                    control={control}
                    getValues={getValues}
                    isAnnualType={isAnnualType}
                    setValue={setValue}
                    showMode={showMode}
                    trigger={trigger}
                />
            </TabPanel>

            <TabPanel
                key={'tab-panel-policies'}
                selectedTab={selectedTab}
                value={`${tabKey}-policy_data`}
            >
                <PolicyDataTab
                    control={control}
                    showMode={showMode}
                    trigger={trigger}
                />
            </TabPanel>
        </>
    );
};

RateFormFields.propTypes = {
    control: PropTypes.object,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    tabKey: PropTypes.string,
    trigger: PropTypes.func,
};

export default RateFormFields;
