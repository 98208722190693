import React from 'react';

import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Switch,
} from '@mui/material';
import PropTypes from 'prop-types';

const ReduxFieldSwitch = ({
    disabled,
    handleClick,
    id,
    input: { value, onChange },
    label,
    meta: { touched, error },
    placeholder,
    visible = true,
    ...other
}) => {
    const onChangeAction = (event) => onChange(event);
    return visible ? (
        <FormControl
            component="fieldset"
            variant="standard"
            error={Boolean(touched && error)}
        >
            <FormLabel component="legend">{placeholder}</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={value ? true : false}
                            className="custom-control-input"
                            id={id}
                            onChange={onChangeAction}
                            onClick={handleClick}
                            disabled={disabled}
                            {...other}
                        />
                    }
                    label={label ? label : value ? 'On' : 'Off'}
                    labelPlacement="start"
                />
            </FormGroup>
            <FormHelperText id="redux-field-switch-helper-text">
                {touched && error}
            </FormHelperText>
        </FormControl>
    ) : null;
};

ReduxFieldSwitch.propTypes = {
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    visible: PropTypes.bool,
};

export default ReduxFieldSwitch;
