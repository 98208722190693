import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const StyledDialog = ({
    children,
    disableEscapeKeyDown = false,
    footerComponent: FooterComponent = null,
    onClose = () => {},
    onEnter = () => {},
    onExited = () => {},
    open = false,
    scroll = 'body',
    size = 'lg',
    title,
}) => (
    <BootstrapDialog
        disableEscapeKeyDown={disableEscapeKeyDown}
        fullWidth={true}
        maxWidth={size}
        onClose={onClose}
        open={open === null ? false : open}
        scroll={scroll}
        TransitionProps={{ onEnter, onExited }}
    >
        <BootstrapDialogTitle onClose={onClose} sx={{ minHeight: '60px' }}>
            {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Box>{children}</Box>
        </DialogContent>
        {FooterComponent && (
            <DialogActions>
                <FooterComponent />
            </DialogActions>
        )}
    </BootstrapDialog>
);

StyledDialog.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    disableEscapeKeyDown: PropTypes.bool,
    footerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    onClose: PropTypes.func,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    open: PropTypes.bool,
    scroll: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default StyledDialog;
