import i18next from 'i18next';
import isNil from 'lodash/isNil';

import { ALLOWED_EXTENSIONS } from './constants';

export const getDatasheetName = (datasheet) => {
    if (!isNil(datasheet)) {
        const path = datasheet.split('?');
        const url = path.length > 0 ? path[0] : '';
        return url.substr(url.lastIndexOf('/') + 1);
    }

    return '';
};

export const getIsAllowedFile = (datasheet) => {
    const extensions = ALLOWED_EXTENSIONS.split(',');
    return new RegExp(
        '(' + extensions.join('|').replace(/\./g, '\\.') + ')$'
    ).test(datasheet.toLowerCase());
};

export const getLastUpdatedText = (datasheet) => {
    if (datasheet.created_at !== datasheet.updated_at)
        return `${i18next.t('Updated at')} ${datasheet.updated_at}`;
    return `${i18next.t('Added at')} ${datasheet.created_at}`;
};
