import * as actions from './actions';
import * as constants from './constants';
import { NAME } from './constants';
import Container from './Container';
import reducer from './reducer';

export default {
    actions,
    constants,
    Container,
    NAME,
    reducer,
};
