import { object, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(({ REQUIRED_TEXT }) =>
    object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        url: string().required(REQUIRED_TEXT).nullable(),
    })
);
