export const getNameFromPath = (path) => {
    if (!path || typeof path !== 'string') return 'N/A';
    return path.split('/').pop();
};

import { parseISODate, formatDate } from 'common/utils/dates';

export const formatDateFromISO = (date, format = 'dd/MM/yyyy HH:mm') => {
    if (!date) return 'N/A';
    const parsedDate = parseISODate(date);
    return formatDate(parsedDate, format);
};
