import API from '..';
const ENTITY = 'templates';

export const getOutstandingDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/outstanding/${id}/`);

export const createOutstanding = (data) =>
    API.post(`/api/v1/${ENTITY}/outstanding/`, data);

export const updateOutstanding = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/outstanding/${id}/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const deleteOutstanding = (id) =>
    API.delete(`/api/v1/${ENTITY}/outstanding/${id}/`);
