import i18next from 'i18next';

import { getActiveCiBancoAlliancesReport } from 'common/api/v1/alliances';
import showToast from 'common/utils/showToast';

import {
    ACTIVE_CIBANCO_ALLIANCES_REPORT,
    ACTIVE_CIBANCO_ALLIANCES_REPORT_FAILURE,
    ACTIVE_CIBANCO_ALLIANCES_REPORT_SUCCESS,
} from '../actionTypes';

import downloadFile from './downloadFile';

export default (fileName, financierId = null) =>
    (dispatch) => {
        dispatch({ type: ACTIVE_CIBANCO_ALLIANCES_REPORT });

        getActiveCiBancoAlliancesReport(financierId)
            .then((response) => {
                dispatch({
                    type: ACTIVE_CIBANCO_ALLIANCES_REPORT_SUCCESS,
                    payload: response.data,
                });
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                dispatch(downloadFile(fileName, url, 'csv'));
                showToast({ body: i18next.t('It was created successfully') });
            })
            .catch((error) => {
                dispatch({
                    type: ACTIVE_CIBANCO_ALLIANCES_REPORT_FAILURE,
                    payload: error,
                });
            });
    };
