import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { isValidAccess } from 'common/utils/helpers/permissionsV2';

const LayoutRoute = ({
    component: Component,
    isHidden = false,
    isPublic = false,
    layout: Layout,
    redirect = { pathname: '/login' },
    requiredPermissions = [],
    ...rest
}) => {
    if (isHidden) return <Redirect to={redirect} />;
    if (!isValidAccess(isPublic, requiredPermissions))
        return <Redirect to={redirect} />;

    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <Layout {...rest}>
                    <Component {...matchProps} />
                </Layout>
            )}
        />
    );
};

LayoutRoute.propTypes = {
    component: PropTypes.elementType,
    isGuest: PropTypes.bool,
    isHidden: PropTypes.bool,
    isPublic: PropTypes.bool,
    layout: PropTypes.elementType,
    redirect: PropTypes.object,
    requiredPermissions: PropTypes.array,
    rest: PropTypes.object,
};

export default LayoutRoute;
