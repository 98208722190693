import isEmpty from 'lodash/isEmpty';
import { change } from 'redux-form';

import { changePasswordProfile } from 'common/api/users';
import alerts from 'common/modules/alerts';
import { logoutActions } from 'common/utils/helpers/session';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { NAME } from '../constants';

export default (values) => (dispatch) => {
    const newValues = {
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_new_password: values.confirm_password,
    };

    dispatch({ type: SAVE });

    changePasswordProfile(newValues)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });

            dispatch(change(`${NAME}/form`, 'old_password', null));
            dispatch(change(`${NAME}/form`, 'new_password', null));
            dispatch(change(`${NAME}/form`, 'confirm_password', null));

            const messages = [
                'La contraseña ha sido actualizada. Por seguridad se terminará la sesión actual.',
            ];

            if (!isEmpty(messages)) {
                const dataAlert = {
                    title: 'Información',
                    messages: messages,
                    textConfirm: 'Continuar',
                    type: alerts.ALERT_TYPE_ONLY_CONFIRM,
                    variant: 'success',
                    onSuccess: () => {
                        dispatch(alerts.actions.close());
                        logoutActions();
                    },
                };

                dispatch(alerts.actions.show(dataAlert));

                return;
            }
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
