import update from 'immutability-helper';

import {
    INITIALIZE_FORM,
    RESET,
    FETCH_PRODUCT_THUMBNAIL,
    FETCH_PRODUCT_THUMBNAIL_SUCCESS,
    FETCH_PRODUCT_THUMBNAIL_FAILURE,
    UPLOAD_THUMBNAIL,
    UPLOAD_THUMBNAIL_SUCCESS,
    UPLOAD_THUMBNAIL_FAILURE,
    DELETE_THUMBNAIL,
    DELETE_THUMBNAIL_SUCCESS,
    DELETE_THUMBNAIL_FAILURE,
} from './actionTypes';

const INITIAL_STATE = {
    uploadThumbnail: {
        isLoading: false,
        errors: null,
    },
    productThumbnail: {
        isLoading: false,
        data: null,
        error: null,
    },
    deleteThumbnail: {
        isLoading: false,
        error: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case FETCH_PRODUCT_THUMBNAIL:
            return update(state, {
                productThumbnail: { isLoading: { $set: true } },
            });

        case FETCH_PRODUCT_THUMBNAIL_SUCCESS:
            return update(state, {
                productThumbnail: {
                    $merge: {
                        isLoading: false,
                        data: action.payload,
                        error: null,
                    },
                },
            });

        case FETCH_PRODUCT_THUMBNAIL_FAILURE:
            return update(state, {
                productThumbnail: {
                    $merge: { isLoading: false, error: action.payload },
                },
            });

        case INITIALIZE_FORM:
            return update(state, { initialValues: { $merge: action.payload } });

        case UPLOAD_THUMBNAIL:
            return update(state, {
                uploadThumbnail: { isLoading: { $set: true } },
            });

        case UPLOAD_THUMBNAIL_SUCCESS:
            return update(state, {
                uploadThumbnail: { isLoading: { $set: false } },
            });

        case UPLOAD_THUMBNAIL_FAILURE:
            return update(state, {
                uploadThumbnail: {
                    $merge: {
                        isLoading: false,
                        errors: action.payload,
                    },
                },
            });
        case DELETE_THUMBNAIL:
            return update(state, {
                deleteThumbnail: { isLoading: { $set: true } },
            });

        case DELETE_THUMBNAIL_SUCCESS:
            return update(state, {
                deleteThumbnail: { isLoading: { $set: false } },
            });

        case DELETE_THUMBNAIL_FAILURE:
            return update(state, {
                deleteThumbnail: {
                    $merge: {
                        isLoading: false,
                        error: action.payload,
                    },
                },
            });

        default:
            return state;
    }
}
