import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { Box, UiDialog as Dialog } from 'common/ui';

import * as companyGeneralActions from '../companyGeneral/actions';

import * as actions from './actions';
import { ListItems, RoleForm } from './components';
import * as selectors from './selectors';

const Container = ({
    fetchCompanyDetail,
    fetchItems,
    handleClickCloseModal,
    handleClickDelete,
    handlePrepareUpdate,
    isFetching,
    isNewRecord,
    isOpenModal,
    items,
    match,
    paginationItems,
}) => {
    const [filterData, setFilterData] = useState({
        company: match.params.id,
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        sortBy: 'desc',
    });

    useEffect(() => {
        fetchCompanyDetail(match.params.id);
        fetchItems(filterData);
    }, []);

    return (
        <Box px={2}>
            <ListItems
                filterData={filterData}
                filterItems={fetchItems}
                handleClickDelete={handleClickDelete}
                handleClickUpdate={handlePrepareUpdate}
                isFetching={isFetching}
                items={items}
                paginationData={paginationItems}
                setFilterData={setFilterData}
            />
            <Dialog
                onClose={() => handleClickCloseModal()}
                open={isOpenModal}
                size="lg"
                title={`${isNewRecord ? 'Agregar' : 'Actualizar'} rol`}
            >
                <RoleForm companyId={match.params.id} filterData={filterData} />
            </Dialog>
        </Box>
    );
};

Container.propTypes = {
    fetchCompanyDetail: PropTypes.func,
    fetchItems: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handlePrepareUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    items: PropTypes.array,
    match: PropTypes.object,
    paginationItems: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingItems,
    isNewRecord: selectors.getIsNewRecord,
    isOpenModal: selectors.getisOpenModal,
    items: selectors.getFetchItemsData,
    paginationItems: selectors.getFetchItemsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanyDetail: (id) =>
        dispatch(companyGeneralActions.fetchCompanyDetail(id)),
    fetchItems: (filterData) => dispatch(actions.fetchItems(filterData)),
    handleClickCloseModal: () => dispatch(actions.openModal(false)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickDelete: (filterData, item) =>
        dispatch(actions.prepareDelete(filterData, item)),
    handlePrepareUpdate: (companyId, roleId) =>
        dispatch(actions.fetchRoleDetails(companyId, roleId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Container));
