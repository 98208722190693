import i18next from 'i18next';

import { formatDate, subDate } from 'common/utils/dates';

export const getTodayDate = (format = 'dd/MM/yyyy') =>
    formatDate(new Date(), format);
export const getPastDate = (value, format = 'dd/MM/yyyy') =>
    formatDate(
        subDate(new Date(), {
            days: value,
        }),
        format
    );
export const getUsabilityOptions = () => [
    {
        color: '#f44336',
        label: `${i18next.t('Numbers in Red')} <= - 25%`,
        operator: 'inequality_less',
        value: -25,
    },
    {
        color: '#ff9800',
        label: `${i18next.t('Numbers in Yellow/Orange')} <= 49%`,
        operator: 'range',
        value: '-24,49',
    },
    {
        color: '#4caf50',
        label: `${i18next.t('Numbers in Green')} >= 50%`,
        operator: 'inequality_greater',
        value: 50,
    },
];

export const initializeQueryDates = (
    filterData,
    setFilterData,
    setValue,
    value
) => {
    setFilterData({
        ...filterData,
        endDate: getTodayDate('yyyy-MM-dd'),
        queryRange: value,
        startDate: getPastDate(value, 'yyyy-MM-dd'),
    });
    if (setValue) {
        setValue('start_date', getPastDate(value));
        setValue('final_date', getTodayDate());
    }
};
