import React from 'react';

import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';
import { Typography } from 'common/ui';

import EmailFormatter from './EmailFormatter';
import NameFormatter from './NameFormatter';
import SubscriptionStatusFormatter from './SubscriptionStatusFormatter';

const ProductColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const nameColumn = [
        {
            align: 'left',
            field: 'business_name',
            headerName: t('Company', { count: 1 }),
            renderCell: (params) => {
                const { row } = params;
                return <NameFormatter row={row} />;
            },
            width: 200,
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'owner_fullname',
            headerName: t('Owner'),
            width: 200,
        },
        {
            align: 'left',
            field: 'owner_email',
            headerName: t('Email'),
            renderCell: (params) => {
                const { row } = params;
                return <EmailFormatter email={row?.owner_email} />;
            },
            width: 200,
        },
        {
            align: 'left',
            field: 'plan_name',
            flex: 1,
            headerName: t('Plan', { count: 1 }),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {`${row?.plan_name} ${row?.billing}`}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'active_users',
            flex: 1,
            headerName: t('Active users'),
        },
        {
            align: 'left',
            field: 'country',
            flex: 1,
            headerName: t('Country'),
        },
        {
            align: 'left',
            field: 'position_city',
            flex: 1,
            headerName: t('Political division'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.position_city || '- - -'}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'created_at',
            headerName: t('Creation date'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">{row.created_at}</Typography>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'subscription_status',
            flex: 1,
            headerName: t('Subscription'),
            renderCell: (params) => {
                const { row } = params;
                return <SubscriptionStatusFormatter row={row} />;
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    return columns;
};

export default ProductColumns;
