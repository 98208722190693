import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getProductDatasheet = createSelector(
    getModel,
    (model) => model.productDatasheet
);

export const getIsLoadingProductDatasheet = createSelector(
    getProductDatasheet,
    (productDatasheet) => productDatasheet.isLoading
);

export const getDataProductDatasheet = createSelector(
    getProductDatasheet,
    (productDatasheets) => productDatasheets.data || {}
);

export const getUploadDatasheet = createSelector(
    getModel,
    (model) => model.uploadDatasheet
);

export const getIsUploadingDatasheet = createSelector(
    getUploadDatasheet,
    (uploadDatasheet) => uploadDatasheet.isLoading
);

export const getUploadDatasheetErrors = createSelector(
    getUploadDatasheet,
    (uploadDatasheet) => uploadDatasheet.errors
);

export const getIsVisibleForm = createSelector(
    getModel,
    (model) => model.isVisibleForm
);
