import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { DialogContainer } from 'common/components';
import { Box, Button } from 'common/ui';

import ReduxSelectTag from '../../modelTrainingData/components/ReduxSelectTag';
import { NAME } from '../constants';

const EditTagsModal = ({
    handleCloseModal,
    handleSubmit,
    isOpenModal,
    onSubmit,
    tagsOptions,
}) => {
    const { t } = useTranslation();

    return (
        <DialogContainer
            maxWidth="md"
            fullWidth
            onClose={handleCloseModal}
            open={isOpenModal}
            title={t('Tags')}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                    component={ReduxSelectTag}
                    multiple
                    name="tags"
                    options={tagsOptions}
                    variant="outlined"
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { sm: 'row', xs: 'column' },
                        gap: 2,
                        justifyContent: { sm: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        fullWidth={false}
                        height="48px"
                        onClick={handleCloseModal}
                        size="large"
                        sx={{ textTransform: 'none' }}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        fullWidth={false}
                        height="48px"
                        size="large"
                        type="submit"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </form>
        </DialogContainer>
    );
};

EditTagsModal.propTypes = {
    handleCloseModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    isOpenModal: PropTypes.bool,
    onSubmit: PropTypes.func,
    tagsOptions: PropTypes.array,
};

export default reduxForm({
    enableReinitialize: true,
    form: `${NAME}/tags-form`,
})(EditTagsModal);
