import i18next from 'i18next';

import { createModel, updateModel } from 'common/api/ocr/fineTuning';
import alerts from 'common/modules/alerts';

import {
    SAVE_MODEL,
    SAVE_MODEL_FAILURE,
    SAVE_MODEL_SUCCESS,
} from '../actionTypes';

import fetchModels from './fetchModels';
import setIsOpenModelForm from './setIsOpenModelForm';

export default (values, isNewModel) => (dispatch) => {
    dispatch({ type: SAVE_MODEL });

    const newValues = {
        context: values?.context || '',
        description: values?.description || '',
        main_model: values?.main_model || false,
        max_tokens: values?.max_tokens || 0,
        model: values.base_model,
        name: values.name,
        prompt: values?.prompt || '',
        temperature: values?.temperature || 0,
    };

    const save = () => {
        if (!isNewModel && values.id) return updateModel(values.id, newValues);

        return createModel(newValues);
    };

    save()
        .then((response) => {
            dispatch({
                payload: response?.data,
                type: SAVE_MODEL_SUCCESS,
            });
            dispatch(setIsOpenModelForm(false));
            dispatch(fetchModels());
        })
        .catch((error) => {
            dispatch({
                payload: error?.response?.data?.detail,
                type: SAVE_MODEL_FAILURE,
            });

            const dataAlert = {
                confirmText: i18next.t('Close'),
                messages: [`${i18next.t('Whoops, something went wrong')}:`],
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'error',
            };

            dispatch(alerts.actions.show(dataAlert));
        });
};
