import React, { useContext } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MenuPopover } from 'common/components';
import {
    Avatar,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Typography,
} from 'common/ui';
import { LoadingContext } from 'common/utils/contexts';

import { ReactComponent as CertifiedDatasheetIcon } from 'resources/icoCertifiedRate.svg';

import { getLastUpdatedText, getDatasheetName } from '../helpers';

const StyledCertifiedDatasheetIcon = styled(CertifiedDatasheetIcon)`
    width: 28;
    height: 40px;
    min-width: 28px;
    min-height: 40px;
    path {
        fill: #ff8f02;
        stroke: transparent;
        filter: contrast(0.8);
    }
    path:first-of-type {
        stroke: #ff8f02;
    }
`;

const StyledMenuItemIcon = styled(Box)({
    height: '24px',
    margin: '6px 12px 6px 0',
    width: '24px',
});

const ProductDatasheet = ({
    datasheet,
    handleClickDownload,
    isEnabledEdition,
    prepareDelete,
    productId,
    showForm,
}) => {
    const { t } = useTranslation();

    const loadingContext = useContext(LoadingContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    if (
        isEmpty(datasheet) ||
        (!isEmpty(datasheet) && isNil(datasheet.data_sheet))
    ) {
        return (
            <Box>
                <Typography align="center" variant="body2">
                    {t('A technical sheet has not been added to the product')}
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            {!isEmpty(datasheet) && !isNil(datasheet.data_sheet) && (
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                    }}
                >
                    <ListItem
                        alignItems="flex-start"
                        secondaryAction={
                            <>
                                <IconButton edge="end" onClick={handleClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <MenuPopover
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    sx={{
                                        width: 220,
                                    }}
                                    withArrow={false}
                                >
                                    <MenuItem
                                        disabled={!isEnabledEdition}
                                        onClick={() => showForm(true)}
                                    >
                                        <StyledMenuItemIcon>
                                            <EditIcon />
                                        </StyledMenuItemIcon>

                                        {t('Edit')}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleClickDownload(
                                                getDatasheetName(
                                                    datasheet.data_sheet
                                                ),
                                                (text) =>
                                                    loadingContext.openLoading(
                                                        text
                                                    ),
                                                () => {
                                                    loadingContext.closeLoading();
                                                }
                                            );
                                        }}
                                    >
                                        <StyledMenuItemIcon>
                                            <VisibilityIcon />
                                        </StyledMenuItemIcon>

                                        {t('View')}
                                    </MenuItem>
                                    <MenuItem
                                        disabled={!isEnabledEdition}
                                        onClick={() => {
                                            prepareDelete(productId);
                                        }}
                                    >
                                        <StyledMenuItemIcon>
                                            <DeleteIcon />
                                        </StyledMenuItemIcon>

                                        {t('Delete')}
                                    </MenuItem>
                                </MenuPopover>
                            </>
                        }
                    >
                        <ListItemAvatar>
                            <Avatar
                                sx={{
                                    bgcolor: '#fff',
                                }}
                            >
                                <StyledCertifiedDatasheetIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="subtitle1"
                                        color="text.primary"
                                    >
                                        {datasheet.data_sheet_name}
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={getLastUpdatedText(datasheet)}
                        />
                    </ListItem>
                </List>
            )}
        </Box>
    );
};

ProductDatasheet.propTypes = {
    datasheet: PropTypes.object,
    handleClickDownload: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    prepareDelete: PropTypes.func,
    productId: PropTypes.string,
    showForm: PropTypes.func,
};

export default ProductDatasheet;
