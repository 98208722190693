import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { ShowErrors } from 'common/components';
import { ReduxFieldInput } from 'common/components/form/redux';
import { Box, Button, Stack } from 'common/ui';

import { SECOND_STEP_FORM } from '../constants';
import validate from '../validateSecondStep';

const SecondStepAuthForm = ({
    errorsSession,
    formValues,
    handleResendCode,
    handleSubmit,
    isLoadingSession,
    isResendingCode,
}) => {
    const { t } = useTranslation();

    return (
        <form>
            <Stack spacing={3}>
                <Field
                    component={ReduxFieldInput}
                    fontSize="1.5rem"
                    fontWeight="bold"
                    name="code"
                    placeholder={t('Code')}
                    textAlign="center"
                    type="text"
                    variant="outlined"
                />

                <ShowErrors
                    errors={errorsSession}
                    severity="error"
                    variant="filled"
                />
            </Stack>

            <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                <Button
                    disabled={isLoadingSession || isResendingCode}
                    fullWidth
                    height="48px"
                    onClick={handleSubmit}
                    size="large"
                    type="submit"
                >
                    {isLoadingSession ? `${t('Loading')}...` : t('Log in')}
                </Button>

                <Box sx={{ marginTop: 2 }}>
                    <Button
                        backgroundColor="transparent"
                        color="inherit"
                        disabled={isLoadingSession || isResendingCode}
                        fullWidth
                        height="48px"
                        onClick={() => {
                            handleResendCode(formValues);
                        }}
                        size="large"
                        variant="text"
                    >
                        {isResendingCode
                            ? `${t('Resending code')}...`
                            : t('Resend code')}
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

SecondStepAuthForm.propTypes = {
    errorsSession: PropTypes.array,
    formValues: PropTypes.object,
    handleResendCode: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoadingSession: PropTypes.bool,
    isResendingCode: PropTypes.bool,
};

export default reduxForm({
    form: SECOND_STEP_FORM,
    validate,
})(SecondStepAuthForm);
