import i18next from 'i18next';

import { restartTrainingData } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import {
    RESTART_TRAINING_DATA,
    RESTART_TRAINING_DATA_FAILURE,
    RESTART_TRAINING_DATA_SUCCESS,
} from '../actionTypes';

export default (id, modelId) => (dispatch) => {
    dispatch({ type: RESTART_TRAINING_DATA });

    restartTrainingData(id, modelId)
        .then(() => {
            dispatch({ type: RESTART_TRAINING_DATA_SUCCESS });
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: RESTART_TRAINING_DATA_FAILURE,
                payload: error?.response?.data?.detail,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t(
                    'An error occurred while restarting the training data'
                ),
            });
        });
};
