import React from 'react';

import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Box, CardMedia, Stack } from 'common/ui';

import CategoryPlaceholder from 'resources/placeholders/placeholder_category.svg';

import * as actions from '../actions';

const StyledThumbnail = styled(CardMedia)(({ theme }) => ({
    position: 'relative',
    minHeight: 180,
    minWidth: 110,
    backgroundSize: 'contain',
    padding: '0.25rem',
    borderRadius: '0.55rem',
    [theme.breakpoints.down('xl')]: {
        minHeight: 180,
        minWidth: 110,
    },
    [theme.breakpoints.down('lg')]: {
        minHeight: 109,
        minWidth: 70,
    },
}));

const IconContainer = styled('span')(() => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    zIndex: 2,
}));

const StyledImage = styled('img')(() => ({
    objectFit: 'contain',
    width: '1.5rem',
}));

const CategoriesPreviewTable = ({
    handleClickUpdate,
    handleSortable,
    isFetching,
    items,
    orderdBy,
    pagination,
    setCurrentItem,
    sortedBy,
}) => {
    const { t } = useTranslation();

    const IconFormatter = (cell, row) => (
        <Box
            component="label"
            sx={{
                textAlign: 'center',
            }}
        >
            <StyledThumbnail
                image={CategoryPlaceholder}
                alt="Category thumbnail or placeholder"
            >
                <IconContainer>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        {!isNil(row.file_url) && (
                            <StyledImage
                                src={
                                    Array.isArray(row?.file_url)
                                        ? row?.file_url[0].preview
                                        : row.file_url
                                }
                                alt=""
                            />
                        )}
                    </Stack>
                </IconContainer>
            </StyledThumbnail>
        </Box>
    );

    const columns = [
        {
            dataField: 'icon',
            formatter: IconFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Icon'),
            width: '10%',
        },
        {
            dataField: 'name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Name'),
            width: '10%',
        },
        {
            dataField: 'templates_counter',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Template', { count: 2 }),
            width: '10%',
        },
        {
            dataField: 'created_at',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Creation date'),
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={(item) => {
                setCurrentItem({
                    ...item,
                    name: item.name,
                });
                handleClickUpdate(item);
            }}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

CategoriesPreviewTable.propTypes = {
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    orderdBy: PropTypes.string,
    pagination: PropTypes.object,
    setCurrentItem: PropTypes.func,
    sortedBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
});

export default connect(null, mapDispatchToProps)(CategoriesPreviewTable);
