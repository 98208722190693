import { createUser, updateUser } from 'common/api/staffUsers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_USER,
    SAVE_USER_FAILURE,
    SAVE_USER_SUCCESS,
} from '../actionTypes';
import { ROLE_TYPES } from '../constants';
import { handleCheckedPermissions } from '../helpers';
import { usersActions } from '../reducer';

import filterUsers from './filterUsers';
import openModal from './openModal';
import resetForm from './resetForm';

export default (filterData, values) => (dispatch) => {
    let newValues = {
        company_group: values.company_group,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
    };

    if (values.roleType === ROLE_TYPES.CUSTOM) {
        newValues.permissions = handleCheckedPermissions(values.permissions);
    }

    if (values?.change_password) newValues.password = values.password;

    dispatch(usersActions[SAVE_USER]());

    const save = values.id
        ? () => updateUser(newValues, values.id)
        : () => createUser(newValues);

    save()
        .then(() => {
            dispatch(usersActions[SAVE_USER_SUCCESS]());
            dispatch(filterUsers(filterData));
            dispatch(openModal(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch(
                usersActions[SAVE_USER_FAILURE](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
