import i18next from 'i18next';

import { duplicateProduct } from 'common/api/productsBulk';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE,
    DUPLICATE_SUCCESS,
    DUPLICATE_FAILURE,
} from '../actionTypes';

import fetchInverters from './fetchInverters';

export default (filterData, id) => (dispatch) => {
    dispatch({ type: DUPLICATE });
    duplicateProduct({
        product_type: 'inverters',
        product_id: id,
    })
        .then((response) => {
            dispatch({ type: DUPLICATE_SUCCESS, payload: response.data });
            dispatch(fetchInverters(filterData));
            showToast({
                body: i18next.t('Successfully duplicated'),
            });
        })
        .catch((error) => {
            dispatch({
                type: DUPLICATE_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
