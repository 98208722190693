import React, { useEffect, useState, useRef } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import {
    ReduxFieldDatePicker,
    ReduxFieldInput,
    ReduxFieldSelect,
    ReduxFieldSwitch,
} from 'common/components/form/redux';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    Tooltip,
    Typography,
} from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import BranchOfficeSelect from './BranchOfficeSelect';

const UserForm = ({
    branchOffices,
    changeInput,
    errors,
    fetchRandomPassword,
    formValues,
    handleClickSave,
    handleSubmit,
    initialValues,
    isFetching,
    isFetchingPassword,
    isMultiBranchesCompany,
    isSaving,
    prepareDelete,
    roles,
    rolesDictionary,
}) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [showTemporalPassword, setTemporalPassword] = useState(false);
    const [actionTitle, setActionTitle] = useState('Copy');

    useEffect(() => {
        const selectedRole = rolesDictionary[formValues?.company_group];
        changeInput('role_level', get(selectedRole, 'role_level', null));
    }, [formValues?.company_group]);

    const handleGeneratePassword = () => {
        fetchRandomPassword((tempPass) => {
            changeInput('tmp_password', tempPass);
        });
    };

    const handleDeleteUser = (item) => {
        prepareDelete(item);
    };

    const groupsFiltered = (options) => {
        return options
            ? options.map((group) => {
                  let options = {};
                  options['label'] = group.name;
                  options['optionValue'] = group.id;
                  options['disabled'] = false;
                  options['icon'] = group.is_system_role ? (
                      <SettingsIcon sx={{ fontSize: '16px' }} />
                  ) : null;
                  return options;
              })
            : [];
    };

    const levelsFiltered = groupsFiltered(roles);

    if (isFetching) return null;

    return (
        <form>
            <Stack pt={2}>
                <Field
                    component={ReduxFieldInput}
                    disabled={isFetching}
                    label={t('Name').concat('*')}
                    name="first_name"
                    variant="outlined"
                />
            </Stack>

            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} md={6}>
                    <Field
                        name="last_name"
                        component={ReduxFieldInput}
                        label={t('Last name').concat('*')}
                        variant="outlined"
                        disabled={isFetching}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field
                        name="second_surname"
                        component={ReduxFieldInput}
                        label={t('Second surname')}
                        variant="outlined"
                        disabled={isFetching}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} md={6}>
                    <Field
                        name="birth_date"
                        component={ReduxFieldDatePicker}
                        label={t('Date of birth')}
                        variant="outlined"
                        disabled={isFetching}
                        maxDate={new Date()}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field
                        name="phone"
                        component={ReduxFieldInput}
                        label={t('Phone')}
                        variant="outlined"
                        disabled={isFetching}
                    />
                </Grid>
            </Grid>

            <Stack spacing={2} pt={2}>
                <Field
                    name="position"
                    component={ReduxFieldInput}
                    label={t('Position')}
                    variant="outlined"
                    disabled={isFetching}
                />

                <Field
                    name="email"
                    component={ReduxFieldInput}
                    label={t('Email').concat('*')}
                    type="email"
                    variant="outlined"
                    disabled={isFetching}
                />
            </Stack>

            <Grid container spacing={2} pt={2}>
                <Grid item xs={12}>
                    <Field
                        name="company_group"
                        component={ReduxFieldSelect}
                        disabled={isFetching}
                        label={t('Role').concat('*')}
                        variant="outlined"
                        options={levelsFiltered}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} pt={2}>
                <Grid item xs={12}>
                    <BranchOfficeSelect
                        branchOffices={branchOffices}
                        isMultiBranchesCompany={isMultiBranchesCompany}
                        roleLevel={formValues?.role_level}
                    />
                </Grid>
            </Grid>

            <Stack spacing={2} pt={2}>
                <Field
                    name="is_enabled"
                    component={ReduxFieldSwitch}
                    label={t('Status')}
                    disabled={isFetching}
                />

                <Field
                    name="has_tmp_password"
                    component={ReduxFieldSwitch}
                    label={t('Temporal password')}
                    disabled={isFetching || !formValues.allow_staff_support}
                    onChange={(event) => {
                        setTemporalPassword(event.target.checked);
                        if (event.target.checked) {
                            handleGeneratePassword();
                        } else changeInput('tmp_password', '');
                    }}
                />

                {showTemporalPassword && (
                    <>
                        {isFetchingPassword ? (
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Typography>
                                    {t('Generating password')}...
                                </Typography>
                                <CircularProgress size={25} />
                            </Stack>
                        ) : (
                            <Field
                                inputRef={inputRef}
                                name="tmp_password"
                                component={ReduxFieldInput}
                                label={t('Password').concat('*')}
                                variant="outlined"
                                endAdornmentIcon={
                                    <Tooltip
                                        title={t(actionTitle)}
                                        placement="top"
                                    >
                                        <FileCopyIcon />
                                    </Tooltip>
                                }
                                handleClickButtonEndAdornment={() => {
                                    inputRef.current.select();
                                    document.execCommand('copy');
                                    setActionTitle('Copied!');
                                    setTimeout(() => {
                                        setActionTitle('Copy');
                                    }, 1000);
                                }}
                            />
                        )}
                    </>
                )}
            </Stack>

            <ShowErrors
                errors={errors}
                mt={3}
                severity="error"
                variant="filled"
            />

            <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
                <Grid item>
                    <Tooltip
                        title={
                            initialValues.is_enabled
                                ? t('User must be disabled')
                                : ''
                        }
                        placement="top"
                    >
                        <Box>
                            <Button
                                color="secondary"
                                disabled={
                                    isFetching ||
                                    isSaving ||
                                    initialValues.is_enabled
                                }
                                fullWidth={false}
                                height="48px"
                                size="large"
                                onClick={() => {
                                    handleDeleteUser(formValues);
                                }}
                            >
                                {t('Delete user')}
                            </Button>
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Button
                        disabled={isFetching || isSaving}
                        fullWidth={false}
                        height="48px"
                        onClick={handleSubmit(handleClickSave)}
                        size="large"
                        type="button"
                    >
                        {isSaving ? `${t('Loading')}...` : t('Save')}
                    </Button>
                </Grid>
            </Stack>
        </form>
    );
};

UserForm.propTypes = {
    branchOffices: PropTypes.array,
    changeInput: PropTypes.func,
    errors: PropTypes.array,
    fetchRandomPassword: PropTypes.func,
    formValues: PropTypes.object,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    isFetchingPassword: PropTypes.bool,
    isMultiBranchesCompany: PropTypes.bool,
    isSaving: PropTypes.bool,
    prepareDelete: PropTypes.func,
    roles: PropTypes.array,
    rolesDictionary: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isFetchingPassword: selectors.getIsFetchingRandomPassword,
    isSaving: selectors.getIsSaving,
    rolesDictionary: selectors.getDictionaryFetchRoles,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    fetchRandomPassword: (onSuccessCallback) =>
        dispatch(actions.fetchRandomPassword(onSuccessCallback)),
    prepareDelete: (item) => dispatch(actions.prepareDelete(item)),
    handleClickSave: (values) => dispatch(actions.save(values)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(UserForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
