import update from 'immutability-helper';

import { SHOW_ALERT, HIDE_ALERT } from './actionTypes';

const INITIAL_STATE = {
    alert: {
        cancelText: '',
        confirmText: '',
        defaultTextResponse: '',
        haveTextCaptcha: false,
        haveTextResponse: false,
        hideButtons: false,
        messages: [],
        onSuccess: null,
        textToValidate: '',
        title: null,
        type: 'confirm',
        variant: 'warning',
    },
    isOpen: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SHOW_ALERT:
            return update(state, {
                alert: {
                    $merge: action.payload,
                },
                isOpen: {
                    $set: true,
                },
            });

        case HIDE_ALERT:
            return update(state, {
                isOpen: {
                    $set: false,
                },
                alert: {
                    $set: INITIAL_STATE.alert,
                },
            });

        default:
            return state;
    }
}
