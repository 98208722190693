import i18next from 'i18next';

import { deleteDatasheet } from 'common/api/datasheets';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    DELETE_DATASHEET,
    DELETE_DATASHEET_FAILURE,
    DELETE_DATASHEET_SUCCESS,
} from '../actionTypes';

import fetchProductDatasheet from './fetchProductDatasheet';

export default (productId) => (dispatch) => {
    dispatch({ type: DELETE_DATASHEET });

    deleteDatasheet(productId)
        .then(() => {
            dispatch({ type: DELETE_DATASHEET_SUCCESS, payload: {} });
            dispatch(alerts.actions.close());
            dispatch(fetchProductDatasheet(productId));
            showToast({
                body: i18next.t('It was successfully removed'),
            });
        })
        .catch((error) => {
            dispatch({
                type: DELETE_DATASHEET_FAILURE,
                payload: error.response.data.errors,
            });

            dispatch(alerts.actions.close());

            const dataAlert = {
                confirmText: i18next.t('Close'),
                messages: [
                    `${i18next.t('Whoops, something went wrong')}:`,
                    ...error.response.data.errors,
                ],
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'error',
            };

            dispatch(alerts.actions.show(dataAlert));
        });
};
