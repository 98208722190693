import React, { useContext, useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { HeaderContainer, TitleIcon } from 'common/components';
import { Container, Typography } from 'common/ui';
import { LoadingContext } from 'common/utils/contexts';

import { ReactComponent as IcoCompany } from 'resources/icoCompany.svg';

import * as actions from './actions';
import ListItems from './components/ListItems';
import * as selectors from './selectors';

const CompaniesContainer = ({
    companies,
    countries,
    downloadFile,
    fetchCompanies,
    fetchCountries,
    fetchPlans,
    isFetching,
    isFetchingCountries,
    isFetchingCSV,
    isFetchingPlans,
    pagination,
    plans,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [filterData, setFilterData] = useState({
        countryName: '',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        planName: '',
        searchText: '',
        sortBy: 'desc',
        status: 'active',
        subscriptionStatus: [],
    });

    useEffect(() => {
        fetchCompanies(filterData);
        fetchCountries();
        fetchPlans();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isFetchingCSV)
            loadingContext.openLoading(t('Downloading CSV').concat('...'));
        else loadingContext.closeLoading();
    }, [isFetchingCSV]);

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4" gutterBottom>
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoCompany}
                        title={t('Company', { count: 2 })}
                    />
                </Typography>
            </HeaderContainer>

            <ListItems
                downloadFile={downloadFile}
                filterData={filterData}
                filterItems={fetchCompanies}
                isFetching={isFetching}
                items={companies}
                paginationData={pagination}
                setFilterData={setFilterData}
                countries={countries}
                isFetchingCountries={isFetchingCountries}
                isFetchingPlans={isFetchingPlans}
                plans={plans}
            />
        </Container>
    );
};

CompaniesContainer.propTypes = {
    companies: PropTypes.array,
    countries: PropTypes.array,
    downloadFile: PropTypes.func,
    fetchCompanies: PropTypes.func,
    fetchCountries: PropTypes.func,
    fetchPlans: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingCountries: PropTypes.bool,
    isFetchingCSV: PropTypes.bool,
    isFetchingPlans: PropTypes.bool,
    pagination: PropTypes.object,
    plans: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    companies: selectors.getCompaniesData,
    countries: selectors.getContriesData,
    isFetching: selectors.getCompaniesIsFetching,
    isFetchingCountries: selectors.getIsFetchingCountries,
    isFetchingCSV: selectors.getIsFetchingCompaniesCSV,
    isFetchingPlans: selectors.getIsFetchingPlans,
    pagination: selectors.getCompaniesPagination,
    plans: selectors.getPlansData,
});

const mapDispatchToProps = (dispatch) => ({
    downloadFile: (filterData) => dispatch(actions.downloadFile(filterData)),
    fetchCompanies: (filterData) =>
        dispatch(actions.fetchCompanies(filterData)),
    fetchCountries: () => dispatch(actions.fetchCountries()),
    fetchPlans: () => dispatch(actions.fetchPlans()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompaniesContainer));
