import { SET_IS_NEW_MODEL } from '../actionTypes';

import initializeModelForm from './initializeModelForm';
import setIsOpenModelForm from './setIsOpenModelForm';

export default (values) => (dispatch) => {
    const newValues = {
        base_model: values?.model || 0,
        context: values?.context || '',
        description: values?.description || '',
        id: values?.id,
        main_model: values?.main_model || false,
        max_tokens: values?.max_tokens || 0,
        name: values?.name || '',
        prompt: values?.prompt || '',
        status: values?.status || 0,
        temperature: values?.temperature || 0,
    };
    dispatch({ type: SET_IS_NEW_MODEL, payload: false });
    dispatch(initializeModelForm(newValues));
    dispatch(setIsOpenModelForm(true));
};
