import { NAME } from './constants';

export const FETCH_READS = `${NAME}/FETCH_READS`;
export const FETCH_READS_FAILURE = `${NAME}/FETCH_READS_FAILURE`;
export const FETCH_READS_SUCCESS = `${NAME}/FETCH_READS_SUCCESS`;

export const FETCH_LOG = `${NAME}/FETCH_LOG`;
export const FETCH_LOG_FAILURE = `${NAME}/FETCH_LOG_FAILURE`;
export const FETCH_LOG_SUCCESS = `${NAME}/FETCH_LOG_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_LOG = `${NAME}/RESET_LOG`;

export const SET_LOG_MODAL_OPEN = `${NAME}/SET_LOG_MODAL_OPEN`;
