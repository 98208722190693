import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME, SECOND_STEP_FORM } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${SECOND_STEP_FORM}`)(state),
    (values) => values || {}
);

/** First step */

export const getInitAuth = createSelector(getModel, (model) => model.initAuth);

export const getIsLoadingInitAuth = createSelector(
    getInitAuth,
    (initAuth) => initAuth.isLoading
);

export const getErrorsInitAuth = createSelector(
    getInitAuth,
    (initAuth) => initAuth.errors
);

export const getValidPassword = createSelector(
    getModel,
    (model) => model.validPassword
);

/** Resend code */

export const getResendCodeModel = createSelector(
    getModel,
    (model) => model.resendCode
);

export const getIsLoadingResendCode = createSelector(
    getResendCodeModel,
    (resendCode) => resendCode.isLoading
);

/** Second step */

export const getSession = createSelector(getModel, (model) => model.session);

export const getIsLoadingSession = createSelector(
    getSession,
    (session) => session.isLoading
);

export const getErrorsSession = createSelector(
    getSession,
    (session) => session.errors
);

export const getIsShowSecondStepAuthForm = createSelector(
    getModel,
    (model) => model.showSecondStepForm
);
