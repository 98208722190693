import { getRateHistory } from 'common/api/suppliers';

import {
    FETCH_RATE_HISTORY,
    FETCH_RATE_HISTORY_FAILURE,
    FETCH_RATE_HISTORY_SUCCESS,
} from '../actionTypes';

import setRateHistoryFilters from './setRateHistoryFilters';

export default (
        page = 1,
        rateId = '',
        year = '',
        month = '',
        summer = '',
        region = '',
        division = '',
        orderBy = '',
        sortBy = '',
        searchStr = ''
    ) =>
    (dispatch) => {
        dispatch({ type: FETCH_RATE_HISTORY });
        dispatch(
            setRateHistoryFilters({
                page,
                rateId,
                year,
                month,
                summer,
                region,
                division,
                orderBy,
                sortBy,
                searchStr,
            })
        );

        getRateHistory({
            division,
            month,
            orderBy,
            page,
            rateId,
            region,
            searchText: searchStr,
            sortBy,
            summer,
            year,
        })
            .then((response) =>
                dispatch({
                    type: FETCH_RATE_HISTORY_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_RATE_HISTORY_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_RATE_HISTORY_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
