import { filterAlliances } from 'common/api/v1/alliances';

import {
    FILTER_ALLIANCES,
    FILTER_ALLIANCES_FAILURE,
    FILTER_ALLIANCES_SUCCESS,
} from '../actionTypes';

export default (filterData) => (dispatch) => {
    dispatch({ type: FILTER_ALLIANCES });

    const {
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
    } = filterData;

    filterAlliances({
        orderBy,
        page: page + 1,
        perPage: pageSize,
        searchText,
        sortBy,
        status,
    })
        .then((response) =>
            dispatch({
                type: FILTER_ALLIANCES_SUCCESS,
                payload: response.data,
            })
        )
        .catch((error) => {
            dispatch({
                type: FILTER_ALLIANCES_FAILURE,
                payload: error.response.data.errors,
            });

            dispatch({
                type: FILTER_ALLIANCES_SUCCESS,
                payload: { data: [] },
            });
        });
};
