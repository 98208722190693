import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleCategoryForm from './toggleCategoryForm';

export default () => (dispatch) => {
    dispatch(toggleCategoryForm(true));
    dispatch(setIsNewRecord(true));
    dispatch(setEnabledEdition(true));
    dispatch(resetForm());
};
