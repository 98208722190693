import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputSearch } from 'common/components';
import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';
import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from 'common/ui';

import { getUsabilityOptions } from '../helpers';

const BulkOperationsCard = ({
    control,
    filterData,
    handleClickEndDate,
    handleClickQueryRange,
    handleClickStartDate,
    handleClickStatus,
    handleClickUsability,
    handleOnChangeSearch,
}) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid
                    alignItems="end"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item xs={12} lg={8}>
                        <InputSearch
                            placeholder={t('Search')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <FormControl fullWidth size="small">
                                <InputLabel>
                                    {t('Filter by')} {t('Status').toLowerCase()}
                                </InputLabel>
                                <Select
                                    onChange={(e) =>
                                        handleClickStatus(e.target.value)
                                    }
                                    value={filterData?.status}
                                    variant="standard"
                                >
                                    <MenuItem value="active">
                                        {t('Active')}
                                    </MenuItem>
                                    <MenuItem value="cancelled">
                                        {t('Inactive')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {!isEmpty(filterData?.status) && (
                                <IconButton
                                    onClick={() => handleClickStatus('')}
                                    sx={{
                                        position: 'absolute',
                                        right: '18px',
                                        top: '8px',
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <FormControl fullWidth size="small">
                                <InputLabel>{t('Usability')}</InputLabel>
                                <Select
                                    label={t('Usability')}
                                    onChange={(e) =>
                                        handleClickUsability(e.target.value)
                                    }
                                    value={filterData?.usabilityValue}
                                    variant="standard"
                                >
                                    {getUsabilityOptions().map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            <CircleIcon
                                                fontSize="small"
                                                sx={{
                                                    color: option.color,
                                                    mr: 1,
                                                }}
                                            />
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {filterData?.usabilityValue && (
                                <IconButton
                                    onClick={() => handleClickUsability('')}
                                    sx={{
                                        position: 'absolute',
                                        right: '18px',
                                        top: '8px',
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                position: 'relative',
                            }}
                        >
                            <FormControl fullWidth size="small">
                                <InputLabel>{t('Query range')}</InputLabel>
                                <Select
                                    label={t('Query range')}
                                    onChange={(e) =>
                                        handleClickQueryRange(e.target.value)
                                    }
                                    value={filterData?.queryRange}
                                    variant="standard"
                                >
                                    <MenuItem value={7}>
                                        {t('Last 7 days')}
                                    </MenuItem>
                                    <MenuItem value={15}>
                                        {t('Last 15 days')}
                                    </MenuItem>
                                    <MenuItem value={30}>
                                        {t('Last 30 days')}
                                    </MenuItem>
                                    <MenuItem value="custom">
                                        {t('Custom')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {!isEmpty(filterData?.queryRange) && (
                                <IconButton
                                    onClick={() => handleClickQueryRange(7)}
                                    sx={{
                                        position: 'absolute',
                                        right: '18px',
                                        top: '8px',
                                    }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        hidden={filterData?.queryRange !== 'custom'}
                        item
                        xs={12}
                        md={6}
                    >
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Start date')}
                            maxDate={new Date()}
                            name="start_date"
                            onChange={(date) => handleClickStartDate(date)}
                            placeholder="MM/DD/AAAA"
                            variant="standard"
                        />
                    </Grid>
                    <Grid
                        hidden={filterData?.queryRange !== 'custom'}
                        item
                        xs={12}
                        md={6}
                    >
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Final date')}
                            maxDate={new Date()}
                            name="final_date"
                            onChange={(date) => handleClickEndDate(date)}
                            placeholder="MM/DD/AAAA"
                            variant="standard"
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    control: PropTypes.object,
    filterData: PropTypes.object,
    handleClickEndDate: PropTypes.func,
    handleClickQueryRange: PropTypes.func,
    handleClickStartDate: PropTypes.func,
    handleClickStatus: PropTypes.func,
    handleClickUsability: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
};

export default BulkOperationsCard;
