import get from 'lodash/get';

import { getUserDetails } from 'common/api/staffUsers';

import {
    FETCH_USER_DETAILS,
    FETCH_USER_DETAILS_FAILURE,
    FETCH_USER_DETAILS_SUCCESS,
} from '../actionTypes';
import { handleSelectedPermissions } from '../helpers';
import { usersActions } from '../reducer';

import initializeForm from './initializeForm';
import openModal from './openModal';
import resetForm from './resetForm';
import setIsNewRecord from './setIsNewRecord';

export default (rolesDictionary, userId) => (dispatch) => {
    dispatch(usersActions[FETCH_USER_DETAILS]());

    getUserDetails(userId)
        .then((response) => {
            const data = get(response, 'data', {});
            dispatch(usersActions[FETCH_USER_DETAILS_SUCCESS](data));
            const {
                company_group,
                email,
                first_name,
                id,
                last_name,
                permissions,
            } = data;
            const selectedRole =
                rolesDictionary[get(company_group, 'type_reference_id', null)];
            dispatch(resetForm());
            dispatch(setIsNewRecord(false));
            dispatch(
                initializeForm({
                    company_group: get(
                        company_group,
                        'type_reference_id',
                        null
                    ),
                    email,
                    first_name,
                    id,
                    last_name,
                    permissions: handleSelectedPermissions(
                        permissions,
                        selectedRole
                    ),
                })
            );
            dispatch(openModal(true));
        })
        .catch((error) =>
            dispatch(
                usersActions[FETCH_USER_DETAILS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
