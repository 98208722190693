import { deleteTag } from 'common/api/ocr/fineTuning';

import {
    DELETE_TAG,
    DELETE_TAG_FAILURE,
    DELETE_TAG_SUCCESS,
} from '../actionTypes';

import fetchTags from './fetchTags';

export default (id) => (dispatch) => {
    dispatch({ type: DELETE_TAG });

    deleteTag(id)
        .then(() => {
            dispatch({ type: DELETE_TAG_SUCCESS, payload: id });
            dispatch(fetchTags());
        })
        .catch((error) =>
            dispatch({
                type: DELETE_TAG_FAILURE,
                payload: error?.response?.data?.detail,
            })
        );
};
