import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    FETCH_ROLES,
    FETCH_ROLES_FAILURE,
    FETCH_ROLES_SUCCESS,
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    FETCH_USER_DETAILS,
    FETCH_USER_DETAILS_FAILURE,
    FETCH_USER_DETAILS_SUCCESS,
    INITIALIZE_FORM,
    SET_IS_OPEN_MODAL,
    RESET_FORM,
    SAVE_USER,
    SAVE_USER_FAILURE,
    SAVE_USER_SUCCESS,
    SET_IS_NEW_RECORD,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    deleteUser: { errors: [], isDeleting: false },
    fetchRoles: {
        data: [],
        errors: [],
        isFetching: false,
    },
    fetchUsers: {
        data: {
            data: [],
            pagination: null,
        },
        errors: [],
        isFetching: false,
    },
    fetchUserDetails: { data: [], errors: [], isFetching: false },
    initialValues: {
        change_password: false,
        company_group: '',
        confirm_password: '',
        email: '',
        first_name: '',
        id: null,
        last_name: '',
        password_confirmation: '',
        password: '',
        permissions: [],
        roleType: null,
    },
    isNewRecord: true,
    isOpenModal: false,
    saveUser: { errors: [], isSaving: false },
};

const usersSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isSaving: false,
            };
        },
        [DELETE_USER]: (state) => {
            state.deleteUser.isDeleting = true;
        },
        [DELETE_USER_FAILURE]: (state, action) => {
            state.deleteUser = {
                ...state.deleteUser,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_USER_SUCCESS]: (state) => {
            state.deleteUser.isDeleting = false;
        },
        [FETCH_ROLES]: (state) => {
            state.fetchRoles.isFetching = true;
        },
        [FETCH_ROLES_FAILURE]: (state, action) => {
            state.fetchRoles = {
                ...state.fetchRoles,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLES_SUCCESS]: (state, action) => {
            state.fetchRoles = {
                ...state.fetchRoles,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_USERS]: (state) => {
            state.fetchUsers.isFetching = true;
        },
        [FETCH_USERS_FAILURE]: (state, action) => {
            state.fetchUsers = {
                ...state.fetchUsers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_USERS_SUCCESS]: (state, action) => {
            state.fetchUsers = {
                ...state.fetchUsers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_USER_DETAILS]: (state) => {
            state.fetchUserDetails.isFetching = true;
        },
        [FETCH_USER_DETAILS_FAILURE]: (state, action) => {
            state.fetchUserDetails = {
                ...state.fetchUserDetails,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_USER_DETAILS_SUCCESS]: (state, action) => {
            state.fetchUserDetails = {
                ...state.fetchUserDetails,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveUser.errors = [];
        },
        [SAVE_USER]: (state) => {
            state.saveUser.isSaving = true;
        },
        [SAVE_USER_FAILURE]: (state, action) => {
            state.saveUser = {
                ...state.saveUser,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_USER_SUCCESS]: (state) => {
            state.saveUser.isSaving = false;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.fetchUsers.data.data = action.payload;
        },
    },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
