import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import CardContainer from 'common/components/CardContainer';
import {
    ReduxFieldAutocomplete,
    ReduxFieldInput,
} from 'common/components/form/redux';
import {
    Box,
    Button,
    Card,
    CardActions,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Pagination,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from 'common/ui';
import { generate_uuid } from 'common/utils/helpers';

import brandFamilyForm from '../../brandFamilyForm';
import * as brandFamilyActions from '../../brandFamilyForm/actions';
import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import FamiliesPreviewTable from './FamiliesPreviewTable';

const BrandForm = ({
    errors,
    families,
    familiesFilters,
    familiesPagination,
    fetchFamilies,
    formValues,
    getOrigins,
    handleClickAddFamily,
    handleClickAddOrigin,
    handleClickSave,
    handleDeleteTemporalFamily,
    handleEditionChange,
    handleSaveTemporalFamily,
    handleSubmit,
    isDeleting,
    isEnabledEdition,
    isFetchingDetail,
    isFetchingFamilies,
    isFetchingOrigins,
    isNewRecord,
    isSaving,
    origins,
    prepareDelete,
    prepareReturnCatalog,
    setCurrentItem,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');

    useEffect(() => {
        if (formValues.id) {
            fetchFamilies(formValues.id, page, orderBy, sortBy, '');
        }
    }, [page, formValues.id]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchFamilies(formValues.id, page, orderBy, sortBy, '');
    };

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    const handleDelete = (item) => {
        prepareDelete(item);
    };

    return (
        <Stack spacing={3}>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title={t('Back')}>
                    <IconButton
                        onClick={() => {
                            prepareReturnCatalog();
                            setCurrentItem({});
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <FormGroup>
                    <FormControlLabel
                        disabled={isNewRecord || isFetchingDetail}
                        control={
                            <Switch
                                checked={isEnabledEdition}
                                onChange={handleEditionSwitchChange}
                            />
                        }
                        label={t('Edit')}
                        labelPlacement="start"
                    />
                </FormGroup>
            </Stack>

            <Box>
                <form>
                    <Stack spacing={3}>
                        <CardContainer
                            title={t('Brand', { count: 1 })}
                            isCollapsable
                            isOpen
                            loading={isFetchingDetail}
                            withDivider
                        >
                            <div>
                                <Grid container spacing={3} pt={3}>
                                    <Grid item xs={12} md={4}>
                                        <Field
                                            name="name"
                                            component={ReduxFieldInput}
                                            placeholder={t('Name')}
                                            variant="outlined"
                                            required
                                            disabled={
                                                isFetchingDetail ||
                                                !isEnabledEdition
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field
                                            name="country"
                                            component={ReduxFieldAutocomplete}
                                            placeholder={t('Origin')}
                                            variant="outlined"
                                            required
                                            disabled={
                                                isFetchingDetail ||
                                                !isEnabledEdition
                                            }
                                            options={origins}
                                            isCreatable
                                            handleAddItem={handleClickAddOrigin}
                                            handleOnSearchChange={getOrigins}
                                            loading={isFetchingOrigins}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button
                                            color="secondary"
                                            disabled={
                                                isFetchingDetail ||
                                                !isEnabledEdition
                                            }
                                            fullWidth
                                            height="inherit"
                                            size="large"
                                            onClick={() => {
                                                handleClickAddFamily({
                                                    id: isNewRecord
                                                        ? generate_uuid()
                                                        : null,
                                                    brand: formValues?.id,
                                                });
                                            }}
                                            startIcon={
                                                <AddIcon color="primary" />
                                            }
                                        >
                                            {t('Add family')}
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid container pt={3}>
                                    <Grid item xs={12}>
                                        <Card
                                            sx={{
                                                boxShadow: (theme) =>
                                                    theme.customShadows.z1,
                                            }}
                                        >
                                            <FamiliesPreviewTable
                                                brandId={formValues?.id}
                                                handleSortable={handleSortable}
                                                isEnabledEdition={
                                                    isEnabledEdition
                                                }
                                                isFetching={isFetchingFamilies}
                                                isNewRecord={isNewRecord}
                                                items={families}
                                                orderdBy={orderBy}
                                                sortedBy={sortBy}
                                            />

                                            {!isFetchingFamilies &&
                                                familiesPagination &&
                                                !isEmpty(
                                                    familiesPagination
                                                ) && (
                                                    <CardActions>
                                                        <Pagination
                                                            color="primary"
                                                            count={
                                                                Math.ceil(
                                                                    parseInt(
                                                                        familiesPagination.total_rows
                                                                    ) /
                                                                        parseInt(
                                                                            familiesPagination.per_page
                                                                        )
                                                                ) || 0
                                                            }
                                                            shape="rounded"
                                                            showFirstButton
                                                            showLastButton
                                                            sx={{
                                                                marginLeft:
                                                                    'auto',
                                                            }}
                                                            page={
                                                                familiesPagination.current_page
                                                            }
                                                            onChange={
                                                                handleOnChangePage
                                                            }
                                                            variant="outlined"
                                                        />
                                                    </CardActions>
                                                )}
                                        </Card>
                                    </Grid>
                                </Grid>

                                {isNewRecord && (
                                    <Grid container pt={3}>
                                        <Grid item xs={12}>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                spacing={2}
                                            >
                                                <InfoIcon />
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    component="div"
                                                >
                                                    {t(
                                                        'All fields are required and it is necessary to add at least one family to be able to create a brand'
                                                    )}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </CardContainer>

                        <ShowErrors
                            errors={errors}
                            mt={3}
                            severity="error"
                            variant="filled"
                        />

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={3}
                            pt={3}
                            pb={3}
                        >
                            <Grid container mt={2}>
                                <Grid item>
                                    {!isNewRecord && isEnabledEdition && (
                                        <Button
                                            color="secondary"
                                            disabled={
                                                isFetchingDetail ||
                                                isSaving ||
                                                isDeleting
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={() => {
                                                handleDelete(formValues);
                                            }}
                                        >
                                            {t('Delete brand')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="flex-end"
                            >
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={isFetchingDetail || isSaving}
                                        fullWidth={false}
                                        height="48px"
                                        size="large"
                                        onClick={() => {
                                            prepareReturnCatalog();
                                            setCurrentItem({});
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </Grid>
                                {isEnabledEdition && (
                                    <Grid item>
                                        <Button
                                            disabled={
                                                isFetchingDetail || isSaving
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={handleSubmit(
                                                handleClickSave
                                            )}
                                            type="submit"
                                        >
                                            {isSaving
                                                ? `${t('Loading')}...`
                                                : isNewRecord
                                                ? t('Create')
                                                : t('Save')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Stack>
                    </Stack>
                </form>
            </Box>

            <Box>
                <brandFamilyForm.Container
                    filters={familiesFilters}
                    handleAfterSave={fetchFamilies}
                    handleTemporalDelete={handleDeleteTemporalFamily}
                    handleTemporalSave={handleSaveTemporalFamily}
                    isNewBrand={isNewRecord}
                />
            </Box>
        </Stack>
    );
};

BrandForm.propTypes = {
    errors: PropTypes.array,
    families: PropTypes.array,
    familiesFilters: PropTypes.object,
    familiesPagination: PropTypes.object,
    fetchFamilies: PropTypes.func,
    formValues: PropTypes.object,
    getOrigins: PropTypes.func,
    handleClickAddFamily: PropTypes.func,
    handleClickAddOrigin: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleDeleteTemporalFamily: PropTypes.func,
    handleEditionChange: PropTypes.func,
    handleSaveTemporalFamily: PropTypes.func,
    handleSubmit: PropTypes.func,
    isDeleting: PropTypes.bool,
    isEnabledEdition: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isFetchingFamilies: PropTypes.bool,
    isFetchingOrigins: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    origins: PropTypes.array,
    prepareDelete: PropTypes.func,
    prepareReturnCatalog: PropTypes.func,
    setCurrentItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    families: selectors.getFamiliesData,
    familiesFilters: selectors.getFamiliesFilters,
    familiesPagination: selectors.getFamiliesPagination,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isDeleting: selectors.getIsDeletingItem,
    isEnabledEdition: selectors.getEnabledEdition,
    isFetchingDetail: selectors.getIsFetchingBrandDetail,
    isFetchingFamilies: selectors.getFamiliesIsFetching,
    isFetchingOrigins: selectors.getIsFetchingOrigins,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
    origins: selectors.getOriginsOptionsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    fetchFamilies: (id, page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchFamilies(id, page, orderBy, sortBy, searchStr)),
    getOrigins: (str) => dispatch(actions.fetchOrigins(str)),
    handleClickAddFamily: (item) =>
        dispatch(brandFamilyActions.prepareCreate(item)),
    handleClickAddOrigin: (values) =>
        dispatch(actions.prepareAddOrigin(values)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleDeleteTemporalFamily: (item) =>
        dispatch(actions.deleteTemporalFamily(item)),
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    handleSaveTemporalFamily: (item) =>
        dispatch(actions.addTemporalFamily(item)),
    prepareDelete: (item) => dispatch(actions.prepareDelete(item)),
    prepareReturnCatalog: () => dispatch(actions.returnCatalog()),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(BrandForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
