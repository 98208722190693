import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { Container as MuiContainer } from 'common/ui';

import * as actions from './actions';
import ListItems from './components/ListItems';
import { getPastDate, getTodayDate } from './helpers';
import * as selectors from './selectors';

const Container = ({ renewals, fetchRenewals, isFetching, pagination }) => {
    const [filterData, setFilterData] = useState({
        endDate: getTodayDate('yyyy-MM-dd'),
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        queryRange: 7,
        searchText: '',
        sortBy: 'desc',
        startDate: getPastDate(7, 'yyyy-MM-dd'),
        status: 'active',
        usabilityOperator: '',
        usabilityValue: '',
    });

    useEffect(() => {
        fetchRenewals(filterData);
    }, []);

    return (
        <MuiContainer maxWidth="">
            <ListItems
                filterData={filterData}
                filterItems={fetchRenewals}
                isFetching={isFetching}
                items={renewals}
                paginationData={pagination}
                setFilterData={setFilterData}
            />
        </MuiContainer>
    );
};

Container.propTypes = {
    renewals: PropTypes.array,
    fetchRenewals: PropTypes.func,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    renewals: selectors.getRenewalsData,
    isFetching: selectors.getIsFetchingRenewals,
    pagination: selectors.getRenewalsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRenewals: (filterData) => dispatch(actions.fetchRenewals(filterData)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Container));
