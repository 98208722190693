import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { UiDialog as Dialog } from 'common/ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import LogViewer from './LogViewer';

const LogModal = ({ isOpen, resetLog, setIsOpen }) => {
    const handleOnClose = () => setIsOpen(false);

    return (
        <Dialog
            onClose={handleOnClose}
            onExited={resetLog}
            open={isOpen}
            size="md"
            title="Log"
        >
            <LogViewer />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpen: selectors.getIsLogModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
    resetLog: () => dispatch(actions.resetLog()),
    setIsOpen: (isOpen) => dispatch(actions.setLogModalOpen(isOpen)),
});

LogModal.propTypes = {
    isOpen: PropTypes.bool,
    resetLog: PropTypes.func,
    setIsOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogModal);
