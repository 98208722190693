import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomListItem } from 'common/components';
import { Box, List } from 'common/ui';

import { handleNormalizeValue } from '../helpers';

const IrradiationSourceInfoCard = ({ companyDetail }) => {
    const { t } = useTranslation();
    return (
        <Box>
            <List>
                <CustomListItem
                    label={t('Default irradiation source')}
                    value={handleNormalizeValue(
                        get(companyDetail, 'irradiation.source.value', null)
                    )}
                    withHorizontalDivider
                />

                <CustomListItem
                    label={t('Last change date')}
                    value={handleNormalizeValue(
                        get(companyDetail, 'irradiation.updated_at', null)
                    )}
                />
            </List>
        </Box>
    );
};

IrradiationSourceInfoCard.propTypes = {
    companyDetail: PropTypes.object,
};

export default IrradiationSourceInfoCard;
