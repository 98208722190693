import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteModel from './deleteModel';

export default (id) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        haveTextCaptcha: true,
        messages: [i18next.t('Are you sure to remove this model?')],
        onSuccess: () => dispatch(deleteModel(id)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Delete model'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
