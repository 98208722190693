import update from 'immutability-helper';

import {
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    OPEN_MODAL,
    DELETE_FAMILY,
    DELETE_FAMILY_SUCCESS,
    DELETE_FAMILY_FAILURE,
    SET_IS_NEW_RECORD,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        name: null,
        brand: null,
    },
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    deleteFamily: {
        isDeleting: false,
        data: [],
        error: null,
    },
    isNewRecord: false,
    isOpenModal: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
                deleteFamily: {
                    $set: INITIAL_STATE.deleteFamily,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case DELETE_FAMILY:
            return update(state, {
                deleteFamily: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_FAMILY_SUCCESS:
            return update(state, {
                deleteFamily: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_FAMILY_FAILURE:
            return update(state, {
                deleteFamily: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });

        case OPEN_MODAL:
            return update(state, {
                isOpenModal: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
