import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AutocompleteField from 'common/components/AutocompleteField';
import { Box } from 'common/ui';

const Filters = ({
    companiesForSelect,
    company,
    isLoading,
    onChangeCompany,
}) => {
    const { t } = useTranslation();

    return (
        <Box mb={2}>
            <AutocompleteField
                disabled={isLoading}
                label={t('Company', { count: 1 })}
                onChange={onChangeCompany}
                options={companiesForSelect}
                value={company}
            />
        </Box>
    );
};

Filters.propTypes = {
    companiesForSelect: PropTypes.array,
    company: PropTypes.string,
    isLoading: PropTypes.bool,
    onChangeCompany: PropTypes.func,
};

export default Filters;
