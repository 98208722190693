import update from 'immutability-helper';

import {
    DOWNLOAD_FILE,
    DOWNLOAD_FILE_FAILURE,
    DOWNLOAD_FILE_SUCCESS,
    FETCH_FILES,
    FETCH_FILES_FAILURE,
    FETCH_FILES_SUCCESS,
    SET_AS_SUCCESS,
    SET_AS_SUCCESS_FAILURE,
    SET_AS_SUCCESS_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    downloadFile: { data: null, errors: [], isFetching: false },
    files: {
        data: [],
        errors: null,
        isFetching: false,
    },
    setAsSuccess: { data: null, errors: [], isSaving: false },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DOWNLOAD_FILE:
            return update(state, {
                downloadFile: { $merge: { errors: [], isFetching: true } },
            });

        case DOWNLOAD_FILE_FAILURE:
            return update(state, {
                downloadFile: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case DOWNLOAD_FILE_SUCCESS:
            return update(state, {
                downloadFile: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_FILES:
            return update(state, {
                files: { $merge: { data: [], isFetching: true, errors: [] } },
            });

        case FETCH_FILES_FAILURE:
            return update(state, {
                files: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_FILES_SUCCESS:
            return update(state, {
                files: { $merge: { data: action.payload, isFetching: false } },
            });

        case SET_AS_SUCCESS:
            return update(state, {
                setAsSuccess: { $merge: { errors: [], isSaving: true } },
            });

        case SET_AS_SUCCESS_FAILURE:
            return update(state, {
                setAsSuccess: {
                    $merge: { errors: action.payload, isSaving: false },
                },
            });

        case SET_AS_SUCCESS_SUCCESS:
            return update(state, {
                setAsSuccess: { $merge: { isSaving: false } },
            });

        default:
            return state;
    }
}
