import fetchCategories from './fetchCategories';
import fetchOrigins from './fetchOrigins';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleTemplateForm from './toggleTemplateForm';

export default () => (dispatch) => {
    dispatch(fetchCategories());
    dispatch(fetchOrigins());

    dispatch(toggleTemplateForm(true));
    dispatch(setIsNewRecord(true));
    dispatch(setEnabledEdition(true));
    dispatch(resetForm());
};
