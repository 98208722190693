import { getYears } from 'common/api/suppliers';

import {
    FETCH_YEARS,
    FETCH_YEARS_SUCCESS,
    FETCH_YEARS_FAILURE,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_YEARS });

    getYears()
        .then((response) => {
            dispatch({
                type: FETCH_YEARS_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_YEARS_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
