import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExtensionIcon from '@mui/icons-material/Extension';
import PropTypes from 'prop-types';

import ReactHookFormSwitch from 'common/components/form/bootstrap/ReactHookFormSwitch';
import { Box, Tooltip, Typography } from 'common/ui';
import { getTranslatedAddonLabel } from 'common/utils/helpers';

import StyledItem from './StyledItem';

const GroupItemSwitch = ({ addons, control, disabled, index, item, name }) => {
    const hasAddon = addons.includes(item?.addon_name);
    return (
        <StyledItem className={index % 2 === 0 ? 'StripedRow' : ''}>
            <Typography id={item.id} variant="caption">
                {item?.name}
            </Typography>
            {item.addon_name && (
                <Tooltip
                    title={
                        <Box alignItems="center" display="flex" gap={1}>
                            {getTranslatedAddonLabel(item?.addon_name)}
                            {hasAddon && (
                                <CheckCircleIcon sx={{ fontSize: '13px' }} />
                            )}
                        </Box>
                    }
                >
                    <ExtensionIcon
                        color="primary"
                        sx={{ cursor: 'pointer', fontSize: 14, ml: 1 }}
                    />
                </Tooltip>
            )}
            <Box ml="auto">
                <ReactHookFormSwitch
                    control={control}
                    disabled={
                        disabled ||
                        !item?.is_enabled ||
                        (item.addon_name && !hasAddon)
                    }
                    fullWidth
                    name={name}
                />
            </Box>
        </StyledItem>
    );
};

GroupItemSwitch.propTypes = {
    addons: PropTypes.array,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    index: PropTypes.number,
    item: PropTypes.object,
    name: PropTypes.string,
};
export default GroupItemSwitch;
