import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Stack, TextField, Typography } from 'common/ui';

const TextCaptcha = ({ isValid, onValidate, textToValidate }) => {
    const { t } = useTranslation();
    const [text, setText] = useState('');

    useEffect(() => {
        if ((text === textToValidate) !== isValid) {
            onValidate(text === textToValidate);
        }
    }, [text]);

    return (
        <Box mt={2}>
            <Typography>
                {t(
                    'Type the word {{textToValidate}} to confirm the operation.',
                    { textToValidate }
                )}
            </Typography>
            <Stack mt={2}>
                <TextField
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                    placeholder={textToValidate}
                />
            </Stack>
        </Box>
    );
};

TextCaptcha.propTypes = {
    isValid: PropTypes.bool,
    onValidate: PropTypes.func,
    textToValidate: PropTypes.string,
};

export default TextCaptcha;
