import i18next from 'i18next';

import { updateTrainingDataFile } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from '../actionTypes';

import fetchFile from './fetchFile';

export default ({ id, modelId, file }) =>
    (dispatch) => {
        if (!file?.length) return;

        dispatch({ type: UPLOAD_FILE });

        updateTrainingDataFile(id, modelId, file[0])
            .then((response) => {
                showToast({ autoClose: 3000 });

                dispatch({ type: UPLOAD_FILE_SUCCESS, payload: response.data });

                if (response?.data?.file)
                    dispatch(fetchFile(response.data.file));
            })
            .catch((error) => {
                dispatch({
                    type: UPLOAD_FILE_FAILURE,
                    payload: error?.response?.data?.detail,
                });

                showToast({
                    autoClose: 3000,
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while updating the training data'
                    ),
                });
            });
    };
