import { NAME } from './constants';

export const FETCH_SUPPLIERS = `${NAME}/FETCH_SUPPLIERS`;
export const FETCH_SUPPLIERS_FAILURE = `${NAME}/FETCH_SUPPLIERS_FAILURE`;
export const FETCH_SUPPLIERS_SUCCESS = `${NAME}/FETCH_SUPPLIERS_SUCCESS`;

export const FETCH_SUPPLIER_DETAIL = `${NAME}/FETCH_SUPPLIER_DETAIL`;
export const FETCH_SUPPLIER_DETAIL_FAILURE = `${NAME}/FETCH_SUPPLIER_DETAIL_FAILURE`;
export const FETCH_SUPPLIER_DETAIL_SUCCESS = `${NAME}/FETCH_SUPPLIER_DETAIL_SUCCESS`;

export const FETCH_ORIGINS = `${NAME}/FETCH_ORIGINS`;
export const FETCH_ORIGINS_SUCCESS = `${NAME}/FETCH_ORIGINS_SUCCESS`;
export const FETCH_ORIGINS_FAILURE = `${NAME}/FETCH_ORIGINS_FAILURE`;

export const FETCH_RATE_HISTORY = `${NAME}/FETCH_RATE_HISTORY`;
export const FETCH_RATE_HISTORY_FAILURE = `${NAME}/FETCH_RATE_HISTORY_FAILURE`;
export const FETCH_RATE_HISTORY_SUCCESS = `${NAME}/FETCH_RATE_HISTORY_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;

export const FETCH_YEARS = `${NAME}/FETCH_YEARS`;
export const FETCH_YEARS_FAILURE = `${NAME}/FETCH_YEARS_FAILURE`;
export const FETCH_YEARS_SUCCESS = `${NAME}/FETCH_YEARS_SUCCESS`;

export const FETCH_MONTHS = `${NAME}/FETCH_MONTHS`;
export const FETCH_MONTHS_FAILURE = `${NAME}/FETCH_MONTHS_FAILURE`;
export const FETCH_MONTHS_SUCCESS = `${NAME}/FETCH_MONTHS_SUCCESS`;

export const FETCH_SUMMER_MONTHS = `${NAME}/FETCH_SUMMER_MONTHS`;
export const FETCH_SUMMER_MONTHS_FAILURE = `${NAME}/FETCH_SUMMER_MONTHS_FAILURE`;
export const FETCH_SUMMER_MONTHS_SUCCESS = `${NAME}/FETCH_SUMMER_MONTHS_SUCCESS`;

export const FETCH_REGIONS = `${NAME}/FETCH_REGIONS`;
export const FETCH_REGIONS_FAILURE = `${NAME}/FETCH_REGIONS_FAILURE`;
export const FETCH_REGIONS_SUCCESS = `${NAME}/FETCH_REGIONS_SUCCESS`;

export const FETCH_DIVISIONS = `${NAME}/FETCH_DIVISIONS`;
export const FETCH_DIVISIONS_FAILURE = `${NAME}/FETCH_DIVISIONS_FAILURE`;
export const FETCH_DIVISIONS_SUCCESS = `${NAME}/FETCH_DIVISIONS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_ITEM_FORM = `${NAME}/OPEN_ITEM_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const SET_FILTERS = `${NAME}/SET_FILTERS`;
export const SET_RATE_HISTORY_FILTERS = `${NAME}/SET_RATE_HISTORY_FILTERS`;
export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;

export const DELETE_SUPPLIER = `${NAME}/DELETE_SUPPLIER`;
export const DELETE_SUPPLIER_SUCCESS = `${NAME}/DELETE_SUPPLIER_SUCCESS`;
export const DELETE_SUPPLIER_FAILURE = `${NAME}/DELETE_SUPPLIER_FAILURE`;
