import update from 'immutability-helper';

import {
    FILTER_COMPANY_LOGS,
    FILTER_COMPANY_LOGS_SUCCESS,
    FILTER_COMPANY_LOGS_FAILURE,
    RESET,
} from './actionTypes';

const INITIAL_STATE = {
    companyLogs: {
        data: {
            data: [],
            paginaton: {},
        },
        errors: [],
        isFetching: false,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FILTER_COMPANY_LOGS:
            return update(state, {
                companyLogs: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FILTER_COMPANY_LOGS_FAILURE:
            return update(state, {
                companyLogs: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FILTER_COMPANY_LOGS_SUCCESS: {
            const { data, pagination } = action.payload || {};
            return update(state, {
                companyLogs: {
                    $merge: {
                        data: {
                            data: [...state.companyLogs.data.data, ...data],
                            pagination,
                        },
                        isFetching: false,
                    },
                },
            });
        }

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        default:
            return state;
    }
}
