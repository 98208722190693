import { getProjectReads } from 'common/api/afterSales/projects';

import {
    FETCH_READS,
    FETCH_READS_FAILURE,
    FETCH_READS_SUCCESS,
} from '../actionTypes';

export default (projectId, { page = 1, pageSize = 25 } = {}) =>
    (dispatch) => {
        if (!projectId) return;

        dispatch({ type: FETCH_READS });

        getProjectReads({ projectId, page, page_size: pageSize })
            .then((response) =>
                dispatch({ payload: response.data, type: FETCH_READS_SUCCESS })
            )
            .catch((error) =>
                dispatch({ payload: error, type: FETCH_READS_FAILURE })
            );
    };
