import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { CardContainer } from 'common/components';
import {
    PlaceholderPlanForm,
    PlaceholderHistory,
} from 'common/components/placeholders';
import {
    Avatar,
    Box,
    Card,
    CardActions,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Pagination,
    Typography,
} from 'common/ui';

import PlaceholderPlans from 'resources/placeholders/placeholder_plans.svg';

import * as actions from './actions';
import { PlansPreviewTable, PlanForm } from './components';
import {
    getHistoryOrigin,
    getHistoryManager,
    getHistoryDescription,
} from './helpers';
import * as selectors from './selectors';

const StyledCard = styled(Card)(({ backgroundcolor, backgroundimg }) => ({
    alignItems: 'center',
    background: `${backgroundimg}`,
    backgroundImage: `url(${backgroundimg}), linear-gradient(to right, #fff , ${backgroundcolor})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
}));

const PlansContainer = ({
    fetchPlans,
    isFetching,
    isLoadingPlanDetail,
    isLoadingPlanHistory,
    isOpenEditionForm,
    match,
    pagination,
    planHistory,
    plans,
    reset,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (match.params.id) {
            fetchPlans(match.params.id, page, orderBy, sortBy, '');
        }
    }, [page, match.params.id]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchPlans(match.params.id, page, orderBy, sortBy, '');
    };

    return (
        <>
            <Container maxWidth="">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <CardContainer
                            title={t('Plan', { count: 2 })}
                            withDivider
                        >
                            <Card
                                sx={{
                                    boxShadow: (theme) =>
                                        theme.customShadows.z0,
                                }}
                            >
                                <PlansPreviewTable
                                    companyId={match.params.id}
                                    handleSortable={handleSortable}
                                    isFetching={isFetching}
                                    items={plans}
                                    sortedBy={sortBy}
                                    orderdBy={orderBy}
                                />

                                {!isFetching &&
                                    pagination &&
                                    !isEmpty(pagination) && (
                                        <CardActions>
                                            <Pagination
                                                color="primary"
                                                count={
                                                    Math.ceil(
                                                        parseInt(
                                                            pagination.total_rows
                                                        ) /
                                                            parseInt(
                                                                pagination.per_page
                                                            )
                                                    ) || 0
                                                }
                                                shape="rounded"
                                                showFirstButton
                                                showLastButton
                                                sx={{
                                                    marginLeft: 'auto',
                                                }}
                                                page={pagination.current_page}
                                                onChange={handleOnChangePage}
                                                variant="outlined"
                                            />
                                        </CardActions>
                                    )}
                            </Card>
                        </CardContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {isOpenEditionForm ? (
                            <Grid item container spacing={3}>
                                <Grid item xs={12}>
                                    <CardContainer
                                        collapsePaddingButton="0px"
                                        title={t('Plan information')}
                                        isCollapsable
                                        loading={isLoadingPlanDetail}
                                        unmountOnExit={false}
                                        withDivider
                                    >
                                        <PlaceholderPlanForm
                                            ready={isLoadingPlanDetail}
                                        >
                                            <PlanForm />
                                        </PlaceholderPlanForm>
                                    </CardContainer>
                                </Grid>

                                <Grid item xs={12}>
                                    <CardContainer
                                        maxContentHeight="365px"
                                        title={t('Plan history')}
                                        isCollapsable
                                        loading={isLoadingPlanHistory}
                                        withDivider
                                    >
                                        <PlaceholderHistory
                                            ready={isLoadingPlanHistory}
                                        >
                                            {!isEmpty(planHistory) ? (
                                                <Box component="div">
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            bgcolor:
                                                                'background.paper',
                                                        }}
                                                    >
                                                        {planHistory.map(
                                                            (
                                                                history,
                                                                index
                                                            ) => (
                                                                <div
                                                                    key={`history-item-${index}`}
                                                                >
                                                                    <ListItem alignItems="flex-start">
                                                                        <ListItemAvatar
                                                                            sx={{
                                                                                marginTop:
                                                                                    '0px',
                                                                            }}
                                                                        >
                                                                            <Avatar
                                                                                alt={getHistoryOrigin(
                                                                                    history
                                                                                )}
                                                                                src="/"
                                                                            />
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                <React.Fragment>
                                                                                    <Typography variant="subtitle2">
                                                                                        {`${getHistoryOrigin(
                                                                                            history
                                                                                        )}${getHistoryManager(
                                                                                            history
                                                                                        )}`}
                                                                                    </Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            display:
                                                                                                'inline',
                                                                                        }}
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        <span
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: getHistoryDescription(
                                                                                                    history
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    </Typography>
                                                                                    <br />
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="subtitle2"
                                                                                        sx={{
                                                                                            lineHeight:
                                                                                                '2.571',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            history.action_time
                                                                                        }
                                                                                    </Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                    <Divider
                                                                        variant="inset"
                                                                        component="li"
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </List>
                                                </Box>
                                            ) : (
                                                <Box component="div">
                                                    <Typography
                                                        color="text.secondary"
                                                        align="center"
                                                    >
                                                        {t(
                                                            'No recent change logs'
                                                        )}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </PlaceholderHistory>
                                    </CardContainer>
                                </Grid>
                            </Grid>
                        ) : (
                            <StyledCard
                                backgroundcolor="#fff"
                                backgroundimg={PlaceholderPlans}
                            >
                                <img
                                    alt="placeholder no plans selected"
                                    loading="lazy"
                                    src={PlaceholderPlans}
                                />
                            </StyledCard>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

PlansContainer.propTypes = {
    fetchPlans: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoadingPlanDetail: PropTypes.bool,
    isLoadingPlanHistory: PropTypes.bool,
    isOpenEditionForm: PropTypes.bool,
    match: PropTypes.object,
    pagination: PropTypes.object,
    planHistory: PropTypes.array,
    plans: PropTypes.array,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getPlansIsFetching,
    isLoadingPlanDetail: selectors.getIsFetchingPlanDetail,
    isLoadingPlanHistory: selectors.getPlanHistoryIsFetching,
    isOpenEditionForm: selectors.getIsOpenEditionForm,
    pagination: selectors.getPlansPagination,
    planHistory: selectors.getPlanHistoryData,
    plans: selectors.getPlansData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPlans: (id, page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchPlans(id, page, orderBy, sortBy, searchStr)),
    reset: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PlansContainer));
