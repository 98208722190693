import API from '..';
const ENTITY = 'templates';

export const getCategories = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/category/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getCategoryDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/category/${id}/`);

export const createCategory = (data) =>
    API.post(`/api/v1/${ENTITY}/category/`, data);

export const updateCategory = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/category/${id}/`, data);

export const deleteCategory = (id) =>
    API.delete(`/api/v1/${ENTITY}/category/${id}/`);
