import update from 'immutability-helper';

import {
    FETCH_OUTSTANDING_DETAIL,
    FETCH_OUTSTANDING_DETAIL_SUCCESS,
    FETCH_OUTSTANDING_DETAIL_FAILURE,
    DELETE_OUTSTANDING,
    DELETE_OUTSTANDING_FAILURE,
    DELETE_OUTSTANDING_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    OPEN_MODAL,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        offer_template: null,
        days_left: null,
        days_validity: null,
        unlimited: false,
    },
    outstandingDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    deleteOutstanding: {
        isDeleting: false,
        data: [],
        error: null,
    },
    isOpenModal: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_OUTSTANDING_DETAIL:
            return update(state, {
                outstandingDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_OUTSTANDING_DETAIL_FAILURE:
            return update(state, {
                outstandingDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_OUTSTANDING_DETAIL_SUCCESS:
            return update(state, {
                outstandingDetail: {
                    $merge: { errors: [], isFetching: false },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
                deleteOutstanding: {
                    $set: INITIAL_STATE.deleteOutstanding,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case DELETE_OUTSTANDING:
            return update(state, {
                deleteOutstanding: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_OUTSTANDING_SUCCESS:
            return update(state, {
                deleteOutstanding: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_OUTSTANDING_FAILURE:
            return update(state, {
                deleteOutstanding: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });

        case OPEN_MODAL:
            return update(state, {
                isOpenModal: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
