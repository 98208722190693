import i18next from 'i18next';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getSuppliersModel = createSelector(
    getModel,
    (model) => model.suppliers
);

export const getSuppliersData = createSelector(getSuppliersModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((supplier) => ({
              ...supplier,
              country: get(supplier, 'country.name', ''),
          }))
);

export const getSuppliersErrors = createSelector(
    getSuppliersModel,
    (model) => model.errors
);

export const getSuppliersIsFetching = createSelector(
    getSuppliersModel,
    (model) => model.isFetching
);

export const getSuppliersPagination = createSelector(
    getSuppliersModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenItemForm = createSelector(
    getModel,
    (model) => model.isOpenItemForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getSupplierDetail = createSelector(
    getModel,
    (model) => model.supplierDetail
);

export const getIsFetchingSupplierDetail = createSelector(
    getSupplierDetail,
    (supplierDetail) => supplierDetail.isFetching
);

export const getFetchOrigins = createSelector(
    getModel,
    (model) => model.fetchOrigins
);

export const getIsFetchingOrigins = createSelector(
    getFetchOrigins,
    (fetchOrigins) => fetchOrigins.isFetching
);

export const getOriginsOptionsForSelect = createSelector(
    getFetchOrigins,
    (fetchOrigins) => {
        let items = fetchOrigins.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));
        return items;
    }
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);

export const getDeleteSupplier = createSelector(
    getModel,
    (model) => model.deleteSupplier
);

export const getIsDeletingSupplier = createSelector(
    getDeleteSupplier,
    (deleteSupplier) => deleteSupplier.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteSupplier,
    (deleteSupplier) => deleteSupplier.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteSupplier,
    (deleteSupplier) => deleteSupplier.errors
);

export const getRateHistoryModel = createSelector(
    getModel,
    (model) => model.rateHistory
);

export const getRateHistoryData = createSelector(getRateHistoryModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((rateHistory) => ({
              ...rateHistory,
              month: get(rateHistory, 'month.value', ''),
          }))
);

export const getRateHistoryErrors = createSelector(
    getRateHistoryModel,
    (model) => model.errors
);

export const getRateHistoryIsFetching = createSelector(
    getRateHistoryModel,
    (model) => model.isFetching
);

export const getRateHistoryPagination = createSelector(
    getRateHistoryModel,
    (model) => model.data.pagination || {}
);

export const getRateHistoryFilters = createSelector(
    getModel,
    (model) => model.historyRateFilters
);

export const getFetchRates = createSelector(
    getModel,
    (model) => model.fetchRates
);

export const getIsFetchingRates = createSelector(
    getFetchRates,
    (fetchRates) => fetchRates.isFetching
);

export const getRatesOptionsForSelect = createSelector(
    getFetchRates,
    (rates) => {
        let items = rates.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));

        return items;
    }
);

export const getFetchYears = createSelector(
    getModel,
    (model) => model.fetchYears
);

export const getIsFetchingYears = createSelector(
    getFetchYears,
    (years) => years.isFetching
);

export const getYearsOptionsForSelect = createSelector(
    getFetchYears,
    (years) => {
        const sortedYears = sortBy(years.data, 'key');
        let items = sortedYears.reverse().map((item) => ({
            label: item.value,
            optionValue: item.key,
        }));
        return items;
    }
);

export const getFetchMonths = createSelector(
    getModel,
    (model) => model.fetchMonths
);

export const getIsFetchingMonths = createSelector(
    getFetchMonths,
    (months) => months.isFetching
);

export const getMonthsOptionsForSelect = createSelector(
    getFetchMonths,
    (months) => {
        const sortedMonths = sortBy(months.data, 'key');
        let items = sortedMonths.reverse().map((item) => ({
            label: item.value,
            optionValue: item.key,
        }));
        return items;
    }
);

export const getFetchSummerMonthsModel = createSelector(
    getModel,
    (model) => model.fetchSummerMonths
);

export const getIsFetchingSummerMonths = createSelector(
    getFetchSummerMonthsModel,
    (summerMonths) => summerMonths.isFetching
);

export const getSummerMonthsOptionsForSelect = createSelector(
    getFetchSummerMonthsModel,
    (summerMonths) => {
        let items = summerMonths.data.map((item) => ({
            label: item.name,
            optionValue: item.name,
        }));
        return [
            {
                label: i18next.t('All', { context: 'male', count: 2 }),
                optionValue: '',
            },
        ].concat(items);
    }
);

export const getFetchRegionsModel = createSelector(
    getModel,
    (model) => model.fetchRegions
);

export const getIsFetchingRegions = createSelector(
    getFetchRegionsModel,
    (regions) => regions.isFetching
);

export const getRegionsOptionsForSelect = createSelector(
    getFetchRegionsModel,
    (regions) => {
        let items = regions.data.map((item) => ({
            label: item.name,
            optionValue: item.name,
        }));
        return [
            {
                label: i18next.t('All', { context: 'female', count: 2 }),
                optionValue: '',
            },
        ].concat(items);
    }
);

export const getFetchDivisionsModel = createSelector(
    getModel,
    (model) => model.fetchDivisions
);

export const getIsFetchingDivisions = createSelector(
    getFetchDivisionsModel,
    (divisions) => divisions.isFetching
);

export const getDivisionsOptionsForSelect = createSelector(
    getFetchDivisionsModel,
    (divisions) => {
        let items = divisions.data.map((item) => ({
            label: item.name,
            optionValue: item.name,
        }));
        return [
            {
                label: i18next.t('All', { context: 'female', count: 2 }),
                optionValue: '',
            },
        ].concat(items);
    }
);
