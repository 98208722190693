import i18next from 'i18next';

import { getPlanHistory } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    FETCH_PLAN_HISTORY,
    FETCH_PLAN_HISTORY_FAILURE,
    FETCH_PLAN_HISTORY_SUCCESS,
} from '../actionTypes';

export default (companyId, plan) => (dispatch) => {
    dispatch({ type: FETCH_PLAN_HISTORY });

    getPlanHistory(companyId, plan.id)
        .then((response) => {
            dispatch({
                type: FETCH_PLAN_HISTORY_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PLAN_HISTORY_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t(
                    'An error occurred while querying the plan history'
                ),
            });
        });
};
