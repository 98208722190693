import { chatGPT } from 'common/api/ocr/openai';

import {
    EDIT_MESSAGE,
    SEND_MESSAGES,
    SEND_MESSAGES_FAILURE,
    SEND_MESSAGES_SUCCESS,
} from '../actionTypes';

import addMessage from './addMessage';

export default (messages, newMessage) => async (dispatch) => {
    dispatch({ type: SEND_MESSAGES });

    dispatch(addMessage(newMessage));

    const newMessages = messages.concat(newMessage);

    chatGPT(newMessages)
        .then((response) => {
            dispatch(addMessage({ role: '', content: '' }));
            const indexMessage = newMessages.length;

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            reader.read().then(function pump({ done, value }) {
                if (done) {
                    dispatch({ type: SEND_MESSAGES_SUCCESS });
                    return;
                }

                const decodedValue = decoder.decode(value);
                const jsons = '[' + decodedValue.replace(/}{/g, '},{') + ']';
                const parsed = JSON.parse(jsons || '[]');
                const { content, role } = parsed.reduce(
                    (acc, item) => ({
                        role: acc.role + (item.role || ''),
                        content: acc.content + (item.content || ''),
                    }),
                    { content: '', role: '' }
                );

                dispatch({
                    type: EDIT_MESSAGE,
                    payload: { content, index: indexMessage, role },
                });

                return reader.read().then(pump);
            });
        })
        .catch((error) =>
            dispatch({
                type: SEND_MESSAGES_FAILURE,
                payload: error?.response?.data?.detail,
            })
        );
};
