import initializeForm from './initializeForm';
import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (item) => (dispatch) => {
    dispatch(toggleModal(true));
    dispatch(resetForm());
    dispatch(
        initializeForm({
            offer_template: item.id,
        })
    );
};
