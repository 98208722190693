import React, { forwardRef, useImperativeHandle, useState } from 'react';

import i18next from 'i18next';
import styled, { keyframes } from 'styled-components';

import isotypeSunwise1 from 'resources/isotypeSunwise1.svg';
import isotypeSunwise2 from 'resources/isotypeSunwise2.svg';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  0%, 20%, 40%, 60%, 80%, 100% {
    opacity: 1;
  }

  10%, 30%, 50%, 70%, 90% {
    opacity: 0;
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
    align-items: center;
    background: #002438ee;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    position: fixed;
    top: 0;
    transition: ${({ visible }) =>
        visible
            ? 'opacity 0.25s linear, visibility 0s linear'
            : 'opacity 0.25s linear, visibility 0s linear 0.25s'};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    width: 100%;
    z-index: 9999;
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const LogoImage1 = styled.img`
    height: 100%;
    position: absolute;
    width: 100%;
`;

const LogoImage2 = styled.img`
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-name: ${rotate};
    animation-timing-function: linear;
    height: 100%;
    position: absolute;
    width: 100%;
`;

const ImageContainer = styled.div`
    display: flex;
    height: 64px;
    margin-bottom: 10px;
    position: relative;
    width: 64px;
`;

const MessageText = styled.span`
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.01rem;
    line-height: 18px;
    min-height: 18px;
    text-align: center;
    width: 100%;
`;

export default forwardRef((p, ref) => {
    const [loadingData, setLoadingData] = useState({
        show: false,
        text: `${i18next.t('Loading')}...`,
    });

    useImperativeHandle(ref, () => ({
        openLoading(text = `${i18next.t('Loading')}...`) {
            document.body.style.overflowY = 'hidden';
            setLoadingData({ text, show: true });
        },
        closeLoading() {
            document.body.style.overflowY = 'auto';
            setLoadingData({ text: '', show: false });
        },
    }));

    return (
        <Wrapper visible={loadingData.show}>
            <Container>
                <ImageContainer>
                    <LogoImage1 src={isotypeSunwise1} />
                    <LogoImage2 src={isotypeSunwise2} />
                </ImageContainer>
                <MessageText>{loadingData.text}</MessageText>
            </Container>
        </Wrapper>
    );
});
