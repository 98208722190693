import isNil from 'lodash/isNil';
import { array, object, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(({ REQUIRED_TEXT }) =>
    object().shape({
        title: string().required(REQUIRED_TEXT).nullable(),
        country: string().required(REQUIRED_TEXT).nullable(),
        category: string().required(REQUIRED_TEXT).nullable(),
        languages: array()
            .min(1, REQUIRED_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        company_id: string().when('id', (value, schema) => {
            if (isNil(value)) {
                return schema.required(REQUIRED_TEXT).nullable();
            }
            return schema.nullable();
        }),
        custom_offer_template: string().when('id', (value, schema) => {
            if (isNil(value)) {
                return schema.required(REQUIRED_TEXT).nullable();
            }
            return schema.nullable();
        }),
    })
);
