import { getFamilies } from 'common/api/productsFamilies';

import {
    FETCH_BRAND_DETAIL_FAILURE,
    FETCH_FAMILIES,
    FETCH_FAMILIES_SUCCESS,
} from '../actionTypes';

import setFamiliesFilters from './setFamiliesFilters';

export default (id = '', page = 1, orderBy = '', sortBy = '', searchStr = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_FAMILIES });
        dispatch(setFamiliesFilters({ id, page, orderBy, sortBy, searchStr }));

        getFamilies(id, page, orderBy, sortBy, searchStr)
            .then((response) =>
                dispatch({
                    type: FETCH_FAMILIES_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_BRAND_DETAIL_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_FAMILIES_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
