import createAccessory from './createAccessory';
import updateAccessory from './updateAccessory';

export default (filterData, values) => (dispatch) => {
    let newValues = {
        general: {
            brand: values.brand,
            family: values.family,
            type: values.product_type,
            costing_unit: values.costing_unit,
            name: values.name,
            code: values.code,
            cost: values.cost,
        },
        thumbnail: {
            image_thumbnail: values.image_thumbnail,
        },
        datasheet: {
            datasheet_archive: values.datasheet_archive,
            datasheet_name: values.datasheet_name,
        },
    };

    if (values.id) dispatch(updateAccessory(filterData, newValues, values.id));
    else dispatch(createAccessory(filterData, newValues));
};
