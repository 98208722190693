import React from 'react';

import PropTypes from 'prop-types';

import { Stack } from 'common/ui';

import ItemPermission from './ItemPermission';
import SectionPermission from './SectionPermission';

const ConfigurationPermissions = ({
    changeInput,
    configurationPermissions,
    formValues,
    isDisabled,
}) => {
    return (
        <Stack pt={2} spacing={3} p={3}>
            {configurationPermissions.map((category) => (
                <SectionPermission
                    categories={configurationPermissions}
                    category={category}
                    changeInput={changeInput}
                    checksNames={[
                        'configuration_groups_view',
                        'configuration_groups_add',
                        'configuration_groups_edit',
                        'configuration_groups_delete',
                    ]}
                    formValues={formValues}
                    index={configurationPermissions.indexOf(category)}
                    isDisabled={isDisabled}
                    key={`section-permission-${category.id}`}
                    label={category.name}
                >
                    {category.groups.map((group) => (
                        <ItemPermission
                            categories={configurationPermissions}
                            changeInput={changeInput}
                            checksNames={[
                                'configuration_groups_view',
                                'configuration_groups_add',
                                'configuration_groups_edit',
                                'configuration_groups_delete',
                            ]}
                            formValues={formValues}
                            group={group}
                            index={category.groups.indexOf(group)}
                            isDisabled={isDisabled}
                            key={`item-permission-${group.id}`}
                            label={`${group.name}`}
                        />
                    ))}
                </SectionPermission>
            ))}
        </Stack>
    );
};

ConfigurationPermissions.propTypes = {
    changeInput: PropTypes.func,
    configurationPermissions: PropTypes.array,
    formValues: PropTypes.object,
    isDisabled: PropTypes.bool,
};

export default ConfigurationPermissions;
