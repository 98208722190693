import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors, TextVariant } from 'common/components';
import CardContainer from 'common/components/CardContainer';
import {
    ReduxFieldAutocomplete,
    ReduxFieldInput,
    ReduxFieldNumberInput,
    ReduxFieldSelect,
} from 'common/components/form/redux';
import productDatasheet from 'common/modules/productDatasheet';
import * as productDatasheetSelectors from 'common/modules/productDatasheet/selectors';
import productDatasheetAIReader from 'common/modules/productDatasheetAIReader';
import productThumbnail from 'common/modules/productThumbnail';
import * as productThumbnailSelectors from 'common/modules/productThumbnail/selectors';
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tooltip,
} from 'common/ui';

import BatteryPlaceholder from 'resources/placeholders/Battery.svg';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const ProductForm = ({
    brands,
    changeInput,
    errors,
    families,
    fetchBrands,
    filterData,
    formValues,
    getFamilies,
    handleClickBulkItems,
    handleEditionChange,
    handleOnSave,
    handleSubmit,
    initializeForm,
    isEnabledEdition,
    isFetchingBrands,
    isFetchingDetail,
    isFetchingFamilies,
    isLoadingProductDatasheet,
    isLoadingProductThumbnail,
    isNewRecord,
    isSaving,
    isUploadingDatasheet,
    isUploadingThumbnail,
    prepareReturnCatalog,
}) => {
    const { t } = useTranslation();

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    const handleSearchFamiliesBrand = (str = '') => {
        getFamilies(formValues.brand, str);
    };

    const handleClickSave = (values) => handleOnSave(filterData, values);

    const isArchived = formValues?.status_flag === 'archived';

    const isLoading =
        isFetchingBrands ||
        isFetchingDetail ||
        isFetchingFamilies ||
        isLoadingProductDatasheet ||
        isLoadingProductThumbnail ||
        isUploadingDatasheet ||
        isUploadingThumbnail ||
        isSaving;

    return (
        <Stack spacing={3}>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title={t('Back')}>
                    <IconButton
                        onClick={() => prepareReturnCatalog(filterData)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <FormGroup>
                    <FormControlLabel
                        disabled={isLoading || isNewRecord}
                        control={
                            <Switch
                                checked={isEnabledEdition}
                                onChange={handleEditionSwitchChange}
                            />
                        }
                        label={t('Edit')}
                        labelPlacement="start"
                    />
                </FormGroup>
            </Stack>

            <productDatasheetAIReader.Container
                initializeForm={initializeForm}
                type={productDatasheetAIReader.constants.TYPE_BATTERY}
            />

            <productThumbnail.Container
                formValues={formValues}
                isEnabledEdition={isEnabledEdition}
                isFetchingDetail={isFetchingDetail}
                placeholder={BatteryPlaceholder}
            />

            <productDatasheet.Container
                isEnabledEdition={isEnabledEdition}
                isFetchingDetail={isFetchingDetail}
                productId={formValues.id}
            />

            <Box>
                <form>
                    <Stack spacing={3}>
                        <CardContainer
                            title={t('General information')}
                            isCollapsable
                            isOpen
                            loading={isFetchingDetail}
                            withDivider
                        >
                            <div>
                                <Grid container spacing={3} pt={3}>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="brand"
                                            component={ReduxFieldAutocomplete}
                                            placeholder={t('Brand', {
                                                count: 1,
                                            })}
                                            variant="outlined"
                                            required
                                            disabled={
                                                !isEnabledEdition || isLoading
                                            }
                                            options={brands}
                                            handleOnSearchChange={fetchBrands}
                                            loading={isFetchingBrands}
                                            onChange={(e) => {
                                                if (!isNil(e)) {
                                                    changeInput('family', '');
                                                    getFamilies(e, '');
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="family"
                                            component={ReduxFieldAutocomplete}
                                            placeholder={t('Family', {
                                                count: 1,
                                            })}
                                            variant="outlined"
                                            required
                                            disabled={
                                                !formValues.brand ||
                                                !isEnabledEdition ||
                                                isLoading
                                            }
                                            options={families}
                                            handleOnSearchChange={
                                                handleSearchFamiliesBrand
                                            }
                                            loading={isFetchingFamilies}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} pt={3}>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="product_type"
                                            component={ReduxFieldSelect}
                                            disabled={
                                                !isEnabledEdition || isLoading
                                            }
                                            label={t('Type')}
                                            variant="outlined"
                                            options={[
                                                {
                                                    label: t('Unit'),
                                                    optionValue: 0,
                                                },
                                                {
                                                    label: t('Kit'),
                                                    optionValue: 1,
                                                },
                                            ]}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="name"
                                            component={ReduxFieldInput}
                                            placeholder={t('Name')}
                                            variant="outlined"
                                            required
                                            disabled={
                                                !isEnabledEdition || isLoading
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} pt={3}>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="code"
                                            component={ReduxFieldInput}
                                            placeholder={t('Product code')}
                                            variant="outlined"
                                            required
                                            disabled={
                                                !isEnabledEdition || isLoading
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            name="cost"
                                            component={ReduxFieldNumberInput}
                                            placeholder={t('Cost')}
                                            variant="outlined"
                                            required
                                            disabled={
                                                !isEnabledEdition || isLoading
                                            }
                                            contentEndAdornment="USD"
                                            prefix="$"
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContainer>

                        <CardContainer
                            title={t('Electrical parameters')}
                            isCollapsable
                            isOpen
                            loading={isFetchingDetail}
                            withDivider
                        >
                            <div>
                                <Stack pt={4}>
                                    <TextVariant
                                        text={t('STC electrical parameters')}
                                        separatorLeft
                                        color="#525252"
                                        fontSize="16px"
                                        fontWeight="600"
                                    />

                                    <Grid container spacing={3} pt={3}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="storage_cap_max"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t(
                                                    'Storage Capacity'
                                                )}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="Wh"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="cycles"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t('Cycles')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="u"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} pt={3}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="P_charge_max"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t('Load Power')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="W"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="I_charge_max"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t(
                                                    'Charging current'
                                                )}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="A"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} pt={3}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="DoD"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t(
                                                    'Discharge Depth'
                                                )}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="%"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="c_nom"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t('Rated Voltage')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="V"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} pt={3}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="P_discharge_max"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t(
                                                    'Discharge Power'
                                                )}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="W"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="I_discharge_max"
                                                component={
                                                    ReduxFieldNumberInput
                                                }
                                                placeholder={t(
                                                    'Discharge current'
                                                )}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    !isEnabledEdition ||
                                                    isLoading
                                                }
                                                contentEndAdornment="A"
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </div>
                        </CardContainer>

                        <ShowErrors
                            errors={errors}
                            mt={3}
                            severity="error"
                            variant="filled"
                        />

                        {isEnabledEdition && (
                            <Grid container mt={1}>
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        display: 'flex',
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row',
                                        },
                                        justifyContent: {
                                            xs: 'center',
                                            md: 'right',
                                        },
                                        gap: 2,
                                    }}
                                >
                                    {!isNewRecord && (
                                        <>
                                            <Button
                                                color="secondary"
                                                disabled={isLoading}
                                                fullWidth={false}
                                                onClick={() =>
                                                    handleClickBulkItems(
                                                        [formValues?.id],
                                                        'archived',
                                                        filterData,
                                                        () =>
                                                            changeInput(
                                                                'status_flag',
                                                                'archived'
                                                            )
                                                    )
                                                }
                                                sx={{
                                                    width: {
                                                        xs: '100%',
                                                        md: 'auto',
                                                    },
                                                    order: { xs: 2, md: 1 },
                                                    mr: { xs: 0, md: 2 },
                                                }}
                                                type="button"
                                                variant="text"
                                                visible={!isArchived}
                                            >
                                                {t('Archive')}
                                            </Button>
                                            <Button
                                                color="secondary"
                                                disabled={isLoading}
                                                fullWidth={false}
                                                onClick={() =>
                                                    handleClickBulkItems(
                                                        [formValues?.id],
                                                        'active',
                                                        filterData,
                                                        () =>
                                                            changeInput(
                                                                'status_flag',
                                                                'active'
                                                            )
                                                    )
                                                }
                                                sx={{
                                                    width: {
                                                        xs: '100%',
                                                        md: 'auto',
                                                    },
                                                    order: { xs: 2, md: 1 },
                                                    mr: { xs: 0, md: 2 },
                                                }}
                                                type="button"
                                                variant="text"
                                                visible={isArchived}
                                            >
                                                {t('Restore')}
                                            </Button>
                                            <Button
                                                color="error"
                                                disabled={isLoading}
                                                fullWidth={false}
                                                onClick={() =>
                                                    handleClickBulkItems(
                                                        [formValues?.id],
                                                        'deleted',
                                                        filterData,
                                                        () =>
                                                            prepareReturnCatalog()
                                                    )
                                                }
                                                sx={{
                                                    width: {
                                                        xs: '100%',
                                                        md: 'auto',
                                                    },
                                                    order: { xs: 2, md: 1 },
                                                    mr: { xs: 0, md: 2 },
                                                }}
                                                type="button"
                                                variant="text"
                                                visible={isArchived}
                                            >
                                                {t('Delete')}
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        disabled={isLoading}
                                        fullWidth={false}
                                        onClick={handleSubmit(handleClickSave)}
                                        sx={{
                                            width: { xs: '100%', md: 'auto' },
                                            order: { xs: 1, md: 2 },
                                            mb: { xs: 2, md: 0 },
                                        }}
                                        type="button"
                                        variant="outlined"
                                        visible={!isArchived}
                                    >
                                        {isSaving
                                            ? t('Saving').concat('...')
                                            : t('Save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Stack>
                </form>
            </Box>
        </Stack>
    );
};

ProductForm.propTypes = {
    brands: PropTypes.array,
    changeInput: PropTypes.func,
    errors: PropTypes.array,
    families: PropTypes.array,
    fetchBrands: PropTypes.func,
    filterData: PropTypes.object,
    formValues: PropTypes.object,
    getFamilies: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleEditionChange: PropTypes.func,
    handleOnSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    initializeForm: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isFetchingBrands: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isFetchingFamilies: PropTypes.bool,
    isLoadingProductDatasheet: PropTypes.bool,
    isLoadingProductThumbnail: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    isUploadingDatasheet: PropTypes.bool,
    isUploadingThumbnail: PropTypes.bool,
    prepareReturnCatalog: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    brands: selectors.getBrandsOptionsForSelect,
    errors: selectors.getErrorsSave,
    families: selectors.getFamiliesOptionsForSelect,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isEnabledEdition: selectors.getEnabledEdition,
    isFetchingBrands: selectors.getIsFetchingBrands,
    isFetchingDetail: selectors.getIsFetchingBatteryDetail,
    isFetchingFamilies: selectors.getIsFetchingFamilies,
    isLoadingProductDatasheet:
        productDatasheetSelectors.getIsLoadingProductDatasheet,
    isLoadingProductThumbnail:
        productThumbnailSelectors.getIsLoadingProductThumbnail,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
    isUploadingDatasheet: productDatasheetSelectors.getIsUploadingDatasheet,
    isUploadingThumbnail: productThumbnailSelectors.getIsUploadingThumbnail,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    fetchBrands: (str) => dispatch(actions.fetchBrands(str)),
    getFamilies: (id, str) => dispatch(actions.fetchFamilies(id, str)),
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    handleOnSave: (filterData, values) =>
        dispatch(actions.save(filterData, values)),
    initializeForm: (data) => dispatch(actions.initializeForm(data)),
    prepareReturnCatalog: (filterData) =>
        dispatch(actions.returnCatalog(filterData)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(ProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
