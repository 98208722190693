import styled from 'styled-components';

import { Typography } from 'common/ui';

export default styled(Typography)`
    font-size: 26px;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
`;
