import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MenuPopoverList } from 'common/components';
import { Typography } from 'common/ui';

const LanguageFormatter = ({ row }) => {
    const { t } = useTranslation();
    const languages = get(row, 'languages', []);
    const languagesForPopoverList = !isEmpty(languages)
        ? languages.map((i) => {
              return {
                  id: i.key,
                  name: i.value,
              };
          })
        : [];

    if (languages.length === 0) {
        return (
            <Typography
                key={`${row.id}-language`}
                variant="subtitle2"
                sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                title={t('No language')}
            >
                {t('No language')}
            </Typography>
        );
    }
    if (languages.length === 1) {
        return (
            <Typography
                key={`${row.id}-language`}
                variant="subtitle2"
                sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                title={languages[0].value}
            >
                {languages[0].value}
            </Typography>
        );
    }
    return (
        <MenuPopoverList
            items={languagesForPopoverList}
            keyName={`${row.id}-languages`}
            titleList={t('Multi language')}
            titleMenu={t('Language', { count: 2 })}
        />
    );
};

LanguageFormatter.propTypes = {
    cell: PropTypes.string,
    row: PropTypes.object,
};

const LanguageFormatterBuild = (_, row) => {
    return <LanguageFormatter row={row} />;
};

export default LanguageFormatterBuild;
