import i18next from 'i18next';
import { get, isEmpty, isNull } from 'lodash';

import { PERMISSION_LEVELS } from 'common/constants/permissionsV2';

import { ROLE_TYPES } from './constants';

export const getHaveSelectedPermissions = (permissions) => {
    if (isEmpty(permissions)) return false;
    const enabledPermissions = permissions.reduce((acc, item) => {
        if (item.permission_checked) acc = acc + 1;
        return acc;
    }, 0);
    return enabledPermissions > 0 || enabledPermissions === permissions?.length;
};

const getPermissions = (groups) => {
    return groups.map((item) => ({
        id: item.id,
        name: item.name,
        permission_checked: item?.permission_level !== PERMISSION_LEVELS.NONE,
    }));
};

export const getRoleName = (type) => {
    if (type?.key === ROLE_TYPES.CUSTOM) return i18next.t('Custom');
    return i18next.t('Admin');
};

export const getRoleOptions = (roles) => {
    const options = roles.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    return [
        {
            disabled: true,
            label: i18next.t('Select a rol'),
            value: '',
        },
        ...options,
    ];
};

export const handleChangeRole = ({ selectedRole, roleId, setValue }) => {
    setValue('roleType', get(selectedRole, 'type.key', null));
    if (
        isNull(roleId) &&
        get(selectedRole, 'type.key', null) === ROLE_TYPES.CUSTOM
    ) {
        const permissions = getPermissions(get(selectedRole, 'groups', []));
        setValue('permissions', permissions);
    }
};

export const handleCheckedPermissions = (permissions) => {
    let filtered = permissions.filter(
        ({ permission_checked }) => permission_checked
    );
    return filtered.map((item) => item.id);
};

export const handleSelectedPermissions = (permissions, selectedRole) => {
    const selectedPermissionsIds = permissions.map(
        (permission) => permission.group
    );
    return get(selectedRole, 'groups', []).map((item) => ({
        id: item.id,
        name: item.name,
        permission_checked: selectedPermissionsIds.includes(item?.id),
    }));
};
