import React from 'react';

import { useTranslation } from 'react-i18next';

import { HeaderContainer, TitleIcon, TabsSystem } from 'common/components';
import { Container, Typography } from 'common/ui';

import filterFilesLisa from '../filterFilesLisa';
import filterFilesScrapper from '../filterFilesScrapper';

const FilterFileContainer = () => {
    const { t } = useTranslation();

    const tabs = [
        {
            id: 'scrapper',
            label: 'Scrapper',
            content: <filterFilesScrapper.Container />,
        },
        {
            id: 'lisa',
            label: 'LISA',
            content: <filterFilesLisa.Container />,
        },
    ];

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon title={t('Filter files')} />
                </Typography>
            </HeaderContainer>

            <TabsSystem baseUrl="ai/filter-files" tabs={tabs} />
        </Container>
    );
};

export default FilterFileContainer;
