import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';
import { Box, Link, Tooltip, Typography } from 'common/ui';
import { getStatusProposalLabel } from 'common/utils/helpers';

const GENERATED_STATUS = 1;

import ProposalStatusIcon from './ProposalStatusIcon';

const ProductColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Proposal'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                if (row.status === GENERATED_STATUS)
                    return (
                        <Link
                            href={`${process.env.REACT_APP_SUNPAL_URL}/interactive/${row.id}?count-visit=0`}
                            underline="hover"
                            sx={{ textAlign: 'left', color: '#424242', py: 1 }}
                            target="_blank"
                        >
                            <Typography fontWeight="bold" variant="caption">
                                {row.parent.name}
                            </Typography>
                        </Link>
                    );
                return (
                    <Typography fontWeight="bold" variant="caption">
                        {row.parent.name}
                    </Typography>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            editable: true,
            field: 'project',
            flex: 1,
            headerName: t('Project'),
            minWidth: 250,
            renderCell: (params) => {
                const { row } = params;
                const title = row?.project?.status_project?.name || '';
                const color = row?.project?.status_project?.primary_color || '';
                return (
                    <Box alignItems="center" display="flex" gap={1}>
                        <Tooltip title={title}>
                            <CircleIcon
                                sx={{
                                    color,
                                    fontSize: 15,
                                }}
                            />
                        </Tooltip>
                        <Typography variant="caption">
                            {row?.project.name}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            align: 'left',
            field: 'render_status',
            flex: 1,
            headerName: t('Status'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                const isGenerated = row?.status === GENERATED_STATUS;
                return (
                    <Box alignItems="center" display="flex" gap={1}>
                        <ProposalStatusIcon isGenerated={isGenerated} />
                        <Typography variant="caption">
                            {getStatusProposalLabel(isGenerated)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            align: 'left',
            field: 'contact',
            flex: 1,
            headerName: t('Contact'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.project.contact?.name}{' '}
                        {row.project.contact?.first_lastname}{' '}
                        {row.project.contact?.second_lastname}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'company',
            flex: 1,
            headerName: t('Company'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.parent.company.business_name}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'country',
            flex: 1,
            headerName: t('Country'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.parent.company.country.long_name}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'user',
            flex: 1,
            headerName: t('Created by'),
            minWidth: 180,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row.project.created_by.first_name}{' '}
                        {row.project.created_by.last_name}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'created_at',
            headerName: t('Created at'),
            minWidth: 180,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) columns = nameColumn;

    return columns;
};

ProductColumns.propTypes = {};

export default ProductColumns;
