import i18next from 'i18next';

import { deleteModel } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import {
    DELETE_MODEL,
    DELETE_MODEL_FAILURE,
    DELETE_MODEL_SUCCESS,
} from '../actionTypes';

import fetchModels from './fetchModels';

export default (id) => (dispatch) => {
    dispatch({ type: DELETE_MODEL });

    deleteModel(id)
        .then((response) => {
            dispatch({
                payload: response?.data,
                type: DELETE_MODEL_SUCCESS,
            });
            dispatch(fetchModels());
        })
        .catch((error) => {
            dispatch({
                payload: error?.response?.data?.detail,
                type: DELETE_MODEL_FAILURE,
            });

            showToast({
                autoClose: 3000,
                body: i18next.t('An error occurred while deleting the model'),
                type: 'error',
            });
        });
};
