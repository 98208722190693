import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Chip, TextField } from 'common/ui';

const SelectTags = ({
    disabled,
    error,
    onChange,
    options = [],
    value: inputValue,
}) => {
    const { t } = useTranslation();
    const onChangeAction = (newValue) => onChange(newValue);

    return (
        <Autocomplete
            blurOnSelect
            multiple
            disabled={disabled}
            getOptionDisabled={(option) =>
                option?.disabled || option.value === ''
            }
            getOptionLabel={(option) => option?.label || ''}
            isOptionEqualToValue={(option, value) =>
                option.value === value?.value
            }
            onChange={(_, newValue) => onChangeAction(newValue)}
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('Tags')}
                    variant="outlined"
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={`${option.value}-${option.label}`}>
                    {option.label}
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={index}
                        label={option.label}
                        sx={{
                            backgroundColor: option?.color,
                            '& .MuiChip-label': {
                                color: 'white',
                                fontWeight: 'bold',
                                mixBlendMode: 'difference',
                            },
                        }}
                        {...getTagProps({ index })}
                    />
                ))
            }
            value={inputValue || []}
        />
    );
};

SelectTags.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.object,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.array,
};

export default SelectTags;
