import { getProviders } from 'common/api/afterSales/providers';

import {
    FETCH_PROVIDERS,
    FETCH_PROVIDERS_FAILURE,
    FETCH_PROVIDERS_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_PROVIDERS });

    getProviders()
        .then((response) =>
            dispatch({
                payload: response?.data,
                type: FETCH_PROVIDERS_SUCCESS,
            })
        )
        .catch((error) =>
            dispatch({ payload: error, type: FETCH_PROVIDERS_FAILURE })
        );
};
