import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import GroupItemSwitch from './GroupItemSwitch';

const ReduxFieldArray = ({ control, disabled }) => {
    const { fields } = useFieldArray({
        control,
        name: 'permissions',
    });

    return fields.map((item, index) => (
        <GroupItemSwitch
            control={control}
            disabled={disabled}
            index={index}
            item={item}
            key={`group-${index}`}
            name={`permissions.${index}.permission_checked`}
        />
    ));
};

ReduxFieldArray.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
};

export default ReduxFieldArray;
