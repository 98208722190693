import { fetchPDFFILES } from 'common/api/v1/parameters';

import {
    FETCH_FILES,
    FETCH_FILES_FAILURE,
    FETCH_FILES_SUCCESS,
} from '../actionTypes';

export default (filter) => (dispatch) => {
    dispatch({ type: FETCH_FILES });

    const {
        country,
        dp1,
        month,
        orderBy,
        page = 0,
        pageSize,
        rate,
        sortBy,
        year,
    } = filter;

    fetchPDFFILES({
        country: country === 'all' ? null : country,
        dp1: dp1 === 'all' ? null : dp1,
        month: month === 'all' ? null : month,
        orderBy,
        page: page + 1,
        perPage: pageSize,
        rate: rate === 'all' ? null : rate,
        sortBy,
        year: year === 'all' ? null : year,
    })
        .then((response) =>
            dispatch({ type: FETCH_FILES_SUCCESS, payload: response.data })
        )
        .catch((error) => {
            dispatch({
                type: FETCH_FILES_FAILURE,
                payload: error.response.data.errors,
            });

            dispatch({ type: FETCH_FILES_SUCCESS, payload: { data: [] } });
        });
};
