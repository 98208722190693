import styled from 'styled-components';

import { media } from 'common/utils/mixins';

export default styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 42px;

    ${media.md` padding-left: 86px; `}
`;
