import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AppBar, Box, Card, Container, Tab, Tabs } from 'common/ui';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
}));

function TabPanel(props) {
    const { children, index, value, ...other } = props;

    return (
        <div
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        padding: '28px 0 16px 0',
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return { id: `tab-${index}` };
};

const TabSystem = ({
    background = '',
    baseUrl,
    disableGutters = true,
    match,
    redirect,
    setCurrentTabId = null,
    setCurrentTabLabel = null,
    tabs = [],
    withoutShadow,
}) => {
    if (isEmpty(tabs)) return null;

    const [value, setValue] = useState(0);

    useEffect(() => {
        if (match.params.tab && tabs.length > 0) {
            const index = tabs.findIndex((t) => t.id === match.params.tab);

            if (index != -1) {
                setValue(index);
                if (setCurrentTabId) setCurrentTabId(tabs[index].id);
                if (setCurrentTabLabel) setCurrentTabLabel(tabs[index].label);
            }
        }
    }, [match.params.tab]);

    const handleChange = (_, newValue) => {
        setValue(newValue);
        if (setCurrentTabId) setCurrentTabId(tabs[newValue].id);
        if (setCurrentTabLabel) setCurrentTabLabel(tabs[newValue].label);
        redirect(`/${baseUrl}/${tabs[newValue].id}`);
    };
    return (
        <Container maxWidth="" disableGutters={disableGutters}>
            <Card
                sx={{
                    bgcolor: background,
                    boxShadow: withoutShadow
                        ? (theme) => theme.customShadows.z1
                        : (theme) => theme.customShadows.z12,
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <StyledAppBar position="static">
                        <Tabs
                            allowScrollButtonsMobile
                            indicatorColor="primary"
                            onChange={handleChange}
                            scrollButtons
                            sx={{
                                '& .MuiTabs-indicator': {
                                    height: '3px',
                                },
                            }}
                            textColor="inherit"
                            variant={
                                useMediaQuery((theme) =>
                                    theme.breakpoints.down('md')
                                )
                                    ? 'scrollable'
                                    : 'fullWidth'
                            }
                            value={value}
                        >
                            {tabs.map((tab, index) => (
                                <Tab
                                    key={`tab-item-${index}`}
                                    label={tab.label}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                    {...a11yProps(index)}
                                />
                            ))}
                        </Tabs>
                    </StyledAppBar>
                </Box>

                {tabs.map((tab, index) => (
                    <TabPanel
                        index={index}
                        key={`tab-panel-${index}`}
                        value={value}
                    >
                        {tab.content}
                    </TabPanel>
                ))}
            </Card>
        </Container>
    );
};

TabSystem.propTypes = {
    background: PropTypes.string,
    baseUrl: PropTypes.string,
    disableGutters: PropTypes.bool,
    match: PropTypes.object,
    redirect: PropTypes.func,
    setCurrentTabId: PropTypes.func,
    setCurrentTabLabel: PropTypes.func,
    tabs: PropTypes.array,
    withoutShadow: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (link) => dispatch(push(link)),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(TabSystem));
