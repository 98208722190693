import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from 'common/ui';
import { scroll } from 'common/utils/mixins';

import ReduxFieldArray from './ReduxFieldArray';

const PermissionWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: scroll;
    padding-right: 8px;
    width: 100%;
    ${scroll.custom}
`;

const PermissionList = ({ control, isDisabled, show }) => {
    const { t } = useTranslation();
    if (!show) return null;
    return (
        <Box mt={2}>
            <Typography fontWeight="bold" variant="title">
                {t('Permission', { count: 2 })}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <PermissionWrapper>
                <ReduxFieldArray control={control} disabled={isDisabled} />
            </PermissionWrapper>
        </Box>
    );
};

PermissionList.propTypes = {
    control: PropTypes.object,
    isDisabled: PropTypes.bool,
    show: PropTypes.bool,
};

export default PermissionList;
