import { filterRenewals } from 'common/api/companies';

import {
    FETCH_RENEWALS,
    FETCH_RENEWALS_FAILURE,
    FETCH_RENEWALS_SUCCESS,
} from '../actionTypes';

export default (filterData) => (dispatch) => {
    dispatch({ type: FETCH_RENEWALS });

    const {
        endDate,
        orderBy,
        page = 0,
        pageSize,
        queryRange,
        searchText,
        sortBy,
        startDate,
        status,
        usabilityOperator,
        usabilityValue,
    } = filterData;

    filterRenewals({
        endDate,
        orderBy,
        page: page + 1,
        perPage: pageSize,
        queryRange,
        searchText,
        sortBy,
        startDate,
        status,
        usabilityOperator,
        usabilityValue,
    })
        .then((response) =>
            dispatch({
                type: FETCH_RENEWALS_SUCCESS,
                payload: response.data,
            })
        )
        .catch((error) => {
            dispatch({
                type: FETCH_RENEWALS_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
