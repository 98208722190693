import React from 'react';

import PropTypes from 'prop-types';

import { FormControlLabel, Switch } from 'common/ui';

const _Switch = ({ checked, disabled, label, onChange, visible, ...rest }) => {
    if (!visible) return null;
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    sx={{
                        '& .MuiSwitch-switchBase.Mui-disabled': {
                            color: '#757575',
                        },
                    }}
                />
            }
            label={label}
            {...rest}
        />
    );
};

_Switch.defaultProps = {
    visible: true,
};

_Switch.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    visible: PropTypes.bool,
};

export default _Switch;
