import { INITIALIZE_FORM } from '../actionTypes';

import setIsOpenModal from './setIsOpenModal';

export default (values) => (dispatch) => {
    const _values = {
        complementary_prompt: values.complementary_prompt,
        files: values.file,
        id: values.id,
        prompt: values.prompt,
        tags: values.tags.map((tag) => ({
            color: tag.color,
            label: tag.name,
            value: tag.id,
        })),
    };

    dispatch(setIsOpenModal(true));
    dispatch({ type: INITIALIZE_FORM, payload: _values });
};
