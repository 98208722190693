import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import updateCompanySubscription from './updateCompanySubscription';

export default (companyId) => (dispatch) => {
    const dataAlert = {
        messages: [
            i18next.t('Are you sure to update your subscription information?'),
        ],
        onSuccess: () => dispatch(updateCompanySubscription(companyId)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
