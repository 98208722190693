import React, { useRef } from 'react';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    AutocompleteField,
    InputSearch,
    StatusFilterSelect,
} from 'common/components';
import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'common/ui';

import { getCompaniesOptions } from '../helpers';

import CustomDatePicker from './CustomDatePicker';

const DEBOUNCE_TIME = 500;

const BulkOperationsCard = ({
    companies,
    fetchCompanies,
    filterData,
    handleOnChangeFilter,
}) => {
    const { t } = useTranslation();

    const handleOnFilter = useRef(
        debounce((search) => fetchCompanies({ search }), DEBOUNCE_TIME)
    ).current;

    return (
        <Card>
            <CardContent>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid item xs={18}>
                        <InputSearch
                            onChange={(e) =>
                                handleOnChangeFilter(
                                    e.target.value,
                                    'searchText'
                                )
                            }
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid item xs>
                        <CustomDatePicker
                            inputFormat="dd/MM/yyyy"
                            label={t('Initial date')}
                            maxDate={new Date()}
                            name="startDate"
                            onChange={(value) =>
                                handleOnChangeFilter(value, 'startDate')
                            }
                            value={filterData?.startDate}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs>
                        <CustomDatePicker
                            inputFormat="dd/MM/yyyy"
                            label={t('Final date')}
                            maxDate={new Date()}
                            name="endDate"
                            onChange={(value) =>
                                handleOnChangeFilter(value, 'endDate')
                            }
                            value={filterData?.endDate}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={18} md>
                        <AutocompleteField
                            label={t('Filter by company')}
                            onInputChange={(value) => {
                                handleOnFilter(value);
                            }}
                            onChange={(id) =>
                                handleOnChangeFilter(
                                    id === '' || !id ? 'all' : id,
                                    'companyId'
                                )
                            }
                            options={getCompaniesOptions(companies)}
                            value={filterData?.companyId}
                        />
                    </Grid>

                    <Grid item xs={18} md>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Filter by status')}</InputLabel>
                            <Select
                                label={t('Filter by status')}
                                onChange={(e) =>
                                    handleOnChangeFilter(
                                        e.target.value,
                                        'status'
                                    )
                                }
                                value={filterData?.status}
                                variant="standard"
                            >
                                {[
                                    {
                                        label: t('All text', { count: 2 }),
                                        value: 'all',
                                    },
                                    { label: t('Draft'), value: 'draft' },
                                    {
                                        label: t('Generated'),
                                        value: 'generated',
                                    },
                                ].map(({ label, value, disabled }) => (
                                    <MenuItem
                                        disabled={disabled}
                                        key={`company-select-${value}-${label}`}
                                        value={value}
                                    >
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={18} md>
                        <StatusFilterSelect
                            filterBy={filterData?.isArchived}
                            handleSelected={(value) =>
                                handleOnChangeFilter(value, 'isArchived')
                            }
                            variant="standard"
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    companies: PropTypes.array,
    fetchCompanies: PropTypes.func,
    filterData: PropTypes.object,
    handleOnChangeFilter: PropTypes.func,
};

export default BulkOperationsCard;
