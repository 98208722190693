import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomPagination } from 'common/components';
import { DataGrid } from 'common/ui';
import { getDataGridClasses } from 'common/utils/helpers';

const BasicDataGrid = ({
    columns,
    isLoading,
    onPageChange,
    onPageSizeChange,
    onRowClick,
    page,
    pageSize,
    paginationMode = 'client',
    rowCount,
    rows,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    return (
        <DataGrid
            autoHeight
            columns={columns ?? []}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            componentsProps={{
                pagination: { rowsPerPageOptions: [25, 50, 75, 100] },
            }}
            disableColumnMenu
            disableSelectionOnClick
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            getRowHeight={() => 'auto'}
            initialState={{ pagination: { pageSize: pageSize || 25 } }}
            loading={isLoading}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowClick={(params) => onRowClick?.(params.row)}
            page={page}
            pageSize={pageSize}
            pagination
            paginationMode={paginationMode}
            rowCount={rowCount}
            rows={rows ?? []}
            sx={{
                ...getDataGridClasses(isDarkMode),
                '& .MuiDataGrid-cell': { cursor: 'pointer' },
            }}
        />
    );
};

BasicDataGrid.propTypes = {
    columns: PropTypes.array,
    isLoading: PropTypes.bool,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onRowClick: PropTypes.func,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    paginationMode: PropTypes.string,
    rowCount: PropTypes.number,
    rows: PropTypes.array,
};

export default BasicDataGrid;
