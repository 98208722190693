import { filterProposals } from 'common/api/proposals';
import { formatDate, parseDate } from 'common/utils/dates';

import {
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FAILURE,
    FETCH_PROPOSALS_SUCCESS,
} from '../actionTypes';
import { proposalsActions } from '../reducer';

export default (filterData) => (dispatch) => {
    dispatch(proposalsActions[FETCH_PROPOSALS]());

    const {
        companyId,
        endDate,
        orderBy,
        page,
        pageSize,
        searchText,
        sortBy,
        startDate,
        isArchived,
        status,
    } = filterData;

    filterProposals({
        company_id: companyId === 'all' ? null : companyId,
        end_date: formatDate(parseDate(endDate, 'dd/MM/yyyy'), 'yyyy-MM-dd'),
        is_archived: isArchived === 'active' ? 0 : 1,
        order_by: orderBy,
        page: page + 1,
        perPage: pageSize,
        search: searchText === '' ? null : searchText,
        sort: sortBy,
        start_date: formatDate(
            parseDate(startDate, 'dd/MM/yyyy'),
            'yyyy-MM-dd'
        ),
        status: status === 'all' ? null : status === 'generated' ? 1 : 0,
    })
        .then((response) =>
            dispatch(proposalsActions[FETCH_PROPOSALS_SUCCESS](response.data))
        )
        .catch((error) =>
            dispatch(
                proposalsActions[FETCH_PROPOSALS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
