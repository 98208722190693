import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AutocompleteField } from 'common/components';
import { Button, Card, CardContent, FormControl, Grid } from 'common/ui';

import { getMonths, getYears } from '../helpers';

const BulkOperationsCard = ({
    countries,
    countriesIsFetching,
    downloadBulk,
    filterData,
    handleClickOnFilter,
    handleOnChangeFilter,
    politicalDivisions,
    politicalDivisionsIsFetching,
    ratesIsFetching,
    ratesOptions,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();
    const callback = () => {
        setSelectionModel(() => {
            return [];
        });
    };
    const handleBulkDownload = (selectionModel) => {
        downloadBulk(selectionModel, callback);
    };

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid
                    alignItems="end"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item lg={2} md={4} xs={12}>
                        <AutocompleteField
                            disabled={countriesIsFetching}
                            label={t('Country')}
                            onChange={(value) =>
                                handleOnChangeFilter(value, 'country')
                            }
                            options={countries}
                            value={filterData?.country || 'all'}
                        />
                    </Grid>
                    <Grid item lg={2} md={4} xs={12}>
                        <FormControl fullWidth size="small">
                            <AutocompleteField
                                disabled={ratesIsFetching}
                                label={t('Rate_one')}
                                onChange={(value) =>
                                    handleOnChangeFilter(value, 'rate')
                                }
                                options={ratesOptions}
                                value={filterData?.rate || 'all'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} md={4} xs={12}>
                        <AutocompleteField
                            disabled={politicalDivisionsIsFetching}
                            label={t('Political division')}
                            onChange={(value) =>
                                handleOnChangeFilter(value, 'dp1')
                            }
                            options={politicalDivisions}
                            value={filterData?.dp1 || 'all'}
                        />
                    </Grid>
                    <Grid item lg={2} md={4} xs={12}>
                        <AutocompleteField
                            label={t('Year_one')}
                            onChange={(value) =>
                                handleOnChangeFilter(value, 'year')
                            }
                            options={getYears()}
                            value={filterData?.year || 'all'}
                        />
                    </Grid>
                    <Grid item lg={2} md={4} xs={12}>
                        <AutocompleteField
                            label={t('Month_one')}
                            onChange={(value) =>
                                handleOnChangeFilter(value, 'month')
                            }
                            options={getMonths()}
                            value={filterData?.month || 'all'}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item>
                        <Button onClick={handleClickOnFilter}>
                            {t('Filter')}
                        </Button>
                    </Grid>

                    {!isEmpty(selectionModel) && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                onClick={() =>
                                    handleBulkDownload(selectionModel)
                                }
                            >
                                {t('Download')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    countries: PropTypes.array,
    countriesIsFetching: PropTypes.bool,
    downloadBulk: PropTypes.func,
    filterData: PropTypes.object,
    handleClickOnFilter: PropTypes.func,
    handleOnChangeFilter: PropTypes.func,
    politicalDivisions: PropTypes.array,
    politicalDivisionsIsFetching: PropTypes.bool,
    ratesIsFetching: PropTypes.bool,
    ratesOptions: PropTypes.array,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
