import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';

import { Tooltip } from 'common/ui';

const UsabilityFormatter = ({ row }) => {
    let color = '#dddddd';
    if (row.usability <= -25) {
        color = '#f44336';
    } else if (row.usability >= -24 && row.usability <= 49) {
        color = '#ff9800';
    } else if (row.usability >= 50) {
        color = '#4caf50';
    }
    return (
        <Tooltip title={`${row.usability} %`}>
            <CircleIcon
                sx={{
                    color: color,
                    fontSize: 16,
                }}
            />
        </Tooltip>
    );
};

UsabilityFormatter.propTypes = {
    row: PropTypes.object,
};

export default UsabilityFormatter;
