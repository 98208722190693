import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomListItem } from 'common/components';
import { Box, Grid, List } from 'common/ui';

import { handleNormalizeValue } from '../helpers';

const GeneralInfoCard = ({ companyDetail }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <List>
                <CustomListItem
                    label={handleNormalizeValue(
                        get(companyDetail, 'business_name', null)
                    )}
                    value={handleNormalizeValue(
                        get(companyDetail, 'name', null)
                    )}
                    withAvatar
                    urlAvatar={get(companyDetail, 'corporate_image.logo', null)}
                />

                <CustomListItem
                    label={t('Email')}
                    value={handleNormalizeValue(
                        get(companyDetail, 'contact_email', null)
                    )}
                    withHorizontalDivider
                />

                <CustomListItem
                    label={t('Address')}
                    value={handleNormalizeValue(
                        get(companyDetail, 'position.description', null)
                    )}
                    withHorizontalDivider
                />

                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <CustomListItem
                            label={t('Telephone')}
                            value={handleNormalizeValue(
                                get(companyDetail, 'contact_telephone', null)
                            )}
                            withVerticalDivider
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CustomListItem
                            label={t('Tax ID')}
                            value={handleNormalizeValue(
                                get(companyDetail, 'rfc', null)
                            )}
                            withVerticalDivider
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CustomListItem
                            label={t('Active users')}
                            value={handleNormalizeValue(
                                get(companyDetail, 'active_users', null)
                            )}
                        />
                    </Grid>
                </Grid>
            </List>
        </Box>
    );
};

GeneralInfoCard.propTypes = {
    companyDetail: PropTypes.object,
};

export default GeneralInfoCard;
