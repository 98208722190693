import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getRatesModel = createSelector(getModel, (model) => model.rates);

export const getRatesData = createSelector(getRatesModel, (model) =>
    !model.data.data ? [] : model.data.data.map((rate) => ({ ...rate }))
);

export const getRatesErrors = createSelector(
    getRatesModel,
    (model) => model.errors
);

export const getRatesIsFetching = createSelector(
    getRatesModel,
    (model) => model.isFetching
);

export const getRatesPagination = createSelector(
    getRatesModel,
    (model) => model.data.pagination || {}
);

export const getCustomRatesModel = createSelector(
    getModel,
    (model) => model.customRates
);

export const getCustomRatesData = createSelector(getCustomRatesModel, (model) =>
    !model.data.data ? [] : model.data.data.map((rate) => ({ ...rate }))
);

export const getCustomRatesErrors = createSelector(
    getCustomRatesModel,
    (model) => model.errors
);

export const getCustomRatesIsFetching = createSelector(
    getCustomRatesModel,
    (model) => model.isFetching
);

export const getCustomRatesPagination = createSelector(
    getCustomRatesModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenItemForm = createSelector(
    getModel,
    (model) => model.isOpenItemForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getSaveCustomRate = createSelector(
    getModel,
    (model) => model.saveCustomRate
);

export const getIsSavingCustomRate = createSelector(
    getSaveCustomRate,
    (save) => save.isSaving
);

export const getSaveCustomRateErrors = createSelector(
    getSaveCustomRate,
    (save) => save.errors
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getRateDetail = createSelector(
    getModel,
    (model) => model.rateDetail
);

export const getIsFetchingRateDetail = createSelector(
    getRateDetail,
    (rateDetail) => rateDetail.isFetching
);

export const getCustomRateDetail = createSelector(
    getModel,
    (model) => model.customRateDetail
);

export const getCustomRateDetailData = createSelector(
    getCustomRateDetail,
    (model) => model.data
);

export const getCustomRateDetailErrors = createSelector(
    getCustomRateDetail,
    (model) => model.errors
);

export const getIsFetchingCustomRateDetail = createSelector(
    getCustomRateDetail,
    (model) => model.isFetching
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);

export const getFetchUnits = createSelector(
    getModel,
    (model) => model.fetchUnits
);

export const getIsFetchingUnits = createSelector(
    getFetchUnits,
    (fetchUnits) => fetchUnits.isFetching
);

export const getUnitsErrors = createSelector(
    getIsFetchingUnits,
    (fetchUnits) => fetchUnits.errors
);

export const getUnitsData = createSelector(
    getFetchUnits,
    (fetchUnits) => fetchUnits.data || []
);

export const getEnergyUnitsDataToSelect = createSelector(
    getUnitsData,
    (unitsData) => {
        const firstElement = {
            disabled: true,
            label: i18next.t('Select unit'),
            value: '',
        };

        let units = [];

        if (unitsData.length > 0) {
            units = unitsData
                ?.filter((item) => item.type_concept === 'Energy')
                ?.map((item) => ({
                    label: item.name,
                    value: item.name,
                }));
        }

        return [firstElement, ...units];
    }
);

export const getDemandUnitsDataToSelect = createSelector(
    getUnitsData,
    (unitsData) => {
        const firstElement = {
            disabled: true,
            label: i18next.t('Select unit'),
            value: '',
        };

        let units = [];

        if (unitsData.length > 0) {
            units = unitsData
                ?.filter((item) => item.type_concept === 'Demand')
                ?.map((item) => ({
                    label: item.name,
                    value: item.name,
                }));
        }

        return [firstElement, ...units];
    }
);

export const getOthersUnitsDataToSelect = createSelector(
    getUnitsData,
    (unitsData) => {
        const firstElement = {
            disabled: true,
            label: i18next.t('Select unit'),
            value: '',
        };

        let units = [];

        if (unitsData.length > 0) {
            units = unitsData
                ?.filter(
                    (item) =>
                        item.type_concept !== 'Demand' &&
                        item.type_concept !== 'Energy'
                )
                ?.map((item) => ({
                    label: item.name,
                    value: item.name,
                }));
        }

        return [firstElement, ...units];
    }
);

export const getFetchContractedDemandUnits = createSelector(
    getModel,
    (model) => model.fetchContractedDemandUnits
);

export const getIsFetchingContractedDemandUnits = createSelector(
    getFetchContractedDemandUnits,
    (fetchContractedDemandUnits) => fetchContractedDemandUnits.isFetching
);

export const getContractedDemandUnitsData = createSelector(
    getFetchContractedDemandUnits,
    (fetchContractedDemandUnits) => fetchContractedDemandUnits.data || []
);

export const getContractedDemandUnitsDataToSelect = createSelector(
    getContractedDemandUnitsData,
    (unitsData) => [
        {
            label: i18next.t('Select unit'),
            value: '',
            disabled: true,
        },
        ...unitsData.map((unit) => ({ label: unit.name, value: unit.name })),
    ]
);

export const getFetchSpainExcessDemandUnits = createSelector(
    getModel,
    (model) => model.fetchSpainExcessDemandUnits || {}
);

export const getIsFetchingSpainExcessDemandUnits = createSelector(
    getFetchSpainExcessDemandUnits,
    (fetchSpainExcessDemandUnits) => fetchSpainExcessDemandUnits.isFetching
);

export const getSpainExcessDemandUnitsData = createSelector(
    getFetchSpainExcessDemandUnits,
    (fetchSpainExcessDemandUnits) => fetchSpainExcessDemandUnits.data || []
);

export const getSpainExcessDemandUnitsDataToSelect = createSelector(
    getSpainExcessDemandUnitsData,
    (unitsData) => [
        {
            label: i18next.t('Select unit'),
            value: '',
            disabled: true,
        },
        ...unitsData.map((unit) => ({ label: unit.name, value: unit.name })),
    ]
);

export const getHasActiveProjects = createSelector(
    getModel,
    (model) => model.hasActiveProjects
);

export const getSupplierUtilities = createSelector(
    getModel,
    (model) => model.fetchSupplierUtilities
);

export const getIsFetchingSupplierUtilities = createSelector(
    getSupplierUtilities,
    (supplierUtilities) => supplierUtilities.isFetching
);

export const getSupplierUtilitiesData = createSelector(
    getSupplierUtilities,
    (supplierUtilities) => supplierUtilities.data || []
);

export const getSupplierUtilitiesItemsToSelect = createSelector(
    getSupplierUtilitiesData,
    (supplierUtilities) => [
        {
            label: i18next.t('Select an electrical supplier'),
            value: '',
            disabled: true,
        },
        ...supplierUtilities.map((supplierUtility) => ({
            label: supplierUtility.name,
            value: supplierUtility.id,
        })),
    ]
);

export const getSaveRate = createSelector(getModel, (model) => model.saveRate);

export const getIsSavingRate = createSelector(
    getSaveRate,
    (saveRate) => saveRate.isSaving
);

export const getSaveRateErrors = createSelector(
    getSaveRate,
    (saveRate) => saveRate.errors
);

export const getModalIsCreate = createSelector(
    getModel,
    (model) => model.modalIsCreate
);

export const getModalIsOpen = createSelector(
    getModel,
    (model) => model.modalIsOpen
);

export const getRateModalInitialValues = createSelector(
    getModel,
    (model) => model.rateModalInitialValues
);

export const getSupplierUtilitiesModalIsOpen = createSelector(
    getModel,
    (model) => model.supplierUtilitiesModalIsOpen
);

export const getFilterRates = createSelector(
    getModel,
    (model) => model.filterRates
);

export const getFilterQuery = createSelector(
    getFilterRates,
    (filterRates) => filterRates.query
);

export const getCurrenciesByCountries = createSelector(
    getModel,
    (model) => model.fetchCurrenciesByCountries
);

export const getIsFetchingCurrenciesByCountries = createSelector(
    getCurrenciesByCountries,
    (currenciesByCountries) => currenciesByCountries.isFetching
);

export const getCurrenciesByCountriesData = createSelector(
    getCurrenciesByCountries,
    (currenciesByCountries) => currenciesByCountries.data || []
);

export const getCountriesDictionary = createSelector(
    getCurrenciesByCountriesData,
    (currenciesByCountries) =>
        currenciesByCountries?.reduce((acc, curr) => {
            if (!curr?.country?.id) return acc;
            return { ...acc, [curr.country.id]: curr.country };
        }, {})
);

export const getCurrenciesByCountriesDictionary = createSelector(
    getCurrenciesByCountriesData,
    (currenciesByCountries) =>
        currenciesByCountries.reduce((acc, curr) => {
            if (!curr?.country?.id || !curr?.currency?.id) return acc;
            return { ...acc, [curr.country.id]: curr.currency.id };
        }, {})
);

export const getCountriesItemsToSelect = createSelector(
    getCurrenciesByCountriesData,
    (currenciesByCountries) => [
        {
            label: i18next.t('Select a country'),
            value: '',
            disabled: true,
        },
        ...currenciesByCountries.map((item) => {
            const country = item?.country || {};
            return { label: country.name, value: country.id };
        }),
    ]
);

export const getCurrenciesItemsToSelect = createSelector(
    getCurrenciesByCountriesData,
    (currenciesByCountries) => [
        {
            label: i18next.t('Select a currency'),
            value: '',
            disabled: true,
        },
        ...currenciesByCountries.map((item) => {
            const currency = item?.currency || {};
            return {
                label: `${currency.name} (${currency.abbreviation}) (${currency.symbol})`,
                value: currency.id,
            };
        }),
    ]
);
