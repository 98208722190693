import { object, string, number } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({
        REQUIRED_TEXT,
        NUMBER_TYPE_TEXT,
        MIN_ZERO_VALIDATION_TEXT,
        getMaxValueText,
    }) =>
        object().shape({
            brand: string().required(REQUIRED_TEXT).nullable(),
            family: string().required(REQUIRED_TEXT).nullable(),
            product_type: string().required(REQUIRED_TEXT).nullable(),
            name: string().required(REQUIRED_TEXT).nullable(),
            code: string().required(REQUIRED_TEXT).nullable(),
            cost: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .nullable(),

            num_cells: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .nullable(),
            ancho: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            alto: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            profundo: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            peso: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            max_p: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            vmp: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            imp: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            voc: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            isc: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            max_dc: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            module_e: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            tk_pmax: number()
                .typeError(NUMBER_TYPE_TEXT)
                .max(0, getMaxValueText(0))
                .required(REQUIRED_TEXT)
                .nullable(),
            tk_voc: number()
                .typeError(NUMBER_TYPE_TEXT)
                .max(0, getMaxValueText(0))
                .required(REQUIRED_TEXT)
                .nullable(),
            tk_isc: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            noct: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
        })
);
