import { NAME } from './constants';

export const FETCH_COMPANIES = `${NAME}/FETCH_COMPANIES`;
export const FETCH_COMPANIES_SUCCESS = `${NAME}/FETCH_COMPANIES_SUCCESS`;
export const FETCH_COMPANIES_FAILURE = `${NAME}/FETCH_COMPANIES_FAILURE`;

export const FETCH_PROJECTS = `${NAME}/FETCH_PROJECTS`;
export const FETCH_PROJECTS_SUCCESS = `${NAME}/FETCH_PROJECTS_SUCCESS`;
export const FETCH_PROJECTS_FAILURE = `${NAME}/FETCH_PROJECTS_FAILURE`;

export const INITIALIZE = `${NAME}/INITIALIZE`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const REWIND_PROJECT = `${NAME}/REWIND_PROJECT`;
export const REWIND_PROJECT_SUCCESS = `${NAME}/REWIND_PROJECT_SUCCESS`;
export const REWIND_PROJECT_FAILURE = `${NAME}/REWIND_PROJECT_FAILURE`;

export const SET_OPEN_REWIND_MODAL = `${NAME}/SET_OPEN_REWIND_MODAL`;
export const SET_OPEN_STATUS_MODAL = `${NAME}/SET_STATUS_MODAL`;

export const SET_PROJECT_STATUS = `${NAME}/SET_PROJECT_STATUS`;
export const SET_PROJECT_STATUS_SUCCESS = `${NAME}/SET_PROJECT_STATUS_SUCCESS`;
export const SET_PROJECT_STATUS_FAILURE = `${NAME}/SET_PROJECT_STATUS_FAILURE`;
