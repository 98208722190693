import { getCategories } from 'common/api/templatesCategories';

import {
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_FAILURE,
    FETCH_CATEGORIES_SUCCESS,
} from '../actionTypes';

import setFilters from './setFilters';

export default (page = 1, orderBy = '', sortBy = '', searchStr = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_CATEGORIES });
        dispatch(setFilters({ page, orderBy, sortBy, searchStr }));

        getCategories(page, orderBy, sortBy, searchStr)
            .then((response) =>
                dispatch({
                    type: FETCH_CATEGORIES_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_CATEGORIES_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_CATEGORIES_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
