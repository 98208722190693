import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
    DOMESTIC_RATES,
    HIGH_CONSUMPTION_DOMESTIC_RATES,
    RATES_WITH_DIVISION,
} from 'common/constants/rates';

export const isMexicanSupplierCFE = (values) => {
    if (!isEmpty(values)) {
        if (values.id && values.name && values.countryName) {
            return values.name === 'CFE' && values.countryName === 'México';
        }
    }

    return false;
};

export const getTagBySelectedRate = (rate, rates) => {
    if (!isEmpty(rates)) {
        const selectedRate = rates.find((r) => r.optionValue === rate);
        if (!isNil(selectedRate)) {
            if (DOMESTIC_RATES.includes(selectedRate.label))
                return i18next.t('Summer month');
            if (HIGH_CONSUMPTION_DOMESTIC_RATES.includes(selectedRate.label))
                return i18next.t('Region');
            if (RATES_WITH_DIVISION.includes(selectedRate.label))
                return i18next.t('Division');
        }
    }

    return i18next.t('Label');
};

export const getSelectedRateName = (rate, rates) => {
    if (!isEmpty(rates)) {
        const selectedRate = rates.find((r) => r.optionValue === rate);
        if (!isNil(selectedRate)) {
            return selectedRate.label;
        }
    }

    return '';
};
