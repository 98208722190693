import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SearchTable } from 'common/components/tables';
import { Box, Toolbar } from 'common/ui';

const StyledContainer = styled(Toolbar)({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
}));

const TableToolbar = ({ handleOnSearchChange, searchStr }) => {
    const { t } = useTranslation();

    return (
        <StyledContainer disableGutters>
            <StyledBox>
                <SearchTable
                    defaultValue={searchStr}
                    handleOnSearchChange={handleOnSearchChange}
                    placeholder={`${t('Search')}...`}
                    variant="filled"
                />
            </StyledBox>
        </StyledContainer>
    );
};

TableToolbar.propTypes = {
    handleOnSearchChange: PropTypes.func,
    searchStr: PropTypes.string,
};

export default TableToolbar;
