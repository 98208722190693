import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Box,
    Container,
    Stack,
    SvgIcon,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from 'common/ui';

import { ReactComponent as IcoBrands } from 'resources/icoBrands.svg';
import { ReactComponent as IcoProducts } from 'resources/icoProducts.svg';

import brandsCatalog from '../brandsCatalog';
import productsCatalog from '../productsCatalog';

import { BRANDS_VIEW, CATALOG_VIEW } from './constants';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButtonGroup-grouped': {
        backgroundColor: '#EEF4FA',
        borderRadius: '8px',
        border: 0,
    },
    '& .MuiToggleButton-root': {
        padding: '12px 24px',
    },
}));

const ProductContainer = ({ match, redirect }) => {
    const { t } = useTranslation();
    const [view, setView] = useState(CATALOG_VIEW);
    const [lastTab, setLastTab] = useState('');

    useEffect(() => {
        if (!isEmpty(match.params.tab)) {
            setLastTab(match.params.tab);
        }
    }, [match.params.tab]);

    useEffect(() => {
        if (match.params.section) {
            setView(match.params.section);
        }
    }, [match.params.section]);

    const handleSwitchView = (event, newView) => {
        if (newView) {
            setView(newView);

            if (newView === CATALOG_VIEW && lastTab) {
                redirect(`/products/${newView}/${lastTab}`);
            } else {
                redirect(`/products/${newView}`);
            }
        }
    };

    return (
        <Container maxWidth="">
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
            >
                <StyledToggleButtonGroup
                    color="secondary"
                    exclusive
                    onChange={handleSwitchView}
                    size="small"
                    value={view}
                >
                    <Tooltip title={t('Products catalog')}>
                        <ToggleButton
                            selected={view === CATALOG_VIEW}
                            value={CATALOG_VIEW}
                        >
                            <Box
                                component="div"
                                sx={{
                                    lineHeight: '0px',
                                    'svg .shape': {
                                        fill: (theme) =>
                                            view === CATALOG_VIEW
                                                ? theme.palette['primary'].main
                                                : '',
                                    },
                                    'svg path': {
                                        fill: (theme) =>
                                            view === CATALOG_VIEW
                                                ? theme.palette['primary'].main
                                                : '',
                                    },
                                }}
                            >
                                <SvgIcon component={IcoProducts} />
                            </Box>
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title={t('Brands catalog')}>
                        <ToggleButton
                            selected={view === BRANDS_VIEW}
                            value={BRANDS_VIEW}
                        >
                            <Box
                                component="div"
                                sx={{
                                    lineHeight: '0px',
                                    'svg .shape': {
                                        fill: (theme) =>
                                            view === BRANDS_VIEW
                                                ? theme.palette['primary'].main
                                                : '',
                                    },
                                    'svg path': {
                                        fill: (theme) =>
                                            view === BRANDS_VIEW
                                                ? theme.palette['primary'].main
                                                : '',
                                    },
                                }}
                            >
                                <SvgIcon component={IcoBrands} />
                            </Box>
                        </ToggleButton>
                    </Tooltip>
                </StyledToggleButtonGroup>
            </Stack>

            {view === CATALOG_VIEW && <productsCatalog.Container />}
            {view === BRANDS_VIEW && <brandsCatalog.Container />}
        </Container>
    );
};

ProductContainer.propTypes = {
    match: PropTypes.object,
    redirect: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (link) => dispatch(push(link)),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(ProductContainer));
