import { object, string, number } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ REQUIRED_TEXT, NUMBER_TYPE_TEXT, MIN_ZERO_VALIDATION_TEXT }) =>
        object().shape({
            brand: string().required(REQUIRED_TEXT).nullable(),
            family: string().required(REQUIRED_TEXT).nullable(),
            product_type: string().required(REQUIRED_TEXT).nullable(),
            name: string().required(REQUIRED_TEXT).nullable(),
            code: string().required(REQUIRED_TEXT).nullable(),
            cost: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, MIN_ZERO_VALIDATION_TEXT)
                .nullable(),
            ancho: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            alto: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            profundo: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            peso: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            oversizing: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            dc_max_p: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            dc_max_v: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            dc_max_i: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            mppts: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            mppt_strings: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            dc_mppt_min_v: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            dc_mppt_max_v: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            dc_mppt_max_i: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            dc_mppt_max_string_i: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            ac_max_p: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            ac_nominal_v: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            ac_min_v: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            ac_max_v: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            ac_nom_i: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            fases: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
        })
);
