import { getInvertersCompany } from 'common/api/companies';

import {
    FETCH_INVERTERS,
    FETCH_INVERTERS_SUCCESS,
    FETCH_INVERTERS_FAILURE,
} from '../actionTypes';

export default (companyId, planId) => (dispatch) => {
    dispatch({ type: FETCH_INVERTERS });

    getInvertersCompany(companyId, planId)
        .then((response) => {
            dispatch({
                type: FETCH_INVERTERS_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_INVERTERS_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
