import React from 'react';

import PropTypes from 'prop-types';

import {
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
} from 'common/ui';

const Placeholder = () => (
    <List>
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <React.Fragment>
                        <Skeleton></Skeleton>
                    </React.Fragment>
                }
                secondary={
                    <React.Fragment>
                        <Skeleton></Skeleton>
                    </React.Fragment>
                }
            />
        </ListItem>

        {[...Array(2)].map((_, index) => (
            <Grid key={Math.random() + index} container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Skeleton></Skeleton>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Skeleton></Skeleton>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Skeleton></Skeleton>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Skeleton></Skeleton>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </Grid>
            </Grid>
        ))}
    </List>
);

const PlaceholderSubscriptionInfoCard = ({ children, ready }) => {
    return <>{ready ? <Placeholder /> : children}</>;
};

PlaceholderSubscriptionInfoCard.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderSubscriptionInfoCard;
