import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
    FETCH_PROPOSALS,
    FETCH_PROPOSALS_FAILURE,
    FETCH_PROPOSALS_SUCCESS,
    RESET,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    companies: {
        data: [],
        errors: [],
        isFetching: false,
    },
    proposals: {
        data: { data: [], paginaton: {} },
        errors: [],
        isFetching: false,
    },
};

const proposalsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_COMPANIES]: (state) => {
            state.companies = {
                ...state.companies,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_COMPANIES_FAILURE]: (state, action) => {
            state.companies = {
                ...state.companies,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANIES_SUCCESS]: (state, action) => {
            state.companies = {
                ...state.companies,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS]: (state) => {
            state.proposals = {
                ...state.proposals,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PROPOSALS_FAILURE]: (state, action) => {
            state.proposals = {
                ...state.proposals,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSALS_SUCCESS]: (state, action) => {
            state.proposals = {
                ...state.proposals,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
    },
});

export const proposalsActions = proposalsSlice.actions;

export default proposalsSlice.reducer;
