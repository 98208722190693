import { getFile } from 'common/api/ocr/mediaStorage';

import {
    FETCH_FILE,
    FETCH_FILE_FAILURE,
    FETCH_FILE_SUCCESS,
} from '../actionTypes';

export default (file_name) => (dispatch) => {
    dispatch({ type: FETCH_FILE });

    getFile(file_name)
        .then((response) =>
            dispatch({ type: FETCH_FILE_SUCCESS, payload: response.data })
        )
        .catch((error) =>
            dispatch({
                type: FETCH_FILE_FAILURE,
                payload: error?.response?.data?.detail,
            })
        );
};
