import React, { useState, useEffect } from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HeaderContainer, TitleIcon } from 'common/components';
import { Container, Grid } from 'common/ui';
import { subDate, formatDate } from 'common/utils/dates';

import * as actions from './actions';
import { ListItems } from './components';
import * as selectors from './selectors';

const ProductsContainer = ({
    companies,
    fetchCompanies,
    filterProposals,
    isFetching,
    paginationData,
    proposals,
    reset,
}) => {
    const { t } = useTranslation();

    const datetime = new Date();
    const endDate = formatDate(datetime);
    const startDate = formatDate(subDate(datetime, { months: 1 }));

    const [filterData, setFilterData] = useState({
        companyId: 'all',
        endDate,
        isArchived: 'active',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        startDate,
        status: 'generated',
    });

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        fetchCompanies({ search: '' });
        filterProposals(filterData);
    }, []);

    return (
        <Container maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon
                        icon={<FolderIcon />}
                        title={t('Proposal', { count: 2 })}
                    />
                </Grid>
            </HeaderContainer>

            <ListItems
                companies={companies}
                fetchCompanies={fetchCompanies}
                filterData={filterData}
                filterItems={filterProposals}
                isFetching={isFetching}
                items={proposals}
                paginationData={paginationData}
                setFilterData={setFilterData}
            />
        </Container>
    );
};

ProductsContainer.propTypes = {
    companies: PropTypes.array,
    fetchCompanies: PropTypes.func,
    filterProposals: PropTypes.func,
    isFetching: PropTypes.bool,
    paginationData: PropTypes.object,
    proposals: PropTypes.array,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    companies: selectors.getCompaniesData,
    isFetching: selectors.getIsFetchingProposals,
    paginationData: selectors.getProposalsPagination,
    proposals: selectors.getProposalsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanies: ({ search }) =>
        dispatch(actions.fetchCompanies({ search })),
    filterProposals: (filterData) =>
        dispatch(actions.filterProposals(filterData)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
