import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DialogContainer } from 'common/components';
import { Box, Stack, Typography } from 'common/ui';

import * as actions from './actions';
import { Buttons, TextCaptcha, TextResponse } from './components';
import * as selectors from './selectors';

const Container = ({
    cancelText = '',
    confirmText = '',
    defaultTextResponse = '',
    handleClickClose,
    handleClickSuccess,
    haveTextCaptcha = false,
    haveTextResponse = false,
    hideButtons,
    isOpen,
    messages,
    size,
    textToValidate = '',
    title,
    type,
    variant,
}) => {
    const [isValidCaptcha, setIsValidCaptcha] = useState(false);
    const [textResponse, setTextResponse] = useState('');

    const renderTextCaptcha = () => {
        if (!haveTextCaptcha) return null;
        return (
            <TextCaptcha
                textToValidate={textToValidate}
                isValid={isValidCaptcha}
                onValidate={setIsValidCaptcha}
            />
        );
    };

    const renderTextResponse = () => {
        if (!haveTextResponse) return null;
        return (
            <TextResponse
                defaultTextResponse={defaultTextResponse}
                textResponse={textResponse}
                onSetTextResponse={setTextResponse}
            />
        );
    };

    const isDisabledConfirmButton = () => {
        if (haveTextCaptcha && !isValidCaptcha) return true;
        if (haveTextResponse && textResponse.trim().length === 0) return true;
        return false;
    };

    const getColorVariant = (variant) => {
        switch (variant) {
            case 'success':
                return '#00B667';
            case 'info':
                return '#008DFF';
            case 'error':
                return '#FA6968';
            case 'warning':
                return '#ff9a00';
            default:
                return '#ff9a00';
        }
    };

    return (
        <DialogContainer
            onClose={handleClickClose}
            open={isOpen}
            maxWidth={size}
            title={title}
            sx={{
                '& .MuiDialogTitle-root': {
                    background: getColorVariant(variant),
                    '& .MuiTypography-root': {
                        color: '#fff',
                    },
                    '& .MuiButtonBase-root': {
                        color: '#fff',
                    },
                },
            }}
        >
            <Box>
                {!isEmpty(messages) && (
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        mb={3}
                    >
                        {messages.map((message, i) => (
                            <Typography
                                key={`message-alert-${i}-${Math.random()}`}
                                variant="subtitle1"
                            >
                                {message}
                            </Typography>
                        ))}
                    </Stack>
                )}

                {renderTextCaptcha()}
                {renderTextResponse()}

                {!hideButtons && (
                    <Buttons
                        type={type}
                        handleClickClose={handleClickClose}
                        handleClickSuccess={() =>
                            handleClickSuccess(haveTextResponse && textResponse)
                        }
                        confirmText={confirmText}
                        cancelText={cancelText}
                        disabledConfirmButton={isDisabledConfirmButton()}
                    />
                )}
            </Box>
        </DialogContainer>
    );
};

Container.propTypes = {
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    defaultTextResponse: PropTypes.string,
    handleClickClose: PropTypes.func,
    handleClickSuccess: PropTypes.func,
    haveTextCaptcha: PropTypes.bool,
    haveTextResponse: PropTypes.bool,
    hideButtons: PropTypes.bool,
    isOpen: PropTypes.bool,
    messages: PropTypes.array,
    size: PropTypes.string,
    textToValidate: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    cancelText: selectors.getCancelText,
    confirmText: selectors.getConfirmText,
    defaultTextResponse: selectors.getDefaultTextResponse,
    haveTextCaptcha: selectors.getHaveTextCaptcha,
    haveTextResponse: selectors.getHaveTextResponse,
    hideButtons: selectors.getHideButtons,
    isOpen: selectors.getIsOpen,
    messages: selectors.getMessages,
    textToValidate: selectors.getTextToValidate,
    title: selectors.getTitle,
    type: selectors.getType,
    variant: selectors.getVariant,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickClose: () => dispatch(actions.close()),
    handleClickSuccess: (response) => dispatch(actions.onSuccess(response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
