import { getSummerMonths } from 'common/api/suppliers';

import {
    FETCH_SUMMER_MONTHS,
    FETCH_SUMMER_MONTHS_SUCCESS,
    FETCH_SUMMER_MONTHS_FAILURE,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_SUMMER_MONTHS });

    getSummerMonths()
        .then((response) => {
            dispatch({
                type: FETCH_SUMMER_MONTHS_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_SUMMER_MONTHS_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
