import { object, string } from 'yup';

import { emailRegex } from 'common/utils/helpers';
import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ REQUIRED_TEXT, EMAIL_VALIDATION_TEXT, minimumNumberCharacters }) =>
        object().shape({
            email: string()
                .required(REQUIRED_TEXT)
                .matches(emailRegex, EMAIL_VALIDATION_TEXT)
                .nullable(),
            password: string()
                .required(REQUIRED_TEXT)
                .min(8, minimumNumberCharacters(8))
                .nullable(),
        })
);
