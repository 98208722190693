import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import activeAlliance from './activeAlliance';

export default (filterData, item, value) => (dispatch) => {
    let messages = [];
    if (item?.is_active)
        messages = [
            i18next.t('Are you sure to disable "{{name}}"?', {
                name: `${item.name}`,
            }),
        ];
    else
        messages = [
            i18next.t('Are you sure to enable "{{name}}"?', {
                name: `${item.name}`,
            }),
        ];
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: messages,
        onSuccess: () => dispatch(activeAlliance(filterData, item, value)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };
    dispatch(alerts.actions.show(dataAlert));
};
