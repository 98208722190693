import API from '../../.';
const ENTITY = 'rates';

export const getUnits = (type) =>
    API.get(`/api/v1/${ENTITY}/unit_charges/${type}/`);

/**custom-rate*/
export const create = (data) =>
    API.post(
        `/api/v1/${ENTITY}/custom-rate/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const getItem = (uuid) =>
    API.get(`/api/v1/${ENTITY}/custom-sw-rate/${uuid}`);
export const getItems = (
    page = 1,
    perPage = 20,
    orderBy = '',
    sortBy = '',
    str = ''
) =>
    API.get(
        `/api/v1/${ENTITY}/custom-rate/?search${
            str ? '=' + str : ''
        }&page=${page}&per_page=${perPage}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );
export const update = (uuid, data) =>
    API.put(
        `/api/v1/${ENTITY}/custom-rate/${uuid}`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
