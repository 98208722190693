import { getUnits } from 'common/api/v1/rates';

import {
    FETCH_CONTRACTED_DEMAND_UNITS,
    FETCH_CONTRACTED_DEMAND_UNITS_FAILURE,
    FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_CONTRACTED_DEMAND_UNITS });
    getUnits('contracted-demand')
        .then((response) =>
            dispatch({
                payload: response?.data?.data,
                type: FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS,
            })
        )
        .catch((error) =>
            dispatch({
                payload: error?.response?.data?.errors,
                type: FETCH_CONTRACTED_DEMAND_UNITS_FAILURE,
            })
        );
};
