import { getUsers } from 'common/api/companies';

import {
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
} from '../actionTypes';

import setFilters from './setFilters';

export default (id, page = 1, orderBy, sortBy, searchStr) =>
    (dispatch) => {
        dispatch({ type: FETCH_USERS });
        dispatch(setFilters({ id, page, orderBy, sortBy, searchStr }));

        getUsers(id, page, orderBy, sortBy, searchStr)
            .then((response) =>
                dispatch({
                    type: FETCH_USERS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_USERS_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_USERS_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
