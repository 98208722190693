import get from 'lodash/get';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getCompaniesModel = createSelector(
    getModel,
    (model) => model.companies
);

export const getCompaniesData = createSelector(getCompaniesModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((company) => ({
              id: company.id,
              business_name: company.name,
              owner_fullname: company.owner_fullname,
              owner_email: company.owner_email,
              plan_name: company.plan_name,
              billing: company.billing,
              active_users: company.active_users,
              country: get(company, 'country.name', ''),
              position_city: company.position_city,
              created_at: company.created_at,
              subscription_status: company.subscription_status,
          }))
);

export const getCompaniesErrors = createSelector(
    getCompaniesModel,
    (model) => model.errors
);

export const getCompaniesIsFetching = createSelector(
    getCompaniesModel,
    (model) => model.isFetching
);

export const getCompaniesPagination = createSelector(
    getCompaniesModel,
    (model) => model.data.pagination || {}
);

export const getCompaniesCSV = createSelector(
    getModel,
    (model) => model.downloadCSV
);

export const getIsFetchingCompaniesCSV = createSelector(
    getCompaniesCSV,
    (downloadCSV) => downloadCSV.isFetching
);

export const getCountries = createSelector(
    getModel,
    (model) => model.countries
);

export const getContriesData = createSelector(
    getCountries,
    (model) => model.data
);

export const getIsFetchingCountries = createSelector(
    getCountries,
    (model) => model.isFetching
);

export const getPlans = createSelector(getModel, (model) => model.plans);

export const getPlansData = createSelector(getPlans, (model) => model.data);

export const getIsFetchingPlans = createSelector(
    getPlans,
    (model) => model.isFetching
);
