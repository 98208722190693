import { array, object, string } from 'yup';

import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import { emailRegex } from 'common/utils/helpers';
import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ EMAIL_VALIDATION_TEXT, REQUIRED_TEXT, minimumNumberCharacters }) =>
        object().shape({
            first_name: string().required(REQUIRED_TEXT).nullable(),
            last_name: string().required(REQUIRED_TEXT).nullable(),
            phone: string().required(REQUIRED_TEXT).nullable(),
            email: string()
                .required(REQUIRED_TEXT)
                .matches(emailRegex, EMAIL_VALIDATION_TEXT)
                .nullable(),
            tmp_password: string()
                .when('has_tmp_password', {
                    is: true,
                    then: string()
                        .required(REQUIRED_TEXT)
                        .min(8, minimumNumberCharacters(8)),
                })
                .nullable(),
            company_group: string().required(REQUIRED_TEXT).nullable(),
            branch_office: string()
                .when(['isMultiBranchesCompany', 'role_level'], {
                    is: (isMultiBranchesCompany, roleLevel) => {
                        const isBranchManagerOrSalesAgent = [
                            ROLE_LEVELS.BRANCH_MANAGER,
                            ROLE_LEVELS.SALES_AGENT,
                        ].includes(roleLevel);
                        return (
                            isMultiBranchesCompany &&
                            isBranchManagerOrSalesAgent
                        );
                    },
                    then: string().required(REQUIRED_TEXT).nullable(),
                    otherwise: () => string().nullable(),
                })
                .nullable(),
            branch_offices: array()
                .when(['isMultiBranchesCompany', 'role_level'], {
                    is: (isMultiBranchesCompany, roleLevel) => {
                        const isCustomOrGM = [
                            ROLE_LEVELS.CUSTOMIZED,
                            ROLE_LEVELS.GENERAL_MANAGER,
                        ].includes(roleLevel);
                        return isMultiBranchesCompany && isCustomOrGM;
                    },
                    then: array().min(1, REQUIRED_TEXT).required(REQUIRED_TEXT),
                    otherwise: () => string().nullable(),
                })
                .nullable(),
        })
);
