import { NAME } from './constants';

export const DELETE_TAG = `${NAME}/DELETE_TAG`;
export const DELETE_TAG_FAILURE = `${NAME}/DELETE_TAG_FAILURE`;
export const DELETE_TAG_SUCCESS = `${NAME}/DELETE_TAG_SUCCESS`;

export const FETCH_TAGS = `${NAME}/FETCH_TAGS`;
export const FETCH_TAGS_FAILURE = `${NAME}/FETCH_TAGS_FAILURE`;
export const FETCH_TAGS_SUCCESS = `${NAME}/FETCH_TAGS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_TAG = `${NAME}/SAVE_TAG`;
export const SAVE_TAG_FAILURE = `${NAME}/SAVE_TAG_FAILURE`;
export const SAVE_TAG_SUCCESS = `${NAME}/SAVE_TAG_SUCCESS`;

export const SET_MODAL_IS_OPEN = `${NAME}/SET_MODAL_IS_OPEN`;
export const SET_IS_NEW_TAG = `${NAME}/SET_IS_NEW_TAG`;
