import { filterFonts } from 'common/api/templatesFonts';

import {
    FETCH_FONTS,
    FETCH_FONTS_FAILURE,
    FETCH_FONTS_SUCCESS,
} from '../actionTypes';

export default ({ orderBy, page = 0, pageSize, sortBy } = {}) =>
    (dispatch) => {
        dispatch({ type: FETCH_FONTS });

        filterFonts({
            orderBy,
            page: page + 1,
            perPage: pageSize,
            sortBy,
        })
            .then((response) =>
                dispatch({
                    type: FETCH_FONTS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_FONTS_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_FONTS_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
