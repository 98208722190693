import React from 'react';

import PropTypes from 'prop-types';

import {
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox,
} from 'common/ui';

const ReduxFieldCheck = ({
    color = 'info',
    disabled,
    id,
    input: { onBlur, onChange, value },
    label = '',
    meta: { error, touched },
    name,
    ...other
}) => {
    return (
        <FormControl
            required
            error={Boolean(touched && error)}
            component="fieldset"
            variant="standard"
            sx={{ m: 1 }}
        >
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={value ? true : false}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            id={id}
                            {...other}
                            color={color}
                        />
                    }
                    label={label}
                    sx={{
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                />
            </FormGroup>
            <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
    );
};

ReduxFieldCheck.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    name: PropTypes.string,
};

export default ReduxFieldCheck;
