import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getUploadDatasheet = createSelector(
    getModel,
    (model) => model.uploadDatasheet
);

export const getIsUploadingDatasheet = createSelector(
    getUploadDatasheet,
    (uploadDatasheet) => uploadDatasheet.isLoading
);

export const getUploadDatasheetErrors = createSelector(
    getUploadDatasheet,
    (uploadDatasheet) => uploadDatasheet.errors
);

export const getIsVisibleForm = createSelector(
    getModel,
    (model) => model.isVisibleForm
);

export const getClipboardData = createSelector(
    getModel,
    (model) => model.clipboard.data
);
