import React from 'react';

import PropTypes from 'prop-types';

import { Container, Grid, Skeleton } from 'common/ui';

const items = [];
const emptyColumns = [];

for (let i = 0; i < 6; i++) {
    emptyColumns.push(
        <Grid item xs={2} key={`content-loader-${i}`}>
            <Skeleton height={200} variant="rectangle" />
        </Grid>
    );
}

for (let i = 0; i < 1; i++) {
    items.push(
        <Grid container key={`placeholder-card-${i}`} spacing={2} mb={2}>
            {emptyColumns}
        </Grid>
    );
}

const Placeholder = <Container maxWidth={false}>{items}</Container>;

const PlaceholderTemplateChart = ({ children, ready }) => (
    <>{ready ? Placeholder : children}</>
);

PlaceholderTemplateChart.propTypes = {
    children: PropTypes.object,
    ready: PropTypes.bool,
};

export default PlaceholderTemplateChart;
