import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import {
    ReduxFieldCheck,
    ReduxFieldInput,
    ReduxFieldNumberInput,
    ReduxFieldSelect,
} from 'common/components/form/redux';
import { Box, Button, Stack } from 'common/ui';

import { BASE_MODELS, MODEL_STATUS_KEYS, NAME } from '../constants';
import validate from '../validate';

const ModelForm = ({
    disabled,
    handleCloseModalForm,
    handleSubmit,
    initialValues,
    onSubmit,
}) => {
    const { t } = useTranslation();

    const isTrained =
        initialValues.status === MODEL_STATUS_KEYS.TRAINED ||
        initialValues.status === MODEL_STATUS_KEYS.TRAINING;
    const _disabled = disabled || isTrained;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
                <Field
                    component={ReduxFieldInput}
                    disabled={disabled}
                    name="name"
                    placeholder={t('Name')}
                    required
                    variant="outlined"
                />

                <Field
                    component={ReduxFieldInput}
                    disabled={disabled}
                    label={t('Description')}
                    name="description"
                    variant="outlined"
                />

                <Field
                    component={ReduxFieldSelect}
                    disabled={_disabled}
                    fullWidth
                    label={t('Base model')}
                    name="base_model"
                    options={BASE_MODELS}
                    required
                    variant="outlined"
                />

                <Field
                    component={ReduxFieldInput}
                    disabled={_disabled}
                    label={t('Context')}
                    maxRows={4}
                    multiline
                    name="context"
                    variant="outlined"
                />

                <Field
                    component={ReduxFieldInput}
                    disabled={_disabled}
                    label="Prompt"
                    maxRows={4}
                    multiline
                    name="prompt"
                    variant="outlined"
                />

                <Field
                    component={ReduxFieldNumberInput}
                    decimalScale={2}
                    label={t('Temperature')}
                    name="temperature"
                    placeholder="0"
                    required
                    variant="outlined"
                />

                <Field
                    component={ReduxFieldNumberInput}
                    label={t('Max tokens')}
                    name="max_tokens"
                    placeholder="2048"
                    required
                    variant="outlined"
                />

                {isTrained && (
                    <Field
                        component={ReduxFieldCheck}
                        disabled={disabled}
                        label={t('Main')}
                        name="main_model"
                    />
                )}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { sm: 'row', xs: 'column' },
                        gap: 2,
                        justifyContent: { sm: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        disabled={disabled}
                        fullWidth={false}
                        height="48px"
                        onClick={handleCloseModalForm}
                        size="large"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={disabled}
                        fullWidth={false}
                        height="48px"
                        size="large"
                        type="submit"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </Stack>
        </form>
    );
};

ModelForm.propTypes = {
    disabled: PropTypes.bool,
    handleCloseModalForm: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
};

export default reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(ModelForm);
