import React from 'react';

import PropTypes from 'prop-types';

import { FilePreview } from 'common/components';
import { Skeleton } from 'common/ui';

import FileUpload from './FileUpload';

const FileContainer = ({
    disabled,
    isFetchingFile,
    page,
    trainingDataFile,
    uploadFile,
}) => {
    if (isFetchingFile)
        return <Skeleton variant="rectangular" width="100%" height="100%" />;

    if (trainingDataFile?.url)
        return (
            <FilePreview
                disabled={disabled}
                file={trainingDataFile.url}
                handleOnChange={uploadFile}
                pageNumber={page + 1}
                paginated
            />
        );

    return <FileUpload disabled={disabled} onChange={uploadFile} />;
};

FileContainer.propTypes = {
    disabled: PropTypes.bool,
    isFetchingFile: PropTypes.bool,
    page: PropTypes.number,
    trainingDataFile: PropTypes.object,
    uploadFile: PropTypes.func,
};

export default FileContainer;
