import get from 'lodash/get';
import { object } from 'yup';

import {
    FIXED_RATE_TYPE,
    HOURLY_RATE_TYPE,
    STAGGERED_RATE_TYPE,
} from './constants';
import {
    getMainDataSchemaValidation,
    getPolicyDataSchemaValidation,
    getSettingsDataSchemaValidation,
} from './validations';

export default (values) =>
    object().shape({
        main_data: getMainDataSchemaValidation(),
        policy_data: getPolicyDataSchemaValidation(),
        settings_data: getSettingsDataSchemaValidation(
            get(values, 'settings_data.payment_type', FIXED_RATE_TYPE) ===
                STAGGERED_RATE_TYPE,
            get(values, 'settings_data.payment_type', FIXED_RATE_TYPE) ===
                HOURLY_RATE_TYPE,
            values
        ),
    });
