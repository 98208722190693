import React, { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Box, Card, CardActions, Container, Pagination } from 'common/ui';

import * as actions from './actions';
import {
    CustomRateModalForm,
    CustomRatesList,
    RateForm,
    RatesPreviewTable,
    RatesPreviewTableToolbar,
} from './components';
import * as selectors from './selectors';

const RatesContainer = ({
    fetchRates,
    filters,
    isFetching,
    isOpenItemForm,
    pagination,
    rates,
    reset,
    setCurrentItem,
}) => {
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        setCurrentItem({});
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (!isOpenItemForm) setCurrentItem({});
    }, [isOpenItemForm]);

    useEffect(() => {
        fetchRates(page, orderBy, sortBy, searchStr);
    }, [page, searchStr]);

    const handleOnChangePage = (_, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchRates(page, orderBy, sortBy, searchStr);
    };

    const handleOnSearchChange = (e) => setSearchStr(e);

    return (
        <>
            {!isOpenItemForm ? (
                <Container maxWidth="">
                    <Box mb={3}>
                        <RatesPreviewTableToolbar
                            handleOnSearchChange={handleOnSearchChange}
                            searchStr={filters.searchStr}
                        />
                    </Box>

                    <Card>
                        <RatesPreviewTable
                            handleSortable={handleSortable}
                            isFetching={isFetching}
                            items={rates}
                            orderdBy={orderBy}
                            setCurrentItem={setCurrentItem}
                            sortedBy={sortBy}
                        />

                        {!isFetching && pagination && !isEmpty(pagination) && (
                            <CardActions>
                                <Pagination
                                    color="primary"
                                    count={
                                        Math.ceil(
                                            parseInt(pagination.total_rows) /
                                                parseInt(pagination.per_page)
                                        ) || 0
                                    }
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    sx={{ marginLeft: 'auto' }}
                                    page={pagination.current_page}
                                    onChange={handleOnChangePage}
                                    variant="outlined"
                                />
                            </CardActions>
                        )}
                    </Card>

                    <CustomRatesList />

                    <CustomRateModalForm />
                </Container>
            ) : (
                <Container maxWidth="">
                    <RateForm setCurrentItem={setCurrentItem} />
                </Container>
            )}
        </>
    );
};

RatesContainer.propTypes = {
    fetchRates: PropTypes.func,
    filters: PropTypes.object,
    isFetching: PropTypes.bool,
    isOpenItemForm: PropTypes.bool,
    pagination: PropTypes.object,
    rates: PropTypes.array,
    reset: PropTypes.func,
    setCurrentItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    rates: selectors.getRatesData,
    filters: selectors.getFilters,
    isFetching: selectors.getRatesIsFetching,
    isOpenItemForm: selectors.getIsOpenItemForm,
    pagination: selectors.getRatesPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRates: (page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchRates(page, orderBy, sortBy, searchStr)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RatesContainer);
