import React from 'react';

import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import PropTypes from 'prop-types';

import { IconButton } from 'common/ui';

const ToggleDevMode = ({ isDevMode, setIsDevMode }) => {
    return (
        <IconButton
            size="medium"
            onClick={() => setIsDevMode((prev) => !prev)}
            sx={{ ml: 'auto' }}
        >
            {isDevMode ? (
                <CodeIcon fontSize="medium" />
            ) : (
                <CodeOffIcon fontSize="medium" />
            )}
        </IconButton>
    );
};

ToggleDevMode.propTypes = {
    isDevMode: PropTypes.bool,
    setIsDevMode: PropTypes.func,
};

export default ToggleDevMode;
