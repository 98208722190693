import styled from 'styled-components';

export default styled.div`
    align-items: center;
    border-bottom: 1px solid #eff1fb;
    display: flex;
    height: 58px;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
`;
