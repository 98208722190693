import { getProjects } from 'common/api/afterSales/projects';

import {
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
} from '../actionTypes';

export default ({ company_id, page = 1, pageSize = 25 } = {}) =>
    (dispatch) => {
        dispatch({ type: FETCH_PROJECTS });

        getProjects({ company_id, page, page_size: pageSize })
            .then((response) =>
                dispatch({
                    payload: response?.data,
                    type: FETCH_PROJECTS_SUCCESS,
                })
            )
            .catch((error) =>
                dispatch({ payload: error, type: FETCH_PROJECTS_FAILURE })
            );
    };
