import i18next from 'i18next';

import { updateTrainingData } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import {
    SAVE_TRAINING_DATA,
    SAVE_TRAINING_DATA_FAILURE,
    SAVE_TRAINING_DATA_SUCCESS,
} from '../actionTypes';

import fetchModel from './fetchModel';
import resetForm from './resetForm';
import setIsOpenModal from './setIsOpenModal';

export default (values, modelId, query) => (dispatch) => {
    dispatch({ type: SAVE_TRAINING_DATA });

    let newValues = { tags: values?.tags?.map((tag) => tag.value) || [] };

    updateTrainingData(values.id, modelId, newValues)
        .then(() => {
            dispatch({ type: SAVE_TRAINING_DATA_SUCCESS });
            dispatch(setIsOpenModal(false));
            dispatch(resetForm());
            dispatch(fetchModel(modelId, query));
        })
        .catch((error) => {
            dispatch({
                type: SAVE_TRAINING_DATA_FAILURE,
                payload: error?.response?.data?.detail,
            });
            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t(
                    'An error occurred while updating the training data'
                ),
            });
        });
};
