import { NAME } from './constants';

export const INITIALIZE_FILTER_FORM = `${NAME}/INITIALIZE_FILTER_FORM`;
export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const FETCH_AGENTS = `${NAME}/FETCH_AGENTS`;
export const FETCH_AGENTS_FAILURE = `${NAME}/FETCH_AGENTS_FAILURE`;
export const FETCH_AGENTS_SUCCESS = `${NAME}/FETCH_AGENTS_SUCCESS`;

export const FETCH_BRANCH_OFFICES = `${NAME}/FETCH_BRANCH_OFFICES`;
export const FETCH_BRANCH_OFFICES_FAILURE = `${NAME}/FETCH_BRANCH_OFFICES_FAILURE`;
export const FETCH_BRANCH_OFFICES_SUCCESS = `${NAME}/FETCH_BRANCH_OFFICES_SUCCESS`;

export const FETCH_COMPANY_DETAIL = `${NAME}/FETCH_COMPANY_DETAIL`;
export const FETCH_COMPANY_DETAIL_FAILURE = `${NAME}/FETCH_COMPANY_DETAIL_FAILURE`;
export const FETCH_COMPANY_DETAIL_SUCCESS = `${NAME}/FETCH_COMPANY_DETAIL_SUCCESS`;

export const FETCH_PROJECTS_COUNTERS = `${NAME}/FETCH_PROJECTS_COUNTERS`;
export const FETCH_PROJECTS_COUNTERS_FAILURE = `${NAME}/FETCH_PROJECTS_COUNTERS_FAILURE`;
export const FETCH_PROJECTS_COUNTERS_SUCCESS = `${NAME}/FETCH_PROJECTS_COUNTERS_SUCCESS`;

export const FETCH_PROPOSALS_TOTALS = `${NAME}/FETCH_PROPOSALS_TOTALS`;
export const FETCH_PROPOSALS_TOTALS_FAILURE = `${NAME}/FETCH_PROPOSALS_TOTALS_FAILURE`;
export const FETCH_PROPOSALS_TOTALS_SUCCESS = `${NAME}/FETCH_PROPOSALS_TOTALS_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const UPDATE_COMPANY_SUBSCRIPION = `${NAME}/UPDATE_COMPANY_SUBSCRIPION`;
export const UPDATE_COMPANY_SUBSCRIPION_FAILURE = `${NAME}/UPDATE_COMPANY_SUBSCRIPION_FAILURE`;
export const UPDATE_COMPANY_SUBSCRIPION_SUCCESS = `${NAME}/UPDATE_COMPANY_SUBSCRIPION_SUCCESS`;
