import { fetchCategoriesSettings } from 'common/api/companies';

import {
    FETCH_CATEGORIES_SETTINGS,
    FETCH_CATEGORIES_SETTINGS_FAILURE,
    FETCH_CATEGORIES_SETTINGS_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_CATEGORIES_SETTINGS });

    fetchCategoriesSettings()
        .then((response) =>
            dispatch({
                type: FETCH_CATEGORIES_SETTINGS_SUCCESS,
                payload: response.data.data,
            })
        )
        .catch((error) =>
            dispatch({
                type: FETCH_CATEGORIES_SETTINGS_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
