import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    ReactHookFormCheck,
    ReactHookFormInputDatePicker,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { Grid } from 'common/ui';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { REWIND_FREQUENCY, REWIND_TYPES } from '../constants';
import { getMaxDate } from '../helpers';
import * as selectors from '../selectors';
import validateRewind from '../validateRewind';

import FormModal from './FormModal';

const RewindModal = ({
    fetchProjects,
    initialValues,
    isOpen,
    isSaving,
    resetForm,
    rewindProject,
    setIsOpen,
}) => {
    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateRewind),
    });
    const { t } = useTranslation();
    const rewindType = watch('rewind_type');

    useEffect(() => reset(initialValues), [initialValues, reset]);

    const onSave = (data) => {
        rewindProject(data, () => {
            setIsOpen(false);
            fetchProjects();
        });
    };

    const typeOptions = [
        { label: t('All'), value: REWIND_TYPES.ALL },
        { label: t('Day', { count: 1 }), value: REWIND_TYPES.DAY },
        { label: t('Range'), value: REWIND_TYPES.RANGE },
    ];
    const freqOptions = [
        { label: t('Daily'), value: REWIND_FREQUENCY.DAILY },
        { label: t('Hourly'), value: REWIND_FREQUENCY.HOURLY },
    ];

    const maxDate = getMaxDate();

    return (
        <FormModal
            isOpen={isOpen}
            isSaving={isSaving}
            onSubmit={handleSubmit(onSave)}
            resetForm={resetForm}
            setIsOpen={setIsOpen}
            title={t('Rewind project')}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={4}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isSaving}
                        isClearable={false}
                        label={t('Type')}
                        name="rewind_type"
                        options={[{ label: '', options: typeOptions }]}
                    />
                </Grid>

                {rewindType && rewindType !== REWIND_TYPES.ALL && (
                    <Grid item xs={12} md={6} xl={4}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            disabled={isSaving}
                            label={
                                rewindType === REWIND_TYPES.DAY
                                    ? t('Date')
                                    : t('Initial date')
                            }
                            maxDate={maxDate}
                            name="initial_date"
                        />
                    </Grid>
                )}

                {rewindType === REWIND_TYPES.RANGE && (
                    <Grid item xs={12} md={6} xl={4}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            disabled={isSaving}
                            label={t('Final date')}
                            maxDate={maxDate}
                            name="final_date"
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={6} xl={4}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isSaving}
                        label={t('Frequency')}
                        name="freq"
                        options={[{ label: '', options: freqOptions }]}
                    />
                </Grid>

                <Grid item xs={12} md={6} xl={4}>
                    <ReactHookFormCheck
                        control={control}
                        disabled={isSaving}
                        label={t('Clear all reads')}
                        name="clear_all_reads"
                    />
                </Grid>
            </Grid>
        </FormModal>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpen: selectors.getIsRewindModalOpen,
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    setIsOpen: (isOpen) => dispatch(actions.setOpenRewindModal(isOpen)),
    rewindProject: (values, callback) =>
        dispatch(actions.rewindProject(values, callback)),
});

RewindModal.propTypes = {
    fetchProjects: PropTypes.func,
    initialValues: PropTypes.object,
    isOpen: PropTypes.bool,
    isSaving: PropTypes.bool,
    resetForm: PropTypes.func,
    rewindProject: PropTypes.func,
    setIsOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RewindModal);
