import { activeAlliance } from 'common/api/v1/alliances';
import showToast from 'common/utils/showToast';

import {
    ENABLE_ALLIANCE,
    ENABLE_ALLIANCE_FAILURE,
    ENABLE_ALLIANCE_SUCCESS,
} from '../actionTypes';

import filterAlliances from './filterAlliances';

export default (filterData, item, value) => (dispatch) => {
    if (!item.id) return;

    const newValues = {
        is_active: value,
    };

    dispatch({ type: ENABLE_ALLIANCE });

    activeAlliance(newValues, item.id)
        .then(() => {
            dispatch({ type: ENABLE_ALLIANCE_SUCCESS });
            dispatch(filterAlliances(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: ENABLE_ALLIANCE_FAILURE,
                payload: error?.response?.data?.errors,
            });
        });
};
