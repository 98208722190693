import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import {
    ReduxFieldDatePicker,
    ReduxFieldSelect,
} from 'common/components/form/redux';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'common/ui';
import { getAgentOptions } from 'common/utils/helpers';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

const ProposalsFilter = ({
    agents,
    companyId,
    fetchProjectsCounters,
    fetchProposalsTotals,
    filterBy,
    formValues,
    handleSubmit,
    setFilterBy,
    showProposals,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (showProposals) fetchProposalsTotals(companyId, formValues);
        else fetchProjectsCounters(companyId, formValues);
    }, [filterBy]);

    const handleClickFilter = (values) =>
        showProposals
            ? fetchProposalsTotals(companyId, values)
            : fetchProjectsCounters(companyId, values);

    return (
        <Grid
            alignItems="flex-end"
            container
            justifyContent="flex-end"
            spacing={2}
        >
            <Grid item xs={12} lg={3}>
                <FormControl fullWidth size="small">
                    <InputLabel>{t('Filter by')}</InputLabel>
                    <Select
                        label={t('Filter by')}
                        onChange={(e) => setFilterBy(e.target.value)}
                        value={filterBy}
                    >
                        <MenuItem value="proposals">
                            {t('Proposal', { count: 2 })}
                        </MenuItem>
                        <MenuItem value="projects">
                            {t('Project', { count: 2 })}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={3}>
                <Field
                    component={ReduxFieldDatePicker}
                    inputFormat="dd/MM/yyyy"
                    label={t('Initial date')}
                    maxDate={new Date()}
                    name="initial_date"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Field
                    component={ReduxFieldDatePicker}
                    inputFormat="dd/MM/yyyy"
                    label={t('Final date')}
                    maxDate={new Date()}
                    name="final_date"
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <FormControl fullWidth size="small">
                    <Field
                        name="agent"
                        component={ReduxFieldSelect}
                        label={t('Salesman')}
                        variant="outlined"
                        options={getAgentOptions(agents)}
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={1}>
                <Button fullWidth onClick={handleSubmit(handleClickFilter)}>
                    <strong>{t('Filter')}</strong>
                </Button>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFilterValues,
    initialValues: selectors.getInitialFilterValues,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectsCounters: (companyId, values) =>
        dispatch(actions.fetchProjectsCounters(companyId, values)),
    fetchProposalsTotals: (companyId, values) =>
        dispatch(actions.fetchProposalsTotals(companyId, values)),
});

ProposalsFilter.propTypes = {
    agents: PropTypes.array,
    companyId: PropTypes.string,
    fetchProjectsCounters: PropTypes.func,
    fetchProposalsTotals: PropTypes.func,
    filterBy: PropTypes.string,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    setFilterBy: PropTypes.func,
    showProposals: PropTypes.bool,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/filter-form`,
})(ProposalsFilter);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
