import { getProductThumbnail } from 'common/api/thumbnails';

import {
    FETCH_PRODUCT_THUMBNAIL,
    FETCH_PRODUCT_THUMBNAIL_FAILURE,
    FETCH_PRODUCT_THUMBNAIL_SUCCESS,
} from '../actionTypes';

export default (id) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: FETCH_PRODUCT_THUMBNAIL });

        getProductThumbnail(id)
            .then((response) => {
                dispatch({
                    type: FETCH_PRODUCT_THUMBNAIL_SUCCESS,
                    payload: response.data,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_PRODUCT_THUMBNAIL_FAILURE,
                    payload: error,
                });
                reject();
            });
    });
