import { INITIALIZE_FORM } from '../actionTypes';

import setIsNewTag from './setIsNewTag';
import setModalIsOpen from './setModalIsOpen';

export default (values = {}) =>
    (dispatch) => {
        const isNewTag = !values?.id;
        dispatch({ payload: values, type: INITIALIZE_FORM });
        dispatch(setIsNewTag(isNewTag));
        dispatch(setModalIsOpen(true));
    };
