import { NAME } from './constants';

export const DOWNLOAD_CSV = `${NAME}/DOWNLOAD_CSV`;
export const DOWNLOAD_CSV_FAILURE = `${NAME}/DOWNLOAD_CSV_FAILURE`;
export const DOWNLOAD_CSV_SUCCESS = `${NAME}/DOWNLOAD_CSV_SUCCESS`;

export const FETCH_COMPANIES = `${NAME}/FETCH_COMPANIES`;
export const FETCH_COMPANIES_FAILURE = `${NAME}/FETCH_COMPANIES_FAILURE`;
export const FETCH_COMPANIES_SUCCESS = `${NAME}/FETCH_COMPANIES_SUCCESS`;

export const FECH_COUNTRIES = `${NAME}/FECH_COUNTRIES`;
export const FECH_COUNTRIES_FAILURE = `${NAME}/FECH_COUNTRIES_FAILURE`;
export const FECH_COUNTRIES_SUCCESS = `${NAME}/FECH_COUNTRIES_SUCCESS`;

export const FETCH_PLANS = `${NAME}/FETCH_PLANS`;
export const FETCH_PLANS_FAILURE = `${NAME}/FETCH_PLANS_FAILURE`;
export const FETCH_PLANS_SUCCESS = `${NAME}/FETCH_PLANS_SUCCESS`;
