import i18next from 'i18next';

import { createApiAccount } from 'common/api/apiAccounts';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchApiAccounts from './fetchApiAccounts';
import resetForm from './resetForm';
import toggleItemForm from './toggleItemForm';

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = {
        phone: values.phone,
        email: values.email,
        password: values.password,
    };

    createApiAccount(newValues)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchApiAccounts(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleItemForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while creating the element'),
            });
        });
};
