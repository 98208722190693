import { NAME } from './constants';

export const DELETE_FONT = `${NAME}/DELETE_FONT`;
export const DELETE_FONT_SUCCESS = `${NAME}/DELETE_FONT_SUCCESS`;
export const DELETE_FONT_FAILURE = `${NAME}/DELETE_FONT_FAILURE`;

export const FETCH_FONTS = `${NAME}/FETCH_FONTS`;
export const FETCH_FONTS_FAILURE = `${NAME}/FETCH_FONTS_FAILURE`;
export const FETCH_FONTS_SUCCESS = `${NAME}/FETCH_FONTS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_PRODUCT_FORM = `${NAME}/OPEN_PRODUCT_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;
