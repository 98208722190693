import API from '..';
const ENTITY = 'products';

export const getBrands = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/brands/?search${str ? '=' + str : ''}&page=${page}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );

export const getBrandDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/brands/${id}/`);

export const createBrand = (data) =>
    API.post(`/api/v1/${ENTITY}/brands/`, data);

export const updateBrand = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/brands/${id}/`, data);

export const deleteBrand = (id) =>
    API.delete(`/api/v1/${ENTITY}/brands/${id}/`);
