import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getCategoriesModel = createSelector(
    getModel,
    (model) => model.categories
);

export const getCategoriesData = createSelector(getCategoriesModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((category) => ({
              ...category,
          }))
);

export const getCategoriesErrors = createSelector(
    getCategoriesModel,
    (model) => model.errors
);

export const getCategoriesIsFetching = createSelector(
    getCategoriesModel,
    (model) => model.isFetching
);

export const getCategoriesPagination = createSelector(
    getCategoriesModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenCategoryForm = createSelector(
    getModel,
    (model) => model.isOpenCategoryForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getCategoryDetail = createSelector(
    getModel,
    (model) => model.categoryDetail
);

export const getIsFetchingCategoryDetail = createSelector(
    getCategoryDetail,
    (categoryDetail) => categoryDetail.isFetching
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);

export const getDeleteCategory = createSelector(
    getModel,
    (model) => model.deleteCategory
);

export const getIsDeletingItem = createSelector(
    getDeleteCategory,
    (deleteCategory) => deleteCategory.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteCategory,
    (deleteCategory) => deleteCategory.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteCategory,
    (deleteCategory) => deleteCategory.errors
);
