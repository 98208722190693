import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getProductThumbnail = createSelector(
    getModel,
    (model) => model.productThumbnail
);

export const getIsLoadingProductThumbnail = createSelector(
    getProductThumbnail,
    (productThumbnail) => productThumbnail.isLoading
);

export const getDataProductThumbnail = createSelector(
    getProductThumbnail,
    (productThumbnail) => productThumbnail.data || {}
);

export const getUploadThumbnail = createSelector(
    getModel,
    (model) => model.uploadThumbnail
);

export const getIsUploadingThumbnail = createSelector(
    getUploadThumbnail,
    (uploadThumbnail) => uploadThumbnail.isLoading
);

export const getUploadThumbnailErrors = createSelector(
    getUploadThumbnail,
    (uploadThumbnail) => uploadThumbnail.errors
);
