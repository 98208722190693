import React, { useContext, useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { HeaderContainer, TitleIcon } from 'common/components';
import { Container, Typography } from 'common/ui';
import { LoadingContext } from 'common/utils/contexts';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

import { ReactComponent as IcoAlliance } from 'resources/icoAlliance.svg';

import * as reportActions from '../reportDownload/actions';
import * as reportSelectors from '../reportDownload/selectors';

import * as actions from './actions';
import ListItems from './components/ListItems';
import * as selectors from './selectors';

const CompaniesContainer = ({
    alliances,
    downloadAlliancesReport,
    filterAlliances,
    isDownloadingAlliancesReport,
    isFetching,
    pagination,
    prepareActiveAlliance,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });

    useEffect(() => {
        filterAlliances(filterData);
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDownloadingAlliancesReport)
            loadingContext.openLoading(t('Downloading CSV').concat('...'));
        else loadingContext.closeLoading();
    }, [isDownloadingAlliancesReport]);

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4" gutterBottom>
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoAlliance}
                        title={t('Alliance', { count: 2 })}
                    />
                </Typography>
            </HeaderContainer>

            <ListItems
                filterData={filterData}
                filterItems={filterAlliances}
                handleClickActive={(row, value) =>
                    prepareActiveAlliance(filterData, row, value)
                }
                handleClickDownload={(id) =>
                    downloadAlliancesReport(t('User', { count: 2 }), id)
                }
                isFetching={isFetching}
                items={alliances}
                paginationData={pagination}
                session={getSessionLocalStorage()}
                setFilterData={setFilterData}
            />
        </Container>
    );
};

CompaniesContainer.propTypes = {
    alliances: PropTypes.array,
    downloadAlliancesReport: PropTypes.func,
    filterAlliances: PropTypes.func,
    isDownloadingAlliancesReport: PropTypes.bool,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
    prepareActiveAlliance: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    alliances: selectors.getAlliancesData,
    isDownloadingAlliancesReport:
        reportSelectors.getIsDownloadingActiveCiBancoAlliancesReport,
    isFetching: selectors.getAlliancesIsFetching,
    pagination: selectors.getAlliancesPagination,
});

const mapDispatchToProps = (dispatch) => ({
    downloadAlliancesReport: (fileName, financierId) =>
        dispatch(
            reportActions.downloadActiveCiBancoAlliancesReport(
                fileName,
                financierId
            )
        ),
    filterAlliances: (filterData) =>
        dispatch(actions.filterAlliances(filterData)),
    prepareActiveAlliance: (filterData, item, value) =>
        dispatch(actions.prepareActiveAlliance(filterData, item, value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompaniesContainer));
