import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getApiAccountsModel = createSelector(
    getModel,
    (model) => model.apiAccounts
);

export const getApiAccountsData = createSelector(getApiAccountsModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((account) => ({
              ...account,
          }))
);

export const getApiAccountsErrors = createSelector(
    getApiAccountsModel,
    (model) => model.errors
);

export const getApiAccountsIsFetching = createSelector(
    getApiAccountsModel,
    (model) => model.isFetching
);

export const getApiAccountsPagination = createSelector(
    getApiAccountsModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenItemForm = createSelector(
    getModel,
    (model) => model.isOpenItemForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getApiAccountDetail = createSelector(
    getModel,
    (model) => model.apiAccountDetail
);

export const getIsFetchingApiAccountsDetail = createSelector(
    getApiAccountDetail,
    (templateDetail) => templateDetail.isFetching
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);

export const getDeleteItem = createSelector(
    getModel,
    (model) => model.deleteItem
);

export const getIsDeletingItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.errors
);
