import * as selectors from '../selectors';

import fetchSuppliers from './fetchSuppliers';
import resetForm from './resetForm';
import toggleItemForm from './toggleItemForm';

export default () => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    dispatch(fetchSuppliers(filters.page));
    dispatch(resetForm());
    dispatch(toggleItemForm(false));
};
