import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import {
    ReduxFieldInput,
    ReduxFieldSwitch,
} from 'common/components/form/redux';
import { Button, Grid, Stack, Card, Box, AppBar, Tab, Tabs } from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import ConfigurationPermissions from './ConfigurationPermissions';
import ContactsPermissions from './ContactsPermissions';
import ProposerActions from './ProposerActions';

const a11yProps = (index) => {
    return { id: `tab-${index}` };
};

const RoleForm = ({
    changeInput,
    closeModalEdition,
    configurationPermissions,
    contactPermissions,
    errors,
    fetchCategoriesSettings,
    formValues,
    handleClickSave,
    handleSubmit,
    isFetchingDetail,
    isSaving,
    proposerPermissions,
    reset,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);

    const handleChangeTab = (_, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchCategoriesSettings();
    }, []);

    const typeCreate = get(formValues, 'type', null);
    const ownerTypeCreate = get(formValues, 'owner_type', null);

    const isOwner =
        parseInt(typeCreate) === 0 && parseInt(ownerTypeCreate) === 0;

    const labelDefault = isOwner
        ? { label: t('Owner'), owner_type: 0 }
        : { label: t('General manager'), owner_type: 1 };

    const visualizations = [
        {
            label: t('All contacts'),
            type: 0,
            owner_type: labelDefault.owner_type,
        },
        {
            label: t('All branch contacts'),
            type: 1,
            owner_type: null,
        },
        {
            label: t('All contacts assigned to the advisory'),
            type: 2,
            owner_type: null,
        },
    ];

    const groupsFiltered = (options) => {
        return options
            ? options.map((group) => {
                  let options = {};
                  options['label'] = group.label;
                  options['optionValue'] = group.type;
                  options['disabled'] = false;
                  return options;
              })
            : [];
    };

    const contactsVisualizationsFiltered = groupsFiltered(visualizations);

    return (
        <form>
            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} md={6}>
                    <Field
                        name="name"
                        component={ReduxFieldInput}
                        placeholder={t('Role')}
                        variant="outlined"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field
                        name="level"
                        component={ReduxFieldInput}
                        placeholder={t('Rol level')}
                        variant="outlined"
                        disabled={true}
                    />
                </Grid>
            </Grid>

            <Stack spacing={2} pt={4} pb={4}>
                <Field
                    name="isEnabledEdition"
                    component={ReduxFieldSwitch}
                    disabled={isFetchingDetail}
                    label={t('Edit')}
                    onChange={(event) => {
                        if (!event.target.checked) {
                            reset();
                        }
                    }}
                />
            </Stack>

            <Card>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <AppBar position="static" color="secondary">
                        <Tabs
                            allowScrollButtonsMobile
                            indicatorColor="primary"
                            onChange={handleChangeTab}
                            scrollButtons
                            sx={{
                                '& .MuiTabs-indicator': {
                                    height: '3px',
                                },
                            }}
                            textColor="inherit"
                            variant={
                                useMediaQuery((theme) =>
                                    theme.breakpoints.down('md')
                                )
                                    ? 'scrollable'
                                    : 'fullWidth'
                            }
                            value={value}
                        >
                            <Tab
                                key={`role-tab-item-${0}`}
                                label={t('Contacts permissions')}
                                sx={{
                                    textTransform: 'none',
                                }}
                                {...a11yProps(0)}
                            />
                            <Tab
                                key={`role-tab-item-${1}`}
                                label={t('Proposer actions')}
                                sx={{
                                    textTransform: 'none',
                                }}
                                {...a11yProps(1)}
                            />
                            <Tab
                                key={`role-tab-item-${2}`}
                                label={t('Configuration permissions')}
                                sx={{
                                    textTransform: 'none',
                                }}
                                {...a11yProps(2)}
                            />
                        </Tabs>
                    </AppBar>
                </Box>

                {value === 0 && (
                    <div
                        hidden={value !== 0}
                        id="role-simple-tabpanel0"
                        role="tabpanel"
                    >
                        <Box
                            sx={{
                                padding: '28px 0 16px 0',
                            }}
                        >
                            <ContactsPermissions
                                changeInput={changeInput}
                                contactPermissions={contactPermissions}
                                contactsVisualizationsFiltered={
                                    contactsVisualizationsFiltered
                                }
                                formValues={formValues}
                                isDisabled={!formValues.isEnabledEdition}
                                isFetchingDetail={isFetchingDetail}
                            />
                        </Box>
                    </div>
                )}

                {value === 1 && (
                    <div
                        hidden={value !== 1}
                        id="role-simple-tabpanel1"
                        role="tabpanel"
                    >
                        <Box
                            sx={{
                                padding: '28px 0 16px 0',
                            }}
                        >
                            <ProposerActions
                                changeInput={changeInput}
                                formValues={formValues}
                                isDisabled={!formValues.isEnabledEdition}
                                isFetchingDetail={isFetchingDetail}
                                proposerPermissions={proposerPermissions}
                            />
                        </Box>
                    </div>
                )}

                {value === 2 && (
                    <div
                        hidden={value !== 2}
                        id="role-simple-tabpanel2"
                        role="tabpanel"
                    >
                        <Box
                            sx={{
                                padding: '28px 0 16px 0',
                            }}
                        >
                            <ConfigurationPermissions
                                changeInput={changeInput}
                                configurationPermissions={
                                    configurationPermissions
                                }
                                formValues={formValues}
                                isDisabled={!formValues.isEnabledEdition}
                            />
                        </Box>
                    </div>
                )}
            </Card>

            <ShowErrors
                errors={errors}
                mt={3}
                severity="error"
                variant="filled"
            />

            {formValues.isEnabledEdition && (
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={3}
                >
                    <Grid item>
                        <Button
                            color="secondary"
                            disabled={isFetchingDetail || isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={() => {
                                reset();
                                closeModalEdition();
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isFetchingDetail || isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={handleSubmit(handleClickSave)}
                            type="submit"
                        >
                            {isSaving ? `${t('Loading')}...` : t('Save')}
                        </Button>
                    </Grid>
                </Stack>
            )}
        </form>
    );
};

RoleForm.propTypes = {
    changeInput: PropTypes.func,
    closeModalEdition: PropTypes.func,
    configurationPermissions: PropTypes.array,
    contactPermissions: PropTypes.array,
    errors: PropTypes.array,
    fetchCategoriesSettings: PropTypes.func,
    formValues: PropTypes.object,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isFetchingDetail: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposerPermissions: PropTypes.array,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    configurationPermissions: selectors.getConfigurationPermissions,
    contactPermissions: selectors.getContactPermissions,
    errors: selectors.getErrorsSaveItem,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isFetchingDetail: selectors.getIsFetchingRoleDetail,
    isSaving: selectors.getIsSavingItem,
    proposerPermissions: selectors.getProposerPermissions,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    fetchCategoriesSettings: () => dispatch(actions.fetchCategoriesSettings()),
    handleClickSave: (values) => dispatch(actions.save(values)),
    closeModalEdition: () => dispatch(actions.toggleModalEdition(false)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(RoleForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
