import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { Box, Stack, TextField } from 'common/ui';

const TextResponse = ({
    defaultTextResponse,
    textResponse,
    onSetTextResponse,
}) => {
    useEffect(() => {
        onSetTextResponse(defaultTextResponse);

        return () => {
            onSetTextResponse('');
        };
    }, []);

    return (
        <Box mt={2}>
            <Stack>
                <TextField
                    onChange={(event) => onSetTextResponse(event.target.value)}
                    value={textResponse}
                />
            </Stack>
        </Box>
    );
};

TextResponse.propTypes = {
    defaultTextResponse: PropTypes.string,
    textResponse: PropTypes.string,
    onSetTextResponse: PropTypes.func,
};

export default TextResponse;
