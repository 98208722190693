import React from 'react';

import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import { Skeleton, TableCell, TableRow, Typography } from 'common/ui';

const buildSkeletonStruct = (cols, rows) => {
    const colsItems = [];
    const rowsItems = [];

    for (let i = 0; i < cols; i++) {
        colsItems.push(
            <TableCell
                key={Math.random()}
                style={{
                    margin: '6px',
                    padding: '12px 16px',
                }}
            >
                <Typography>
                    <Skeleton variant="text"></Skeleton>
                </Typography>
            </TableCell>
        );
    }

    for (let i = 0; i < rows; i++) {
        rowsItems.push(
            <TableRow
                key={Math.random()}
                sx={{
                    marginBottom: '2px',
                }}
            >
                {colsItems}
            </TableRow>
        );
    }

    return rowsItems;
};

const Placeholder = ({ cols = 1, rows = 1 }) => buildSkeletonStruct(cols, rows);

Placeholder.propTypes = {
    cols: PropTypes.number,
    rows: PropTypes.number,
};

const PlaceholderCustomTable = ({ children, cols = 0, ready, rows = 20 }) => {
    const upLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const dynamicCols = cols > 0 ? cols : upLg ? 8 : downSm ? 2 : 5;
    return (
        <>{ready ? <Placeholder cols={dynamicCols} rows={rows} /> : children}</>
    );
};

PlaceholderCustomTable.propTypes = {
    children: PropTypes.node,
    cols: PropTypes.number,
    ready: PropTypes.bool,
    rows: PropTypes.number,
};

export default PlaceholderCustomTable;
