import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const DUPLICATE = `${NAME}/DUPLICATE`;
export const DUPLICATE_SUCCESS = `${NAME}/DUPLICATE_SUCCESS`;
export const DUPLICATE_FAILURE = `${NAME}/DUPLICATE_FAILURE`;

export const FETCH_PANELS = `${NAME}/FETCH_PANELS`;
export const FETCH_PANELS_FAILURE = `${NAME}/FETCH_PANELS_FAILURE`;
export const FETCH_PANELS_SUCCESS = `${NAME}/FETCH_PANELS_SUCCESS`;

export const FETCH_PANEL_DETAIL = `${NAME}/FETCH_PANEL_DETAIL`;
export const FETCH_PANEL_DETAIL_FAILURE = `${NAME}/FETCH_PANEL_DETAIL_FAILURE`;
export const FETCH_PANEL_DETAIL_SUCCESS = `${NAME}/FETCH_PANEL_DETAIL_SUCCESS`;

export const FETCH_BRANDS = `${NAME}/FETCH_BRANDS`;
export const FETCH_BRANDS_SUCCESS = `${NAME}/FETCH_BRANDS_SUCCESS`;
export const FETCH_BRANDS_FAILURE = `${NAME}/FETCH_BRANDS_FAILURE`;

export const FETCH_FAMILIES = `${NAME}/FETCH_FAMILIES`;
export const FETCH_FAMILIES_SUCCESS = `${NAME}/FETCH_FAMILIES_SUCCESS`;
export const FETCH_FAMILIES_FAILURE = `${NAME}/FETCH_FAMILIES_FAILURE`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_PRODUCT_FORM = `${NAME}/OPEN_PRODUCT_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;

export const UPDATE_ITEMS_SUCCESS = `${NAME}/UPDATE_ITEMS_SUCCESS`;
