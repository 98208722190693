import initializeForm from './initializeForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleProductForm from './toggleProductForm';

export default (item) => (dispatch) => {
    dispatch(initializeForm(item));
    dispatch(setIsNewRecord(false));
    dispatch(setEnabledEdition(false));
    dispatch(toggleProductForm(true));
};
