import { getDocuments } from 'common/api/ocr/projects';

import {
    FETCH_FILES,
    FETCH_FILES_FAILURE,
    FETCH_FILES_SUCCESS,
} from '../actionTypes';

export default (filter) => (dispatch) => {
    dispatch({ type: FETCH_FILES });

    const {
        address,
        is_bimonthly = -1,
        name,
        rate,
        service_number,
        zip_code,
        status = 1,
    } = filter || {};

    getDocuments({
        address: address || null,
        is_bimonthly: is_bimonthly === -1 ? null : is_bimonthly === 1,
        name: name || null,
        rate: rate || null,
        service_number: service_number || null,
        zip_code: zip_code || null,
        ...(status ? { status: 0 } : { exclude_status: 0 }),
    })
        .then((response) =>
            dispatch({ type: FETCH_FILES_SUCCESS, payload: response.data })
        )
        .catch((error) => {
            dispatch({
                type: FETCH_FILES_FAILURE,
                payload: error?.response?.data?.detail,
            });

            dispatch({ type: FETCH_FILES_SUCCESS, payload: [] });
        });
};
