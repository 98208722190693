import React from 'react';

import PropTypes from 'prop-types';

import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
} from 'common/ui';

const Placeholder = () => (
    <List>
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <React.Fragment>
                        <Skeleton></Skeleton>
                    </React.Fragment>
                }
                secondary={
                    <React.Fragment>
                        <Skeleton></Skeleton>
                    </React.Fragment>
                }
            />
        </ListItem>
    </List>
);

const PlaceholderProductDatasheet = ({ children, ready }) => {
    return <>{ready ? <Placeholder /> : children}</>;
};

PlaceholderProductDatasheet.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderProductDatasheet;
