import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getTemplatesModel = createSelector(
    getModel,
    (model) => model.templates
);

export const getTemplatesData = createSelector(getTemplatesModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((template) => ({
              ...template,
              category: get(template, 'category.name', ''),
              country: get(template, 'country.name', ''),
              languages: !isEmpty(template.languages) ? template.languages : [],
              language_name: !isEmpty(template.languages)
                  ? template.languages[0].name
                  : i18next.t('No language'),
          }))
);

export const getTemplatesErrors = createSelector(
    getTemplatesModel,
    (model) => model.errors
);

export const getTemplatesIsFetching = createSelector(
    getTemplatesModel,
    (model) => model.isFetching
);

export const getTemplatesPagination = createSelector(
    getTemplatesModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenTemplateForm = createSelector(
    getModel,
    (model) => model.isOpenTemplateForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getTemplateDetail = createSelector(
    getModel,
    (model) => model.templateDetail
);

export const getIsFetchingTemplateDetail = createSelector(
    getTemplateDetail,
    (templateDetail) => templateDetail.isFetching
);

export const getFetchCategories = createSelector(
    getModel,
    (model) => model.fetchCategories
);

export const getIsFetchingCategories = createSelector(
    getFetchCategories,
    (fetchCategories) => fetchCategories.isFetching
);

export const getCategoriesOptionsForSelect = createSelector(
    getFetchCategories,
    (fetchCategories) => {
        let items = fetchCategories.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));
        return items;
    }
);

export const getFetchOrigins = createSelector(
    getModel,
    (model) => model.fetchOrigins
);

export const getIsFetchingOrigins = createSelector(
    getFetchOrigins,
    (fetchOrigins) => fetchOrigins.isFetching
);

export const getOriginsOptionsForSelect = createSelector(
    getFetchOrigins,
    (fetchOrigins) => {
        let items = fetchOrigins.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));
        return [
            {
                label: i18next.t('Global'),
                optionValue: 'global',
            },
        ].concat(items);
    }
);

export const getFetchLanguages = createSelector(
    getModel,
    (model) => model.fetchLanguages
);

export const getIsFetchingLanguages = createSelector(
    getFetchLanguages,
    (fetchLanguages) => fetchLanguages.isFetching
);

export const getLanguagesOptionsForSelect = createSelector(
    getFetchLanguages,
    (fetchLanguages) => {
        let items = fetchLanguages.data.map((item) => ({
            label: item.value,
            optionValue: item.key,
        }));

        const forcedOrder = ['es-mx', 'en-us', 'pt-br'];

        let orderedLanguages = [];

        forcedOrder.forEach((order) => {
            const item = items.find((i) => i.optionValue === order);
            if (!isNil(item)) orderedLanguages.push(item);
        });

        return orderedLanguages;
    }
);

export const getFetchCustomTemplates = createSelector(
    getModel,
    (model) => model.fetchCustomTemplates
);

export const getIsFetchingCustomTemplates = createSelector(
    getFetchCustomTemplates,
    (fetchCustomTemplates) => fetchCustomTemplates.isFetching
);

export const getCustomTemplatesOptionsForSelect = createSelector(
    getFetchCustomTemplates,
    (fetchCustomTemplates) => {
        let items = fetchCustomTemplates.data.map((item) => ({
            label: item.title,
            optionValue: item.id,
        }));

        items.unshift({
            value: null,
            label: i18next.t('Select a filtered base template'),
            disabled: true,
        });
        return items;
    }
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);

export const getDeleteTemplate = createSelector(
    getModel,
    (model) => model.deleteTemplate
);

export const getIsDeletingTemplate = createSelector(
    getDeleteTemplate,
    (deleteTemplate) => deleteTemplate.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteTemplate,
    (deleteTemplate) => deleteTemplate.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteTemplate,
    (deleteTemplate) => deleteTemplate.errors
);
