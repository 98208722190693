import { NAME } from './constants';

export const FETCH_ROLES = `${NAME}/FETCH_ROLES`;
export const FETCH_ROLES_FAILURE = `${NAME}/FETCH_ROLES_FAILURE`;
export const FETCH_ROLES_SUCCESS = `${NAME}/FETCH_ROLES_SUCCESS`;

export const FETCH_ROLE_DETAIL = `${NAME}/FETCH_ROLE_DETAIL`;
export const FETCH_ROLE_DETAIL_FAILURE = `${NAME}/FETCH_ROLE_DETAIL_FAILURE`;
export const FETCH_ROLE_DETAIL_SUCCESS = `${NAME}/FETCH_ROLE_DETAIL_SUCCESS`;

export const FETCH_CATEGORIES_SETTINGS = `${NAME}/FETCH_CATEGORIES_SETTINGS`;
export const FETCH_CATEGORIES_SETTINGS_FAILURE = `${NAME}/FETCH_CATEGORIES_SETTINGS_FAILURE`;
export const FETCH_CATEGORIES_SETTINGS_SUCCESS = `${NAME}/FETCH_CATEGORIES_SETTINGS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_MODAL_EDITION = `${NAME}/OPEN_MODAL_EDITION`;
export const SET_FILTERS = `${NAME}/SET_FILTERS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;
