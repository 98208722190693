import React from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getRoleName } from '../helpers';

import ActionsFormatter from './ActionsFormatter';
import NameFormatter from './NameFormatter';

const Columns = ({
    filterData,
    handleClickBulkItems,
    handleClickUpdate,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();

    let columns = [
        {
            editable: true,
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            hide: !isEmpty(rowModesModel),
            renderCell: (params) => {
                const { row } = params;
                return <NameFormatter row={row} />;
            },
            sortable: false,
        },
        {
            align: 'left',
            editable: true,
            field: 'first_name',
            flex: 1,
            headerName: t('Name'),
            hide: isEmpty(rowModesModel),
            minWidth: 180,
        },
        {
            align: 'left',
            editable: true,
            field: 'last_name',
            flex: 1,
            headerName: t('Last name'),
            hide: isEmpty(rowModesModel),
            minWidth: 180,
        },
        {
            field: 'role_name',
            flex: 1,
            headerName: t('Role name'),
            hide: !isEmpty(rowModesModel),
            renderCell: (params) => {
                const { row } = params;
                return get(row, 'company_group.name', '');
            },
            sortable: false,
        },
        {
            field: 'role_type',
            flex: 1,
            headerName: t('Role type'),
            hide: !isEmpty(rowModesModel),
            renderCell: (params) => {
                const { row } = params;
                return getRoleName(get(row, 'company_group.type', ''));
            },
            sortable: false,
        },
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return columns;
};

Columns.propTypes = {
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default Columns;
