import isArray from 'lodash/isArray';

import { updateDatasheet } from 'common/api/datasheets';

import {
    UPLOAD_DATASHEET,
    UPLOAD_DATASHEET_FAILURE,
    UPLOAD_DATASHEET_SUCCESS,
} from '../actionTypes';

import showForm from './showForm';

export default (values, productId) => (dispatch) =>
    new Promise((resolve) => {
        var data = new FormData();
        data.append('data_sheet_name', values.datasheet_name);

        if (isArray(values.datasheet_archive)) {
            data.append(
                'data_sheet',
                values.datasheet_archive && values.datasheet_archive[0]
            );
        }

        dispatch({ type: UPLOAD_DATASHEET });

        updateDatasheet(data, productId)
            .then((response) => {
                dispatch({
                    type: UPLOAD_DATASHEET_SUCCESS,
                    payload: response.data,
                });
                dispatch(showForm(false));
            })
            .catch((error) =>
                dispatch({
                    type: UPLOAD_DATASHEET_FAILURE,
                    payload: error.response.data.errors,
                })
            )
            .finally(resolve);
    });
