import { stopTrainingModel } from 'common/api/ocr/openai';
import showToast from 'common/utils/showToast';

import {
    STOP_TRAINING,
    STOP_TRAINING_FAILURE,
    STOP_TRAINING_SUCCESS,
} from '../actionTypes';

import fetchModels from './fetchModels';

export default (modelId) => (dispatch) => {
    dispatch({ type: STOP_TRAINING });

    stopTrainingModel(modelId)
        .then(() => {
            dispatch({ type: STOP_TRAINING_SUCCESS });
            showToast({ autoClose: 3000 });
            dispatch(fetchModels());
        })
        .catch((error) => {
            dispatch({
                type: STOP_TRAINING_FAILURE,
                payload: error?.response?.data?.detail,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
            });
        });
};
