import isEmpty from 'lodash/isEmpty';

import { updateUser } from 'common/api/companies';
import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchUsers from './fetchUsers';
import resetForm from './resetForm';
import toggleModalUser from './toggleModalUser';

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    let newValues = {
        first_name: values.first_name,
        last_name: values.last_name,
        second_surname: values.second_surname,
        phone: values.phone,
        birth_date: values.birth_date,
        company_group: values.company_group,
        position: values.position,
        email: values.email,
        is_enabled: values.is_enabled,
        has_tmp_password: values.has_tmp_password,
        tmp_password: values.tmp_password,
    };

    const isCustomOrGM = [
        ROLE_LEVELS.CUSTOMIZED,
        ROLE_LEVELS.GENERAL_MANAGER,
    ].includes(values?.role_level);

    if (isCustomOrGM) {
        newValues.branch_offices = !isEmpty(values.branch_offices)
            ? values.branch_offices.join()
            : '';
    } else {
        newValues.branch_office = values.branch_office;
    }

    dispatch({ type: SAVE });

    updateUser(values.company_id, values.id, newValues)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchUsers(
                    filters.id,
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleModalUser(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
