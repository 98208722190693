import React from 'react';

import { useTranslation } from 'react-i18next';

import { CardContainer, HeaderContainer, TitleIcon } from 'common/components';
import { Container, Grid, Typography } from 'common/ui';

import { ReactComponent as IcoProfile } from 'resources/icoProfile.svg';

import DataForm from './components/DataForm';

const ProfileContainer = () => {
    const { t } = useTranslation();
    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4" gutterBottom>
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoProfile}
                        title={t('My profile')}
                    />
                </Typography>
            </HeaderContainer>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CardContainer title={t('General information')}>
                        <DataForm />
                    </CardContainer>
                </Grid>

                <Grid item xs={12} mb={2}>
                    <CardContainer
                        maxContentHeight="365px"
                        subheader={t('Of the last months')}
                        title={t('User activity')}
                    >
                        {/* <>
                            {[...Array(15)].map((_, index) => (
                                <Typography
                                    key={`log-activity-user-${index}`}
                                    variant="body2"
                                    mb={2}
                                >
                                    Nombre Apellido actividad el DD / MM / AAAA
                                    a las 00:00 hrs.
                                </Typography>
                            ))}
                        </> */}
                    </CardContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

ProfileContainer.propTypes = {};

export default ProfileContainer;
