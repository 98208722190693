import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';

import { TextField } from 'common/ui';

const InputSearch = ({
    disabled,
    onChange,
    placeholder,
    value,
    visible = true,
}) => {
    const handleOnChange = (e) => onChange(e);
    if (!visible) return null;
    return (
        <TextField
            disabled={disabled}
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            onChange={handleOnChange}
            placeholder={placeholder}
            value={value}
            variant="standard"
        />
    );
};

InputSearch.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    visible: PropTypes.bool,
};

export default InputSearch;
