import { getProjectsCounters } from 'common/api/companies';

import {
    FETCH_PROJECTS_COUNTERS,
    FETCH_PROJECTS_COUNTERS_FAILURE,
    FETCH_PROJECTS_COUNTERS_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeFilterForm from './initializeFilterForm';

export default (companyId, values) => (dispatch) => {
    const { agent, final_date: finalDate, initial_date: initialDate } = values;

    dispatch(companyGeneralActions[FETCH_PROJECTS_COUNTERS]());
    dispatch(initializeFilterForm(values));

    getProjectsCounters(companyId, initialDate, finalDate, agent)
        .then((response) =>
            dispatch(
                companyGeneralActions[FETCH_PROJECTS_COUNTERS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_PROJECTS_COUNTERS_FAILURE](
                    error.response.data.errors
                )
            )
        );
};
