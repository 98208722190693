import { NAME } from './constants';

export const DOWNLOAD_FILE = `${NAME}/DOWNLOAD_FILE`;
export const DOWNLOAD_FILE_FAILURE = `${NAME}/DOWNLOAD_FILE_FAILURE`;
export const DOWNLOAD_FILE_SUCCESS = `${NAME}/DOWNLOAD_FILE_SUCCESS`;

export const FETCH_FILES = `${NAME}/FETCH_FILES`;
export const FETCH_FILES_FAILURE = `${NAME}/FETCH_FILES_FAILURE`;
export const FETCH_FILES_SUCCESS = `${NAME}/FETCH_FILES_SUCCESS`;

export const SET_AS_SUCCESS = `${NAME}/SET_AS_SUCCESS`;
export const SET_AS_SUCCESS_FAILURE = `${NAME}/SET_AS_SUCCESS_FAILURE`;
export const SET_AS_SUCCESS_SUCCESS = `${NAME}/SET_AS_SUCCESS_SUCCESS`;
