import i18next from 'i18next';

import { updateCompanyOwner } from 'common/api/companies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeForm from './initializeForm';

const handleError = (dispatch) => {
    dispatch({
        type: SAVE_FAILURE,
        payload: [
            i18next.t('An error occurred during the update. Please try later'),
        ],
    });
    return;
};

export default (values, onSuccessCallback) => (dispatch) => {
    dispatch(companyGeneralActions[SAVE]());

    updateCompanyOwner({ email: values.owner.email }, values.owner.id)
        .then((response) => {
            if (!response.data) handleError(dispatch);
            dispatch(companyGeneralActions[SAVE_SUCCESS](response.data));
            dispatch(
                initializeForm({
                    ...values,
                    owner: {
                        ...values.owner,
                        email: response.data.email,
                    },
                })
            );
            if (onSuccessCallback) onSuccessCallback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyGeneralActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
