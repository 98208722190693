import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

// ADDONS REPORT
export const getAddonsReport = createSelector(
    getModel,
    (model) => model.addonsReport
);

export const getIsDownloadingAddonsReport = createSelector(
    getAddonsReport,
    (value) => value.isDownloading
);

export const getAddonsReportErrors = createSelector(
    getAddonsReport,
    (value) => value.errors
);

// BUREAU REPORT
export const getBureauReport = createSelector(
    getModel,
    (model) => model.bureauReport
);

export const getIsDownloadingBureauReport = createSelector(
    getBureauReport,
    (value) => value.isDownloading
);

export const getBureauReportErrors = createSelector(
    getBureauReport,
    (value) => value.errors
);

// CIBANCO ACTIVE ALLIANCE REPORT
export const getActiveCiBancoAlliancesReport = createSelector(
    getModel,
    (model) => model.activeCiBancoAlliancesReport
);

export const getIsDownloadingActiveCiBancoAlliancesReport = createSelector(
    getActiveCiBancoAlliancesReport,
    (value) => value.isDownloading
);

export const getActiveCiBancoAlliancesReportErrors = createSelector(
    getActiveCiBancoAlliancesReport,
    (value) => value.errors
);
