import { NAME } from './constants';

export const DOWNLOAD_CSV = `${NAME}/DOWNLOAD_CSV`;
export const DOWNLOAD_CSV_FAILURE = `${NAME}/DOWNLOAD_CSV_FAILURE`;
export const DOWNLOAD_CSV_SUCCESS = `${NAME}/DOWNLOAD_CSV_SUCCESS`;

export const FECH_COUNTRIES = `${NAME}/FECH_COUNTRIES`;
export const FECH_COUNTRIES_FAILURE = `${NAME}/FECH_COUNTRIES_FAILURE`;
export const FECH_COUNTRIES_SUCCESS = `${NAME}/FECH_COUNTRIES_SUCCESS`;

export const FETCH_FILES = `${NAME}/FETCH_FILES`;
export const FETCH_FILES_FAILURE = `${NAME}/FETCH_FILES_FAILURE`;
export const FETCH_FILES_SUCCESS = `${NAME}/FETCH_FILES_SUCCESS`;

export const FECH_POLITICAL_DIVISIONS = `${NAME}/FECH_POLITICAL_DIVISIONS`;
export const FECH_POLITICAL_DIVISIONS_FAILURE = `${NAME}/FECH_POLITICAL_DIVISIONS_FAILURE`;
export const FECH_POLITICAL_DIVISIONS_SUCCESS = `${NAME}/FECH_POLITICAL_DIVISIONS_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;
