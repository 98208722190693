import update from 'immutability-helper';

import {
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DOWNLOAD_CSV_SUCCESS,
    FETCH_BRANCH_OFFICES,
    FETCH_BRANCH_OFFICES_FAILURE,
    FETCH_BRANCH_OFFICES_SUCCESS,
    FETCH_ROLES,
    FETCH_ROLES_FAILURE,
    FETCH_ROLES_SUCCESS,
    FETCH_TEMPORAL_PASSWORD,
    FETCH_TEMPORAL_PASSWORD_FAILURE,
    FETCH_TEMPORAL_PASSWORD_SUCCESS,
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    FETCH_USER_DETAIL,
    FETCH_USER_DETAIL_FAILURE,
    FETCH_USER_DETAIL_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL_USER,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_FILTERS,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        allow_staff_support: false,
        birth_date: '',
        branch_office: '',
        branch_offices: [],
        company_group: '',
        company_id: '',
        email: '',
        first_name: '',
        has_tmp_password: false,
        id: null,
        is_enabled: true,
        isMultiBranchesCompany: false,
        last_name: '',
        phone: '',
        position: '',
        role_level: '',
        second_surname: '',
        tmp_password: '',
    },
    users: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    fetchRoles: {
        isFetching: false,
        data: [],
        error: null,
    },
    fetchBranchOffices: {
        isFetching: false,
        data: [],
        error: null,
    },
    downloadCSV: {
        isFetching: false,
        data: null,
        errors: [],
    },
    userDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    isOpenModalUser: false,
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    randomPassword: {
        isFetching: false,
        data: null,
        errors: [],
    },
    filters: {
        id: '',
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    deleteUser: {
        isDeleting: false,
        data: [],
        error: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DOWNLOAD_CSV:
            return update(state, {
                downloadCSV: { $merge: { errors: [], isFetching: true } },
            });

        case DOWNLOAD_CSV_FAILURE:
            return update(state, {
                downloadCSV: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case DOWNLOAD_CSV_SUCCESS:
            return update(state, {
                downloadCSV: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_USERS:
            return update(state, {
                users: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_USERS_FAILURE:
            return update(state, {
                users: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_USERS_SUCCESS:
            return update(state, {
                users: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_ROLES:
            return update(state, {
                fetchRoles: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_ROLES_SUCCESS:
            return update(state, {
                fetchRoles: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_ROLES_FAILURE:
            return update(state, {
                fetchRoles: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_BRANCH_OFFICES:
            return update(state, {
                fetchBranchOffices: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_BRANCH_OFFICES_SUCCESS:
            return update(state, {
                fetchBranchOffices: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_BRANCH_OFFICES_FAILURE:
            return update(state, {
                fetchBranchOffices: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_USER_DETAIL:
            return update(state, {
                userDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_USER_DETAIL_FAILURE:
            return update(state, {
                userDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_USER_DETAIL_SUCCESS:
            return update(state, {
                userDetail: { $merge: { errors: [], isFetching: false } },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
                deleteUser: {
                    $set: INITIAL_STATE.deleteUser,
                },
            });

        case OPEN_MODAL_USER:
            return update(state, {
                isOpenModalUser: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_TEMPORAL_PASSWORD:
            return update(state, {
                randomPassword: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_TEMPORAL_PASSWORD_FAILURE:
            return update(state, {
                randomPassword: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_TEMPORAL_PASSWORD_SUCCESS:
            return update(state, {
                randomPassword: { $merge: { errors: [], isFetching: false } },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case DELETE_USER:
            return update(state, {
                deleteUser: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_USER_SUCCESS:
            return update(state, {
                deleteUser: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_USER_FAILURE:
            return update(state, {
                deleteUser: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });

        default:
            return state;
    }
}
