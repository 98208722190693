import { filterLogs } from 'common/api/companyLogs';

import {
    FILTER_COMPANY_LOGS,
    FILTER_COMPANY_LOGS_FAILURE,
    FILTER_COMPANY_LOGS_SUCCESS,
} from '../actionTypes';

export default (filterData) => (dispatch) => {
    const { companyId, page = 1 } = filterData;

    dispatch({ type: FILTER_COMPANY_LOGS });

    filterLogs({
        companyId,
        page: page,
    })
        .then((response) =>
            dispatch({
                type: FILTER_COMPANY_LOGS_SUCCESS,
                payload: response.data,
            })
        )
        .catch((error) => {
            dispatch({
                type: FILTER_COMPANY_LOGS_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
