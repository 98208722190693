import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteApiAccount from './deleteApiAccount';

export default (item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [
            i18next.t(
                'The API Account "{{name}}" will be permanently deleted',
                {
                    name: `${item.title}`,
                }
            ),
        ],
        onSuccess: () => dispatch(deleteApiAccount(item.id)),
        title: i18next.t('Delete API'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(alerts.actions.show(dataAlert));
};
