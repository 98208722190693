import { getTrainingData } from 'common/api/ocr/fineTuning';

import {
    FETCH_TRAINING_DATA,
    FETCH_TRAINING_DATA_SUCCESS,
} from '../actionTypes';

import fetchFile from './fetchFile';
import initialize from './initialize';

export default (id, modelId) => (dispatch) => {
    dispatch({ type: FETCH_TRAINING_DATA });

    getTrainingData(id, modelId)
        .then((response) => {
            const data = response?.data;
            dispatch({ type: FETCH_TRAINING_DATA_SUCCESS, payload: data });

            dispatch(initialize(data));

            if (response?.data?.file) dispatch(fetchFile(response.data.file));
        })
        .catch((error) =>
            dispatch({
                type: FETCH_TRAINING_DATA_SUCCESS,
                payload: error?.response?.data?.detail,
            })
        );
};
