import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import { Box, Divider, Typography } from 'common/ui';

import { PROPOSER_CATEGORY_UUID } from '../constants';

import GroupItem from './GroupItem';
import GroupItemSwitch from './GroupItemSwitch';

const ReduxFieldArray = ({
    category,
    control,
    disabled,
    proposerIndex,
    setValue,
    subscription,
}) => {
    const { fields } = useFieldArray({
        control,
        name: 'permissions',
    });

    const addons = get(subscription, 'addons', []);

    return fields.map((item, catIndex) => {
        if (item.category !== category) return null;
        if (item.subcategories)
            return item.subcategories.map((subcategory, subIndex) => (
                <Box key={subcategory.id} py={1}>
                    <Typography fontWeight="bold" variant="body2">
                        {subcategory.name}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {subcategory?.groups.map((group, index) => {
                        if (category === PROPOSER_CATEGORY_UUID)
                            return (
                                <GroupItemSwitch
                                    addons={addons}
                                    control={control}
                                    disabled={disabled}
                                    index={index}
                                    item={group}
                                    key={`group-${group.id}`}
                                    name={`permissions.${catIndex}.subcategories.${subIndex}.groups.${index}.permission_checked`}
                                />
                            );
                        else
                            return (
                                <GroupItem
                                    addons={addons}
                                    categories={fields}
                                    control={control}
                                    disabled={disabled}
                                    index={index}
                                    item={group}
                                    key={`group-${group.id}`}
                                    name={`permissions.${catIndex}.subcategories.${subIndex}.groups.${index}.permission_level`}
                                    proposerIndex={proposerIndex}
                                    setValue={setValue}
                                />
                            );
                    })}
                </Box>
            ));
        if (item.groups)
            return item.groups.map((group, index) => (
                <GroupItem
                    addons={addons}
                    categories={fields}
                    control={control}
                    disabled={disabled}
                    index={index}
                    item={group}
                    key={`group-${group.id}`}
                    name={`permissions.${catIndex}.groups.${index}.permission_level`}
                    proposerIndex={proposerIndex}
                    setValue={setValue}
                />
            ));
    });
};

ReduxFieldArray.propTypes = {
    category: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    proposerIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setValue: PropTypes.func,
    subscription: PropTypes.object,
};

export default ReduxFieldArray;
