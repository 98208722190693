import { NAME } from './constants';

export const CREATE_TRAINING_DOCUMENT = `${NAME}/CREATE_TRAINING_DOCUMENT`;
export const CREATE_TRAINING_DOCUMENT_FAILURE = `${NAME}/CREATE_TRAINING_DOCUMENT_FAILURE`;
export const CREATE_TRAINING_DOCUMENT_SUCCESS = `${NAME}/CREATE_TRAINING_DOCUMENT_SUCCESS`;

export const DELETE_MODEL = `${NAME}/DELETE_MODEL`;
export const DELETE_MODEL_FAILURE = `${NAME}/DELETE_MODEL_FAILURE`;
export const DELETE_MODEL_SUCCESS = `${NAME}/DELETE_MODEL_SUCCESS`;

export const DOWNLOAD_TRAINING_DOCUMENT = `${NAME}/DOWNLOAD_TRAINING_DOCUMENT`;
export const DOWNLOAD_TRAINING_DOCUMENT_FAILURE = `${NAME}/DOWNLOAD_TRAINING_DOCUMENT_FAILURE`;
export const DOWNLOAD_TRAINING_DOCUMENT_SUCCESS = `${NAME}/DOWNLOAD_TRAINING_DOCUMENT_SUCCESS`;

export const DUPLICATE_MODEL = `${NAME}/DUPLICATE_MODEL`;
export const DUPLICATE_MODEL_FAILURE = `${NAME}/DUPLICATE_MODEL_FAILURE`;
export const DUPLICATE_MODEL_SUCCESS = `${NAME}/DUPLICATE_MODEL_SUCCESS`;

export const FETCH_MODELS = `${NAME}/FETCH_MODELS`;
export const FETCH_MODELS_FAILURE = `${NAME}/FETCH_MODELS_FAILURE`;
export const FETCH_MODELS_SUCCESS = `${NAME}/FETCH_MODELS_SUCCESS`;

export const INITIALIZE_MODEL_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_DUPLICATE_MODEL_FORM = `${NAME}/INITIALIZE_DUPLICATE_MODEL_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_DUPLICATE_MODEL_FORM = `${NAME}/RESET_DUPLICATE_MODEL_FORM`;
export const RESET_MODEL_FORM = `${NAME}/RESET_MODEL_FORM`;

export const SAVE_MODEL = `${NAME}/SAVE_MODEL`;
export const SAVE_MODEL_FAILURE = `${NAME}/SAVE_MODEL_FAILURE`;
export const SAVE_MODEL_SUCCESS = `${NAME}/SAVE_MODEL_SUCCESS`;

export const SET_IS_NEW_MODEL = `${NAME}/SET_IS_NEW_MODEL`;
export const SET_IS_OPEN_DUPLICATE_MODEL_FORM = `${NAME}/SET_IS_OPEN_DUPLICATE_MODEL_FORM`;
export const SET_IS_OPEN_MODEL_FORM = `${NAME}/SET_IS_OPEN_MODAL_FORM`;

export const START_TRAINING = `${NAME}/START_TRAINING`;
export const START_TRAINING_FAILURE = `${NAME}/START_TRAINING_FAILURE`;
export const START_TRAINING_SUCCESS = `${NAME}/START_TRAINING_SUCCESS`;

export const STOP_TRAINING = `${NAME}/STOP_TRAINING`;
export const STOP_TRAINING_FAILURE = `${NAME}/STOP_TRAINING_FAILURE`;
export const STOP_TRAINING_SUCCESS = `${NAME}/STOP_TRAINING_SUCCESS`;
