import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';

import * as rateHistoryModalActions from '../../rateHistoryModal/actions';

const RateHistoryPreviewTable = ({
    handleClickEditRateHistory,
    handleSortable,
    isEnabledEdition,
    isFetching,
    items,
    month,
    orderdBy,
    pagination,
    rateLabel,
    selectedRate,
    sortedBy,
    year,
}) => {
    const { t } = useTranslation();

    const getDynamicColumns = () => {
        var dynamicDataColumns = [];
        if (!isEmpty(items)) {
            var keys = Object.keys(items[0]);

            if (!isEmpty(keys)) {
                dynamicDataColumns = keys
                    .filter(
                        (k) => !['id', 'year', 'label', 'month'].includes(k)
                    )
                    .map((k) => {
                        return {
                            dataField: k,
                            formatter: FieldNameFormatter,
                            label: k,
                            width: '10%',
                        };
                    });
            }
        }

        return [
            {
                dataField: 'year',
                formatter: FieldNameFormatter,
                handleSortable: handleSortable,
                sortable: true,
                label: t('Year', { count: 1 }),
                width: '10%',
            },
            {
                dataField: 'label',
                formatter: FieldNameFormatter,
                handleSortable: handleSortable,
                sortable: true,
                label: rateLabel,
                width: '10%',
            },
            {
                dataField: 'month',
                formatter: FieldNameFormatter,
                handleSortable: handleSortable,
                sortable: true,
                label: t('Month', { count: 1 }),
                width: '10%',
            },
        ].concat(dynamicDataColumns);
    };

    return (
        <CustomTable
            columns={getDynamicColumns()}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={
                isEnabledEdition
                    ? () => {
                          handleClickEditRateHistory(selectedRate, year, month);
                      }
                    : null
            }
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

RateHistoryPreviewTable.propTypes = {
    handleClickEditRateHistory: PropTypes.func,
    handleSortable: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    orderdBy: PropTypes.string,
    pagination: PropTypes.object,
    rateLabel: PropTypes.string,
    selectedRate: PropTypes.string,
    sortedBy: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapDispatchToProps = (dispatch) => ({
    handleClickEditRateHistory: (rate, year, month) =>
        dispatch(
            rateHistoryModalActions.prepareRateHistoryDatagrid(
                rate,
                year,
                month
            )
        ),
});

export default connect(null, mapDispatchToProps)(RateHistoryPreviewTable);
