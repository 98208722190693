import { SAVE } from '../actionTypes';

import createApiAccount from './createApiAccount';
import updateApiAccount from './updateApiAccount';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE });
    if (values.id) {
        dispatch(updateApiAccount(values, values.id));
    } else {
        dispatch(createApiAccount(values));
    }
};
