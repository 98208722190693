import React from 'react';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { Box, IconButton, InputAdornment, TextField } from 'common/ui';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value || 0,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix={props.prefix}
            decimalScale={props.decimalScale}
            fixedDecimalScale={props.fixedDecimalScale}
            {...other}
        />
    );
});

NumberFormatCustom.propTypes = {
    onChange: PropTypes.func,
    prefix: PropTypes.string,
    decimalScale: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
};

const ReduxFieldNumberInput = ({
    borderRadius,
    contentEndAdornment,
    contentStartAdornment,
    decimalScale = 0,
    defaultValue,
    endAdornmentIcon,
    fixedDecimalScale = false,
    fontSize,
    fontWeight,
    fullWidth = true,
    handleClickButtonEndAdornment,
    handleClickButtonStartAdornment,
    input: { value, onChange, onBlur },
    meta: { touched, error },
    min,
    placeholder,
    prefix = ' ',
    startAdornmentIcon,
    step,
    textAlign,
    type,
    variant = 'filled',
    ...other
}) => {
    const onChangeAction = (event) => {
        onChange(event.target.value);
    };

    const onBlurAction = (event, blurValue) => {
        if (type === 'number' && event.target.value === '') {
            event.preventDefault();
            blurValue = min || 0;
            event.target.value = min || 0;
        }

        onBlur(event, blurValue);
    };

    return (
        <Box
            sx={{
                '& .MuiInputBase-input': {
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    textAlign: textAlign,
                },
                '& .MuiTextField-root': {
                    '& fieldset': {
                        borderRadius: borderRadius,
                    },
                },
            }}
        >
            <TextField
                error={Boolean(touched && error)}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (endAdornmentIcon || contentEndAdornment) && (
                        <InputAdornment position="end">
                            {endAdornmentIcon ? (
                                <IconButton
                                    onClick={handleClickButtonEndAdornment}
                                    edge="end"
                                >
                                    {endAdornmentIcon}
                                </IconButton>
                            ) : (
                                contentEndAdornment
                            )}
                        </InputAdornment>
                    ),
                    startAdornment: (startAdornmentIcon ||
                        contentStartAdornment) && (
                        <InputAdornment position="start">
                            {startAdornmentIcon ? (
                                <IconButton
                                    onClick={handleClickButtonStartAdornment}
                                    edge="end"
                                >
                                    {startAdornmentIcon}
                                </IconButton>
                            ) : (
                                contentStartAdornment
                            )}
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    prefix: prefix,
                    decimalScale: decimalScale,
                    fixedDecimalScale: fixedDecimalScale,
                }}
                label={placeholder}
                defaultValue={defaultValue}
                helperText={touched && error}
                variant={variant}
                onBlur={onBlurAction}
                onFocus={(e) => {
                    e.target.select();
                }}
                onChange={(e) => onChangeAction(e)}
                placeholder={placeholder}
                step={step}
                type={type}
                value={value}
                fullWidth={fullWidth}
                {...other}
            />
        </Box>
    );
};

ReduxFieldNumberInput.propTypes = {
    borderRadius: PropTypes.string,
    contentEndAdornment: PropTypes.string,
    contentStartAdornment: PropTypes.string,
    decimalScale: PropTypes.number,
    defaultValue: PropTypes.string,
    endAdornmentIcon: PropTypes.node,
    fixedDecimalScale: PropTypes.bool,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    fullWidth: PropTypes.bool,
    handleClickButtonEndAdornment: PropTypes.func,
    handleClickButtonStartAdornment: PropTypes.func,
    input: PropTypes.object,
    meta: PropTypes.object,
    min: PropTypes.string,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    startAdornmentIcon: PropTypes.node,
    step: PropTypes.string,
    textAlign: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
};

export default ReduxFieldNumberInput;
