import React, { useEffect, useRef, useState } from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { JSONViewer } from 'common/components';
import { Button } from 'common/ui';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { parseJson } from '../helpers';
import mainCompletionSchema from '../mainCompletionSchema.json';
import * as selectors from '../selectors';
import validate from '../validate';

import ColumnWrapper from './ColumnWrapper';
import Form from './Form';
import ToggleDevMode from './ToggleDevMode';

const CompletionColumn = ({
    disabled,
    handleOnSave,
    initialValues,
    isSaving,
    page,
    resetForm,
}) => {
    const [isDevMode, setIsDevMode] = useState(false);
    const [json, setJson] = useState('');
    const editorRef = useRef(null);
    const { t } = useTranslation();
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => () => resetForm(), []);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isDevMode) handleChangeJson();
    }, [page]);

    const handleEditorDidMount = (editor, instance) => {
        editorRef.current = editor;
        editorRef.current.getModelMarkers = instance.editor.getModelMarkers;
    };

    const handleEditorWillMount = (monaco) => {
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            schemaValidation: 'error',
            schemas: [
                {
                    fileMatch: ['*'],
                    schema: mainCompletionSchema,
                    uri: mainCompletionSchema.$id,
                },
            ],
            validate: true,
        });
    };

    const completionKey = `values.${page}.completion`;

    const handleChangeJson = () => {
        setJson(JSON.stringify(getValues(completionKey), null, 2));
    };

    const handleToggleDevMode = () => {
        if (!isDevMode) handleChangeJson();
        setIsDevMode(!isDevMode);
    };

    const handleOnChangeEditor = (value) => {
        const parsedValue = parseJson(value);
        setJson(value);
        if (parsedValue !== null) setValue(completionKey, parsedValue);
    };

    return (
        <>
            <ToggleDevMode
                isDevMode={isDevMode}
                setIsDevMode={handleToggleDevMode}
            />

            {isDevMode ? (
                <ColumnWrapper>
                    <JSONViewer
                        beforeMount={handleEditorWillMount}
                        json={json}
                        onChange={handleOnChangeEditor}
                        onMount={handleEditorDidMount}
                        path={`${initialValues?.id || ''}-${completionKey}`}
                        readOnly={disabled}
                    />
                </ColumnWrapper>
            ) : (
                <Form
                    baseName={completionKey}
                    control={control}
                    disabled={disabled}
                    key={completionKey}
                    page={page}
                    setValue={setValue}
                />
            )}

            <Button
                disabled={disabled || isSaving}
                onClick={handleSubmit(handleOnSave)}
                startIcon={!isEmpty(errors) && <WarningIcon />}
            >
                {t('Save')}
            </Button>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingTrainingData,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
});

CompletionColumn.propTypes = {
    disabled: PropTypes.bool,
    handleOnSave: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    page: PropTypes.number,
    resetForm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletionColumn);
