import React from 'react';

import PropTypes from 'prop-types';

import { Box, Card, CardContent, Grid, Skeleton, Typography } from 'common/ui';

const Placeholder = () => (
    <Card sx={{ display: 'flex' }}>
        <Grid container spacing={3}>
            <Grid item xs={6} sm={4} md={3} lg={2}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Skeleton variant="rectangular" height={180} />
                    <Typography
                        align="center"
                        variant="caption"
                        color="text.secondary"
                        component="div"
                    >
                        <Skeleton></Skeleton>
                    </Typography>
                </CardContent>
            </Grid>
            <Grid item xs={6} sm={8} md={9} lg={10}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">
                            <Skeleton></Skeleton>
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                        >
                            <Skeleton></Skeleton>
                        </Typography>
                    </CardContent>
                </Box>
            </Grid>
        </Grid>
    </Card>
);

const PlaceholderProductHeader = ({ children, ready }) => {
    return <>{ready ? <Placeholder /> : children}</>;
};

PlaceholderProductHeader.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderProductHeader;
