import i18next from 'i18next';
import get from 'lodash/get';

import { getAccessoryDetail } from 'common/api/accessories';
import fetchProductDatasheet from 'common/modules/productDatasheet/actions/fetchProductDatasheet';
import fetchProductThumbnail from 'common/modules/productThumbnail/actions/fetchProductThumbnail';
import showToast from 'common/utils/showToast';

import {
    FETCH_ACCESSORY_DETAIL,
    FETCH_ACCESSORY_DETAIL_FAILURE,
    FETCH_ACCESSORY_DETAIL_SUCCESS,
} from '../actionTypes';

import fetchBrands from './fetchBrands';
import fetchFamilies from './fetchFamilies';
import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleProductForm from './toggleProductForm';

export default (item) => (dispatch) => {
    dispatch({ type: FETCH_ACCESSORY_DETAIL });
    dispatch(resetForm());
    dispatch(toggleProductForm(true));

    getAccessoryDetail(item.id)
        .then(async (response) => {
            dispatch({
                type: FETCH_ACCESSORY_DETAIL_SUCCESS,
                payload: response.data,
            });

            const datasheetData = await dispatch(
                fetchProductDatasheet(item.id)
            );

            const thumbnailData = await dispatch(
                fetchProductThumbnail(item.id)
            );

            const values = {
                ...response.data,
                product_type: parseInt(response.data.product_type),
                brand: get(response.data, 'brand.id', ''),
                family: get(response.data, 'family.id', ''),
                costing_unit: parseInt(
                    get(response.data, 'costing_unit.key', -1)
                ),
                status_flag: item.status_flag,
                image_thumbnail: thumbnailData.image_thumbnail,
                datasheet_name: datasheetData.data_sheet_name,
                datasheet_archive: datasheetData.data_sheet,
            };

            dispatch(fetchBrands(get(response.data, 'brand.name', '')));

            dispatch(
                fetchFamilies(
                    get(response.data, 'brand.id', ''),
                    get(response.data, 'family.name', '')
                )
            );

            dispatch(setIsNewRecord(false));
            dispatch(initializeForm(values));
            dispatch(setEnabledEdition(false));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_ACCESSORY_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t('An error occurred while querying the product'),
            });

            dispatch(toggleProductForm(false));
        });
};
