import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { HeaderContainer, TitleIcon, TabsSystem } from 'common/components';
import { Container, Typography } from 'common/ui';

import { ReactComponent as IcoProducts } from 'resources/icoProducts.svg';

import accessoriesConfiguration from '../accesoriesConfiguration';
import * as accessoriesConfigurationActions from '../accesoriesConfiguration/actions';
import batteryConfiguration from '../batteryConfiguration';
import * as batteryConfigurationActions from '../batteryConfiguration/actions';
import invertersConfiguration from '../invertersConfiguration';
import * as invertersConfigurationActions from '../invertersConfiguration/actions';
import panelConfiguration from '../panelConfiguration';
import * as panelConfigurationActions from '../panelConfiguration/actions';

import { HeaderBreadcrumbs } from './components';
import { BATTERIES, PANELS, INVERTERS, ACCESSORIES } from './constants';

const getTabs = (t, setCurrentProduct) => [
    {
        id: PANELS,
        label: t('Panel', { count: 2 }),
        content: (
            <panelConfiguration.Container
                setCurrentProduct={setCurrentProduct}
            />
        ),
    },
    {
        id: INVERTERS,
        label: t('Inverter', { count: 2 }),
        content: (
            <invertersConfiguration.Container
                setCurrentProduct={setCurrentProduct}
            />
        ),
    },
    {
        id: BATTERIES,
        label: t('Battery', { count: 2 }),
        content: (
            <batteryConfiguration.Container
                setCurrentProduct={setCurrentProduct}
            />
        ),
    },
    {
        id: ACCESSORIES,
        label: t('Accessory', { count: 2 }),
        content: (
            <accessoriesConfiguration.Container
                setCurrentProduct={setCurrentProduct}
            />
        ),
    },
];

const ProductsCatalogContainer = ({
    match,
    prepareReturnAccessoriesCatalog,
    prepareReturnInvertersCatalog,
    prepareReturnPanelsCatalog,
    prepareReturnBatteriesCatalog,
}) => {
    const { t } = useTranslation();
    const [currentTabId, setCurrentTabId] = useState(PANELS);
    const [currentTabLabel, setCurrentTabLabel] = useState(
        t('Panel', { count: 2 })
    );
    const [currentProduct, setCurrentProduct] = useState({});

    const getLocations = () => {
        if (!isEmpty(currentProduct)) {
            return [
                {
                    label: t('Products catalog'),
                    link: `/products/catalog/${
                        match.params.tab ? match.params.tab : PANELS
                    }`,
                },
                {
                    label: currentTabLabel,
                    action: () => {
                        setCurrentProduct({});

                        switch (currentTabId) {
                            case INVERTERS:
                                return prepareReturnInvertersCatalog();
                            case ACCESSORIES:
                                return prepareReturnAccessoriesCatalog();
                            case BATTERIES:
                                return prepareReturnBatteriesCatalog();
                            default:
                                return prepareReturnPanelsCatalog();
                        }
                    },
                },
            ];
        }
        return [];
    };

    return (
        <Container maxWidth="" disableGutters>
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoProducts}
                        title={t('Product', { count: 2 })}
                    />
                </Typography>
            </HeaderContainer>

            <HeaderBreadcrumbs
                currentTabLabel={currentProduct?.name}
                locationsBreadcrumbs={getLocations()}
                titleIcon={IcoProducts}
                title={t('Products catalog')}
            />

            <TabsSystem
                baseUrl="products/catalog"
                tabs={getTabs(t, setCurrentProduct)}
                setCurrentTabId={setCurrentTabId}
                setCurrentTabLabel={setCurrentTabLabel}
            />
        </Container>
    );
};

ProductsCatalogContainer.propTypes = {
    match: PropTypes.object,
    prepareReturnAccessoriesCatalog: PropTypes.func,
    prepareReturnInvertersCatalog: PropTypes.func,
    prepareReturnPanelsCatalog: PropTypes.func,
    prepareReturnBatteriesCatalog: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    prepareReturnAccessoriesCatalog: () =>
        dispatch(accessoriesConfigurationActions.returnCatalog()),
    prepareReturnInvertersCatalog: () =>
        dispatch(invertersConfigurationActions.returnCatalog()),
    prepareReturnPanelsCatalog: () =>
        dispatch(panelConfigurationActions.returnCatalog()),
    prepareReturnBatteriesCatalog: () =>
        dispatch(batteryConfigurationActions.returnCatalog()),
});

export default connect(
    null,
    mapDispatchToProps
)(withRouter(ProductsCatalogContainer));
