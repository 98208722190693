import { getProjectReads } from 'common/api/afterSales/projects';

import {
    FETCH_LOG,
    FETCH_LOG_FAILURE,
    FETCH_LOG_SUCCESS,
} from '../actionTypes';

export default (projectId, readId, callback) => (dispatch) => {
    if (!projectId || !readId) return;

    dispatch({ type: FETCH_LOG });

    getProjectReads({ projectId, readId })
        .then((response) => {
            dispatch({
                payload: response?.data?.reads?.[0],
                type: FETCH_LOG_SUCCESS,
            });

            if (callback) callback();
        })
        .catch((error) =>
            dispatch({ payload: error, type: FETCH_LOG_FAILURE })
        );
};
