import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/DropdownCardMenu';
import { Box } from 'common/ui';

const ActionsFormatter = ({ filterData, handleClickDelete, row }) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            <DropdownCardMenu
                items={[
                    {
                        handleClick: () => handleClickDelete(filterData, row),
                        icon: <DeleteIcon fontSize="small" />,
                        label: t('Delete'),
                    },
                ]}
            />
        </Box>
    );
};

ActionsFormatter.propTypes = {
    filterData: PropTypes.object,
    handleClickDelete: PropTypes.func,
    row: PropTypes.object,
};

export default ActionsFormatter;
