import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomPagination } from 'common/components';
import { Card, CardContent, DataGrid, LinearProgress } from 'common/ui';
import { getDataGridClasses } from 'common/utils/helpers';

import {
    getCountriesOptions,
    getPoliticalDivisionsOptions,
    getRateOptions,
} from '../helpers';

import BulkOperationsCard from './BulkOperationsCard';
import ProductColumns from './ProductColumns';

const ListItems = ({
    countries,
    countriesIsFetching,
    downloadBulk,
    fetchPoliticalDivisions,
    fetchRates,
    filterData,
    filterItems,
    handleDownloadFile,
    isFetching,
    items,
    paginationData,
    politicalDivisions,
    politicalDivisionsIsFetching,
    rates,
    ratesIsFetching,
    setFilterData,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [rowCount, setRowCount] = useState(0);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (paginationData) {
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
        }
    }, [paginationData?.total_rows, setRowCount]);

    const handleOnChangeFilter = (value, name) => {
        const newFilterData = { ...filterData, [name]: value, page: 0 };
        setFilterData(newFilterData);

        if (name === 'country') {
            fetchPoliticalDivisions(value);
            fetchRates(value);
            setFilterData({ ...newFilterData, dp1: 'all', rate: 'all' });
        } else {
            setFilterData(newFilterData);
        }
    };

    const handleOnPageChange = (page) => {
        const newFilterData = { ...filterData, page: page };
        setFilterData(newFilterData);
        filterItems(newFilterData);
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;
        const newFilterData = {
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        };
        setFilterData(newFilterData);
        filterItems(newFilterData);
    };

    return (
        <>
            <BulkOperationsCard
                countries={getCountriesOptions(countries)}
                countriesIsFetching={countriesIsFetching}
                downloadBulk={downloadBulk}
                filterData={filterData}
                handleClickOnFilter={() => filterItems(filterData)}
                handleOnChangeFilter={handleOnChangeFilter}
                politicalDivisions={getPoliticalDivisionsOptions(
                    politicalDivisions
                )}
                politicalDivisionsIsFetching={politicalDivisionsIsFetching}
                ratesIsFetching={ratesIsFetching}
                ratesOptions={getRateOptions(rates)}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <CardContent>
                    <DataGrid
                        autoHeight
                        checkboxSelection
                        columns={ProductColumns(handleDownloadFile)}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: { pageSize: filterData?.pageSize },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData((filterData) => ({
                                ...filterData,
                                pageSize: newPage,
                            }))
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                        sx={{
                            ...getDataGridClasses(isDarkMode),
                            '& .MuiDataGrid-cell': {
                                cursor: 'pointer',
                                p: '10px',
                            },
                        }}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    countries: PropTypes.array,
    countriesIsFetching: PropTypes.bool,
    downloadBulk: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchRates: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleDownloadFile: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    politicalDivisions: PropTypes.array,
    politicalDivisionsIsFetching: PropTypes.bool,
    rates: PropTypes.array,
    ratesIsFetching: PropTypes.bool,
    setFilterData: PropTypes.func,
};

export default ListItems;
