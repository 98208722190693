import React from 'react';

import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import {
    Avatar,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from 'common/ui';

const CustomListItem = ({
    backgroundAvatar = 'primary',
    label,
    urlAvatar,
    value,
    withAvatar,
    withHorizontalDivider,
    withVerticalDivider,
}) => {
    const isAboveSmall = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const sx =
        withVerticalDivider && isAboveSmall
            ? {
                  '&:after': {
                      content: '""',
                      position: 'absolute',
                      height: '80%',
                      display: 'block',
                      right: 0,
                      width: '1px',
                      bgcolor: 'divider',
                  },
              }
            : {};
    return (
        <>
            <ListItem
                alignItems="flex-start"
                sx={sx}
                divider={withVerticalDivider && !isAboveSmall}
            >
                {withAvatar && (
                    <ListItemAvatar
                        sx={{
                            marginTop: '0px',
                        }}
                    >
                        <Avatar
                            alt={value}
                            src={urlAvatar || '/'}
                            sx={{
                                bgcolor: backgroundAvatar,
                            }}
                        />
                    </ListItemAvatar>
                )}
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Typography
                                sx={{
                                    display: 'inline',
                                    overflowWrap: 'break-word',
                                }}
                                component="span"
                                variant={withAvatar ? 'subtitle2' : 'body2'}
                                color={
                                    withAvatar
                                        ? 'text.primary'
                                        : 'text.secondary'
                                }
                            >
                                {withAvatar ? value : label}
                            </Typography>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{
                                    display: 'inline',
                                    overflowWrap: 'break-word',
                                }}
                                component="span"
                                variant={withAvatar ? 'body2' : 'subtitle2'}
                                color={
                                    withAvatar
                                        ? 'text.secondary'
                                        : 'text.primary'
                                }
                            >
                                {withAvatar ? label : value}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            {withHorizontalDivider && <Divider component="li" />}
        </>
    );
};

CustomListItem.propTypes = {
    backgroundAvatar: PropTypes.string,
    label: PropTypes.string,
    urlAvatar: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withAvatar: PropTypes.bool,
    withHorizontalDivider: PropTypes.bool,
    withVerticalDivider: PropTypes.bool,
};

export default CustomListItem;
