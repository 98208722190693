import API from '../.';
const ENTITY = 'products';

export const bulkEditionRow = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/row-edition/${id}/`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const bulkProducts = (data) =>
    API.put(
        `/api/v1/${ENTITY}/bulk-status-flags/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );

export const duplicateProduct = (data) =>
    API.post(`/api/v1/${ENTITY}/duplicate/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
