import { getApiAccounts } from 'common/api/apiAccounts';

import {
    FETCH_API_ACCOUNTS,
    FETCH_API_ACCOUNTS_FAILURE,
    FETCH_API_ACCOUNTS_SUCCESS,
} from '../actionTypes';

import setFilters from './setFilters';

export default (page = 1, orderBy = '', sortBy = '', searchStr = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_API_ACCOUNTS });
        dispatch(setFilters({ page, orderBy, sortBy, searchStr }));

        getApiAccounts(page, orderBy, sortBy, searchStr)
            .then((response) =>
                dispatch({
                    type: FETCH_API_ACCOUNTS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_API_ACCOUNTS_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_API_ACCOUNTS_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
