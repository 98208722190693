import React from 'react';

import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import {
    ReduxFieldCheck,
    ReduxFieldNumberInput,
} from 'common/components/form/redux';
import { Box, Button, Grid, Stack, Typography } from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const Form = ({
    changeInput,
    errors,
    filters = {},
    formValues,
    handleAfterSave = null,
    handleClickCloseModalOutstanding,
    handleClickSave,
    handleSubmit,
    initialValues,
    isDeleting,
    isFetchingDetail,
    isSaving,
    prepareDelete,
    reset,
}) => {
    const { t } = useTranslation();

    const handleDeleteOutstanding = (item) => {
        prepareDelete(item, () => {
            if (handleAfterSave)
                handleAfterSave(
                    filters?.page,
                    filters?.orderBy,
                    filters?.sortBy,
                    filters?.searchStr
                );
        });
    };

    const handleSaveAction = (values) => {
        handleClickSave(values, () => {
            if (handleAfterSave)
                handleAfterSave(
                    filters?.page,
                    filters?.orderBy,
                    filters?.sortBy,
                    filters?.searchStr
                );
        });
    };

    return (
        <form>
            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} md={5}>
                    <Field
                        autoFocus
                        name="days_left"
                        component={ReduxFieldNumberInput}
                        placeholder={t('Duration')}
                        variant="outlined"
                        required
                        disabled={isFetchingDetail || formValues.unlimited}
                        contentEndAdornment={t('Day', {
                            count: parseInt(formValues.days_left) || 2,
                        }).toLocaleLowerCase()}
                    />
                </Grid>
                {!initialValues.unlimited && !isNil(initialValues.days_left) && (
                    <Grid
                        item
                        container
                        xs={12}
                        md={3}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Typography
                            variant="caption"
                            color="text.secondary"
                        >{`${t('Remain')} ${initialValues.days_validity}/${
                            initialValues.days_left
                        }`}</Typography>
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    md={
                        !initialValues.unlimited &&
                        !isNil(initialValues.days_left)
                            ? 4
                            : 6
                    }
                >
                    <Field
                        name="unlimited"
                        component={ReduxFieldCheck}
                        label={t('Unlimited')}
                        disabled={isFetchingDetail}
                        id="redux-field-check-outstanding-template"
                        color="primary"
                        onChange={(event) => {
                            if (event.target.checked) {
                                changeInput('days_left', 0);
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <ShowErrors
                errors={errors}
                mt={3}
                severity="error"
                variant="filled"
            />

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
                mt={3}
            >
                {formValues.id && (
                    <Box mt={2}>
                        <Button
                            color="secondary"
                            disabled={
                                isFetchingDetail || isSaving || isDeleting
                            }
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={() => {
                                handleDeleteOutstanding(formValues);
                            }}
                        >
                            {t('Delete')}
                        </Button>
                    </Box>
                )}
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button
                            color="secondary"
                            disabled={isFetchingDetail || isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={() => {
                                reset();
                                handleClickCloseModalOutstanding();
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isFetchingDetail || isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={handleSubmit(handleSaveAction)}
                            type="submit"
                        >
                            {isSaving ? `${t('Loading')}...` : t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        </form>
    );
};

Form.propTypes = {
    changeInput: PropTypes.func,
    errors: PropTypes.array,
    filters: PropTypes.object,
    formValues: PropTypes.object,
    handleAfterSave: PropTypes.func,
    handleClickCloseModalOutstanding: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isDeleting: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isSaving: PropTypes.bool,
    prepareDelete: PropTypes.func,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isDeleting: selectors.getIsDeletingOutstanding,
    isFetchingDetail: selectors.getIsFetchingOutstandingDetail,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    prepareDelete: (item, callback) =>
        dispatch(actions.prepareDelete(item, callback)),
    handleClickCloseModalOutstanding: () =>
        dispatch(actions.toggleModal(false)),
    handleClickSave: (values, callback) =>
        dispatch(actions.save(values, callback)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
