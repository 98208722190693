import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors, TextVariant } from 'common/components';
import CardContainer from 'common/components/CardContainer';
import {
    ReduxFieldInput,
    ReduxFieldNumberInput,
} from 'common/components/form/redux';
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tooltip,
} from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const RateForm = ({
    errors,
    handleClickSave,
    handleEditionChange,
    handleSubmit,
    isEnabledEdition,
    isFetchingDetail,
    isNewRecord,
    isSaving,
    prepareReturnCatalog,
    setCurrentItem,
}) => {
    const { t } = useTranslation();

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    return (
        <Stack spacing={3}>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title={t('Back')}>
                    <IconButton
                        onClick={() => {
                            prepareReturnCatalog();
                            setCurrentItem({});
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <FormGroup>
                    <FormControlLabel
                        disabled={isNewRecord || isFetchingDetail}
                        control={
                            <Switch
                                checked={isEnabledEdition}
                                onChange={handleEditionSwitchChange}
                            />
                        }
                        label={t('Edit')}
                        labelPlacement="start"
                    />
                </FormGroup>
            </Stack>

            <Box>
                <form>
                    <Stack spacing={3}>
                        <CardContainer
                            title={t('Certified rate administration')}
                            isCollapsable
                            isOpen
                            loading={isFetchingDetail}
                            withDivider
                        >
                            <div>
                                <Grid container pt={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="name"
                                            component={ReduxFieldInput}
                                            placeholder={t('Name')}
                                            variant="outlined"
                                            required
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container pt={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="description"
                                            component={ReduxFieldInput}
                                            placeholder={t('Description')}
                                            variant="outlined"
                                            required
                                            disabled={
                                                isFetchingDetail ||
                                                !isEnabledEdition
                                            }
                                            multiline
                                            maxRows={6}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container pt={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="annual_percentage_increase"
                                            component={ReduxFieldNumberInput}
                                            placeholder={t(
                                                'Annual percentage increase'
                                            )}
                                            variant="outlined"
                                            required
                                            disabled
                                            contentEndAdornment="%"
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                    </Grid>
                                </Grid>

                                <Stack spacing={3} pt={3}>
                                    <TextVariant
                                        fontWeight="600"
                                        separatorLeft
                                        text={t('Other charges')}
                                    />
                                </Stack>

                                <Stack spacing={3} pt={3}>
                                    <TextVariant
                                        fontWeight="600"
                                        separatorLeft
                                        text={t('Rate taxes')}
                                    />
                                </Stack>
                            </div>
                        </CardContainer>

                        <ShowErrors
                            errors={errors}
                            mt={3}
                            severity="error"
                            variant="filled"
                        />

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={3}
                            pt={3}
                            pb={3}
                        >
                            <Grid item>
                                <Button
                                    color="secondary"
                                    disabled={isFetchingDetail || isSaving}
                                    fullWidth={false}
                                    height="48px"
                                    size="large"
                                    onClick={() => {
                                        prepareReturnCatalog();
                                        setCurrentItem({});
                                    }}
                                >
                                    {t('Cancel')}
                                </Button>
                            </Grid>
                            {isEnabledEdition && (
                                <Grid item>
                                    <Button
                                        disabled={isFetchingDetail || isSaving}
                                        fullWidth={false}
                                        height="48px"
                                        size="large"
                                        onClick={handleSubmit(handleClickSave)}
                                        type="submit"
                                    >
                                        {isSaving
                                            ? `${t('Loading')}...`
                                            : isNewRecord
                                            ? t('Create')
                                            : t('Save')}
                                    </Button>
                                </Grid>
                            )}
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </Stack>
    );
};

RateForm.propTypes = {
    errors: PropTypes.array,
    handleClickSave: PropTypes.func,
    handleEditionChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    prepareReturnCatalog: PropTypes.func,
    setCurrentItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isEnabledEdition: selectors.getEnabledEdition,
    isFetchingDetail: selectors.getIsFetchingRateDetail,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    prepareReturnCatalog: () => dispatch(actions.returnCatalog()),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(RateForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
