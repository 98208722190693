import axios from 'axios';
import get from 'lodash/get';
import { serialize } from 'object-to-formdata';

import {
    getUserSettings,
    getIanaTimezoneKey,
    getRuntimeLanguage,
} from '../utils/helpers/multiregion';

const CancelToken = axios.CancelToken;
let cancelableRequests = {};

const API = axios.create({ baseURL: process.env.REACT_APP_OCR_API_URL });

API.cancelAll = () => {
    Object.keys(cancelableRequests).forEach((key) => {
        cancelableRequests[key]();
        delete cancelableRequests[key];
    });
};

API.interceptors.request.use((req) => {
    const token = localStorage.getItem('token') || null;
    const customId = `${new Date().getTime()}`;

    if (token) req.headers['Authorization'] = `Bearer ${token}`;

    const settings = getUserSettings();
    const language = get(settings, 'language.key', getRuntimeLanguage());
    const timezone = get(settings, 'timezone.key', getIanaTimezoneKey());
    req.headers['Accept-Language'] = language;
    req.headers['X-Timezone-IANA'] = timezone;

    if (!req.headers['Content-Type'])
        req.headers['Content-Type'] = 'multipart/form-data';

    if (
        req.headers['Content-Type'] === 'multipart/form-data' &&
        (req.method === 'post' ||
            req.method === 'put' ||
            req.method === 'patch')
    )
        req.data = serialize(req.data, {
            noFilesWithArrayNotation: true,
        });

    req.customId = customId;
    req.cancelToken = new CancelToken(function executor(c) {
        cancelableRequests[customId] = c;
    });

    return req;
});

API.interceptors.response.use(
    (res) => {
        delete cancelableRequests[res.config.customId];
        return res;
    },
    (error) => {
        if (
            error.response &&
            cancelableRequests[error.response.config.customId]
        ) {
            delete cancelableRequests[error.response.config.customId];
        }
        if (
            error.code === 'ECONNABORTED' ||
            typeof error.response === 'undefined'
        ) {
            const _error = Object.assign({}, error);

            return Promise.reject({
                ..._error,
                data: { errors: ['Ocurrio un error de conexion'] },
                response: {
                    data: { errors: ['Ocurrio un error de conexion'] },
                },
            });
        }

        return Promise.reject(error);
    }
);

export default API;
