import { getRates } from 'common/api/rates';

import {
    FETCH_RATES,
    FETCH_RATES_SUCCESS,
    FETCH_RATES_FAILURE,
} from '../actionTypes';

export default (page = 1, perPage = 20, orderBy = '', sortBy = '', str = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_RATES });

        getRates(page, perPage, orderBy, sortBy, str)
            .then((response) => {
                dispatch({
                    type: FETCH_RATES_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_RATES_FAILURE,
                    payload: error.response.data.errors,
                })
            );
    };
