import { getRegions } from 'common/api/suppliers';

import {
    FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_REGIONS });

    getRegions()
        .then((response) => {
            dispatch({
                type: FETCH_REGIONS_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_REGIONS_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
