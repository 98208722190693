import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { HeaderContainer, TitleIcon, TabsSystem } from 'common/components';
import { Container, Typography } from 'common/ui';

import { ReactComponent as IcoTemplates } from 'resources/icoTemplates.svg';

import templateCatalog from '../templatesCatalog';
import * as templatesCatalogActions from '../templatesCatalog/actions';
import templatesCategories from '../templatesCategories';
import * as templatesCategoriesActions from '../templatesCategories/actions';
import templatesFonts from '../templatesFonts';
import * as templatesFontsActions from '../templatesFonts/actions';

import { HeaderBreadcrumbs } from './components';
import { CATALOG, CATEGORIES, FONTS } from './constants';

const getTabs = (t, setCurrentItem) => [
    {
        id: CATALOG,
        label: t('Catalog', { count: 1 }),
        content: <templateCatalog.Container setCurrentItem={setCurrentItem} />,
    },
    {
        id: CATEGORIES,
        label: t('Category', { count: 2 }),
        content: (
            <templatesCategories.Container setCurrentItem={setCurrentItem} />
        ),
    },
    {
        id: FONTS,
        label: t('Font', { count: 2 }),
        content: <templatesFonts.Container setCurrentItem={setCurrentItem} />,
    },
];

const TemplateContainer = ({
    match,
    prepareReturnTemplatesCatalog,
    prepareReturnTemplatesCategories,
    prepareReturnTemplatesFonts,
}) => {
    const { t } = useTranslation();
    const [currentTabId, setCurrentTabId] = useState(CATALOG);
    const [currentTabLabel, setCurrentTabLabel] = useState(
        t('Catalog', { count: 1 })
    );
    const [currentItem, setCurrentItem] = useState({});

    const getLocations = () => {
        if (!isEmpty(currentItem)) {
            return [
                {
                    label: t('Template', { count: 2 }),
                    link: `/templates/${
                        match.params.tab ? match.params.tab : CATALOG
                    }`,
                },
                {
                    label: currentTabLabel,
                    action: () => {
                        setCurrentItem({});

                        switch (currentTabId) {
                            case CATEGORIES:
                                return prepareReturnTemplatesCategories();
                            case FONTS:
                                return prepareReturnTemplatesFonts();
                            default:
                                return prepareReturnTemplatesCatalog();
                        }
                    },
                },
            ];
        }
        return [];
    };

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoTemplates}
                        title={t('Template', { count: 2 })}
                    />
                </Typography>
            </HeaderContainer>

            <HeaderBreadcrumbs
                currentTabLabel={currentItem?.name}
                locationsBreadcrumbs={getLocations()}
                titleIcon={IcoTemplates}
                title={currentTabLabel}
            />

            <TabsSystem
                baseUrl="templates"
                tabs={getTabs(t, setCurrentItem)}
                setCurrentTabId={setCurrentTabId}
                setCurrentTabLabel={setCurrentTabLabel}
            />
        </Container>
    );
};
TemplateContainer.propTypes = {
    match: PropTypes.object,
    prepareReturnTemplatesCatalog: PropTypes.func,
    prepareReturnTemplatesCategories: PropTypes.func,
    prepareReturnTemplatesFonts: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    prepareReturnTemplatesCatalog: () =>
        dispatch(templatesCatalogActions.returnCatalog()),
    prepareReturnTemplatesCategories: () =>
        dispatch(templatesCategoriesActions.returnCatalog()),
    prepareReturnTemplatesFonts: () =>
        dispatch(templatesFontsActions.returnCatalog()),
});

export default connect(null, mapDispatchToProps)(withRouter(TemplateContainer));
