import { number, object, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ REQUIRED_TEXT, getMaxValueText, getMinValueText }) =>
        object().shape({
            base_model: number().required(REQUIRED_TEXT).nullable(),
            name: string().required(REQUIRED_TEXT).nullable(),
            max_tokens: number()
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(4096, getMaxValueText(4096))
                .nullable(),
            stop_str: string().required(REQUIRED_TEXT).nullable(),
            temperature: number()
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(2, getMaxValueText(2))
                .nullable(),
        })
);
