import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';

const ProposalStatusIcon = ({ isGenerated }) => {
    if (isGenerated)
        return <CircleIcon sx={{ color: '#3caf5f', fontSize: 15 }} />;
    return <CircleIcon sx={{ color: '#f57d00', fontSize: 15 }} />;
};

ProposalStatusIcon.propTypes = {
    isGenerated: PropTypes.bool,
};

export default ProposalStatusIcon;
