import { NAME } from './constants';

export const DOWNLOAD_BUREAU_CSV_REPORT = `${NAME}/DOWNLOAD_BUREAU_CSV_REPORT`;
export const DOWNLOAD_BUREAU_CSV_REPORT_FAILURE = `${NAME}/DOWNLOAD_BUREAU_CSV_REPORT_FAILURE`;
export const DOWNLOAD_BUREAU_CSV_REPORT_SUCCESS = `${NAME}/DOWNLOAD_BUREAU_CSV_REPORT_SUCCESS`;

export const DOWNLOAD_ADDONS_CSV_REPORT = `${NAME}/DOWNLOAD_ADDONS_CSV_REPORT`;
export const DOWNLOAD_ADDONS_CSV_REPORT_FAILURE = `${NAME}/DOWNLOAD_ADDONS_CSV_REPORT_FAILURE`;
export const DOWNLOAD_ADDONS_CSV_REPORT_SUCCESS = `${NAME}/DOWNLOAD_ADDONS_CSV_REPORT_SUCCESS`;

export const ACTIVE_CIBANCO_ALLIANCES_REPORT = `${NAME}/ACTIVE_CIBANCO_ALLIANCES_REPORT`;
export const ACTIVE_CIBANCO_ALLIANCES_REPORT_FAILURE = `${NAME}/ACTIVE_CIBANCO_ALLIANCES_REPORT_FAILURE`;
export const ACTIVE_CIBANCO_ALLIANCES_REPORT_SUCCESS = `${NAME}/ACTIVE_CIBANCO_ALLIANCES_REPORT_SUCCESS`;
