import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/** Reads */
export const getReadsModel = createSelector(getModel, (model) => model.reads);

export const getReadsData = createSelector(
    getReadsModel,
    (reads) => reads.data
);

export const getReads = createSelector(
    getReadsData,
    (data) => data?.reads || []
);

export const getReadsPagination = createSelector(
    getReadsData,
    (data) => data?.pagination || {}
);

export const getReadsProject = createSelector(
    getReadsData,
    (reads) => reads.project
);

export const getReadsIsLoading = createSelector(
    getReadsModel,
    (reads) => reads.isLoading
);

export const getReadsErrors = createSelector(
    getReadsModel,
    (reads) => reads.errors
);

/** Read Log */
export const getReadLogModel = createSelector(
    getModel,
    (model) => model.read_log
);

export const getReadLog = createSelector(
    getReadLogModel,
    (read_log) => read_log.data
);

export const getReadLogStringified = createSelector(getReadLog, (read_log) =>
    JSON.stringify(read_log, null, 2)
);

export const getReadLogIsLoading = createSelector(
    getReadLogModel,
    (read_log) => read_log.isLoading
);

export const getReadLogErrors = createSelector(
    getReadLogModel,
    (read_log) => read_log.errors
);

export const getIsLogModalOpen = createSelector(
    getModel,
    (model) => model?.isLogModalOpen || false
);
