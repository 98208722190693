import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getInverterDetail } from 'common/api/inverters';
import fetchProductDatasheet from 'common/modules/productDatasheet/actions/fetchProductDatasheet';
import fetchProductThumbnail from 'common/modules/productThumbnail/actions/fetchProductThumbnail';
import showToast from 'common/utils/showToast';

import {
    FETCH_INVERTER_DETAIL,
    FETCH_INVERTER_DETAIL_FAILURE,
    FETCH_INVERTER_DETAIL_SUCCESS,
} from '../actionTypes';
import {
    GENERAL_CATEGORY,
    INPUT_DATA_CATEGORY,
    OUTPUT_DATA_CATEGORY,
} from '../constants';

import fetchBrands from './fetchBrands';
import fetchFamilies from './fetchFamilies';
import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleProductForm from './toggleProductForm';

const getSectionFieldValue = (response, position, tag) => {
    const tags = get(response.data, 'tags', []);
    const section = !isEmpty(tags)
        ? tags.find((t) => t.position === position)
        : {};
    if (!isEmpty(section)) {
        const obj = section.data.find((t) => t.tag === tag);
        if (obj) return obj.value;
    }
    return '';
};

export default (item) => (dispatch) => {
    dispatch({ type: FETCH_INVERTER_DETAIL });
    dispatch(resetForm());
    dispatch(toggleProductForm(true));

    getInverterDetail(item.id)
        .then(async (response) => {
            dispatch({
                type: FETCH_INVERTER_DETAIL_SUCCESS,
                payload: response.data,
            });

            const datasheetData = await dispatch(
                fetchProductDatasheet(item.id)
            );

            const thumbnailData = await dispatch(
                fetchProductThumbnail(item.id)
            );

            const values = {
                ...response.data,
                product_type: parseInt(response.data.product_type),
                brand: get(response.data, 'brand.id', ''),
                family: get(response.data, 'family.id', ''),
                ancho: getSectionFieldValue(
                    response,
                    GENERAL_CATEGORY,
                    'ancho'
                ),
                alto: getSectionFieldValue(response, GENERAL_CATEGORY, 'alto'),
                profundo: getSectionFieldValue(
                    response,
                    GENERAL_CATEGORY,
                    'profundo'
                ),
                peso: getSectionFieldValue(response, GENERAL_CATEGORY, 'peso'),
                oversizing: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'oversizing'
                ),
                dc_max_p: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'dc_max_p'
                ),
                dc_max_v: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'dc_max_v'
                ),
                dc_max_i: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'dc_max_i'
                ),
                mppts: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'mppts'
                ),
                mppt_strings: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'mppt_strings'
                ),
                dc_mppt_min_v: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'dc_mppt_min_v'
                ),
                dc_mppt_max_v: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'dc_mppt_max_v'
                ),
                dc_mppt_max_i: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'dc_mppt_max_i'
                ),
                dc_mppt_max_string_i: getSectionFieldValue(
                    response,
                    INPUT_DATA_CATEGORY,
                    'dc_mppt_max_string_i'
                ),
                ac_max_p: getSectionFieldValue(
                    response,
                    OUTPUT_DATA_CATEGORY,
                    'ac_max_p'
                ),
                ac_nominal_v: getSectionFieldValue(
                    response,
                    OUTPUT_DATA_CATEGORY,
                    'ac_nominal_v'
                ),
                ac_min_v: getSectionFieldValue(
                    response,
                    OUTPUT_DATA_CATEGORY,
                    'ac_min_v'
                ),
                ac_max_v: getSectionFieldValue(
                    response,
                    OUTPUT_DATA_CATEGORY,
                    'ac_max_v'
                ),
                ac_nom_i: getSectionFieldValue(
                    response,
                    OUTPUT_DATA_CATEGORY,
                    'ac_nom_i'
                ),
                fases: getSectionFieldValue(
                    response,
                    OUTPUT_DATA_CATEGORY,
                    'fases'
                ),
                status_flag: item.status_flag,
                image_thumbnail: thumbnailData.image_thumbnail,
                datasheet_name: datasheetData.data_sheet_name,
                datasheet_archive: datasheetData.data_sheet,
            };

            dispatch(fetchBrands(get(response.data, 'brand.name', '')));

            dispatch(
                fetchFamilies(
                    get(response.data, 'brand.id', ''),
                    get(response.data, 'family.name', '')
                )
            );

            dispatch(setIsNewRecord(false));
            dispatch(initializeForm(values));
            dispatch(setEnabledEdition(false));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_INVERTER_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t('An error occurred while querying the product'),
            });

            dispatch(toggleProductForm(false));
        });
};
