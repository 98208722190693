import React from 'react';

import PropTypes from 'prop-types';

const LoginLayout = ({ children }) => <>{children}</>;

LoginLayout.propTypes = {
    children: PropTypes.node,
};

export default LoginLayout;
