import { NAME } from './constants';

export const FETCH_ALLIANCES = `${NAME}/FETCH_ALLIANCES`;
export const FETCH_ALLIANCES_FAILURE = `${NAME}/FETCH_ALLIANCES_FAILURE`;
export const FETCH_ALLIANCES_SUCCESS = `${NAME}/FETCH_ALLIANCES_SUCCESS`;

export const FETCH_ALLIANCE_PRODUCTS = `${NAME}/FETCH_ALLIANCE_PRODUCTS`;
export const FETCH_ALLIANCE_PRODUCTS_FAILURE = `${NAME}/FETCH_ALLIANCE_PRODUCTS_FAILURE`;
export const FETCH_ALLIANCE_PRODUCTS_SUCCESS = `${NAME}/FETCH_ALLIANCE_PRODUCTS_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const SHOW_ALLIANCE_PRODUCTS = `${NAME}/SHOW_ALLIANCE_PRODUCTS`;
export const SET_FILTERS = `${NAME}/SET_FILTERS`;
export const SET_ALLIANCE_PRODUCTS_FILTERS = `${NAME}/SET_ALLIANCE_PRODUCTS_FILTERS`;
