import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getBatteryDetail } from 'common/api/batteries';
import fetchProductDatasheet from 'common/modules/productDatasheet/actions/fetchProductDatasheet';
import fetchProductThumbnail from 'common/modules/productThumbnail/actions/fetchProductThumbnail';
import showToast from 'common/utils/showToast';

import {
    FETCH_BATTERY_DETAIL,
    FETCH_BATTERY_DETAIL_FAILURE,
    FETCH_BATTERY_DETAIL_SUCCESS,
} from '../actionTypes';
import { PARAMETERS_CATEGORY } from '../constants';

import fetchBrands from './fetchBrands';
import fetchFamilies from './fetchFamilies';
import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleProductForm from './toggleProductForm';

const getSectionFieldValue = (response, position, tag) => {
    const tags = get(response.data, 'tags', []);
    const section = !isEmpty(tags)
        ? tags.find((t) => t.position === position)
        : {};
    if (!isEmpty(section)) {
        const obj = section.data.find((t) => t.tag === tag);
        if (obj) return obj.value;
    }
    return '';
};

export default (item) => (dispatch) => {
    dispatch({ type: FETCH_BATTERY_DETAIL });
    dispatch(resetForm());
    dispatch(toggleProductForm(true));

    getBatteryDetail(item.id)
        .then(async (response) => {
            dispatch({
                type: FETCH_BATTERY_DETAIL_SUCCESS,
                payload: response.data,
            });

            const datasheetData = await dispatch(
                fetchProductDatasheet(item.id)
            );

            const thumbnailData = await dispatch(
                fetchProductThumbnail(item.id)
            );

            const values = {
                ...response.data,
                product_type: parseInt(response.data.product_type),
                brand: get(response.data, 'brand.id', ''),
                family: get(response.data, 'family.id', ''),
                storage_cap_max: getSectionFieldValue(
                    response,
                    PARAMETERS_CATEGORY,
                    'storage_cap_max'
                ),
                I_discharge_max: getSectionFieldValue(
                    response,
                    PARAMETERS_CATEGORY,
                    'I_discharge_max'
                ),
                P_discharge_max: getSectionFieldValue(
                    response,
                    PARAMETERS_CATEGORY,
                    'P_discharge_max'
                ),
                cycles: getSectionFieldValue(
                    response,
                    PARAMETERS_CATEGORY,
                    'cycles'
                ),
                P_charge_max: getSectionFieldValue(
                    response,
                    PARAMETERS_CATEGORY,
                    'P_charge_max'
                ),
                I_charge_max: getSectionFieldValue(
                    response,
                    PARAMETERS_CATEGORY,
                    'I_charge_max'
                ),
                DoD: getSectionFieldValue(response, PARAMETERS_CATEGORY, 'DoD'),
                c_nom: getSectionFieldValue(
                    response,
                    PARAMETERS_CATEGORY,
                    'c_nom'
                ),
                status_flag: item.status_flag,
                image_thumbnail: thumbnailData.image_thumbnail,
                datasheet_name: datasheetData.data_sheet_name,
                datasheet_archive: datasheetData.data_sheet,
            };

            dispatch(fetchBrands(get(response.data, 'brand.name', '')));

            dispatch(
                fetchFamilies(
                    get(response.data, 'brand.id', ''),
                    get(response.data, 'family.name', '')
                )
            );

            dispatch(setIsNewRecord(false));
            dispatch(initializeForm(values));
            dispatch(setEnabledEdition(false));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_BATTERY_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t('An error occurred while querying the product'),
            });

            dispatch(toggleProductForm(false));
        });
};
