import React from 'react';

import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'common/ui';

const Dot = styled.div`
    background-color: ${({ background }) => background};
    border-radius: 100%;
    height: 16px;
    margin-right: 10px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
`;

const StyledImage = styled.img`
    margin-right: 10px;
`;

const Label = styled.span`
    font-size: ${({ fontSize }) => fontSize} !important;
    font-weight: ${({ fontWeight }) => fontWeight};

    ${({ separatorRight }) =>
        separatorRight &&
        `
        border-right: 3px solid #525252;
        padding-right: 15px;`}

    ${({ separatorLeft }) =>
        separatorLeft &&
        `
        border-left: 3px solid #525252;
        padding-left: 15px;`}

    ${({ variant }) =>
        variant === 'blue' &&
        `
        color: #008DFF;
        font-weight: 600;`}

    ${({ variant }) =>
        variant === 'bold' &&
        `   
        font-size: 14px;
        font-weight: 600;`}
`;

const getCircleColor = (color) => {
    switch (color) {
        case 'bittersweet':
            return '#FA6968';

        case 'blue':
            return '#1695FF';

        case 'dark':
            return '#000000';

        case 'green':
            return '#09E1A1';

        case 'light_grey':
            return '#D0D7DD';

        case 'orange':
            return '#FF9A00';

        case 'purple':
            return '#DE09E1';

        case 'transparent':
            return 'transparent';

        default:
            return '#848BAB';
    }
};

const TextVariant = ({
    as,
    className,
    dot,
    fontSize = '14px',
    fontWeight = '500',
    icon,
    iconWidth = '19',
    separatorLeft,
    separatorRight,
    text,
    variant,
}) => {
    return (
        <Box
            component="div"
            className={className}
            sx={{
                alignItems: 'center',
                display: 'inline-flex',
            }}
        >
            {isNil(dot) ? null : <Dot background={getCircleColor(dot)} />}

            {isNil(icon) ? null : (
                <StyledImage src={icon} alt="" width={iconWidth} />
            )}

            <Label
                as={as}
                fontSize={fontSize}
                fontWeight={fontWeight}
                separatorRight={separatorRight}
                separatorLeft={separatorLeft}
                title={text}
                variant={variant}
            >
                {text}
            </Label>
        </Box>
    );
};

TextVariant.propTypes = {
    as: PropTypes.elementType,
    color: PropTypes.string,
    className: PropTypes.string,
    dot: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    icon: PropTypes.string,
    iconWidth: PropTypes.string,
    separatorLeft: PropTypes.bool,
    separatorRight: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    variant: PropTypes.string,
};

export default TextVariant;
