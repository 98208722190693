import { bulkEditionRow } from 'common/api/productsBulk';
import showToast from 'common/utils/showToast';

import {
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import * as selectors from '../selectors';

export default (values) => (dispatch, getState) => {
    const state = getState();
    const data = selectors.getAccessoriesData(state);

    if (!values.id) return;

    const newValues = {
        product_type: 'accessories',
        code: values.code,
        name: values.name,
    };

    dispatch({ type: SAVE });

    bulkEditionRow(newValues, values.id)
        .then(() => {
            dispatch({ type: SAVE_SUCCESS, payload: newItems });
            const newItems = data.map((item) => {
                if (item.id === values.id) {
                    return values;
                }
                return item;
            });
            dispatch({
                type: UPDATE_ITEMS_SUCCESS,
                payload: newItems,
            });
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error?.response?.data?.errors,
            });
        });
};
