import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { CustomPagination } from 'common/components';
import { Card, CardContent, DataGrid } from 'common/ui';
import { getDataGridClasses } from 'common/utils/helpers';

import FilterCard from './FilterCard';
import ProductColumns from './ProductColumns';

const DEBOUNCE_TIME = 1500;

const ListItems = ({
    companies,
    fetchCompanies,
    filterData,
    filterItems,
    isFetching,
    items,
    paginationData,
    setFilterData,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [rowCount, setRowCount] = useState(0);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnPageChange = (page) =>
        setFilterData({ ...filterData, page: page });

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const handleOnChangeFilter = (value, name) =>
        setFilterData((filterData) => ({
            ...filterData,
            page: 0,
            [name]: value,
        }));

    return (
        <>
            <FilterCard
                companies={companies}
                fetchCompanies={fetchCompanies}
                filterData={filterData}
                handleOnChangeFilter={handleOnChangeFilter}
            />

            <Card>
                <CardContent>
                    <DataGrid
                        autoHeight
                        columns={ProductColumns()}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rows={items}
                        sortingMode="server"
                        sx={getDataGridClasses(isDarkMode)}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    companies: PropTypes.array,
    fetchCompanies: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
