import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Box, Slider } from 'common/ui';

const StyledSlider = styled(Slider)(() => ({
    height: 6,
    '& .MuiSlider-mark': {
        backgroundColor: '#636982',
        height: 8,
        width: 1,
        top: 30,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
}));

const ReduxFieldSlider = ({
    disabled,
    input: { value, onChange },
    marks,
    max,
    min,
    step,
    ...other
}) => {
    return (
        <Box pl={1} pr={1}>
            <StyledSlider
                valueLabelDisplay="auto"
                step={step}
                marks={marks}
                min={min}
                max={max}
                disabled={disabled}
                onChange={onChange}
                value={value}
                {...other}
            />
        </Box>
    );
};

ReduxFieldSlider.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    marks: PropTypes.array,
    max: PropTypes.number,
    min: PropTypes.number,
    step: PropTypes.number,
};

export default ReduxFieldSlider;
