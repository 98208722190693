import React from 'react';

import { styled } from '@mui/material/styles';

import { Grid, Card, Skeleton } from 'common/ui';

const StyledGridItem = styled(Grid)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    gap: 16px;
    min-height: 550px;
`;

const Placeholder = () => (
    <Card>
        <Grid container p={3} spacing={4} columns={16}>
            <StyledGridItem item xs={16} md={8}>
                <Skeleton variant="rectangular" height="100%" width="100%" />
            </StyledGridItem>

            <StyledGridItem item xs={16} md={8}>
                <Skeleton variant="rectangular" height="100%" width="100%" />
            </StyledGridItem>
        </Grid>
    </Card>
);

export default Placeholder;
