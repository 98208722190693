import React from 'react';

import PropTypes from 'prop-types';

import { Typography } from 'common/ui';

const FieldNameFormatter = (cell) => {
    return (
        <Typography variant="subtitle2" title={cell}>
            {cell ? cell : '- - -'}
        </Typography>
    );
};

FieldNameFormatter.propTypes = {
    cell: PropTypes.string,
};

export default FieldNameFormatter;
