import API from '..';
const ENTITY = 'products';

export const filterPanels = ({
    brandName = '',
    maxPower = null,
    orderBy,
    page = 1,
    perPage = 25,
    searchText,
    sortBy,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/panels/`, {
        params: {
            page: page,
            per_page: perPage,
            status_flag: status,
            ...(brandName ? { brand: brandName } : {}),
            ...(maxPower ? { max_power: maxPower } : {}),
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const getPanels = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/panels/?search${str ? '=' + str : ''}&page=${page}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );

export const getPanelDetail = (id) => API.get(`/api/v1/${ENTITY}/panel/${id}/`);

export const createPanel = (data) =>
    API.post(`/api/v1/${ENTITY}/panel/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const updatePanel = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/panel/${id}/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const filterBrands = (str = '') =>
    API.get(`/api/v1/${ENTITY}/panels/brands/?search${str ? '=' + str : ''}`);
