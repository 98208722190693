import i18next from 'i18next';

import { getItem } from 'common/api/v1/rates';
import showToast from 'common/utils/showToast';

import {
    FETCH_CUSTOM_RATE_DETAIL,
    FETCH_CUSTOM_RATE_DETAIL_FAILURE,
    FETCH_CUSTOM_RATE_DETAIL_SUCCESS,
} from '../actionTypes';
import { parseBodyObjectToFormValues } from '../helpers';

import initializeRateModalForm from './initializeRateModalForm';
import setModalIsOpen from './setModalIsOpen';

export default (item) => (dispatch) => {
    dispatch({ type: FETCH_CUSTOM_RATE_DETAIL });

    getItem(item.id)
        .then((response) => {
            dispatch({
                payload: response?.data?.data,
                type: FETCH_CUSTOM_RATE_DETAIL_SUCCESS,
            });
            dispatch(
                initializeRateModalForm(
                    parseBodyObjectToFormValues(response?.data?.data)
                )
            );
            dispatch(setModalIsOpen(true));
        })
        .catch((error) => {
            dispatch({
                payload: error?.response?.data?.errors,
                type: FETCH_CUSTOM_RATE_DETAIL_FAILURE,
            });
            showToast({
                body: i18next.t('An error occurred while querying the rate'),
                type: 'error',
            });
            dispatch(setModalIsOpen(false));
        });
};
