import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HiddenComponent } from 'common/components';
import {
    Box,
    Button,
    Card,
    CardActions,
    Grid,
    IconButton,
    Pagination,
    Tooltip,
    Typography,
} from 'common/ui';

const StyledIconButton = styled(IconButton)(({ theme, color }) => ({
    backgroundColor: theme.palette[color].main,
    color: theme.palette[color].contrastText,
    margin: '4px 6px',
}));
import * as actions from '../actions';
import * as selectors from '../selectors';

import { CustomRatesPreviewTable, RatesPreviewTableToolbar } from './';

const RatesContainer = ({
    customRates,
    fetchCustomRates,
    filters,
    isFetching,
    pagination,
    prepareNewRate,
}) => {
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [searchStr, setSearchStr] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        fetchCustomRates(page, orderBy, sortBy, searchStr);
    }, [page, searchStr]);

    const handleOnChangePage = (_, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchCustomRates(page, orderBy, sortBy, searchStr);
    };

    const handleOnSearchChange = (e) => setSearchStr(e);

    return (
        <>
            <Typography sx={{ mt: 3 }} variant="h4">
                Otras Tarifas
            </Typography>

            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <RatesPreviewTableToolbar
                            handleOnSearchChange={handleOnSearchChange}
                            searchStr={filters.searchStr}
                        />
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                        <HiddenComponent width="lgDown">
                            <Button
                                disabled={isFetching}
                                fullWidth={false}
                                height="48px"
                                startIcon={<AddIcon />}
                                type="button"
                                onClick={() => prepareNewRate()}
                            >
                                {t('Add')}
                            </Button>
                        </HiddenComponent>

                        <HiddenComponent width="lgUp">
                            <Tooltip title={t('Add')}>
                                <span>
                                    <StyledIconButton
                                        color="primary"
                                        disabled={isFetching}
                                        onClick={() => prepareNewRate()}
                                    >
                                        <AddIcon />
                                    </StyledIconButton>
                                </span>
                            </Tooltip>
                        </HiddenComponent>
                    </Grid>
                </Grid>
            </Box>

            <Card>
                <CustomRatesPreviewTable
                    handleSortable={handleSortable}
                    isFetching={isFetching}
                    items={customRates}
                    orderdBy={orderBy}
                    sortedBy={sortBy}
                />

                {!isFetching && pagination && !isEmpty(pagination) && (
                    <CardActions>
                        <Pagination
                            color="primary"
                            count={
                                Math.ceil(
                                    parseInt(pagination.total_rows) /
                                        parseInt(pagination.per_page)
                                ) || 0
                            }
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            sx={{ marginLeft: 'auto' }}
                            page={pagination.current_page}
                            onChange={handleOnChangePage}
                            variant="outlined"
                        />
                    </CardActions>
                )}
            </Card>
        </>
    );
};

RatesContainer.propTypes = {
    customRates: PropTypes.array,
    fetchCustomRates: PropTypes.func,
    filters: PropTypes.object,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
    prepareNewRate: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    customRates: selectors.getCustomRatesData,
    filters: selectors.getFilters,
    isFetching: selectors.getCustomRatesIsFetching,
    pagination: selectors.getCustomRatesPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCustomRates: (page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchCustomRates(page, orderBy, sortBy, searchStr)),
    prepareNewRate: () => dispatch(actions.prepareNewRate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RatesContainer);
