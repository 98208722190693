import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

import { ADDITION, CHANGE, DELETION } from './constants';

export const getHistoryOrigin = (history) => {
    if (!isEmpty(history)) {
        if (get(history, 'user_data.id', '') === 'system') {
            return i18next.t('Automatic').concat(' - ');
        } else {
            return i18next.t('Manual').concat(' - ');
        }
    }
    return '';
};

export const getHistoryManager = (history) => {
    if (!isEmpty(history)) {
        if (get(history, 'user_data.id', '') === 'system') {
            return i18next.t('System');
        } else {
            return get(history, 'user_data.name', '');
        }
    }
    return i18next.t('Unknown');
};

export const getHistoryActionText = (history) => {
    if (!isEmpty(history)) {
        switch (get(history, 'action_flag.key', 0)) {
            case ADDITION:
                return i18next.t('Made an addition in');
            case CHANGE:
                return i18next.t('Made a modification in');
            case DELETION:
                return i18next.t('Made an elimination in');
            default:
                return '';
        }
    }
    return '';
};

export const getHistoryObject = (history) => {
    if (!isEmpty(history)) {
        return get(history, 'object_data.name', '');
    }
    return '';
};

export const getHistoryDescription = (history) => {
    let historyDescription = [];
    if (!isEmpty(history)) {
        if (!isEmpty(history.metafields)) {
            history.metafields.forEach((m) => {
                //add product
                if (isNil(m.old_value) && !isNil(m.current_value)) {
                    historyDescription.push(
                        `- Agregó el elemento ${
                            isObject(m.current_value)
                                ? get(m.current_value, 'name', m.verbose_name)
                                : m.current_value
                        } (${m.verbose_name})`
                    );
                }
                //change product
                if (!isNil(m.old_value) && !isNil(m.current_value)) {
                    historyDescription.push(
                        `- Cambió el elemento de ${
                            isObject(m.old_value)
                                ? get(m.old_value, 'name', m.verbose_name)
                                : m.old_value
                        } a ${
                            isObject(m.current_value)
                                ? get(m.current_value, 'name', m.verbose_name)
                                : m.current_value
                        } (${m.verbose_name})`
                    );
                }
                //remove product
                if (!isNil(m.old_value) && isNil(m.current_value)) {
                    historyDescription.push(
                        `- Elimino el elemento ${
                            isObject(m.old_value)
                                ? get(m.old_value, 'name', m.verbose_name)
                                : m.old_value
                        } (${m.verbose_name})`
                    );
                }
            });
        } else {
            historyDescription.push(
                `${getHistoryActionText(history)} ${getHistoryObject(history)}`
            );
        }
    }
    return historyDescription.join('<br />');
};
