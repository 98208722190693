import createPanel from './createPanel';
import updatePanel from './updatePanel';

export default (filterData, values) => (dispatch) => {
    let newValues = {
        general: {
            brand: values.brand,
            family: values.family,
            type: values.product_type,
            name: values.name,
            code: values.code,
            cost: values.cost,
        },
        tags: {
            num_cells: values.num_cells,
            ancho: values.ancho,
            alto: values.alto,
            profundo: values.profundo,
            peso: values.peso,
            max_p: values.max_p,
            vmp: values.vmp,
            imp: values.imp,
            voc: values.voc,
            isc: values.isc,
            max_dc: values.max_dc,
            module_e: values.module_e,
            tk_pmax: values.tk_pmax,
            tk_voc: values.tk_voc,
            tk_isc: values.tk_isc,
            noct: values.noct,
        },
        thumbnail: {
            image_thumbnail: values.image_thumbnail,
        },
        datasheet: {
            datasheet_archive: values.datasheet_archive,
            datasheet_name: values.datasheet_name,
        },
    };

    if (values.id) dispatch(updatePanel(filterData, newValues, values.id));
    else dispatch(createPanel(filterData, newValues));
};
