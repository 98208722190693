import { NAME } from './constants';

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const FETCH_TEMPLATE_DETAIL = `${NAME}/FETCH_TEMPLATE_DETAIL`;
export const FETCH_TEMPLATE_DETAIL_FAILURE = `${NAME}/FETCH_TEMPLATE_DETAIL_FAILURE`;
export const FETCH_TEMPLATE_DETAIL_SUCCESS = `${NAME}/FETCH_TEMPLATE_DETAIL_SUCCESS`;

export const FETCH_CATEGORIES = `${NAME}/FETCH_CATEGORIES`;
export const FETCH_CATEGORIES_SUCCESS = `${NAME}/FETCH_CATEGORIES_SUCCESS`;
export const FETCH_CATEGORIES_FAILURE = `${NAME}/FETCH_CATEGORIES_FAILURE`;

export const FETCH_ORIGINS = `${NAME}/FETCH_ORIGINS`;
export const FETCH_ORIGINS_SUCCESS = `${NAME}/FETCH_ORIGINS_SUCCESS`;
export const FETCH_ORIGINS_FAILURE = `${NAME}/FETCH_ORIGINS_FAILURE`;

export const FETCH_LANGUAGES = `${NAME}/FETCH_LANGUAGES`;
export const FETCH_LANGUAGES_SUCCESS = `${NAME}/FETCH_LANGUAGES_SUCCESS`;
export const FETCH_LANGUAGES_FAILURE = `${NAME}/FETCH_LANGUAGES_FAILURE`;

export const FETCH_CUSTOM_TEMPLATES = `${NAME}/FETCH_CUSTOM_TEMPLATES`;
export const FETCH_CUSTOM_TEMPLATES_SUCCESS = `${NAME}/FETCH_CUSTOM_TEMPLATES_SUCCESS`;
export const FETCH_CUSTOM_TEMPLATES_FAILURE = `${NAME}/FETCH_CUSTOM_TEMPLATES_FAILURE`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_TEMPLATE_FORM = `${NAME}/OPEN_TEMPLATE_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const SET_FILTERS = `${NAME}/SET_FILTERS`;
export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;

export const DELETE_TEMPLATE = `${NAME}/DELETE_TEMPLATE`;
export const DELETE_TEMPLATE_SUCCESS = `${NAME}/DELETE_TEMPLATE_SUCCESS`;
export const DELETE_TEMPLATE_FAILURE = `${NAME}/DELETE_TEMPLATE_FAILURE`;
