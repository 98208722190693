import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getOutstandingDetail = createSelector(
    getModel,
    (model) => model.outstandingDetail
);

export const getIsFetchingOutstandingDetail = createSelector(
    getOutstandingDetail,
    (outstandingDetail) => outstandingDetail.isFetching
);

export const getDeleteOutstanding = createSelector(
    getModel,
    (model) => model.deleteOutstanding
);

export const getIsDeletingOutstanding = createSelector(
    getDeleteOutstanding,
    (deleteOutstanding) => deleteOutstanding.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteOutstanding,
    (deleteOutstanding) => deleteOutstanding.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteOutstanding,
    (deleteOutstanding) => deleteOutstanding.errors
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal || false
);
