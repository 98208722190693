import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DialogContainer } from 'common/components';

import * as actions from './actions';
import { RateHistoryDatagridForm } from './components';
import * as selectors from './selectors';

const Container = ({
    filters,
    handleAfterSave,
    handleClickCloseModal,
    isLoadingRateHistoryDatagrid,
    isOpenModal,
    rateLabel,
    rateName,
    reset,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    return (
        <DialogContainer
            loading={isLoadingRateHistoryDatagrid}
            onClose={handleClickCloseModal}
            open={isOpenModal}
            fullWidth={true}
            maxWidth="xl"
            title={t('Rate cost history records').concat(` (${rateName})`)}
        >
            <RateHistoryDatagridForm
                filters={filters}
                handleAfterSave={handleAfterSave}
                rateLabel={rateLabel}
            />
        </DialogContainer>
    );
};

Container.propTypes = {
    filters: PropTypes.object,
    handleAfterSave: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    isLoadingRateHistoryDatagrid: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    rateLabel: PropTypes.string,
    rateName: PropTypes.string,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isLoadingRateHistoryDatagrid: selectors.getIsFetchingRateHistoryDatagrid,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () => dispatch(actions.toggleModal(false)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
