import i18next from 'i18next';

import { updateApiAccount } from 'common/api/apiAccounts';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchApiAccounts from './fetchApiAccounts';
import resetForm from './resetForm';
import toggleItemForm from './toggleItemForm';

export default (values, id) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = new FormData();
    newValues.append('phone', values.phone);
    newValues.append('email', values.email);
    newValues.append('password', values.password);

    updateApiAccount(newValues, id)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchApiAccounts(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleItemForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while updating the element'),
            });
        });
};
