import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { UiDialog as Dialog } from 'common/ui';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { BUSINESS_TYPE_ITEMS } from '../constants';
import * as selectors from '../selectors';
import validateCustomRate from '../validateCustomRate';

import CustomRateFormTabs from './CustomRateFormTabs';

const CustomRateModalForm = ({
    fetchCurrenciesByCountries,
    fetchSuppliers,
    fetchUnits,
    initialValues,
    modalIsOpen,
    resetRateModalForm,
    saveCustomRate,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();

    const {
        control,
        getValues,
        handleSubmit,
        reset,
        setValue,
        trigger,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateCustomRate),
    });

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    const [mainDataBusinessType, mainDataNameShort] = watch([
        'main_data.business_type',
        'main_data.name_short',
    ]);

    const handleOnEnter = () => {
        fetchCurrenciesByCountries();
        fetchSuppliers();
        fetchUnits();
    };

    return (
        <Dialog
            disableEscapeKeyDown
            onEnter={handleOnEnter}
            onClose={(_, reason) => {
                if (reason && reason == 'backdropClick') return;
                setModalIsOpen(false);
            }}
            onExited={() => resetRateModalForm()}
            open={modalIsOpen}
            size="xl"
            title={`${t(
                BUSINESS_TYPE_ITEMS[mainDataBusinessType]
            )} - ${mainDataNameShort}`}
        >
            <form onSubmit={handleSubmit((values) => saveCustomRate(values))}>
                <CustomRateFormTabs
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    tabKey="custom-rate-modal-form-tabs"
                    trigger={trigger}
                />
            </form>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getRateModalInitialValues,
    isFetchingSupplierUtilities: selectors.getIsFetchingSupplierUtilities,
    isSavingRate: selectors.getIsSavingCustomRate,
    modalIsOpen: selectors.getModalIsOpen,
    saveRateErrors: selectors.getSaveCustomRateErrors,
    supplierUtilitiesItemsToSelect: selectors.getSupplierUtilitiesItemsToSelect,
    supplierUtilitiesModalIsOpen: selectors.getSupplierUtilitiesModalIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCurrenciesByCountries: () =>
        dispatch(actions.fetchCurrenciesByCountries()),
    fetchSuppliers: () => dispatch(actions.fetchSupplierUtilities()),
    fetchUnits: () => dispatch(actions.fetchUnits()),
    resetRateModalForm: () => dispatch(actions.resetRateModalForm()),
    saveCustomRate: (values) => dispatch(actions.saveCustomRate(values)),
    setModalIsOpen: (isOpen) => dispatch(actions.setModalIsOpen(isOpen)),
    setSupplierUtilitiesModalIsOpen: (isOpen) =>
        dispatch(actions.setSupplierUtilitiesModalIsOpen(isOpen)),
});

CustomRateModalForm.propTypes = {
    fetchCurrenciesByCountries: PropTypes.func,
    fetchSuppliers: PropTypes.func,
    fetchUnits: PropTypes.func,
    initialValues: PropTypes.object,
    modalIsOpen: PropTypes.bool,
    resetRateModalForm: PropTypes.func,
    saveCustomRate: PropTypes.func,
    setModalIsOpen: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomRateModalForm);
