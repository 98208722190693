import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledIcon from './StyledIcon';
import StyledIconContainer from './StyledIconContainer';

const StyledNotifications = styled.div`
    background-color: ${({ notificationsBackground }) =>
        notificationsBackground ? notificationsBackground : '#FF9A00'};
    border-radius: 30px;
    color: ${({ notificationsColor }) =>
        notificationsColor ? notificationsColor : '#FFFFFF'};
    font-size: 8px;
    font-weight: bold;
    height: 12px;
    line-height: 11px;
    margin-left: auto;
    margin-right: 0;
    padding: 1px;
    text-align: center;
    width: 12px;
`;

const Label = styled.div`
    color: #ffffff6b;
    font-size: 11px;
    line-height: 16px;
    margin-top: 5px;
    text-align: center;
`;

const SideBarItem = ({
    active,
    className = '',
    handleClick,
    icon,
    isHidden = false,
    label,
    notifications,
    notificationsBackground,
    notificationsColor,
}) => {
    if (isHidden) return null;

    return (
        <StyledIconContainer
            className={`${active ? 'active' : ''}${className}`}
            onClick={handleClick}
        >
            {notifications > 0 && (
                <StyledNotifications
                    notificationsColor={notificationsColor}
                    notificationsBackground={notificationsBackground}
                >
                    {notifications}
                </StyledNotifications>
            )}

            <StyledIcon className="ico">{icon}</StyledIcon>

            <Label className="label">{label}</Label>
        </StyledIconContainer>
    );
};

SideBarItem.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    handleClick: PropTypes.func,
    icon: PropTypes.object,
    isHidden: PropTypes.bool,
    label: PropTypes.string,
    notifications: PropTypes.array,
    notificationsBackground: PropTypes.string,
    notificationsColor: PropTypes.string,
};

export default SideBarItem;
