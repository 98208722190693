import React from 'react';

import PropTypes from 'prop-types';

import { Stack } from 'common/ui';

const HeaderContainer = ({ children }) => {
    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={4}
            >
                {children}
            </Stack>
        </>
    );
};

HeaderContainer.propTypes = {
    children: PropTypes.node,
};

export default HeaderContainer;
