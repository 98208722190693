import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BasicDataGrid } from 'common/components';
import { Box } from 'common/ui';
import { formatISODate } from 'common/utils/helpers';
import {
    getDateFormatByLocale,
    getLocaleEquivalenceForDateFns,
} from 'common/utils/helpers/multiregion';

import { BASE_MODELS } from '../constants';
import { getModelStatus } from '../helpers';

import DropdownModelActions from './DropdownModelActions';

const ModelsTable = ({
    createTrainingDocument,
    downloadTrainingDocument,
    isFetchingModels,
    items,
    prepareDeleteModel,
    prepareDuplicateModel,
    prepareStartTrainingModel,
    prepareStopTrainingModel,
    prepareUpdateModel,
    redirect,
}) => {
    const { t } = useTranslation();
    const locale = getLocaleEquivalenceForDateFns();
    const dateFormat = getDateFormatByLocale();

    const columns = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            valueGetter: ({ row } = {}) =>
                row?.name?.concat(row?.main_model ? ' \u2B50' : ''),
        },
        {
            align: 'left',
            field: 'model',
            flex: 1,
            headerName: t('Base model'),
            valueGetter: (params) =>
                BASE_MODELS.find(
                    (model) => model.optionValue === params?.row?.model
                )?.label || '',
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            valueGetter: (params) =>
                formatISODate(params?.row?.created_at, locale, dateFormat),
        },
        {
            align: 'left',
            field: 'status',
            flex: 0.75,
            headerName: t('Status'),
            renderCell: (params) => {
                const modelStatus = getModelStatus(params?.row?.status);

                return (
                    <Box display="flex" gap={1} alignItems="center">
                        <Box
                            component="span"
                            sx={{
                                backgroundColor: modelStatus.color,
                                borderRadius: '100%',
                                width: '12px',
                                height: '12px',
                            }}
                        />
                        <span>{t(modelStatus.label)}</span>
                    </Box>
                );
            },
        },
        {
            align: 'center',
            field: '',
            renderCell: (params) => (
                <DropdownModelActions
                    createTrainingDocument={createTrainingDocument}
                    downloadTrainingDocument={downloadTrainingDocument}
                    model={params.row}
                    prepareDeleteModel={prepareDeleteModel}
                    prepareDuplicateModel={prepareDuplicateModel}
                    prepareStartTrainingModel={prepareStartTrainingModel}
                    prepareStopTrainingModel={prepareStopTrainingModel}
                    prepareUpdateModel={prepareUpdateModel}
                />
            ),
            sortable: false,
            width: 50,
        },
    ];

    return (
        <BasicDataGrid
            columns={columns}
            isLoading={isFetchingModels}
            onRowClick={(row) => redirect(`/ai/fine-tuning/${row.id}`)}
            rows={items}
        />
    );
};

ModelsTable.propTypes = {
    createTrainingDocument: PropTypes.func,
    downloadTrainingDocument: PropTypes.func,
    isFetchingModels: PropTypes.bool,
    items: PropTypes.array,
    prepareDeleteModel: PropTypes.func,
    prepareDuplicateModel: PropTypes.func,
    prepareStartTrainingModel: PropTypes.func,
    prepareStopTrainingModel: PropTypes.func,
    prepareUpdateModel: PropTypes.func,
    redirect: PropTypes.func,
};

export default ModelsTable;
