import update from 'immutability-helper';

import {
    FETCH_ALLIANCES,
    FETCH_ALLIANCES_FAILURE,
    FETCH_ALLIANCES_SUCCESS,
    FETCH_ALLIANCE_PRODUCTS,
    FETCH_ALLIANCE_PRODUCTS_FAILURE,
    FETCH_ALLIANCE_PRODUCTS_SUCCESS,
    RESET,
    SET_ALLIANCE_PRODUCTS_FILTERS,
    SET_FILTERS,
    SHOW_ALLIANCE_PRODUCTS,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        company_id: '',
        enabledEdition: false,
    },
    alliances: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    filters: {
        id: '',
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    productsFilters: {
        id: '',
        alliance: '',
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    allianceProducts: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    showAllianceProducts: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case FETCH_ALLIANCES:
            return update(state, {
                alliances: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_ALLIANCES_FAILURE:
            return update(state, {
                alliances: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_ALLIANCES_SUCCESS:
            return update(state, {
                alliances: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_ALLIANCE_PRODUCTS:
            return update(state, {
                allianceProducts: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_ALLIANCE_PRODUCTS_FAILURE:
            return update(state, {
                allianceProducts: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_ALLIANCE_PRODUCTS_SUCCESS:
            return update(state, {
                allianceProducts: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case SET_ALLIANCE_PRODUCTS_FILTERS:
            return update(state, {
                productsFilters: {
                    $merge: action.payload,
                },
            });

        case SHOW_ALLIANCE_PRODUCTS:
            return update(state, {
                showAllianceProducts: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
