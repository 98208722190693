import i18next from 'i18next';

import { deleteThumbnail } from 'common/api/thumbnails';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    DELETE_THUMBNAIL,
    DELETE_THUMBNAIL_FAILURE,
    DELETE_THUMBNAIL_SUCCESS,
} from '../actionTypes';

import fetchProductThumbnail from './fetchProductThumbnail';

export default (productId) => (dispatch) => {
    dispatch({ type: DELETE_THUMBNAIL });

    deleteThumbnail(productId)
        .then(() => {
            dispatch({ type: DELETE_THUMBNAIL_SUCCESS, payload: {} });
            dispatch(fetchProductThumbnail(productId));
            showToast({
                body: i18next.t('It was successfully removed'),
            });
        })
        .catch((error) => {
            dispatch({
                type: DELETE_THUMBNAIL_FAILURE,
                payload: error.response.data.errors,
            });

            const dataAlert = {
                confirmText: i18next.t('Close'),
                messages: [
                    `${i18next.t('Whoops, something went wrong')}:`,
                    ...error.response.data.errors,
                ],
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'error',
            };

            dispatch(alerts.actions.show(dataAlert));
        });
};
