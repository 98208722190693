import React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Box, Skeleton, Typography } from 'common/ui';

const Wrapper = styled(Box)`
    border-radius: 8px;
    display: flex;
    width: 100%;
`;

const IconColumn = styled(Box)`
    align-items: center;
    background: #20445a;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 8px;
    svg path {
        fill: #eef4fa;
    }
`;

const KeyIndicator = ({
    height,
    isLoading,
    preffix,
    svgIcon,
    title,
    unit,
    value,
    widthIcon,
}) => {
    const theme = useTheme();
    const displayPreffix = preffix ? <span>{preffix}</span> : null;
    const displayUnit = unit ? <span>{unit}</span> : null;
    const isDarkMode = theme.palette.mode === 'dark';
    return (
        <Wrapper
            sx={{
                background: isDarkMode ? '#282B30' : '#eef4fa',
                height: height,
            }}
        >
            <IconColumn sx={{ width: widthIcon }}>{svgIcon}</IconColumn>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 1,
                    width: '100%',
                }}
            >
                {isLoading ? (
                    <Box display="flex" flexDirection="column">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton
                            variant="text"
                            sx={{ fontSize: '1rem', width: '100px' }}
                        />
                    </Box>
                ) : (
                    <>
                        <Typography variant="caption">{title}</Typography>
                        <Typography variant="body2" fontWeight="bold">
                            {displayPreffix} {value} {displayUnit}
                        </Typography>
                    </>
                )}
            </Box>
        </Wrapper>
    );
};

KeyIndicator.defaultProps = {
    height: 'auto',
    widthIcon: '40px',
};

KeyIndicator.propTypes = {
    height: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isLoading: PropTypes.bool,
    preffix: PropTypes.string,
    svgIcon: PropTypes.object,
    title: PropTypes.string,
    unit: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    widthIcon: PropTypes.string,
};

export default KeyIndicator;
