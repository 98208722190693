import React from 'react';

import { useTranslation } from 'react-i18next';

import { useBreakpoint } from 'common/hooks';
import { Avatar, Typography } from 'common/ui';
import { handleFileURL } from 'common/utils/helpers';

import ProductActionsFormatter from './ProductActionsFormatter';

const ProductColumns = ({
    handleClickActive,
    handleClickDownload,
    session,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const nameColumn = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
        },
    ];

    let columns = [
        {
            align: 'left',
            field: 'image',
            headerName: t('Logo'),
            width: 100,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Avatar
                        src={handleFileURL(
                            row?.image,
                            process.env.REACT_APP_S3_MEDIA_PATH
                        )}
                        sx={{ height: 36, width: 36 }}
                    />
                );
            },
            sortable: false,
        },
        ...nameColumn,
        {
            align: 'left',
            field: 'is_active',
            headerName: t('Status'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.is_active ? t('Active') : t('Disabled')}
                    </Typography>
                );
            },
            sortable: false,
            width: 200,
        },
        {
            align: 'left',
            field: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ProductActionsFormatter
                        handleClickDownload={handleClickDownload}
                        handleClickActive={handleClickActive}
                        row={row}
                        session={session}
                    />
                );
            },
            sortable: false,
            width: 100,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    return columns;
};

export default ProductColumns;
