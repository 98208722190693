import { NAME } from './constants';

export const FETCH_PRODUCT_DATASHEET = `${NAME}/FETCH_PRODUCT_DATASHEET`;
export const FETCH_PRODUCT_DATASHEET_FAILURE = `${NAME}/FETCH_PRODUCT_DATASHEET_FAILURE`;
export const FETCH_PRODUCT_DATASHEET_SUCCESS = `${NAME}/FETCH_PRODUCT_DATASHEET_SUCCESS`;

export const UPLOAD_DATASHEET = `${NAME}/UPLOAD_DATASHEET`;
export const UPLOAD_DATASHEET_FAILURE = `${NAME}/UPLOAD_DATASHEET_FAILURE`;
export const UPLOAD_DATASHEET_SUCCESS = `${NAME}/UPLOAD_DATASHEET_SUCCESS`;

export const DELETE_DATASHEET = `${NAME}/DELETE_DATASHEET`;
export const DELETE_DATASHEET_FAILURE = `${NAME}/DELETE_DATASHEET_FAILURE`;
export const DELETE_DATASHEET_SUCCESS = `${NAME}/DELETE_DATASHEET_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const SHOW_FORM = `${NAME}/SHOW_FORM`;

export const RESET_UPLOAD_MODEL = `${NAME}/RESET_UPLOAD_MODEL`;

export const DOWNLOAD_DATASHEET = `${NAME}/DOWNLOAD_DATASHEET`;
export const DOWNLOAD_DATASHEET_SUCCESS = `${NAME}/DOWNLOAD_DATASHEET_SUCCESS`;
export const DOWNLOAD_DATASHEET_FAILURE = `${NAME}/DOWNLOAD_DATASHEET_FAILURE`;
