import React from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { ShowErrors } from 'common/components';
import { ReduxFieldInput } from 'common/components/form/redux';
import { Box, Button, Stack } from 'common/ui';

import { FIRST_STEP_FORM } from '../constants';
import validate from '../validate';

const FirstStepAuthForm = ({
    errorsInitAuth,
    handleShowPassword,
    handleSubmit,
    isLoadingInitAuth,
    onChangePassword,
    showPassword,
}) => {
    const { t } = useTranslation();

    return (
        <form>
            <Stack spacing={3}>
                <Field
                    autoComplete={'false'}
                    component={ReduxFieldInput}
                    name="email"
                    placeholder={t('Email')}
                    type="email"
                    variant="outlined"
                />

                <Field
                    autoComplete={'false'}
                    component={ReduxFieldInput}
                    endAdornmentIcon={
                        showPassword ? <VisibilityOff /> : <Visibility />
                    }
                    handleClickButtonEndAdornment={handleShowPassword}
                    name="password"
                    onChange={onChangePassword}
                    placeholder={t('Password')}
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                />

                <ShowErrors
                    errors={errorsInitAuth}
                    severity="error"
                    variant="filled"
                />
            </Stack>

            <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                <Button
                    disabled={isLoadingInitAuth}
                    fullWidth
                    height="48px"
                    onClick={handleSubmit}
                    size="large"
                    type="submit"
                >
                    {isLoadingInitAuth ? `${t('Loading')}...` : t('Next')}
                </Button>
            </Box>
        </form>
    );
};

FirstStepAuthForm.propTypes = {
    errorsInitAuth: PropTypes.array,
    handleShowPassword: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoadingInitAuth: PropTypes.bool,
    onChangePassword: PropTypes.func,
    showPassword: PropTypes.bool,
};

export default reduxForm({
    form: FIRST_STEP_FORM,
    validate,
})(FirstStepAuthForm);
