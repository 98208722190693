import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Box, Button, Container } from 'common/ui';

import * as actions from './actions';
import { ListItems, ProductForm } from './components';
import * as selectors from './selectors';

const AccesoriesContainer = ({
    accessories,
    accessoryBrands,
    fetchBrands,
    fetchAccessories,
    handleClickBulkItems,
    handleClickCreate,
    handleClickUpdate,
    handleDuplicate,
    handleSaveRow,
    isDuplicatingProduct,
    isFetching,
    isFetchingBulk,
    isOpenProductForm,
    pagination,
    reset,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        brandName: '',
        orderBy: null,
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: null,
        status: 'active',
        type: '',
    });

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        fetchBrands();
        fetchAccessories(filterData);
    }, []);

    return (
        <>
            {!isOpenProductForm ? (
                <Container maxWidth="">
                    <Box mb={3} textAlign="right">
                        <Button
                            disabled={isFetching}
                            fullWidth={false}
                            height="48px"
                            onClick={() => handleClickCreate()}
                            startIcon={<AddIcon />}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            type="button"
                        >
                            {t('Add')}
                        </Button>
                    </Box>

                    <ListItems
                        accessoryBrands={accessoryBrands}
                        filterData={filterData}
                        filterItems={fetchAccessories}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDuplicate={(item) =>
                            handleDuplicate(filterData, item.id)
                        }
                        handleClickUpdate={(item) => handleClickUpdate(item)}
                        handleSaveRow={handleSaveRow}
                        isFetching={
                            isDuplicatingProduct || isFetching || isFetchingBulk
                        }
                        items={accessories}
                        paginationData={pagination}
                        setFilterData={setFilterData}
                    />
                </Container>
            ) : (
                <Container maxWidth="">
                    <ProductForm
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                    />
                </Container>
            )}
        </>
    );
};

AccesoriesContainer.propTypes = {
    accessories: PropTypes.array,
    accessoryBrands: PropTypes.array,
    fetchAccessories: PropTypes.func,
    fetchBrands: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleDuplicate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isDuplicatingProduct: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFetchingBulk: PropTypes.bool,
    isOpenProductForm: PropTypes.bool,
    pagination: PropTypes.object,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    accessories: selectors.getAccessoriesData,
    accessoryBrands: selectors.getBrandsData,
    isDuplicatingProduct: selectors.getIsDuplicatingProduct,
    isFetching: selectors.getAccessoriesIsFetching,
    isFetchingBulk: selectors.getIsFetchingBulk,
    isOpenProductForm: selectors.getIsOpenProductForm,
    pagination: selectors.getAccessoriesPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBrands: () => dispatch(actions.fetchBrands()),
    fetchAccessories: (filterData) =>
        dispatch(actions.fetchAccessories(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
    handleDuplicate: (filterData, id) =>
        dispatch(actions.duplicateAccessory(filterData, id)),
    handleSaveRow: (item) => dispatch(actions.updateProduct(item)),
    reset: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccesoriesContainer);
