import { updateRole } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import * as selectors from '../selectors';

import fetchRoles from './fetchRoles';
import resetForm from './resetForm';
import toggleModalEdition from './toggleModalEdition';

const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0);

const handleCheckboxField = (values) => {
    const object_keys = Object.keys(values);
    let temp = [];

    object_keys.forEach((item) => {
        if (values[item] === true)
            temp.push(isNumber(item) ? Number(item) : item);
    });

    return temp;
};

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());
    const groups = [];

    if (values.groups.length > 0) {
        values.groups.forEach((value, key) => {
            const subpermissions = [];

            if (value.subpermissions) {
                const objectKeys = Object.keys(value.subpermissions.field);

                objectKeys.forEach((key) => {
                    const fieldValue = value.subpermissions.field[key];
                    const listValues = handleCheckboxField(fieldValue);

                    subpermissions.push({
                        field: key,
                        type: listValues.length > 1 ? 'list' : 'string',
                        value:
                            listValues.length > 1
                                ? JSON.stringify(listValues)
                                : listValues.toString(),
                        operation: 0,
                    });
                });
            }

            if (value) {
                if (parseInt(values.type) == 0 && values.owner_type == 0) {
                    if (value.owner) {
                        groups.push({
                            group_id: key,
                            is_enabled:
                                value.owner.permission_view === true ||
                                value.owner.permission_add === true ||
                                value.owner.permission_change === true ||
                                value.owner.permission_delete === true,
                            permission_view:
                                value.owner.permission_view === true,
                            permission_add: value.owner.permission_add === true,
                            permission_change:
                                value.owner.permission_change === true,
                            permission_delete:
                                value.owner.permission_delete === true,
                            subpermissions: subpermissions,
                        });
                    }
                } else if (
                    parseInt(values.type) == 0 &&
                    values.owner_type == 1
                ) {
                    if (value.general_manager) {
                        groups.push({
                            group_id: key,
                            is_enabled:
                                value.general_manager.permission_view ===
                                    true ||
                                value.general_manager.permission_add === true ||
                                value.general_manager.permission_change ===
                                    true ||
                                value.general_manager.permission_delete ===
                                    true,
                            permission_view:
                                value.general_manager.permission_view === true,
                            permission_add:
                                value.general_manager.permission_add === true,
                            permission_change:
                                value.general_manager.permission_change ===
                                true,
                            permission_delete:
                                value.general_manager.permission_delete ===
                                true,
                            subpermissions: subpermissions,
                        });
                    }
                } else if (
                    parseInt(values.type) == 3 &&
                    values.owner_type == 2
                ) {
                    if (value.superadmin) {
                        groups.push({
                            group_id: key,
                            is_enabled:
                                value.superadmin.permission_view === true ||
                                value.superadmin.permission_add === true ||
                                value.superadmin.permission_change === true ||
                                value.superadmin.permission_delete === true,
                            permission_view:
                                value.superadmin.permission_view === true,
                            permission_add:
                                value.superadmin.permission_add === true,
                            permission_change:
                                value.superadmin.permission_change === true,
                            permission_delete:
                                value.superadmin.permission_delete === true,
                            subpermissions: subpermissions,
                        });
                    }
                } else if (
                    parseInt(values.type) == 1 &&
                    values.owner_type == null
                ) {
                    if (value.branch_office_manager) {
                        groups.push({
                            group_id: key,
                            is_enabled:
                                value.branch_office_manager.permission_view ===
                                    true ||
                                value.branch_office_manager.permission_add ===
                                    true ||
                                value.branch_office_manager
                                    .permission_change === true ||
                                value.branch_office_manager
                                    .permission_delete === true,
                            permission_view:
                                value.branch_office_manager.permission_view ===
                                true,
                            permission_add:
                                value.branch_office_manager.permission_add ===
                                true,
                            permission_change:
                                value.branch_office_manager
                                    .permission_change === true,
                            permission_delete:
                                value.branch_office_manager
                                    .permission_delete === true,
                            subpermissions: subpermissions,
                        });
                    }
                } else if (
                    parseInt(values.type) == 2 &&
                    values.owner_type == null
                ) {
                    if (value.salesman_agent) {
                        groups.push({
                            group_id: key,
                            is_enabled:
                                value.salesman_agent.permission_view === true ||
                                value.salesman_agent.permission_add === true ||
                                value.salesman_agent.permission_change ===
                                    true ||
                                value.salesman_agent.permission_delete === true,
                            permission_view:
                                value.salesman_agent.permission_view === true,
                            permission_add:
                                value.salesman_agent.permission_add === true,
                            permission_change:
                                value.salesman_agent.permission_change === true,
                            permission_delete:
                                value.salesman_agent.permission_delete === true,
                            subpermissions: subpermissions,
                        });
                    }
                }
            }
        });
    }

    const newValues = {
        groups: JSON.stringify(groups),
        maximum_discount: values.maximum_discount,
        name: values.name,
        type: values.type == 3 ? 0 : values.type,
        owner_type: values.owner_type,
        contacts_visualization_level: values.contacts_visualization_level,
    };

    dispatch({ type: SAVE_ITEM });

    updateRole(values.company_id, values.id, newValues)
        .then((response) => {
            dispatch({ type: SAVE_ITEM_SUCCESS, payload: response.data });

            dispatch(
                fetchRoles(
                    filters.id,
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleModalEdition(false));
            dispatch(resetForm());

            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_ITEM_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
