import React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from 'common/ui';
import { isJsonString } from 'common/utils/helpers';
import { scroll } from 'common/utils/mixins';

const TableWrapper = styled(Box)`
    height: 400px;
    overflow-y: auto;
    padding-right: 8px;
    width: 100%;
    ${scroll.custom}
`;

const LogDetails = ({ metafields }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    if (isEmpty(metafields))
        return (
            <Box py={2} textAlign="center">
                <Typography fontWeight="bold" variant="body2">
                    {t('No data')}
                </Typography>
            </Box>
        );

    const renderValue = (value) => {
        if (isNull(value) || isEmpty(value)) return '---';
        if (isJsonString(value))
            return (
                <ReactJson
                    collapsed={true}
                    src={value}
                    theme={isDarkMode ? 'monokai' : 'rjv-default'}
                />
            );
        return value;
    };
    return (
        <TableWrapper>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Field')}</TableCell>
                        <TableCell>{t('Old value')}</TableCell>
                        <TableCell>{t('New value')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {metafields.map((meta) => {
                        return (
                            <TableRow key={meta.id}>
                                <TableCell>{meta.field}</TableCell>
                                <TableCell>
                                    {renderValue(meta.old_value)}
                                </TableCell>
                                <TableCell>
                                    {renderValue(meta.current_value)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableWrapper>
    );
};

LogDetails.propTypes = {
    metafields: PropTypes.array,
};

export default LogDetails;
