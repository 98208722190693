import React from 'react';

import { Switch } from 'react-router-dom';

import { LayoutRoute } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { LoginLayout, PageNotFoundLayout } from 'common/layouts';
import DefaultLayout from 'common/layouts/defaultLayout';

import afterSalesPowerStation from '../afterSalesPowerStation';
import afterSalesProjects from '../afterSalesProjects';
import alliances from '../alliances';
import companies from '../companies';
import company from '../company';
import dashboard from '../dashboard';
import filterFiles from '../filterFiles';
import fineTuningAI from '../fineTuningAI';
import login from '../login';
import modelTrainingData from '../modelTrainingData';
import parameters from '../parameters';
import products from '../products';
import profile from '../profile';
import proposals from '../proposals';
import reports from '../reports';
import templates from '../templates';
import trainingDataAI from '../trainingDataAI';
import trainingDataAITags from '../trainingDataAITags';
import users from '../users';

const Container = () => {
    return (
        <Switch>
            <LayoutRoute
                component={login.Container}
                layout={LoginLayout}
                isPublic
                path="/login"
            />

            <LayoutRoute
                component={dashboard.Container}
                exact
                isHidden
                layout={DefaultLayout}
                path={['/', '/dashboard']}
                redirect={{ pathname: '/companies' }}
            />

            <LayoutRoute
                component={reports.Container}
                exact
                layout={DefaultLayout}
                path={['/reports', '/reports/:tab']}
                requiredPermissions={[PERMISSION_LIST.REPORTS]}
            />

            <LayoutRoute
                component={companies.Container}
                exact
                layout={DefaultLayout}
                path={['/companies']}
                requiredPermissions={[PERMISSION_LIST.COMPANIES]}
            />

            <LayoutRoute
                component={company.Container}
                exact
                layout={DefaultLayout}
                path={[
                    '/company-file',
                    '/company-file/:id',
                    '/company-file/:id/:tab',
                ]}
                requiredPermissions={[PERMISSION_LIST.COMPANIES]}
            />

            <LayoutRoute
                component={products.Container}
                exact
                layout={DefaultLayout}
                path={[
                    '/products',
                    '/products/:section',
                    '/products/:section/:tab',
                ]}
                requiredPermissions={[PERMISSION_LIST.PRODUCTS]}
            />

            <LayoutRoute
                component={proposals.Container}
                exact
                layout={DefaultLayout}
                path={['/proposals']}
                requiredPermissions={[PERMISSION_LIST.PROPOSALS]}
            />

            <LayoutRoute
                component={templates.Container}
                exact
                layout={DefaultLayout}
                path={['/templates', '/templates/:tab']}
                requiredPermissions={[PERMISSION_LIST.TEMPLATES]}
            />

            <LayoutRoute
                component={alliances.Container}
                exact
                layout={DefaultLayout}
                path={['/alliances']}
                requiredPermissions={[PERMISSION_LIST.ALLIANCES]}
            />

            <LayoutRoute
                component={parameters.Container}
                exact
                layout={DefaultLayout}
                path={['/parameters', '/parameters/:tab']}
                requiredPermissions={[PERMISSION_LIST.RATES]}
            />

            <LayoutRoute
                component={users.Container}
                exact
                layout={DefaultLayout}
                path={['/users']}
                requiredPermissions={[PERMISSION_LIST.USERS]}
            />

            <LayoutRoute
                component={fineTuningAI.Container}
                exact
                layout={DefaultLayout}
                path={['/ai', '/ai/fine-tuning']}
                requiredPermissions={[PERMISSION_LIST.AI_MODELS]}
            />

            <LayoutRoute
                component={modelTrainingData.Container}
                exact
                layout={DefaultLayout}
                path={[
                    '/ai/fine-tuning/:id',
                    '/ai/fine-tuning/:id/training-data',
                ]}
                requiredPermissions={[PERMISSION_LIST.AI_MODELS]}
            />

            <LayoutRoute
                component={trainingDataAI.Container}
                exact
                layout={DefaultLayout}
                path={['/ai/fine-tuning/:model/training-data/:id']}
                requiredPermissions={[PERMISSION_LIST.AI_MODELS]}
            />

            <LayoutRoute
                component={filterFiles.Container}
                exact
                layout={DefaultLayout}
                path={['/ai/filter-files', '/ai/filter-files/:tab']}
                requiredPermissions={[PERMISSION_LIST.AI_FILES]}
            />

            <LayoutRoute
                component={trainingDataAITags.Container}
                exact
                layout={DefaultLayout}
                path={['/ai/tags']}
                requiredPermissions={[PERMISSION_LIST.AI_TAGS]}
            />

            <LayoutRoute
                component={afterSalesProjects.Container}
                exact
                layout={DefaultLayout}
                path={['/after-sales-power-stations']}
                requiredPermissions={[PERMISSION_LIST.AFTER_SALES]}
            />

            <LayoutRoute
                component={afterSalesPowerStation.Container}
                exact
                layout={DefaultLayout}
                path={['/after-sales-power-stations/:id']}
                requiredPermissions={[PERMISSION_LIST.AFTER_SALES]}
            />

            <LayoutRoute
                component={profile.Container}
                exact
                layout={DefaultLayout}
                path={['/profile']}
            />

            <LayoutRoute component={''} layout={PageNotFoundLayout} path="*" />
        </Switch>
    );
};

Container.propTypes = {};

export default Container;
