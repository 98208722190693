import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BasicDataGrid, DropdownCardMenu } from 'common/components';
import { Box, Chip } from 'common/ui';
import { formatISODate, getContrastingTextColor } from 'common/utils/helpers';
import {
    getDateFormatByLocale,
    getLocaleEquivalenceForDateFns,
} from 'common/utils/helpers/multiregion';

const TrainingDataTable = ({
    deleteTrainingData,
    disabled,
    isFetchingModel,
    onRowClick,
    prepareUpdateTrainingData,
    restartTrainingData,
    trainingData,
}) => {
    const { t } = useTranslation();
    const locale = getLocaleEquivalenceForDateFns();
    const dateFormat = getDateFormatByLocale();

    const columns = [
        {
            align: 'left',
            field: 'id',
            flex: 1,
            headerName: 'Id',
            valueGetter: (params) => params?.row?.id,
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            valueGetter: (params) =>
                formatISODate(params?.row?.created_at, locale, dateFormat),
        },
        {
            align: 'left',
            field: 'tags',
            flex: 1,
            headerName: t('Tags'),
            renderCell: (params) => {
                const { row } = params;

                return (
                    <Box display="flex" gap={1} flexWrap="wrap">
                        {row?.tags?.map((tag) => {
                            const textColor = getContrastingTextColor(
                                tag.color
                            );
                            return (
                                <Chip
                                    key={tag.id}
                                    label={tag.name}
                                    sx={{
                                        backgroundColor: tag.color,
                                        '& .MuiChip-label': {
                                            color: textColor,
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            );
                        })}
                    </Box>
                );
            },
        },
        {
            align: 'center',
            field: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <DropdownCardMenu
                        items={[
                            {
                                disabled,
                                label: t('Edit'),
                                handleClick: () =>
                                    prepareUpdateTrainingData(row.id),
                                icon: <EditIcon fontSize="small" />,
                            },
                            {
                                disabled,
                                label: t('Delete'),
                                handleClick: () => deleteTrainingData(row.id),
                                icon: <DeleteIcon fontSize="small" />,
                            },
                            {
                                disabled,
                                label: t('Restart'),
                                handleClick: () => restartTrainingData(row.id),
                                icon: <RestoreIcon fontSize="small" />,
                            },
                        ]}
                    />
                );
            },
            sortable: false,
            width: 50,
        },
    ];

    return (
        <BasicDataGrid
            columns={columns}
            isLoading={isFetchingModel}
            onRowClick={(row) => onRowClick(row.id)}
            rows={trainingData}
        />
    );
};

TrainingDataTable.propTypes = {
    deleteTrainingData: PropTypes.func,
    disabled: PropTypes.bool,
    isFetchingModel: PropTypes.bool,
    onRowClick: PropTypes.func,
    prepareUpdateTrainingData: PropTypes.func,
    restartTrainingData: PropTypes.func,
    trainingData: PropTypes.array,
};

export default TrainingDataTable;
