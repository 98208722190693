import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model?.initialValues
);

export const getModelTrainingData = createSelector(
    getModel,
    (model) => model?.modelTrainingData
);

export const getDataModelTrainingData = createSelector(
    getModelTrainingData,
    (model) => model?.data || {}
);

export const getModelTrainingDataErrors = createSelector(
    getModelTrainingData,
    (model) => model?.errors
);

export const getModelTrainingDataIsFetching = createSelector(
    getModelTrainingData,
    (model) => model?.isFetching
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model?.isOpenModal
);

export const getSaveTrainingData = createSelector(
    getModel,
    (model) => model?.saveTrainingData
);

export const getSaveTrainingDataErrors = createSelector(
    getSaveTrainingData,
    (model) => model?.errors
);

export const getSaveTrainingDataIsSaving = createSelector(
    getSaveTrainingData,
    (model) => model?.isSaving
);
