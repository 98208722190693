import React from 'react';

import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';

import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    Select,
    MenuItem,
} from 'common/ui';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CustomSelect = ({
    disabled,
    fullWidth = true,
    label,
    multiple = false,
    onChange,
    options = [],
    variant = 'filled',
    value,
    visible = true,
    ...other
}) => {
    return visible ? (
        <Box>
            <FormControl
                disabled={disabled}
                fullWidth={fullWidth}
                variant={variant}
            >
                <InputLabel>{label}</InputLabel>
                <Select
                    value={value}
                    label={label}
                    onChange={onChange}
                    multiple={multiple}
                    displayEmpty={multiple}
                    renderValue={(selected) => {
                        if (isArray(selected)) {
                            if (selected.length > 0) {
                                let selectedOptionsLabel = [];
                                selected.forEach((s) => {
                                    const opt = options.find(
                                        (o) => o.optionValue === s
                                    );
                                    if (opt)
                                        selectedOptionsLabel.push(opt.label);
                                });
                                return selectedOptionsLabel.join(', ');
                            }
                        } else {
                            const opt = options.find(
                                (o) => o.optionValue === selected
                            );
                            return opt ? opt.label : '';
                        }
                        return '';
                    }}
                    MenuProps={MenuProps}
                    {...other}
                >
                    {options.map(
                        ({ label, optionValue, disabled: optionDisabled }) => (
                            <MenuItem
                                key={`select-${optionValue}-${label}`}
                                value={optionValue}
                                disabled={optionDisabled}
                            >
                                {multiple ? (
                                    <>
                                        <Checkbox
                                            checked={
                                                value.indexOf(optionValue) > -1
                                            }
                                        />
                                        <ListItemText primary={label} />
                                    </>
                                ) : (
                                    label
                                )}
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        </Box>
    ) : null;
};

CustomSelect.propTypes = {
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
    variant: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
};

export default CustomSelect;
