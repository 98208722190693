const Tooltip = (theme) => {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: theme.palette.grey[800],
                    //color: 'rgba(0, 0, 0, 0.87)',
                    boxShadow: theme.shadows[1],
                    fontSize: 13,
                    padding: theme.spacing(2),
                    margin: theme.spacing(2),
                },
                arrow: {
                    color: theme.palette.grey[800],
                    '&:before': {
                        boxShadow: theme.shadows[1],
                    },
                },
            },
        },
    };
};

export default Tooltip;
