import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteDatasheet from './deleteDatasheet';
import prepareCloseForm from './prepareCloseForm';

export default (productId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        onSuccess: () => dispatch(deleteDatasheet(productId)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(prepareCloseForm(false));
    dispatch(alerts.actions.show(dataAlert));
};
