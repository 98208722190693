import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFilesModel = createSelector(getModel, (model) => model.files);

export const getFilesData = createSelector(getFilesModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((file) => {
              const {
                  billing_month,
                  billing_year,
                  customer_energy_consumption: { dp1, rate },
                  id,
                  url,
              } = file;

              return {
                  dp1,
                  id,
                  month: billing_month,
                  rate,
                  url,
                  year: billing_year,
              };
          })
);

export const getFilesErrors = createSelector(
    getFilesModel,
    (model) => model.errors
);

export const getFilesIsFetching = createSelector(
    getFilesModel,
    (model) => model.isFetching
);

export const getFilesPagination = createSelector(
    getFilesModel,
    (model) => model.data.pagination || {}
);

export const getCountriesModel = createSelector(
    getModel,
    (model) => model.countries
);

export const getCountriesData = createSelector(
    getCountriesModel,
    (model) => model.data
);

export const getCountriesIsFetching = createSelector(
    getCountriesModel,
    (model) => model.isFetching
);

export const getPoliticalDivisionsModel = createSelector(
    getModel,
    (model) => model.politicalDivisions
);

export const getPoliticalDivisionsData = createSelector(
    getPoliticalDivisionsModel,
    (model) => model.data
);

export const getPoliticalDivisionsIsFetching = createSelector(
    getPoliticalDivisionsModel,
    (model) => model.isFetching
);

export const getRatesModel = createSelector(
    getModel,
    (model) => model.fetchRates
);

export const getRatesIsFetching = createSelector(
    getRatesModel,
    (model) => model.isFetching
);

export const getRatesOptionsForSelect = createSelector(getRatesModel, (rates) =>
    rates.data.map((item) => ({
        label: item.name,
        optionValue: item.id,
    }))
);
