import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MenuPopoverList } from 'common/components';
import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import { Typography } from 'common/ui';

const BranchOfficeFormatter = ({ row }) => {
    const { t } = useTranslation();

    const { id, branch_office, branch_offices = [], role_level } = row;

    const isCustomOrGM = [
        ROLE_LEVELS.CUSTOMIZED,
        ROLE_LEVELS.GENERAL_MANAGER,
    ].includes(role_level);

    if (isCustomOrGM) {
        return (
            <MenuPopoverList
                items={branch_offices}
                keyName={`branch-office-name-${id}`}
                titleList={t('Multi-branch office', { count: 2 })}
                titleMenu={t('Branch office', { count: 2 })}
            />
        );
    }

    if (branch_office) {
        return (
            <Typography
                variant="subtitle2"
                sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                title={branch_office?.name}
            >
                {branch_office ? branch_office.name : '- - -'}
            </Typography>
        );
    }

    return (
        <Typography
            variant="subtitle2"
            sx={{
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            title={branch_office?.name}
        >
            {'- - -'}
        </Typography>
    );
};

BranchOfficeFormatter.propTypes = {
    row: PropTypes.object,
};

const BranchOfficeFormatterBuild = (_, row) => {
    return <BranchOfficeFormatter row={row} />;
};

export default BranchOfficeFormatterBuild;
