import isEmpty from 'lodash/isEmpty';

import fetchAccessories from './fetchAccessories';
import resetForm from './resetForm';
import toggleProductForm from './toggleProductForm';

export default (filterData = {}) =>
    (dispatch) => {
        if (!isEmpty(filterData)) dispatch(fetchAccessories(filterData));
        dispatch(resetForm());
        dispatch(toggleProductForm(false));
    };
