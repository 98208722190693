import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteTag from './deleteTag';

export default (id) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [i18next.t('Are you sure to remove this tag?')],
        onSuccess: () => dispatch(deleteTag(id)),
        title: i18next.t('Delete'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
