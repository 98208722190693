import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import KeyIndicator from 'common/components/KeyIndicator';
import { Box, Grid } from 'common/ui';
import { numberFormat } from 'common/utils/helpers';

import { getChartSettings } from '../helpers';
import * as selectors from '../selectors';

import PlaceholderTemplateChart from './PlaceholderTemplateChart';

const ProposalsTotals = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    proposalsTotals,
    readyPlaceholder,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const chartConfig = getChartSettings(
        proposalsTotals.categories,
        currencyIso,
        currencyLocale,
        currencySymbol,
        proposalsTotals.series
    );

    return (
        <Grid container mt={2}>
            <Grid item xs={12} lg={8}>
                <PlaceholderTemplateChart ready={readyPlaceholder}>
                    <Chart
                        height="250"
                        options={{
                            ...chartConfig.options,
                            theme: { mode: theme.palette.mode },
                        }}
                        series={chartConfig.series}
                        type="bar"
                    />
                </PlaceholderTemplateChart>
            </Grid>

            <Grid item xs={12} lg={4}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{ width: '100%' }}
                >
                    <KeyIndicator
                        isLoading={readyPlaceholder}
                        title={t('Proposals issued')}
                        value={numberFormat(proposalsTotals.projects_number, {
                            decimals: 0,
                            locale: currencyLocale,
                            style: 'decimal',
                        })}
                        widthIcon="20px"
                    />
                    <KeyIndicator
                        isLoading={readyPlaceholder}
                        title={t('Value of the proposals')}
                        value={numberFormat(proposalsTotals.projects_totals, {
                            currency: currencyIso,
                            locale: currencyLocale,
                            style: 'currency',
                            symbol: currencySymbol,
                        })}
                        widthIcon="20px"
                    />
                    <KeyIndicator
                        isLoading={readyPlaceholder}
                        title={t('Power of the proposals')}
                        value={numberFormat(proposalsTotals.projects_kws, {
                            decimals: 0,
                            locale: currencyLocale,
                            style: 'decimal',
                            unit: 'kW',
                        })}
                        widthIcon="20px"
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    proposalsTotals: selectors.getProposalsTotalsData,
});

ProposalsTotals.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    proposalsTotals: PropTypes.object,
    readyPlaceholder: PropTypes.bool,
};

export default connect(mapStateToProps, null)(ProposalsTotals);
