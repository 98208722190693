import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import alerts from 'common/modules/alerts';

import prepareDelete from './prepareDelete';

export default (item) => (dispatch) => {
    dispatch(alerts.actions.close());

    if (!isEmpty(item.templates)) {
        const dataAlert = {
            confirmText: i18next.t('Continue deletion'),
            messages: [
                i18next.t(
                    'Make sure this category no longer has templates assigned'
                ),
            ],
            onSuccess: () => dispatch(prepareDelete(item)),
            title: i18next.t('Delete category'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    } else {
        dispatch(prepareDelete(item));
    }
};
