import React, { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import CardContainer from 'common/components/CardContainer';
import {
    ReduxFieldInput,
    ReduxFieldSelect,
    ReduxFieldNumberInput,
} from 'common/components/form/redux';
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Typography,
    Tooltip,
} from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const ApiAccountForm = ({
    errors,
    formValues,
    handleClickSave,
    handleEditionChange,
    handleSubmit,
    isDeleting,
    isEnabledEdition,
    isFetchingDetail,
    isNewRecord,
    isSaving,
    prepareDelete,
    prepareReturnCatalog,
    setCurrentItem,
}) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    const handleDelete = (item) => {
        prepareDelete(item);
    };

    return (
        <Stack spacing={3}>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title={t('Back')}>
                    <IconButton
                        onClick={() => {
                            prepareReturnCatalog();
                            setCurrentItem({});
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <FormGroup>
                    <FormControlLabel
                        disabled={isNewRecord || isFetchingDetail}
                        control={
                            <Switch
                                checked={isEnabledEdition}
                                onChange={handleEditionSwitchChange}
                            />
                        }
                        label={t('Edit')}
                        labelPlacement="start"
                    />
                </FormGroup>
            </Stack>

            <Box>
                <form>
                    <Stack spacing={3}>
                        <CardContainer
                            title={t('General information')}
                            isCollapsable
                            isOpen
                            loading={isFetchingDetail}
                            withDivider
                        >
                            <Grid container spacing={2} pt={3}>
                                <Grid item xs={12}>
                                    <Grid item container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="phone"
                                                component={ReduxFieldInput}
                                                placeholder={t('Phone')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="email"
                                                component={ReduxFieldInput}
                                                placeholder={t('Email')}
                                                variant="outlined"
                                                required
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container spacing={2} pt={2}>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                name="password"
                                                component={ReduxFieldInput}
                                                placeholder={t('Password')}
                                                disabled={
                                                    isFetchingDetail ||
                                                    !isEnabledEdition
                                                }
                                                endAdornmentIcon={
                                                    showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )
                                                }
                                                handleClickButtonEndAdornment={
                                                    handleShowPassword
                                                }
                                                type={
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {!isNewRecord && (
                                            <Grid
                                                container
                                                item
                                                spacing={2}
                                                xs={12}
                                                md={6}
                                            >
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        name="rpus_counter"
                                                        component={
                                                            ReduxFieldNumberInput
                                                        }
                                                        placeholder={t('RPUs')}
                                                        variant="outlined"
                                                        required
                                                        disabled
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        name="status"
                                                        component={
                                                            ReduxFieldSelect
                                                        }
                                                        label={t('Status')}
                                                        variant="outlined"
                                                        required
                                                        disabled
                                                        options={[]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        component="div"
                                        mt={4}
                                        mb={4}
                                    >
                                        * {t('Required fields')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContainer>

                        <ShowErrors
                            errors={errors}
                            mt={3}
                            severity="error"
                            variant="filled"
                        />

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={3}
                            pt={3}
                            pb={3}
                        >
                            <Grid container mt={2}>
                                <Grid item>
                                    {!isNewRecord && isEnabledEdition && (
                                        <Button
                                            color="secondary"
                                            disabled={
                                                isFetchingDetail ||
                                                isSaving ||
                                                isDeleting
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={() => {
                                                handleDelete(formValues);
                                            }}
                                        >
                                            {t('Delete API')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="flex-end"
                            >
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={isFetchingDetail || isSaving}
                                        fullWidth={false}
                                        height="48px"
                                        size="large"
                                        onClick={() => {
                                            prepareReturnCatalog();
                                            setCurrentItem({});
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </Grid>
                                {isEnabledEdition && (
                                    <Grid item>
                                        <Button
                                            disabled={
                                                isFetchingDetail || isSaving
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={handleSubmit(
                                                handleClickSave
                                            )}
                                            type="submit"
                                        >
                                            {isSaving
                                                ? `${t('Loading')}...`
                                                : isNewRecord
                                                ? t('Create')
                                                : t('Save')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </Stack>
    );
};

ApiAccountForm.propTypes = {
    errors: PropTypes.array,
    formValues: PropTypes.object,
    handleClickSave: PropTypes.func,
    handleEditionChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isDeleting: PropTypes.bool,
    isEnabledEdition: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    prepareDelete: PropTypes.func,
    prepareReturnCatalog: PropTypes.func,
    setCurrentItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isDeleting: selectors.getIsDeletingItem,
    isEnabledEdition: selectors.getEnabledEdition,
    isFetchingDetail: selectors.getIsFetchingApiAccountsDetail,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    prepareDelete: (item) => dispatch(actions.prepareDelete(item)),
    prepareReturnCatalog: () => dispatch(actions.returnCatalog()),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(ApiAccountForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
