import { saveRateHistoryDatagrid } from 'common/api/suppliers';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';

import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (values, onSuccessCallback = null) =>
    (dispatch) => {
        dispatch({ type: SAVE });

        saveRateHistoryDatagrid(values.rate, values.year, values.month, {
            data: values.rateDatagrid,
        })
            .then((response) => {
                dispatch({ type: SAVE_SUCCESS, payload: response.data });

                dispatch(toggleModal(false));
                dispatch(resetForm());
                showToast();

                if (onSuccessCallback) {
                    onSuccessCallback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_FAILURE,
                    payload: error.response.data.errors,
                });
            });
    };
