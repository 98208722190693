import React, { useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from 'common/ui';
import { scroll } from 'common/utils/mixins';

import { getDateTitle } from '../helpers';

import LogItem from './LogItem';

const LogsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow-y: scroll;
    padding: 8px;
    width: 100%;
    ${scroll.custom}
`;

const ListItems = ({
    companyId,
    companyLogs,
    filterLogs,
    handleSelectLog,
    isFetching,
    paginationData,
    selectedLogId,
}) => {
    const { t } = useTranslation();
    const wrapperRef = useRef(null);

    const renderLogs = () => {
        if (!isFetching && isEmpty(companyLogs))
            return (
                <Box textAlign="center">
                    <Typography fontWeight="bold" variant="body2">
                        {t('No records found')}
                    </Typography>
                </Box>
            );

        return companyLogs.map((group, index) => (
            <List
                key={index}
                component="nav"
                subheader={
                    <Box>
                        <Typography variant="h5">
                            {getDateTitle(group?.date)}
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                    </Box>
                }
            >
                {group?.logs.map((log) => (
                    <ListItem
                        key={log.id}
                        sx={{
                            border:
                                selectedLogId === log?.id
                                    ? '2px solid #FF9800'
                                    : 0,
                        }}
                    >
                        <ListItemText
                            primary={
                                <LogItem
                                    handleSelectLog={handleSelectLog}
                                    logData={log}
                                />
                            }
                        />
                    </ListItem>
                ))}
            </List>
        ));
    };

    return (
        <LogsWrapper ref={wrapperRef}>
            <InfiniteScroll
                getScrollParent={() => wrapperRef.current}
                hasMore={
                    !isFetching &&
                    paginationData?.current_page < paginationData?.total_pages
                }
                loader={
                    <div className="scroll-loader" key={0}>
                        {t('Loading').concat('...')}
                    </div>
                }
                loadMore={(page) => {
                    if (isFetching) return;
                    filterLogs({ companyId, page });
                }}
                pageStart={1}
                threshold={100}
                useWindow={false}
            >
                {renderLogs()}
            </InfiniteScroll>
            {isFetching && (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            )}
        </LogsWrapper>
    );
};

ListItems.propTypes = {
    companyId: PropTypes.string,
    companyLogs: PropTypes.array,
    filterLogs: PropTypes.func,
    handleSelectLog: PropTypes.func,
    isFetching: PropTypes.bool,
    paginationData: PropTypes.object,
    selectedLogId: PropTypes.string,
};

export default ListItems;
