import React from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridRowModes } from '@mui/x-data-grid';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/DropdownCardMenu';
import { Box, IconButton, Tooltip } from 'common/ui';
import showToast from 'common/utils/showToast';

const ProductActionsFormatter = ({
    filterData,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickUpdate,
    row,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const isArchived = row?.status_flag === 'archived';
    const isInEditMode = rowModesModel[row?.id]?.mode === GridRowModes.Edit;

    const handleClickSave = (id) => () => {
        if (isEmpty(rowModesModel[row?.id]?.code)) {
            showToast({
                type: 'error',
                body: t('{{field}} can not be empty', {
                    field: t('Code'),
                }),
            });
            return false;
        }
        if (isEmpty(rowModesModel[row?.id]?.name)) {
            showToast({
                type: 'error',
                body: t('{{field}} can not be empty', {
                    field: t('Name'),
                }),
            });
            return false;
        }
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        setSelectedRowId(null);
    };

    const handleClickCancel = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        setSelectedRowId(null);
    };

    const renderOptions = () => {
        if (isInEditMode) {
            return (
                <>
                    <Tooltip title={t('Save changes')}>
                        <IconButton onClick={handleClickSave(selectedRowId)}>
                            <SaveIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Cancel')}>
                        <IconButton onClick={handleClickCancel(selectedRowId)}>
                            <CancelIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        }

        return (
            <>
                <IconButton
                    disabled={isEmpty(row.data_sheet)}
                    href={row.data_sheet}
                    target="_blank"
                >
                    <VisibilityIcon fontSize="small" />
                </IconButton>
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () => handleClickUpdate(row),
                            icon: <EditIcon fontSize="small" />,
                            label: t('Edit'),
                        },
                        {
                            handleClick: () => handleClickDuplicate(row),
                            icon: <FileCopyIcon fontSize="small" />,
                            label: t('Duplicate'),
                        },
                        {
                            handleClick: () =>
                                handleClickBulkItems(
                                    [row.id],
                                    'archived',
                                    filterData
                                ),
                            icon: <ArchiveOutlinedIcon fontSize="small" />,
                            label: t('Archive'),
                            visible: !isArchived,
                        },
                        {
                            handleClick: () =>
                                handleClickBulkItems(
                                    [row.id],
                                    'active',
                                    filterData
                                ),
                            icon: <UnarchiveOutlinedIcon fontSize="small" />,
                            label: t('Unarchive'),
                            visible: isArchived,
                        },
                        {
                            handleClick: () =>
                                handleClickBulkItems(
                                    [row.id],
                                    'deleted',
                                    filterData
                                ),
                            icon: <DeleteIcon fontSize="small" />,
                            label: t('Delete'),
                            visible: isArchived,
                        },
                    ]}
                />
            </>
        );
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            {renderOptions()}
        </Box>
    );
};

ProductActionsFormatter.propTypes = {
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    row: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.string,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductActionsFormatter;
