import { getItems } from 'common/api/v1/rates';

import {
    FETCH_CUSTOM_RATES,
    FETCH_CUSTOM_RATES_FAILURE,
    FETCH_CUSTOM_RATES_SUCCESS,
} from '../actionTypes';

import setFilters from './setFilters';

export default (page = 1, orderBy = '', sortBy = '', searchStr = '') =>
    (dispatch) => {
        const perPage = 20;

        dispatch({ type: FETCH_CUSTOM_RATES });
        dispatch(setFilters({ page, orderBy, sortBy, searchStr }));

        getItems(page, perPage, orderBy, sortBy, searchStr)
            .then((response) =>
                dispatch({
                    type: FETCH_CUSTOM_RATES_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_CUSTOM_RATES_FAILURE,
                    payload: error.response.data.errors,
                });
            });
    };
