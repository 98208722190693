import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomPagination, DropdownCardMenu } from 'common/components';
import { Box, Card, CardContent, DataGrid, LinearProgress } from 'common/ui';
import { getDataGridClasses } from 'common/utils/helpers';

import { downloadFile } from '../actions';
import { FILE_STATUS, FILE_STATUS_KEYS } from '../constants';
import { getNameFromPath } from '../helpers';

import BulkOperationsCard from './BulkOperationsCard';

const ListItems = ({
    downloadBulk,
    filterData,
    filterItems,
    isFetching,
    items,
    setFileAsSuccess,
    setFilterData,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [selectionModel, setSelectionModel] = useState([]);

    const columns = [
        {
            align: 'left',
            field: 'file',
            flex: 2,
            headerName: t('Name'),
            valueGetter: (params) => getNameFromPath(params?.row?.file),
        },
        {
            align: 'left',
            field: 'status',
            flex: 1,
            headerName: t('Status'),
            renderCell: (params) => {
                const status = FILE_STATUS[params?.row?.status];
                return (
                    <>
                        <Box
                            bgcolor={status?.color || 'transparent'}
                            borderRadius="5px"
                            height={10}
                            marginRight={1}
                            width={10}
                        />
                        {t(status?.label || 'Unknown')}
                    </>
                );
            },
            sortable: false,
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Created at'),
        },
        {
            align: 'right',
            field: 'actions',
            headerName: '',
            renderCell: (params) => (
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () => downloadFile(params?.row?.file),
                            icon: <DownloadIcon fontSize="small" />,
                            label: t('Download'),
                        },
                        {
                            handleClick: () =>
                                setFileAsSuccess(params?.row?.id, filterData),
                            icon: <CheckIcon fontSize="small" />,
                            label: t('Trained'),
                            visible:
                                params?.row?.status !==
                                FILE_STATUS_KEYS.SUCCESS,
                        },
                    ]}
                />
            ),
            sortable: false,
            width: 50,
        },
    ];

    const handleOnChangeFilter = (e, name) => {
        const newFilterData = { ...filterData, [name]: e.target.value };
        setFilterData(newFilterData);
    };

    return (
        <>
            <BulkOperationsCard
                downloadBulk={downloadBulk}
                filterData={filterData}
                handleClickOnFilter={() => filterItems(filterData)}
                handleOnChangeFilter={handleOnChangeFilter}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <CardContent>
                    <DataGrid
                        autoHeight
                        checkboxSelection
                        columns={columns}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        disableColumnMenu
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        loading={isFetching}
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        pageSize={20}
                        pagination
                        paginationMode="client"
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="client"
                        sx={{
                            ...getDataGridClasses(isDarkMode),
                            '& .MuiDataGrid-cell': {
                                cursor: 'pointer',
                                p: '10px',
                            },
                        }}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    downloadBulk: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    setFileAsSuccess: PropTypes.func,
    setFilterData: PropTypes.func,
};

export default ListItems;
