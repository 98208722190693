export const NAME = 'company-logs';

export const ACTIONS_TYPES = {
    ADDITION: 1,
    CHANGE: 2,
    DELETION: 3,
};
export const DATE_FORMATS = {
    HOUR: 'HH:mm',
    TITLE: 'EEEE, LLL d',
};
