import i18next from 'i18next';

import { exportFile } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DOWNLOAD_CSV_SUCCESS,
} from '../actionTypes';

export default (filterData) => (dispatch) => {
    dispatch({ type: DOWNLOAD_CSV });
    const { countryName, planName, searchText, subscriptionStatus } =
        filterData;

    exportFile({
        countryName: countryName,
        planName: planName,
        searchText,
        subscriptionStatus: subscriptionStatus.join(','),
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute(
                'download',
                `${i18next.t('Company', { count: 2 })}.csv`
            );
            document.body.appendChild(link);
            link.click();
            dispatch({ type: DOWNLOAD_CSV_SUCCESS });
        })
        .catch((error) => {
            dispatch({
                type: DOWNLOAD_CSV_FAILURE,
                payload: error.response.data.errors,
            });
            showToast({
                type: 'error',
                body: i18next.t('An error occurred while downloading the file'),
            });
        });
};
