import { OPEN_PRODUCT_FORM } from '../actionTypes';

import resetForm from './resetForm';

export default (value) => (dispatch) => {
    if (!value) dispatch(resetForm());
    dispatch({
        type: OPEN_PRODUCT_FORM,
        payload: value,
    });
};
