import React from 'react';

import PropTypes from 'prop-types';

import { List, ListItem, ListItemText, Skeleton } from 'common/ui';

const Placeholder = () => (
    <List>
        {[...Array(2)].map((_, index) => (
            <ListItem key={Math.random() + index} alignItems="flex-start">
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Skeleton></Skeleton>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Skeleton></Skeleton>
                        </React.Fragment>
                    }
                />
            </ListItem>
        ))}
    </List>
);

const PlaceholderIrradiationSourceInfoCard = ({ children, ready }) => {
    return <>{ready ? <Placeholder /> : children}</>;
};

PlaceholderIrradiationSourceInfoCard.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderIrradiationSourceInfoCard;
