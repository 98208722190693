import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import { ReduxFieldInput } from 'common/components/form/redux';
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tooltip,
} from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const ProductForm = ({
    errors,
    filterData,
    handleEditionChange,
    handleOnSave,
    handleSubmit,
    isEnabledEdition,
    isNewRecord,
    isSaving,
    prepareReturnCatalog,
}) => {
    const { t } = useTranslation();

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    const handleClickSave = (values) => handleOnSave(filterData, values);

    return (
        <Stack spacing={3}>
            <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tooltip title={t('Back')}>
                    <IconButton
                        onClick={() => prepareReturnCatalog(filterData)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <FormGroup>
                    <FormControlLabel
                        disabled={isNewRecord}
                        control={
                            <Switch
                                checked={isEnabledEdition}
                                onChange={handleEditionSwitchChange}
                            />
                        }
                        label={t('Edit')}
                        labelPlacement="start"
                    />
                </FormGroup>
            </Stack>

            <Box>
                <form>
                    <Stack spacing={3}>
                        <Grid container spacing={3} pt={3}>
                            <Grid item xs={12}>
                                <Field
                                    name="name"
                                    component={ReduxFieldInput}
                                    placeholder={t('Name')}
                                    variant="outlined"
                                    required
                                    disabled={!isEnabledEdition}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="url"
                                    component={ReduxFieldInput}
                                    placeholder="Url"
                                    variant="outlined"
                                    required
                                    disabled={!isEnabledEdition}
                                />
                            </Grid>
                        </Grid>

                        <ShowErrors
                            errors={errors}
                            mt={3}
                            severity="error"
                            variant="filled"
                        />

                        {isEnabledEdition && (
                            <Grid container mt={1}>
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        display: 'flex',
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row',
                                        },
                                        justifyContent: {
                                            xs: 'center',
                                            md: 'right',
                                        },
                                        gap: 2,
                                    }}
                                >
                                    <Button
                                        disabled={isSaving}
                                        fullWidth={false}
                                        onClick={handleSubmit(handleClickSave)}
                                        sx={{
                                            width: { xs: '100%', md: 'auto' },
                                            order: { xs: 1, md: 2 },
                                            mb: { xs: 2, md: 0 },
                                        }}
                                        type="button"
                                        variant="outlined"
                                    >
                                        {t('Save')}
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Stack>
                </form>
            </Box>
        </Stack>
    );
};

ProductForm.propTypes = {
    errors: PropTypes.array,
    filterData: PropTypes.object,
    handleEditionChange: PropTypes.func,
    handleOnSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    prepareReturnCatalog: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isEnabledEdition: selectors.getEnabledEdition,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    handleOnSave: (filterData, values) =>
        dispatch(actions.save(filterData, values)),
    prepareReturnCatalog: (filterData) =>
        dispatch(actions.returnCatalog(filterData)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(ProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
