import { NAME } from './constants';

export const FETCH_COMPANIES = `${NAME}/FETCH_COMPANIES`;
export const FETCH_COMPANIES_FAILURE = `${NAME}/FETCH_COMPANIES_FAILURE`;
export const FETCH_COMPANIES_SUCCESS = `${NAME}/FETCH_COMPANIES_SUCCESS`;

export const FETCH_PROPOSALS = `${NAME}/FETCH_PROPOSALS`;
export const FETCH_PROPOSALS_FAILURE = `${NAME}/FETCH_PROPOSALS_FAILURE`;
export const FETCH_PROPOSALS_SUCCESS = `${NAME}/FETCH_PROPOSALS_SUCCESS`;

export const RESET = `${NAME}/RESET`;
