import React, { useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { IconButton, InputAdornment, TextField } from 'common/ui';

const DEBOUNCE_TIME = 200;

const SearchTable = ({
    defaultValue = '',
    handleOnSearchChange,
    placeholder = 'Buscar...',
    variant = 'outlined',
}) => {
    const [value, setValue] = useState('');
    const [timer, setTimer] = useState(null);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (!isEmpty(defaultValue)) {
            setValue(defaultValue);
        }
    }, []);

    const iconAdornments = isSelected
        ? {
              startAdornment: (
                  <InputAdornment position="start">
                      <SearchIcon />
                  </InputAdornment>
              ),
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton
                          onClick={() => {
                              handleChange('');
                              setIsSelected(false);
                          }}
                          edge="end"
                      >
                          <ClearIcon />
                      </IconButton>
                  </InputAdornment>
              ),
          }
        : {
              startAdornment: (
                  <InputAdornment position="start">
                      <SearchIcon />
                  </InputAdornment>
              ),
          };

    const handleChange = (e) => {
        setValue(e);
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(e), DEBOUNCE_TIME));
    };

    const triggerChange = (e) => handleOnSearchChange(e);

    return (
        <TextField
            fullWidth
            InputProps={iconAdornments}
            onChange={(e) => handleChange(e.target.value)}
            onKeyUp={(e) => {
                if (e.target.value && e.target.value.length > 0)
                    setIsSelected(true);
                else setIsSelected(false);
            }}
            placeholder={placeholder}
            variant={variant}
            value={value}
            onFocus={(e) => {
                e.target.select();
                if (e.target.value && e.target.value.length > 0)
                    setIsSelected(true);
                else setIsSelected(false);
            }}
        />
    );
};

SearchTable.propTypes = {
    defaultValue: PropTypes.string,
    handleOnSearchChange: PropTypes.func,
    placeholder: PropTypes.string,
    variant: PropTypes.string,
};

export default SearchTable;
