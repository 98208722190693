import React from 'react';

import ListIcon from '@mui/icons-material/List';
import { PropTypes } from 'prop-types';

import { MenuPopover } from 'common/components';
import {
    Box,
    Divider,
    IconButton,
    MenuItem,
    Stack,
    Typography,
} from 'common/ui';

const MenuProfile = ({ items = [], keyName, titleList, titleMenu }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                alignItems: 'center',
                justifyContent: 'start',
                width: '100%',
            }}
        >
            <Typography
                title={titleList}
                variant="subtitle2"
                sx={{
                    cursor: 'pointer',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {titleList}
            </Typography>
            <IconButton
                onClick={handleClick}
                sx={{
                    padding: 0,
                    fontWeight: 'bold',
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                        },
                    }),
                }}
            >
                <ListIcon />
            </IconButton>
            <MenuPopover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                sx={{
                    width: 220,
                }}
                withArrow={false}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {titleMenu}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />
                {items.map((item) => (
                    <MenuItem key={`${keyName}-options-${item.id}`}>
                        {item.name}
                    </MenuItem>
                ))}
            </MenuPopover>
        </Stack>
    );
};

MenuProfile.propTypes = {
    items: PropTypes.array,
    keyName: PropTypes.string,
    titleList: PropTypes.string,
    titleMenu: PropTypes.string,
};

export default MenuProfile;
