import i18next from 'i18next';
import { get, isNull } from 'lodash';

import { numberFormat } from 'common/utils/helpers';

import { SOCKET_SUBSCRIPTION_EVENTS } from './constants';

export const getChartSettings = (
    categories,
    currencyIso,
    currencyLocale,
    currencySymbol,
    series
) => {
    return {
        options: {
            colors: ['#0073d0', '#03e396'],
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
            },
            xaxis: {
                categories: categories,
            },
            yaxis: [
                {
                    axisBorder: {
                        show: true,
                        color: '#0073d0',
                    },
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (val) =>
                            numberFormat(val, {
                                currency: currencyIso,
                                locale: currencyLocale,
                                style: 'currency',
                                symbol: currencySymbol,
                            }),
                    },
                },
                {
                    opposite: true,
                    axisBorder: {
                        show: true,
                        color: '#03e396',
                    },
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (val) =>
                            numberFormat(val, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: 'kW',
                            }),
                    },
                },
            ],
            chart: {
                background: '#ffffff00',
                toolbar: {
                    show: false,
                },
            },
        },
        series: series,
    };
};

export const getProjectsChartSettings = (
    categories,
    currencyLocale,
    series
) => {
    return {
        options: {
            colors: ['#0073d0'],
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
            },
            xaxis: {
                categories: categories,
            },
            yaxis: [
                {
                    axisBorder: {
                        show: true,
                        color: '#0073d0',
                    },
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (val) =>
                            numberFormat(val, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                            }),
                    },
                },
            ],
            chart: {
                background: '#ffffff00',
                toolbar: {
                    show: false,
                },
            },
        },
        series: [series],
    };
};

export const getUsersDetails = (subscription) => {
    const counters = get(subscription, 'counters', {});

    if (isNull(subscription?.counters)) {
        return subscription?.purchased_users;
    }
    if (get(counters, 'maximum_users', 0) > 0) {
        return get(counters, 'maximum_users', 0);
    }
    return i18next.t('Unlimited', { count: 2 });
};

export const handleNormalizeValue = (value) => {
    if (isNull(value)) {
        return '---';
    }
    return `${value}`;
};

export const handleNormalizeValueToWhitespace = (value) => {
    if (isNull(value)) {
        return '';
    }
    return `${value}`;
};

export const handleSocketEvents = (
    companyId,
    event,
    fetchCompanyDetail,
    setEvent
) => {
    if (event === SOCKET_SUBSCRIPTION_EVENTS.UPDATE_SUBSCRIPTION)
        fetchCompanyDetail(companyId);
    setEvent(null);
};
