import React, { useEffect } from 'react';

import StarIcon from '@mui/icons-material/Star';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DialogContainer } from 'common/components';

import * as actions from './actions';
import { Form } from './components';
import * as selectors from './selectors';

const Container = ({
    filters,
    handleAfterSave,
    handleClickCloseModalOutstanding,
    isLoadingOutstandingDetail,
    isOpenModalOutstanding,
    reset,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    return (
        <DialogContainer
            loading={isLoadingOutstandingDetail}
            onClose={handleClickCloseModalOutstanding}
            open={isOpenModalOutstanding}
            fullWidth={true}
            title={t('Outstanding')}
            isSvgIcon
            svgIcon={StarIcon}
        >
            <Form filters={filters} handleAfterSave={handleAfterSave} />
        </DialogContainer>
    );
};

Container.propTypes = {
    filters: PropTypes.object,
    handleAfterSave: PropTypes.func,
    handleClickCloseModalOutstanding: PropTypes.func,
    isLoadingOutstandingDetail: PropTypes.bool,
    isOpenModalOutstanding: PropTypes.bool,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isLoadingOutstandingDetail: selectors.getIsFetchingOutstandingDetail,
    isOpenModalOutstanding: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModalOutstanding: () =>
        dispatch(actions.toggleModal(false)),
    reset: () => dispatch(actions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
