import styled from 'styled-components';

import { media } from 'common/utils/mixins';

export default styled.div`
    cursor: pointer;
    padding: 15px 0;

    svg {
        height: 14px;
        width: 14px;
    }

    svg .shape,
    svg path {
        fill: #ffffff6b;
    }

    .label {
        display: none;
    }

    .ico {
        display: block;
        height: 28px;
        position: relative;
        text-align: center;
        top: 1px;
        width: 28px;
    }

    &.active {
        background: rgba(255, 255, 255, 0.05);
        border-right: 4px solid #ff9a00;
    }

    &:hover .ico {
        transform: scale(1.14);
    }

    &.active,
    &:hover {
        background: rgba(255, 255, 255, 0.08);
        & div {
            color: #fff;
        }

        svg .shape,
        svg path {
            fill: #fff;
        }
    }

    ${media.md`
        svg {
            height: 28px;
            line-height: 28px;
            width: 28px;
        }

        .label{
            display: block;
        }
    `}
`;
