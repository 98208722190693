import React, { useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { CardContainer, ShowErrors } from 'common/components';
import {
    PlaceholderOwnerInfoCard,
    PlaceholderGeneralInfoCard,
    PlaceholderSubscriptionInfoCard,
    PlaceholderIrradiationSourceInfoCard,
} from 'common/components/placeholders';
import * as subscriptionSocket from 'common/sockets/subscription';
import {
    Card,
    CardActions,
    Container,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Pagination,
} from 'common/ui';

import * as actions from './actions';
import {
    BranchOfficesPreviewTable,
    CardTotalProposalIssued,
    GeneralInfoCard,
    IrradiationSourceInfoCard,
    OwnerInfoCard,
    SubscriptionInfoCard,
} from './components';
import { NAME } from './constants';
import * as selectors from './selectors';

const CompanyGeneralContainer = ({
    branchOffices,
    companyDetail,
    companyDetailErrors,
    connectSocket,
    disconnectSocket,
    fetchAgents,
    fetchBranchOffices,
    fetchCompanyDetail,
    isFetchingBranchOffices,
    isFetchingCompanyDetail,
    isUpdatingSubscription,
    match,
    pagination,
    prepareUpdateSubscription,
    resetComponentState,
    resetForm,
    socketHandler,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [ownerEditionEnabled, setOwnerEditionEnabled] = useState(false);

    useEffect(() => {
        return () => {
            resetComponentState();
        };
    }, []);

    useEffect(() => {
        if (match.params.id) {
            fetchCompanyDetail(match.params.id);
            fetchBranchOffices(match.params.id, page, orderBy, sortBy);
            fetchAgents(match.params.id);
        }
    }, [match.params.id]);

    useEffect(() => {
        if (match.params.id) connectSocket(match.params.id);
        return () => {
            disconnectSocket();
        };
    }, [match.params.id]);

    const handleOnChangePage = (event, value) => {
        setPage(value);
        fetchBranchOffices(match.params.id, page, orderBy, sortBy);
    };

    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchBranchOffices(match.params.id, page, orderBy, sortBy);
    };

    const handleOnOwnerEditionEnabled = () => {
        setOwnerEditionEnabled(!ownerEditionEnabled);
        resetForm();
    };

    return (
        <Container maxWidth="">
            <Stack spacing={3}>
                <Grid container spacing={3}>
                    {isArray(companyDetailErrors) &&
                        !isEmpty(companyDetailErrors) && (
                            <Grid item xs={12}>
                                <ShowErrors
                                    errors={companyDetailErrors}
                                    severity="error"
                                    variant="filled"
                                />
                            </Grid>
                        )}

                    <Grid item xs={12} md={6}>
                        <Grid item container spacing={3}>
                            <Grid item xs={12}>
                                <CardContainer
                                    collapsePaddingButton="0px"
                                    sxCard={{ height: '470px' }}
                                    title={t('Company', { count: 1 })}
                                    withDivider
                                >
                                    <PlaceholderGeneralInfoCard
                                        ready={isFetchingCompanyDetail}
                                    >
                                        <GeneralInfoCard
                                            companyDetail={companyDetail}
                                        />
                                    </PlaceholderGeneralInfoCard>
                                </CardContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <CardContainer
                                    collapsePaddingButton="0px"
                                    action={
                                        <Tooltip
                                            title={
                                                ownerEditionEnabled
                                                    ? t('Cancel')
                                                    : t('Edit')
                                            }
                                        >
                                            <span>
                                                <IconButton
                                                    disabled={
                                                        isFetchingCompanyDetail
                                                    }
                                                    onClick={
                                                        handleOnOwnerEditionEnabled
                                                    }
                                                >
                                                    {ownerEditionEnabled ? (
                                                        <ClearIcon />
                                                    ) : (
                                                        <EditIcon />
                                                    )}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    }
                                    title={t('Owner')}
                                    withDivider
                                >
                                    <PlaceholderOwnerInfoCard
                                        ready={isFetchingCompanyDetail}
                                    >
                                        <OwnerInfoCard
                                            editMode={ownerEditionEnabled}
                                            handleOnOwnerEditionEnabled={
                                                handleOnOwnerEditionEnabled
                                            }
                                        />
                                    </PlaceholderOwnerInfoCard>
                                </CardContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid item container spacing={3}>
                            <Grid item xs={12}>
                                <CardContainer
                                    collapsePaddingButton="0px"
                                    title={t('Subscription')}
                                    withDivider
                                >
                                    <PlaceholderSubscriptionInfoCard
                                        ready={isFetchingCompanyDetail}
                                    >
                                        <SubscriptionInfoCard
                                            companyDetail={companyDetail}
                                            fetchCompanyDetail={
                                                fetchCompanyDetail
                                            }
                                            isUpdatingSubscription={
                                                isUpdatingSubscription
                                            }
                                            prepareUpdateSubscription={
                                                prepareUpdateSubscription
                                            }
                                            socketHandler={socketHandler}
                                        />
                                    </PlaceholderSubscriptionInfoCard>
                                </CardContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <CardContainer
                                    collapsePaddingButton="0px"
                                    title={t('Irradiation source')}
                                    withDivider
                                >
                                    <PlaceholderIrradiationSourceInfoCard
                                        ready={isFetchingCompanyDetail}
                                    >
                                        <IrradiationSourceInfoCard
                                            companyDetail={companyDetail}
                                        />
                                    </PlaceholderIrradiationSourceInfoCard>
                                </CardContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <CardTotalProposalIssued
                    companyId={match.params.id}
                    currency={get(companyDetail, 'currency', {})}
                    currencyIso={'MXN'}
                    currencyLocale={'es-Mx'}
                    currencySymbol={'$'}
                />

                <CardContainer
                    collapsePaddingButton="0px"
                    title={t('Branch office', { count: 2 })}
                    withDivider
                >
                    <Card
                        sx={{
                            boxShadow: (theme) => theme.customShadows.z1,
                        }}
                    >
                        <BranchOfficesPreviewTable
                            handleSortable={handleSortable}
                            isFetching={isFetchingBranchOffices}
                            items={branchOffices}
                            sortedBy={sortBy}
                            orderdBy={orderBy}
                        />

                        {!isFetchingBranchOffices &&
                            pagination &&
                            !isEmpty(pagination) && (
                                <CardActions>
                                    <Pagination
                                        color="primary"
                                        count={
                                            Math.ceil(
                                                parseInt(
                                                    pagination.total_rows
                                                ) /
                                                    parseInt(
                                                        pagination.per_page
                                                    )
                                            ) || 0
                                        }
                                        shape="rounded"
                                        showFirstButton
                                        showLastButton
                                        sx={{
                                            marginLeft: 'auto',
                                        }}
                                        page={pagination.current_page}
                                        onChange={handleOnChangePage}
                                        variant="outlined"
                                    />
                                </CardActions>
                            )}
                    </Card>
                </CardContainer>
            </Stack>
        </Container>
    );
};

CompanyGeneralContainer.propTypes = {
    branchOffices: PropTypes.array,
    companyDetail: PropTypes.object,
    companyDetailErrors: PropTypes.array,
    connectSocket: PropTypes.func,
    disconnectSocket: PropTypes.func,
    fetchAgents: PropTypes.func,
    fetchBranchOffices: PropTypes.func,
    fetchCompanyDetail: PropTypes.func,
    isFetchingBranchOffices: PropTypes.bool,
    isFetchingCompanyDetail: PropTypes.bool,
    isUpdatingSubscription: PropTypes.bool,
    match: PropTypes.object,
    pagination: PropTypes.object,
    prepareUpdateSubscription: PropTypes.func,
    resetComponentState: PropTypes.func,
    resetForm: PropTypes.func,
    socketHandler: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    branchOffices: selectors.getBranchOfficesData,
    companyDetail: selectors.getCompanyDetailData,
    companyDetailErrors: selectors.getCompanyDetailErrors,
    isFetchingBranchOffices: selectors.getBranchOfficesIsFetching,
    isFetchingCompanyDetail: selectors.getIsFetchingCompanyDetail,
    isUpdatingSubscription: selectors.getIsUpdatingSubscription,
    pagination: selectors.getBranchOfficesPagination,
    socketConected: selectors.getSocketConected,
    socketError: selectors.getSocketError,
    socketHandler: selectors.getSocketHandler,
});

const mapDispatchToProps = (dispatch) => ({
    connectSocket: (companyId) =>
        dispatch(subscriptionSocket.connect(companyId)),
    disconnectSocket: () => dispatch(subscriptionSocket.disconnect()),
    fetchAgents: (companyId) => dispatch(actions.fetchAgents(companyId)),
    fetchBranchOffices: (id, page, orderBy, sortBy) =>
        dispatch(actions.fetchBranchOffices(id, page, orderBy, sortBy)),
    fetchCompanyDetail: (id) => dispatch(actions.fetchCompanyDetail(id)),
    prepareUpdateSubscription: (id) =>
        dispatch(actions.prepareUpdateSubscription(id)),
    resetForm: () => dispatch(reset(`${NAME}/form`)),
    resetComponentState: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompanyGeneralContainer));
