import { object, string } from 'yup';

import { emailRegex, phoneRegex } from 'common/utils/helpers';
import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({
        EMAIL_VALIDATION_TEXT,
        REQUIRED_TEXT,
        PHONE_VALIDATION_TEXT,
        maximumNumberCharacters,
        minimumNumberCharacters,
    }) =>
        object().shape({
            phone: string()
                .matches(phoneRegex, PHONE_VALIDATION_TEXT)
                .max(10, maximumNumberCharacters(10))
                .nullable(),
            email: string()
                .required(REQUIRED_TEXT)
                .matches(emailRegex, EMAIL_VALIDATION_TEXT)
                .nullable(),
            password: string()
                .required(REQUIRED_TEXT)
                .min(8, minimumNumberCharacters(8))
                .nullable(),
        })
);
