import React from 'react';

import i18next from 'i18next';
import { toast } from 'react-toastify';

import ToastNotification from 'common/components/ToastNotification';

export default (props) => {
    const defaults = {
        autoClose: 3000,
        closeButton: false,
        closeOnClick: false,
        draggable: true,
        body: 'Successfully saved',
        hideProgressBar: true,
        newestOnTop: true,
        title: '',
        type: 'success',
        variant: 'filled',
    };

    const values = { ...defaults, ...props };

    const {
        autoClose,
        body,
        closeButton,
        closeOnClick,
        draggable,
        hideProgressBar,
        newestOnTop,
        title,
        type,
        variant,
    } = values;

    const translatedBody = i18next.exists(body) ? i18next.t(body) : body;
    const customId = new Date().getTime();

    return toast(
        <ToastNotification
            body={translatedBody}
            title={title}
            toastId={customId}
            type={type}
            variant={variant}
        />,
        {
            autoClose: autoClose,
            closeButton,
            closeOnClick,
            draggable,
            hideProgressBar: hideProgressBar,
            newestOnTop,
            style: {
                background: 'transparent',
                boxShadow: 'none',
                borderRadius: '0px',
                padding: '0px',
            },
            toastId: customId,
        }
    );
};
