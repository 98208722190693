import update from 'immutability-helper';

import {
    DELETE_CATEGORY,
    DELETE_CATEGORY_FAILURE,
    DELETE_CATEGORY_SUCCESS,
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_FAILURE,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORY_DETAIL,
    FETCH_CATEGORY_DETAIL_FAILURE,
    FETCH_CATEGORY_DETAIL_SUCCESS,
    INITIALIZE_FORM,
    OPEN_CATEGORY_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_ENABLED_EDITION,
    SET_FILTERS,
    SET_IS_NEW_RECORD,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        name: null,
        file_url: null,
        templates: [],
    },
    categories: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    categoryDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    isNewRecord: false,
    isEnabledEdition: true,
    isOpenCategoryForm: false,
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    filters: {
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    deleteCategory: {
        isDeleting: false,
        data: [],
        error: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_CATEGORIES:
            return update(state, {
                categories: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_CATEGORIES_FAILURE:
            return update(state, {
                categories: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_CATEGORIES_SUCCESS:
            return update(state, {
                categories: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_CATEGORY_DETAIL:
            return update(state, {
                categoryDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_CATEGORY_DETAIL_FAILURE:
            return update(state, {
                categoryDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_CATEGORY_DETAIL_SUCCESS:
            return update(state, {
                categoryDetail: { $merge: { errors: [], isFetching: false } },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
            });

        case OPEN_CATEGORY_FORM:
            return update(state, {
                isOpenCategoryForm: {
                    $set: action.payload,
                },
            });

        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case SET_ENABLED_EDITION:
            return update(state, {
                isEnabledEdition: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case DELETE_CATEGORY:
            return update(state, {
                deleteCategory: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_CATEGORY_SUCCESS:
            return update(state, {
                deleteCategory: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_CATEGORY_FAILURE:
            return update(state, {
                deleteCategory: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });
        default:
            return state;
    }
}
