import i18next from 'i18next';

import { deleteFont } from 'common/api/templatesFonts';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    DELETE_FONT,
    DELETE_FONT_SUCCESS,
    DELETE_FONT_FAILURE,
} from '../actionTypes';

import fetchFonts from './fetchFonts';

export default (filterData, id) => (dispatch) => {
    dispatch({ type: DELETE_FONT });

    deleteFont(id)
        .then(() => {
            dispatch({ type: DELETE_FONT_SUCCESS, payload: null });
            dispatch(alerts.actions.close());
            dispatch(fetchFonts(filterData));
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch({
                type: DELETE_FONT_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
