import { updateUser } from 'common/api/staffUsers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_USER,
    SAVE_USER_FAILURE,
    SAVE_USER_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { usersActions } from '../reducer';
import * as selectors from '../selectors';

export default (values) => (dispatch, getState) => {
    const state = getState();
    const data = selectors.getFetchUsersData(state);

    if (!values.id) return;

    const newValues = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
    };

    dispatch(usersActions[SAVE_USER]());

    updateUser(newValues, values.id)
        .then(() => {
            dispatch(usersActions[SAVE_USER_SUCCESS]());
            const newItems = data.map((item) => {
                if (item.id === values.id) {
                    return values;
                }
                return item;
            });
            dispatch(usersActions[UPDATE_ITEMS_SUCCESS](newItems));
            showToast();
        })
        .catch((error) => {
            dispatch(
                usersActions[SAVE_USER_FAILURE](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
