import React from 'react';

import PropTypes from 'prop-types';

import ReactHookFormSwitch from 'common/components/form/bootstrap/ReactHookFormSwitch';
import { Box, Typography } from 'common/ui';

import StyledItem from './StyledItem';

const GroupItemSwitch = ({ control, disabled, index, item, name }) => {
    return (
        <StyledItem className={index % 2 === 0 ? 'StripedRow' : ''}>
            <Typography id={item.id} variant="body2">
                {item?.name}
            </Typography>
            <Box ml="auto">
                <ReactHookFormSwitch
                    control={control}
                    disabled={disabled}
                    fullWidth
                    name={name}
                />
            </Box>
        </StyledItem>
    );
};

GroupItemSwitch.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    index: PropTypes.number,
    item: PropTypes.object,
    name: PropTypes.string,
};
export default GroupItemSwitch;
