import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { Alert, Box, Button, Divider, Switch, Typography } from 'common/ui';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { ROLE_TYPES } from '../constants';
import {
    getHaveSelectedPermissions,
    getRoleOptions,
    handleChangeRole,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import PermissionList from './PermissionList';

const UserForm = ({
    errors,
    filterData,
    initialValues,
    isFetchingRoles,
    isFetchingUserDetails,
    isNewRecord,
    isSaving,
    roles,
    rolesDictionary,
    save,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: { isNewRecord },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        const selectedRole = rolesDictionary[watch('company_group')];
        handleChangeRole({ selectedRole, roleId: watch('id'), setValue });
    }, [watch('company_group')]);

    const isFetching = isFetchingRoles || isFetchingUserDetails;

    const isDisabled = isFetching;

    const handleChangeSwitch = (event) => {
        setValue('change_password', event.target.checked);
    };

    const handleOnSave = (values) => save(filterData, values);
    const haveSelectedPermissions = getHaveSelectedPermissions(
        watch('permissions')
    );
    const showAlert =
        watch('roleType') === ROLE_TYPES.CUSTOM && !haveSelectedPermissions;

    return (
        <form onSubmit={handleSubmit(handleOnSave)}>
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                name="first_name"
                label={t('Name')}
            />
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                name="last_name"
                label={t('Last name')}
            />
            <ReactHookFormInput
                control={control}
                disabled={isDisabled || !isNewRecord}
                name="email"
                label={t('Email')}
            />
            {!isNewRecord && (
                <Box alignItems="center" display="flex" mb={2}>
                    <Typography variant="body2">
                        {t('Change password')}
                    </Typography>
                    <Switch
                        checked={watch('change_password')}
                        onChange={handleChangeSwitch}
                    />
                </Box>
            )}
            {watch('change_password') && (
                <Box>
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Password').concat(' *')}
                        name="password"
                        type="password"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Confirm password').concat(' *')}
                        name="confirm_password"
                        type="password"
                    />
                </Box>
            )}
            <ReactHookFormSelect
                control={control}
                disabled={isDisabled}
                fullWidth
                name="company_group"
                label={t('Role')}
                onChange={() => {}}
                options={getRoleOptions(roles)}
            />

            <PermissionList
                control={control}
                isDisabled={isDisabled}
                show={watch('roleType') === ROLE_TYPES.CUSTOM}
            />

            {showAlert && (
                <Alert severity="warning" sx={{ mt: 2 }}>
                    {t('You must select at least one permission')}
                </Alert>
            )}

            <ShowErrors errors={errors} />

            <Divider sx={{ my: 2 }} />

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'end',
                }}
            >
                <Button
                    disabled={isDisabled || isSaving || showAlert}
                    fullWidth={false}
                    type="submit"
                >
                    {isNewRecord ? 'Agregar' : 'Guardar'}
                </Button>
            </Box>
        </form>
    );
};

UserForm.propTypes = {
    errors: PropTypes.array,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    isFetchingRoles: PropTypes.bool,
    isFetchingUserDetails: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    roles: PropTypes.array,
    rolesDictionary: PropTypes.object,
    save: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetchingRoles: selectors.getIsFetchingRoles,
    isFetchingUserDetails: selectors.getIsFetchingUserDetails,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingUser,
    roles: selectors.getFetchRolesData,
    rolesDictionary: selectors.getFetchRolesDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    save: (filterData, values) => dispatch(actions.save(filterData, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
