import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardContent, Grid } from 'common/ui';

import SelectTags from './SelectTags';

const Filter = ({
    disabled,
    handleFilterTrainingData,
    selectedTags,
    setSelectedTags,
    tagsOptions,
}) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                <Grid
                    alignItems="center"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item sm={6} xs={12}>
                        <SelectTags
                            disabled={disabled}
                            onChange={setSelectedTags}
                            options={tagsOptions}
                            value={selectedTags}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={disabled}
                            onClick={handleFilterTrainingData}
                        >
                            {t('Filter')}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

Filter.propTypes = {
    disabled: PropTypes.bool,
    handleFilterTrainingData: PropTypes.func,
    selectedTags: PropTypes.array,
    setSelectedTags: PropTypes.func,
    tagsOptions: PropTypes.array,
};

export default Filter;
