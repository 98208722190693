import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from 'common/ui';

const ReactHookFormSelect = ({
    control,
    disabled,
    fullWidth,
    id,
    label,
    name,
    onChange,
    options,
    sxFormControl,
    variant,
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
            const valueIsBetweenTheOptions = options.some(
                (option) => option.value === field.value
            );

            return (
                <FormControl
                    error={Boolean(error)}
                    fullWidth={fullWidth}
                    size="small"
                    sx={sxFormControl}
                    variant={variant}
                >
                    <InputLabel
                        {...(field?.value &&
                            valueIsBetweenTheOptions && {
                                shrink: Boolean(field.value),
                            })}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        disabled={disabled}
                        displayEmpty={label === ''}
                        id={id}
                        label={label}
                        {...field}
                        onChange={(e) => {
                            field.onChange(e);
                            if (onChange) onChange(e);
                        }}
                        value={
                            field.value !== null && valueIsBetweenTheOptions
                                ? field.value
                                : ''
                        }
                    >
                        {options.map(
                            ({
                                disabled: optionDisabled,
                                label,
                                sxItem = {},
                                value,
                                visible = true,
                            }) =>
                                visible ? (
                                    <MenuItem
                                        disabled={optionDisabled}
                                        key={`select-${value}-${label}`}
                                        sx={sxItem}
                                        value={value}
                                    >
                                        {label}
                                    </MenuItem>
                                ) : null
                        )}
                    </Select>
                    {Boolean(error) && (
                        <FormHelperText>{error?.message}</FormHelperText>
                    )}
                </FormControl>
            );
        }}
    />
);

ReactHookFormSelect.defaultProps = {
    fullWidth: true,
    ignore: [],
    label: '',
    sxFormControl: {},
};

ReactHookFormSelect.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    ignore: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    required: PropTypes.bool,
    sxFormControl: PropTypes.object,
    variant: PropTypes.string,
};

export default ReactHookFormSelect;
