import API from '..';
const ENTITY = 'products';

export const filterBatteries = ({
    brandName = '',
    maxPower = null,
    orderBy,
    page = 1,
    perPage = 25,
    searchText,
    sortBy,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/batteries/`, {
        params: {
            page: page,
            per_page: perPage,
            status_flag: status,
            ...(brandName ? { brand: brandName } : {}),
            ...(maxPower ? { max_power: maxPower } : {}),
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const getBatteries = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/batteries/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getBatteryDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/battery/${id}/`);

export const createBattery = (data) =>
    API.post(`/api/v1/${ENTITY}/battery/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const updateBattery = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/battery/${id}/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const filterBrands = (str = '') =>
    API.get(`/api/v1/${ENTITY}/batteries/brands?search${str ? '=' + str : ''}`);
