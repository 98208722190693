import i18next from 'i18next';
import get from 'lodash/get';

import { getSupplierDetail } from 'common/api/suppliers';
import showToast from 'common/utils/showToast';

import {
    FETCH_SUPPLIER_DETAIL,
    FETCH_SUPPLIER_DETAIL_FAILURE,
    FETCH_SUPPLIER_DETAIL_SUCCESS,
} from '../actionTypes';

import fetchOrigins from './fetchOrigins';
import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleItemForm from './toggleItemForm';

export default (item) => (dispatch) => {
    dispatch(resetForm());
    dispatch(toggleItemForm(true));

    dispatch({ type: FETCH_SUPPLIER_DETAIL });

    getSupplierDetail(item.id)
        .then((response) => {
            dispatch({
                type: FETCH_SUPPLIER_DETAIL_SUCCESS,
                payload: response.data,
            });

            const values = {
                ...response.data,
                country: get(response.data, 'country.id', ''),
                countryName: get(response.data, 'country.name', ''),
                ratesCounter: get(response.data, 'rates_counter', 0),
            };

            dispatch(fetchOrigins(get(response.data, 'country.name', '')));

            dispatch(setIsNewRecord(false));
            dispatch(initializeForm(values));
            dispatch(setEnabledEdition(false));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_SUPPLIER_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t(
                    'An error occurred while querying the power supplier'
                ),
            });

            dispatch(toggleItemForm(false));
        });
};
