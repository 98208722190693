import React from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FieldArray, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import PlaceholderCustomTable from 'common/components/placeholders/PlaceholderCustomTable';
import {
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
} from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import RateHistoryFiedRedux from './RateHistoryFieldRedux';

const StyledTableHeaderCell = styled(TableCell)(({ theme, color }) => ({
    backgroundColor: theme.palette[color].lighter,
    color: theme.palette[color].contrastText,
    overflowWrap: 'break-word',
}));

const RateHistoryDatagrid = ({
    changeInput,
    errors,
    filters = {},
    formValues,
    handleAfterSave = null,
    handleClickCloseModal,
    handleClickSave,
    handleSubmit,
    isFetchingDetail,
    isSaving,
    rateLabel,
    reset,
}) => {
    const { t } = useTranslation();

    const handleSaveAction = (values) => {
        handleClickSave(values, () => {
            if (handleAfterSave)
                handleAfterSave(
                    filters?.page,
                    filters?.rateId,
                    filters?.year,
                    filters?.month,
                    filters?.summer,
                    filters?.region,
                    filters?.division,
                    filters?.orderBy,
                    filters?.sortBy,
                    filters?.searchStr
                );
        });
    };

    const getStaticColumns = () => {
        return [
            {
                align: 'left',
                dataField: 'year',
                label: t('Year', { count: 1 }),
                width: '10%',
            },
            {
                align: 'left',
                dataField: 'label.name',
                label: rateLabel,
                width: '10%',
            },
            {
                align: 'left',
                dataField: 'month.value',
                label: t('Month', { count: 1 }),
                width: '10%',
            },
        ];
    };

    const getDynamicColumns = () => {
        var dynamicDataColumns = [];
        if (!isEmpty(formValues.rateDatagrid)) {
            var keys = Object.keys(formValues.rateDatagrid[0]);

            if (!isEmpty(keys)) {
                dynamicDataColumns = keys
                    .filter(
                        (k) =>
                            ![
                                'id',
                                'year',
                                'label',
                                'month',
                                'editable',
                            ].includes(k)
                    )
                    .map((k) => {
                        return {
                            align: 'left',
                            dataField: k,
                            label: k,
                            width: '10%',
                        };
                    });
            }
        }

        return dynamicDataColumns;
    };

    return (
        <form>
            <Grid container spacing={2} pt={2}>
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: (theme) => theme.customShadows.z1,
                    }}
                >
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                {getStaticColumns()
                                    .concat(getDynamicColumns())
                                    .map((column) => (
                                        <StyledTableHeaderCell
                                            key={column.dataField}
                                            align={column.align}
                                            style={{
                                                width: column.width,
                                            }}
                                            color="secondary"
                                        >
                                            <Typography variant="subtitle2">
                                                {column.label}
                                            </Typography>
                                        </StyledTableHeaderCell>
                                    ))}
                                <StyledTableHeaderCell
                                    key={`action-column-header`}
                                    align="left"
                                    style={{
                                        width: '10%',
                                    }}
                                    color="secondary"
                                >
                                    <Typography variant="subtitle2">
                                        {t('Edit')}
                                    </Typography>
                                </StyledTableHeaderCell>
                            </TableRow>
                        </TableHead>

                        {isFetchingDetail ? (
                            <TableBody>
                                <PlaceholderCustomTable
                                    cols={
                                        getStaticColumns().concat(
                                            getDynamicColumns()
                                        ).length + 1
                                    }
                                    ready={isFetchingDetail}
                                />
                            </TableBody>
                        ) : (
                            <TableBody>
                                <FieldArray
                                    changeInput={changeInput}
                                    component={RateHistoryFiedRedux}
                                    dynamicColumns={getDynamicColumns()}
                                    name="rateDatagrid"
                                    rateDatagrid={formValues.rateDatagrid}
                                    staticColumns={getStaticColumns()}
                                />
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>

            <ShowErrors
                errors={errors}
                mt={3}
                severity="error"
                variant="filled"
            />

            <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
                <Grid item>
                    <Button
                        color="secondary"
                        disabled={isFetchingDetail || isSaving}
                        fullWidth={false}
                        height="48px"
                        size="large"
                        onClick={() => {
                            reset();
                            handleClickCloseModal();
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={isFetchingDetail || isSaving}
                        fullWidth={false}
                        height="48px"
                        size="large"
                        onClick={handleSubmit(handleSaveAction)}
                        type="submit"
                    >
                        {isSaving ? `${t('Loading')}...` : t('Save')}
                    </Button>
                </Grid>
            </Stack>
        </form>
    );
};

RateHistoryDatagrid.propTypes = {
    changeInput: PropTypes.func,
    errors: PropTypes.array,
    filters: PropTypes.object,
    formValues: PropTypes.object,
    handleAfterSave: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isFetchingDetail: PropTypes.bool,
    isSaving: PropTypes.bool,
    rateLabel: PropTypes.string,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isFetchingDetail: selectors.getIsFetchingRateHistoryDatagrid,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    handleClickCloseModal: () => dispatch(actions.toggleModal(false)),
    handleClickSave: (values, callback) =>
        dispatch(actions.save(values, callback)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(RateHistoryDatagrid);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
