import { boolean, object, number } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ REQUIRED_TEXT, NUMBER_TYPE_TEXT, getMinValueText }) =>
        object().shape({
            days_left: number()
                .typeError(NUMBER_TYPE_TEXT)
                .when('unlimited', {
                    is: false,
                    then: number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(1, getMinValueText(1)),
                })
                .nullable(),
            unlimited: boolean().required(REQUIRED_TEXT).nullable(),
        })
);
