import { object, string, ref } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (formValues, { isNewRecord }) => {
    const { minimumNumberCharacters, PASSWORDS_DONT_MATCH, REQUIRED_TEXT } =
        getValidationTexts();

    let validationRules = {
        company_group: string().required(REQUIRED_TEXT).nullable(),
        email: string().required(REQUIRED_TEXT).nullable(),
        first_name: string().required(REQUIRED_TEXT).nullable(),
        last_name: string().required(REQUIRED_TEXT).nullable(),
    };

    if (!isNewRecord && formValues?.change_password)
        validationRules = {
            ...validationRules,
            password: string()
                .min(8, minimumNumberCharacters(8))
                .required(REQUIRED_TEXT)
                .nullable(),
            confirm_password: string()
                .min(8, minimumNumberCharacters(8))
                .required(REQUIRED_TEXT)
                .oneOf([ref('password'), null], PASSWORDS_DONT_MATCH)
                .nullable(),
        };

    return object().shape(validationRules);
};
