import { fetchPoliticalDivisionsByCountry } from 'common/api/v1/parameters';

import {
    FECH_POLITICAL_DIVISIONS,
    FECH_POLITICAL_DIVISIONS_FAILURE,
    FECH_POLITICAL_DIVISIONS_SUCCESS,
} from '../actionTypes';

export default (country) => (dispatch) => {
    dispatch({ type: FECH_POLITICAL_DIVISIONS });
    fetchPoliticalDivisionsByCountry(country)
        .then((response) => {
            dispatch({
                payload: response.data.data,
                type: FECH_POLITICAL_DIVISIONS_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch({
                payload: error.response.data.errors,
                type: FECH_POLITICAL_DIVISIONS_FAILURE,
            });
        });
};
