import i18next from 'i18next';

import { getUsersCSV } from 'common/api/companies';
import { formatDate } from 'common/utils/dates';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DOWNLOAD_CSV_SUCCESS,
} from '../actionTypes';

export default (id, orderBy, sortBy, searchStr, openLoading, closeLoading) =>
    (dispatch) => {
        dispatch({ type: DOWNLOAD_CSV });
        openLoading();

        getUsersCSV(id, orderBy, sortBy, searchStr)
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                    'download',
                    `${i18next.t('User', { count: 2 })}_${formatDate(
                        new Date(),
                        't'
                    )}.csv`
                );
                document.body.appendChild(link);
                link.click();
                dispatch({ type: DOWNLOAD_CSV_SUCCESS });
                closeLoading();
            })
            .catch((error) => {
                dispatch({
                    type: DOWNLOAD_CSV_FAILURE,
                    payload: error.response.data.errors,
                });
                closeLoading();
                showToast({
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while downloading the file'
                    ),
                });
            });
    };
