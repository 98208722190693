import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    ReactHookFormCheck,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { STATUS_CONFIG, STATUS_KEYS } from 'common/constants/afterSales';
import { Box } from 'common/ui';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateStatus from '../validateStatus';

import FormModal from './FormModal';

const getStatusOption = (key, t) => ({
    label: t(STATUS_CONFIG[key]?.label),
    value: key,
});

const StatusModal = ({
    fetchProjects,
    initialValues,
    isOpen,
    isSaving,
    resetForm,
    setIsOpen,
    setProjectStatus,
}) => {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateStatus),
    });
    const { t } = useTranslation();

    useEffect(() => reset(initialValues), [initialValues, reset]);

    const onSave = (data) => {
        setProjectStatus(data, () => {
            setIsOpen(false);
            fetchProjects();
        });
    };

    const statusOptions = Object.values(STATUS_KEYS).map((key) =>
        getStatusOption(key, t)
    );

    const isDisabled = isSaving;

    return (
        <FormModal
            isOpen={isOpen}
            isSaving={isSaving}
            onSubmit={handleSubmit(onSave)}
            resetForm={resetForm}
            setIsOpen={setIsOpen}
            title={t('Change status')}
        >
            <Box my={2}>
                <ReactHookFormSelect2
                    control={control}
                    disabled={isDisabled}
                    isClearable={false}
                    label={t('Status')}
                    name="status"
                    options={[{ label: '', options: statusOptions }]}
                />

                <ReactHookFormCheck
                    control={control}
                    disabled={isDisabled}
                    label={t('Change project status')}
                    name="change_project_status"
                    sxFormLabel={{ mt: 2 }}
                />
            </Box>
        </FormModal>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpen: selectors.getIsStatusModalOpen,
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    resetForm: () => dispatch(actions.resetForm()),
    setIsOpen: (isOpen) => dispatch(actions.setOpenStatusModal(isOpen)),
    setProjectStatus: (values, callback) =>
        dispatch(actions.setProjectStatus(values, callback)),
});

StatusModal.propTypes = {
    fetchProjects: PropTypes.func,
    initialValues: PropTypes.object,
    isOpen: PropTypes.bool,
    isSaving: PropTypes.bool,
    resetForm: PropTypes.func,
    setIsOpen: PropTypes.func,
    setProjectStatus: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusModal);
