import i18next from 'i18next';

import { createTrainingDocument } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import { CREATE_TRAINING_DOCUMENT } from '../actionTypes';

import fetchModels from './fetchModels';

export default (modelId) => (dispatch) => {
    if (!modelId) return;

    dispatch({ type: CREATE_TRAINING_DOCUMENT });

    createTrainingDocument(modelId)
        .then((response) => {
            dispatch({
                type: CREATE_TRAINING_DOCUMENT,
                payload: response?.data,
            });

            showToast({ autoClose: 3000 });
            dispatch(fetchModels());
        })
        .catch((error) => {
            dispatch({
                type: CREATE_TRAINING_DOCUMENT,
                payload: error?.response?.data?.detail,
            });

            showToast({
                autoClose: 3000,
                body: i18next.t(
                    'An error occurred while creating the training document'
                ),
                type: 'error',
            });

            dispatch(fetchModels());
        });
};
