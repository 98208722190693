import API from '..';
const ENTITY = 'api-accounts';

export const getApiAccounts = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/filter/?search${str ? '=' + str : ''}&page=${page}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );

export const getApiAccountDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/account/${id}/`);

export const createApiAccount = (data) =>
    API.post(`/api/v1/${ENTITY}/account/`, data);

export const updateApiAccount = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/account/${id}/`, data);

export const deleteApiAccount = (id) =>
    API.delete(`/api/v1/${ENTITY}/account/${id}/`);
