import { getAgents } from 'common/api/companies';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

export default (companyId) => (dispatch) => {
    dispatch(companyGeneralActions[FETCH_AGENTS]());
    getAgents(companyId)
        .then((response) =>
            dispatch(
                companyGeneralActions[FETCH_AGENTS_SUCCESS](response.data.data)
            )
        )
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_AGENTS_FAILURE](
                    error.response.data.errors
                )
            )
        );
};
