import { getProposalsTotals } from 'common/api/companies';

import {
    FETCH_PROPOSALS_TOTALS,
    FETCH_PROPOSALS_TOTALS_FAILURE,
    FETCH_PROPOSALS_TOTALS_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeFilterForm from './initializeFilterForm';

export default (companyId, values) => (dispatch) => {
    const { agent, final_date: finalDate, initial_date: initialDate } = values;

    dispatch(companyGeneralActions[FETCH_PROPOSALS_TOTALS]());
    dispatch(initializeFilterForm(values));

    getProposalsTotals(companyId, initialDate, finalDate, agent)
        .then((response) =>
            dispatch(
                companyGeneralActions[FETCH_PROPOSALS_TOTALS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_PROPOSALS_TOTALS_FAILURE](
                    error.response.data.errors
                )
            )
        );
};
