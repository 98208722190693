import React, { useEffect } from 'react';

import FactoryIcon from '@mui/icons-material/Factory';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { HeaderContainer, TitleIcon } from 'common/components';
import * as afterSalesProvidersActions from 'common/modules/afterSalesProviders/actions';
import { Container, Typography } from 'common/ui';

import * as action from './actions';
import ListItems from './components/ListItems';

const CompaniesContainer = ({ fetchCompanies, fetchProviders }) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchCompanies();
        fetchProviders();
    }, []);

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">{t('After sales')}</Typography>
            </HeaderContainer>

            <Stack mb={3} ml={3}>
                <TitleIcon
                    isSvgIcon={true}
                    svgIcon={FactoryIcon}
                    title={t('Power station', { count: 2 })}
                />
            </Stack>

            <ListItems />
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    fetchCompanies: () => dispatch(action.fetchCompanies()),
    fetchProviders: () => dispatch(afterSalesProvidersActions.fetchProviders()),
});

CompaniesContainer.propTypes = {
    fetchCompanies: PropTypes.func,
    fetchProviders: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(CompaniesContainer);
