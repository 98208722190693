import get from 'lodash/get';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getBrandsModel = createSelector(getModel, (model) => model.brands);

export const getBrandsData = createSelector(getBrandsModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((brand) => ({
              ...brand,
              country: get(brand, 'country.name', ''),
          }))
);

export const getBrandsErrors = createSelector(
    getBrandsModel,
    (model) => model.errors
);

export const getBrandsIsFetching = createSelector(
    getBrandsModel,
    (model) => model.isFetching
);

export const getBrandsPagination = createSelector(
    getBrandsModel,
    (model) => model.data.pagination || {}
);

export const getFamiliesModel = createSelector(
    getModel,
    (model) => model.families
);

export const getFamiliesData = createSelector(getFamiliesModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((family) => ({
              ...family,
          }))
);

export const getFamiliesErrors = createSelector(
    getFamiliesModel,
    (model) => model.errors
);

export const getFamiliesIsFetching = createSelector(
    getFamiliesModel,
    (model) => model.isFetching
);

export const getFamiliesPagination = createSelector(
    getFamiliesModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenBrandForm = createSelector(
    getModel,
    (model) => model.isOpenBrandForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getBrandDetail = createSelector(
    getModel,
    (model) => model.brandDetail
);

export const getIsFetchingBrandDetail = createSelector(
    getBrandDetail,
    (brandDetail) => brandDetail.isFetching
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getFamiliesFilters = createSelector(
    getModel,
    (model) => model.familiesFilters
);

export const getSaveOrigin = createSelector(
    getModel,
    (model) => model.saveOrigin
);

export const getFetchOrigins = createSelector(
    getModel,
    (model) => model.fetchOrigins
);

export const getIsFetchingOrigins = createSelector(
    getFetchOrigins,
    (fetchOrigins) => fetchOrigins.isFetching
);

export const getOriginsOptionsForSelect = createSelector(
    getFetchOrigins,
    getSaveOrigin,
    (fetchOrigins, saveOrigins) => {
        let items = fetchOrigins.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));

        let temporalOrigins = saveOrigins.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));

        let newOrigins = [];
        temporalOrigins.forEach((t) => {
            let exist = items.find((i) => i.optionValue === t.optionValue);
            if (!exist) {
                newOrigins.push(t);
            }
        });

        return items.concat(newOrigins);
    }
);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);

export const getDeleteBrand = createSelector(
    getModel,
    (model) => model.deleteBrand
);

export const getIsDeletingItem = createSelector(
    getDeleteBrand,
    (deleteBrand) => deleteBrand.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteBrand,
    (deleteBrand) => deleteBrand.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteBrand,
    (deleteBrand) => deleteBrand.errors
);
