import React from 'react';

import PropTypes from 'prop-types';

import RateHistoryRow from './RateHistoryRow';

const RateHistoryFiedRedux = ({
    changeInput,
    dynamicColumns = [],
    fields,
    rateDatagrid,
    staticColumns = [],
}) => {
    return (
        <>
            {fields.map((_, index) => {
                return (
                    <RateHistoryRow
                        changeInput={changeInput}
                        dynamicColumns={dynamicColumns}
                        indexRow={index}
                        key={`history-row-${index}`}
                        rateHistoryRow={rateDatagrid[index]}
                        staticColumns={staticColumns}
                        totalRows={fields.length}
                    />
                );
            })}
        </>
    );
};

RateHistoryFiedRedux.propTypes = {
    changeInput: PropTypes.func,
    dynamicColumns: PropTypes.array,
    fields: PropTypes.object,
    rateDatagrid: PropTypes.array,
    staticColumns: PropTypes.array,
};

export default RateHistoryFiedRedux;
