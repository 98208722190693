import React, { useState } from 'react';

import { createFilterOptions } from '@mui/material/Autocomplete';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import {
    Autocomplete,
    Box,
    CircularProgress,
    TextField,
    MenuItem,
} from 'common/ui';

const filter = createFilterOptions();

const DEBOUNCE_TIME = 1000;

const ReduxFieldAutocomplete = ({
    addItemData = {},
    borderRadius,
    disabled,
    fontSize,
    fontWeight,
    fullWidth = true,
    handleAddItem = () => {},
    handleOnSearchChange = () => {},
    input: { value, onChange },
    isCreatable = false,
    loading = false,
    meta: { touched, error },
    options = [],
    placeholder,
    setDialogValue = () => {},
    textAlign,
    toggleOpen = () => {},
    variant = 'filled',
    ...other
}) => {
    const [timer, setTimer] = useState(null);

    const onChangeAction = (event, newValue) => {
        if (typeof newValue === 'string') {
            if (isCreatable) {
                setTimeout(() => {
                    toggleOpen(true);
                    setDialogValue({
                        label: newValue,
                    });
                    handleAddItem({
                        label: newValue,
                        ...addItemData,
                    });
                });
            }
        } else if (newValue && newValue.inputValue) {
            if (isCreatable) {
                toggleOpen(true);
                setDialogValue({
                    label: newValue.inputValue,
                });
                handleAddItem({
                    label: newValue.inputValue,
                    ...addItemData,
                });
            }
        } else {
            onChange(newValue?.optionValue);
        }
    };

    const handleInputChange = (e) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(e), DEBOUNCE_TIME));
    };

    const triggerChange = (e) => {
        handleOnSearchChange(e);
    };

    return (
        <Box
            sx={{
                '& .MuiInputBase-input': {
                    fontSize: fontSize,
                    fontWeight: fontWeight,
                    textAlign: textAlign,
                },
                '& .MuiTextField-root': {
                    '& fieldset': {
                        borderRadius: borderRadius,
                    },
                },
            }}
        >
            <Autocomplete
                disabled={disabled}
                value={value}
                onChange={(event, newValue) => onChangeAction(event, newValue)}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason !== 'reset') handleInputChange(newInputValue);
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (isCreatable) {
                        const isExisting = options.some(
                            (option) => params.inputValue === option.label
                        );

                        if (params.inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue: params.inputValue,
                                label: `${i18next.t('Add')} "${
                                    params.inputValue
                                }"`,
                                isOptionAdd: true,
                            });
                        }
                    }

                    return filtered;
                }}
                options={options}
                getOptionLabel={(option) => {
                    if (option.optionValue) {
                        return option.label;
                    }

                    if (option.inputValue) {
                        return option.inputValue;
                    }

                    const opt = options.find((o) => o.optionValue === option);

                    if (opt) return opt.label;

                    return '';
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                isOptionEqualToValue={(option, value) =>
                    option.optionValue === value
                }
                getOptionDisabled={(option) => option.disabled}
                renderOption={(props, option) => (
                    <MenuItem
                        disabled={loading}
                        key={`autocomplete-option-${option.optionValue}-${option.label}`}
                        {...props}
                    >
                        {option.isOptionAdd ? (
                            <strong>{option.label}</strong>
                        ) : (
                            option.label
                        )}
                    </MenuItem>
                )}
                noOptionsText={i18next.t('No options available')}
                renderInput={(params) => (
                    <TextField
                        error={Boolean(touched && error)}
                        label={placeholder}
                        helperText={touched && error}
                        variant={variant}
                        fullWidth={fullWidth}
                        {...params}
                        {...other}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
};

ReduxFieldAutocomplete.propTypes = {
    addItemData: PropTypes.object,
    borderRadius: PropTypes.string,
    disabled: PropTypes.bool,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    fullWidth: PropTypes.bool,
    handleAddItem: PropTypes.func,
    handleOnSearchChange: PropTypes.func,
    input: PropTypes.object,
    isCreatable: PropTypes.bool,
    loading: PropTypes.bool,
    meta: PropTypes.object,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    setDialogValue: PropTypes.func,
    textAlign: PropTypes.string,
    toggleOpen: PropTypes.func,
    variant: PropTypes.string,
};

export default ReduxFieldAutocomplete;
