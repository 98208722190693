import update from 'immutability-helper';

import {
    DELETE_SUPPLIER,
    DELETE_SUPPLIER_FAILURE,
    DELETE_SUPPLIER_SUCCESS,
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_FAILURE,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_MONTHS,
    FETCH_MONTHS_FAILURE,
    FETCH_MONTHS_SUCCESS,
    FETCH_ORIGINS,
    FETCH_ORIGINS_FAILURE,
    FETCH_ORIGINS_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
    FETCH_RATE_HISTORY,
    FETCH_RATE_HISTORY_FAILURE,
    FETCH_RATE_HISTORY_SUCCESS,
    FETCH_REGIONS,
    FETCH_REGIONS_FAILURE,
    FETCH_REGIONS_SUCCESS,
    FETCH_SUMMER_MONTHS,
    FETCH_SUMMER_MONTHS_FAILURE,
    FETCH_SUMMER_MONTHS_SUCCESS,
    FETCH_SUPPLIERS,
    FETCH_SUPPLIERS_FAILURE,
    FETCH_SUPPLIERS_SUCCESS,
    FETCH_SUPPLIER_DETAIL,
    FETCH_SUPPLIER_DETAIL_FAILURE,
    FETCH_SUPPLIER_DETAIL_SUCCESS,
    FETCH_YEARS,
    FETCH_YEARS_FAILURE,
    FETCH_YEARS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_ITEM_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_ENABLED_EDITION,
    SET_FILTERS,
    SET_IS_NEW_RECORD,
    SET_RATE_HISTORY_FILTERS,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        name: null,
        country: null,
        countryName: null,
        ratesCounter: 0,
    },
    suppliers: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    supplierDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    rateHistory: {
        data: {
            data: [],
            pagination: {},
        },
        error: null,
        isFetching: false,
    },
    fetchOrigins: {
        isFetching: false,
        data: [],
        errors: null,
    },
    historyRateFilters: {
        page: 1,
        rateId: '',
        year: '',
        month: '',
        summer: '',
        region: '',
        division: '',
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    fetchRates: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fetchYears: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fetchMonths: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fetchSummerMonths: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fetchRegions: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fetchDivisions: {
        isFetching: false,
        data: [],
        errors: null,
    },
    isNewRecord: false,
    isEnabledEdition: true,
    isOpenItemForm: false,
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    filters: {
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    deleteSupplier: {
        isDeleting: false,
        data: [],
        error: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_SUPPLIERS:
            return update(state, {
                suppliers: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_SUPPLIERS_FAILURE:
            return update(state, {
                suppliers: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_SUPPLIERS_SUCCESS:
            return update(state, {
                suppliers: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_SUPPLIER_DETAIL:
            return update(state, {
                supplierDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_SUPPLIER_DETAIL_FAILURE:
            return update(state, {
                supplierDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_SUPPLIER_DETAIL_SUCCESS:
            return update(state, {
                supplierDetail: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_ORIGINS:
            return update(state, {
                fetchOrigins: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_ORIGINS_SUCCESS:
            return update(state, {
                fetchOrigins: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_ORIGINS_FAILURE:
            return update(state, {
                fetchOrigins: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_RATE_HISTORY:
            return update(state, {
                rateHistory: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_RATE_HISTORY_FAILURE:
            return update(state, {
                rateHistory: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_RATE_HISTORY_SUCCESS:
            return update(state, {
                rateHistory: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_RATES:
            return update(state, {
                fetchRates: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_RATES_SUCCESS:
            return update(state, {
                fetchRates: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_RATES_FAILURE:
            return update(state, {
                fetchRates: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_YEARS:
            return update(state, {
                fetchYears: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_YEARS_SUCCESS:
            return update(state, {
                fetchYears: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_YEARS_FAILURE:
            return update(state, {
                fetchYears: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_MONTHS:
            return update(state, {
                fetchMonths: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_MONTHS_SUCCESS:
            return update(state, {
                fetchMonths: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_MONTHS_FAILURE:
            return update(state, {
                fetchMonths: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_SUMMER_MONTHS:
            return update(state, {
                fetchSummerMonths: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_SUMMER_MONTHS_SUCCESS:
            return update(state, {
                fetchSummerMonths: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_SUMMER_MONTHS_FAILURE:
            return update(state, {
                fetchSummerMonths: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_REGIONS:
            return update(state, {
                fetchRegions: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_REGIONS_SUCCESS:
            return update(state, {
                fetchRegions: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_REGIONS_FAILURE:
            return update(state, {
                fetchRegions: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_DIVISIONS:
            return update(state, {
                fetchDivisions: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_DIVISIONS_SUCCESS:
            return update(state, {
                fetchDivisions: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_DIVISIONS_FAILURE:
            return update(state, {
                fetchDivisions: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                supplierDetail: {
                    $set: INITIAL_STATE.supplierDetail,
                },
                rateHistory: {
                    $set: INITIAL_STATE.rateHistory,
                },
                historyRateFilters: {
                    $set: INITIAL_STATE.historyRateFilters,
                },
                fetchRates: {
                    $set: INITIAL_STATE.fetchRates,
                },
                fetchYears: {
                    $set: INITIAL_STATE.fetchYears,
                },
                fetchMonths: {
                    $set: INITIAL_STATE.fetchMonths,
                },
                fetchSummerMonths: {
                    $set: INITIAL_STATE.fetchSummerMonths,
                },
                fetchRegions: {
                    $set: INITIAL_STATE.fetchRegions,
                },
                fetchDivisions: {
                    $set: INITIAL_STATE.fetchDivisions,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
            });

        case OPEN_ITEM_FORM:
            return update(state, {
                isOpenItemForm: {
                    $set: action.payload,
                },
            });

        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case SET_ENABLED_EDITION:
            return update(state, {
                isEnabledEdition: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case SET_RATE_HISTORY_FILTERS:
            return update(state, {
                historyRateFilters: {
                    $merge: action.payload,
                },
            });

        case DELETE_SUPPLIER:
            return update(state, {
                deleteSupplier: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_SUPPLIER_SUCCESS:
            return update(state, {
                deleteSupplier: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_SUPPLIER_FAILURE:
            return update(state, {
                deleteSupplier: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });
        default:
            return state;
    }
}
