import update from 'immutability-helper';

import {
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    OPEN_MODAL,
    FETCH_RATE_HISTORY_DATAGRID,
    FETCH_RATE_HISTORY_DATAGRID_FAILURE,
    FETCH_RATE_HISTORY_DATAGRID_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        rate: null,
        year: null,
        month: null,
        rateDatagrid: [],
    },
    rateHistoryDatagrid: {
        isFetching: false,
        data: null,
        errors: [],
    },
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    isOpenModal: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_RATE_HISTORY_DATAGRID:
            return update(state, {
                rateHistoryDatagrid: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_RATE_HISTORY_DATAGRID_FAILURE:
            return update(state, {
                rateHistoryDatagrid: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_RATE_HISTORY_DATAGRID_SUCCESS:
            return update(state, {
                rateHistoryDatagrid: {
                    $merge: { errors: [], isFetching: false },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case OPEN_MODAL:
            return update(state, {
                isOpenModal: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
