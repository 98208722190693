import React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';

import { Box } from 'common/ui';
import { formatDate, isValidDate, parseDate } from 'common/utils/dates';

const ReduxFieldDatePicker = ({
    disableFuture,
    disablePast,
    disabled,
    fullWidth = true,
    input: { value, onChange },
    inputFormat = 'MM/dd/yyyy',
    label,
    maxDate,
    meta: { touched, error },
    minDate,
    placeholder,
    readOnly,
    variant,
}) => {
    const onChangeAction = (date) => {
        if (isValidDate(date)) {
            onChange(formatDate(date, inputFormat));
        } else {
            onChange('');
        }
    };

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    disabled={disabled}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    inputFormat={inputFormat}
                    label={label}
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={onChangeAction}
                    readOnly={readOnly}
                    slotProps={{
                        textField: {
                            error: Boolean(touched && error),
                            fullWidth,
                            helperText: touched && error,
                            placeholder,
                            variant,
                        },
                    }}
                    value={value ? parseDate(value, inputFormat) : null}
                />
            </LocalizationProvider>
        </Box>
    );
};

ReduxFieldDatePicker.propTypes = {
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    input: PropTypes.object,
    inputFormat: PropTypes.string,
    label: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    meta: PropTypes.object,
    minDate: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    variant: PropTypes.string,
};

export default ReduxFieldDatePicker;
