import { getModel } from 'common/api/ocr/fineTuning';

import {
    FETCH_MODEL,
    FETCH_MODEL_FAILURE,
    FETCH_MODEL_SUCCESS,
} from '../actionTypes';
import { getFilterQuery } from '../helpers';

export default (id, query) => (dispatch) => {
    dispatch({ type: FETCH_MODEL });

    getModel(id, getFilterQuery(query))
        .then((response) => {
            dispatch({
                payload: response?.data,
                type: FETCH_MODEL_SUCCESS,
            });
        })
        .catch((error) =>
            dispatch({
                payload: error?.response?.errors,
                type: FETCH_MODEL_FAILURE,
            })
        );
};
