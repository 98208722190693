import { NAME } from './constants';

export const FETCH_PRODUCT_THUMBNAIL = `${NAME}/FETCH_PRODUCT_THUMBNAIL`;
export const FETCH_PRODUCT_THUMBNAIL_FAILURE = `${NAME}/FETCH_PRODUCT_THUMBNAIL_FAILURE`;
export const FETCH_PRODUCT_THUMBNAIL_SUCCESS = `${NAME}/FETCH_PRODUCT_THUMBNAIL_SUCCESS`;

export const UPLOAD_THUMBNAIL = `${NAME}/UPLOAD_THUMBNAIL`;
export const UPLOAD_THUMBNAIL_FAILURE = `${NAME}/UPLOAD_THUMBNAIL_FAILURE`;
export const UPLOAD_THUMBNAIL_SUCCESS = `${NAME}/UPLOAD_THUMBNAIL_SUCCESS`;

export const DELETE_THUMBNAIL = `${NAME}/DELETE_THUMBNAIL`;
export const DELETE_THUMBNAIL_FAILURE = `${NAME}/DELETE_THUMBNAIL_FAILURE`;
export const DELETE_THUMBNAIL_SUCCESS = `${NAME}/DELETE_THUMBNAIL_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_UPLOAD_MODEL = `${NAME}/RESET_UPLOAD_MODEL`;
