import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    Divider,
    IconButton,
    LinearProgress,
} from 'common/ui';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CardContainer = ({
    action,
    avatar,
    children,
    collapsePaddingButton = '24px',
    isCollapsable,
    isOpen = true,
    loading,
    maxContentHeight = 'auto',
    subheader,
    sxCard = {},
    title,
    unmountOnExit = true,
    urlMedia,
    withDivider = false,
}) => {
    const [expanded, setExpanded] = useState(isOpen);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const actionHeader = isCollapsable ? (
        <ExpandMore expand={expanded} onClick={handleExpandClick}>
            <ExpandMoreIcon />
        </ExpandMore>
    ) : (
        action
    );

    return (
        <Card sx={sxCard}>
            <CardHeader
                avatar={avatar}
                action={actionHeader}
                title={title}
                subheader={subheader}
                sx={{
                    cursor: isCollapsable ? 'pointer' : 'normal',
                    paddingBottom: subheader ? '6px' : '24px',
                }}
                onClick={isCollapsable ? handleExpandClick : () => {}}
            />
            {urlMedia && (
                <CardMedia
                    component="img"
                    height="194"
                    image={urlMedia}
                    alt="Card media source"
                />
            )}
            <Collapse
                in={expanded}
                timeout="auto"
                sx={{
                    paddingBottom: collapsePaddingButton,
                }}
                unmountOnExit={unmountOnExit}
            >
                {withDivider && !loading && <Divider variant="middle" />}
                {loading && <LinearProgress />}
                <CardContent
                    sx={{
                        maxHeight: maxContentHeight,
                        overflow: 'auto',
                        padding: '16px',
                    }}
                    dir="ltr"
                >
                    {children}
                </CardContent>
            </Collapse>
        </Card>
    );
};

CardContainer.propTypes = {
    action: PropTypes.node,
    avatar: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
    collapsePaddingButton: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    isCollapsable: PropTypes.bool,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    maxContentHeight: PropTypes.string,
    subheader: PropTypes.string,
    sxCard: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    unmountOnExit: PropTypes.bool,
    urlMedia: PropTypes.string,
    withDivider: PropTypes.bool,
};

export default CardContainer;
