import { get, isNull } from 'lodash';

import { getProductDatasheet } from 'common/api/datasheets';

import {
    FETCH_PRODUCT_DATASHEET,
    FETCH_PRODUCT_DATASHEET_FAILURE,
    FETCH_PRODUCT_DATASHEET_SUCCESS,
} from '../actionTypes';

import showForm from './showForm';

export default (id) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: FETCH_PRODUCT_DATASHEET });

        getProductDatasheet(id)
            .then((response) => {
                dispatch({
                    type: FETCH_PRODUCT_DATASHEET_SUCCESS,
                    payload: response.data,
                });
                if (isNull(get(response.data, 'data_sheet', null)))
                    dispatch(showForm(true));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_PRODUCT_DATASHEET_FAILURE,
                    payload: error,
                });
                reject();
            });
    });
