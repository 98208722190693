import React from 'react';

import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const ButtonComponent = ({
    borderRadius,
    children,
    className,
    color,
    dataIntercomTarget,
    disabled,
    endIcon,
    fullWidth = true,
    height,
    href,
    onClick,
    variant = 'contained',
    size,
    startIcon,
    sx,
    target,
    to,
    visible = true,
    type,
}) => {
    return visible ? (
        <Button
            className={className}
            color={color}
            data-intercom-target={dataIntercomTarget}
            disabled={disabled}
            endIcon={endIcon}
            fullWidth={fullWidth}
            href={href}
            onClick={onClick}
            variant={variant}
            size={size}
            startIcon={startIcon}
            sx={{
                borderRadius: borderRadius,
                height: height,
                ...sx,
            }}
            target={target}
            to={to}
            type={type}
        >
            {children}
        </Button>
    ) : null;
};

ButtonComponent.propTypes = {
    borderRadius: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    dataIntercomTarget: PropTypes.string,
    disabled: PropTypes.bool,
    endIcon: PropTypes.node,
    fullWidth: PropTypes.bool,
    height: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    size: PropTypes.string,
    startIcon: PropTypes.node,
    sx: PropTypes.object,
    target: PropTypes.string,
    to: PropTypes.string,
    visible: PropTypes.bool,
    type: PropTypes.string,
};

export default ButtonComponent;
