import { setProjectStatus } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SET_PROJECT_STATUS,
    SET_PROJECT_STATUS_FAILURE,
    SET_PROJECT_STATUS_SUCCESS,
} from '../actionTypes';

export default (values, callback) => (dispatch) => {
    if (!values) return;

    dispatch({ type: SET_PROJECT_STATUS });

    const { change_project_status, company, id, status, sunwise_project_id } =
        values || {};

    setProjectStatus({
        companyId: company,
        projectId: change_project_status ? null : id,
        status,
        sunwiseProjectId: sunwise_project_id,
    })
        .then(() => {
            dispatch({ type: SET_PROJECT_STATUS_SUCCESS });

            showToast();
            if (callback) callback();
        })
        .catch((error) => {
            dispatch({ payload: error, type: SET_PROJECT_STATUS_FAILURE });

            showFastApiErrorsAsAlert(dispatch, error?.response);
        });
};
