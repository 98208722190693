import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { Switch, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { HiddenComponent, MenuPopover } from 'common/components';
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    MenuItem,
    Stack,
    Typography,
} from 'common/ui';
import { stringAvatar } from 'common/utils/helpers';
import { logoutActions } from 'common/utils/helpers/session';

import * as profileActions from '../../modules/profile/actions';

const StyledMenuItemIcon = styled(Box)({
    height: '24px',
    margin: '6px 12px 6px 0',
    width: '24px',
});

const MenuProfile = ({ handleRedirect, profileValues, setTheme }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleOnSwitchTheme = (e) => {
        const theme = e.target.checked ? 'dark' : 'light';
        setTheme(theme);
    };

    if (isNil(profileValues)) return null;

    return (
        <>
            <Box
                component="div"
                onClick={handleClick}
                sx={{
                    cursor: 'pointer',
                    maxWidth: 320,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                >
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            padding: 0,
                            fontWeight: 'bold',
                            width: 44,
                            height: 44,
                            ...(open && {
                                '&:before': {
                                    zIndex: 1,
                                    content: "''",
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                },
                            }),
                        }}
                    >
                        {profileValues?.picture ? (
                            <Avatar src={profileValues.picture} />
                        ) : (
                            <Avatar
                                {...stringAvatar(
                                    `${profileValues.first_name} ${profileValues.last_name}`
                                )}
                            />
                        )}
                    </IconButton>
                    <HiddenComponent width="smDown">
                        <Typography variant="body" noWrap>
                            {profileValues.first_name} {profileValues.last_name}
                        </Typography>
                        <ArrowDropDownIcon />
                    </HiddenComponent>
                </Stack>
            </Box>
            <MenuPopover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                sx={{
                    width: 220,
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {`${profileValues.first_name} ${profileValues.last_name}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary' }}
                        noWrap
                    >
                        {profileValues.email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />
                <MenuItem onClick={() => handleRedirect('/profile')}>
                    <StyledMenuItemIcon>
                        <PersonIcon />
                    </StyledMenuItemIcon>

                    {t('Profile')}
                </MenuItem>

                <MenuItem>
                    <DarkModeIcon />
                    {t('Dark mode')}{' '}
                    <Switch
                        checked={theme.palette.mode === 'dark'}
                        onChange={handleOnSwitchTheme}
                    />
                </MenuItem>

                <MenuItem onClick={() => logoutActions()}>
                    <StyledMenuItemIcon>
                        <LogoutIcon />
                    </StyledMenuItemIcon>

                    {t('Logout')}
                </MenuItem>
            </MenuPopover>
        </>
    );
};

MenuProfile.propTypes = {
    handleRedirect: PropTypes.func,
    profileValues: PropTypes.object,
    setTheme: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    setTheme: (value) => dispatch(profileActions.setTheme(value)),
});

export default compose(connect(null, mapDispatchToProps))(MenuProfile);
