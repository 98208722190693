import update from 'immutability-helper';

import {
    FETCH_CONTRACTED_DEMAND_UNITS,
    FETCH_CONTRACTED_DEMAND_UNITS_FAILURE,
    FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS,
    FETCH_CURRENCIES_BY_COUNTRIES,
    FETCH_CURRENCIES_BY_COUNTRIES_FAILURE,
    FETCH_CURRENCIES_BY_COUNTRIES_SUCCESS,
    FETCH_CUSTOM_RATE_DETAIL,
    FETCH_CUSTOM_RATE_DETAIL_FAILURE,
    FETCH_CUSTOM_RATE_DETAIL_SUCCESS,
    FETCH_CUSTOM_RATES,
    FETCH_CUSTOM_RATES_FAILURE,
    FETCH_CUSTOM_RATES_SUCCESS,
    FETCH_RATE_DETAIL,
    FETCH_RATE_DETAIL_FAILURE,
    FETCH_RATE_DETAIL_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
    FETCH_SPAIN_EXCESS_DEMAND_UNITS,
    FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE,
    FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS,
    FETCH_SUPPLIER_UTILITIES,
    FETCH_SUPPLIER_UTILITIES_FAILURE,
    FETCH_SUPPLIER_UTILITIES_SUCCESS,
    FETCH_UNITS,
    FETCH_UNITS_FAILURE,
    FETCH_UNITS_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_RATE_MODAL_FORM,
    OPEN_ITEM_FORM,
    RESET,
    RESET_FORM,
    RESET_RATE_MODAL_FORM,
    SAVE,
    SAVE_CUSTOM_RATE,
    SAVE_CUSTOM_RATE_FAILURE,
    SAVE_CUSTOM_RATE_SUCCESS,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_ENABLED_EDITION,
    SET_FILTERS,
    SET_IS_NEW_RECORD,
    SET_MODAL_IS_CREATE,
    SET_MODAL_IS_OPEN,
} from './actionTypes';
import { INIT_VALUES_DEFAULT } from './constants';

const INITIAL_STATE = {
    customRateDetail: { data: null, errors: [], isFetching: false },
    customRates: {
        data: { data: [], paginaton: {} },
        error: null,
        isFetching: false,
    },
    fetchContractedDemandUnits: { data: [], errors: null, isFetching: false },
    fetchCurrenciesByCountries: { data: [], errors: null, isFetching: false },
    fetchSpainExcessDemandUnits: { data: [], errors: null, isFetching: false },
    fetchSupplierUtilities: { data: [], errors: null, isFetching: false },
    fetchUnits: { data: [], errors: null, isFetching: false },
    filters: { orderBy: '', page: 1, searchStr: '', sortBy: '' },
    initialValues: {
        annual_percentage_increase: null,
        description: null,
        id: null,
        name: null,
        other_concepts: [],
        taxes_concepts: [],
    },
    isEnabledEdition: true,
    isNewRecord: false,
    isOpenItemForm: false,
    modalIsCreate: true,
    modalIsOpen: false,
    rateDetail: { data: null, errors: [], isFetching: false },
    rateModalInitialValues: INIT_VALUES_DEFAULT,
    rates: {
        data: { data: [], paginaton: {} },
        error: null,
        isFetching: false,
    },
    save: { data: {}, errors: null, isSaving: false },
    saveCustomRate: { data: {}, errors: null, isSaving: false },
    saveRate: { errors: null, isSaving: false },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_CONTRACTED_DEMAND_UNITS:
            return update(state, {
                fetchContractedDemandUnits: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_CONTRACTED_DEMAND_UNITS_FAILURE:
            return update(state, {
                fetchContractedDemandUnits: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS:
            return update(state, {
                fetchContractedDemandUnits: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_CUSTOM_RATES:
            return update(state, {
                customRates: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_CUSTOM_RATES_FAILURE:
            return update(state, {
                customRates: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_CUSTOM_RATES_SUCCESS:
            return update(state, {
                customRates: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_CUSTOM_RATE_DETAIL:
            return update(state, {
                customRateDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_CUSTOM_RATE_DETAIL_FAILURE:
            return update(state, {
                customRateDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_CUSTOM_RATE_DETAIL_SUCCESS:
            return update(state, {
                customRateDetail: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_CURRENCIES_BY_COUNTRIES:
            return update(state, {
                fetchCurrenciesByCountries: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_CURRENCIES_BY_COUNTRIES_FAILURE:
            return update(state, {
                fetchCurrenciesByCountries: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_CURRENCIES_BY_COUNTRIES_SUCCESS:
            return update(state, {
                fetchCurrenciesByCountries: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_RATES:
            return update(state, {
                rates: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_RATES_FAILURE:
            return update(state, {
                rates: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_RATES_SUCCESS:
            return update(state, {
                rates: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_SPAIN_EXCESS_DEMAND_UNITS:
            return update(state, {
                fetchSpainExcessDemandUnits: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE:
            return update(state, {
                fetchSpainExcessDemandUnits: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS:
            return update(state, {
                fetchSpainExcessDemandUnits: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_SUPPLIER_UTILITIES:
            return update(state, {
                fetchSupplierUtilities: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_SUPPLIER_UTILITIES_FAILURE:
            return update(state, {
                fetchSupplierUtilities: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_SUPPLIER_UTILITIES_SUCCESS:
            return update(state, {
                fetchSupplierUtilities: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_RATE_DETAIL:
            return update(state, {
                rateDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_RATE_DETAIL_FAILURE:
            return update(state, {
                rateDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_RATE_DETAIL_SUCCESS:
            return update(state, {
                rateDetail: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_UNITS:
            return update(state, {
                fetchUnits: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_UNITS_FAILURE:
            return update(state, {
                fetchUnits: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_UNITS_SUCCESS:
            return update(state, {
                fetchUnits: {
                    $merge: {
                        data: action.payload,
                        errors: [],
                        isFetching: false,
                    },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
            });

        case OPEN_ITEM_FORM:
            return update(state, {
                isOpenItemForm: {
                    $set: action.payload,
                },
            });

        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case SET_ENABLED_EDITION:
            return update(state, {
                isEnabledEdition: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case SAVE_CUSTOM_RATE:
            return update(state, {
                saveCustomRate: {
                    $merge: { errors: [], isSaving: true },
                },
            });

        case SAVE_CUSTOM_RATE_SUCCESS:
            return update(state, {
                saveCustomRate: {
                    $merge: { data: action.payload, isSaving: false },
                },
            });

        case SAVE_CUSTOM_RATE_FAILURE:
            return update(state, {
                saveCustomRate: {
                    $merge: { errors: action.payload, isSaving: false },
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case RESET_RATE_MODAL_FORM:
            return update(state, {});

        case SET_MODAL_IS_CREATE:
            return update(state, {
                modalIsCreate: {
                    $set: action.payload,
                },
            });

        case SET_MODAL_IS_OPEN:
            return update(state, {
                modalIsOpen: {
                    $set: action.payload,
                },
            });

        case INITIALIZE_RATE_MODAL_FORM:
            return update(state, {
                rateModalInitialValues: {
                    $merge: action.payload,
                },
            });

        default:
            return state;
    }
}
