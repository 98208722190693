import React from 'react';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { styled } from '@mui/material/styles';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import { ReduxFieldFile } from 'common/components/form/redux';
import {
    CardContent,
    Box,
    Typography,
    Stack,
    Tooltip,
    IconButton,
    CardMedia,
} from 'common/ui';

import { ALLOWED_EXTENSIONS } from '../constants';

const StyledThumbnail = styled(CardMedia)(({ theme }) => ({
    position: 'relative',
    height: 218,
    width: 140,
    backgroundSize: 'contain',
    padding: '0.25rem',
    backgroundColor: '#EEF4FA',
    borderRadius: '0.55rem',
    [theme.breakpoints.down('xl')]: {
        minHeight: 208,
        minWidth: 130,
    },
    [theme.breakpoints.down('lg')]: {
        minHeight: 109,
        minWidth: 70,
    },
    [theme.breakpoints.down('sm')]: {
        minHeight: 218,
        minWidth: 140,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiThumbnailBackdrop-root': {
            opacity: 1,
        },
        '& .MuiBackdrop-root': {
            opacity: 0.15,
        },
    },
}));

const Backdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    zIndex: 2,
}));

const ThumbnailBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    justifyContent: 'center',
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '0.55rem',
    bottom: 0,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    zIndex: 3,
}));

const StyledIconButton = styled(IconButton)(({ theme, color }) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,

    '&:hover': {
        backgroundColor: theme.palette[color].light,
    },
}));

const Form = ({
    formValues,
    isEnabledEdition,
    onHandleClickDelete,
    placeholder,
    thumbnail,
}) => {
    const { t } = useTranslation();

    return (
        <CardContent>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    component="label"
                    htmlFor={isEnabledEdition ? 'thumbnail_image_01' : ''}
                    sx={{
                        cursor: isEnabledEdition ? 'pointer' : 'normal',
                        textAlign: 'center',
                    }}
                >
                    <StyledThumbnail
                        image={
                            !isEmpty(thumbnail) &&
                            !isNull(thumbnail.image_thumbnail)
                                ? thumbnail.image_thumbnail
                                : Array.isArray(formValues?.image_thumbnail)
                                ? formValues?.image_thumbnail[0].preview
                                : placeholder
                        }
                        alt="Product thumbnail or placeholder"
                    >
                        {isEnabledEdition && (
                            <>
                                <ThumbnailBackdrop className="MuiThumbnailBackdrop-root">
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        {!isEmpty(thumbnail) &&
                                            !isNull(
                                                thumbnail.image_thumbnail
                                            ) && (
                                                <Tooltip title={t('Reset')}>
                                                    <StyledIconButton
                                                        onClick={
                                                            onHandleClickDelete
                                                        }
                                                        color="primary"
                                                    >
                                                        <RotateLeftIcon />
                                                    </StyledIconButton>
                                                </Tooltip>
                                            )}

                                        <Tooltip title={t('Upload')}>
                                            <StyledIconButton
                                                color="primary"
                                                onClick={() => {
                                                    document
                                                        .getElementById(
                                                            'thumbnail_image_01'
                                                        )
                                                        .click();
                                                }}
                                            >
                                                <AddPhotoAlternateIcon />
                                            </StyledIconButton>
                                        </Tooltip>
                                    </Stack>
                                </ThumbnailBackdrop>
                                <Backdrop
                                    className="MuiBackdrop-root"
                                    htmlFor="thumbnail_image_01"
                                />
                            </>
                        )}
                    </StyledThumbnail>
                </Box>

                <Typography
                    align="center"
                    variant="caption"
                    color="text.secondary"
                    component="div"
                >
                    {t('JPG format')} - 2MB máx
                </Typography>
                <Box sx={{ display: 'none' }}>
                    <Field
                        accept={ALLOWED_EXTENSIONS}
                        id="thumbnail_image_01"
                        component={ReduxFieldFile}
                        placeholder=""
                        name="image_thumbnail"
                        variant="profile"
                        maxSize={2000000}
                    />
                </Box>
            </Stack>
        </CardContent>
    );
};

Form.propTypes = {
    formValues: PropTypes.object,
    isEnabledEdition: PropTypes.bool,
    onHandleClickDelete: PropTypes.func,
    placeholder: PropTypes.string,
    thumbnail: PropTypes.object,
};

export default Form;
