import update from 'immutability-helper';

import {
    DELETE_BRAND,
    DELETE_BRAND_FAILURE,
    DELETE_BRAND_SUCCESS,
    FETCH_BRANDS,
    FETCH_BRANDS_FAILURE,
    FETCH_BRANDS_SUCCESS,
    FETCH_BRAND_DETAIL,
    FETCH_BRAND_DETAIL_FAILURE,
    FETCH_BRAND_DETAIL_SUCCESS,
    FETCH_FAMILIES,
    FETCH_FAMILIES_FAILURE,
    FETCH_FAMILIES_SUCCESS,
    FETCH_ORIGINS,
    FETCH_ORIGINS_FAILURE,
    FETCH_ORIGINS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_BRAND_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_ORIGIN,
    SAVE_ORIGIN_FAILURE,
    SAVE_ORIGIN_SUCCESS,
    SAVE_SUCCESS,
    SET_ENABLED_EDITION,
    SET_FAMILIES_FILTERS,
    SET_FILTERS,
    SET_IS_NEW_RECORD,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        name: null,
        country: null,
        families: [],
    },
    brands: {
        data: {
            data: [],
            pagination: {},
        },
        error: null,
        isFetching: false,
    },
    brandDetail: {
        isFetching: false,
        data: null,
        errors: [],
    },
    families: {
        data: {
            data: [],
            pagination: {},
        },
        error: null,
        isFetching: false,
    },
    fetchOrigins: {
        isFetching: false,
        data: [],
        error: null,
    },
    isNewRecord: false,
    isEnabledEdition: true,
    isOpenBrandForm: false,
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    saveOrigin: {
        isSaving: false,
        data: [],
        errors: null,
    },
    filters: {
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    familiesFilters: {
        id: '',
        page: 1,
        orderBy: '',
        sortBy: '',
        searchStr: '',
    },
    deleteBrand: {
        isDeleting: false,
        data: [],
        error: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_BRANDS:
            return update(state, {
                brands: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_BRANDS_FAILURE:
            return update(state, {
                brands: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_BRANDS_SUCCESS:
            return update(state, {
                brands: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_BRAND_DETAIL:
            return update(state, {
                brandDetail: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_BRAND_DETAIL_FAILURE:
            return update(state, {
                brandDetail: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_BRAND_DETAIL_SUCCESS:
            return update(state, {
                brandDetail: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_FAMILIES:
            return update(state, {
                families: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_FAMILIES_FAILURE:
            return update(state, {
                families: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_FAMILIES_SUCCESS:
            return update(state, {
                families: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_ORIGINS:
            return update(state, {
                fetchOrigins: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_ORIGINS_SUCCESS:
            return update(state, {
                fetchOrigins: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_ORIGINS_FAILURE:
            return update(state, {
                fetchOrigins: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
                saveOrigin: {
                    $set: INITIAL_STATE.saveOrigin,
                },
                families: {
                    $set: INITIAL_STATE.families,
                },
            });

        case OPEN_BRAND_FORM:
            return update(state, {
                isOpenBrandForm: {
                    $set: action.payload,
                },
            });

        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case SET_ENABLED_EDITION:
            return update(state, {
                isEnabledEdition: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case SET_FILTERS:
            return update(state, {
                filters: {
                    $merge: action.payload,
                },
            });

        case SET_FAMILIES_FILTERS:
            return update(state, {
                familiesFilters: {
                    $merge: action.payload,
                },
            });

        case SAVE_ORIGIN:
            return update(state, {
                saveOrigin: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_ORIGIN_SUCCESS:
            return update(state, {
                saveOrigin: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_ORIGIN_FAILURE:
            return update(state, {
                saveOrigin: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case DELETE_BRAND:
            return update(state, {
                deleteBrand: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_BRAND_SUCCESS:
            return update(state, {
                deleteBrand: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_BRAND_FAILURE:
            return update(state, {
                deleteBrand: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });
        default:
            return state;
    }
}
