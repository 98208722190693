import { VALID_PASSWORD, INVALID_PASSWORD } from '../actionTypes';

export default (event) => (dispatch) => {
    const password = event.target.value;

    if (password.length >= 8) {
        dispatch({ type: VALID_PASSWORD });
    } else {
        dispatch({ type: INVALID_PASSWORD });
    }
};
