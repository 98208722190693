import i18next from 'i18next';

import { bulkUploadTrainingDocuments } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import {
    SAVE_TRAINING_DATA,
    SAVE_TRAINING_DATA_FAILURE,
    SAVE_TRAINING_DATA_SUCCESS,
} from '../actionTypes';

import fetchModel from './fetchModel';
import resetForm from './resetForm';
import setIsOpenModal from './setIsOpenModal';

export default (values, modelId, query) => (dispatch) => {
    dispatch({ type: SAVE_TRAINING_DATA });

    let newValues = {
        prompt: values.prompt,
        files: values?.files || [],
        complementary_prompt: values.complementary_prompt,
    };

    const tags = values.tags.length
        ? JSON.stringify(values.tags.map((tag) => tag.value))
        : null;

    if (tags) newValues.tags = tags;

    bulkUploadTrainingDocuments(modelId, newValues)
        .then(() => {
            dispatch(setIsOpenModal(false));
            dispatch(resetForm());
            dispatch({ type: SAVE_TRAINING_DATA_SUCCESS });
            dispatch(fetchModel(modelId, query));
        })
        .catch((err) => {
            dispatch({ type: SAVE_TRAINING_DATA_FAILURE, payload: err });
            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while creating the element'),
            });
        });
};
