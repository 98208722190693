import i18next from 'i18next';

import {
    INITIALIZE_DUPLICATE_MODEL_FORM,
    SET_IS_OPEN_DUPLICATE_MODEL_FORM,
} from '../actionTypes';

export default (model = {}) =>
    (dispatch) => {
        dispatch({ type: SET_IS_OPEN_DUPLICATE_MODEL_FORM, payload: true });

        dispatch({
            type: INITIALIZE_DUPLICATE_MODEL_FORM,
            payload: {
                id: model.id,
                name: i18next.t('Copy of').concat(' ', model.name),
            },
        });
    };
