import update from 'immutability-helper';

import {
    CLEAR_CLIPBOARD_DATA,
    SET_CLIPBOARD_DATA,
    UPLOAD_DATASHEET,
    UPLOAD_DATASHEET_FAILURE,
    UPLOAD_DATASHEET_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    clipboard: { data: {} },
    uploadDatasheet: { isLoading: false, errors: null },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CLEAR_CLIPBOARD_DATA: {
            const _data = {
                ...state.clipboard.data,
                [action.payload.type]: null,
            };
            return update(state, { clipboard: { data: { $set: _data } } });
        }

        case SET_CLIPBOARD_DATA: {
            const _data = {
                ...state.clipboard.data,
                [action.payload.type]: action.payload.data,
            };
            return update(state, { clipboard: { data: { $set: _data } } });
        }

        case UPLOAD_DATASHEET:
            return update(state, {
                uploadDatasheet: { $merge: { isLoading: true, errors: null } },
            });

        case UPLOAD_DATASHEET_SUCCESS:
            return update(state, {
                uploadDatasheet: { $merge: { isLoading: false } },
            });

        case UPLOAD_DATASHEET_FAILURE:
            return update(state, {
                uploadDatasheet: {
                    $merge: { errors: action.payload, isLoading: false },
                },
            });

        default:
            return state;
    }
}
