import React from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import PlaceholderCustomTable from 'common/components/placeholders/PlaceholderCustomTable';
import {
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    Typography,
} from 'common/ui';

import FlexTableCell from './FlexTableCell';
import FlexTablePagination from './FlexTablePagination';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor:
            theme.palette.mode === 'light'
                ? '#f7fcff'
                : theme.palette.secondary.main,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CustomTable = ({
    afterComponent,
    before,
    columns = [],
    data,
    emptyText = 'No records found',
    handleClickRow,
    handleOnPageChange,
    isFetching,
    pagination,
    sortedBy,
    orderdBy,
}) => {
    const getSortDefault = (header) =>
        header.sortDefault ? header.sortDefault : '';

    const headerCells = !isFetching
        ? columns.map((header) => (
              <FlexTableCell
                  align={header.headerAlign}
                  dataField={header.dataField}
                  key={header.dataField}
                  handleSortable={header.handleSortable}
                  isHead
                  sortable={header.sortable}
                  width={header.width}
                  sortDefault={
                      orderdBy === header.dataField
                          ? sortedBy
                          : getSortDefault(header)
                  }
              >
                  <Typography
                      variant="subtitle2"
                      sx={{
                          color: (theme) =>
                              theme.palette['secondary'].contrastText,
                      }}
                  >
                      {header.label}
                  </Typography>
              </FlexTableCell>
          ))
        : columns.map((header, index) => (
              <FlexTableCell
                  align={header.headerAlign}
                  dataField={`${header.label}-${index}`}
                  isHead
                  key={`${header.label}-${index}`}
                  width={header.width}
              >
                  <Typography
                      variant="subtitle2"
                      sx={{
                          color: (theme) =>
                              theme.palette['secondary'].contrastText,
                      }}
                  >
                      {header.label}
                  </Typography>
              </FlexTableCell>
          ));

    const dataContent = data.map((data) => {
        const cells = columns.map((column) => {
            let content = data[column.dataField];

            if (column.formatter) {
                content = column.formatExtraData
                    ? column.formatter(content, data, column.formatExtraData)
                    : column.formatter(content, data);
            }

            if (column.placeholder) {
                content = column.placeholder(content, data);
            }

            return (
                <FlexTableCell
                    align={column.align}
                    key={Math.random()}
                    width={column.width}
                >
                    <Box
                        component="span"
                        sx={{
                            color: (theme) => theme.palette.grey[600],
                        }}
                    >
                        {content}
                    </Box>
                </FlexTableCell>
            );
        });

        return (
            <StyledTableRow
                hover
                key={data.id}
                onClick={() => {
                    if (handleClickRow) handleClickRow(data);
                }}
                sx={{
                    cursor:
                        typeof handleClickRow === 'function'
                            ? 'pointer'
                            : 'normal',
                }}
            >
                {cells}
            </StyledTableRow>
        );
    });

    return (
        <>
            {afterComponent && afterComponent()}
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: (theme) => theme.customShadows.z0,
                }}
            >
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow
                            sx={{
                                color: (theme) =>
                                    theme.palette['secondary'].contrastText,
                                backgroundColor: (theme) =>
                                    theme.palette['secondary'].lighter,
                            }}
                        >
                            {headerCells}
                        </TableRow>
                    </TableHead>

                    <>
                        {isFetching ? (
                            <TableBody>
                                <PlaceholderCustomTable
                                    cols={columns.length}
                                    ready={isFetching}
                                />
                            </TableBody>
                        ) : isEmpty(data) ? (
                            <caption>
                                <Typography
                                    variant="body2"
                                    align="center"
                                    mt={2}
                                    mb={2}
                                >
                                    {emptyText}
                                </Typography>
                            </caption>
                        ) : (
                            <TableBody>
                                {dataContent}
                                {pagination && !isEmpty(pagination) && (
                                    <FlexTablePagination
                                        pagination={pagination}
                                        handleOnPageChange={handleOnPageChange}
                                    />
                                )}
                            </TableBody>
                        )}
                    </>
                </Table>
            </TableContainer>
            {before}
        </>
    );
};

CustomTable.propTypes = {
    afterComponent: PropTypes.func,
    before: PropTypes.node,
    columns: PropTypes.array,
    data: PropTypes.array,
    emptyText: PropTypes.string,
    handleClickRow: PropTypes.func,
    handleOnPageChange: PropTypes.func,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
    orderdBy: PropTypes.string,
};

export default CustomTable;
