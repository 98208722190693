import styled from 'styled-components';

import { media } from 'common/utils/mixins';

export const TableRow = styled.div`
    align-items: center;
    height: auto;
    display: flex;
    position: relative;
    width: 100%;
    padding-left: 32px;

    &.cursor-pointer {
        cursor: pointer;
    }

    & > * {
        color: #848bab;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
    }

    ${media.md`
        height: auto;
    `}

    @media all and (max-width: 750px) {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &:before {
            display: none;
        }
    }
`;

export const StripedRow = styled(TableRow)`
    background-color: #ffffff;
    border-bottom: 1px solid #eff1fb;
    &.striped {
        background-color: #f7fcff;
    }

    &:hover {
        background-color: #eef4fa;
    }
`;

export const CardRow = styled(TableRow)`
    background-color: #ffffff;
    border: 1px solid #ecedf0;
    border-radius: 3px;
    box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
    margin: 6px 0;
    padding-left: 16px;
    ${media.md`
        .table-cell {
            padding: 11px 10px;
        }
    `}

    &:before {
        left: 1px;
        top: calc(50% - 7px);
    }
`;

export const TableHead = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding-left: 32px;
    ${media.md`
        height: auto;
    `}
    &:before {
        display: none;
    }

    & > * {
        font-weight: 600;
        padding: 10px !important;
        &:before {
            display: none !important;
        }
    }
    @media all and (max-width: 750px) {
        display: none;
    }
`;
