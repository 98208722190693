import React from 'react';

import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';

import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Select,
    MenuItem,
} from 'common/ui';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ReduxFieldSelect = ({
    autoWidth,
    disabled,
    fullWidth = true,
    input: { value, onChange, onBlur },
    label,
    meta: { touched, error },
    multiple,
    options = [],
    readOnly,
    required,
    variant = 'filled',
    ...other
}) => {
    const onChangeAction = (event) => onChange(event);

    const onBlurAction = (event, blurValue) => {
        onBlur(event, blurValue);
    };

    return (
        <Box>
            <FormControl
                disabled={disabled}
                error={Boolean(touched && error)}
                fullWidth={fullWidth}
                required={required}
                variant={variant}
            >
                <InputLabel id={`redux-field-select-label`}>{label}</InputLabel>
                <Select
                    autoWidth={autoWidth}
                    labelId={`redux-field-select-label`}
                    id={`redux-field-select`}
                    value={value}
                    label={label}
                    onChange={onChangeAction}
                    onBlur={onBlurAction}
                    inputProps={{ readOnly: readOnly }}
                    multiple={multiple}
                    displayEmpty={multiple}
                    renderValue={(selected) => {
                        if (isArray(selected)) {
                            if (selected.length > 0) {
                                let selectedOptionsLabel = [];
                                selected.forEach((s) => {
                                    const opt = options.find(
                                        (o) => o.optionValue === s
                                    );
                                    if (opt)
                                        selectedOptionsLabel.push(opt.label);
                                });
                                return selectedOptionsLabel.join(', ');
                            }
                        } else {
                            const opt = options.find(
                                (o) => o.optionValue === selected
                            );
                            return opt ? opt.label : '';
                        }
                        return '';
                    }}
                    MenuProps={MenuProps}
                    {...other}
                >
                    {options.map(
                        ({
                            icon = null,
                            label,
                            optionValue,
                            disabled: optionDisabled,
                        }) => (
                            <MenuItem
                                key={`select-${optionValue}-${label}`}
                                value={optionValue}
                                disabled={optionDisabled}
                            >
                                {multiple ? (
                                    <>
                                        <Checkbox
                                            checked={
                                                value.indexOf(optionValue) > -1
                                            }
                                        />
                                        <ListItemText primary={label} />
                                    </>
                                ) : (
                                    label
                                )}
                                {icon && (
                                    <ListItemIcon sx={{ ml: 1 }}>
                                        {icon}
                                    </ListItemIcon>
                                )}
                            </MenuItem>
                        )
                    )}
                </Select>
                <FormHelperText id={`redux-field-select-helper-text`}>
                    {touched && error}
                </FormHelperText>
            </FormControl>
        </Box>
    );
};

ReduxFieldSelect.propTypes = {
    autoWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    multiple: PropTypes.bool,
    options: PropTypes.array,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    variant: PropTypes.string,
};

export default ReduxFieldSelect;
