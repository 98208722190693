import i18next from 'i18next';
import isArray from 'lodash/isArray';

import { downloadDatasheet } from 'common/api/datasheets';
import showToast from 'common/utils/showToast';

import { DOWNLOAD_DATASHEET, DOWNLOAD_DATASHEET_FAILURE } from '../actionTypes';
import { getIsAllowedFile } from '../helpers';

export default (datasheetName, openLoading, closeLoading) => (dispatch) => {
    dispatch({ type: DOWNLOAD_DATASHEET });
    openLoading(i18next.t('Getting file').concat('...'));

    if (!getIsAllowedFile(datasheetName)) {
        const errorMessage = i18next.t('Invalid file format');
        dispatch({
            type: DOWNLOAD_DATASHEET_FAILURE,
            payload: [errorMessage],
        });
        closeLoading();

        showToast({
            type: 'error',
            body: `${i18next.t(
                'An error occurred while downloading the file'
            )}. ${errorMessage}`,
        });
        return;
    }

    downloadDatasheet(datasheetName)
        .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
            );
            const link = document.createElement('a');
            link.href = `${url}#${datasheetName}`;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            closeLoading();
        })
        .catch((error) => {
            dispatch({
                type: DOWNLOAD_DATASHEET_FAILURE,
                payload: error.response.data.errors,
            });
            closeLoading();

            if (error.response) {
                try {
                    const { data } = error.response;
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        if (e.target.readyState === 2) {
                            let res = {};
                            res = JSON.parse(e.target.result);

                            if (isArray(res.errors)) {
                                showToast({
                                    type: 'error',
                                    body: `${i18next.t(
                                        'An error occurred while downloading the file'
                                    )}. ${res.errors[0]}`,
                                });
                            }
                        }
                    };
                    reader.readAsText(data);
                } catch (readError) {
                    showToast({
                        type: 'error',
                        body: i18next.t(
                            'An error occurred while downloading the file'
                        ),
                    });
                }
            } else {
                showToast({
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while downloading the file'
                    ),
                });
            }
        });
};
