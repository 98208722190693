import API from '../../ocrAPI';
const ENTITY = 'openai';
const baseURL = process.env.REACT_APP_OCR_API_URL + `/api/${ENTITY}`;

export const chatGPT = (data = []) => {
    const token = localStorage.getItem('token') || '';
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify(data);

    return fetch(`${baseURL}/chat`, { method: 'POST', headers, body }).then(
        (res) => {
            if (res.ok) return res;
            return res.json().then((json) => {
                throw { response: { data: json } };
            });
        }
    );
};

export const getModels = () => API.get(`/api/${ENTITY}/fine-tuning`);
export const stopTrainingModel = (modelId) =>
    API.post(`/api/${ENTITY}/fine-tuning/${modelId}/stop`);
export const trainModel = (modelId) =>
    API.post(`/api/${ENTITY}/fine-tuning/${modelId}`);
