import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model?.initialValues
);

export const getTrainingDataModel = createSelector(
    getModel,
    (model) => model?.fetchTrainingData
);

export const getTrainingData = createSelector(
    getTrainingDataModel,
    (model) => model?.data
);

export const getTrainingDataValues = createSelector(
    getTrainingData,
    (data) => data?.values || []
);

export const getTrainingDataIsFetching = createSelector(
    getTrainingDataModel,
    (model) => model?.isFetching
);

export const getTrainingDataErrors = createSelector(
    getTrainingDataModel,
    (model) => model?.errors
);

export const getTrainingDataTags = createSelector(
    getTrainingData,
    (data) => data?.tags
);

export const getDeleteTrainingDataModel = createSelector(
    getModel,
    (model) => model?.deleteTrainingData
);

export const getDeleteTrainingDataIsDeleting = createSelector(
    getDeleteTrainingDataModel,
    (model) => model?.isDeleting
);

export const getDeleteTrainingDataErrors = createSelector(
    getDeleteTrainingDataModel,
    (model) => model?.errors
);

export const getFetchFileModel = createSelector(
    getModel,
    (model) => model?.fetchFile
);

export const getFetchFileData = createSelector(
    getFetchFileModel,
    (model) => model?.data
);

export const getFetchFileIsFetching = createSelector(
    getFetchFileModel,
    (model) => model?.isFetching
);

export const getSaveTrainingDataModel = createSelector(
    getModel,
    (model) => model?.saveTrainingData
);

export const getIsSavingTrainingData = createSelector(
    getSaveTrainingDataModel,
    (model) => model?.isSaving
);

// chat
export const getMessages = createSelector(
    getModel,
    (model) => model?.chatMessages || []
);

export const getChatInitialValues = createSelector(
    getModel,
    (model) => model?.chatInitialValues
);

export const getSendMessagesModel = createSelector(
    getModel,
    (model) => model?.sendMessages
);

export const getSendMessagesIsSending = createSelector(
    getSendMessagesModel,
    (model) => model?.isSending
);

export const getFetchTrainingDataTagsModel = createSelector(
    getModel,
    (model) => model?.fetchTrainingDataTags
);

export const getFetchTrainingDataTagsData = createSelector(
    getFetchTrainingDataTagsModel,
    (model) => model?.data
);

export const getFetchTrainingDataTagsIsFetching = createSelector(
    getFetchTrainingDataTagsModel,
    (model) => model?.isFetching
);

export const getFetchTrainingDataTagsForSelect = createSelector(
    getFetchTrainingDataTagsData,
    (data) =>
        data?.map((tag) => ({
            label: tag.name,
            value: tag.id,
            color: tag.color,
        }))
);

export const getIsOpenTagsForm = createSelector(
    getModel,
    (model) => model?.isOpenTagsForm
);

export const getTagsFormInitialValues = createSelector(
    getModel,
    (model) => model?.tagsFormInitialValues
);
