import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import CardContainer from 'common/components/CardContainer';
import { CustomSelect } from 'common/components/form';
import {
    ReduxFieldAutocomplete,
    ReduxFieldInput,
} from 'common/components/form/redux';
import {
    DOMESTIC_RATES,
    HIGH_CONSUMPTION_DOMESTIC_RATES,
    RATES_WITH_DIVISION,
} from 'common/constants/rates';
import {
    Box,
    Button,
    Card,
    CardActions,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Pagination,
    Stack,
    Switch,
    Typography,
    Tooltip,
} from 'common/ui';

import rateHistoryModal from '../../rateHistoryModal';
import * as rateHistoryModalActions from '../../rateHistoryModal/actions';
import * as actions from '../actions';
import { NAME } from '../constants';
import {
    getTagBySelectedRate,
    getSelectedRateName,
    isMexicanSupplierCFE,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import RateHistoryPreviewTable from './RateHistoryPreviewTable';

const SupplierForm = ({
    divisions,
    errors,
    fetchDivisions,
    fetchMonths,
    fetchRateHistory,
    fetchRates,
    fetchRegions,
    fetchSummerMonths,
    fetchYears,
    formValues,
    getOrigins,
    handleClickEditRateHistory,
    handleClickSave,
    handleEditionChange,
    handleSubmit,
    isEnabledEdition,
    isFetchingDetail,
    isFetchingDivisions,
    isFetchingMonths,
    isFetchingOrigins,
    isFetchingRateHistory,
    isFetchingRates,
    isFetchingRegions,
    isFetchingSummerMonths,
    isFetchingYears,
    isNewRecord,
    isSaving,
    months,
    origins,
    prepareReturnCatalog,
    rateHistories,
    rateHistoryFilters,
    rateHistoryPagination,
    rates,
    regions,
    setCurrentItem,
    summerMonths,
    years,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [selectedRate, setSelectedRate] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [summer, setSummerMonth] = useState('');
    const [region, setRegion] = useState('');
    const [division, setDivision] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');

    useEffect(() => {
        if (isMexicanSupplierCFE(formValues)) {
            fetchRates(1, formValues.ratesCounter, '', '', formValues.name);
            fetchYears();
            fetchMonths();
            fetchSummerMonths();
            fetchRegions();
            fetchDivisions();
        }
    }, [formValues.id]);

    useEffect(() => {
        if (!isEmpty(rates)) {
            setSelectedRate(rates[0].optionValue);
        }

        if (!isEmpty(years)) {
            setYear(years[0].optionValue);
        }

        if (!isEmpty(months)) {
            setMonth(months[0].optionValue);
        }
    }, [rates, years, months]);

    useEffect(() => {
        if (selectedRate && year && month) {
            fetchRateHistory(
                page,
                selectedRate,
                year,
                month,
                summer,
                region,
                division,
                orderBy,
                sortBy,
                ''
            );
        }
    }, [page, selectedRate, year, month, summer, region, division]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchRateHistory(
            page,
            selectedRate,
            year,
            month,
            summer,
            region,
            division,
            orderBy,
            sortBy,
            ''
        );
    };

    const handleEditionSwitchChange = (event) => {
        handleEditionChange(event.target.checked);
    };

    return (
        <>
            <Stack spacing={3}>
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Tooltip title={t('Back')}>
                        <IconButton
                            onClick={() => {
                                prepareReturnCatalog();
                                setCurrentItem({});
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>

                    <FormGroup>
                        <FormControlLabel
                            disabled={isNewRecord || isFetchingDetail}
                            control={
                                <Switch
                                    checked={isEnabledEdition}
                                    onChange={handleEditionSwitchChange}
                                />
                            }
                            label={t('Edit')}
                            labelPlacement="start"
                        />
                    </FormGroup>
                </Stack>

                <Box>
                    <form>
                        <Stack spacing={3}>
                            <CardContainer
                                title={t('General information')}
                                isCollapsable
                                isOpen
                                loading={isFetchingDetail}
                                withDivider
                            >
                                <Grid container spacing={2} pt={3}>
                                    <Grid item xs={12}>
                                        <Grid item container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    name="name"
                                                    component={ReduxFieldInput}
                                                    placeholder={t('Name')}
                                                    variant="outlined"
                                                    required
                                                    disabled={
                                                        isFetchingDetail ||
                                                        !isEnabledEdition
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Field
                                                    name="country"
                                                    component={
                                                        ReduxFieldAutocomplete
                                                    }
                                                    placeholder={t('Country')}
                                                    variant="outlined"
                                                    required
                                                    disabled={
                                                        isFetchingDetail ||
                                                        !isEnabledEdition
                                                    }
                                                    options={origins}
                                                    handleOnSearchChange={
                                                        getOrigins
                                                    }
                                                    loading={isFetchingOrigins}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            component="div"
                                            mt={4}
                                        >
                                            * {t('Required fields')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContainer>

                            {isMexicanSupplierCFE(formValues) && (
                                <CardContainer
                                    title={t("Supplier's rate history")}
                                    isCollapsable
                                    isOpen
                                    loading={
                                        isFetchingRates || isFetchingRateHistory
                                    }
                                    withDivider
                                >
                                    {!isEmpty(rates) ? (
                                        <Stack spacing={2}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Grid
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    container
                                                    item
                                                    xs={12}
                                                    lg={2}
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="text.primary"
                                                        component="div"
                                                    >
                                                        {t('Filter by').concat(
                                                            ': '
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} lg={2}>
                                                    <CustomSelect
                                                        disabled={
                                                            isFetchingRates
                                                        }
                                                        label={t('Rate', {
                                                            count: 1,
                                                        })}
                                                        onChange={(e) => {
                                                            setSelectedRate(
                                                                e.target.value
                                                            );

                                                            setSummerMonth('');
                                                            setRegion('');
                                                            setDivision('');
                                                        }}
                                                        options={rates}
                                                        value={selectedRate}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={2}>
                                                    <CustomSelect
                                                        disabled={
                                                            isFetchingYears
                                                        }
                                                        label={t('Year', {
                                                            count: 1,
                                                        })}
                                                        onChange={(e) =>
                                                            setYear(
                                                                e.target.value
                                                            )
                                                        }
                                                        options={years}
                                                        value={year}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={2}>
                                                    <CustomSelect
                                                        disabled={
                                                            isFetchingMonths
                                                        }
                                                        label={t('Month', {
                                                            count: 1,
                                                        })}
                                                        onChange={(e) =>
                                                            setMonth(
                                                                e.target.value
                                                            )
                                                        }
                                                        options={months}
                                                        value={month}
                                                    />
                                                </Grid>

                                                {selectedRate &&
                                                    DOMESTIC_RATES.includes(
                                                        getSelectedRateName(
                                                            selectedRate,
                                                            rates
                                                        )
                                                    ) && (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            lg={2}
                                                        >
                                                            <CustomSelect
                                                                disabled={
                                                                    isFetchingSummerMonths
                                                                }
                                                                label={t(
                                                                    'Summer month'
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setSummerMonth(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                    setRegion(
                                                                        ''
                                                                    );
                                                                    setDivision(
                                                                        ''
                                                                    );
                                                                }}
                                                                options={
                                                                    summerMonths
                                                                }
                                                                value={summer}
                                                            />
                                                        </Grid>
                                                    )}

                                                {selectedRate &&
                                                    HIGH_CONSUMPTION_DOMESTIC_RATES.includes(
                                                        getSelectedRateName(
                                                            selectedRate,
                                                            rates
                                                        )
                                                    ) && (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            lg={2}
                                                        >
                                                            <CustomSelect
                                                                disabled={
                                                                    isFetchingRegions
                                                                }
                                                                label={t(
                                                                    'Region'
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setRegion(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                    setSummerMonth(
                                                                        ''
                                                                    );
                                                                    setDivision(
                                                                        ''
                                                                    );
                                                                }}
                                                                options={
                                                                    regions
                                                                }
                                                                value={region}
                                                            />
                                                        </Grid>
                                                    )}

                                                {selectedRate &&
                                                    RATES_WITH_DIVISION.includes(
                                                        getSelectedRateName(
                                                            selectedRate,
                                                            rates
                                                        )
                                                    ) && (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            lg={2}
                                                        >
                                                            <CustomSelect
                                                                disabled={
                                                                    isFetchingDivisions
                                                                }
                                                                label={t(
                                                                    'Division'
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setDivision(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                    setSummerMonth(
                                                                        ''
                                                                    );
                                                                    setRegion(
                                                                        ''
                                                                    );
                                                                }}
                                                                options={
                                                                    divisions
                                                                }
                                                                value={division}
                                                            />
                                                        </Grid>
                                                    )}

                                                {isEnabledEdition && (
                                                    <Grid item xs={6} lg={2}>
                                                        <Button
                                                            disabled={
                                                                isFetchingDetail ||
                                                                !selectedRate ||
                                                                !year ||
                                                                !month
                                                            }
                                                            fullWidth={true}
                                                            size="large"
                                                            startIcon={
                                                                <AddIcon />
                                                            }
                                                            type="button"
                                                            onClick={() => {
                                                                handleClickEditRateHistory(
                                                                    selectedRate,
                                                                    year,
                                                                    month
                                                                );
                                                            }}
                                                        >
                                                            {t('Add')}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>

                                            <Card
                                                sx={{
                                                    boxShadow: (theme) =>
                                                        theme.customShadows.z1,
                                                }}
                                            >
                                                <RateHistoryPreviewTable
                                                    handleSortable={
                                                        handleSortable
                                                    }
                                                    isEnabledEdition={
                                                        isEnabledEdition
                                                    }
                                                    isFetching={
                                                        isFetchingRateHistory
                                                    }
                                                    items={rateHistories}
                                                    month={month}
                                                    orderdBy={orderBy}
                                                    rateLabel={getTagBySelectedRate(
                                                        selectedRate,
                                                        rates
                                                    )}
                                                    selectedRate={selectedRate}
                                                    sortedBy={sortBy}
                                                    year={year}
                                                />

                                                {!isFetchingRateHistory &&
                                                    rateHistoryPagination &&
                                                    !isEmpty(
                                                        rateHistoryPagination
                                                    ) && (
                                                        <CardActions>
                                                            <Pagination
                                                                color="primary"
                                                                count={
                                                                    Math.ceil(
                                                                        parseInt(
                                                                            rateHistoryPagination.total_rows
                                                                        ) /
                                                                            parseInt(
                                                                                rateHistoryPagination.per_page
                                                                            )
                                                                    ) || 0
                                                                }
                                                                shape="rounded"
                                                                showFirstButton
                                                                showLastButton
                                                                sx={{
                                                                    marginLeft:
                                                                        'auto',
                                                                }}
                                                                page={
                                                                    rateHistoryPagination.current_page
                                                                }
                                                                onChange={
                                                                    handleOnChangePage
                                                                }
                                                                variant="outlined"
                                                            />
                                                        </CardActions>
                                                    )}
                                            </Card>
                                        </Stack>
                                    ) : (
                                        <Grid container pt={3}>
                                            <Grid item xs={12}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={2}
                                                >
                                                    <InfoIcon />
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        component="div"
                                                    >
                                                        {t(
                                                            'There are still no rates at this provider. Notify development to load rates'
                                                        )}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    )}
                                </CardContainer>
                            )}

                            <ShowErrors
                                errors={errors}
                                mt={3}
                                severity="error"
                                variant="filled"
                            />

                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                spacing={3}
                                pt={3}
                                pb={3}
                            >
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        disabled={isFetchingDetail || isSaving}
                                        fullWidth={false}
                                        height="48px"
                                        size="large"
                                        onClick={() => {
                                            prepareReturnCatalog();
                                            setCurrentItem({});
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </Grid>
                                {isEnabledEdition && (
                                    <Grid item>
                                        <Button
                                            disabled={
                                                isFetchingDetail || isSaving
                                            }
                                            fullWidth={false}
                                            height="48px"
                                            size="large"
                                            onClick={handleSubmit(
                                                handleClickSave
                                            )}
                                            type="submit"
                                        >
                                            {isSaving
                                                ? `${t('Loading')}...`
                                                : isNewRecord
                                                ? t('Create')
                                                : t('Save')}
                                        </Button>
                                    </Grid>
                                )}
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Stack>

            <rateHistoryModal.Container
                filters={rateHistoryFilters}
                handleAfterSave={fetchRateHistory}
                rateLabel={getTagBySelectedRate(selectedRate, rates)}
                rateName={getSelectedRateName(selectedRate, rates)}
            />
        </>
    );
};

SupplierForm.propTypes = {
    divisions: PropTypes.array,
    errors: PropTypes.array,
    fetchDivisions: PropTypes.func,
    fetchMonths: PropTypes.func,
    fetchRateHistory: PropTypes.func,
    fetchRates: PropTypes.func,
    fetchRegions: PropTypes.func,
    fetchSummerMonths: PropTypes.func,
    fetchYears: PropTypes.func,
    formValues: PropTypes.object,
    getOrigins: PropTypes.func,
    handleClickEditRateHistory: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleEditionChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    isFetchingDivisions: PropTypes.bool,
    isFetchingMonths: PropTypes.bool,
    isFetchingOrigins: PropTypes.bool,
    isFetchingRateHistory: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    isFetchingRegions: PropTypes.bool,
    isFetchingSummerMonths: PropTypes.bool,
    isFetchingYears: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    months: PropTypes.array,
    origins: PropTypes.array,
    prepareReturnCatalog: PropTypes.func,
    rateHistories: PropTypes.array,
    rateHistoryFilters: PropTypes.object,
    rateHistoryPagination: PropTypes.object,
    rates: PropTypes.array,
    regions: PropTypes.array,
    setCurrentItem: PropTypes.func,
    summerMonths: PropTypes.array,
    years: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    divisions: selectors.getDivisionsOptionsForSelect,
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isEnabledEdition: selectors.getEnabledEdition,
    isFetchingDetail: selectors.getIsFetchingSupplierDetail,
    isFetchingDivisions: selectors.getIsFetchingDivisions,
    isFetchingMonths: selectors.getIsFetchingMonths,
    isFetchingOrigins: selectors.getIsFetchingOrigins,
    isFetchingRateHistory: selectors.getRateHistoryIsFetching,
    isFetchingRates: selectors.getIsFetchingRates,
    isFetchingRegions: selectors.getIsFetchingRegions,
    isFetchingSummerMonths: selectors.getIsFetchingSummerMonths,
    isFetchingYears: selectors.getIsFetchingYears,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
    months: selectors.getMonthsOptionsForSelect,
    origins: selectors.getOriginsOptionsForSelect,
    rateHistories: selectors.getRateHistoryData,
    rateHistoryFilters: selectors.getRateHistoryFilters,
    rateHistoryPagination: selectors.getRateHistoryPagination,
    rates: selectors.getRatesOptionsForSelect,
    regions: selectors.getRegionsOptionsForSelect,
    summerMonths: selectors.getSummerMonthsOptionsForSelect,
    years: selectors.getYearsOptionsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    fetchDivisions: () => dispatch(actions.fetchDivisions()),
    fetchMonths: () => dispatch(actions.fetchMonths()),
    fetchRegions: () => dispatch(actions.fetchRegions()),
    fetchSummerMonths: () => dispatch(actions.fetchSummerMonths()),
    fetchRateHistory: (
        page,
        rateId,
        year,
        month,
        summer,
        region,
        division,
        orderBy,
        sortBy,
        searchStr
    ) =>
        dispatch(
            actions.fetchRateHistory(
                page,
                rateId,
                year,
                month,
                summer,
                region,
                division,
                orderBy,
                sortBy,
                searchStr
            )
        ),
    fetchRates: (page, perPage, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchRates(page, perPage, orderBy, sortBy, searchStr)),
    fetchYears: () => dispatch(actions.fetchYears()),
    getOrigins: (str) => dispatch(actions.fetchOrigins(str)),

    handleClickEditRateHistory: (rate, year, month) =>
        dispatch(
            rateHistoryModalActions.prepareRateHistoryDatagrid(
                rate,
                year,
                month
            )
        ),
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleEditionChange: (value) => dispatch(actions.setEnabledEdition(value)),
    prepareReturnCatalog: () => dispatch(actions.returnCatalog()),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(SupplierForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
