import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BasicDataGrid, DropdownCardMenu } from 'common/components';
import { Box } from 'common/ui';

const TagsTable = ({
    isFetchingTags,
    items = [],
    prepareDeleteTag,
    prepareUpdateTag,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            valueGetter: (params) => params?.row?.name,
        },
        {
            align: 'left',
            field: 'color',
            flex: 1,
            headerName: t('Color'),
            renderCell: (params) => {
                return (
                    <Box
                        component="span"
                        sx={{
                            backgroundColor: params?.row?.color,
                            borderRadius: '8px',
                            height: '16px',
                            width: '48px',
                        }}
                    />
                );
            },
        },
        {
            align: 'center',
            field: '',
            renderCell: (params) => (
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () => prepareUpdateTag(params.row),
                            icon: <EditIcon fontSize="small" />,
                            label: t('Edit'),
                        },
                        {
                            handleClick: () => prepareDeleteTag(params.row.id),
                            icon: <DeleteIcon fontSize="small" />,
                            label: t('Delete'),
                        },
                    ]}
                />
            ),
            sortable: false,
            width: 50,
        },
    ];

    return (
        <BasicDataGrid
            columns={columns}
            isLoading={isFetchingTags}
            onRowClick={(row) => prepareUpdateTag(row)}
            rows={items}
        />
    );
};

TagsTable.propTypes = {
    isFetchingTags: PropTypes.bool,
    items: PropTypes.array,
    prepareDeleteTag: PropTypes.func,
    prepareUpdateTag: PropTypes.func,
};

export default TagsTable;
