import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

export const getFeaturedTimeText = (item) => {
    if (!isEmpty(item) && item.is_outstanding) {
        if (!isEmpty(item.outstanding)) {
            if (
                isNil(item.outstanding.days_left) ||
                parseInt(item.outstanding.days_left) === 0
            ) {
                return i18next.t('Unlimited');
            } else {
                return `${item.outstanding.days_left} ${i18next
                    .t('Day', {
                        count: parseInt(item.outstanding.days_left),
                    })
                    .toLocaleLowerCase()}`;
            }
        }
    }

    return '---';
};
