import i18next from 'i18next';

import { preAuth } from 'common/api/auth';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    RESEND_CODE,
    RESEND_CODE_FAILURE,
    RESEND_CODE_SUCCESS,
} from '../actionTypes';

import setAuthValues from './setAuthValues';
import showSecondStepAuthForm from './showSecondStepAuthForm';

export default (data) => (dispatch) => {
    dispatch({ type: RESEND_CODE });
    const newValues = {
        email: data.email,
        password: data.password,
    };

    preAuth(newValues)
        .then((response) => {
            dispatch(showSecondStepAuthForm(false));
            dispatch({
                type: RESEND_CODE_SUCCESS,
                payload: response.data,
            });

            dispatch(
                setAuthValues({
                    ...newValues,
                    validationToken: response.data.auth_token,
                })
            );

            dispatch(showSecondStepAuthForm(true));
            showToast({ body: i18next.t('The code was successfully resent') });
        })
        .catch((error) => {
            dispatch({
                type: RESEND_CODE_FAILURE,
                payload: error.response.data.errors,
            });
            dispatch(alerts.actions.close());

            const dataAlert = {
                confirmText: i18next.t('Close'),
                messages: [
                    `${i18next.t('Whoops, something went wrong')}:`,
                    ...error.response.data.errors,
                ],
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'error',
            };

            dispatch(alerts.actions.show(dataAlert));
        });
};
