import { filterOrigins } from 'common/api/productsCountry';

import {
    FETCH_ORIGINS,
    FETCH_ORIGINS_SUCCESS,
    FETCH_ORIGINS_FAILURE,
} from '../actionTypes';

export default (str = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_ORIGINS });

        filterOrigins(str)
            .then((response) => {
                dispatch({
                    type: FETCH_ORIGINS_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_ORIGINS_FAILURE,
                    payload: error.response.data.errors,
                })
            );
    };
