import { getCompanyBranchOffices } from 'common/api/companies';

import {
    FETCH_BRANCH_OFFICES,
    FETCH_BRANCH_OFFICES_FAILURE,
    FETCH_BRANCH_OFFICES_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

export default (companyId, page = 1, orderBy, sortBy) =>
    (dispatch) => {
        dispatch(companyGeneralActions[FETCH_BRANCH_OFFICES]());

        getCompanyBranchOffices(companyId, page, orderBy, sortBy)
            .then((response) =>
                dispatch(
                    companyGeneralActions[FETCH_BRANCH_OFFICES_SUCCESS](
                        response.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    companyGeneralActions[FETCH_BRANCH_OFFICES_FAILURE](
                        error.response.data.errors
                    )
                )
            );
    };
