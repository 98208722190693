import API from '..';
const ENTITY = 'proposals';

export const filterProposals = ({
    company_id,
    end_date,
    order_by = 'created_at',
    page = 1,
    perPage = 25,
    search = '',
    sort = null,
    start_date,
    is_archived = false,
    status,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            company_id,
            end_date,
            order_by,
            page: page,
            per_page: perPage,
            search,
            sort,
            start_date,
            is_archived,
            status,
        },
    });
