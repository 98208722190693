import API from '..';
const ENTITY = 'products';

export const filterInverters = ({
    brandName = '',
    maxPower = null,
    orderBy,
    page = 1,
    perPage = 25,
    searchText,
    sortBy,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/inverters/`, {
        params: {
            page: page,
            per_page: perPage,
            status_flag: status,
            ...(brandName ? { brand: brandName } : {}),
            ...(maxPower ? { max_power: maxPower } : {}),
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const getInverters = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/inverters/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const getInverterDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/inverter/${id}/`);

export const createInverter = (data) =>
    API.post(`/api/v1/${ENTITY}/inverter/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const updateInverter = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/inverter/${id}/`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const filterBrands = (str = '') =>
    API.get(
        `/api/v1/${ENTITY}/inverters/brands/?search${str ? '=' + str : ''}`
    );
