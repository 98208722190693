import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModal from './toggleModal';

export default (item) => (dispatch) => {
    dispatch(setIsNewRecord(false));
    dispatch(toggleModal(true));
    dispatch(resetForm());
    dispatch(
        initializeForm({
            ...item,
        })
    );
};
