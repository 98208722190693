import { NAME } from './constants';

export const ADD_MESSAGE = `${NAME}/ADD_MESSAGE`;

export const DELETE_TRAINING_DATA = `${NAME}/DELETE_TRAINING_DATA`;
export const DELETE_TRAINING_DATA_FAILURE = `${NAME}/DELETE_TRAINING_DATA_FAILURE`;
export const DELETE_TRAINING_DATA_SUCCESS = `${NAME}/DELETE_TRAINING_DATA_SUCCESS`;

export const EDIT_MESSAGE = `${NAME}/EDIT_MESSAGE`;

export const FETCH_FILE = `${NAME}/FETCH_FILE`;
export const FETCH_FILE_FAILURE = `${NAME}/FETCH_FILE_FAILURE`;
export const FETCH_FILE_SUCCESS = `${NAME}/FETCH_FILE_SUCCESS`;

export const FETCH_TRAINING_DATA = `${NAME}/FETCH_TRAINING_DATA`;
export const FETCH_TRAINING_DATA_FAILURE = `${NAME}/FETCH_TRAINING_DATA_FAILURE`;
export const FETCH_TRAINING_DATA_SUCCESS = `${NAME}/FETCH_TRAINING_DATA_SUCCESS`;

export const FETCH_TRAINING_DATA_TAGS = `${NAME}/FETCH_TRAINING_DATA_TAGS`;
export const FETCH_TRAINING_DATA_TAGS_FAILURE = `${NAME}/FETCH_TRAINING_DATA_TAGS_FAILURE`;
export const FETCH_TRAINING_DATA_TAGS_SUCCESS = `${NAME}/FETCH_TRAINING_DATA_TAGS_SUCCESS`;

export const INITIALIZE = `${NAME}/INITIALIZE`;
export const INITIALIZE_TAGS_FORM = `${NAME}/INITIALIZE_TAGS_FORM`;

export const SAVE_TRAINING_DATA = `${NAME}/SAVE_TRAINING_DATA`;
export const SAVE_TRAINING_DATA_FAILURE = `${NAME}/SAVE_TRAINING_DATA_FAILURE`;
export const SAVE_TRAINING_DATA_SUCCESS = `${NAME}/SAVE_TRAINING_DATA_SUCCESS`;

export const SAVE_TRAINING_DATA_TAGS = `${NAME}/SAVE_TRAINING_DATA_TAGS`;
export const SAVE_TRAINING_DATA_TAGS_FAILURE = `${NAME}/SAVE_TRAINING_DATA_TAGS_FAILURE`;
export const SAVE_TRAINING_DATA_TAGS_SUCCESS = `${NAME}/SAVE_TRAINING_DATA_TAGS_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_TAGS_FORM = `${NAME}/RESET_TAGS_FORM`;

export const SEND_MESSAGES = `${NAME}/SEND_MESSAGES`;
export const SEND_MESSAGES_FAILURE = `${NAME}/SEND_MESSAGES_FAILURE`;
export const SEND_MESSAGES_SUCCESS = `${NAME}/SEND_MESSAGES_SUCCESS`;

export const SET_IS_OPEN_TAGS_FORM = `${NAME}/SET_IS_OPEN_TAGS_FORM`;

export const UPLOAD_FILE = `${NAME}/UPLOAD_FILE`;
export const UPLOAD_FILE_FAILURE = `${NAME}/UPLOAD_FILE_FAILURE`;
export const UPLOAD_FILE_SUCCESS = `${NAME}/UPLOAD_FILE_SUCCESS`;
