import React from 'react';

import Editor from '@monaco-editor/react';
import PropTypes from 'prop-types';

const JSONViewer = ({
    beforeMount,
    json,
    onChange,
    onMount,
    onValidate,
    path,
    readOnly = false,
}) => {
    return (
        <Editor
            beforeMount={beforeMount}
            defaultLanguage="json"
            height="100%"
            onChange={onChange}
            onMount={onMount}
            options={{
                automaticLayout: true,
                minimap: { enabled: false },
                padding: { top: 16, bottom: 16 },
                readOnly,
                scrollBeyondLastLine: false,
            }}
            path={path}
            onValidate={onValidate}
            theme="vs-dark"
            value={json}
            width="100%"
        />
    );
};

JSONViewer.propTypes = {
    beforeMount: PropTypes.func,
    json: PropTypes.string,
    onChange: PropTypes.func,
    onMount: PropTypes.func,
    onValidate: PropTypes.func,
    path: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default JSONViewer;
