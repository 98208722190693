import React from 'react';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { MONTHS } from 'common/constants';
import { Button } from 'common/ui';

const getMonthName = (month) => i18next.t(MONTHS[month - 1]);

const ProductColumns = (handleDownloadFile) => {
    const { t } = useTranslation();
    const columns = [
        {
            align: 'left',
            field: 'rate',
            flex: 1,
            headerName: t('Rate_one'),
            renderCell: (params) => {
                const { row } = params;
                return row.rate;
            },
        },
        {
            align: 'left',
            field: 'dp1',
            flex: 1,
            headerName: t('Political division'),
            renderCell: (params) => {
                const { row } = params;
                return row.dp1;
            },
        },
        {
            align: 'left',
            field: 'year',
            flex: 1,
            headerName: t('Year_one'),
            renderCell: (params) => {
                const { row } = params;
                return row.year || '';
            },
        },
        {
            align: 'left',
            field: 'month',
            flex: 1,
            headerName: t('Month_one'),
            renderCell: (params) => {
                const { row } = params;
                return getMonthName(row.month) || '';
            },
        },
        {
            align: 'left',
            field: 'url',
            flex: 1,
            headerName: t('Download'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Button onClick={() => handleDownloadFile(row.url)}>
                        {t('Download')}
                    </Button>
                );
            },
        },
    ];

    return columns;
};

export default ProductColumns;
