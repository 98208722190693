import i18next from 'i18next';
import { isEmpty, isNull } from 'lodash';

import { updateTemplate } from 'common/api/templatesCatalog';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchTemplates from './fetchTemplates';
import resetForm from './resetForm';
import toggleTemplateForm from './toggleTemplateForm';

export default (values, id) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = new FormData();
    newValues.append('title', values.title);
    newValues.append(
        'country',
        values.country === 'global' ? '' : values.country
    );
    newValues.append('category', values.category);
    newValues.append('locked', values.locked);

    if (!isNull(values?.custom_offer_template))
        newValues.append('custom_offer_template', values.custom_offer_template);

    if (!isNull(values?.company_id))
        newValues.append('company_id', values.company_id);

    if (Array.isArray(values.languages)) {
        newValues.append(
            'languages',
            !isEmpty(values.languages) ? values.languages.join() : ''
        );
    }

    if (Array.isArray(values.image_thumbnail)) {
        newValues.append(
            'image_thumbnail',
            values.image_thumbnail && values.image_thumbnail[0]
        );
    }

    if (values?.company_id && isNull(values?.custom_offer_template)) {
        dispatch({
            type: SAVE_FAILURE,
            payload: [i18next.t('Select a filtered base template')],
        });
        return false;
    }

    updateTemplate(newValues, id)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchTemplates(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleTemplateForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t(
                    'An error occurred while updating the template'
                ),
            });
        });
};
