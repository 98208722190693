import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteCategory from './deleteCategory';

export default (item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        onSuccess: () => dispatch(deleteCategory(item.id)),
        title: i18next.t('Delete category'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(alerts.actions.show(dataAlert));
};
