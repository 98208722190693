import API from '..';
const ENTITY = 'products';

export const getFamilies = (
    id,
    page = 1,
    orderBy = '',
    sortBy = '',
    str = ''
) =>
    API.get(
        `/api/v1/${ENTITY}/brand-families/${id}/?search${
            str ? '=' + str : ''
        }&page=${page}${orderBy !== '' ? `&order_by=${orderBy}` : ''}${
            sortBy !== '' ? `&sort=${sortBy}` : ''
        }`
    );

export const createFamily = (data) =>
    API.post(`/api/v1/${ENTITY}/families/`, data);

export const updateFamily = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/families/${id}/`, data);

export const deleteFamily = (id) =>
    API.delete(`/api/v1/${ENTITY}/families/${id}/`);

export const filterFamiliesByBrand = (id, str = '') =>
    API.get(
        `/api/v1/${ENTITY}/brands-list/${id}/families/?search${
            str ? '=' + str : ''
        }`
    );
