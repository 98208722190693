import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteSupplier from './deleteSupplier';

export default (item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        messages: [
            i18next.t(
                'The power supplier "{{name}}" will be permanently deleted',
                {
                    name: `${item.name}`,
                }
            ),
        ],
        onSuccess: () => dispatch(deleteSupplier(item.id)),
        title: i18next.t('Delete power supplier'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(alerts.actions.show(dataAlert));
};
