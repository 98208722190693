import React, { useContext, useEffect } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container as MuiContainer,
    Grid,
    Typography,
} from 'common/ui';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({
    downloadAddonsReport,
    downloadBureauReport,
    isDownloading,
    isDownloadingAddonsReport,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (!isEmpty(loadingContext)) {
            if (isDownloading || isDownloadingAddonsReport) {
                loadingContext.openLoading(t('Downloading').concat('...'));
            } else {
                loadingContext.closeLoading();
            }
        }
    }, [isDownloading, isDownloadingAddonsReport]);

    return (
        <MuiContainer maxWidth="">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '140px',
                        }}
                    >
                        <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                            <Typography
                                variant="title"
                                fontWeight="bold"
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {t('Approved proposals report')}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                size="small"
                                startIcon={<DownloadIcon />}
                                variant="text"
                            >
                                {t('Download')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '140px',
                        }}
                    >
                        <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                            <Typography
                                variant="title"
                                fontWeight="bold"
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {t('Credit Bureau report')}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                onClick={() =>
                                    downloadBureauReport(
                                        t('Credit Bureau report')
                                    )
                                }
                                size="small"
                                startIcon={<DownloadIcon />}
                                variant="text"
                            >
                                {t('Download')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '140px',
                        }}
                    >
                        <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                            <Typography
                                variant="title"
                                fontWeight="bold"
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {t('Add ons report')}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                onClick={() =>
                                    downloadAddonsReport(t('Add ons report'))
                                }
                                size="small"
                                startIcon={<DownloadIcon />}
                                variant="text"
                            >
                                {t('Download')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </MuiContainer>
    );
};

Container.propTypes = {
    downloadAddonsReport: PropTypes.func,
    downloadBureauReport: PropTypes.func,
    isDownloading: PropTypes.bool,
    isDownloadingAddonsReport: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    isDownloading: selectors.getIsDownloadingBureauReport,
    isDownloadingAddonsReport: selectors.getIsDownloadingAddonsReport,
});

const mapDispatchToProps = (dispatch) => ({
    downloadActiveCiBancoAlliancesReport: (fileName) =>
        dispatch(actions.downloadActiveCiBancoAlliancesReport(fileName)),
    downloadAddonsReport: (fileName) =>
        dispatch(actions.downloadAddonsReport(fileName)),
    downloadBureauReport: (fileName) =>
        dispatch(actions.downloadBureauReport(fileName)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Container));
