import React, { useRef, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Button, Menu, MenuItem, Typography } from 'common/ui';
import { capitalize } from 'common/utils/helpers';

const BulkEditionMenu = ({
    items,
    prefix,
    selectedBulkOption,
    selectionModel,
    setSelectionModel,
}) => {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const count = selectionModel.length;
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const label = () => {
        if (selectedBulkOption) return selectedBulkOption;
        return `${count} ${capitalize(prefix)} ${t('Selected', {
            count: count,
        }).toLowerCase()}`;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'start', md: 'end' },
                gap: 1,
            }}
        >
            <Box ref={containerRef}>
                <Button
                    color="secondary"
                    endIcon={<KeyboardArrowDownIcon />}
                    fullWidth
                    onClick={handleClick}
                    variant="outlined"
                >
                    <Typography
                        component="span"
                        fontWeight="bold"
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            width: 'calc(100% - 16px)',
                            textAlign: 'left',
                            textOverflow: 'ellipsis',
                            textTransform: 'initial',
                        }}
                        variant="body2"
                    >
                        {label()}
                    </Typography>
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                        '& .MuiPaper-root': {
                            mt: '0.25rem',
                            width: containerRef?.current?.offsetWidth || 'auto',
                        },
                    }}
                >
                    {items.map(({ handleClick, label, visible }, index) =>
                        visible ? (
                            <MenuItem
                                key={`bulk-option-${index}`}
                                onClick={() => {
                                    handleClick();
                                    handleClose();
                                }}
                                sx={{ boxShadow: 'none' }}
                            >
                                <Typography variant="body2" color="#848bab">
                                    {label}
                                </Typography>
                            </MenuItem>
                        ) : null
                    )}
                </Menu>
            </Box>
            <Button
                color="secondary"
                fullWidth={false}
                onClick={() => setSelectionModel([])}
                variant="outlined"
            >
                {t('Cancel')}
            </Button>
        </Box>
    );
};

BulkEditionMenu.defaultProps = {
    handleClickUpdateMargin: () => {},
    isOpenModalMargin: false,
    showMarginOption: true,
};

BulkEditionMenu.propTypes = {
    items: PropTypes.array,
    prefix: PropTypes.string,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
};

export default BulkEditionMenu;
