import React, { useEffect, useState } from 'react';

import InsertCommentIcon from '@mui/icons-material/InsertComment';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Breadcrumbs, HeaderContainer, TitleIcon } from 'common/components';
import { Box, IconButton, Popper, Stack, Typography } from 'common/ui';

import * as actions from './actions';
import ChatContainer from './components/ChatContainer';
import Content from './components/Content';
import EditTagsModal from './components/EditTagsModal';
import TagsHeader from './components/TagsHeader';
import * as selectors from './selectors';

const Container = ({
    fetchTags,
    fetchTrainingData,
    isFetchingTrainingData,
    isOpenTagsForm,
    match,
    prepareEditTags,
    reset,
    resetTagsForm,
    saveTrainingDataTags,
    setIsOpenTagsForm,
    tagsFormInitialValues,
    trainingDataTags,
    trainingDataTagsForSelect,
    trainingDataValues,
    updateTrainingData,
    uploadFile,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();

    const { model, id } = match.params;

    useEffect(() => fetchTags(), []);

    useEffect(() => {
        if (model && id) fetchTrainingData(id, model);

        return () => reset();
    }, [id, model]);

    const locationsBreadcrumbs = [
        {
            label: t('Model', { count: 2 }),
            link: '/ai/fine-tuning',
        },
        {
            label: `${model}`,
            link: `/ai/fine-tuning/${model}`,
        },
    ];

    const handleOnSave = (formValues) => {
        updateTrainingData({ id, formValues, modelId: model });
    };

    const handleUploadFile = (file) => uploadFile({ id, modelId: model, file });

    const handleOpenChat = (event) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleSaveTags = (values) => {
        saveTrainingDataTags({ id, modelId: model, tags: values?.tags });
    };
    const handleCloseTagsForm = () => {
        setIsOpenTagsForm(false);
        resetTagsForm();
    };

    const openChat = Boolean(anchorEl);

    return (
        <>
            <Stack px={3}>
                <HeaderContainer>
                    <Typography variant="h4">
                        <TitleIcon
                            isSvgIcon={true}
                            svgIcon={PsychologyIcon}
                            title={t('Model', { count: 2 })}
                        />
                    </Typography>
                </HeaderContainer>

                <Stack
                    alignItems="center"
                    direction="row"
                    flexWrap="wrap"
                    gap={2}
                    justifyContent="space-between"
                    mb={3}
                    pl={3}
                >
                    <Stack>
                        <TitleIcon
                            isSvgIcon={true}
                            svgIcon={PsychologyIcon}
                            title={t('Training data', { count: 2 })}
                        />
                        <Breadcrumbs
                            current={{ label: id }}
                            isFetchingData={isFetchingTrainingData}
                            locations={locationsBreadcrumbs}
                        />
                    </Stack>

                    <TagsHeader
                        tags={trainingDataTags}
                        handleEditTags={() => prepareEditTags(trainingDataTags)}
                    />
                </Stack>

                <Content
                    disabled={isFetchingTrainingData}
                    handleOnSave={handleOnSave}
                    handleUploadFile={handleUploadFile}
                    isFetchingTrainingData={isFetchingTrainingData}
                    trainingDataValues={trainingDataValues}
                />
            </Stack>

            <Popper open={openChat} anchorEl={anchorEl}>
                <Box
                    sx={{
                        backgroundColor: 'background.paper',
                        boxShadow: 3,
                        height: '500px',
                        mb: 2,
                        mr: 3,
                        width: '300px',
                    }}
                >
                    <ChatContainer />
                </Box>
            </Popper>
            <IconButton
                onClick={handleOpenChat}
                sx={{
                    backgroundColor: 'primary.main',
                    bottom: '20px',
                    color: 'white',
                    position: 'fixed',
                    right: '20px',
                    zIndex: 3,
                    '&:hover': { backgroundColor: 'primary.dark' },
                }}
            >
                <InsertCommentIcon fontSize="large" />
            </IconButton>

            <EditTagsModal
                handleCloseModal={handleCloseTagsForm}
                isOpenModal={isOpenTagsForm}
                onSubmit={handleSaveTags}
                initialValues={tagsFormInitialValues}
                tagsOptions={trainingDataTagsForSelect}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingTrainingData: selectors.getTrainingDataIsFetching,
    isOpenTagsForm: selectors.getIsOpenTagsForm,
    tagsFormInitialValues: selectors.getTagsFormInitialValues,
    trainingDataTags: selectors.getTrainingDataTags,
    trainingDataTagsForSelect: selectors.getFetchTrainingDataTagsForSelect,
    trainingDataValues: selectors.getTrainingDataValues,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTrainingData: (id, modelId) =>
        dispatch(actions.fetchTrainingData(id, modelId)),
    fetchTags: (id, modelId) => dispatch(actions.fetchTags(id, modelId)),
    prepareEditTags: (tags) => dispatch(actions.prepareEditTags(tags)),
    reset: () => dispatch(actions.reset()),
    resetTagsForm: () => dispatch(actions.resetTagsForm()),
    saveTrainingDataTags: ({ id, modelId, tags }) =>
        dispatch(actions.saveTrainingDataTags({ id, modelId, tags })),
    setIsOpenTagsForm: (isOpen) => dispatch(actions.setIsOpenTagsForm(isOpen)),
    updateTrainingData: ({ id, formValues, modelId }) =>
        dispatch(actions.updateTrainingData({ id, formValues, modelId })),
    uploadFile: ({ id, modelId, file }) =>
        dispatch(actions.uploadFile({ id, modelId, file })),
});

Container.propTypes = {
    fetchTags: PropTypes.func,
    fetchTrainingData: PropTypes.func,
    isFetchingTrainingData: PropTypes.bool,
    isOpenTagsForm: PropTypes.bool,
    match: PropTypes.object,
    prepareEditTags: PropTypes.func,
    reset: PropTypes.func,
    resetTagsForm: PropTypes.func,
    saveTrainingDataTags: PropTypes.func,
    setIsOpenTagsForm: PropTypes.func,
    tagsFormInitialValues: PropTypes.object,
    trainingDataTags: PropTypes.array,
    trainingDataTagsForSelect: PropTypes.array,
    trainingDataValues: PropTypes.array,
    updateTrainingData: PropTypes.func,
    uploadFile: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
