import isEmpty from 'lodash/isEmpty';

export default (values = {}) => {
    const errors = {};

    if (isEmpty(values.old_password)) {
        errors.old_password = 'Contraseña actual no puede ser vacío.';
    }

    if (isEmpty(values.new_password)) {
        errors.new_password = 'Contraseña no puede ser vacío.';
    }

    if (isEmpty(values.confirm_password)) {
        errors.confirm_password = 'Confirmar contraseña no puede ser vacío.';
    }

    if (!isEmpty(values.new_password) && !isEmpty(values.confirm_password)) {
        if (values.new_password !== values.confirm_password) {
            errors.confirm_password = 'Confirmar contraseña no coincide.';
        }
    }

    return errors;
};
