import { NAME } from './constants';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';

export const getModel = state => {
    return state[NAME];
};

export const getInitialValues = createSelector(
    getModel,
    model => model.initialValues
);

export const getSave = createSelector(
    getModel,
    model => model.save
);

export const getIsSaving = createSelector(
    getSave,
    save => save.isSaving
);

export const getDataSave = createSelector(
    getSave,
    save => save.data
);

export const getErrorsSave = createSelector(
    getSave,
    save => save.errors
);

export const getValues = createSelector(
    state => getFormValues(`${NAME}/form`)(state),
    values => values || {}
);


export const getValidPasswordConfirmationLength = createSelector(
    getModel,
    model => model.validPasswordConfirmationLength
);

export const getValidPassword = createSelector(
    getModel,
    model => model.validPassword
);

export const getValidPasswordConfirmation = createSelector(
    getModel,
    model => model.validPasswordConfirmation
);