import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DropdownCardMenu } from 'common/components';
import { ReactComponent as TrainIcon } from 'common/resources/icoWorkout.svg';

import { MODEL_STATUS_KEYS } from '../constants';

const StyledTrainIcon = styled(TrainIcon)(({ theme }) => ({
    display: 'inline-block',
    fontSize: '1.25rem',
    padding: '0.1em',
    height: '1em',
    width: '1em',
    '& path': { fill: theme.palette.mode === 'dark' ? '#fff' : '#212b36ff' },
}));

const DropdownModelActions = ({
    createTrainingDocument,
    downloadTrainingDocument,
    model,
    prepareDeleteModel,
    prepareDuplicateModel,
    prepareStartTrainingModel,
    prepareStopTrainingModel,
    prepareUpdateModel,
}) => {
    const { t } = useTranslation();

    const modelCanBeEdited =
        model.status !== MODEL_STATUS_KEYS.TRAINING &&
        model.status !== MODEL_STATUS_KEYS.TRAINED;
    const modelCanBeTrained =
        modelCanBeEdited && model.status !== MODEL_STATUS_KEYS.INACTIVE;

    return (
        <DropdownCardMenu
            items={[
                {
                    disabled: !modelCanBeTrained,
                    handleClick: () => prepareStartTrainingModel(model.id),
                    icon: <StyledTrainIcon />,
                    label: t('Train'),
                    visible: model.status !== MODEL_STATUS_KEYS.TRAINING,
                },
                {
                    handleClick: () => prepareStopTrainingModel(model.id),
                    icon: <CancelIcon fontSize="small" />,
                    label: t('Stop training'),
                    visible: model.status === MODEL_STATUS_KEYS.TRAINING,
                },
                {
                    disabled: !modelCanBeEdited,
                    handleClick: () => createTrainingDocument(model.id),
                    icon: <FileUploadIcon fontSize="medium" />,
                    label: t('Upload file', { count: 2 }),
                },
                {
                    disabled: !model.training_jsonl,
                    handleClick: () =>
                        downloadTrainingDocument(model.training_jsonl),
                    icon: <DownloadIcon fontSize="small" />,
                    label: t('Download file'),
                },
                {
                    handleClick: () => prepareUpdateModel(model),
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                },
                {
                    handleClick: () => prepareDuplicateModel(model),
                    icon: <FileCopyIcon fontSize="small" />,
                    label: t('Duplicate'),
                },
                {
                    disabled: model.status === MODEL_STATUS_KEYS.TRAINING,
                    handleClick: () => prepareDeleteModel(model.id),
                    icon: <DeleteIcon fontSize="small" />,
                    label: t('Delete'),
                },
            ]}
        />
    );
};

DropdownModelActions.propTypes = {
    createTrainingDocument: PropTypes.func,
    downloadTrainingDocument: PropTypes.func,
    model: PropTypes.object,
    prepareDeleteModel: PropTypes.func,
    prepareDuplicateModel: PropTypes.func,
    prepareStartTrainingModel: PropTypes.func,
    prepareStopTrainingModel: PropTypes.func,
    prepareUpdateModel: PropTypes.func,
};

export default DropdownModelActions;
