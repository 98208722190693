import showForm from 'common/modules/productDatasheet/actions/showForm';

import fetchBrands from './fetchBrands';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleProductForm from './toggleProductForm';

export default () => (dispatch) => {
    dispatch(fetchBrands(''));
    dispatch(toggleProductForm(true));
    dispatch(setIsNewRecord(true));
    dispatch(setEnabledEdition(true));
    dispatch(resetForm());
    dispatch(showForm(true));
};
