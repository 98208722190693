import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, SvgIcon, Stack, Typography } from 'common/ui';

const StyledImage = styled.img`
    margin-right: 10px;
    max-width: 100%;
`;

const FontIcon = styled.i`
    color: #ff9a00;
    font-size: 21px;
    margin-right: 15px;
`;

const renderIcon = (isSvgIcon, svgIcon, imgUrl, isFontIcon) => {
    if (isSvgIcon && svgIcon)
        return (
            <Box
                component="div"
                sx={{
                    marginRight: '8px',
                    lineHeight: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    'svg .shape': {
                        fill: (theme) => theme.palette['primary'].main,
                    },
                    'svg path': {
                        fill: (theme) => theme.palette['primary'].main,
                    },
                }}
            >
                <SvgIcon
                    component={svgIcon}
                    fontSize="inherit"
                    sx={{
                        width: '1.2em',
                        height: '1.2em',
                    }}
                />
            </Box>
        );
    if (!imgUrl) return null;
    if (isFontIcon) return <FontIcon className={`fa ${imgUrl}`} />;

    return <StyledImage src={imgUrl} width={19} />;
};

const TitleIcon = ({
    imgUrl,
    isFontIcon,
    isSvgIcon,
    svgIcon,
    title,
    variant = 'inherit',
}) => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
        >
            {renderIcon(isSvgIcon, svgIcon, imgUrl, isFontIcon)}

            <Typography gutterBottom variant={variant}>
                {title}
            </Typography>
        </Stack>
    );
};

TitleIcon.propTypes = {
    imgUrl: PropTypes.string,
    isFontIcon: PropTypes.bool,
    isSvgIcon: PropTypes.bool,
    svgIcon: PropTypes.elementType,
    title: PropTypes.string,
    variant: PropTypes.string,
};

export default TitleIcon;
