import i18next from 'i18next';

import { getRandomPassword } from 'common/api/auth';
import showToast from 'common/utils/showToast';

import {
    FETCH_TEMPORAL_PASSWORD,
    FETCH_TEMPORAL_PASSWORD_FAILURE,
    FETCH_TEMPORAL_PASSWORD_SUCCESS,
} from '../actionTypes';

export default (onSuccessCallback) => (dispatch) => {
    dispatch({ type: FETCH_TEMPORAL_PASSWORD });

    getRandomPassword()
        .then((response) => {
            dispatch({
                type: FETCH_TEMPORAL_PASSWORD_SUCCESS,
                payload: response.data.password,
            });

            if (onSuccessCallback) onSuccessCallback(response.data.password);
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEMPORAL_PASSWORD_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t('There was an error generating the password'),
            });
        });
};
