import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Autocomplete, TextField } from 'common/ui';
import { arraytoDictionary } from 'common/utils/helpers';

const AutocompleteField = ({
    disabled,
    label,
    onChange,
    onInputChange,
    options,
    value,
}) => {
    const [currentValue, setCurrentValue] = useState(null);

    useEffect(() => {
        if (isEmpty(value)) return;
        const dictionary = arraytoDictionary(options, 'value');
        setCurrentValue(dictionary[value]);
    }, [value]);

    return (
        <Autocomplete
            autoHighlight
            disabled={disabled}
            isOptionEqualToValue={(option, selected) =>
                option?.value === selected.value
            }
            onChange={(_, newValue) => {
                if (newValue) onChange(newValue?.value);
            }}
            onInputChange={(_, newInputValue, reason) => {
                if (onInputChange) onInputChange(newInputValue);
                if (reason !== 'reset') setCurrentValue(newInputValue);
                if (reason === 'clear') {
                    setCurrentValue(null);
                    onChange('');
                }
            }}
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                    }}
                    variant="standard"
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={`item-${Math.random()}`}>
                    {option.label}
                </li>
            )}
            value={currentValue}
        />
    );
};

AutocompleteField.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.string,
};

export default AutocompleteField;
