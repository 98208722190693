import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { ReduxCheckbox, Checkboxes } from 'react-form-checkbox';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors, TextVariant } from 'common/components';
import {
    ReduxFieldInput,
    ReduxFieldSelect,
    ReduxFieldSlider,
} from 'common/components/form/redux';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
} from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import ReduxFieldArray from './ReduxFieldArray';

const checkboxItem = ({ input, label }) => {
    return (
        <FormControl component="fieldset" variant="standard">
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="info"
                            disabled={true}
                            {...input}
                            checked={input.checked < 0 ? true : false}
                        />
                    }
                    label={label}
                    sx={{
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                />
            </FormGroup>
        </FormControl>
    );
};

const PlanForm = ({
    closeEditionForm,
    errors,
    formValues,
    getInverters,
    getPanels,
    handleClickSave,
    handleSubmit,
    inverters,
    isFetchingDetail,
    isFetchingInverters,
    isFetchingPanels,
    isSaving,
    panels,
    pristine,
    reset,
    resetForm,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            resetForm();
        };
    }, []);

    useEffect(() => {
        if (formValues.company_id && formValues.id) {
            getPanels(formValues.company_id, formValues.id);
            getInverters(formValues.company_id, formValues.id);
        }
    }, [formValues.id]);

    return (
        <form>
            <Stack pt={2}>
                <Field
                    name="name"
                    component={ReduxFieldInput}
                    placeholder={t('Name')}
                    variant="outlined"
                    disabled={isFetchingDetail || !formValues.enabledEdition}
                />
            </Stack>

            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} md={6}>
                    <Field
                        name="branch_office"
                        component={ReduxFieldInput}
                        placeholder={t('Branch office', { count: 1 })}
                        variant="outlined"
                        disabled={
                            isFetchingDetail || !formValues.enabledEdition
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field
                        name="custom_offer_template"
                        component={ReduxFieldInput}
                        placeholder={t('Template', { count: 1 })}
                        variant="outlined"
                        disabled={
                            isFetchingDetail || !formValues.enabledEdition
                        }
                    />
                </Grid>
            </Grid>

            <Stack pt={2}>
                <TextVariant variant="bold" text={t('Type of installation')} />

                <Stack direction="row" spacing={2} pt={1} className="">
                    <Field
                        name="installation_type"
                        component={ReduxCheckbox(Checkboxes)}
                        itemComponent={checkboxItem}
                        data={[
                            {
                                label: t('Residential'),
                                value: 0,
                            },
                            { label: t('Commercial'), value: 1 },
                            { label: t('Industrial'), value: 2 },
                        ]}
                    />
                </Stack>
            </Stack>

            <Stack pt={2}>
                <Stack spacing={3}>
                    <TextVariant
                        dot="default"
                        variant="bold"
                        text={t('Solar panel model')}
                    />

                    <Field
                        name="panel"
                        component={ReduxFieldSelect}
                        disabled={
                            isFetchingDetail ||
                            !formValues.branch_office ||
                            isFetchingPanels
                        }
                        label={t('Panel', { count: 1 })}
                        variant="outlined"
                        options={panels}
                        required
                    />
                </Stack>

                <Stack spacing={3} pt={2}>
                    <TextVariant
                        dot="blue"
                        variant="bold"
                        text={t('Investor brand')}
                    />
                    <Field
                        name="inverter_brand"
                        component={ReduxFieldSelect}
                        disabled={
                            isFetchingDetail ||
                            !formValues.branch_office ||
                            isFetchingInverters
                        }
                        label={t('Inverter', { count: 1 })}
                        variant="outlined"
                        options={inverters}
                        required
                    />
                </Stack>

                <Stack pt={2}>
                    <FieldArray
                        component={ReduxFieldArray}
                        disabled={
                            isFetchingDetail || !formValues.enabledEdition
                        }
                        dot="orange"
                        label={t('Accessory', { count: 2 })}
                        placeholder={t('Accessory', { count: 1 })}
                        name="accessories"
                    />
                </Stack>

                <Stack spacing={3} pt={2}>
                    <TextVariant
                        dot="green"
                        variant="bold"
                        text={t('Structure', { count: 1 })}
                    />
                    <Field
                        name="structure"
                        component={ReduxFieldInput}
                        placeholder={t('Structure', { count: 1 })}
                        variant="outlined"
                        disabled={
                            isFetchingDetail || !formValues.enabledEdition
                        }
                    />
                </Stack>

                <Stack pt={2}>
                    <FieldArray
                        component={ReduxFieldArray}
                        disabled={
                            isFetchingDetail || !formValues.enabledEdition
                        }
                        dot="purple"
                        label={t('Workforce and electrical equipment')}
                        placeholder={t('Workforce and electrical equipment')}
                        name="workforce_and_electric_materials"
                    />
                </Stack>

                <Stack pt={2}>
                    <FieldArray
                        component={ReduxFieldArray}
                        disabled={
                            isFetchingDetail || !formValues.enabledEdition
                        }
                        dot="bittersweet"
                        label={t('Additional', { count: 2 })}
                        placeholder={t('Additional', { count: 1 })}
                        name="additionals"
                    />
                </Stack>
            </Stack>

            <Stack spacing={3} pt={2}>
                <TextVariant
                    variant="bold"
                    text={t('Energy saving percentage')}
                />

                <Field
                    name="saving_percentage"
                    component={ReduxFieldSlider}
                    min={0}
                    max={100}
                    marks={[
                        {
                            value: 0,
                            label: '0',
                        },
                        {
                            value: 50,
                            label: '50%',
                        },
                        {
                            value: 100,
                            label: '100%',
                        },
                    ]}
                    disabled={isFetchingDetail || !formValues.enabledEdition}
                />
            </Stack>

            <ShowErrors
                errors={errors}
                mt={3}
                severity="error"
                variant="filled"
            />

            {!pristine && (
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={3}
                >
                    <Grid item>
                        <Button
                            color="secondary"
                            disabled={isFetchingDetail || isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={() => {
                                closeEditionForm();
                                reset();
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isFetchingDetail || isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={handleSubmit(handleClickSave)}
                            type="submit"
                        >
                            {isSaving ? `${t('Loading')}...` : t('Save')}
                        </Button>
                    </Grid>
                </Stack>
            )}
        </form>
    );
};

PlanForm.propTypes = {
    closeEditionForm: PropTypes.func,
    errors: PropTypes.array,
    formValues: PropTypes.object,
    getInverters: PropTypes.func,
    getPanels: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    inverters: PropTypes.array,
    isFetchingDetail: PropTypes.bool,
    isFetchingInverters: PropTypes.bool,
    isFetchingPanels: PropTypes.bool,
    isSaving: PropTypes.bool,
    panels: PropTypes.array,
    pristine: PropTypes.bool,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSaveItem,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    inverters: selectors.getDataFetchInverters,
    isFetchingDetail: selectors.getIsFetchingPlanDetail,
    isFetchingInverters: selectors.getIsFetchingInverters,
    isFetchingPanels: selectors.getIsFetchingPanels,
    isSaving: selectors.getIsSavingItem,
    panels: selectors.getDataFetchPanels,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    closeEditionForm: () => dispatch(actions.showEditionForm(false)),
    getPanels: (companyId, planId) =>
        dispatch(actions.getPanels(companyId, planId)),
    getInverters: (companyId, planId) =>
        dispatch(actions.getInverters(companyId, planId)),
    handleClickSave: (values) => dispatch(actions.save(values)),
    resetForm: () => dispatch(actions.resetForm()),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(PlanForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
