import i18next from 'i18next';
import get from 'lodash/get';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { arraytoDictionary } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getUsersModel = createSelector(getModel, (model) => model.users);

export const getUsersData = createSelector(getUsersModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((user) => ({
              id: user.id,
              full_name: user.full_name,
              email: user.email,
              company_group_name: get(user, 'company_group.name', ''),
              company_group_level: get(user, 'company_group.level', ''),
              company_group: user.company_group,
              branch_office_name: user?.branch_office?.name,
              branch_office: user.branch_office,
              branch_offices: user.branch_offices,
              created_at: user.created_at,
              is_enabled: {
                  status: user.is_enabled
                      ? i18next.t('Active')
                      : i18next.t('Inactive'),
                  has_tmp_password: user.has_tmp_password,
              },
          }))
);

export const getUsersErrors = createSelector(
    getUsersModel,
    (model) => model.errors
);

export const getUsersIsFetching = createSelector(
    getUsersModel,
    (model) => model.isFetching
);

export const getUsersPagination = createSelector(
    getUsersModel,
    (model) => model.data.pagination || {}
);

export const getUsersCSV = createSelector(
    getModel,
    (model) => model.downloadCSV
);

export const getIsFetchingUsersCSV = createSelector(
    getUsersCSV,
    (downloadCSV) => downloadCSV.isFetching
);

export const getIsOpenModalUser = createSelector(
    getModel,
    (model) => model.isOpenModalUser || false
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getUserDetail = createSelector(
    getModel,
    (model) => model.userDetail
);

export const getIsFetchingUserDetail = createSelector(
    getUserDetail,
    (userDetail) => userDetail.isFetching
);

export const getRandomPasswordModel = createSelector(
    getModel,
    (model) => model.randomPassword
);

export const getIsFetchingRandomPassword = createSelector(
    getRandomPasswordModel,
    (randomPassword) => randomPassword.isFetching
);

export const getDataRandomPassword = createSelector(
    getRandomPasswordModel,
    (randomPassword) => randomPassword.data
);

export const getFilters = createSelector(getModel, (model) => model.filters);

export const getDeleteUser = createSelector(
    getModel,
    (model) => model.deleteUser
);

export const getIsDeletingUser = createSelector(
    getDeleteUser,
    (deleteUser) => deleteUser.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteUser,
    (deleteUser) => deleteUser.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteUser,
    (deleteUser) => deleteUser.errors
);

export const getFetchRoles = createSelector(
    getModel,
    (model) => model.fetchRoles
);

export const getIsFetchingRoles = createSelector(
    getFetchRoles,
    (fetchRoles) => fetchRoles.isFetching
);

export const getDataFetchRoles = createSelector(
    getFetchRoles,
    (fetchRoles) => fetchRoles.data || []
);

export const getDictionaryFetchRoles = createSelector(
    getDataFetchRoles,
    (roles) => arraytoDictionary(roles, 'id')
);

export const getErrorsFetchRoles = createSelector(
    getFetchRoles,
    (fetchRoles) => fetchRoles.errors
);

export const getFetchBranchOffices = createSelector(
    getModel,
    (model) => model.fetchBranchOffices
);

export const getIsFetchingBranchOffices = createSelector(
    getFetchBranchOffices,
    (fetchBranchOffices) => fetchBranchOffices.isFetching
);

export const getDataFetchBranchOffices = createSelector(
    getFetchBranchOffices,
    (fetchBranchOffices) => fetchBranchOffices.data || []
);

export const getErrorsFetchBranchOffices = createSelector(
    getFetchBranchOffices,
    (fetchBranchOffices) => fetchBranchOffices.errors
);

export const getBranchesItemsSelect = createSelector(
    getDataFetchBranchOffices,
    (data) => [
        ...data
            .filter((item) => item.id !== 'all')
            .map((item) => ({
                label: item.name,
                optionValue: item.id,
            })),
    ]
);
