import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormCheck,
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { Button, Tab, Tabs } from 'common/ui';

import ColumnWrapper from '../ColumnWrapper';

import CurrentPeriod from './CurrentPeriod';
import FieldArrayConcepts from './FieldArrayConcepts';
import History from './History';

const TabPanel = ({ children, selectedTab, value }) => {
    if (value !== selectedTab) return null;
    return <ColumnWrapper>{children}</ColumnWrapper>;
};

TabPanel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    selectedTab: PropTypes.string,
    value: PropTypes.string,
};

const Form = ({ control, disabled, baseName, page, setValue }) => {
    const [selectedTab, setSelectedTab] = useState('tab-general');
    const [hasCurrentPeriod, hasHistory, unitPrefix] = useWatch({
        control,
        name: [
            `tabs.${page}.has_current_period`,
            `tabs.${page}.has_history`,
            `${baseName}.unit_prefix`,
        ],
    });

    const { t } = useTranslation();

    const handleDeleteSection = (indicator, formName) => {
        setSelectedTab('tab-general');
        setValue(formName, null);
        setValue(`tabs.${page}.${indicator}`, false);
    };

    return (
        <>
            <Tabs
                onChange={(_, newValue) => setSelectedTab(newValue)}
                scrollButtons="auto"
                value={selectedTab}
                variant="scrollable"
                sx={{ borderBottom: 1, borderColor: 'divider', px: 1 }}
            >
                <Tab
                    key="tab-general"
                    id="tab-general"
                    label={t('General')}
                    value="tab-general"
                />

                {hasCurrentPeriod && (
                    <Tab
                        key="tab-current-period"
                        id="tab-current-period"
                        label={t('Current period')}
                        value="tab-current-period"
                    />
                )}

                {hasHistory && (
                    <Tab
                        key="tab-history"
                        id="tab-history"
                        label={t('History')}
                        value="tab-history"
                    />
                )}
            </Tabs>

            <TabPanel selectedTab={selectedTab} value="tab-general">
                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.name`}
                    label={t('Name')}
                    name={`${baseName}.name`}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.service_number`}
                    label={t('Service number')}
                    name={`${baseName}.service_number`}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.address`}
                    label={t('Address')}
                    name={`${baseName}.address`}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.zip_code`}
                    label={t('Zip code')}
                    name={`${baseName}.zip_code`}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.rate`}
                    label={t('Rate name')}
                    name={`${baseName}.rate`}
                />

                <ReactHookFormSelect
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.is_bimonthly`}
                    label={t('Billing period')}
                    name={`${baseName}.is_bimonthly`}
                    options={[
                        { label: t('Bimonthly'), value: true },
                        { label: t('Monthly'), value: false },
                    ]}
                    sxFormControl={{ size: 'medium' }}
                />

                <ReactHookFormInput
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.unit_prefix`}
                    label={t('Unit prefix')}
                    name={`${baseName}.unit_prefix`}
                />

                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    decimalsLimit={2}
                    disabled={disabled}
                    fullWidth
                    key={`${baseName}.applied_credit`}
                    label={t('Applied credit')}
                    name={`${baseName}.applied_credit`}
                />

                <ReactHookFormCheck
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.has_low_tension_concept`}
                    label={`2% ${t('Low tension')}`}
                    name={`${baseName}.has_low_tension_concept`}
                />

                <FieldArrayConcepts
                    control={control}
                    disabled={disabled}
                    key={`${baseName}.contracted_demand`}
                    name={`${baseName}.contracted_demand`}
                    title={t('Contracted demand')}
                    unit={`${unitPrefix || ''}W`}
                />

                {!hasCurrentPeriod && (
                    <Button
                        onClick={() =>
                            setValue(`tabs.${page}.has_current_period`, true)
                        }
                        variant="outlined"
                        sx={{ mt: 'auto' }}
                    >
                        {t('Add current period')}
                    </Button>
                )}

                {!hasHistory && (
                    <Button
                        onClick={() =>
                            setValue(`tabs.${page}.has_history`, true)
                        }
                        variant="outlined"
                        sx={{ mt: hasCurrentPeriod ? 'auto' : 0 }}
                    >
                        {t('Add history')}
                    </Button>
                )}
            </TabPanel>

            {hasCurrentPeriod && (
                <TabPanel selectedTab={selectedTab} value="tab-current-period">
                    <CurrentPeriod
                        baseName={`${baseName}.values`}
                        control={control}
                        disabled={disabled}
                        handleDeleteSection={handleDeleteSection}
                        key={`${baseName}.values`}
                        unitPrefix={unitPrefix || ''}
                    />
                </TabPanel>
            )}

            {hasHistory && (
                <TabPanel selectedTab={selectedTab} value="tab-history">
                    <History
                        control={control}
                        disabled={disabled}
                        handleDeleteSection={handleDeleteSection}
                        key={`${baseName}.history`}
                        name={`${baseName}.history`}
                        unitPrefix={unitPrefix || ''}
                    />
                </TabPanel>
            )}
        </>
    );
};

Form.propTypes = {
    baseName: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    page: PropTypes.number,
    setValue: PropTypes.func,
};

export default Form;
