import i18next from 'i18next';
import { get, isArray, isEmpty, isObject, isString } from 'lodash';

import { DO_NOT_ASSIGN_VALUE, SUBSCRIPTION_STATUS } from 'common/constants';
import alerts from 'common/modules/alerts';
import { formatDate, parseISODate } from 'common/utils/dates';
import showToast from 'common/utils/showToast';

import { getDateFormatByLocale } from './multiregion';

const dataImageRegex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const accesoryTypeToLabel = (accesoryType) => {
    const accesoriesType = {
        0: i18next.t('Monitoring'),
        1: i18next.t('Wire'),
        2: i18next.t('Optimizer'),
        3: i18next.t('Connector'),
        4: i18next.t('Transformer'),
    };
    return accesoriesType[accesoryType];
};

export const arraytoDictionary = (array, key) => {
    if (!Array.isArray(array)) return {};
    return array.reduce(
        (acc, current) => ({ ...acc, [current[key]]: current }),
        {}
    );
};

export const batteryTypeToLabel = (panelType) => {
    const panelTypes = { 0: i18next.t('Unit'), 1: i18next.t('Kit') };
    return panelTypes[panelType];
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const countDecimals = (value) => {
    const newValue = parseExponentialNumber(value);
    let decimalsNumber = 0;
    if (!isNaN(parseFloat(newValue))) {
        const parts = newValue.toString().split('.');
        if (parts[1]) decimalsNumber = parts[1].length;
        return decimalsNumber;
    }
    return 0;
};

export const dictionaryToArray = (dictionary) =>
    Object.keys(dictionary).map((key) => dictionary[key]);

export const formatISODate = (isoDate, locale, format) => {
    if (!isoDate) return '';
    return formatDate(
        parseISODate(isoDate),
        format || `${getDateFormatByLocale()} HH:mm`,
        locale
    );
};

export const generate_uuid = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
    return uuid;
};

export const getAgentOptions = (agents) => {
    const items = agents
        .filter((agent) => agent.is_active && agent.is_enabled)
        .map((item) => {
            return { label: item.full_name, optionValue: item.id };
        });
    return [
        {
            label: i18next.t('All', { context: 'male', count: 2 }),
            optionValue: '',
        },
        {
            label: i18next.t('Without solar advisor'),
            optionValue: DO_NOT_ASSIGN_VALUE,
        },
        ...items,
    ];
};

export const getDataGridClasses = (isDarkMode) => ({
    '& .MuiDataGrid-row.even': { backgroundColor: '#f7fcff', color: '#000' },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        backgroundColor: 'rgba(255, 154, 0, 0.16)',
    },
    '& .MuiDataGrid-row--editing:hover .MuiDataGrid-cell': {
        backgroundColor: 'rgba(255, 154, 0, 0.16)',
    },
    '& .MuiInputBase-formControl input': {
        border: 0,
        outline: 'none',
        boxShadow: 'none',
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#3A647f',
        color: '#fff',
    },
    '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root .MuiSvgIcon-root': {
        fill: '#fff',
    },
    '& .MuiDataGrid-virtualScroller': {
        overflowY: 'hidden',
    },
    ...(isDarkMode && {
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'transparent !important',
            color: '#fff',
        },
        '& .MuiDataGrid-row.even': { backgroundColor: '#000', color: '#fff' },
        '& .MuiDataGrid-row.even .MuiLink-root': { color: '#fff' },
        '& .MuiDataGrid-row.odd .MuiLink-root': { color: '#fff' },
    }),
});

export const getDecimals = (value) => {
    if (!value) return 0;
    const decimals = countDecimals(value);
    if (decimals <= 1) return 1;
    if (decimals >= 2 && decimals <= 4) return decimals;
    return 4;
};

export const getFileExtensionByPath = (url) => {
    if (!url) return '';
    if (url.indexOf('.', url.lastIndexOf('/') + 1) === -1) {
        return '';
    }
    return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const getFileNameByPath = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
};

const stringToColor = (string) => {
    let hash = 0;
    let color = '';
    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return `#${color}`;
};

export const getProductBrandLabel = (brand) => {
    if (!isEmpty(brand)) {
        return `${get(brand, 'name', '')} (${get(brand, 'country.name', '')})`;
    }
    return '';
};

export const getTranslatedAddonLabel = (addon) => {
    switch (addon) {
        case 'Cotizador-Web':
            return i18next.t('Web quoter');
        case 'Modulo-Sunwise-Analytics':
            return i18next.t('Analytics');
        case 'Sunwise-After-Sales':
            return i18next.t('After sales');
        case 'multi-branchs':
            return i18next.t('Multi-branch office', { count: 2 });
        case 'TS-PS':
            return i18next.t('Time shifting and peak saving');
        default:
            return '';
    }
};

export const getSubscriptionStatusDotColor = (status) => {
    const statusArray = Object.values(SUBSCRIPTION_STATUS);
    const statusDictionary = arraytoDictionary(statusArray, 'key');
    return statusDictionary[status]?.color;
};

export const handleFileURL = (url, preffix) => {
    const pattern = /^((https):\/\/)/;
    return pattern.test(url) ? url : `${preffix}${url}`;
};

export const inverterTypeToLabel = (panelType) => {
    const panelTypes = {
        0: i18next.t('Central'),
        1: i18next.t('String'),
        2: i18next.t('Micro inverter'),
        3: i18next.t('String-OPT'),
    };
    return panelTypes[panelType];
};

export const isImageBase64 = (string) => string.match(dataImageRegex);

export const isImageFileSupported = (pathName) =>
    ['png', 'jpeg', 'jpg'].includes(
        getFileExtensionByPath(pathName).toLowerCase()
    );

export const isImageUrl = (string) => string.match(urlRegex);

export const isJsonString = (str) => {
    let value = typeof item !== 'string' ? JSON.stringify(str) : str;
    try {
        value = JSON.parse(value);
    } catch (e) {
        return false;
    }

    return typeof value === 'object' && value !== null;
};

export const numberFormat = (value, options) => {
    const newValue = parseExponentialNumber(value);
    const {
        currency,
        decimals = getDecimals(value),
        locale = 'en-US',
        unit = '',
        style,
    } = options;
    let formatted = newValue;
    let unitText = '';
    switch (style) {
        case 'currency':
            formatted = new Intl.NumberFormat(locale, {
                style,
                currency,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(newValue);
            break;
        case 'decimal':
            formatted = new Intl.NumberFormat(locale, {
                style,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(newValue);
            unitText = unit;
            break;
        default:
            formatted = newValue;
            break;
    }
    return `${formatted} ${!isEmpty(unitText) ? unitText : ''}`;
};

export const panelTypeToLabel = (panelType) => {
    const panelTypes = {
        0: i18next.t('Monocrystalline'),
        1: i18next.t('Polycrystalline'),
        2: i18next.t('Thin Film'),
    };
    return panelTypes[panelType];
};

export const parseExponentialNumber = (value) => {
    if (!value) return 0;
    if (!(value.toString().indexOf('e') !== -1)) return value;
    const parts = value.toString().split('e-');
    if (parts[1]) {
        if (parts[1] > 100) return 0;
        return value.toFixed(parts[1]);
    }
    return value;
};

export const showReponseErrorsAsAlert = (dispatch, response) => {
    let errors = [];
    const responseErrors = get(response, 'data.errors', null);
    if (responseErrors) {
        if (isArray(responseErrors))
            errors = responseErrors.map((error) => error);
        else if (isString(responseErrors)) errors = [responseErrors];
        else if (isObject(responseErrors)) {
            Object.keys(responseErrors).forEach((key) => {
                if (isArray(responseErrors[key])) {
                    errors = [...errors, `${key} ${responseErrors[key][0]}`];
                }
                if (isString(responseErrors[key])) {
                    errors = [...errors, responseErrors[key]];
                }
            });
        }
    }
    if (!isEmpty(errors)) {
        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: errors,
            title: i18next.t('Error'),
            type: alerts.ALERT_TYPE_ALERT,
            variant: 'warning',
        };
        dispatch(alerts.actions.show(dataAlert));
    } else {
        showToast({
            type: 'error',
            body: i18next.t('An error occurred during the process'),
        });
    }
};

export const showFastApiErrorsAsAlert = (dispatch, response) => {
    const errors = response?.data?.detail;
    if (Array.isArray(errors) && errors.length > 0) {
        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: errors?.map((detail) => `${detail?.msg}`),
            title: i18next.t('Error'),
            type: alerts.ALERT_TYPE_ALERT,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    } else {
        showToast({
            body: i18next.t('An error occurred during the process'),
            type: 'error',
        });
    }
};

export const stringAvatar = (name) => {
    const nameArray = name.split(' ');
    let newName = '';
    if (nameArray.length === 1) newName = nameArray[0][0];
    else if (nameArray.length >= 2 && nameArray[1][0])
        newName = `${nameArray[0][0]}${nameArray[1][0]}`;
    return {
        sx: { bgcolor: stringToColor(name) },
        children: newName?.toUpperCase(),
    };
};

export const getStatusProposalLabel = (isGenerated) => {
    if (isGenerated) return i18next.t('Generated', { context: 'female' });
    return i18next.t('Draft');
};

// Utility function to calculate luminance
const getLuminance = (color) => {
    const rgb = color.match(/\w\w/g).map((c) => Number.parseInt(c, 16));
    const [r, g, b] = rgb.map((c) => {
        c /= 255;
        return c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4;
    });
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

// Utility function to get contrasting text color
export const getContrastingTextColor = (backgroundColor) => {
    const luminance = getLuminance(backgroundColor);
    return luminance > 0.5 ? 'black' : 'white';
};
