import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import { ReduxFieldNumberInput } from 'common/components/form/redux';
import { Stack } from 'common/ui';

import ItemPermission from './ItemPermission';
import SectionPermission from './SectionPermission';

const ProposerActions = ({
    changeInput,
    formValues,
    isDisabled,
    isFetchingDetail,
    proposerPermissions,
}) => {
    const { t } = useTranslation();
    return (
        <Stack pt={2} spacing={3} p={3}>
            <Field
                name="maximum_discount"
                component={ReduxFieldNumberInput}
                placeholder={t('Maximum total discount')}
                variant="outlined"
                required
                disabled={isFetchingDetail || isDisabled}
                contentEndAdornment="%"
            />
            <br />
            {proposerPermissions.map((category) => (
                <SectionPermission
                    categories={proposerPermissions}
                    category={category}
                    changeInput={changeInput}
                    checksNames={[
                        'proposer_groups_view',
                        'proposer_groups_add',
                        'proposer_groups_edit',
                        'proposer_groups_delete',
                    ]}
                    formValues={formValues}
                    index={proposerPermissions.indexOf(category)}
                    isDisabled={isDisabled}
                    key={`section-permission-${category.id}`}
                    label={category.name}
                >
                    {category.groups.map((group) => (
                        <ItemPermission
                            categories={proposerPermissions}
                            changeInput={changeInput}
                            checksNames={[
                                'proposer_groups_view',
                                'proposer_groups_add',
                                'proposer_groups_edit',
                                'proposer_groups_delete',
                            ]}
                            formValues={formValues}
                            group={group}
                            index={category.groups.indexOf(group)}
                            isDisabled={isDisabled}
                            key={`item-permission-${group.id}`}
                            label={`${group.name}`}
                        />
                    ))}
                </SectionPermission>
            ))}
        </Stack>
    );
};

ProposerActions.propTypes = {
    changeInput: PropTypes.func,
    formValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    isFetchingDetail: PropTypes.bool,
    proposerPermissions: PropTypes.array,
};

export default ProposerActions;
