import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import useDebounce from 'common/hooks/useDebounce';
import { TextField, InputAdornment } from 'common/ui';
import {
    getCountryCurrencyDecimalSeparator,
    getCountryCurrencyThousandSeparator,
} from 'common/utils/helpers/session';

const handleValue = (e) => {
    let newValue = '';
    if (e.target.value) {
        const [integerPart, decimalPart] = e.target.value.split(
            getCountryCurrencyDecimalSeparator()
        );
        let cleanedIntegerPart = '0';
        if (integerPart)
            cleanedIntegerPart = integerPart.replaceAll(
                getCountryCurrencyThousandSeparator(),
                ''
            );
        let cleanedNumber = `${parseInt(cleanedIntegerPart)}`;
        if (decimalPart) cleanedNumber = `${cleanedNumber}.${decimalPart}`;
        newValue = cleanedNumber;
    }
    return newValue;
};

const ReactHookFormIntlNumberInputConsumption = (props) => {
    const {
        allowDecimals,
        allowNegativeValue,
        append,
        autoFocus,
        control,
        dataIntercomTarget,
        debounceTime,
        decimalsLimit,
        disabled,
        fullWidth,
        label,
        max,
        min,
        name,
        onBlur,
        onChange,
        onPaste,
        prepend,
        readOnly,
        sx,
        tier,
        variant,
    } = props;
    const [event, setEvent] = useState({});
    const debouncedValue = useDebounce(event, debounceTime);
    const getIsAllowedValue = ({ floatValue }) => {
        const _max = parseFloat(max);
        const _min = parseFloat(min);
        return (_min && !isNaN(_min) && floatValue < _min) ||
            (_max && !isNaN(_max) && floatValue > _max)
            ? false
            : true;
    };
    const onChangeAction = (event) =>
        setEvent({
            ...event,
            target: {
                ...event.target,
                value: handleValue(event),
            },
        });

    const onBlurAction = (event, onBlur) => {
        const value = handleValue(event);
        if (onBlur) onBlur(event, value);
    };

    const onPasteAction = (event, onPaste) => {
        const value = handleValue(event);
        if (onPaste) onPaste(event, value);
    };

    useEffect(() => {
        if (onChange && !isEmpty(event)) return onChange(event);
    }, [debouncedValue]);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <NumberFormat
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        endAdornment: append && (
                            <InputAdornment position="end">
                                {append}
                            </InputAdornment>
                        ),
                        readOnly: readOnly,
                        startAdornment: prepend && (
                            <InputAdornment position="start">
                                {prepend}
                            </InputAdornment>
                        ),
                        inputRef: field.ref,
                    }}
                    allowNegative={allowNegativeValue}
                    autoFocus={autoFocus}
                    customInput={TextField}
                    data-intercom-target={dataIntercomTarget}
                    decimalScale={!allowDecimals ? 0 : decimalsLimit}
                    decimalSeparator={getCountryCurrencyDecimalSeparator()}
                    disabled={disabled}
                    error={Boolean(error)}
                    fullWidth={fullWidth}
                    helperText={error?.message}
                    isAllowed={getIsAllowedValue}
                    isNumericString
                    label={label}
                    onBlur={(e) => onBlurAction(e, onBlur)}
                    onChange={onChangeAction}
                    onPaste={(e) => onPasteAction(e, onPaste)}
                    onValueChange={(target) =>
                        field.onChange({
                            label: label,
                            placeholder: target.value,
                            tier: tier,
                            value: target.value,
                        })
                    }
                    placeholder={field.value?.placeholder}
                    sx={sx}
                    thousandSeparator={getCountryCurrencyThousandSeparator()}
                    value={field.value?.value}
                    variant={variant}
                />
            )}
        />
    );
};

ReactHookFormIntlNumberInputConsumption.defaultProps = {
    allowDecimals: true,
    allowNegativeValue: false,
    autoFocus: false,
    debounceTime: 0,
    decimalsLimit: 16,
    step: 1,
};

ReactHookFormIntlNumberInputConsumption.propTypes = {
    allowDecimals: PropTypes.bool,
    allowNegativeValue: PropTypes.bool,
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoFocus: PropTypes.bool,
    control: PropTypes.object,
    dataIntercomTarget: PropTypes.string,
    debounceTime: PropTypes.number,
    decimalsLimit: PropTypes.number,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onPaste: PropTypes.func,
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    readOnly: PropTypes.bool,
    sx: PropTypes.object,
    tier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    variant: PropTypes.string,
};

export default ReactHookFormIntlNumberInputConsumption;
