import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Card, Grid } from 'common/ui';

import CompletionColumn from './CompletionColumn';
import ElectricBillColumn from './ElectricBillColumn';
import Placeholder from './PlaceholderCard';

const StyledGridItem = styled(Grid)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    gap: 4px;
    min-height: 550px;
`;

const Content = ({
    disabled,
    handleOnSave,
    handleUploadFile,
    isFetchingTrainingData,
    trainingDataValues = [],
}) => {
    const [page, setPage] = useState(0);
    if (isFetchingTrainingData) return <Placeholder />;

    const pageOptions = trainingDataValues?.map((_, i) => ({
        label: i + 1,
        optionValue: i,
    }));

    return (
        <Card sx={{ overflow: 'visible' }}>
            <Grid container p={3} spacing={2} columns={16}>
                <StyledGridItem item xs={16} md={8}>
                    <ElectricBillColumn
                        handleUploadFile={handleUploadFile}
                        page={page}
                        pageOptions={pageOptions}
                        setPage={setPage}
                        trainingDataValues={trainingDataValues}
                    />
                </StyledGridItem>

                <StyledGridItem item xs={16} md={8}>
                    <CompletionColumn
                        disabled={disabled}
                        handleOnSave={handleOnSave}
                        page={page}
                    />
                </StyledGridItem>
            </Grid>
        </Card>
    );
};

Content.propTypes = {
    disabled: PropTypes.bool,
    handleOnSave: PropTypes.func,
    handleUploadFile: PropTypes.func,
    isFetchingTrainingData: PropTypes.bool,
    trainingDataValues: PropTypes.array,
};

export default Content;
