import React, { useRef } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DropdownCardMenu from 'common/components/DropdownCardMenu';
import { MAX_FILE_SIZE } from 'common/constants';
import { Box } from 'common/ui';
import showToast from 'common/utils/showToast';

const FileInput = styled.input.attrs(() => ({
    type: 'file',
    accept: '.csv,text/csv,application/vnd.ms-excel',
}))`
    cursor: pointer;
    display: block;
    height: 0;
    visibility: hidden;
    width: 0;
`;

const ManageCsvDropdown = ({ handleDownload, handleUpload = () => {} }) => {
    const { t } = useTranslation();
    const inputFileRef = useRef(null);

    const onChangeFile = (event) => {
        const { files } = event.target;
        if (files.length > 0) {
            if (files[0].size >= MAX_FILE_SIZE) {
                showToast({
                    body: t('The size is larger than allowed'),
                    type: 'error',
                });
                return;
            }
            handleUpload(files[0]);
            event.target.value = null;
        }
    };

    return (
        <Box>
            <FileInput ref={inputFileRef} onChange={onChangeFile} />
            <DropdownCardMenu
                items={[
                    {
                        handleClick: () => handleDownload(),
                        icon: <DownloadIcon fontSize="small" />,
                        label: 'Descargar CSV',
                    },
                    {
                        handleClick: () => inputFileRef.current.click(),
                        icon: <UploadIcon fontSize="small" />,
                        label: 'Cargar CSV',
                        visible: false,
                    },
                ]}
                sxButton={{ ml: 'auto' }}
            />
        </Box>
    );
};

ManageCsvDropdown.propTypes = {
    handleDownload: PropTypes.func,
    handleUpload: PropTypes.func,
};

export default ManageCsvDropdown;
