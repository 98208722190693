import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs } from 'common/ui';

const TabsSettings = ({
    handleOnChangeTab,
    hasContractedDemand,
    hasEnergyConcepts,
    selectedTab,
}) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                onChange={handleOnChangeTab}
                scrollButtons="auto"
                value={selectedTab}
                variant="scrollable"
            >
                <Tab
                    key="tab-charges"
                    id="tab-charges"
                    label={t('Charges')}
                    value="charges"
                />
                {hasEnergyConcepts && (
                    <Tab
                        key="tab-energy-distribution"
                        id="tab-energy-distribution"
                        label={t('Energy distribution')}
                        value="energy-distribution"
                    />
                )}
                {hasContractedDemand && (
                    <Tab
                        key="tab-demand-distribution"
                        id="tab-demand-distribution"
                        label={t('Contracted demand distribution')}
                        value="demand-distribution"
                    />
                )}
            </Tabs>
        </Box>
    );
};

TabsSettings.propTypes = {
    handleOnChangeTab: PropTypes.func,
    hasContractedDemand: PropTypes.bool,
    hasEnergyConcepts: PropTypes.bool,
    selectedTab: PropTypes.string,
};

export default TabsSettings;
