import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { HiddenComponent } from 'common/components';
import { IconButton, Grid, Typography, Tooltip } from 'common/ui';

import { getIsEmptyCategoryGroups } from '../helpers';

import HeaderColumn from './HeaderColumn';

const HeaderSection = ({
    categories,
    category,
    changeInput,
    checksNames,
    formValues,
    index,
    isDisabled,
    label,
}) => {
    const { t } = useTranslation();
    const isVisible = (category, index, permission) => {
        return index == 0 && category.permissions.includes(permission);
    };

    const typeCreate = get(formValues, 'type', null);
    const ownerTypeCreate = get(formValues, 'owner_type', null);

    const isGeneralManager =
        parseInt(typeCreate) === 0 && parseInt(ownerTypeCreate) === 1;

    const isSuperAdmin =
        parseInt(typeCreate) === 3 && parseInt(ownerTypeCreate) === 2;

    const isBranchOfficeManager =
        parseInt(typeCreate) === 1 && ownerTypeCreate == null;

    const isSalesmanAgent =
        parseInt(typeCreate) === 2 && ownerTypeCreate == null;

    const prefixRole = isGeneralManager
        ? 'general_manager'
        : isSuperAdmin
        ? 'superadmin'
        : isBranchOfficeManager
        ? 'branch_office_manager'
        : isSalesmanAgent
        ? 'salesman_agent'
        : 'owner';

    const autoCheckView = (input) => {
        const value = get(formValues, input, false);
        categories.map((category) => {
            return category.groups.map((group) => {
                if (
                    !group[prefixRole].minimum.includes('VIEW') &&
                    !group[prefixRole].forbidden.includes('VIEW')
                ) {
                    changeInput(
                        `groups.${group.id}.${prefixRole}.permission_view`,
                        !value
                    );
                    if (value) {
                        changeInput(
                            `groups.${group.id}.${prefixRole}.permission_add`,
                            false
                        );
                        changeInput(
                            `groups.${group.id}.${prefixRole}.permission_change`,
                            false
                        );
                        changeInput(
                            `groups.${group.id}.${prefixRole}.permission_delete`,
                            false
                        );
                        changeInput(checksNames[0], false);
                        changeInput(checksNames[1], false);
                        changeInput(checksNames[2], false);
                        changeInput(checksNames[3], false);
                    }
                }
                return group;
            });
        });
    };

    const autoCheckAdd = (input) => {
        const value = get(formValues, input, false);
        categories.map((category) => {
            return category.groups.map((group) => {
                if (
                    !group[prefixRole].minimum.includes('ADD') &&
                    !group[prefixRole].forbidden.includes('ADD')
                ) {
                    changeInput(
                        `groups.${group.id}.${prefixRole}.permission_add`,
                        !value
                    );
                    changeInput(
                        `groups.${group.id}.${prefixRole}.permission_view`,
                        true
                    );
                    changeInput(checksNames[0], true);
                }
                return group;
            });
        });
    };

    const autoCheckEdit = (input) => {
        const value = get(formValues, input, false);
        categories.map((category) => {
            return category.groups.map((group) => {
                if (
                    !group[prefixRole].minimum.includes('EDIT') &&
                    !group[prefixRole].forbidden.includes('EDIT')
                ) {
                    changeInput(
                        `groups.${group.id}.${prefixRole}.permission_change`,
                        !value
                    );
                    changeInput(
                        `groups.${group.id}.${prefixRole}.permission_view`,
                        true
                    );
                    changeInput(checksNames[0], true);
                }
                return group;
            });
        });
    };

    const autoCheckDelete = (input) => {
        const value = get(formValues, input, false);
        categories.map((category) => {
            return category.groups.map((group) => {
                if (
                    !group[prefixRole].minimum.includes('DELETE') &&
                    !group[prefixRole].forbidden.includes('DELETE')
                ) {
                    changeInput(
                        `groups.${group.id}.${prefixRole}.permission_delete`,
                        !value
                    );
                    changeInput(
                        `groups.${group.id}.${prefixRole}.permission_view`,
                        true
                    );
                    changeInput(checksNames[0], true);
                }
                return group;
            });
        });
    };

    return (
        !getIsEmptyCategoryGroups(category) && (
            <Grid
                container
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid item xs md pl={2}>
                    <HiddenComponent width="smDown">
                        <Typography variant="h6">{label}</Typography>
                    </HiddenComponent>

                    <HiddenComponent width="smUp">
                        <Tooltip title={label} placement="top">
                            <span>
                                <IconButton color="secondary">
                                    <InfoIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </HiddenComponent>
                </Grid>

                <HeaderColumn
                    category={category}
                    formValues={formValues}
                    label={t('Watch')}
                    inputId={`input_${checksNames[0]}`}
                    isDisabled={isDisabled}
                    name={checksNames[0]}
                    onchange={(input) => autoCheckView(input)}
                    visible={isVisible(category, index, 'VIEW')}
                />

                <HeaderColumn
                    category={category}
                    formValues={formValues}
                    label={t('Add')}
                    inputId={`input_${checksNames[1]}`}
                    isDisabled={isDisabled}
                    name={checksNames[1]}
                    onchange={(input) => autoCheckAdd(input)}
                    visible={isVisible(category, index, 'ADD')}
                />

                <HeaderColumn
                    category={category}
                    formValues={formValues}
                    label={t('Edit')}
                    inputId={`input_${checksNames[2]}`}
                    isDisabled={isDisabled}
                    name={checksNames[2]}
                    onchange={(input) => autoCheckEdit(input)}
                    visible={isVisible(category, index, 'EDIT')}
                />

                <HeaderColumn
                    category={category}
                    formValues={formValues}
                    label={t('Enable')}
                    inputId={`input_${checksNames[2]}`}
                    isDisabled={isDisabled}
                    name={checksNames[2]}
                    onchange={(input) => autoCheckEdit(input)}
                    visible={isVisible(category, index, 'ENABLE')}
                />

                <HeaderColumn
                    category={category}
                    formValues={formValues}
                    label={t('Delete')}
                    inputId={`input_${checksNames[3]}`}
                    isDisabled={isDisabled}
                    name={checksNames[3]}
                    onchange={(input) => autoCheckDelete(input)}
                    visible={isVisible(category, index, 'DELETE')}
                />
            </Grid>
        )
    );
};

HeaderSection.propTypes = {
    categories: PropTypes.array,
    category: PropTypes.object,
    changeInput: PropTypes.func,
    checksNames: PropTypes.array,
    formValues: PropTypes.object,
    index: PropTypes.number,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
};

export default HeaderSection;
