import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/DropdownCardMenu';
import { Box } from 'common/ui';

import DinosaurIcon from 'resources/dinosaur.png';

const emailToShowDino = ['alan@sunwise.mx', 'alan@sunwise.io'];

const ProductActionsFormatter = ({
    handleClickActive,
    handleClickDownload,
    row,
    session,
}) => {
    const { t } = useTranslation();

    let downloadIcon = <DownloadIcon />;
    let downloadLabel = `${t('Download')} ${t('User', {
        count: 2,
    }).toLowerCase()}`;

    if (session) {
        const { email } = session;

        if (emailToShowDino.includes(email)) {
            downloadIcon = <img src={DinosaurIcon} width={22} />;
            downloadLabel = `${t('Download')} usaurios`;
        }
    }

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            <DropdownCardMenu
                items={[
                    {
                        handleClick: () => handleClickActive(row, true),
                        icon: <ToggleOnIcon fontSize="small" />,
                        label: t('Enable'),
                        visible: !row?.is_active,
                    },
                    {
                        handleClick: () => handleClickActive(row, false),
                        icon: <ToggleOffIcon fontSize="small" />,
                        label: t('Disable'),
                        visible: row?.is_active,
                    },
                    {
                        handleClick: () => handleClickDownload(row.id),
                        icon: downloadIcon,
                        label: downloadLabel,
                    },
                ]}
            />
        </Box>
    );
};

ProductActionsFormatter.propTypes = {
    handleClickActive: PropTypes.func,
    handleClickDownload: PropTypes.func,
    row: PropTypes.object,
    session: PropTypes.object,
};

export default ProductActionsFormatter;
