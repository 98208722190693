import { getCurrenciesByCountries } from 'common/api/v1/parameters';

import {
    FETCH_CURRENCIES_BY_COUNTRIES,
    FETCH_CURRENCIES_BY_COUNTRIES_FAILURE,
    FETCH_CURRENCIES_BY_COUNTRIES_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_CURRENCIES_BY_COUNTRIES });

    getCurrenciesByCountries({ page: 1, perPage: 1000 })
        .then((response) =>
            dispatch({
                type: FETCH_CURRENCIES_BY_COUNTRIES_SUCCESS,
                payload: response?.data?.data,
            })
        )
        .catch((error) =>
            dispatch({
                type: FETCH_CURRENCIES_BY_COUNTRIES_FAILURE,
                payload: error,
            })
        );
};
