import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Box, Button } from 'common/ui';

const WrapperField = styled.div`
    ${({ variant }) =>
        variant === 'firstLoad' &&
        `
        background: #002438;
        border-radius: 3px;
        border: 3px dashed #a3a6b4;
        color: #ffffff;
    `}

    cursor: pointer;
    font-size: 12px;
    letter-spacing: -0.29px;
    line-height: 19px;
    margin: 16px 0;
    outline: none;
    padding: 24px;
`;

const StyledWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

const StyledIco = styled.div`
    display: inline-flex;
    justify-content: center;
    padding: 8px;
`;

const FileUpload = ({
    accept,
    changeFileText,
    disabled,
    input,
    uploadText = '',
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        input.onChange(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <WrapperField
            {...getRootProps()}
            variant={!input?.value ? `firstLoad` : `reload`}
        >
            <StyledWrapper>
                <input
                    disabled={disabled}
                    {...getInputProps()}
                    accept={accept}
                />

                {!input?.value ? (
                    <Box
                        alignItems="center"
                        display="flex"
                        gap="16px"
                        textAlign="center"
                    >
                        <StyledIco>
                            <AttachFileIcon />
                        </StyledIco>
                        <Box>{uploadText}</Box>
                    </Box>
                ) : (
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        textAlign="center"
                    >
                        <CheckCircleIcon />
                        <Button disabled={disabled}>{changeFileText}</Button>
                    </Box>
                )}
            </StyledWrapper>
        </WrapperField>
    );
};

FileUpload.propTypes = {
    accept: PropTypes.string,
    changeFileText: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.object,
    uploadText: PropTypes.string,
};

export default FileUpload;
