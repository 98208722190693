import { getSubscriptionPlans } from 'common/api/companies';

import {
    FETCH_PLANS,
    FETCH_PLANS_FAILURE,
    FETCH_PLANS_SUCCESS,
} from '../actionTypes';

export default (companyId) => (dispatch) => {
    dispatch({ type: FETCH_PLANS });
    getSubscriptionPlans(companyId)
        .then((response) => {
            dispatch({
                type: FETCH_PLANS_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PLANS_FAILURE,
                payload: error.response.data.errors,
            });
        });
};
