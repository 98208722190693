import styled from 'styled-components';

import { Typography } from 'common/ui';

export default styled(Typography)`
    font-size: 22px;
    font-weight: normal;
    line-height: 27px;
    margin-bottom: 16px;
    margin-top: 16px;
`;
