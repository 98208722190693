import { object, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(({ REQUIRED_TEXT, minimumNumberCharacters }) =>
    object().shape({
        code: string()
            .required(REQUIRED_TEXT)
            .min(5, minimumNumberCharacters(5))
            .nullable(),
    })
);
