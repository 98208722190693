import { filterCustomTemplates } from 'common/api/templatesCatalog';

import {
    FETCH_CUSTOM_TEMPLATES,
    FETCH_CUSTOM_TEMPLATES_SUCCESS,
    FETCH_CUSTOM_TEMPLATES_FAILURE,
} from '../actionTypes';

export default (companyId = '', str = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_CUSTOM_TEMPLATES });

        filterCustomTemplates(companyId, str)
            .then((response) => {
                dispatch({
                    type: FETCH_CUSTOM_TEMPLATES_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_CUSTOM_TEMPLATES_FAILURE,
                    payload: error.response.data.errors,
                })
            );
    };
