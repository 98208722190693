import i18next from 'i18next';

import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import deleteItem from './deleteItem';

export default (filterData, item) => (dispatch) => {
    if (item.has_users) {
        showToast({
            body: i18next.t('You cannot remove roles that are in use'),
            type: 'error',
        });
        return false;
    }
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [
            i18next.t('Are you sure to remove the role "{{name}}"?', {
                name: `${item.name}`,
            }),
        ],
        onSuccess: () => dispatch(deleteItem(filterData, item.id)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
