import { NAME } from './constants';

export const FETCH_PLANS = `${NAME}/FETCH_PLANS`;
export const FETCH_PLANS_FAILURE = `${NAME}/FETCH_PLANS_FAILURE`;
export const FETCH_PLANS_SUCCESS = `${NAME}/FETCH_PLANS_SUCCESS`;

export const FETCH_PANELS = `${NAME}/FETCH_PANELS`;
export const FETCH_PANELS_SUCCESS = `${NAME}/FETCH_PANELS_SUCCESS`;
export const FETCH_PANELS_FAILURE = `${NAME}/FETCH_PANELS_FAILURE`;

export const FETCH_INVERTERS = `${NAME}/FETCH_INVERTERS`;
export const FETCH_INVERTERS_SUCCESS = `${NAME}/FETCH_INVERTERS_SUCCESS`;
export const FETCH_INVERTERS_FAILURE = `${NAME}/FETCH_INVERTERS_FAILURE`;

export const FETCH_PLAN_DETAIL = `${NAME}/FETCH_PLAN_DETAIL`;
export const FETCH_PLAN_DETAIL_FAILURE = `${NAME}/FETCH_PLAN_DETAIL_FAILURE`;
export const FETCH_PLAN_DETAIL_SUCCESS = `${NAME}/FETCH_PLAN_DETAIL_SUCCESS`;

export const FETCH_PLAN_HISTORY = `${NAME}/FETCH_PLAN_HISTORY`;
export const FETCH_PLAN_HISTORY_FAILURE = `${NAME}/FETCH_PLAN_HISTORY_FAILURE`;
export const FETCH_PLAN_HISTORY_SUCCESS = `${NAME}/FETCH_PLAN_HISTORY_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SHOW_EDITION_FORM = `${NAME}/SHOW_EDITION_FORM`;
export const SET_FILTERS = `${NAME}/SET_FILTERS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;
