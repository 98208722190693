import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import { ReduxFieldInput } from 'common/components/form/redux';
import { Button, Grid, Stack } from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const Form = ({
    errors,
    filters = {},
    formValues,
    handleAfterSave = null,
    handleClickCloseModal,
    handleClickSave,
    handleSubmit,
    handleTemporalDelete = null,
    handleTemporalSave = null,
    isDeleting,
    isNewBrand,
    isNewRecord,
    isSaving,
    prepareDelete,
    reset,
}) => {
    const { t } = useTranslation();

    const handleDeleteFamily = (item) => {
        if (!isNewBrand) {
            prepareDelete(item, () => {
                if (handleAfterSave)
                    handleAfterSave(
                        filters?.id,
                        filters?.page,
                        filters?.orderBy,
                        filters?.sortBy,
                        filters?.searchStr
                    );
            });
        } else {
            if (handleTemporalDelete) {
                handleTemporalDelete(item);
                handleClickCloseModal();
            }
        }
    };

    const handleSaveAction = (values) => {
        if (!isNewBrand) {
            handleClickSave(values, () => {
                if (handleAfterSave)
                    handleAfterSave(
                        filters?.id,
                        filters?.page,
                        filters?.orderBy,
                        filters?.sortBy,
                        filters?.searchStr
                    );
            });
        } else {
            if (handleTemporalSave) {
                handleTemporalSave(values);
                handleClickCloseModal();
            }
        }
    };

    return (
        <form>
            <Grid container spacing={2} pt={2}>
                <Grid item xs={12}>
                    <Field
                        autoFocus
                        name="name"
                        component={ReduxFieldInput}
                        placeholder={t('Family name')}
                        variant="outlined"
                        required
                    />
                </Grid>
            </Grid>

            <ShowErrors
                errors={errors}
                mt={3}
                severity="error"
                variant="filled"
            />

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
                mt={3}
            >
                <Grid container mt={2}>
                    <Grid item>
                        {!isNewRecord && formValues.id && (
                            <Button
                                color="secondary"
                                disabled={isSaving || isDeleting}
                                fullWidth={false}
                                height="48px"
                                size="large"
                                onClick={() => {
                                    handleDeleteFamily(formValues);
                                }}
                            >
                                {t('Delete')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button
                            color="secondary"
                            disabled={isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={() => {
                                reset();
                                handleClickCloseModal();
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isSaving}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            onClick={handleSubmit(handleSaveAction)}
                            type="submit"
                        >
                            {isSaving ? `${t('Loading')}...` : t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        </form>
    );
};

Form.propTypes = {
    errors: PropTypes.array,
    filters: PropTypes.object,
    formValues: PropTypes.object,
    handleAfterSave: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleTemporalDelete: PropTypes.func,
    handleTemporalSave: PropTypes.func,
    isDeleting: PropTypes.bool,
    isNewBrand: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    prepareDelete: PropTypes.func,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isDeleting: selectors.getIsDeletingFamily,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    prepareDelete: (item, callback) =>
        dispatch(actions.prepareDelete(item, callback)),
    handleClickCloseModal: () => dispatch(actions.toggleModal(false)),
    handleClickSave: (values, callback) =>
        dispatch(actions.save(values, callback)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
