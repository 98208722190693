import { filterSuppliers } from 'common/api/suppliers';

import {
    FETCH_SUPPLIER_UTILITIES,
    FETCH_SUPPLIER_UTILITIES_FAILURE,
    FETCH_SUPPLIER_UTILITIES_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_SUPPLIER_UTILITIES });
    filterSuppliers({
        page: 1,
        perPage: 1000,
    })
        .then((response) =>
            dispatch({
                payload: response.data.data,
                type: FETCH_SUPPLIER_UTILITIES_SUCCESS,
            })
        )
        .catch((error) =>
            dispatch({ payload: error, type: FETCH_SUPPLIER_UTILITIES_FAILURE })
        );
};
