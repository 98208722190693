import React from 'react';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Stack, Typography, IconButton, Tooltip } from 'common/ui';

import { getCopiedDistribution } from '../../../../helpers';
import { TiersTooltip } from '../../../HourlyRateComponents';

const HeaderDistribution = ({
    distribution,
    rateTiers,
    setDistribution,
    title,
}) => {
    const { t } = useTranslation();
    const rateTiersMapped = rateTiers?.map((concept, index) => {
        return { identifier: index, name: concept.tag_name };
    });

    const handleCopyDistribution = () => {
        setDistribution(getCopiedDistribution(distribution, rateTiersMapped));
    };

    return (
        <Stack direction="row" justifyContent="space-between" p={1}>
            <Stack alignItems="center" direction="row" gap={1} pl={1}>
                <Typography variant="h4">{title}</Typography>

                <TiersTooltip rateTiers={rateTiersMapped} />
            </Stack>

            <Tooltip title={t('Sync hourly distribution')}>
                <IconButton size="small" onClick={handleCopyDistribution}>
                    <AutoFixHighIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

HeaderDistribution.propTypes = {
    distribution: PropTypes.object,
    rateTiers: PropTypes.array,
    setDistribution: PropTypes.func,
    title: PropTypes.string,
};

export default HeaderDistribution;
