import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { CustomPagination } from 'common/components';
import { Card, CardContent, DataGrid } from 'common/ui';
import { getDataGridClasses } from 'common/utils/helpers';

import {
    getCountryOptions,
    getPlansOptions,
    getStatusOptions,
} from '../helpers';

import BulkOperationsCard from './BulkOperationsCard';
import ProductColumns from './ProductColumns';

const DEBOUNCE_TIME = 1500;

const ListItems = ({
    countries,
    downloadFile,
    filterData,
    filterItems,
    isFetching,
    isFetchingCountries,
    isFetchingPlans,
    items,
    paginationData,
    plans,
    setFilterData,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [rowCount, setRowCount] = useState(0);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleClickCountry = (value) => {
        setFilterData({
            ...filterData,
            countryName: value,
        });
    };

    const handleClickPlan = (value) => {
        setFilterData({
            ...filterData,
            planName: value,
        });
    };

    const handleClickStatus = (value) => {
        setFilterData({
            ...filterData,
            subscriptionStatus: value,
        });
    };

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <>
            <BulkOperationsCard
                countryOptions={getCountryOptions(countries)}
                downloadFile={downloadFile}
                filterData={filterData}
                handleClickCountry={handleClickCountry}
                handleClickPlan={handleClickPlan}
                handleClickStatus={handleClickStatus}
                handleOnChangeSearch={handleOnChangeSearch}
                isFetchingCountries={isFetchingCountries}
                isFetchingPlans={isFetchingPlans}
                plansOptions={getPlansOptions(plans)}
                statusList={getStatusOptions()}
            />

            <Card>
                <CardContent>
                    <DataGrid
                        autoHeight
                        checkboxSelection={false}
                        columns={ProductColumns()}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rows={items}
                        sortingMode="server"
                        sx={{
                            ...getDataGridClasses(isDarkMode),
                            '& .MuiDataGrid-cell': { p: '10px' },
                        }}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    countries: PropTypes.array,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingCountries: PropTypes.bool,
    isFetchingPlans: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    plans: PropTypes.array,
    setFilterData: PropTypes.func,
};

export default ListItems;
