import i18next from 'i18next';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { getRateHistoryDatagrid } from 'common/api/suppliers';
import showToast from 'common/utils/showToast';

import {
    FETCH_RATE_HISTORY_DATAGRID,
    FETCH_RATE_HISTORY_DATAGRID_FAILURE,
    FETCH_RATE_HISTORY_DATAGRID_SUCCESS,
} from '../actionTypes';

import initializeForm from './initializeForm';
import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (rateId = '', year = '', month = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_RATE_HISTORY_DATAGRID });
        dispatch(resetForm());
        dispatch(toggleModal(true));

        getRateHistoryDatagrid(rateId, year, month)
            .then((response) => {
                dispatch({
                    type: FETCH_RATE_HISTORY_DATAGRID_SUCCESS,
                    payload: response.data,
                });

                const values = {
                    rate: rateId,
                    year: year,
                    month,
                    rateDatagrid: [],
                };

                if (
                    isArray(response?.data?.data) &&
                    !isEmpty(response?.data?.data)
                ) {
                    values.rateDatagrid = response.data.data.map((d) => {
                        return {
                            ...d,
                            editable: false,
                        };
                    });
                }

                dispatch(initializeForm(values));
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_RATE_HISTORY_DATAGRID_FAILURE,
                    payload: error.response.data.errors,
                });

                showToast({
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while querying the rate history datagrid'
                    ),
                });

                dispatch(toggleModal(false));
            });
    };
