import update from 'immutability-helper';

import {
    SAVE,
    SAVE_SUCCESS,
    SAVE_FAILURE,
    SET_CURRENT_VALUES,
    FETCH,
    FETCH_FAILURE,
    FETCH_SUCCESS,
    INITIALIZE_FORM,
    SET_THEME,
} from './actionTypes';

const INITIAL_STATE = {
    initialValues: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        picture: null,
    },
    currentValues: {
        email: null,
        first_name: null,
        last_name: null,
        picture: null,
    },
    fetch: {
        isFetching: false,
        data: [],
        error: null,
    },
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
    theme: 'light',
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH:
            return update(state, {
                fetch: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_SUCCESS:
            return update(state, {
                fetch: {
                    $merge: {
                        isFetching: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_FAILURE:
            return update(state, {
                fetch: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case SET_CURRENT_VALUES:
            return update(state, {
                currentValues: {
                    $merge: action.payload,
                },
            });

        case SET_THEME:
            return update(state, {
                theme: { $set: action.payload },
            });

        default:
            return state;
    }
}
