import { filterCompany } from 'common/api/companies';

import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_COMPANIES });

    filterCompany()
        .then((response) => {
            dispatch({
                type: FETCH_COMPANIES_SUCCESS,
                payload: response?.data?.data,
            });
        })
        .catch((error) => {
            dispatch({ type: FETCH_COMPANIES_FAILURE, payload: error });
        });
};
