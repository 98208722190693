import i18next from 'i18next';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getPlansModel = createSelector(getModel, (model) => model.plans);

export const getPlansData = createSelector(getPlansModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((plan) => ({
              ...plan,
              installation_name: plan.installation_type,
          }))
);

export const getPlansErrors = createSelector(
    getPlansModel,
    (model) => model.errors
);

export const getPlansIsFetching = createSelector(
    getPlansModel,
    (model) => model.isFetching
);

export const getPlansPagination = createSelector(
    getPlansModel,
    (model) => model.data.pagination || {}
);

export const getPlanDetail = createSelector(
    getModel,
    (model) => model.planDetail
);

export const getIsFetchingPlanDetail = createSelector(
    getPlanDetail,
    (planDetail) => planDetail.isFetching
);

export const getPlanHistoryModel = createSelector(
    getModel,
    (model) => model.planHistory
);

export const getPlanHistoryData = createSelector(getPlanHistoryModel, (model) =>
    !model.data.data
        ? []
        : model.data.data.map((history) => ({
              ...history,
          }))
);

export const getPlanHistoryErrors = createSelector(
    getPlanHistoryModel,
    (model) => model.errors
);

export const getPlanHistoryIsFetching = createSelector(
    getPlanHistoryModel,
    (model) => model.isFetching
);

export const getPlanHistoryPagination = createSelector(
    getPlanHistoryModel,
    (model) => model.data.pagination || {}
);
export const getFilters = createSelector(getModel, (model) => model.filters);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getDataSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.data
);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);

export const getIsOpenEditionForm = createSelector(
    getModel,
    (model) => model.isOpenEditionForm || false
);

export const getFetchPanels = createSelector(
    getModel,
    (model) => model.fetchPanels
);

export const getIsFetchingPanels = createSelector(
    getFetchPanels,
    (fetchPanels) => fetchPanels.isFetching
);

export const getDataFetchPanels = createSelector(
    getFetchPanels,
    (fetchPanels) => {
        let items = fetchPanels.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));

        items.unshift({
            label: i18next.t('Select panel'),
            optionValue: '',
        });

        return items;
    }
);

export const getErrorsFetchPanels = createSelector(
    getFetchPanels,
    (fetchPanels) => fetchPanels.errors
);

export const getFetchInverters = createSelector(
    getModel,
    (model) => model.fetchInverters
);

export const getIsFetchingInverters = createSelector(
    getFetchInverters,
    (fetchInverters) => fetchInverters.isFetching
);

export const getDataFetchInverters = createSelector(
    getFetchInverters,
    (fetchInverters) => {
        let items = fetchInverters.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));

        items.unshift({
            label: i18next.t('Select investor'),
            optionValue: '',
        });

        return items;
    }
);

export const getErrorsFetchInverters = createSelector(
    getFetchInverters,
    (fetchInverters) => fetchInverters.errors
);
