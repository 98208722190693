import { SAVE } from '../actionTypes';

import createBrand from './createBrand';
import updateBrand from './updateBrand';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE });
    if (values.id) {
        dispatch(updateBrand(values, values.id));
    } else {
        dispatch(createBrand(values));
    }
};
