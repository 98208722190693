import React from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import ReduxFieldInput from 'common/components/form/redux/ReduxFieldInput';
import ShowErrors from 'common/components/ShowErrors';
import { Button, Card, Grid, Tooltip } from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const DataForm = ({
    errors,
    handleSubmit,
    handleChangePassword,
    handleChangePasswordConfirmation,
    handleClickSave,
    isSaving,
}) => {
    const onChangePassword = (e) => handleChangePassword(e);

    const onChangePasswordConfirmation = (e) =>
        handleChangePasswordConfirmation(e);

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <Card className="mb-3 mt-3">
                <Grid>
                    <Grid>
                        <Field
                            name="old_password"
                            component={ReduxFieldInput}
                            label={'Contraseña actual'}
                            placeholder={'Ingresa tu contraseña actual'}
                            type="password"
                            autoComplete="off"
                        />

                        <Tooltip title={'Mínimo 8 caracteres'}>
                            <div>
                                <Field
                                    name="new_password"
                                    component={ReduxFieldInput}
                                    label={'Contraseña'}
                                    placeholder={'Ingresa tu nueva contraseña'}
                                    type="password"
                                    autoComplete="off"
                                    onChange={(event) =>
                                        onChangePassword(event)
                                    }
                                />
                            </div>
                        </Tooltip>

                        <Tooltip
                            title={
                                'Mínimo 8 caracteres. Debe coincidir con la contraseña.'
                            }
                        >
                            <div>
                                <Field
                                    name="confirm_password"
                                    component={ReduxFieldInput}
                                    label={'Contraseña'}
                                    placeholder={'Confirma tu nueva contraseña'}
                                    type="password"
                                    autoComplete="off"
                                    onChange={onChangePasswordConfirmation}
                                    onFocus={onChangePasswordConfirmation}
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>

                <ShowErrors errors={errors} />

                <hr />

                <div className="text-right">
                    <Button className="mb-2" disabled={isSaving} type="submit">
                        Guardar
                    </Button>
                </div>
            </Card>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getIsSaving,
    errors: selectors.getErrorsSave,
    validPassword: selectors.getValidPassword,
    validPasswordConfirmation: selectors.getValidPasswordConfirmation,
    validPasswordConfirmationLength:
        selectors.getValidPasswordConfirmationLength,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (values) => dispatch(actions.save(values)),
    handleChangePassword: (event) =>
        dispatch(actions.handleChangePassword(event)),
    handleChangePasswordConfirmation: (event) =>
        dispatch(actions.handleChangePasswordConfirmation(event)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(DataForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
