import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    AutocompleteField,
    BulkEditionMenu,
    InputSearch,
    StatusFilterSelect,
} from 'common/components';
import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'common/ui';

const BulkOperationsCard = ({
    filterData,
    handleClickBulkItems,
    handleOnChangeSearch,
    handleOnSelectBrand,
    handleOnSelectStatus,
    handleOnSelectType,
    prefix,
    productBrands = [],
    selectedBulkOption,
    selectionModel,
    setSelectedBulkOption,
    setSelectionModel,
}) => {
    const { t } = useTranslation();

    const isArchivedList = filterData?.status === 'archived';
    const callback = () => {
        setSelectedBulkOption('');
        setSelectionModel([]);
    };

    const getBrandOptions = () => {
        return productBrands.map(({ name }) => ({
            value: name,
            label: name,
        }));
    };

    const accesoryTypeOptions = [
        {
            disabled: true,
            label: t('Select'),
            value: '',
        },
        {
            label: t('Monitoring'),
            value: 0,
        },
        {
            label: t('Wire'),
            value: 1,
        },
        {
            label: t('Optimizer'),
            value: 2,
        },
        {
            label: t('Connector'),
            value: 3,
        },
        {
            label: t('Transformer'),
            value: 4,
        },
    ];

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid
                    alignItems="end"
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item xs={12} lg={4}>
                        <InputSearch
                            placeholder={t('Search')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <AutocompleteField
                            label={t('Brand')}
                            options={getBrandOptions()}
                            onChange={(value) => handleOnSelectBrand(value)}
                            value={filterData?.brandName}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Type')}</InputLabel>
                            <Select
                                label={t('Type')}
                                onChange={(e) => handleOnSelectType(e.target)}
                                value={filterData?.type}
                                variant="standard"
                            >
                                {accesoryTypeOptions.map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`type-select-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <StatusFilterSelect
                            filterBy={filterData?.status}
                            handleSelected={handleOnSelectStatus}
                            variant="standard"
                        />
                    </Grid>
                    {!isEmpty(selectionModel) && (
                        <Grid item xs={12}>
                            <BulkEditionMenu
                                items={[
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'archived',
                                                filterData,
                                                callback
                                            ),
                                        visible: !isArchivedList,
                                    },
                                    {
                                        label: t('Unarchive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'active',
                                                filterData,
                                                callback
                                            ),
                                        visible: isArchivedList,
                                    },
                                    {
                                        label: t('Delete'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                'deleted',
                                                filterData,
                                                callback
                                            ),
                                        visible: isArchivedList,
                                    },
                                ]}
                                prefix={prefix}
                                selectedBulkOption={selectedBulkOption}
                                selectionModel={selectionModel}
                                setSelectionModel={setSelectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectBrand: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    handleOnSelectType: PropTypes.func,
    prefix: PropTypes.string,
    productBrands: PropTypes.array,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectedBulkOption: PropTypes.func,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
