import React, { useContext, useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { DialogContainer } from 'common/components';
import { Box, Card, CardActions, Container, Pagination } from 'common/ui';
import { LoadingContext } from 'common/utils/contexts';

import * as companyGeneralActions from '../companyGeneral/actions';
import * as companyGeneralSelectors from '../companyGeneral/selectors';

import * as actions from './actions';
import { UserForm } from './components';
import UsersPreviewTable from './components/UsersPreviewTable';
import UsersPreviewTableToolbar from './components/UsersPreviewTableToolbar';
import * as selectors from './selectors';

const UsersContainer = ({
    branchOffices,
    downloadCSV,
    fetchBranchOffices,
    fetchCompanyDetail,
    fetchRoles,
    fetchUsers,
    handleClickCloseModalUser,
    isFetchingCompanyDetail,
    isFetchingCSV,
    isFetchingUserDetail,
    isFetchingUsers,
    isMultiBranchesCompany,
    isOpenModalUser,
    match,
    pagination,
    reset,
    roles,
    users,
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [searchStr, setSearchStr] = useState('');
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        fetchCompanyDetail(match.params.id);
        fetchRoles(match.params.id);
        fetchBranchOffices(match.params.id);
        return () => reset();
    }, []);

    useEffect(() => {
        if (match.params.id) {
            fetchUsers(match.params.id, page, orderBy, sortBy, searchStr);
        }
    }, [page, match.params.id, searchStr]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchUsers(match.params.id, page, orderBy, sortBy, searchStr);
    };

    const handleOnSearchChange = (e) => setSearchStr(e);

    const handleOnDownloadCSV = () => {
        downloadCSV(
            match.params.id,
            orderBy,
            sortBy,
            searchStr,
            (text) => loadingContext.openLoading(text),
            () => {
                loadingContext.closeLoading();
            }
        );
    };

    const isFetching =
        isFetchingCompanyDetail || isFetchingUserDetail || isFetchingUsers;

    return (
        <>
            <Container maxWidth="">
                <Box mb={3}>
                    <UsersPreviewTableToolbar
                        isFetching={isFetching}
                        isFetchingCSV={isFetchingCSV}
                        handleDownloadFile={handleOnDownloadCSV}
                        handleOnSearchChange={handleOnSearchChange}
                    />
                </Box>

                <Card>
                    <UsersPreviewTable
                        companyId={match.params.id}
                        handleSortable={handleSortable}
                        isFetching={isFetching}
                        isMultiBranchesCompany={isMultiBranchesCompany}
                        items={users}
                        sortedBy={sortBy}
                        orderdBy={orderBy}
                    />

                    {!isFetching && pagination && !isEmpty(pagination) && (
                        <CardActions>
                            <Pagination
                                color="primary"
                                count={
                                    Math.ceil(
                                        parseInt(pagination.total_rows) /
                                            parseInt(pagination.per_page)
                                    ) || 0
                                }
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                sx={{
                                    marginLeft: 'auto',
                                }}
                                page={pagination.current_page}
                                onChange={handleOnChangePage}
                                variant="outlined"
                            />
                        </CardActions>
                    )}
                </Card>
            </Container>
            <DialogContainer
                loading={isFetchingUserDetail}
                onClose={handleClickCloseModalUser}
                open={isOpenModalUser}
                fullWidth={true}
                title={t('Edit user')}
            >
                <UserForm
                    branchOffices={branchOffices}
                    isFetching={isFetching}
                    isMultiBranchesCompany={isMultiBranchesCompany}
                    roles={roles}
                />
            </DialogContainer>
        </>
    );
};

UsersContainer.propTypes = {
    branchOffices: PropTypes.array,
    downloadCSV: PropTypes.func,
    fetchBranchOffices: PropTypes.func,
    fetchCompanyDetail: PropTypes.func,
    fetchRoles: PropTypes.func,
    fetchUsers: PropTypes.func,
    handleClickCloseModalUser: PropTypes.func,
    isFetchingUsers: PropTypes.bool,
    isFetchingCompanyDetail: PropTypes.bool,
    isFetchingCSV: PropTypes.bool,
    isFetchingUserDetail: PropTypes.bool,
    isMultiBranchesCompany: PropTypes.bool,
    isOpenModalUser: PropTypes.bool,
    match: PropTypes.object,
    pagination: PropTypes.object,
    reset: PropTypes.func,
    roles: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    branchOffices: selectors.getBranchesItemsSelect,
    isFetchingCompanyDetail: companyGeneralSelectors.getIsFetchingCompanyDetail,
    isFetchingCSV: selectors.getIsFetchingUsersCSV,
    isFetchingUserDetail: selectors.getIsFetchingUserDetail,
    isFetchingUsers: selectors.getUsersIsFetching,
    isMultiBranchesCompany: companyGeneralSelectors.getIsMultiBranchCompany,
    isOpenModalUser: selectors.getIsOpenModalUser,
    pagination: selectors.getUsersPagination,
    roles: selectors.getDataFetchRoles,
    users: selectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    downloadCSV: (id, orderBy, sortBy, searchStr, openLoading, closeLoading) =>
        dispatch(
            actions.downloadCSV(
                id,
                orderBy,
                sortBy,
                searchStr,
                openLoading,
                closeLoading
            )
        ),
    fetchBranchOffices: (id) => dispatch(actions.fetchBranchOffices(id)),
    fetchCompanyDetail: (id) =>
        dispatch(companyGeneralActions.fetchCompanyDetail(id)),
    fetchRoles: (id) => dispatch(actions.fetchRoles(id)),
    fetchUsers: (id, page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchUsers(id, page, orderBy, sortBy, searchStr)),
    handleClickCloseModalUser: () => dispatch(actions.toggleModalUser(false)),
    reset: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UsersContainer));
