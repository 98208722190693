import React from 'react';

import PropTypes from 'prop-types';

import { Breadcrumbs, Skeleton } from 'common/ui';

const Placeholder = () => (
    <Breadcrumbs>
        {[...Array(2)].map((_, index) => (
            <Skeleton
                width={150}
                key={`skeleton-${Math.random() + index}`}
            ></Skeleton>
        ))}
    </Breadcrumbs>
);

const PlaceholderBreadcrumbs = ({ children, ready }) => {
    return ready ? <Placeholder /> : children;
};

PlaceholderBreadcrumbs.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderBreadcrumbs;
