import update from 'immutability-helper';

import {
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DOWNLOAD_CSV_SUCCESS,
    FETCH_COMPANIES,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAILURE,
    FECH_COUNTRIES,
    FECH_COUNTRIES_SUCCESS,
    FECH_COUNTRIES_FAILURE,
    FETCH_PLANS,
    FETCH_PLANS_SUCCESS,
    FETCH_PLANS_FAILURE,
} from './actionTypes';

const INITIAL_STATE = {
    companies: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
    countries: {
        data: [],
        errors: [],
        isFetching: false,
    },
    downloadCSV: {
        isFetching: false,
        data: null,
        errors: [],
    },
    plans: {
        data: [],
        errors: [],
        isFetching: false,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DOWNLOAD_CSV:
            return update(state, {
                downloadCSV: { $merge: { errors: [], isFetching: true } },
            });

        case DOWNLOAD_CSV_FAILURE:
            return update(state, {
                downloadCSV: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case DOWNLOAD_CSV_SUCCESS:
            return update(state, {
                downloadCSV: { $merge: { errors: [], isFetching: false } },
            });

        case FETCH_COMPANIES:
            return update(state, {
                companies: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_COMPANIES_FAILURE:
            return update(state, {
                companies: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_COMPANIES_SUCCESS:
            return update(state, {
                companies: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FECH_COUNTRIES:
            return update(state, {
                countries: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FECH_COUNTRIES_FAILURE:
            return update(state, {
                countries: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FECH_COUNTRIES_SUCCESS:
            return update(state, {
                countries: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case FETCH_PLANS:
            return update(state, {
                plans: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_PLANS_FAILURE:
            return update(state, {
                plans: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_PLANS_SUCCESS:
            return update(state, {
                plans: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        default:
            return state;
    }
}
