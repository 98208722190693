import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { getTemplateDetail } from 'common/api/templatesCatalog';
import showToast from 'common/utils/showToast';

import {
    FETCH_TEMPLATE_DETAIL,
    FETCH_TEMPLATE_DETAIL_FAILURE,
    FETCH_TEMPLATE_DETAIL_SUCCESS,
} from '../actionTypes';

import fetchCategories from './fetchCategories';
import fetchOrigins from './fetchOrigins';
import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleTemplateForm from './toggleTemplateForm';

const getLanguages = (tempLanguages) => {
    if (isEmpty(tempLanguages)) {
        return [];
    }
    return tempLanguages
        .filter((language) => language !== null && language.key !== null)
        .map((language) => language.key);
};

export default (item) => (dispatch) => {
    dispatch({ type: FETCH_TEMPLATE_DETAIL });
    dispatch(resetForm());
    dispatch(toggleTemplateForm(true));

    getTemplateDetail(item.id)
        .then((response) => {
            dispatch({
                type: FETCH_TEMPLATE_DETAIL_SUCCESS,
                payload: response.data,
            });

            const templateCountry = get(response.data, 'country.id', '');
            const values = {
                ...response.data,
                country:
                    !isNil(templateCountry) && !isEmpty(templateCountry)
                        ? templateCountry
                        : 'global',
                category: get(response.data, 'category.id', ''),
                languages: getLanguages(response.data.languages) || [],
                image_thumbnail: get(response.data, 'image_thumbnail', ''),
            };

            dispatch(fetchCategories(get(response.data, 'category.name', '')));
            dispatch(fetchOrigins(get(response.data, 'country.name', '')));

            dispatch(setIsNewRecord(false));
            dispatch(initializeForm(values));
            dispatch(setEnabledEdition(false));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEMPLATE_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t(
                    'An error occurred while querying the template'
                ),
            });

            dispatch(toggleTemplateForm(false));
        });
};
