import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';

import {
    TextField,
    InputAdornment,
    Tooltip,
    ClickAwayListener,
} from 'common/ui';

const Color = styled.div`
    background-color: ${({ color }) => color && color};
    border-radius: 16px;
    height: 20px;
    width: 20px;
`;

function rgba2hex({ a, b, g, r }) {
    const hex =
        (r | (1 << 8)).toString(16).slice(1) +
        (g | (1 << 8)).toString(16).slice(1) +
        (b | (1 << 8)).toString(16).slice(1) +
        ((a * 255) | (1 << 8)).toString(16).slice(1);
    return '#' + hex;
}

const ReduxColorPicker = React.memo(
    ({
        disabled = false,
        fullWidth = true,
        initialHexColor,
        input: { name, onChange, value },
        label,
        primaryColor,
        secondaryColor,
    }) => {
        const [open, setOpen] = React.useState(false);
        const color = value || initialHexColor;

        useEffect(() => {
            if (value === 'primary_color') onChange(primaryColor);
            else if (value === 'secondary_color') onChange(secondaryColor);
        });

        const handleTooltipClose = () => setOpen(false);
        const handleTooltipOpen = () => setOpen(true);
        const handleOnTextFieldChange = (e) => onChange(e.target.value);
        const handleChange = (color) => {
            const hex = color.rgb.a === 1 ? color.hex : rgba2hex(color.rgb);
            onChange(`${hex}`);
        };

        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={handleTooltipClose}
                        open={open}
                        PopperProps={{ disablePortal: true }}
                        title={
                            <ChromePicker
                                color={color}
                                onChange={handleChange}
                            />
                        }
                    >
                        <TextField
                            disabled={disabled}
                            fullWidth={fullWidth}
                            inputProps={{ maxLength: 9 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Color color={color} />
                                    </InputAdornment>
                                ),
                            }}
                            label={label}
                            name={name}
                            onChange={handleOnTextFieldChange}
                            onClick={handleTooltipOpen}
                            value={color}
                        />
                    </Tooltip>
                </div>
            </ClickAwayListener>
        );
    }
);

ReduxColorPicker.displayName = 'ColorPicker';

ReduxColorPicker.propTypes = {
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    initialHexColor: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
};

export default ReduxColorPicker;
