import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';

import * as brandFamilyActions from '../../brandFamilyForm/actions';

const FamiliesPreviewTable = ({
    brandId,
    handleClickUpdate,
    handleSortable,
    isEnabledEdition,
    isFetching,
    isNewRecord,
    items,
    orderdBy,
    pagination,
    sortedBy,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: !isNewRecord,
            label: t('Name'),
            width: '100%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={
                isNewRecord ? t('No added records') : t('No records found')
            }
            handleClickRow={
                isEnabledEdition
                    ? (item) => {
                          handleClickUpdate({
                              ...item,
                              brand: brandId,
                          });
                      }
                    : null
            }
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

FamiliesPreviewTable.propTypes = {
    brandId: PropTypes.string,
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isEnabledEdition: PropTypes.bool,
    isFetching: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    items: PropTypes.array,
    orderdBy: PropTypes.string,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (item) =>
        dispatch(brandFamilyActions.prepareUpdate(item)),
});

export default connect(null, mapDispatchToProps)(FamiliesPreviewTable);
