import { NAME } from './constants';

export const FETCH_CONTRACTED_DEMAND_UNITS = `${NAME}/FETCH_CONTRACTED_DEMAND_UNITS`;
export const FETCH_CONTRACTED_DEMAND_UNITS_FAILURE = `${NAME}/FETCH_CONTRACTED_DEMAND_UNITS_FAILURE`;
export const FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS = `${NAME}/FETCH_CONTRACTED_DEMAND_UNITS_SUCCESS`;

export const FETCH_CURRENCIES_BY_COUNTRIES = `${NAME}/FETCH_CURRENCIES_BY_COUNTRIES`;
export const FETCH_CURRENCIES_BY_COUNTRIES_FAILURE = `${NAME}/FETCH_CURRENCIES_BY_COUNTRIES_FAILURE`;
export const FETCH_CURRENCIES_BY_COUNTRIES_SUCCESS = `${NAME}/FETCH_CURRENCIES_BY_COUNTRIES_SUCCESS`;

export const FETCH_CUSTOM_RATES = `${NAME}/FETCH_CUSTOM_RATES`;
export const FETCH_CUSTOM_RATES_FAILURE = `${NAME}/FETCH_CUSTOM_RATES_FAILURE`;
export const FETCH_CUSTOM_RATES_SUCCESS = `${NAME}/FETCH_CUSTOM_RATES_SUCCESS`;

export const FETCH_CUSTOM_RATE_DETAIL = `${NAME}/FETCH_CUSTOM_RATE_DETAIL`;
export const FETCH_CUSTOM_RATE_DETAIL_FAILURE = `${NAME}/FETCH_CUSTOM_RATE_DETAIL_FAILURE`;
export const FETCH_CUSTOM_RATE_DETAIL_SUCCESS = `${NAME}/FETCH_CUSTOM_RATE_DETAIL_SUCCESS`;

export const FETCH_RATES = `${NAME}/FETCH_RATES`;
export const FETCH_RATES_FAILURE = `${NAME}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_SUCCESS = `${NAME}/FETCH_RATES_SUCCESS`;

export const FETCH_RATE_DETAIL = `${NAME}/FETCH_RATE_DETAIL`;
export const FETCH_RATE_DETAIL_FAILURE = `${NAME}/FETCH_RATE_DETAIL_FAILURE`;
export const FETCH_RATE_DETAIL_SUCCESS = `${NAME}/FETCH_RATE_DETAIL_SUCCESS`;

export const FETCH_SPAIN_EXCESS_DEMAND_UNITS = `${NAME}/FETCH_SPAIN_EXCESS_DEMAND_UNITS`;
export const FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE = `${NAME}/FETCH_SPAIN_EXCESS_DEMAND_UNITS_FAILURE`;
export const FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS = `${NAME}/FETCH_SPAIN_EXCESS_DEMAND_UNITS_SUCCESS`;

export const FETCH_SUPPLIER_UTILITIES = `${NAME}/FETCH_SUPPLIER_UTILITIES`;
export const FETCH_SUPPLIER_UTILITIES_FAILURE = `${NAME}/FETCH_SUPPLIER_UTILITIES_FAILURE`;
export const FETCH_SUPPLIER_UTILITIES_SUCCESS = `${NAME}/FETCH_SUPPLIER_UTILITIES_SUCCESS`;

export const FETCH_UNITS = `${NAME}/FETCH_UNITS`;
export const FETCH_UNITS_FAILURE = `${NAME}/FETCH_UNITS_FAILURE`;
export const FETCH_UNITS_SUCCESS = `${NAME}/FETCH_UNITS_SUCCESS`;

export const INITIALIZE_CUSTOM_RATE_FORM = `${NAME}/INITIALIZE_CUSTOM_RATE_FORM`;
export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_RATE_MODAL_FORM = `${NAME}/INITIALIZE_RATE_MODAL_FORM`;

export const OPEN_ITEM_FORM = `${NAME}/OPEN_ITEM_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_RATE_MODAL_FORM = `${NAME}/RESET_RATE_MODAL_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SAVE_CUSTOM_RATE = `${NAME}/SAVE_CUSTOM_RATE`;
export const SAVE_CUSTOM_RATE_FAILURE = `${NAME}/SAVE_CUSTOM_RATE_FAILURE`;
export const SAVE_CUSTOM_RATE_SUCCESS = `${NAME}/SAVE_CUSTOM_RATE_SUCCESS`;

export const SAVE_SUPPLIER_UTILITY = `${NAME}/SAVE_SUPPLIER_UTILITY`;
export const SAVE_SUPPLIER_UTILITY_FAILURE = `${NAME}/SAVE_SUPPLIER_UTILITY_FAILURE`;
export const SAVE_SUPPLIER_UTILITY_SUCCESS = `${NAME}/SAVE_SUPPLIER_UTILITY_SUCCESS`;

export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;
export const SET_FILTERS = `${NAME}/SET_FILTERS`;
export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_MODAL_IS_CREATE = `${NAME}/SET_MODAL_IS_CREATE`;
export const SET_MODAL_IS_OPEN = `${NAME}/SET_MODAL_IS_OPEN`;
export const SET_SUPPLIER_UTILITIES_MODAL_IS_OPEN = `${NAME}/SET_SUPPLIER_UTILITIES_MODAL_IS_OPEN`;
