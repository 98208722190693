import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Breadcrumbs, TitleIcon } from 'common/components';
import { Container, Stack } from 'common/ui';

const HeaderBreadcrumbs = ({
    currentTabLabel = '',
    disableGutters = true,
    isFetchingBreadcrumbs,
    locationsBreadcrumbs = [],
    title,
    titleIcon,
}) => {
    return (
        <Container maxWidth="" disableGutters={disableGutters}>
            <Stack mb={3} pl={3}>
                {title && titleIcon ? (
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={titleIcon}
                        title={title}
                    />
                ) : (
                    <TitleIcon title={title} />
                )}
                {!isEmpty(locationsBreadcrumbs) && (
                    <Breadcrumbs
                        current={{ label: currentTabLabel }}
                        isFetchingData={isFetchingBreadcrumbs}
                        locations={locationsBreadcrumbs}
                    />
                )}
            </Stack>
        </Container>
    );
};

HeaderBreadcrumbs.propTypes = {
    currentTabLabel: PropTypes.string,
    disableGutters: PropTypes.bool,
    isFetchingBreadcrumbs: PropTypes.bool,
    locationsBreadcrumbs: PropTypes.array,
    title: PropTypes.string,
    titleIcon: PropTypes.elementType,
};

export default HeaderBreadcrumbs;
