import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getImageUrlByStatus } from '../helpers';
import * as selectors from '../selectors';

const ProviderImage = ({ providerId, providersDictionary, style }) => {
    const theme = useTheme();

    const provider = providersDictionary?.[providerId];

    const url = getImageUrlByStatus(provider, theme?.palette?.mode === 'dark');

    if (!url) return null;
    return <img alt={provider?.name} src={url} style={style} />;
};

const mapStateToProps = createStructuredSelector({
    providersDictionary: selectors.getProvidersDictionary,
});

ProviderImage.propTypes = {
    providerId: PropTypes.string,
    providersDictionary: PropTypes.object,
    style: PropTypes.object,
};

export default connect(mapStateToProps)(ProviderImage);
