import styled from 'styled-components';

export default styled.h6`
    color: ${({ color = '#202253' }) => color};
    font-size: ${({ fontSize = '14' }) => fontSize}px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 0;
    padding: 0 15px;
    position: relative;

    &:before {
        background-color: ${({ indicatorColor = '#2f4dff' }) => indicatorColor};
        content: '';
        display: block;
        height: 14px;
        left: 0;
        margin-top: -7px;
        position: absolute;
        top: 50%;
        width: 2px;
    }
`;
