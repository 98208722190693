import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Typography } from 'common/ui';

import * as actions from '../actions';

import { InstallationTypeFormatter } from './formatters';

const BranchOfficeFormatter = (cell) => {
    return (
        <Typography
            variant="subtitle2"
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}
            title={cell && cell.name}
        >
            {cell && cell.name ? cell.name : '- - -'}
        </Typography>
    );
};

const PlansPreviewTable = ({
    companyId,
    handleClickUpdate,
    handleSortable,
    isFetching,
    items,
    sortedBy,
    pagination,
    orderdBy,
}) => {
    const { t } = useTranslation();
    const columns = [
        {
            dataField: 'name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Plan', { count: 1 }),
            width: '10%',
        },
        {
            dataField: 'installation_name',
            formatter: InstallationTypeFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Rate', { count: 1 }),
            width: '10%',
        },
        {
            dataField: 'branch_office',
            formatter: BranchOfficeFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Branch office', { count: 1 }),
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={(item) => handleClickUpdate(companyId, item)}
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

PlansPreviewTable.propTypes = {
    companyId: PropTypes.string,
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
    orderdBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (companyId, item) =>
        dispatch(actions.prepareUpdate(companyId, item)),
});

export default connect(null, mapDispatchToProps)(PlansPreviewTable);
