import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { ReduxFieldNumberInput } from 'common/components/form/redux';
import { IconButton, TableRow, TableCell, Typography } from 'common/ui';

import { parseClipboardData } from '../helpers';

const StyledTableRow = styled(TableRow)(() => ({
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
        backgroundColor: '#f7fcff',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const RateHistoryRow = ({
    changeInput,
    dynamicColumns = [],
    indexRow,
    rateHistoryRow,
    staticColumns = [],
    totalRows,
}) => {
    return (
        <StyledTableRow
            onClick={() => {
                for (var i = 0; i < totalRows; i++) {
                    if (i === indexRow) {
                        changeInput(
                            `rateDatagrid[${indexRow}].editable`,
                            !get(rateHistoryRow, 'editable', false)
                        );
                    } else {
                        changeInput(`rateDatagrid[${i}].editable`, false);
                    }
                }
            }}
        >
            {staticColumns.map((column) => {
                return (
                    <TableCell
                        key={column.dataField}
                        align={column.align}
                        style={{
                            width: column.width,
                            color: '#848bab',
                            fontSize: '12px',
                            fontWeight: 'bold',
                        }}
                    >
                        <Typography variant="subtitle2">
                            {get(rateHistoryRow, column.dataField, '')}
                        </Typography>
                    </TableCell>
                );
            })}
            {dynamicColumns.map((column, indexColumn) => {
                return (
                    <TableCell
                        key={column.dataField}
                        align={column.align}
                        style={{
                            width: column.width,
                            color: '#848bab',
                            fontSize: '12px',
                            fontWeight: 'bold',
                        }}
                        onClick={() => {}}
                    >
                        {rateHistoryRow.editable ? (
                            <Field
                                name={`rateDatagrid[${indexRow}].${column.dataField}`}
                                component={ReduxFieldNumberInput}
                                variant="filled"
                                required
                                decimalScale={4}
                                fixedDecimalScale
                                onClick={(e) => e.stopPropagation()}
                                onPaste={(e) => {
                                    if (!e || !e.target || !e.clipboardData)
                                        return;
                                    e.preventDefault();
                                    const data = parseClipboardData(
                                        e.clipboardData.getData('text/plain')
                                    );

                                    if (!isEmpty(data)) {
                                        data.forEach((row, i) => {
                                            row.forEach((cell, j) => {
                                                const currentRow = indexRow + i;
                                                const currentColumn =
                                                    indexColumn + j;

                                                /** Ignore overflowing row and column indexes */
                                                if (
                                                    currentRow < totalRows &&
                                                    currentColumn <
                                                        dynamicColumns.length
                                                ) {
                                                    changeInput(
                                                        `rateDatagrid[${currentRow}].${dynamicColumns[currentColumn].dataField}`,
                                                        cell
                                                    );
                                                }
                                            });
                                        });
                                    }
                                }}
                                size="small"
                            />
                        ) : (
                            <Typography variant="subtitle2">
                                {get(rateHistoryRow, column.dataField, '')}
                            </Typography>
                        )}
                    </TableCell>
                );
            })}
            <TableCell
                key={`action-column-row-${indexRow}`}
                align={'left'}
                style={{
                    width: '10%',
                    color: '#848bab',
                    fontSize: '12px',
                    fontWeight: 'bold',
                }}
            >
                <IconButton
                    color="secondary"
                    onClick={() => {
                        for (var i = 0; i < totalRows; i++) {
                            if (i === indexRow) {
                                changeInput(
                                    `rateDatagrid[${indexRow}].editable`,
                                    !get(rateHistoryRow, 'editable', false)
                                );
                            } else {
                                changeInput(
                                    `rateDatagrid[${i}].editable`,
                                    false
                                );
                            }
                        }
                    }}
                >
                    {!get(rateHistoryRow, 'editable', false) ? (
                        <EditIcon />
                    ) : (
                        <EditOffIcon />
                    )}
                </IconButton>
            </TableCell>
        </StyledTableRow>
    );
};

RateHistoryRow.propTypes = {
    changeInput: PropTypes.func,
    dynamicColumns: PropTypes.array,
    indexRow: PropTypes.number,
    rateHistoryRow: PropTypes.object,
    staticColumns: PropTypes.array,
    totalRows: PropTypes.number,
};

export default RateHistoryRow;
