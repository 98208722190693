import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CustomPagination = ({ rowsPerPageOptions }) => {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

    return (
        <Box
            display="flex"
            sx={{
                alignItems: 'center',
                flexDirection: { xs: 'column', md: 'row', gap: 2 },
                width: { xs: '100%', md: 'auto' },
            }}
        >
            {rowsPerPageOptions?.length > 0 && (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        m: 1,
                    }}
                >
                    <Typography fontWeight="bold" noWrap variant="body2">
                        {t('Items per page')}
                    </Typography>
                    <FormControl
                        variant="standard"
                        sx={{
                            minWidth: 60,
                        }}
                    >
                        <Select
                            value={pageSize}
                            onChange={(event) =>
                                apiRef.current.setPageSize(event?.target?.value)
                            }
                            sx={{ textAlign: 'center' }}
                        >
                            {rowsPerPageOptions.map((option) => (
                                <MenuItem
                                    key={`option-${option}`}
                                    value={option}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}
            <FormControl sx={{ m: 1 }}>
                <Pagination
                    color="primary"
                    count={pageCount}
                    page={page + 1}
                    onChange={(_, value) => apiRef.current.setPage(value - 1)}
                    size="small"
                />
            </FormControl>
        </Box>
    );
};

CustomPagination.defaultProps = {
    rowsPerPageOptions: [],
};

CustomPagination.propTypes = {
    rowsPerPageOptions: PropTypes.array,
};

export default CustomPagination;
