import { preAuth } from 'common/api/auth';

import {
    INIT_AUTH,
    INIT_AUTH_FAILURE,
    INIT_AUTH_SUCCESS,
} from '../actionTypes';

import setAuthValues from './setAuthValues';
import showSecondStepAuthForm from './showSecondStepAuthForm';

export default (data) => (dispatch) => {
    dispatch({ type: INIT_AUTH });

    preAuth(data)
        .then((response) => {
            dispatch({
                type: INIT_AUTH_SUCCESS,
                payload: response.data,
            });

            dispatch(
                setAuthValues({
                    ...data,
                    validationToken: response.data.auth_token,
                })
            );

            dispatch(showSecondStepAuthForm(true));
        })
        .catch((error) =>
            dispatch({
                type: INIT_AUTH_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
