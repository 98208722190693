import i18next from 'i18next';

import { getRateDetail } from 'common/api/rates';
import showToast from 'common/utils/showToast';

import {
    FETCH_RATE_DETAIL,
    FETCH_RATE_DETAIL_FAILURE,
    FETCH_RATE_DETAIL_SUCCESS,
} from '../actionTypes';

import initializeForm from './initializeForm';
import resetForm from './resetForm';
import setEnabledEdition from './setEnabledEdition';
import setIsNewRecord from './setIsNewRecord';
import toggleItemForm from './toggleItemForm';

export default (item) => (dispatch) => {
    dispatch({ type: FETCH_RATE_DETAIL });
    dispatch(resetForm());
    dispatch(toggleItemForm(true));

    getRateDetail(item.id)
        .then((response) => {
            dispatch({
                type: FETCH_RATE_DETAIL_SUCCESS,
                payload: response.data,
            });

            const values = {
                ...response.data,
            };

            dispatch(setIsNewRecord(false));
            dispatch(initializeForm(values));
            dispatch(setEnabledEdition(false));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_RATE_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t('An error occurred while querying the rate'),
            });

            dispatch(toggleItemForm(false));
        });
};
