import { number, object, string } from 'yup';

import schemaValidate from 'common/utils/schemaValidate';

export default schemaValidate(
    ({ REQUIRED_TEXT, NUMBER_TYPE_TEXT, MAX_100_PERCENTAGE_VALIDATION_TEXT }) =>
        object().shape({
            name: string().required(REQUIRED_TEXT).nullable(),
            level: string().required(REQUIRED_TEXT).nullable(),
            contacts_visualization_level: string()
                .required(REQUIRED_TEXT)
                .nullable(),
            maximum_discount: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .max(100, MAX_100_PERCENTAGE_VALIDATION_TEXT)
                .nullable(),
        })
);
