const Grid = () => ({
    MuiGrid: {
        defaultProps: {
            columns: 12,
            spacing: 2,
        },
        styleOverrides: {
            root: {
                '> .MuiCard-root': { marginBottom: 0 },
            },
        },
    },
});

export default Grid;
