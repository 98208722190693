import i18next from 'i18next';
import isArray from 'lodash/isArray';

import { updateAccessory } from 'common/api/accessories';
import uploadFile from 'common/modules/productDatasheet/actions/uploadFile';
import uploadThumbnail from 'common/modules/productThumbnail/actions/uploadThumbnail';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';

import returnCatalog from './returnCatalog';

export default (filterData, values, id) => (dispatch) => {
    dispatch({ type: SAVE });

    updateAccessory(values, id)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            const productId = response?.data?.id;
            let promiseList = [];
            if (isArray(values?.thumbnail.image_thumbnail)) {
                promiseList.push(
                    dispatch(uploadThumbnail(values?.thumbnail, productId))
                );
            }
            if (isArray(values?.datasheet.datasheet_archive)) {
                promiseList.push(
                    dispatch(uploadFile(values?.datasheet, productId))
                );
            }
            Promise.all(promiseList)
                .then(() => {
                    dispatch(returnCatalog(filterData));
                    showToast();
                })
                .catch();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while updating the product'),
            });
        });
};
