export const NAME = 'fine-tuning-AI';

export const BASE_MODELS = [
    {
        disabled: true,
        label: 'Curie',
        optionValue: 0,
    },
    {
        disabled: true,
        label: 'Davinci',
        optionValue: 1,
    },
    {
        disabled: true,
        label: 'Ada',
        optionValue: 2,
    },
    {
        disabled: true,
        label: 'Babbage',
        optionValue: 3,
    },
    {
        disabled: false,
        label: 'GPT-3.5 Turbo',
        optionValue: 4,
    },
    {
        disabled: false,
        label: 'GPT-4 2024-08-06',
        optionValue: 5,
    },
    {
        disabled: false,
        label: 'GPT-4 Mini',
        optionValue: 6,
    },
    {
        disabled: false,
        label: 'GPT-4 0613',
        optionValue: 7,
    },
];

export const MODEL_STATUS_KEYS = {
    INACTIVE: 0,
    READY: 1,
    TRAINING: 2,
    TRAINED: 3,
    TRAINING_FAILED: 4,
    ERROR: 5,
};

export const MODEL_STATUS = {
    [MODEL_STATUS_KEYS.INACTIVE]: { color: '#C4C4C4', label: 'Inactive' },
    [MODEL_STATUS_KEYS.READY]: { color: '#2F80ED', label: 'Ready' },
    [MODEL_STATUS_KEYS.TRAINING]: { color: '#F2C94C', label: 'In training' },
    [MODEL_STATUS_KEYS.TRAINED]: { color: '#44b700', label: 'Trained' },
    [MODEL_STATUS_KEYS.TRAINING_FAILED]: { color: '#EB5757', label: 'Failed' },
    [MODEL_STATUS_KEYS.ERROR]: { color: '#EB5757', label: 'Error' },
};
