import update from 'immutability-helper';

import {
    ADD_MESSAGE,
    EDIT_MESSAGE,
    FETCH_FILE,
    FETCH_FILE_FAILURE,
    FETCH_FILE_SUCCESS,
    FETCH_TRAINING_DATA,
    FETCH_TRAINING_DATA_FAILURE,
    FETCH_TRAINING_DATA_SUCCESS,
    FETCH_TRAINING_DATA_TAGS,
    FETCH_TRAINING_DATA_TAGS_FAILURE,
    FETCH_TRAINING_DATA_TAGS_SUCCESS,
    INITIALIZE,
    INITIALIZE_TAGS_FORM,
    RESET,
    RESET_FORM,
    RESET_TAGS_FORM,
    SAVE_TRAINING_DATA,
    SAVE_TRAINING_DATA_FAILURE,
    SAVE_TRAINING_DATA_SUCCESS,
    SAVE_TRAINING_DATA_TAGS,
    SAVE_TRAINING_DATA_TAGS_FAILURE,
    SAVE_TRAINING_DATA_TAGS_SUCCESS,
    SEND_MESSAGES,
    SEND_MESSAGES_FAILURE,
    SEND_MESSAGES_SUCCESS,
    SET_IS_OPEN_TAGS_FORM,
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    chatInitialValues: { content: '' },
    chatMessages: [],
    deleteTrainingData: { errors: [], isDeleting: false },
    fetchFile: { data: {}, errors: [], isFetching: false },
    fetchTrainingData: { data: {}, isFetching: false, errors: [] },
    fetchTrainingDataTags: { data: [], errors: [], isFetching: false },
    initialValues: { tabs: [], values: [] },
    isOpenTagsForm: false,
    saveTrainingData: { errors: [], isSaving: false },
    saveTrainingDataTags: { errors: [], isSaving: false },
    sendMessages: { data: {}, errors: [], isSending: false },
    tagsFormInitialValues: { tags: [] },
    uploadFile: { data: {}, errors: [], isUploading: false },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_MESSAGE:
            return update(state, {
                chatMessages: { $push: [action.payload] },
            });

        case EDIT_MESSAGE: {
            const { content, index, role } = action?.payload || {};

            return update(state, {
                chatMessages: {
                    [index]: {
                        role: { $set: state.chatMessages[index].role + role },
                        content: {
                            $set: state.chatMessages[index].content + content,
                        },
                    },
                },
            });
        }

        case FETCH_FILE:
            return update(state, {
                fetchFile: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_FILE_FAILURE:
            return update(state, {
                fetchFile: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_FILE_SUCCESS:
            return update(state, {
                fetchFile: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_TRAINING_DATA:
            return update(state, {
                fetchTrainingData: { $merge: { errors: [], isFetching: true } },
            });

        case FETCH_TRAINING_DATA_FAILURE:
            return update(state, {
                fetchTrainingData: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_TRAINING_DATA_SUCCESS:
            return update(state, {
                fetchTrainingData: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case FETCH_TRAINING_DATA_TAGS:
            return update(state, {
                fetchTrainingDataTags: {
                    $merge: { errors: [], isFetching: true },
                },
            });

        case FETCH_TRAINING_DATA_TAGS_FAILURE:
            return update(state, {
                fetchTrainingDataTags: {
                    $merge: { errors: action.payload, isFetching: false },
                },
            });

        case FETCH_TRAINING_DATA_TAGS_SUCCESS:
            return update(state, {
                fetchTrainingDataTags: {
                    $merge: { data: action.payload, isFetching: false },
                },
            });

        case INITIALIZE:
            return update(state, {
                initialValues: { $merge: action.payload },
            });

        case INITIALIZE_TAGS_FORM:
            return update(state, {
                tagsFormInitialValues: { $merge: action.payload },
            });

        case RESET:
            return update(state, { $set: INITIAL_STATE });

        case RESET_FORM:
            return update(state, {
                initialValues: { $set: INITIAL_STATE.initialValues },
            });

        case RESET_TAGS_FORM:
            return update(state, {
                tagsFormInitialValues: {
                    $set: INITIAL_STATE.tagsFormInitialValues,
                },
            });

        case SAVE_TRAINING_DATA:
            return update(state, {
                saveTrainingData: {
                    $merge: { errors: [], isSaving: true },
                },
            });

        case SAVE_TRAINING_DATA_FAILURE:
            return update(state, {
                saveTrainingData: {
                    $merge: { errors: action.payload, isSaving: false },
                },
            });

        case SAVE_TRAINING_DATA_SUCCESS:
            return update(state, {
                saveTrainingData: {
                    $merge: { data: action.payload, isSaving: false },
                },
            });

        case SAVE_TRAINING_DATA_TAGS:
            return update(state, {
                saveTrainingDataTags: {
                    $merge: { errors: [], isSaving: true },
                },
            });

        case SAVE_TRAINING_DATA_TAGS_FAILURE:
            return update(state, {
                saveTrainingDataTags: {
                    $merge: { errors: action.payload, isSaving: false },
                },
            });

        case SAVE_TRAINING_DATA_TAGS_SUCCESS:
            return update(state, {
                saveTrainingDataTags: {
                    $merge: { data: action.payload, isSaving: false },
                },
                fetchTrainingData: {
                    data: { $merge: { tags: action?.payload?.tags } },
                },
            });

        case SEND_MESSAGES:
            return update(state, {
                sendMessages: { $merge: { errors: [], isSending: true } },
            });

        case SEND_MESSAGES_FAILURE:
            return update(state, {
                sendMessages: {
                    $merge: { errors: action.payload, isSending: false },
                },
            });

        case SEND_MESSAGES_SUCCESS:
            return update(state, {
                sendMessages: {
                    $merge: { data: action.payload, isSending: false },
                },
            });

        case SET_IS_OPEN_TAGS_FORM:
            return update(state, {
                isOpenTagsForm: { $set: action.payload },
            });

        case UPLOAD_FILE:
            return update(state, {
                uploadFile: { $merge: { errors: [], isUploading: true } },
            });

        case UPLOAD_FILE_FAILURE:
            return update(state, {
                uploadFile: {
                    $merge: { errors: action.payload, isUploading: false },
                },
            });

        case UPLOAD_FILE_SUCCESS:
            return update(state, {
                uploadFile: {
                    $merge: { data: action.payload, isUploading: false },
                },
            });

        default:
            return state;
    }
};
