import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteFamily from './deleteFamily';
import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (item, callback) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept'),
        onSuccess: () => dispatch(deleteFamily(item.id, callback)),
        title: i18next.t('Delete family'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(toggleModal(false));
    dispatch(resetForm());
    dispatch(alerts.actions.show(dataAlert));
};
