import isArray from 'lodash/isArray';

import { uploadFile } from 'common/api/productsAI';

import {
    UPLOAD_DATASHEET,
    UPLOAD_DATASHEET_FAILURE,
    UPLOAD_DATASHEET_SUCCESS,
    SET_CLIPBOARD_DATA,
} from '../actionTypes';

export default (values, callback) => (dispatch) => {
    const { file, type } = values;
    const data = { type };

    if (isArray(file)) data.pdf_file = values.file[0];

    dispatch({ type: UPLOAD_DATASHEET });

    uploadFile(data)
        .then((response) => {
            dispatch({
                payload: response.data.data,
                type: UPLOAD_DATASHEET_SUCCESS,
            });

            dispatch({
                payload: { data: response.data.data, type },
                type: SET_CLIPBOARD_DATA,
            });

            if (callback) callback(response.data.data);
        })
        .catch((error) =>
            dispatch({
                payload: error?.response?.data?.errors || [error.message],
                type: UPLOAD_DATASHEET_FAILURE,
            })
        );
};
