import React, { useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CardContainer from 'common/components/CardContainer';
import { Grid } from 'common/ui';

import * as selectors from '../selectors';

import ProjectsTotals from './ProjectsTotals';
import ProposalsFilter from './ProposalsFilter';
import ProposalsTotals from './ProposalsTotals';

const CardTotalProposalIssued = ({
    agents,
    companyId,
    currency,
    isFetchingProjectsCounters,
    isFetchingProposalTotals,
}) => {
    const { t } = useTranslation();
    const [filterBy, setFilterBy] = useState('projects');

    const showProposals = filterBy === 'proposals';

    return (
        <Grid container>
            <Grid item xs={12}>
                <CardContainer
                    title={
                        showProposals
                            ? t('Total proposals issued')
                            : t('Total projects issued')
                    }
                    withDivider
                >
                    <>
                        <ProposalsFilter
                            agents={agents}
                            companyId={companyId}
                            filterBy={filterBy}
                            setFilterBy={setFilterBy}
                            showProposals={showProposals}
                        />
                        {showProposals ? (
                            <ProposalsTotals
                                currencyIso={get(
                                    currency,
                                    'abbreviation',
                                    'MXN'
                                )}
                                currencyLocale={get(
                                    currency,
                                    'locale',
                                    'es-MX'
                                )}
                                currencySymbol={get(currency, 'symbol', '$')}
                                readyPlaceholder={isFetchingProposalTotals}
                            />
                        ) : (
                            <ProjectsTotals
                                currencyLocale={get(
                                    currency,
                                    'locale',
                                    'es-MX'
                                )}
                                readyPlaceholder={isFetchingProjectsCounters}
                            />
                        )}
                    </>
                </CardContainer>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsData,
    isFetchingProjectsCounters: selectors.getIsFetchingProjectsCounters,
    isFetchingProposalTotals: selectors.getIsFetchingProposalsTotals,
});

CardTotalProposalIssued.propTypes = {
    agents: PropTypes.array,
    companyId: PropTypes.string,
    currency: PropTypes.object,
    isFetchingProjectsCounters: PropTypes.bool,
    isFetchingProposalTotals: PropTypes.bool,
};

export default connect(mapStateToProps, null)(CardTotalProposalIssued);
