import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { Box, Tooltip, Typography } from 'common/ui';
import { getSubscriptionStatusDotColor } from 'common/utils/helpers';

import { getStatusName } from '../helpers';

const SubscriptionStatusFormatter = ({ row }) => {
    const dueInvoicesCount = get(row, 'due_invoices_count', 0);
    const hasPaymentDue = dueInvoicesCount > 0;
    const status = get(row, 'subscription_status', null);
    const statusName = getStatusName(hasPaymentDue, status);
    return (
        <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
            {status && (
                <CircleIcon
                    sx={{
                        color: getSubscriptionStatusDotColor(status),
                        fontSize: 16,
                    }}
                />
            )}
            <Tooltip title={status ? statusName : '- - -'}>
                <Typography
                    variant="body2"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '8rem',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {status ? statusName : '- - -'}
                </Typography>
            </Tooltip>
        </Box>
    );
};

SubscriptionStatusFormatter.propTypes = {
    row: PropTypes.object,
};

export default SubscriptionStatusFormatter;
