import React from 'react';

import PropTypes from 'prop-types';

import { Box, Stack, Skeleton, Typography } from 'common/ui';

const CustomSkeleton = () => (
    <Typography variant="h3">
        <Skeleton></Skeleton>
    </Typography>
);

const Placeholder = () => (
    <Box>
        <Stack>
            <CustomSkeleton />
        </Stack>
        <Stack>
            <CustomSkeleton />
        </Stack>
        <Stack>
            <CustomSkeleton />
        </Stack>
    </Box>
);

const PlaceholderHistory = ({ children, ready }) => {
    return <>{ready ? <Placeholder /> : children}</>;
};

PlaceholderHistory.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderHistory;
