import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchTags = createSelector(
    getModel,
    (model) => model?.fetchTags
);

export const getFetchTagsData = createSelector(
    getFetchTags,
    (fetchTags) => fetchTags?.data || []
);

export const getIsFetchingTags = createSelector(
    getFetchTags,
    (fetchTags) => fetchTags?.isFetching
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model?.isOpenModal
);

export const getIsNewTag = createSelector(getModel, (model) => model?.isNewTag);

export const getInitialValues = createSelector(
    getModel,
    (model) => model?.initialValues
);

export const getSaveTag = createSelector(getModel, (model) => model?.saveTag);

export const getIsSavingTag = createSelector(
    getSaveTag,
    (saveTag) => saveTag?.isSaving
);

export const getDeleteTag = createSelector(
    getModel,
    (model) => model?.deleteTag
);

export const getIsDeletingTag = createSelector(
    getDeleteTag,
    (deleteTag) => deleteTag?.isDeleting
);
