import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteUser from './deleteUser';
import resetForm from './resetForm';
import toggleModalUser from './toggleModalUser';

export default (item) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Accept') /* 
        messages: [
            i18next.t('Are you sure to remove the item "{{name}}"?', {
                name: `${item.first_name} ${item.last_name}`,
            }),
        ], */,
        onSuccess: () => dispatch(deleteUser(item.id)),
        title: i18next.t('Delete user'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
        haveTextCaptcha: true,
        textToValidate: i18next.t('Delete').toUpperCase(),
    };

    dispatch(toggleModalUser(false));
    dispatch(resetForm());
    dispatch(alerts.actions.show(dataAlert));
};
