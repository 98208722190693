import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getPlanDetail } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    FETCH_PLAN_DETAIL,
    FETCH_PLAN_DETAIL_FAILURE,
    FETCH_PLAN_DETAIL_SUCCESS,
} from '../actionTypes';

import fetchPlanHistory from './fetchPlanHistory';
import initializeForm from './initializeForm';
import resetForm from './resetForm';
import showEditionForm from './showEditionForm';

export default (companyId, plan) => (dispatch) => {
    dispatch({ type: FETCH_PLAN_DETAIL });
    dispatch(resetForm());
    dispatch(showEditionForm(true));
    dispatch(fetchPlanHistory(companyId, plan));

    getPlanDetail(companyId, plan.id)
        .then((response) => {
            dispatch({
                type: FETCH_PLAN_DETAIL_SUCCESS,
                payload: response.data,
            });

            const values = {
                ...response.data,
                branch_office: response.data.branch_office.name,
                custom_offer_template:
                    response.data.custom_offer_template.title,
                installation_type: response.data.installation_type.map(
                    (installation_type) => {
                        return installation_type.installation_type.key;
                    }
                ),
                panel: get(response.data, 'panel.id', ''),
                inverter_brand: get(response.data, 'inverter_brand.id', ''),
                structure: response.data.structure.name,
                accessories: !isEmpty(response.data.accessories)
                    ? response.data.accessories.map((elem) => ({
                          item: elem.name,
                      }))
                    : [{}],
                workforce_and_electric_materials: !isEmpty(
                    response.data.workforce_and_electric_materials
                )
                    ? response.data.workforce_and_electric_materials.map(
                          (elem) => ({
                              item: elem.name,
                          })
                      )
                    : [{}],
                additionals: !isEmpty(response.data.additionals)
                    ? response.data.additionals.map((elem) => ({
                          item: elem.name,
                      }))
                    : [{}],
                saving_percentage: response.data.saving_percentage,
                company_id: companyId,
            };

            dispatch(initializeForm(values));
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PLAN_DETAIL_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                type: 'error',
                body: i18next.t('An error occurred while querying the plan'),
            });

            dispatch(showEditionForm(false));
        });
};
