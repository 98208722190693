// Status - Projects and Power Stations
export const STATUS_KEYS = {
    OFFLINE: 2,
    ONLINE: 0,
    UNSTABLE: 1,
};

export const STATUS_CONFIG = {
    [STATUS_KEYS.UNSTABLE]: { color: '#fdcb6e', label: 'Unstable' },
    [STATUS_KEYS.ONLINE]: { color: '#00b894', label: 'Online' },
    [STATUS_KEYS.OFFLINE]: { color: '#d63031', label: 'Offline' },
};
