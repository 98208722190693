import API from '..';
const ENTITY = 'system';

export const getRoleLevels = (companyId) =>
    API.get(`/api/v1/companies/${ENTITY}/roles/`, {
        params: {
            company: companyId,
        },
    });
export const getRoleLevelDetails = (levelId) =>
    API.get(`/api/v1/${ENTITY}/roles/${levelId}/`);
export const getRoles = () => API.get(`/api/v1/${ENTITY}/roles/`);
