import { filterInverters } from 'common/api/inverters';

import {
    FETCH_INVERTERS,
    FETCH_INVERTERS_FAILURE,
    FETCH_INVERTERS_SUCCESS,
} from '../actionTypes';

export default ({
        brandName = '',
        maxPower = null,
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
    } = {}) =>
    (dispatch) => {
        dispatch({ type: FETCH_INVERTERS });

        filterInverters({
            brandName,
            maxPower,
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
        })
            .then((response) =>
                dispatch({
                    type: FETCH_INVERTERS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_INVERTERS_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_INVERTERS_SUCCESS,
                    payload: { data: [] },
                });
            });
    };
