import i18next from 'i18next';

import { createFont } from 'common/api/templatesFonts';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchFonts from './fetchFonts';
import resetForm from './resetForm';
import toggleProductForm from './toggleProductForm';

const getFontNames = (fonts) => {
    return fonts.reduce(
        (acc, current) => [...acc, current?.name?.toLowerCase()],
        []
    );
};

const getFontUrls = (fonts) => {
    return fonts.reduce(
        (acc, current) => [...acc, current?.url?.toLowerCase()],
        []
    );
};

export default (filterData, values) => (dispatch, getState) => {
    const fonts = selectors.getFontsData(getState());
    const fontNames = getFontNames(fonts);
    const fontUrls = getFontUrls(fonts);
    if (fontNames.includes(values?.name?.toLowerCase())) {
        dispatch({
            type: SAVE_FAILURE,
            payload: [i18next.t('The font name is already in use')],
        });
        return false;
    }
    if (fontUrls.includes(values?.url?.toLowerCase())) {
        dispatch({
            type: SAVE_FAILURE,
            payload: [i18next.t('The font url is already in use')],
        });
        return false;
    }
    dispatch({ type: SAVE });
    createFont(values)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(fetchFonts(filterData));
            dispatch(toggleProductForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('An error occurred while creating the product'),
            });
        });
};
