import { NAME } from './constants';

export const FETCH_CATEGORIES = `${NAME}/FETCH_CATEGORIES`;
export const FETCH_CATEGORIES_FAILURE = `${NAME}/FETCH_CATEGORIES_FAILURE`;
export const FETCH_CATEGORIES_SUCCESS = `${NAME}/FETCH_CATEGORIES_SUCCESS`;

export const FETCH_CATEGORY_DETAIL = `${NAME}/FETCH_CATEGORY_DETAIL`;
export const FETCH_CATEGORY_DETAIL_FAILURE = `${NAME}/FETCH_CATEGORY_DETAIL_FAILURE`;
export const FETCH_CATEGORY_DETAIL_SUCCESS = `${NAME}/FETCH_CATEGORY_DETAIL_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_CATEGORY_FORM = `${NAME}/OPEN_CATEGORY_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const SET_FILTERS = `${NAME}/SET_FILTERS`;
export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_ENABLED_EDITION = `${NAME}/SET_ENABLED_EDITION`;

export const DELETE_CATEGORY = `${NAME}/DELETE_CATEGORY`;
export const DELETE_CATEGORY_SUCCESS = `${NAME}/DELETE_CATEGORY_SUCCESS`;
export const DELETE_CATEGORY_FAILURE = `${NAME}/DELETE_CATEGORY_FAILURE`;
