import { createSelector } from 'reselect';

import { arraytoDictionary } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* Delete user */
export const getDeleteUser = createSelector(
    getModel,
    (model) => model.deleteUser
);

export const getIsDeletingUser = createSelector(
    getDeleteUser,
    (deleteUser) => deleteUser.isDeleting
);

export const getDeleteUserErrors = createSelector(
    getDeleteUser,
    (deleteUser) => deleteUser.errors
);

/* Fetch roles */
export const getFetchRoles = createSelector(
    getModel,
    (model) => model.fetchRoles
);
export const getIsFetchingRoles = createSelector(
    getFetchRoles,
    (model) => model.isFetching
);
export const getFetchRolesData = createSelector(
    getFetchRoles,
    (model) => model.data
);
export const getFetchRolesDictionary = createSelector(
    getFetchRolesData,
    (roles) => arraytoDictionary(roles, 'id')
);

/* Fetch users */
export const getFetchUsers = createSelector(
    getModel,
    (model) => model.fetchUsers
);
export const getIsFetchingUsers = createSelector(
    getFetchUsers,
    (model) => model.isFetching
);
export const getFetchUsersData = createSelector(
    getFetchUsers,
    (model) => model?.data?.data
);
export const getFetchUsersPagination = createSelector(
    getFetchUsers,
    (model) => model?.data?.pagination
);

/* User details */
export const getUserDetails = createSelector(
    getModel,
    (model) => model.fetchUserDetails
);

export const getIsFetchingUserDetails = createSelector(
    getUserDetails,
    (model) => model.isFetching
);

export const getUserDetailsData = createSelector(
    getUserDetails,
    (model) => model.data || {}
);

export const getUserDetailsErrors = createSelector(
    getUserDetails,
    (model) => model.errors
);

/* Save user */
export const getSaveUser = createSelector(getModel, (model) => model.saveUser);

export const getIsSavingUser = createSelector(
    getSaveUser,
    (saveUser) => saveUser.isSaving
);

export const getSaveUserData = createSelector(
    getSaveUser,
    (saveUser) => saveUser.data
);

export const getSaveUserErrors = createSelector(
    getSaveUser,
    (saveUser) => saveUser.errors
);

export const getisOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);
