import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { createTemplate } from 'common/api/templatesCatalog';
import showToast from 'common/utils/showToast';

import { SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import * as selectors from '../selectors';

import fetchTemplates from './fetchTemplates';
import resetForm from './resetForm';
import toggleTemplateForm from './toggleTemplateForm';

export default (values) => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    var newValues = {
        title: values.title,
        country: values.country === 'global' ? '' : values.country,
        category: values.category,
        company_id: values.company_id,
        custom_offer_template: values.custom_offer_template,
        locked: values.locked,
    };

    if (Array.isArray(values.languages)) {
        newValues.languages = !isEmpty(values.languages)
            ? values.languages.join()
            : '';
    }

    if (Array.isArray(values.image_thumbnail)) {
        newValues.image_thumbnail =
            values.image_thumbnail && values.image_thumbnail[0];
    }

    createTemplate(newValues)
        .then((response) => {
            dispatch({ type: SAVE_SUCCESS, payload: response.data });
            dispatch(
                fetchTemplates(
                    filters.page,
                    filters.orderBy,
                    filters.sortBy,
                    filters.searchStr
                )
            );
            dispatch(toggleTemplateForm(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch({
                type: SAVE_FAILURE,
                payload: error.response.data.errors,
            });

            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t(
                    'An error occurred while creating the template'
                ),
            });
        });
};
