import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomSelect from 'common/components/form/CustomSelect';
import { Stack } from 'common/ui';

import * as selectors from '../selectors';

import ColumnWrapper from './ColumnWrapper';
import FileContainer from './FileContainer';
import ToggleDevMode from './ToggleDevMode';

const ElectricBillColumn = ({
    disabled,
    handleUploadFile,
    isFetchingFile,
    page,
    pageOptions,
    setPage,
    trainingDataFile,
    trainingDataValues,
}) => {
    const [isDevMode, setIsDevMode] = useState(false);
    const { t } = useTranslation();

    const prompt = trainingDataValues?.[page]?.prompt || '';

    return (
        <>
            <Stack
                direction="row"
                gap={1}
                p={2}
                sx={{ alignItems: 'center', '& > div': { flex: 1 } }}
            >
                <CustomSelect
                    disabled={disabled}
                    label={t('Page')}
                    onChange={(e) => setPage(e.target.value)}
                    options={pageOptions}
                    value={page}
                    variant="outlined"
                />
                <ToggleDevMode
                    isDevMode={isDevMode}
                    setIsDevMode={setIsDevMode}
                />
            </Stack>

            {isDevMode ? (
                <ColumnWrapper>{prompt}</ColumnWrapper>
            ) : (
                <FileContainer
                    disabled={disabled}
                    isFetchingFile={isFetchingFile}
                    page={page}
                    trainingDataFile={trainingDataFile}
                    uploadFile={handleUploadFile}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingFile: selectors.getFetchFileIsFetching,
    trainingDataFile: selectors.getFetchFileData,
});

ElectricBillColumn.propTypes = {
    disabled: PropTypes.bool,
    handleUploadFile: PropTypes.func,
    isFetchingFile: PropTypes.bool,
    page: PropTypes.number,
    pageOptions: PropTypes.array,
    setPage: PropTypes.func,
    trainingDataFile: PropTypes.object,
    trainingDataValues: PropTypes.array,
};

export default connect(mapStateToProps)(ElectricBillColumn);
