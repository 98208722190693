import { filterOrigins } from 'common/api/suppliers';

import {
    FETCH_ORIGINS,
    FETCH_ORIGINS_SUCCESS,
    FETCH_ORIGINS_FAILURE,
} from '../actionTypes';

export default () => (dispatch) => {
    dispatch({ type: FETCH_ORIGINS });

    filterOrigins({
        page: 1,
        perPage: 1000,
    })
        .then((response) => {
            dispatch({
                type: FETCH_ORIGINS_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_ORIGINS_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
