import createBattery from './createBattery';
import updateBattery from './updateBattery';

export default (filterData, values) => (dispatch) => {
    const newValues = {
        general: {
            brand: values.brand,
            family: values.family,
            type: values.product_type,
            name: values.name,
            code: values.code,
            cost: values.cost,
        },
        tags: {
            storage_cap_max: values.storage_cap_max,
            cycles: values.cycles,
            P_charge_max: values.P_charge_max,
            I_charge_max: values.I_charge_max,
            DoD: values.DoD,
            c_nom: values.c_nom,
            P_discharge_max: values.P_discharge_max,
            I_discharge_max: values.I_discharge_max,
        },
        thumbnail: {
            image_thumbnail: values.image_thumbnail,
        },
        datasheet: {
            datasheet_archive: values.datasheet_archive,
            datasheet_name: values.datasheet_name,
        },
    };

    if (values.id) dispatch(updateBattery(filterData, newValues, values.id));
    else dispatch(createBattery(filterData, newValues));
};
