import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HiddenComponent } from 'common/components';
import { IconButton, Grid, Typography, Tooltip } from 'common/ui';

import { NAME } from '../constants';

import InputCheck from './InputCheck';

const StyledRow = styled(Grid)`
    background: rgba(238, 244, 250, 0.25);
    &.striped {
        background: rgba(238, 244, 250, 0.15);
    }
`;

const StyledCol = styled(Grid)`
    align-items: center;
    border-right: 1px solid #eff1fb;
    border-top: 1px solid #eff1fb;
    display: flex;
    height: auto;
`;

const ItemPermission = ({
    categories,
    changeInput,
    checksNames,
    formValues,
    group,
    index,
    isDisabled,
    label,
}) => {
    const typeCreate = get(formValues, 'type', null);
    const ownerTypeCreate = get(formValues, 'owner_type', null);

    const isGeneralManager =
        parseInt(typeCreate) === 0 && parseInt(ownerTypeCreate) === 1;

    const isSuperAdmin =
        parseInt(typeCreate) === 3 && parseInt(ownerTypeCreate) === 2;

    const isBranchOfficeManager =
        parseInt(typeCreate) === 1 && ownerTypeCreate == null;

    const isSalesmanAgent =
        parseInt(typeCreate) === 2 && ownerTypeCreate == null;

    const prefixRole = isGeneralManager
        ? 'general_manager'
        : isSuperAdmin
        ? 'superadmin'
        : isBranchOfficeManager
        ? 'branch_office_manager'
        : isSalesmanAgent
        ? 'salesman_agent'
        : 'owner';

    const isStriped = (index) => {
        return index % 2 != 0 ? 'striped' : 'normal';
    };

    const isVisible = (group, permission) => {
        return group.permissions.includes(permission);
    };

    const isSelected = (categories, input, key, permission) => {
        let counter = 1;
        let total_categories = 0;
        const value = get(formValues, input, false);
        categories.forEach((category) => {
            category.groups.forEach((group) => {
                if (
                    group[prefixRole].forbidden.includes(permission) ||
                    get(
                        formValues,
                        `groups.${group.id}.${prefixRole}.${key}`,
                        false
                    )
                ) {
                    counter += 1;
                }
                total_categories += 1;
            });
        });

        if (value) {
            counter = counter - 2;
        }
        return counter >= total_categories;
    };

    const autoCheckView = (input, group) => {
        const value = get(formValues, input, false);
        if (!value) {
            changeInput(
                `groups.${group.id}.${prefixRole}.permission_view`,
                !value
            );
            changeInput(
                checksNames[0],
                isSelected(categories, input, 'permission_view', 'VIEW')
            );
        }
    };

    const removeSelected = (input, group) => {
        const value = get(formValues, input, false);
        if (value) {
            removeSelectedAdd(input, group);
            removeSelectedEdit(input, group);
            removeSelectedDelete(input, group);
        }
    };

    const removeSelectedAdd = (input, group) => {
        if (
            !group[prefixRole].minimum.includes('VIEW') &&
            !group[prefixRole].forbidden.includes('ADD')
        ) {
            changeInput(
                `groups.${group.id}.${prefixRole}.permission_add`,
                false
            );
            changeInput(
                checksNames[1],
                isSelected(categories, input, 'permission_add', 'ADD')
            );
        }
    };

    const removeSelectedEdit = (input, group) => {
        if (
            !group[prefixRole].minimum.includes('EDIT') &&
            !group[prefixRole].forbidden.includes('EDIT')
        ) {
            changeInput(
                `groups.${group.id}.${prefixRole}.permission_change`,
                false
            );
            changeInput(
                checksNames[2],
                isSelected(categories, input, 'permission_change', 'EDIT')
            );
        }
    };

    const removeSelectedDelete = (input, group) => {
        if (
            !group[prefixRole].minimum.includes('DELETE') &&
            !group[prefixRole].forbidden.includes('DELETE')
        ) {
            changeInput(
                `groups.${group.id}.${prefixRole}.permission_delete`,
                false
            );
            changeInput(
                checksNames[3],
                isSelected(categories, input, 'permission_delete', 'DELETE')
            );
        }
    };

    return (
        !group.hidden && (
            <StyledRow
                container
                className={`align-items-center ${isStriped(index)} group-${
                    group.id
                }`}
            >
                <StyledCol item xs md pl={2}>
                    <HiddenComponent width="smDown">
                        <Typography id={`label_${group.id}`} variant="body2">
                            {label}
                        </Typography>
                    </HiddenComponent>

                    <HiddenComponent width="smUp">
                        <Tooltip title={label} placement="top">
                            <span>
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </HiddenComponent>
                </StyledCol>

                <InputCheck
                    onchange={(input) => {
                        removeSelected(input, group);
                        changeInput(
                            checksNames[0],
                            isSelected(
                                categories,
                                input,
                                'permission_view',
                                'VIEW'
                            )
                        );
                    }}
                    id={`input_check_view_${group.id}`}
                    inputId={`${NAME}_${group.id}_${prefixRole}_permission_view`}
                    name={`groups.${group.id}.${prefixRole}.permission_view`}
                    disabled={isDisabled}
                    visible={isVisible(group, 'VIEW')}
                />

                <InputCheck
                    onchange={(input) => {
                        autoCheckView(input, group);
                        changeInput(
                            checksNames[1],
                            isSelected(
                                categories,
                                input,
                                'permission_add',
                                'ADD'
                            )
                        );
                    }}
                    id={`input_check_add_${group.id}`}
                    inputId={`${NAME}_${group.id}_${prefixRole}_permission_add`}
                    name={`groups.${group.id}.${prefixRole}.permission_add`}
                    disabled={isDisabled}
                    visible={isVisible(group, 'ADD')}
                />

                <InputCheck
                    onchange={(input) => {
                        autoCheckView(input, group);
                        changeInput(
                            checksNames[2],
                            isSelected(
                                categories,
                                input,
                                'permission_change',
                                'EDIT'
                            )
                        );
                    }}
                    id={`input_check_change_${group.id}`}
                    inputId={`${NAME}_${group.id}_${prefixRole}_permission_change`}
                    name={`groups.${group.id}.${prefixRole}.permission_change`}
                    disabled={isDisabled}
                    visible={isVisible(group, 'EDIT')}
                />

                <InputCheck
                    onchange={(input) => {
                        autoCheckView(input, group);
                        changeInput(
                            checksNames[3],
                            isSelected(
                                categories,
                                input,
                                'permission_delete',
                                'DELETE'
                            )
                        );
                    }}
                    id={`input_check_delete_${group.id}`}
                    inputId={`${NAME}_${group.id}_${prefixRole}_permission_delete`}
                    name={`groups.${group.id}.${prefixRole}.permission_delete`}
                    disabled={isDisabled}
                    visible={isVisible(group, 'DELETE')}
                />
            </StyledRow>
        )
    );
};

ItemPermission.propTypes = {
    categories: PropTypes.array,
    changeInput: PropTypes.func,
    checksNames: PropTypes.array,
    formValues: PropTypes.object,
    group: PropTypes.object,
    index: PropTypes.number,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
};

export default ItemPermission;
