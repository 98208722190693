import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

import { HourlyGridWrapper } from 'common/components';
import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid,
    Typography,
} from 'common/ui';

const DistributionCard = ({
    conceptName,
    control,
    defaultExpanded = false,
    distributionComponent: DistributionComponent,
    field,
    hourlyDistribution,
    optionsForSelect = [],
    showMode,
    title,
    weekDay,
    ...rest
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    return (
        <Card>
            <Accordion
                defaultExpanded={defaultExpanded}
                disableGutters
                expanded={isExpanded}
                onChange={(e, expanded) => {
                    if (e.target.nodeName !== 'LI') setIsExpanded(expanded);
                }}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon sx={{ fontSize: '3rem' }} />}
                    id={`hourly-table-header-${conceptName}.${weekDay}`}
                >
                    <Grid
                        alignItems="center"
                        container
                        justifyContent="space-between"
                        padding={1}
                    >
                        <Grid item md={8} xs={18}>
                            <Typography variant="h6">{title}</Typography>
                        </Grid>
                        {!showMode && (
                            <Grid item xs={18} md={4}>
                                <ReactHookFormSelect
                                    control={control}
                                    name={`${field}.select.${conceptName}.${weekDay}`}
                                    options={optionsForSelect}
                                />
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <HourlyGridWrapper sx={{ mt: 3, mx: 1 }}>
                        <DistributionComponent
                            conceptName={conceptName}
                            field={field}
                            hourlyDistribution={hourlyDistribution}
                            showMode={showMode}
                            weekDay={weekDay}
                            {...rest}
                        />
                    </HourlyGridWrapper>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

DistributionCard.propTypes = {
    conceptName: PropTypes.string,
    control: PropTypes.object,
    defaultExpanded: PropTypes.bool,
    distributionComponent: PropTypes.func,
    field: PropTypes.string,
    hourlyDistribution: PropTypes.array,
    optionsForSelect: PropTypes.array,
    showMode: PropTypes.bool,
    title: PropTypes.string,
    weekDay: PropTypes.string,
};

export default DistributionCard;
