import API from '../../.';
const ENTITY = 'parameters';

export const fetchPDFFILES = ({
    country,
    dp1,
    month,
    page = 1,
    per_page = 25,
    rate,
    year,
}) =>
    API.get(`/api/v1/${ENTITY}/download-cfe-file/`, {
        params: {
            country,
            dp1,
            month,
            page,
            per_page,
            rate,
            year,
        },
    });

export const fetchPoliticalDivisionsByCountry = (country) =>
    API.get(`/api/v1/${ENTITY}/dp1/`, { params: { country } });

export const fetchRatesByCountry = (country) =>
    API.get(`/api/v1/${ENTITY}/custom-rates/`, { params: { country } });

export const getCurrenciesByCountries = ({ page = 1, perPage = 25 } = {}) =>
    API.get(`/api/v1/${ENTITY}/countries-currencies/`, {
        params: { page: page, per_page: perPage },
    });
