import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { Autocomplete, TextField } from 'common/ui';

const AutocompleteMultipleField = ({
    disabled,
    label,
    onChange,
    options,
    value,
}) => {
    const [currentValue, setCurrentValue] = useState([]);

    const isValidValue = (value) => Array.isArray(value) && !isEmpty(value);

    useEffect(() => {
        if (isValidValue(value)) {
            const selectedOptions = options.filter((item) =>
                value.includes(item.value)
            );
            setCurrentValue(selectedOptions);
        }
    }, [value]);

    const handleReset = () => {
        setCurrentValue([]);
        onChange([]);
    };

    return (
        <Autocomplete
            multiple
            limitTags={3}
            disabled={disabled}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            id="multiple-autocomplete"
            isOptionEqualToValue={(option, selected) =>
                !isEmpty(value) && option?.value === selected.value
            }
            onChange={(_, newValue) => {
                if (isValidValue(newValue))
                    onChange(newValue?.map((item) => item.value));
                else handleReset();
            }}
            onInputChange={(_, __, reason) => {
                if (reason === 'clear') handleReset();
            }}
            options={options}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="standard" />
            )}
            value={currentValue}
        />
    );
};

AutocompleteMultipleField.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.array,
};

export default AutocompleteMultipleField;
