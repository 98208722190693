import update from 'immutability-helper';

import {
    FETCH_RENEWALS,
    FETCH_RENEWALS_SUCCESS,
    FETCH_RENEWALS_FAILURE,
} from './actionTypes';

const INITIAL_STATE = {
    renewals: {
        data: {
            data: [],
            paginaton: {},
        },
        error: null,
        isFetching: false,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_RENEWALS:
            return update(state, {
                renewals: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_RENEWALS_FAILURE:
            return update(state, {
                renewals: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_RENEWALS_SUCCESS:
            return update(state, {
                renewals: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        default:
            return state;
    }
}
