import React, { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Box, Card, CardActions, Container, Pagination } from 'common/ui';

import outstandingTemplateForm from '../outstandingTemplateForm';

import * as actions from './actions';
import {
    TemplateForm,
    TemplatesPreviewTable,
    TemplatesPreviewTableToolbar,
} from './components';
import * as selectors from './selectors';

const TemplatesCatalogContainer = ({
    fetchTemplates,
    filters,
    handleClickCreate,
    isFetching,
    isOpenTemplateForm,
    pagination,
    reset,
    setCurrentItem,
    templates,
}) => {
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        setCurrentItem({});
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (!isOpenTemplateForm) {
            setCurrentItem({});
        }
    }, [isOpenTemplateForm]);

    useEffect(() => {
        fetchTemplates(page, orderBy, sortBy, searchStr);
    }, [page, searchStr]);

    const handleOnChangePage = (event, value) => setPage(value);
    const handleSortable = (orderBy, sortBy) => {
        setOrderBy(orderBy);
        setSortBy(sortBy);
        fetchTemplates(page, orderBy, sortBy, searchStr);
    };

    const handleOnSearchChange = (e) => setSearchStr(e);

    return (
        <>
            {!isOpenTemplateForm ? (
                <Container maxWidth="">
                    <Box mb={3}>
                        <TemplatesPreviewTableToolbar
                            isFetching={isFetching}
                            handleClickCreate={handleClickCreate}
                            handleOnSearchChange={handleOnSearchChange}
                            searchStr={filters.searchStr}
                            setCurrentItem={setCurrentItem}
                        />
                    </Box>

                    <Card>
                        <TemplatesPreviewTable
                            handleSortable={handleSortable}
                            isFetching={isFetching}
                            items={templates}
                            orderdBy={orderBy}
                            setCurrentItem={setCurrentItem}
                            sortedBy={sortBy}
                        />

                        {!isFetching && pagination && !isEmpty(pagination) && (
                            <CardActions>
                                <Pagination
                                    color="primary"
                                    count={
                                        Math.ceil(
                                            parseInt(pagination.total_rows) /
                                                parseInt(pagination.per_page)
                                        ) || 0
                                    }
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    sx={{
                                        marginLeft: 'auto',
                                    }}
                                    page={pagination.current_page}
                                    onChange={handleOnChangePage}
                                    variant="outlined"
                                />
                            </CardActions>
                        )}
                    </Card>
                </Container>
            ) : (
                <Container maxWidth="">
                    <TemplateForm setCurrentItem={setCurrentItem} />
                </Container>
            )}

            <outstandingTemplateForm.Container
                filters={filters}
                handleAfterSave={fetchTemplates}
            />
        </>
    );
};

TemplatesCatalogContainer.propTypes = {
    fetchTemplates: PropTypes.func,
    filters: PropTypes.object,
    handleClickCreate: PropTypes.func,
    isFetching: PropTypes.bool,
    isOpenTemplateForm: PropTypes.bool,
    pagination: PropTypes.object,
    reset: PropTypes.func,
    setCurrentItem: PropTypes.func,
    templates: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getTemplatesIsFetching,
    isOpenTemplateForm: selectors.getIsOpenTemplateForm,
    filters: selectors.getFilters,
    pagination: selectors.getTemplatesPagination,
    templates: selectors.getTemplatesData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTemplates: (page, orderBy, sortBy, searchStr) =>
        dispatch(actions.fetchTemplates(page, orderBy, sortBy, searchStr)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    reset: () => dispatch(actions.reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplatesCatalogContainer);
