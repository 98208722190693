import * as selectors from '../selectors';

import fetchCategories from './fetchCategories';
import resetForm from './resetForm';
import toggleCategoryForm from './toggleCategoryForm';

export default () => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    dispatch(fetchCategories(filters.page));
    dispatch(resetForm());
    dispatch(toggleCategoryForm(false));
};
