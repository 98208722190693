import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';

import { Chip, Box, IconButton } from 'common/ui';

const TagsHeader = ({ tags, handleEditTags }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Box
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                gap={1}
                justifyContent="center"
                maxWidth="100%"
            >
                {tags?.map((tag) => (
                    <Chip
                        key={tag.id}
                        label={tag.name}
                        sx={{
                            backgroundColor: tag?.color,
                            '& .MuiChip-label': {
                                color: 'white',
                                fontWeight: 'bold',
                                mixBlendMode: 'difference',
                            },
                        }}
                    />
                ))}
            </Box>
            <Box ml={1}>
                <IconButton onClick={handleEditTags}>
                    <EditIcon fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
};

TagsHeader.propTypes = {
    tags: PropTypes.array,
    handleEditTags: PropTypes.func,
};

export default TagsHeader;
