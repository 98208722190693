import { push } from 'connected-react-router';

import { auth } from 'common/api/auth';

import setCurrentValues from '../../profile/actions/setCurrentValues';
import {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
} from '../actionTypes';
import { getPermissionRedirect } from '../helpers';

export default (data) => (dispatch) => {
    const newValues = {
        auth_token: data.validationToken,
        code: data.code,
    };

    dispatch({ type: LOGIN_USER });

    auth(newValues)
        .then((response) => {
            const {
                id,
                company_group,
                email,
                is_staff,
                is_active,
                first_name,
                last_name,
                is_enabled,
                picture,
                permissions,
                token,
            } = response.data;

            localStorage.setItem('token', token);
            localStorage.setItem(
                'session',
                JSON.stringify({
                    id,
                    company_group,
                    email,
                    is_staff,
                    is_active,
                    first_name,
                    last_name,
                    is_enabled,
                    picture,
                })
            );
            localStorage.setItem('permissions', JSON.stringify(permissions));

            dispatch({
                type: LOGIN_USER_SUCCESS,
                payload: response.data,
            });

            dispatch(
                setCurrentValues({
                    id,
                    email,
                    first_name,
                    last_name,
                    picture,
                })
            );
            const permission = permissions[0].group;
            const redirect = (link) => dispatch(push(link));
            getPermissionRedirect(permission, redirect);
        })
        .catch((error) =>
            dispatch({
                type: LOGIN_USER_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
