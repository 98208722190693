import styled from 'styled-components';

export default styled.div`
    align-items: center;
    box-shadow: inset 0 1px 3px 0 rgba(181, 167, 208, 0.6),
        20px 2px 17px 0 rgba(120, 112, 178, 0.08);
    display: flex;
    height: 52px;
    justify-content: space-between;
    padding: 0px 15px;
    width: 100%;
`;
