import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { DialogContainer } from 'common/components';
import {
    ReduxFieldInput,
    ReduxColorPicker,
} from 'common/components/form/redux';
import { Box, Button, Stack } from 'common/ui';

import { NAME } from '../constants';

const TagModalForm = ({
    disabled,
    handleCloseModal,
    handleSubmit,
    isNewTag,
    isOpenModal,
    onSubmit,
}) => {
    const { t } = useTranslation();

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="xs"
            onClose={handleCloseModal}
            open={isOpenModal}
            title={isNewTag ? t('Create') : t('Edit')}
            sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Field
                        component={ReduxFieldInput}
                        disabled={disabled}
                        name="name"
                        placeholder={t('Name')}
                        required
                        variant="outlined"
                    />

                    <Field
                        component={ReduxColorPicker}
                        disabled={disabled}
                        name="color"
                        placeholder={t('Color')}
                        required
                        variant="outlined"
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { sm: 'row', xs: 'column' },
                            gap: 2,
                            justifyContent: { sm: 'right', xs: 'center' },
                            pt: 2,
                            width: '100%',
                        }}
                    >
                        <Button
                            color="secondary"
                            disabled={disabled}
                            fullWidth={false}
                            height="48px"
                            onClick={handleCloseModal}
                            size="large"
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            disabled={disabled}
                            fullWidth={false}
                            height="48px"
                            size="large"
                            type="submit"
                        >
                            {t('Save')}
                        </Button>
                    </Box>
                </Stack>
            </form>
        </DialogContainer>
    );
};

TagModalForm.propTypes = {
    disabled: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    isNewTag: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default reduxForm({
    form: `${NAME}/tag-form`,
    enableReinitialize: true,
})(TagModalForm);
