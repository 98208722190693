import React from 'react';

import PropTypes from 'prop-types';

import { Container } from 'common/ui';

import HeaderSection from './HeaderSection';

const SectionPermission = ({
    categories,
    category,
    changeInput,
    checksNames,
    formValues,
    children,
    index,
    isDisabled,
    label,
}) => {
    return (
        <Container maxWidth="" disableGutters>
            <HeaderSection
                categories={categories}
                category={category}
                changeInput={changeInput}
                checksNames={checksNames}
                formValues={formValues}
                index={index}
                isDisabled={isDisabled}
                label={label}
            />
            {children}
        </Container>
    );
};

SectionPermission.propTypes = {
    categories: PropTypes.array,
    category: PropTypes.object,
    changeInput: PropTypes.func,
    checksNames: PropTypes.array,
    formValues: PropTypes.object,
    children: PropTypes.array,
    index: PropTypes.number,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
};

export default SectionPermission;
