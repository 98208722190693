import { NAME } from './constants';

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;
export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_FAMILY = `${NAME}/DELETE_FAMILY`;
export const DELETE_FAMILY_SUCCESS = `${NAME}/DELETE_FAMILY_SUCCESS`;
export const DELETE_FAMILY_FAILURE = `${NAME}/DELETE_FAMILY_FAILURE`;
