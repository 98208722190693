import { NAME } from './constants';

export const FETCH_OUTSTANDING_DETAIL = `${NAME}/FETCH_OUTSTANDING_DETAIL`;
export const FETCH_OUTSTANDING_DETAIL_FAILURE = `${NAME}/FETCH_OUTSTANDING_DETAIL_FAILURE`;
export const FETCH_OUTSTANDING_DETAIL_SUCCESS = `${NAME}/FETCH_OUTSTANDING_DETAIL_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_OUTSTANDING = `${NAME}/DELETE_USER`;
export const DELETE_OUTSTANDING_SUCCESS = `${NAME}/DELETE_USER_SUCCESS`;
export const DELETE_OUTSTANDING_FAILURE = `${NAME}/DELETE_USER_FAILURE`;
