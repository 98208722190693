import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { ReduxFieldInput } from 'common/components/form/redux';
import TextVariant from 'common/components/TextVariant';
import { Box, Stack } from 'common/ui';

const ReduxFieldArray = ({ disabled, dot, fields, label, placeholder }) => {
    return (
        <Stack spacing={3}>
            <TextVariant dot={dot} variant="bold" text={label} />
            <Stack spacing={2}>
                {fields.map((item, i) => {
                    return (
                        <Box key={`field-array-${i}-${Math.random()}`}>
                            <Field
                                name={`${item}.item`}
                                component={ReduxFieldInput}
                                placeholder={`${placeholder} ${i + 1}`}
                                variant="outlined"
                                disabled={disabled}
                            />
                        </Box>
                    );
                })}
            </Stack>
        </Stack>
    );
};

ReduxFieldArray.propTypes = {
    disabled: PropTypes.bool,
    dot: PropTypes.string,
    fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
};

export default ReduxFieldArray;
