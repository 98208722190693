import update from 'immutability-helper';

import {
    DELETE_FONT,
    DELETE_FONT_FAILURE,
    DELETE_FONT_SUCCESS,
    FETCH_FONTS,
    FETCH_FONTS_FAILURE,
    FETCH_FONTS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_PRODUCT_FORM,
    RESET,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_ENABLED_EDITION,
    SET_IS_NEW_RECORD,
} from './actionTypes';

const INITIAL_STATE = {
    deleteFont: {
        isDeleting: false,
        data: [],
        error: null,
    },
    fonts: {
        data: {
            data: [],
            pagination: {},
        },
        error: null,
        isFetching: false,
    },
    initialValues: {
        id: null,
        name: '',
        url: '',
    },
    isNewRecord: false,
    isEnabledEdition: true,
    isOpenProductForm: false,
    save: {
        isSaving: false,
        data: {},
        errors: null,
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DELETE_FONT:
            return update(state, {
                deleteFont: {
                    $merge: {
                        isDeleting: false,
                        errors: [],
                    },
                },
            });

        case DELETE_FONT_SUCCESS:
            return update(state, {
                deleteFont: {
                    $merge: {
                        isDeleting: false,
                        data: action.payload,
                    },
                },
            });

        case DELETE_FONT_FAILURE:
            return update(state, {
                deleteFont: {
                    $merge: {
                        isDeleting: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_FONTS:
            return update(state, {
                fonts: {
                    $merge: {
                        isFetching: true,
                        errors: [],
                    },
                },
            });

        case FETCH_FONTS_FAILURE:
            return update(state, {
                fonts: {
                    $merge: {
                        isFetching: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_FONTS_SUCCESS:
            return update(state, {
                fonts: {
                    $merge: {
                        data: action.payload,
                        isFetching: false,
                    },
                },
            });

        case INITIALIZE_FORM:
            return update(state, {
                initialValues: {
                    $merge: action.payload,
                },
            });

        case RESET:
            return update(state, {
                $set: INITIAL_STATE,
            });

        case RESET_FORM:
            return update(state, {
                initialValues: {
                    $set: INITIAL_STATE.initialValues,
                },
                save: {
                    $set: INITIAL_STATE.save,
                },
            });

        case OPEN_PRODUCT_FORM:
            return update(state, {
                isOpenProductForm: {
                    $set: action.payload,
                },
            });

        case SET_IS_NEW_RECORD:
            return update(state, {
                isNewRecord: {
                    $set: action.payload,
                },
            });

        case SET_ENABLED_EDITION:
            return update(state, {
                isEnabledEdition: {
                    $set: action.payload,
                },
            });

        case SAVE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: true,
                        errors: [],
                    },
                },
            });

        case SAVE_SUCCESS:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        data: action.payload,
                    },
                },
            });

        case SAVE_FAILURE:
            return update(state, {
                save: {
                    $merge: {
                        isSaving: false,
                        errors: action.payload,
                    },
                },
            });

        default:
            return state;
    }
}
