import React, { useEffect } from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { CheckAccess, MenuProfile } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import alerts from 'common/modules/alerts';
import { Box } from 'common/ui';
import { media } from 'common/utils/mixins';

import { ReactComponent as IcoAlliance } from 'resources/icoAlliance.svg';
import { ReactComponent as IcoCompany } from 'resources/icoCompany.svg';
import { ReactComponent as IcoRates } from 'resources/icoParameters.svg';
import { ReactComponent as IcoProducts } from 'resources/icoProducts.svg';
import { ReactComponent as IcoTemplates } from 'resources/icoTemplates.svg';
import isotypeSunwise from 'resources/isotypeSunwise.svg';

import * as profileActions from '../../../modules/profile/actions';
import * as profileSelectors from '../../../modules/profile/selectors';

import Footer from './Footer';
import Header from './Header';
import OptionsAI from './OptionsAI';
import SectionModule from './SectionModule';
import SideBar from './SideBar';
import SideBarItem from './SideBarItem';
import Wrapper from './Wrapper';

const SectionMenu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
`;

const SideBarIsotype = styled.img`
    display: flex;
    margin: 12px auto 54px;
    height: 30px;

    ${media.md`
        height: 45px;    
    `}
`;

const DefaultLayout = ({
    children,
    fetchUsersSettings,
    match,
    profileValues,
    redirect,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchUsersSettings();
    }, []);

    return (
        <Wrapper>
            <SideBar>
                <SideBarIsotype
                    alt=""
                    src={isotypeSunwise}
                    onClick={() => redirect('/')}
                    className="cursor-pointer"
                />

                <CheckAccess requiredPermissions={[PERMISSION_LIST.REPORTS]}>
                    <SideBarItem
                        active={/^\/reports/i.test(match.path)}
                        handleClick={() => redirect('/reports')}
                        icon={<AssessmentIcon />}
                        label={t('Report', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.COMPANIES]}>
                    <SideBarItem
                        active={
                            match.path === '/' ||
                            /^\/companies/i.test(match.path) ||
                            /^\/company-file/i.test(match.path)
                        }
                        handleClick={() => redirect('/companies')}
                        icon={<IcoCompany />}
                        label={t('Company', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.PROPOSALS]}>
                    <SideBarItem
                        active={/^\/proposals/i.test(match.path)}
                        handleClick={() => redirect('/proposals')}
                        icon={<FolderIcon />}
                        label={t('Proposal', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.PRODUCTS]}>
                    <SideBarItem
                        active={/^\/products/i.test(match.path)}
                        handleClick={() => redirect('/products')}
                        icon={<IcoProducts />}
                        label={t('Product', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.TEMPLATES]}>
                    <SideBarItem
                        active={/^\/templates/i.test(match.path)}
                        handleClick={() => redirect('/templates')}
                        icon={<IcoTemplates />}
                        label={t('Template', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.ALLIANCES]}>
                    <SideBarItem
                        active={/^\/alliances/i.test(match.path)}
                        handleClick={() => redirect('/alliances')}
                        icon={<IcoAlliance />}
                        label={t('Alliance', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.RATES]}>
                    <SideBarItem
                        active={/^\/parameters/i.test(match.path)}
                        handleClick={() => redirect('/parameters')}
                        icon={<IcoRates />}
                        label={t('Parameter', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess
                    requiredPermissions={[PERMISSION_LIST.AFTER_SALES]}
                >
                    <SideBarItem
                        active={/^\/after-sales-power-stations/i.test(
                            match.path
                        )}
                        handleClick={() =>
                            redirect('/after-sales-power-stations')
                        }
                        icon={<BarChartIcon />}
                        label={t('After sales')}
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.USERS]}>
                    <SideBarItem
                        active={/^\/users/i.test(match.path)}
                        handleClick={() => redirect('/users')}
                        icon={<PersonIcon />}
                        label={t('User', { count: 2 })}
                    />
                </CheckAccess>

                <CheckAccess
                    requiredPermissions={[
                        PERMISSION_LIST.AI_RECEIPTS,
                        PERMISSION_LIST.AI_MODELS,
                        PERMISSION_LIST.AI_TAGS,
                        PERMISSION_LIST.AI_FILES,
                    ]}
                >
                    <OptionsAI match={match} redirect={redirect} />
                </CheckAccess>
            </SideBar>

            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    width: '100%',
                }}
            >
                <Header>
                    <SectionMenu>
                        <MenuProfile
                            handleRedirect={redirect}
                            profileValues={profileValues}
                        />
                    </SectionMenu>
                </Header>

                <SectionModule>{children}</SectionModule>

                <alerts.Container />

                <Footer />
            </Box>
        </Wrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    profileValues: profileSelectors.getCurrentValues,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUsersSettings: () => dispatch(profileActions.fetchData()),
    redirect: (link) => dispatch(push(link)),
});

DefaultLayout.propTypes = {
    children: PropTypes.object,
    fetchUsersSettings: PropTypes.func,
    match: PropTypes.object,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DefaultLayout));
