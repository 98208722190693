export const NAME = 'panels-configuration';

export const GENERAL_CATEGORY = 1;
export const PARAMETERS_CATEGORY = 2;
export const TEMPERATURES_CATEGORY = 3;

export const PANEL_TYPES = {
    0: 'Monocrystalline',
    1: 'Polycrystalline',
    2: 'Thin Film',
};
