import { PERMISSION_LIST } from 'common/constants/permissionsV2';

export const getPermissionRedirect = (permission, redirect) => {
    switch (permission) {
        case PERMISSION_LIST.COMPANIES:
            redirect('/companies');
            return false;
        case PERMISSION_LIST.PRODUCTS:
            redirect('/products');
            return false;
        case PERMISSION_LIST.TEMPLATES:
            redirect('/templates');
            return false;
        case PERMISSION_LIST.RATES:
            redirect('/parameters');
            return false;
        case PERMISSION_LIST.REPORTS:
            redirect('/reports');
            return false;
        case PERMISSION_LIST.ALLIANCES:
            redirect('/alliances');
            return false;
        case PERMISSION_LIST.AI_MODELS:
            redirect('/ai');
            return false;
        case PERMISSION_LIST.AI_TAGS:
            redirect('/ai/tags');
            return false;
        case PERMISSION_LIST.AI_FILES:
            redirect('/ai/filter-files');
            return false;
        case PERMISSION_LIST.USERS:
            redirect('/users');
            return false;
        case PERMISSION_LIST.PROPOSALS:
            redirect('/proposals');
            return false;
        case PERMISSION_LIST.AFTER_SALES:
            redirect('/after-sales-power-stations');
            return false;
        default:
            redirect('/profile');
            return false;
    }
};
