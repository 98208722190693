import React from 'react';

import PropTypes from 'prop-types';

import { Tooltip, Typography } from 'common/ui';

const EmailFormatter = ({ email }) => {
    return (
        <Tooltip title={email}>
            <Typography
                noWrap
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    width: 'calc(100% - 16px)',
                }}
                variant="body2"
            >
                {email ? email : '- - -'}
            </Typography>
        </Tooltip>
    );
};

EmailFormatter.propTypes = {
    email: PropTypes.string,
};

export default EmailFormatter;
