import i18next from 'i18next';
import { get, isEmpty, isNull } from 'lodash';

import { getUserDetail } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    FETCH_USER_DETAIL,
    FETCH_USER_DETAIL_FAILURE,
    FETCH_USER_DETAIL_SUCCESS,
} from '../actionTypes';
import * as selectors from '../selectors';

import initializeForm from './initializeForm';
import resetForm from './resetForm';
import toggleModalUser from './toggleModalUser';

const getBranchOffices = (tempBranchOffices) => {
    if (!isEmpty(tempBranchOffices)) {
        return tempBranchOffices
            .filter((item) => get(item, 'id', null))
            .map((item) => item.id);
    }
    return '';
};

const getMainBranchOffice = (branchOffices, branchOffice) => {
    if (!isNull(branchOffice)) return branchOffice?.id;
    else if (!isEmpty(branchOffices)) return branchOffices[0]?.id;
    return '';
};

export default (companyId, isMultiBranchesCompany, userId) =>
    (dispatch, getState) => {
        const branchOffices = selectors.getDataFetchBranchOffices(getState());
        dispatch({ type: FETCH_USER_DETAIL });
        getUserDetail(companyId, userId)
            .then((response) => {
                const data = get(response, 'data', {});
                dispatch({
                    type: FETCH_USER_DETAIL_SUCCESS,
                    payload: data,
                });
                dispatch(resetForm());
                dispatch(toggleModalUser(true));

                let values = {
                    ...data,
                    company_group: get(data, 'company_group.id', null),
                    company_id: companyId,
                    role_level: get(data, 'company_group.rol_level', null),
                    isMultiBranchesCompany,
                };

                const mainBranchOffice = getMainBranchOffice(
                    branchOffices,
                    values?.branch_office
                );

                if (isMultiBranchesCompany) {
                    values.branch_office = mainBranchOffice;
                    values.branch_offices = getBranchOffices(
                        data.branch_offices
                    );
                } else {
                    values.branch_office = mainBranchOffice;
                    values.branch_offices = [mainBranchOffice];
                }

                dispatch(initializeForm(values));
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_USER_DETAIL_FAILURE,
                    payload: error.response.data.errors,
                });

                showToast({
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while querying the user'
                    ),
                });

                dispatch(toggleModalUser(false));
            });
    };
