export const NAME = 'after-sales-power-stations';

export const REWIND_FREQUENCY = {
    DAILY: 'D',
    HOURLY: 'H',
};

export const REWIND_TYPES = {
    ALL: 'all',
    DAY: 'day',
    RANGE: 'range',
};
