import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Button, UiDialog as Dialog } from 'common/ui';

const FormModal = ({
    children,
    isOpen,
    isSaving,
    onSubmit,
    resetForm,
    setIsOpen,
    title,
}) => {
    const { t } = useTranslation();

    const handleOnClose = () => setIsOpen(false);

    return (
        <Dialog
            onClose={handleOnClose}
            onExited={resetForm}
            open={isOpen}
            size="md"
            title={title}
        >
            <form onSubmit={onSubmit}>
                {children}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { sm: 'row', xs: 'column' },
                        gap: 2,
                        justifyContent: { sm: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        disabled={isSaving}
                        fullWidth={false}
                        height="48px"
                        onClick={handleOnClose}
                        size="large"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isSaving}
                        fullWidth={false}
                        height="48px"
                        size="large"
                        type="submit"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </form>
        </Dialog>
    );
};

FormModal.propTypes = {
    isOpen: PropTypes.bool,
    resetForm: PropTypes.func,
    setIsOpen: PropTypes.func,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    isSaving: PropTypes.bool,
    children: PropTypes.node,
};

export default FormModal;
