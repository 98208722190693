import update from 'immutability-helper';

import {
    DOWNLOAD_BUREAU_CSV_REPORT,
    DOWNLOAD_BUREAU_CSV_REPORT_FAILURE,
    DOWNLOAD_BUREAU_CSV_REPORT_SUCCESS,
    DOWNLOAD_ADDONS_CSV_REPORT,
    DOWNLOAD_ADDONS_CSV_REPORT_FAILURE,
    DOWNLOAD_ADDONS_CSV_REPORT_SUCCESS,
    ACTIVE_CIBANCO_ALLIANCES_REPORT,
    ACTIVE_CIBANCO_ALLIANCES_REPORT_FAILURE,
    ACTIVE_CIBANCO_ALLIANCES_REPORT_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    activeCiBancoAlliancesReport: {
        isDownloading: false,
        data: null,
        errors: [],
    },
    addonsReport: {
        isDownloading: false,
        data: null,
        errors: [],
    },
    bureauReport: {
        isDownloading: false,
        data: null,
        errors: [],
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DOWNLOAD_ADDONS_CSV_REPORT:
            return update(state, {
                addonsReport: { $merge: { errors: [], isDownloading: true } },
            });

        case DOWNLOAD_ADDONS_CSV_REPORT_FAILURE:
            return update(state, {
                addonsReport: {
                    $merge: { errors: action.payload, isDownloading: false },
                },
            });

        case DOWNLOAD_ADDONS_CSV_REPORT_SUCCESS:
            return update(state, {
                addonsReport: { $merge: { errors: [], isDownloading: false } },
            });

        case DOWNLOAD_BUREAU_CSV_REPORT:
            return update(state, {
                bureauReport: { $merge: { errors: [], isDownloading: true } },
            });

        case DOWNLOAD_BUREAU_CSV_REPORT_FAILURE:
            return update(state, {
                bureauReport: {
                    $merge: { errors: action.payload, isDownloading: false },
                },
            });

        case DOWNLOAD_BUREAU_CSV_REPORT_SUCCESS:
            return update(state, {
                bureauReport: { $merge: { errors: [], isDownloading: false } },
            });

        case ACTIVE_CIBANCO_ALLIANCES_REPORT:
            return update(state, {
                activeCiBancoAlliancesReport: {
                    $merge: { errors: [], isDownloading: true },
                },
            });

        case ACTIVE_CIBANCO_ALLIANCES_REPORT_FAILURE:
            return update(state, {
                activeCiBancoAlliancesReport: {
                    $merge: { errors: action.payload, isDownloading: false },
                },
            });

        case ACTIVE_CIBANCO_ALLIANCES_REPORT_SUCCESS:
            return update(state, {
                activeCiBancoAlliancesReport: {
                    $merge: { errors: [], isDownloading: false },
                },
            });

        default:
            return state;
    }
}
