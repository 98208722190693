import React, { useState } from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TagsIcon from '@mui/icons-material/Discount';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckAccess from 'common/components/CheckAccess';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { Box, Menu } from 'common/ui';

import Label from './Label';
import StyledIcon from './StyledIcon';
import StyledIconContainer from './StyledIconContainer';

const ItemOption = ({ handleOnClick, icon, label, match, url }) => {
    return (
        <StyledIconContainer
            className={match.url.startsWith(url) ? 'active' : ''}
            onClick={() => handleOnClick(url)}
        >
            <Box px={2}>
                <StyledIcon className="ico">{icon}</StyledIcon>
                <Label className="label">{label}</Label>
            </Box>
        </StyledIconContainer>
    );
};

ItemOption.propTypes = {
    handleOnClick: PropTypes.func,
    icon: PropTypes.object,
    label: PropTypes.string,
    match: PropTypes.object,
    url: PropTypes.string,
};

const OptionsAI = ({ match, redirect }) => {
    const [anchorIAMenu, setAnchorIAMenu] = useState(null);
    const { t } = useTranslation();

    const handleIAMenu = (event) => {
        setAnchorIAMenu(event.currentTarget);
    };
    const handleCloseIAMenu = () => {
        setAnchorIAMenu(null);
    };
    const handleMenuClick = (url) => {
        handleCloseIAMenu();
        redirect(url);
    };

    return (
        <>
            <StyledIconContainer
                className={/^\/ai/i.test(match.path) ? 'active' : ''}
                onClick={handleIAMenu}
            >
                <StyledIcon className="ico">
                    <PsychologyIcon />
                </StyledIcon>
                <Label className="label">{t('AI', { count: 2 })}</Label>
            </StyledIconContainer>
            <Menu
                anchorEl={anchorIAMenu}
                anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
                open={Boolean(anchorIAMenu)}
                onClose={handleCloseIAMenu}
                transformOrigin={{ horizontal: 'left', vertical: 'center' }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: (theme) =>
                            theme.palette.secondary.main,
                        color: (theme) => theme.palette.secondary.contrastText,
                    },
                }}
            >
                <CheckAccess requiredPermissions={[PERMISSION_LIST.AI_MODELS]}>
                    <ItemOption
                        handleOnClick={handleMenuClick}
                        icon={<PsychologyIcon />}
                        label={t('Model', { count: 2 })}
                        match={match}
                        url="/ai/fine-tuning"
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.AI_TAGS]}>
                    <ItemOption
                        handleOnClick={handleMenuClick}
                        icon={<TagsIcon />}
                        label={t('Tags')}
                        match={match}
                        url="/ai/tags"
                    />
                </CheckAccess>

                <CheckAccess requiredPermissions={[PERMISSION_LIST.AI_FILES]}>
                    <ItemOption
                        handleOnClick={handleMenuClick}
                        icon={<CloudDownloadIcon />}
                        label={t('File', { count: 2 })}
                        match={match}
                        url="/ai/filter-files"
                    />
                </CheckAccess>
            </Menu>
        </>
    );
};

OptionsAI.propTypes = {
    match: PropTypes.object,
    redirect: PropTypes.func,
};

export default OptionsAI;
