import React from 'react';

import PropTypes from 'prop-types';

import { Pagination } from 'common/ui';

const FlexTablePagination = ({ handleOnPageChange, pagination }) => {
    if (!pagination) {
        return null;
    }

    return (
        <Pagination
            color="primary"
            count={
                Math.ceil(
                    parseInt(pagination.total_rows) /
                        parseInt(pagination.per_page)
                ) || 0
            }
            shape="rounded"
            showFirstButton
            showLastButton
            sx={{
                marginLeft: 'auto',
                marginRight: '8px',
            }}
            page={pagination.current_page}
            onChange={handleOnPageChange}
            variant="outlined"
        />
    );
};

FlexTablePagination.propTypes = {
    handleOnPageChange: PropTypes.func,
    pagination: PropTypes.object,
};

export default FlexTablePagination;
