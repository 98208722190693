import i18next from 'i18next';

export const getCompaniesOptions = (companies) => {
    let items = companies.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    return [
        {
            label: i18next.t('All text', { context: 'female', count: 2 }),
            value: 'all',
        },
        ...items,
    ];
};
