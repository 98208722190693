import { createTrainingData } from 'common/api/ocr/fineTuning';

import {
    SAVE_TRAINING_DATA,
    SAVE_TRAINING_DATA_FAILURE,
    SAVE_TRAINING_DATA_SUCCESS,
} from '../actionTypes';

import fetchModel from './fetchModel';
import resetForm from './resetForm';
import setIsOpenModal from './setIsOpenModal';

export default (modelId, query) => (dispatch) => {
    dispatch({ type: SAVE_TRAINING_DATA });

    createTrainingData(modelId)
        .then(() => {
            dispatch(setIsOpenModal(false));
            dispatch(resetForm());
            dispatch({ type: SAVE_TRAINING_DATA_SUCCESS });
            dispatch(fetchModel(modelId, query));
        })
        .catch((err) => {
            dispatch({ type: SAVE_TRAINING_DATA_FAILURE, payload: err });
        });
};
