import API from '..';
const ENTITY = 'products';

export const filterOrigins = (str = '') =>
    API.get(`/api/v1/${ENTITY}/origins/?search${str ? '=' + str : ''}`);

export const getOriginDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/origins/${id}/`);

export const createOrigin = (data) =>
    API.post(`/api/v1/${ENTITY}/origins/`, data);

export const updateOrigin = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/origins/${id}/`, data);

export const deleteOrigin = (id) =>
    API.delete(`/api/v1/${ENTITY}/origins/${id}/`);
