import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { getProductBrandLabel } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getBatteriesModel = createSelector(
    getModel,
    (model) => model.batteries
);

export const getBatteriesData = createSelector(
    getBatteriesModel,
    (model) => model.data.data || []
);

export const getBatteriesErrors = createSelector(
    getBatteriesModel,
    (model) => model.errors
);

export const getBatteriesIsFetching = createSelector(
    getBatteriesModel,
    (model) => model.isFetching
);

export const getBatteriesPagination = createSelector(
    getBatteriesModel,
    (model) => model.data.pagination || {}
);

export const getBulkUpdateItemsModel = createSelector(
    getModel,
    (model) => model.bulkUpdateItems
);

export const getIsFetchingBulk = createSelector(
    getBulkUpdateItemsModel,
    (model) => model.isFetching
);

export const getDuplicateModel = createSelector(
    getModel,
    (model) => model.duplicateProduct
);

export const getIsDuplicatingProduct = createSelector(
    getDuplicateModel,
    (model) => model.isDuplicating
);

export const getIsOpenProductForm = createSelector(
    getModel,
    (model) => model.isOpenProductForm || false
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getBatteryDetail = createSelector(
    getModel,
    (model) => model.batteryDetail
);

export const getIsFetchingBatteryDetail = createSelector(
    getBatteryDetail,
    (model) => model.isFetching
);

export const getFetchBrands = createSelector(
    getModel,
    (model) => model.fetchBrands
);

export const getIsFetchingBrands = createSelector(
    getFetchBrands,
    (fetchBrands) => fetchBrands.isFetching
);

export const getBrandsData = createSelector(
    getFetchBrands,
    (fetchBrands) => fetchBrands.data
);

export const getBrandsOptionsForSelect = createSelector(
    getBrandsData,
    (brandsData) => {
        let items = brandsData.map((item) => ({
            label: getProductBrandLabel(item),
            optionValue: item.id,
        }));

        return items;
    }
);

export const getFetchFamilies = createSelector(
    getModel,
    (model) => model.fetchFamilies
);

export const getIsFetchingFamilies = createSelector(
    getFetchFamilies,
    (fetchFamilies) => fetchFamilies.isFetching
);

export const getFamiliesOptionsForSelect = createSelector(
    getFetchFamilies,
    (fetchFamilies) => {
        let items = fetchFamilies.data.map((item) => ({
            label: item.name,
            optionValue: item.id,
        }));

        return items;
    }
);

export const getEnabledEdition = createSelector(
    getModel,
    (model) => model.isEnabledEdition
);
