import React, { useEffect, useRef, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import CustomPagination from 'common/components/CustomPagination';
import { DEBOUNCE_TIME } from 'common/constants';
import { Card, DataGrid } from 'common/ui';

import Columns from './Columns';

const ListItems = ({
    filterData,
    filterItems,
    handleClickDelete,
    handleClickUpdate,
    isFetching,
    items,
    paginationData,
    setFilterData,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    return (
        <Card>
            <DataGrid
                autoHeight
                columns={Columns({
                    filterData,
                    handleClickDelete,
                    handleClickUpdate,
                })}
                components={{
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                }}
                componentsProps={{
                    pagination: {
                        rowsPerPageOptions: [25, 50, 75, 100],
                    },
                }}
                disableColumnMenu
                disableSelectionOnClick
                getRowHeight={() => 'auto'}
                initialState={{
                    pagination: {
                        pageSize: filterData?.pageSize,
                    },
                }}
                loading={isFetching}
                onPageChange={handleOnPageChange}
                onPageSizeChange={(newPage) =>
                    setFilterData({ ...filterData, pageSize: newPage })
                }
                page={paginationData?.current_page - 1 || 0}
                pageSize={filterData.pageSize}
                pagination
                paginationMode="server"
                rowCount={rowCount}
                rows={items}
            />
        </Card>
    );
};

ListItems.propTypes = {
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
