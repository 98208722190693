import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { CustomListItem, ShowErrors } from 'common/components';
import { ReduxFieldInput } from 'common/components/form/redux';
import { Box, Button, Divider, Grid, List, Stack } from 'common/ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import {
    handleNormalizeValue,
    handleNormalizeValueToWhitespace,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const OwnerInfoCard = ({
    editMode,
    errors,
    formValues,
    handleSubmit,
    handleOnOwnerEditionEnabled,
    isSavingData,
    save,
}) => {
    const { t } = useTranslation();

    const handleOnSubmit = (values) => {
        save(values, () => {
            handleOnOwnerEditionEnabled();
        });
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Box>
                <List>
                    <CustomListItem
                        label={t('Name')}
                        value={`${handleNormalizeValue(
                            get(formValues, 'owner.first_name', null)
                        )} ${handleNormalizeValueToWhitespace(
                            get(formValues, 'owner.last_name', null)
                        )} ${handleNormalizeValueToWhitespace(
                            get(formValues, 'owner.second_surname', null)
                        )}`}
                        withAvatar
                        urlAvatar={get(formValues, 'owner.profile_image', null)}
                    />

                    {!editMode && (
                        <CustomListItem
                            label={t('Email')}
                            value={handleNormalizeValue(
                                get(formValues, 'owner.email', null)
                            )}
                            withHorizontalDivider
                        />
                    )}

                    {editMode && (
                        <Stack spacing={2} mt={3}>
                            <Field
                                component={ReduxFieldInput}
                                name="owner.email"
                                placeholder={t('Email')}
                                type="email"
                                variant="outlined"
                            />
                            <Divider />
                        </Stack>
                    )}

                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <CustomListItem
                                label={t('Telephone')}
                                value={handleNormalizeValue(
                                    get(
                                        formValues,
                                        'owner.phone',
                                        get(
                                            formValues,
                                            'contact_cellphone',
                                            null
                                        )
                                    )
                                )}
                                withVerticalDivider
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomListItem
                                label={t('Creation date')}
                                value={handleNormalizeValue(
                                    get(formValues, 'owner.created_at', null)
                                )}
                            />
                        </Grid>
                    </Grid>

                    {editMode && (
                        <Stack spacing={2} mt={3}>
                            <ShowErrors
                                errors={errors}
                                mt={3}
                                severity="error"
                                variant="filled"
                            />
                            <Button
                                disabled={isSavingData}
                                fullWidth
                                height="48px"
                                size="large"
                                type="submit"
                            >
                                {isSavingData
                                    ? `${t('Saving')}...`
                                    : t('Apply')}
                            </Button>
                        </Stack>
                    )}
                </List>
            </Box>
        </form>
    );
};

OwnerInfoCard.propTypes = {
    editMode: PropTypes.bool,
    errors: PropTypes.array,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleOnOwnerEditionEnabled: PropTypes.func,
    isSavingData: PropTypes.bool,
    save: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isSavingData: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, onSuccessCallback) =>
        dispatch(actions.save(values, onSuccessCallback)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(OwnerInfoCard);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
