import i18next from 'i18next';

import { createTag, updateTag } from 'common/api/ocr/fineTuning';
import showToast from 'common/utils/showToast';

import { SAVE_TAG, SAVE_TAG_FAILURE, SAVE_TAG_SUCCESS } from '../actionTypes';

import fetchTags from './fetchTags';
import setModalIsOpen from './setModalIsOpen';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE_TAG });

    const { id, ...data } = values;

    const request = id ? updateTag(id, data) : createTag(data);

    request
        .then((response) => {
            showToast({ autoClose: 3000 });
            dispatch({ type: SAVE_TAG_SUCCESS, payload: response.data });
            dispatch(setModalIsOpen(false));
            dispatch(fetchTags());
        })
        .catch((error) => {
            dispatch({
                type: SAVE_TAG_FAILURE,
                payload: error?.response?.data?.detail,
            });
            showToast({
                autoClose: 3000,
                type: 'error',
                body: i18next.t('Error'),
            });
        });
};
