import React from 'react';

import PropTypes from 'prop-types';

import { Box, Stack, Grid, Skeleton, Typography } from 'common/ui';

const CustomSkeleton = () => (
    <Typography variant="h3">
        <Skeleton></Skeleton>
    </Typography>
);

const Placeholder = () => (
    <Box>
        <Stack>
            <CustomSkeleton />
        </Stack>

        <Grid container spacing={2}>
            <Grid item xs={6}>
                <CustomSkeleton />
            </Grid>
            <Grid item xs={6}>
                <CustomSkeleton />
            </Grid>
        </Grid>

        <Stack>
            <CustomSkeleton />
        </Stack>

        <Grid container spacing={2}>
            <Grid item xs={4}>
                <CustomSkeleton />
            </Grid>
            <Grid item xs={4}>
                <CustomSkeleton />
            </Grid>
            <Grid item xs={4}>
                <CustomSkeleton />
            </Grid>
        </Grid>

        <Stack>
            <Stack spacing={3}>
                <CustomSkeleton />

                <CustomSkeleton />
            </Stack>

            <Stack spacing={3}>
                <CustomSkeleton />
                <CustomSkeleton />
            </Stack>

            <Stack>
                <CustomSkeleton />
            </Stack>

            <Stack spacing={3}>
                <CustomSkeleton />
                <CustomSkeleton />
            </Stack>

            <Stack>
                <CustomSkeleton />
            </Stack>

            <Stack>
                <CustomSkeleton />
            </Stack>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" spacing={3}>
            <Grid container spacing={1}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <CustomSkeleton />
                </Grid>
                <Grid item xs={4}>
                    <CustomSkeleton />
                </Grid>
            </Grid>
        </Stack>
    </Box>
);

const PlaceholderPlanForm = ({ children, ready }) => {
    return <>{ready ? <Placeholder /> : children}</>;
};

PlaceholderPlanForm.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderPlanForm;
