import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getRenewalsModel = createSelector(
    getModel,
    (model) => model.renewals
);

export const getRenewalsData = createSelector(
    getRenewalsModel,
    (model) => model?.data?.data
);

export const getIsFetchingRenewals = createSelector(
    getRenewalsModel,
    (model) => model.isFetching
);

export const getRenewalsPagination = createSelector(
    getRenewalsModel,
    (model) => model?.data?.pagination || {}
);

export const getRenewalsErrors = createSelector(
    getRenewalsModel,
    (model) => model.errors
);
