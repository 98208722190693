import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { HeaderContainer, TitleIcon, TabsSystem } from 'common/components';
import { Container, Typography } from 'common/ui';

import { ReactComponent as IcoCompany } from 'resources/icoCompany.svg';

import companyAlliances from '../companyAlliances';
import companyGeneral from '../companyGeneral';
import * as companyGeneralActions from '../companyGeneral/actions';
import * as companyGeneralSelectors from '../companyGeneral/selectors';
import companyLogs from '../companyLogs';
import companyPlans from '../companyPlans';
import companyUsers from '../companyUsers';
import rolesConfiguration from '../rolesConfiguration';

import { HeaderBreadcrumbs } from './components';

const getTabs = (t) => [
    {
        id: 'company',
        label: t('Company', { count: 1 }),
        content: <companyGeneral.Container />,
    },
    {
        id: 'users',
        label: t('User', { count: 2 }),
        content: <companyUsers.Container />,
    },
    {
        id: 'roles',
        label: t('Role', { count: 2 }),
        content: <rolesConfiguration.Container />,
    },
    {
        id: 'plans',
        label: t('Plans and Products'),
        content: <companyPlans.Container />,
    },
    {
        id: 'alliances',
        label: t('Alliance', { count: 2 }),
        content: <companyAlliances.Container />,
    },
    {
        id: 'logs',
        label: t('Activity'),
        content: <companyLogs.Container />,
    },
];

const CompanyContainer = ({
    companyDetail,
    isFetchingCompanyDetail,
    match,
}) => {
    const { t } = useTranslation();

    const [currentTabLabel, setCurrentTabLabel] = useState(
        t('Company', { count: 1 })
    );

    const getLocations = () => {
        if (!isEmpty(companyDetail)) {
            return [
                {
                    label: t('Company', { count: 2 }),
                    link: '/companies',
                },
                {
                    label: companyDetail?.name,
                },
            ];
        }
        return [];
    };

    return (
        <Container maxWidth="">
            <HeaderContainer>
                <Typography variant="h4">
                    <TitleIcon
                        isSvgIcon={true}
                        svgIcon={IcoCompany}
                        title={t('Company', { count: 2 })}
                    />
                </Typography>
            </HeaderContainer>

            <HeaderBreadcrumbs
                currentTabLabel={currentTabLabel}
                isFetchingBreadcrumbs={isFetchingCompanyDetail}
                locationsBreadcrumbs={getLocations()}
                titleIcon={IcoCompany}
                title={t('Company file')}
            />

            <TabsSystem
                baseUrl={`company-file/${match.params.id}`}
                tabs={getTabs(t)}
                setCurrentTabLabel={setCurrentTabLabel}
            />
        </Container>
    );
};

CompanyContainer.propTypes = {
    companyDetail: PropTypes.object,
    isFetchingCompanyDetail: PropTypes.bool,
    match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    companyDetail: companyGeneralSelectors.getCompanyDetailData,
    isFetchingCompanyDetail: companyGeneralSelectors.getIsFetchingCompanyDetail,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanyDetail: (id) =>
        dispatch(companyGeneralActions.fetchCompanyDetail(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompanyContainer));
