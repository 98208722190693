import React, { useEffect, useState, useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { CustomPagination } from 'common/components';
import { Box, Card, CardContent, DataGrid } from 'common/ui';
import { formatDate, parseDate } from 'common/utils/dates';
import { arraytoDictionary, getDataGridClasses } from 'common/utils/helpers';

import { getUsabilityOptions, initializeQueryDates } from '../helpers';

import BulkOperationsCard from './BulkOperationsCard';
import ProductColumns from './ProductColumns';

const DEBOUNCE_TIME = 1500;

const ListItems = ({
    filterData,
    filterItems,
    isFetching,
    items,
    paginationData,
    setFilterData,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [rowCount, setRowCount] = useState(0);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);
    const { control, setValue } = useForm();

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleClickEndDate = (value) => {
        const endDate = parseDate(value, 'dd/MM/yyyy');
        setFilterData({
            ...filterData,
            endDate: formatDate(endDate, 'yyyy-MM-dd'),
        });
    };

    const handleClickQueryRange = (value) => {
        if (value === 'custom') {
            setValue('start_date', '');
            setValue('final_date', '');
            setFilterData({
                ...filterData,
                queryRange: value,
            });
        } else initializeQueryDates(filterData, setFilterData, setValue, value);
    };

    const handleClickStartDate = (value) => {
        const startDate = parseDate(value, 'dd/MM/yyyy');
        setFilterData({
            ...filterData,
            startDate: formatDate(startDate, 'yyyy-MM-dd'),
        });
    };

    const handleClickStatus = (value) => {
        setFilterData({
            ...filterData,
            status: value,
        });
    };

    const handleClickUsability = (value) => {
        let operator = '';
        if (value) {
            const usabilityDictionary = arraytoDictionary(
                getUsabilityOptions(),
                'value'
            );
            operator = usabilityDictionary[value].operator;
        }
        setFilterData({
            ...filterData,
            usabilityOperator: operator,
            usabilityValue: value,
        });
    };

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <>
            <BulkOperationsCard
                control={control}
                filterData={filterData}
                handleClickEndDate={handleClickEndDate}
                handleClickQueryRange={handleClickQueryRange}
                handleClickStartDate={handleClickStartDate}
                handleClickStatus={handleClickStatus}
                handleClickUsability={handleClickUsability}
                handleOnChangeSearch={handleOnChangeSearch}
            />

            <Card>
                <CardContent>
                    {isFetching && (
                        <Box display="flex" justifyContent="center" p={1}>
                            <CircularProgress color="secondary" />
                        </Box>
                    )}
                    <DataGrid
                        autoHeight
                        checkboxSelection={false}
                        columns={ProductColumns()}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0
                                ? 'even'
                                : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rows={items}
                        sortingMode="server"
                        sx={{
                            ...getDataGridClasses(isDarkMode),
                            '& .MuiDataGrid-cell': { p: '10px' },
                        }}
                    />
                </CardContent>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
