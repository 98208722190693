import { fetchRatesByCountry } from 'common/api/v1/parameters';

import {
    FETCH_RATES,
    FETCH_RATES_SUCCESS,
    FETCH_RATES_FAILURE,
} from '../actionTypes';

export default (country) => (dispatch) => {
    dispatch({ type: FETCH_RATES });

    fetchRatesByCountry(country)
        .then((response) => {
            dispatch({
                type: FETCH_RATES_SUCCESS,
                payload: response.data.data,
            });
        })
        .catch((error) =>
            dispatch({
                type: FETCH_RATES_FAILURE,
                payload: error.response.data.errors,
            })
        );
};
