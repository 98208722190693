import { filterBrands } from 'common/api/batteries';

import {
    FETCH_BRANDS,
    FETCH_BRANDS_SUCCESS,
    FETCH_BRANDS_FAILURE,
} from '../actionTypes';

export default (str = '') =>
    (dispatch) => {
        dispatch({ type: FETCH_BRANDS });

        filterBrands(str)
            .then((response) => {
                dispatch({
                    type: FETCH_BRANDS_SUCCESS,
                    payload: response.data.data,
                });
            })
            .catch((error) =>
                dispatch({
                    type: FETCH_BRANDS_FAILURE,
                    payload: error.response.data.errors,
                })
            );
    };
