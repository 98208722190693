import API from '..';
const ENTITY = 'templates';

export const getTemplates = (page = 1, orderBy = '', sortBy = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/filter/?search${str ? '=' + str : ''}&page=${page}${
            orderBy !== '' ? `&order_by=${orderBy}` : ''
        }${sortBy !== '' ? `&sort=${sortBy}` : ''}`
    );

export const getTemplateDetail = (id) =>
    API.get(`/api/v1/${ENTITY}/template/${id}/`);

export const createTemplate = (data) =>
    API.post(`/api/v1/${ENTITY}/template/`, data);

export const updateTemplate = (data, id) =>
    API.patch(`/api/v1/${ENTITY}/template/${id}/`, data);

export const deleteTemplate = (id) =>
    API.delete(`/api/v1/${ENTITY}/template/${id}/`);

export const filterCategories = (str = '') =>
    API.get(`/api/v1/${ENTITY}/categories/?search${str ? '=' + str : ''}`);

export const filterOrigins = (str = '') =>
    API.get(`/api/v1/${ENTITY}/origins/?search${str ? '=' + str : ''}`);

export const filterLanguages = (str = '') =>
    API.get(`/api/v1/${ENTITY}/languages/?search${str ? '=' + str : ''}`);

export const filterCustomTemplates = (id = '', str = '') =>
    API.get(
        `/api/v1/${ENTITY}/custom-templates/${id}/?search${
            str ? '=' + str : ''
        }`
    );
