import i18next from 'i18next';

import { getSyncCompanySubscription } from 'common/api/companies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_COMPANY_SUBSCRIPION,
    UPDATE_COMPANY_SUBSCRIPION_FAILURE,
    UPDATE_COMPANY_SUBSCRIPION_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import fetchCompanyDetail from './fetchCompanyDetail';

export default (companyId) => (dispatch) => {
    dispatch(companyGeneralActions[UPDATE_COMPANY_SUBSCRIPION]());

    getSyncCompanySubscription(companyId)
        .then(() => {
            dispatch(
                companyGeneralActions[UPDATE_COMPANY_SUBSCRIPION_SUCCESS]()
            );
            dispatch(fetchCompanyDetail(companyId));
            showToast({ body: i18next.t('Information updated successfully') });
        })
        .catch((error) => {
            dispatch(
                companyGeneralActions[UPDATE_COMPANY_SUBSCRIPION_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
