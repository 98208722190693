import * as selectors from '../selectors';

import fetchApiAccounts from './fetchApiAccounts';
import resetForm from './resetForm';
import toggleItemForm from './toggleItemForm';

export default () => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    dispatch(fetchApiAccounts(filters.page));
    dispatch(resetForm());
    dispatch(toggleItemForm(false));
};
