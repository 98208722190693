import { SAVE } from '../actionTypes';

import createOutstanding from './createOutstanding';
import updateOutstanding from './updateOutstanding';

export default (values, callback) => (dispatch) => {
    dispatch({ type: SAVE });
    let newValues = {
        days_left: values.unlimited ? 0 : values.days_left,
    };

    if (values.id) {
        dispatch(updateOutstanding(newValues, values.id, callback));
    } else {
        newValues.offer_template = values.offer_template;
        dispatch(createOutstanding(newValues, callback));
    }
};
