import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Container as MaterialContainer } from 'common/ui';

import * as actions from './actions';
import ListItems from './components/ListItems';
import * as selectors from './selectors';

const Container = ({
    countries,
    countriesIsFetching,
    downloadBulk,
    fetchCountries,
    fetchItems,
    fetchPoliticalDivisions,
    fetchRates,
    files,
    handleDownloadFile,
    isFetching,
    pagination,
    politicalDivisions,
    politicalDivisionsIsFetching,
    rates,
    ratesIsFetching,
}) => {
    const [filterData, setFilterData] = useState({
        country: 'all',
        month: 'all',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        politicalDivisions: 'all',
        rate: 'all',
        sortBy: 'desc',
        status: 'active',
        year: 'all',
    });

    useEffect(() => {
        fetchItems(filterData);
        fetchCountries();
        fetchPoliticalDivisions();
        fetchRates();
    }, []);

    return (
        <MaterialContainer maxWidth="">
            <ListItems
                countries={countries}
                countriesIsFetching={countriesIsFetching}
                downloadBulk={downloadBulk}
                fetchPoliticalDivisions={fetchPoliticalDivisions}
                fetchRates={fetchRates}
                filterData={filterData}
                filterItems={fetchItems}
                handleDownloadFile={handleDownloadFile}
                isFetching={isFetching}
                items={files}
                paginationData={pagination}
                politicalDivisions={politicalDivisions}
                politicalDivisionsIsFetching={politicalDivisionsIsFetching}
                rates={rates}
                ratesIsFetching={ratesIsFetching}
                setFilterData={setFilterData}
            />
        </MaterialContainer>
    );
};

Container.propTypes = {
    countries: PropTypes.array,
    countriesIsFetching: PropTypes.bool,
    downloadBulk: PropTypes.func,
    fetchCountries: PropTypes.func,
    fetchItems: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchRates: PropTypes.func,
    files: PropTypes.array,
    handleDownloadFile: PropTypes.func,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
    politicalDivisions: PropTypes.array,
    politicalDivisionsIsFetching: PropTypes.bool,
    rates: PropTypes.array,
    ratesIsFetching: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    countries: selectors.getCountriesData,
    countriesIsFetching: selectors.getCountriesIsFetching,
    files: selectors.getFilesData,
    isFetching: selectors.getFilesIsFetching,
    pagination: selectors.getFilesPagination,
    politicalDivisions: selectors.getPoliticalDivisionsData,
    politicalDivisionsIsFetching: selectors.getPoliticalDivisionsIsFetching,
    rates: selectors.getRatesOptionsForSelect,
    ratesIsFetching: selectors.getRatesIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    downloadBulk: (selectionModel, callback) =>
        dispatch(actions.downloadBulk(selectionModel, callback)),
    fetchCountries: () => dispatch(actions.fetchCountries()),
    fetchItems: (filterData) => dispatch(actions.fetchItems(filterData)),
    fetchPoliticalDivisions: (country) =>
        dispatch(actions.fetchPoliticalDivisions(country)),
    fetchRates: (country) => dispatch(actions.fetchRates(country)),
    handleDownloadFile: (url) => dispatch(actions.downloadFile(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
