import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';

import { Button, Grid, Stack } from 'common/ui';

const Buttons = ({
    cancelText = i18next.t('Cancel').toUpperCase(),
    confirmText = i18next.t('Accept').toUpperCase(),
    disabledBackButton,
    disabledConfirmButton,
    handleClickClose,
    handleClickSuccess,
    type,
}) => {
    if (type === 'confirm') {
        return (
            <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
                <Grid item>
                    <Button
                        color="secondary"
                        disabled={disabledBackButton}
                        fullWidth={false}
                        height="48px"
                        size="large"
                        onClick={handleClickClose}
                    >
                        {cancelText}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        disabled={disabledConfirmButton}
                        fullWidth={false}
                        height="48px"
                        size="large"
                        onClick={handleClickSuccess}
                    >
                        {confirmText}
                    </Button>
                </Grid>
            </Stack>
        );
    }

    if (type === 'only_confirm') {
        return (
            <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
                <Grid item>
                    <Button
                        color="primary"
                        fullWidth={false}
                        height="48px"
                        size="large"
                        onClick={handleClickSuccess}
                    >
                        {confirmText}
                    </Button>
                </Grid>
            </Stack>
        );
    }

    return (
        <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
            <Grid item>
                <Button
                    color="primary"
                    fullWidth={false}
                    height="48px"
                    size="large"
                    onClick={handleClickClose}
                >
                    {confirmText}
                </Button>
            </Grid>
        </Stack>
    );
};

Buttons.propTypes = {
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    disabledBackButton: PropTypes.bool,
    disabledConfirmButton: PropTypes.bool,
    handleClickClose: PropTypes.func,
    handleClickSuccess: PropTypes.func,
    type: PropTypes.string,
};

export default Buttons;
