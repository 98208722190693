import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getCompanyLogsModel = createSelector(
    getModel,
    (model) => model.companyLogs
);

export const getCompanyLogsData = createSelector(
    getCompanyLogsModel,
    (model) => model.data?.data
);

export const getIsFetchingCompanyLogs = createSelector(
    getCompanyLogsModel,
    (model) => model.isFetching
);

export const getCompanyLogsErrors = createSelector(
    getCompanyLogsModel,
    (model) => model.errors
);

export const getCompanyLogsPagination = createSelector(
    getCompanyLogsModel,
    (model) => model.data?.pagination || {}
);
