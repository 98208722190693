import i18next from 'i18next';

import { getAllianceProducts } from 'common/api/companies';
import showToast from 'common/utils/showToast';

import {
    FETCH_ALLIANCE_PRODUCTS,
    FETCH_ALLIANCE_PRODUCTS_FAILURE,
    FETCH_ALLIANCE_PRODUCTS_SUCCESS,
} from '../actionTypes';

import setAllianceProductsFilters from './setAllianceProductsFilters';
import showAllianceProducts from './showAllianceProducts';

export default (companyId, alliance, page = 1, orderBy, sortBy, searchStr) =>
    (dispatch) => {
        dispatch({ type: FETCH_ALLIANCE_PRODUCTS });
        dispatch(
            setAllianceProductsFilters({
                id: companyId,
                alliance: alliance,
                page,
                orderBy,
                sortBy,
                searchStr,
            })
        );
        dispatch(showAllianceProducts(true));

        getAllianceProducts(
            companyId,
            alliance.id,
            page,
            orderBy,
            sortBy,
            searchStr
        )
            .then((response) =>
                dispatch({
                    type: FETCH_ALLIANCE_PRODUCTS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                dispatch({
                    type: FETCH_ALLIANCE_PRODUCTS_FAILURE,
                    payload: error.response.data.errors,
                });

                dispatch({
                    type: FETCH_ALLIANCE_PRODUCTS_SUCCESS,
                    payload: { data: [] },
                });

                showToast({
                    type: 'error',
                    body: i18next.t(
                        'An error occurred while querying the alliance'
                    ),
                });

                dispatch(showAllianceProducts(false));
            });
    };
