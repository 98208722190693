import React, { useState } from 'react';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Box, Stack, TableCell } from 'common/ui';

const ControlCaret = styled('span')(() => ({
    color: '#003b4b',
    cursor: 'pointer',
}));

const ArrowUp = () => (
    <KeyboardArrowUp
        sx={{
            fontSize: 16,
            color: (theme) => `${theme.palette.secondary.contrastText}`,
        }}
    />
);

const ArrowDown = () => (
    <KeyboardArrowDown
        sx={{
            fontSize: 16,
            color: (theme) => `${theme.palette.secondary.contrastText}`,
        }}
    />
);

const SortCaret = ({ sort }) => {
    if (sort === 'ASC') return ArrowDown();
    if (sort === 'DESC') return ArrowUp();
    return (
        <Stack component="span">
            {ArrowUp()}
            {ArrowDown()}
        </Stack>
    );
};

SortCaret.propTypes = {
    sort: PropTypes.string,
};

const RenderSort = ({ dataField, sort, sortable, setSort, handleSortable }) =>
    sortable ? (
        <ControlCaret
            onClick={() => {
                const newSort = sort === 'ASC' ? 'DESC' : 'ASC';
                setSort(newSort);

                if (typeof handleSortable === 'function') {
                    handleSortable(dataField, newSort);
                }
            }}
        >
            <SortCaret sort={sort} />
        </ControlCaret>
    ) : null;

RenderSort.propTypes = {
    dataField: PropTypes.string,
    sort: PropTypes.string,
    sortable: PropTypes.bool,
    setSort: PropTypes.func,
    handleSortable: PropTypes.func,
};

const FlexTableCell = ({
    align,
    children,
    dataField,
    handleSortable,
    isHead = false,
    sortable,
    width,
    sortDefault,
}) => {
    const [sort, setSort] = useState(sortDefault);

    return (
        <TableCell
            align={align}
            sx={{
                minWidth: width,
                maxWidth: width,
                padding: '12px 16px',
            }}
        >
            {isHead ? (
                <Box
                    alignItems="center"
                    direction="row"
                    display="flex"
                    justifyContent="space-between"
                >
                    {children}
                    <RenderSort
                        dataField={dataField}
                        handleSortable={handleSortable}
                        sortable={sortable}
                        sort={sort}
                        setSort={setSort}
                    />
                </Box>
            ) : (
                children
            )}
        </TableCell>
    );
};

FlexTableCell.propTypes = {
    align: PropTypes.string,
    children: PropTypes.node,
    dataField: PropTypes.string,
    handleSortable: PropTypes.func,
    isHead: PropTypes.bool,
    sortable: PropTypes.bool,
    width: PropTypes.string,
    sortDefault: PropTypes.string,
};

export default FlexTableCell;
