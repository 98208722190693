import { SAVE } from '../actionTypes';

import createTemplate from './createTemplate';
import updateTemplate from './updateTemplate';

export default (values) => (dispatch) => {
    dispatch({ type: SAVE });
    if (values.id) {
        dispatch(updateTemplate(values, values.id));
    } else {
        dispatch(createTemplate(values));
    }
};
