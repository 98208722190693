import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { Switch } from 'common/components/form';

const ReactHookFormSwitch = ({ control, disabled, label, name }) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
            <Switch
                checked={value ?? false}
                disabled={disabled}
                label={label}
                onChange={(e) => onChange(e.target.checked)}
            />
        )}
    />
);

ReactHookFormSwitch.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
};
export default ReactHookFormSwitch;
