import React, { forwardRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { TitleIcon } from 'common/components';
import {
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    LinearProgress,
    Slide,
    DialogContentText,
} from 'common/ui';

const StyledDialogTitle = (props) => {
    const { children, closeButton, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {closeButton && onClose && (
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </DialogTitle>
    );
};

StyledDialogTitle.propTypes = {
    children: PropTypes.node,
    closeButton: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide appear={true} in={true} ref={ref} {...props} />;
});

const DialogContainer = ({
    children,
    closeButton = true,
    description = '',
    footerComponent: FooterComponent = null,
    fullWidth,
    imgUrl,
    isFontIcon = false,
    isSvgIcon = false,
    loading = false,
    maxWidth,
    onClose,
    open,
    scroll = 'body',
    svgIcon,
    title,
    ...other
}) => {
    return (
        <div>
            <Dialog
                onClose={(e, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose();
                    }
                }}
                open={open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={Transition}
                scroll={scroll}
                {...other}
            >
                <StyledDialogTitle
                    closeButton={closeButton}
                    id="customized-dialog-title"
                    onClose={onClose}
                >
                    <TitleIcon
                        isSvgIcon={isSvgIcon}
                        svgIcon={svgIcon}
                        imgUrl={imgUrl}
                        isFontIcon={isFontIcon}
                        title={title}
                        variant="h5"
                    />
                </StyledDialogTitle>
                {loading ? <LinearProgress /> : <Divider variant="middle" />}

                <DialogContent>
                    <DialogContentText>{description}</DialogContentText>
                    {children}
                </DialogContent>
                {FooterComponent && (
                    <DialogActions>
                        <FooterComponent />
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

DialogContainer.propTypes = {
    children: PropTypes.node,
    closeButton: PropTypes.bool,
    description: PropTypes.string,
    footerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    fullWidth: PropTypes.bool,
    imgUrl: PropTypes.string,
    isFontIcon: PropTypes.bool,
    isSvgIcon: PropTypes.bool,
    loading: PropTypes.bool,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    scroll: PropTypes.string,
    svgIcon: PropTypes.elementType,
    title: PropTypes.string,
};

export default DialogContainer;
