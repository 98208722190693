import isEmpty from 'lodash/isEmpty';

import { INITIALIZE } from '../actionTypes';
import { parseJson } from '../helpers';

export default (data) => (dispatch) => {
    const formValues =
        data?.values?.map((page, index) => {
            return {
                completion: parseJson(page?.completion),
                page: index,
                prompt: page?.prompt,
            };
        }) || [];

    const tabs = formValues?.map((pageContent) => ({
        has_current_period: !isEmpty(pageContent?.completion?.values),
        has_history: !isEmpty(pageContent?.completion?.history),
    }));

    const newValues = { id: data?.id, tabs, values: formValues };

    dispatch({ type: INITIALIZE, payload: newValues });
};
