import get from 'lodash/get';

import { me } from 'common/api/auth';

import { FETCH, FETCH_SUCCESS, FETCH_FAILURE } from '../actionTypes';

import initializeForm from './initializeForm';
import setCurrentValues from './setCurrentValues';

export default () => (dispatch) => {
    dispatch({ type: FETCH });

    me()
        .then((response) => {
            const data = get(response, 'data', {});
            const { email, first_name, last_name, picture } = data;
            const permissions = get(data, 'permissions', []);
            localStorage.setItem('permissions', JSON.stringify(permissions));

            dispatch({
                type: FETCH_SUCCESS,
                payload: data,
            });
            dispatch(
                setCurrentValues({
                    email,
                    first_name,
                    last_name,
                    picture,
                })
            );
            dispatch(initializeForm(data));
        })
        .catch((error) =>
            dispatch({ type: FETCH_FAILURE, payload: error.data.errors })
        );
};
