import * as selectors from '../selectors';

import fetchBrands from './fetchBrands';
import resetForm from './resetForm';
import toggleBrandForm from './toggleBrandForm';

export default () => (dispatch, getState) => {
    const filters = selectors.getFilters(getState());

    dispatch(fetchBrands(filters.page));
    dispatch(resetForm());
    dispatch(toggleBrandForm(false));
};
