import React from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FieldMailFormatter, FieldNameFormatter } from 'common/components';
import { CustomTable } from 'common/components/tables';
import { StripedRow } from 'common/components/tables/Rows';
import { Grid, Typography, Tooltip } from 'common/ui';

import * as actions from '../actions';

import { BranchOfficesFormatter } from './formatters';

const StatusFormatter = (cell, t) => (
    <>
        {cell && (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" title={cell && cell.status}>
                        {cell ? cell.status : '- - -'}
                    </Typography>
                </Grid>
                {cell.has_tmp_password && (
                    <Grid item xs={6}>
                        <Typography>
                            <Tooltip
                                arrow
                                title={t('Active temporary password')}
                            >
                                <WarningIcon color="secondary" />
                            </Tooltip>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        )}
    </>
);

const UsersPreviewTable = ({
    companyId,
    handleClickUpdate,
    handleSortable,
    isFetching,
    isMultiBranchesCompany,
    items,
    orderdBy,
    pagination,
    sortedBy,
}) => {
    const { t } = useTranslation();
    const columns = [
        {
            dataField: 'full_name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Name'),
            width: '10%',
        },
        {
            dataField: 'email',
            formatter: FieldMailFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Email'),
            width: '10%',
        },
        {
            dataField: 'company_group_name',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Role'),
            width: '10%',
        },
        {
            dataField: 'company_group_level',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Rol level'),
            width: '10%',
        },
        {
            dataField: 'branch_office_name',
            formatter: BranchOfficesFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Branch office', { count: 1 }),
            width: '10%',
        },
        {
            dataField: 'created_at',
            formatter: FieldNameFormatter,
            handleSortable: handleSortable,
            sortable: true,
            label: t('Discharge date'),
            width: '10%',
        },
        {
            dataField: 'is_enabled',
            formatter: (_, { is_enabled }) => StatusFormatter(is_enabled, t),
            handleSortable: handleSortable,
            sortable: true,
            label: t('Status'),
            headerAlign: 'center',
            width: '10%',
        },
    ];

    return (
        <CustomTable
            columns={columns}
            data={items}
            emptyText={t('No records found')}
            handleClickRow={(item) =>
                handleClickUpdate(companyId, isMultiBranchesCompany, item.id)
            }
            isFetching={isFetching}
            showTextFilter={true}
            tableRowComponent={StripedRow}
            pagination={pagination}
            sortedBy={sortedBy}
            orderdBy={orderdBy}
        />
    );
};

UsersPreviewTable.propTypes = {
    companyId: PropTypes.string,
    handleClickUpdate: PropTypes.func,
    handleSortable: PropTypes.func,
    isFetching: PropTypes.bool,
    isMultiBranchesCompany: PropTypes.bool,
    items: PropTypes.array,
    orderdBy: PropTypes.string,
    pagination: PropTypes.object,
    sortedBy: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    handleClickUpdate: (companyId, isMultiBranchesCompany, userId) =>
        dispatch(
            actions.prepareUpdate(companyId, isMultiBranchesCompany, userId)
        ),
});

export default connect(null, mapDispatchToProps)(UsersPreviewTable);
