import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HeaderContainer, TitleIcon } from 'common/components';
import {
    Button,
    Container as MuiContainer,
    UiDialog as Dialog,
} from 'common/ui';

import * as actions from './actions';
import { ListItems, UserForm } from './components';
import * as selectors from './selectors';

const Container = ({
    fetchRoles,
    filterUsers,
    handleClickBulkItems,
    handleClickCloseModal,
    handleClickCreate,
    handlePrepareUpdate,
    handleSaveRow,
    isFetching,
    isNewRecord,
    isOpenModal,
    items,
    paginationItems,
    rolesDictionary,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });

    useEffect(() => {
        fetchRoles();
        filterUsers(filterData);
    }, []);

    return (
        <MuiContainer maxWidth="">
            <HeaderContainer>
                <Grid item>
                    <TitleIcon title={t('User', { count: 2 })} variant="h4" />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button onClick={() => handleClickCreate()}>
                        {t('Add user')}
                    </Button>
                </Grid>
            </HeaderContainer>
            <ListItems
                filterData={filterData}
                filterItems={filterUsers}
                handleClickBulkItems={handleClickBulkItems}
                handleClickUpdate={(userId) =>
                    handlePrepareUpdate(rolesDictionary, userId)
                }
                handleSaveRow={handleSaveRow}
                isFetching={isFetching}
                items={items}
                paginationData={paginationItems}
                setFilterData={setFilterData}
            />
            <Dialog
                onClose={() => handleClickCloseModal()}
                open={isOpenModal}
                size="md"
                title={`${isNewRecord ? t('Add') : t('Update')} ${t(
                    'User'
                ).toLowerCase()}`}
            >
                <UserForm filterData={filterData} />
            </Dialog>
        </MuiContainer>
    );
};

Container.propTypes = {
    fetchRoles: PropTypes.func,
    filterUsers: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handlePrepareUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    items: PropTypes.array,
    paginationItems: PropTypes.object,
    rolesDictionary: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingUsers,
    isNewRecord: selectors.getIsNewRecord,
    isOpenModal: selectors.getisOpenModal,
    items: selectors.getFetchUsersData,
    paginationItems: selectors.getFetchUsersPagination,
    rolesDictionary: selectors.getFetchRolesDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRoles: () => dispatch(actions.fetchRoles()),
    filterUsers: (filterData) => dispatch(actions.filterUsers(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickCloseModal: () => dispatch(actions.openModal(false)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handlePrepareUpdate: (rolesDictionary, userId) =>
        dispatch(actions.fetchUserDetails(rolesDictionary, userId)),
    handleSaveRow: (item) => dispatch(actions.updateUser(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
