import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from 'common/ui';

const FieldArrayConcepts = ({
    control,
    disabled,
    name,
    title,
    unit = 'kWh',
}) => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({ control, name });

    return (
        <>
            <Typography variant="subtitle2">{title}</Typography>
            <Divider />

            {fields.map((item, index) => (
                <Grid alignItems="center" container spacing={2} key={item.id}>
                    <Grid item sm>
                        <ReactHookFormInput
                            control={control}
                            disabled={disabled}
                            key={`${name}.${index}.tier`}
                            label={t('Name')}
                            name={`${name}.${index}.tier`}
                        />
                    </Grid>

                    <Grid item sm>
                        <ReactHookFormIntlNumberInput
                            allowNegativeValue={false}
                            append={unit}
                            control={control}
                            decimalsLimit={2}
                            disabled={disabled}
                            fullWidth
                            key={`${name}.${index}.consumption`}
                            label={t('Quantity')}
                            name={`${name}.${index}.consumption`}
                        />
                    </Grid>

                    <Grid item xs={2} sm={1} ml="auto">
                        <Tooltip title={t('Delete')}>
                            <IconButton onClick={() => remove(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            ))}
            <Button
                disabled={disabled}
                onClick={() => append({ tier: null, consumption: null })}
                type="button"
            >
                {t('Add')}
            </Button>
        </>
    );
};

FieldArrayConcepts.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    title: PropTypes.string,
    unit: PropTypes.string,
};

export default FieldArrayConcepts;
