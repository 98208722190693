import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormIntlNumberInput,
    ReactHookFormCheck,
} from 'common/components/form/bootstrap';
import { Button, Grid } from 'common/ui';

import FieldArrayConcepts from './FieldArrayConcepts';

const CurrentPeriod = ({
    baseName,
    control,
    disabled,
    handleDeleteSection,
    unitPrefix,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disableFuture
                        disabled={disabled}
                        format="yyyy-MM-dd"
                        inputFormat="yyyy-MM-dd"
                        key={`${baseName}.initial_date`}
                        label={t('Initial date')}
                        name={`${baseName}.initial_date`}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disableFuture
                        disabled={disabled}
                        format="yyyy-MM-dd"
                        inputFormat="yyyy-MM-dd"
                        key={`${baseName}.final_date`}
                        label={t('Final date')}
                        name={`${baseName}.final_date`}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        decimalsLimit={2}
                        disabled={disabled}
                        fullWidth
                        key={`${baseName}.fp`}
                        label={t('Power factor')}
                        max={100}
                        name={`${baseName}.fp`}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append={`${unitPrefix}Wh`}
                        control={control}
                        decimalsLimit={2}
                        disabled={disabled}
                        fullWidth
                        key={`${baseName}.solar_generation`}
                        label={t('Solar generation')}
                        name={`${baseName}.solar_generation`}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <ReactHookFormCheck
                        control={control}
                        disabled={disabled}
                        key={`${baseName}.season_change`}
                        label={t('Season change')}
                        name={`${baseName}.season_change`}
                    />
                </Grid>
            </Grid>

            <FieldArrayConcepts
                control={control}
                disabled={disabled}
                name={`${baseName}.energy`}
                title={t('Energy')}
                unit={`${unitPrefix}Wh`}
            />

            <FieldArrayConcepts
                control={control}
                disabled={disabled}
                name={`${baseName}.demand`}
                title={t('Demand')}
                unit={`${unitPrefix}W`}
            />

            <Button
                color="error"
                onClick={() =>
                    handleDeleteSection('has_current_period', baseName)
                }
                sx={{ mt: 'auto' }}
                variant="outlined"
            >
                {t('Delete current period')}
            </Button>
        </>
    );
};

CurrentPeriod.propTypes = {
    baseName: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleDeleteSection: PropTypes.func,
    unitPrefix: PropTypes.string,
};

export default CurrentPeriod;
